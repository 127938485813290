import React, { useEffect, useState } from "react";
import { Button, Form, Input,  notification } from "antd";
// import {  updateCourses } from "../../Api/Courses/course";
import { editTag } from "../../Api/user/userapi";


// const { TextArea } = Input;



function EditTag(props) {
  const { setIsEditModalVisible, editChapterData, chapterId, showData } = props;

  const [isEdit, setIsEdit] = useState(false);

  const [courseValue, setCourseValue] = useState({
    name: "",
  });

  const [form] = Form.useForm();
  const user_info = JSON.parse(localStorage.getItem("gpt_authuser"));

  const inputHandle = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setCourseValue({
      ...courseValue,
      [name]: value,
    });
  };

  useEffect(() => {
    if (editChapterData) {
      setCourseValue({
        name: editChapterData.name,
      });
    }
  }, [editChapterData]);

  const editChapterHandle = async () => {
    setIsEdit(true);
    const payload = {
        tagId: chapterId,
        name: courseValue.name,
      created_id: user_info._id,
    };
    try {
      const resp = await editTag( payload);
      if (resp && resp.status === 200) {
        showData();
        setTimeout(() => {
          notification.open({
            message: 'Tags',
            description: 'Updated successfully.',
          });
        }, 1000);
        // message.success("updated successfully.");
        setIsEditModalVisible(false);
      }
    } catch (e) {
    }
  };

  return (
    <>
      <div>
        <Form onFinish={editChapterHandle} form={form}
      
        >
          <Form.Item >
            <Input
              className="form-control form-control-lg form-control-solid"
              placeholder="Enter name"
              name="name"
              value={courseValue.name}
              onChange={inputHandle}
              required
            />
          </Form.Item>

          

          <Form.Item>
            <div className="d-flex align-items-center my-2 justify-content-center">
              <Button type="primary" style={{ height: "45px", borderRadius: '5px' }} htmlType="submit" className='login-form-button btn btn-lg btn-primary fw-bolder submit_popup_button'>
                Update Tag
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </>
  );
}

export default EditTag;
