import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
// import { useFormik } from "formik";
// import { Form, Input, notification } from "antd";
import { getProgressById } from "../../Api/user/userapi";
import Footer from "../../Constant/Footer";
import Moment from 'moment';
import {Tooltip } from "antd";
// useEffect(() => {}, [values]);

const ApplicationProgress = (props) => {
    const [applicationdetaildata, setArray] = useState([]);
    const { applicationid } = useParams();
    useEffect(() => {
        userDetails();
    }, []);
    const userDetails = async () => {
        try {
            const resp = await getProgressById({ applicaionId: applicationid });
            if (resp && resp.status === 200) {
                const list = resp;
                const arrayData = list.data.data;
               
                setArray(arrayData);
            }
        } catch (e) { }
    };
    return (
        <div className="d-flex flex-column flex-root header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed h-100">
            <div className="page d-flex flex-row flex-column-fluid">
                <div className="d-flex flex-column flex-row-fluid" id="kt_wrapper">
                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                        <div className="post d-flex flex-column-fluid" id="kt_post">
                            <div id="kt_content_container" className="container-xxl">
                                <div className="card mb-xl-8">
                                    <div className="card-body p-0">
                                        <div className="card-px text-center py-20 my-10">
                                            <Link to="/" className="mb-10">
                                                <img alt="Logo" src="/assets/media/logos/logo.png" className="h-50px logo" />
                                            </Link>
                                            <div className="row my-10" style={{ justifyContent: "center" }}>
                                                <div className="col-lg-3 fv-row fv-plugins-icon-container text-start">
                                                    <label className="col-form-label fw-bold fs-6">Name</label>
                                                    <div className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" >
                                                        {applicationdetaildata.firstname ? applicationdetaildata.firstname : ""} {applicationdetaildata.lastname ? applicationdetaildata.lastname : ""}
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 fv-row fv-plugins-icon-container text-start">
                                                    <label className="col-form-label fw-bold fs-6">Email</label>
                                                    <div className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"> {applicationdetaildata.email ? applicationdetaildata.email : ""}</div>
                                                </div>

                                                <div className="col-lg-3 fv-row fv-plugins-icon-container text-start">
                                                    <label className="col-form-label fw-bold fs-6">Applied For</label>
                                                    <div className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"> {applicationdetaildata.TagName ? applicationdetaildata.TagName : ""}</div>
                                                </div>
                                                <div className="col-lg-4 fv-row fv-plugins-icon-container text-start  mt-10">
                                                    <div className="timeline-label">
                                                        {applicationdetaildata.clarificationrequestedstatus && applicationdetaildata.clarificationrequestedstatus === '1' ?(
                                                        <div className="timeline-item">
                                                            <div className="timeline-label fw-bolder text-gray-800 fs-6"></div>

                                                            <div className="timeline-badge">
                                                                    <i className={applicationdetaildata.clarificationrequestedstatus ? "fas fa-circle text-success fs-6" : "fa fa-genderless text-round1 fs-1"}></i>
                                                            </div>

                                                            <div className="fw-bolder timeline-content text-gray-800 ps-3">Clarification requested</div>
                                                        </div>
                                                       ):("")}
                                                        {applicationdetaildata.clarificationreceivestatus && applicationdetaildata.clarificationreceivestatus === '1' ? (
                                                        <div className="timeline-item">
                                                            <div className="timeline-label fw-bolder text-gray-800 fs-6"></div>

                                                            <div className="timeline-badge">
                                                                    <i className={applicationdetaildata.clarificationreceivestatus ? "fas fa-circle text-success fs-6" : "fa fa-genderless text-round1 fs-1"}></i>
                                                            </div>

                                                            <div className="timeline-content d-flex">
                                                                <span className="fw-bolder text-gray-800 ps-3">Clarification received</span>
                                                            </div>
                                                        </div>
                                                        ) : ("")}
                                                        <div className="timeline-item">
                                                            <div className="timeline-label fw-bolder text-gray-800 fs-6"></div>

                                                            <div className="timeline-badge">
                                                                {applicationdetaildata.underintialreviewstatus === '1' ? (
                                                                    <Tooltip title={Moment(applicationdetaildata.underintialreviewdate).format('DD-MM-YYYY h:mm a')}>
                                                                        <i className="fas fa-circle text-success fs-6" ></i>
                                                                    </Tooltip>
                                                                ) : (
                                                                <i className="fa fa-genderless text-round1 fs-1"></i>
                                                                )}
                                                               
                                                            </div>

                                                            <div className="timeline-content fw-bolder text-gray-800 ps-3">Under Initial Review</div>
                                                        </div>
                                                        {((applicationdetaildata.statusint !== 4 || applicationdetaildata.statusint !== 5) && (applicationdetaildata.intialreviewcompletestatus === '1')) && (applicationdetaildata.intialreviewcompletestatus ) && (
                                                        <div className="timeline-item">
                                                            <div className="timeline-label fw-bolder text-gray-800 fs-6"></div>

                                                            <div className="timeline-badge">
                                                                    <Tooltip title={Moment(applicationdetaildata.intialreviewcompletedate).format('DD-MM-YYYY h:mm a')}>
                                                                        <i className="fas fa-circle text-success fs-6" ></i>
                                                                    </Tooltip>
                                                                
                                                            </div>

                                                            <div className="timeline-content fw-bolder text-gray-800 ps-3">Initial review completed</div>
                                                        </div>
                                                        )}
                                                        {((applicationdetaildata.statusint !== 4 && applicationdetaildata.statusint !== 5) && (applicationdetaildata.intialreviewcompletestatus === '0')) && (applicationdetaildata.intialreviewcompletestatus) && (
                                                            <div className="timeline-item">
                                                                <div className="timeline-label fw-bolder text-gray-800 fs-6"></div>

                                                                <div className="timeline-badge">

                                                                        <i className="fa fa-genderless text-round1 fs-1"></i>
                                                                </div>

                                                                <div className="timeline-content fw-bolder text-gray-800 ps-3">Initial review completed</div>
                                                            </div>
                                                        )}
                                                        {((applicationdetaildata.statusint !== 4 || applicationdetaildata.statusint !== 5) && (applicationdetaildata.underfinalreviewstatus === '1')) && (applicationdetaildata.underfinalreviewstatus) && (
                                                        <div className="timeline-item">
                                                            <div className="timeline-label fw-bolder text-gray-800 fs-6"></div>

                                                            <div className="timeline-badge">
                                                                    <Tooltip title={Moment(applicationdetaildata.underfinalreviewdate).format('DD-MM-YYYY h:mm a')}>
                                                                        <i className="fas fa-circle text-success fs-6" ></i>
                                                                    </Tooltip>                                                               
                                                            </div>

                                                            <div className="timeline-content fw-bolder text-gray-800 ps-3">Under Final Review</div>
                                                        </div>
                                                        )}
                                                        {((applicationdetaildata.statusint !== 4 && applicationdetaildata.statusint !== 5) && (applicationdetaildata.underfinalreviewstatus === '0')) && (applicationdetaildata.underfinalreviewstatus) && (
                                                            <div className="timeline-item">
                                                                <div className="timeline-label fw-bolder text-gray-800 fs-6"></div>

                                                                <div className="timeline-badge">
                                                                    <i className="fa fa-genderless text-round1 fs-1"></i>
                                                                </div>

                                                                <div className="timeline-content fw-bolder text-gray-800 ps-3">Under Final Review</div>
                                                            </div>
                                                        )}
                                                        {((applicationdetaildata.statusint !== 4 || applicationdetaildata.statusint !== 5) && (applicationdetaildata.setinterview === '1')) && (applicationdetaildata.setinterview) && (
                                                        
                                                        <div className="timeline-item">
                                                            <div className="timeline-label fw-bolder text-gray-800 fs-6"></div>

                                                            <div className="timeline-badge">
                                                                    <Tooltip title={Moment(applicationdetaildata.setinterviewdate).format('DD-MM-YYYY h:mm a')}>
                                                                        <i className="fas fa-circle text-success fs-6" ></i>
                                                                    </Tooltip>
                                                                
                                                            </div>

                                                            <div className="timeline-content fw-bolder text-gray-800 ps-3">Setting up Interview </div>
                                                        </div>
                                                        )}
                                                        {((applicationdetaildata.statusint !== 4 && applicationdetaildata.statusint !== 5) && (applicationdetaildata.setinterview === '0')) && (applicationdetaildata.setinterview) && (
                                                            <div className="timeline-item">
                                                                <div className="timeline-label fw-bolder text-gray-800 fs-6"></div>

                                                                <div className="timeline-badge">
                                                                        
                                                                        <i className="fa fa-genderless text-round1 fs-1"></i>
                                                                </div>

                                                                <div className="timeline-content fw-bolder text-gray-800 ps-3">Setting up Interview </div>
                                                            </div>
                                                        )}
                                                        {((applicationdetaildata.statusint !== 4 || applicationdetaildata.statusint !== 5) && (applicationdetaildata.setroundone === '1')) && (applicationdetaildata.setroundone) && (

                                                            <div className="timeline-item">
                                                                <div className="timeline-label fw-bolder text-gray-800 fs-6"></div>

                                                                <div className="timeline-badge">
                                                                    <Tooltip title={Moment(applicationdetaildata.setroundonedate).format('DD-MM-YYYY h:mm a')}>
                                                                        <i className="fas fa-circle text-success fs-6" ></i>
                                                                    </Tooltip>

                                                                </div>

                                                                <div className="timeline-content fw-bolder text-gray-800 ps-3">Accepted (Round 1) </div>
                                                            </div>
                                                        )}
                                                        {((applicationdetaildata.statusint !== 4 && applicationdetaildata.statusint !== 5) && (applicationdetaildata.setroundone === '0')) && (applicationdetaildata.setroundone) && (
                                                            <div className="timeline-item">
                                                                <div className="timeline-label fw-bolder text-gray-800 fs-6"></div>

                                                                <div className="timeline-badge">

                                                                    <i className="fa fa-genderless text-round1 fs-1"></i>
                                                                </div>

                                                                <div className="timeline-content fw-bolder text-gray-800 ps-3">Accepted (Round 1) </div>
                                                            </div>
                                                        )}
                                                        {((applicationdetaildata.statusint !== 4 || applicationdetaildata.statusint !== 5) && (applicationdetaildata.setroundtwo === '1')) && (applicationdetaildata.setroundtwo) && (

                                                            <div className="timeline-item">
                                                                <div className="timeline-label fw-bolder text-gray-800 fs-6"></div>

                                                                <div className="timeline-badge">
                                                                    <Tooltip title={Moment(applicationdetaildata.setroundtwodate).format('DD-MM-YYYY h:mm a')}>
                                                                        <i className="fas fa-circle text-success fs-6" ></i>
                                                                    </Tooltip>

                                                                </div>

                                                                <div className="timeline-content fw-bolder text-gray-800 ps-3">Accepted (Round 2) </div>
                                                            </div>
                                                        )}
                                                        {((applicationdetaildata.statusint !== 4 && applicationdetaildata.statusint !== 5) && (applicationdetaildata.setroundtwo === '0')) && (applicationdetaildata.setroundtwo) && (
                                                            <div className="timeline-item">
                                                                <div className="timeline-label fw-bolder text-gray-800 fs-6"></div>

                                                                <div className="timeline-badge">

                                                                    <i className="fa fa-genderless text-round1 fs-1"></i>
                                                                </div>

                                                                <div className="timeline-content fw-bolder text-gray-800 ps-3">Accepted (Round 2) </div>
                                                            </div>
                                                        )}
                                                        {(applicationdetaildata.statusint !== 4) ? (
                                                        <div className="timeline-item">
                                                            <div className="timeline-label fw-bolder text-gray-800 fs-6"></div>

                                                            <div className="timeline-badge">
                                                                    {applicationdetaildata.statusint === 5 ? (
                                                                        <Tooltip title={Moment(applicationdetaildata.acceptrejectdate).format('DD-MM-YYYY h:mm a')}>
                                                                            <i className="fas fa-circle text-success fs-6" ></i>
                                                                        </Tooltip>
                                                                    ) : (
                                                                        <i className="fa fa-genderless text-round1 fs-1"></i>
                                                                    )}
                                                            </div>

                                                            <div className="timeline-content fw-bolder text-gray-800 ps-3">Accepted (Final)</div>
                                                        </div>
                                                        ) : ("")}
                                                        {(applicationdetaildata.statusint !== 5) ? (
                                                            <div className="timeline-item">
                                                                <div className="timeline-label fw-bolder text-gray-800 fs-6"></div>

                                                                <div className="timeline-badge">
                                                                    {applicationdetaildata.statusint === 4 ? (
                                                                        <Tooltip title={Moment(applicationdetaildata.acceptrejectdate).format('DD-MM-YYYY h:mm a')}>
                                                                            <i className="fas fa-circle text-success fs-6" ></i>
                                                                        </Tooltip>
                                                                    ) : (
                                                                        <i className="fa fa-genderless text-round1 fs-1"></i>
                                                                    )}
                                                                </div>

                                                                <div className="timeline-content fw-bolder text-gray-800 ps-3">Rejected</div>
                                                            </div>
                                                        ) : ("")}
                                                                                                              
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Footer />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
                <span className="svg-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="black" />
                        <path
                            d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                            fill="black"
                        />
                    </svg>
                </span>
            </div>
        </div>
    );
};

export default ApplicationProgress;
