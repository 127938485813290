import React from "react";
import Routers from "./Routers";
// import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
// import { Form, Input, Typography, Spin, Row, notification } from "antd";
import "antd/dist/antd.css";

import {  userRefresh } from "./Api/user/userapi";
import TabsProvider from "./Provider/Provider";

const user_info = JSON.parse(localStorage.getItem("gpt_authuser"));
// console.log('user_info===', user_info)
// const user_role = JSON.parse(localStorage.getItem("gpt_user_role"));

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            permission_checked: false,
        };
    }

    componentDidMount() {
        if (user_info && user_info.usertype && user_info.usertype !== "admin") {
            this.getrole(user_info.level, user_info._id);
        } else {
            this.setState({ permission_checked: true });
        }

        const interval = setInterval(() => {
            if (user_info && user_info.usertype && user_info.usertype !== "admin") {
                this.getrole(user_info.level, user_info._id);
            }
        }, 60000);
        return () => clearInterval(interval);
    }

    getrole = async (levelId, MemberId) => {
        try {
            // const response = await getRole({ levelId: levelId, MemberId: MemberId });
            const response = await userRefresh({ userId: MemberId });
            if (response && response.data.success !== false) {
                if (response.data.data.status !== 'active'){
                    window.location.href = "/logout";
                }
                
                localStorage.setItem("gpt_authuser", JSON.stringify(response.data.data));
                localStorage.setItem("gpt_user_role", JSON.stringify(response.data.data.Levelsdata[0]));
                // localStorage.setItem("gpt_user_role", JSON.stringify(response.data.data));
                this.setState({ permission_checked: true });
            }
        } catch (error) {
            console.log("error--------", error);
        }
    };

    render() {
        if (this.state.permission_checked === false) {
            return null;
        }
        return (
            <TabsProvider>
                <div className="App">
                    <Routers />
                </div>
            </TabsProvider>
        );
    }
}

export default App;