import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

function FinalAcceptApplication(props) {

    const { setIsFinalAcceptModalVisible, editChapterData, messagedata, FinalAcceptApplicationSubmit, chapterId } = props;
    useEffect(() => {
        if (editChapterData) {
        }
    }, [editChapterData]);


    const SubmitHandler = async (event) => {
        event.preventDefault();
        FinalAcceptApplicationSubmit(chapterId);
    };
    const handleCancel = () => {
        setIsFinalAcceptModalVisible(false);
    };
    return (
        <form name="normal_login" className="login-form" onSubmit={SubmitHandler}>

            <h4>{messagedata}</h4>
            <div className="model text-right mt-3">
                <button type="submit" className='btn btn-sm btn-primary fw-bolder'>Ok</button>
                <span className="btn btn-sm btn-light-primary fw-bolder cancel_popup" onClick={() => handleCancel()} >Cancel</span>
            </div>
        </form>
    );
}

export default FinalAcceptApplication;
