import React from "react";
import Select from "react-select";
// import { useFormik } from "formik";
import { notification } from "antd";
import { Link, Redirect } from "react-router-dom";
import { updateMember, getLevels, getTag, MemberDetail } from "../../Api/user/userapi";
import csc from "country-state-city";

import LeftSideBar from "../../Constant/LeftSideBar";
import TopBar from "../../Constant/TopBar";
import Footer from "../../Constant/Footer";
import Validations from "../Utility/Validations";
const user_info = JSON.parse(localStorage.getItem("gpt_authuser"));
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#ffdbdb" : ""
        },
        backgroundColor: state.isSelected ? "#cc0c0c" : "",
    }),
};

class EditMember extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            member_form: {
                id: {
                    label: "Member ID",
                    type: "hidden",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },
                userName: {
                    type: "text",
                    label: "Name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                level: {
                    type: "select",
                    label: "Select Level",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Select Level",
                    validations: { required: true },
                    options: [],
                },
                tags: {
                    type: "select",
                    label: "Select tags",
                    value: [],
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Select tags",
                    validations: { required: true, typeahead: true},
                    options: [
                        { label: "Select tags", value: "" },
                    ],
                },
                age: {
                    type: "number",
                    label: "Age",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, number: true  },
                },
                position: {
                    type: "text",
                    label: "Position",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                zoomlink: {
                    type: "text",
                    label: "Link",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                gender: {
                    type: "select",
                    label: "Select gender",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Select gender",
                    validations: { required: true },
                    options: [
                        { label: "Filter By Gender", value: "" }, { label: "Woman", value: "Woman" }, { label: "Man", value: "Man" }, { label: "Trans Woman", value: "Trans Woman" }, { label: "Trans Man", value: "Trans Man" }, { label: "Gender Queer", value: "Gender Queer" }, { label: "Gender Fluid", value: "Gender Fluid" }, { label: "Prefer Not To Disclose", value: "Prefer Not To Disclose" },
                        // { label: "Select gender", value: "" },
                        // { label: "Male", value: "Male" },
                        // { label: "Female", value: "Female" },
                        // { label: "Transgender", value: "Transgender" },
                        // { label: "Non-Binary", value: "Non-Binary" },
                        // { label: "Other", value: "Other" },

                    ],
                },
                phone: {
                    type: "number",
                    label: "Phone",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, number: true  },
                },
                email: {
                    type: "email",
                    label: "Eail",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, email: true },
                },

                address: {
                    type: "text",
                    label: "Address",
                    value: "",
                    valid: false,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                country: {
                    type: "select",
                    label: "Select country",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Select country",
                    validations: { required: true },
                    options: [],
                },
                state: {
                    type: "select",
                    label: "Select state",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Select state",
                    validations: { required: true },
                    options: [],
                },
                city: {
                    type: "select",
                    label: "Select city",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Select city",
                    validations: { required: true },
                    options: [],
                },
                selectedValue: {
                    value:[],
                    validations: { required: false },
                },
                created_id: {
                    value: user_info._id,
                    validations: { required: false },
                },

            },
            
            loader: false,
            memberId: this.props.memberId,
            isAuthenticated: false,
            Levels: [],
            Members: [],

        };

    }

    async componentDidMount() {

        const update_member_form = { ...this.state.member_form };
     
        this.MemberDetail();
        this.userLevels();
        this.userDetails();


        const countries = csc.getAllCountries();
        let country = [];
        for (let key in countries) {
            country.push({
                value: countries[key].id,
                label: countries[key].name,
            });
        }
        update_member_form.country.options = country;
        this.setState({ member_form: update_member_form });
    }

    MemberDetail = async () => {
        try {
            const resp = await MemberDetail({ memberId: this.props.memberId });
            if (resp && resp.status === 200) {
                const list = resp;
                const member_detail = list.data.data;
                const update_member_form = { ...this.state.member_form };
                for (let key in update_member_form) {
                    let form_element = update_member_form[key];
                    if (member_detail[key]) {
                        if (key === 'tags') {
                            if (member_detail[key] !== '') {
                                const resultvalue = [];
                                for (let keys in member_detail[key]) {
                                    resultvalue.push(member_detail[key][keys]);
                                }
                                update_member_form.selectedValue.value = resultvalue;
                            }

                        }
                        if (key === 'country') {
                            this.getstate(member_detail[key]);
                        }
                        if (key === 'state') {
                            this.getcity(member_detail[key])
                        }



                        form_element.value = member_detail[key];
                        update_member_form[key] = form_element;
                    }
                }
                update_member_form.id.value = this.state.memberId;                
                this.setState({
                    loader: false,
                    member_form: update_member_form,
                });

            }
        } catch (e) {
        }
    };
    getstate = async (countryId) => {
        const update_member_form = { ...this.state.member_form };
        const states = csc.getStatesOfCountry(countryId);
        let statevalue = [];
        for (let key in states) {
            statevalue.push({
                value: states[key].id,
                label: states[key].name,
            });
        }
        update_member_form.state.options = statevalue;
        this.setState({ member_form: update_member_form });
    };
    getcity = async (stateId) => {
        const update_member_form = { ...this.state.member_form };
        const citys = csc.getCitiesOfState(stateId);
        let cityvalue = [];
        for (let key in citys) {
            cityvalue.push({
                value: citys[key].id,
                label: citys[key].name,
            });
        }
        update_member_form.city.options = cityvalue;
        this.setState({ member_form: update_member_form });
    };
    userLevels = async () => {
        try {
            const resp = await getLevels();
            if (resp && resp.status === 200) {
                const list = resp;
                const all_bidtype = list.data.data;
                const update_member_form = { ...this.state.member_form };
                let LevelId_option = [];
                for (let key in all_bidtype) {
                    LevelId_option.push({
                        value: all_bidtype[key]._id,
                        label: all_bidtype[key].name,
                    });
                }
                update_member_form.level.options = LevelId_option;
                this.setState({ member_form: update_member_form });
                // this.setState({
                //     Levels: arrayData,
                // });
                // setLevels(arrayData);
            }
        } catch (e) {
        }
    };
    userDetails = async () => {
        try {

            const resp = await getTag();
            if (resp && resp.status === 200) {
                const list = resp;
                const arrayData = list.data.data;
                const update_member_form = { ...this.state.member_form };
                arrayData.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1)
                let MemberId_option = [];
                MemberId_option.push({
                    label: 'All Tags',
                    value: "0",
                });
                for (let key in arrayData) {
                    MemberId_option.push({
                        label: arrayData[key].name,
                        value: arrayData[key]._id,
                    });
                }
                update_member_form.tags.options = MemberId_option;
                this.setState({ member_form: update_member_form });
            }
        } catch (e) {
        }
    };

    inputChangeHandler(event, identifier) {
        const update_member_form = { ...this.state.member_form };
        const form_element = { ...update_member_form[identifier] };
        form_element.value = event.target.value;
        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_member_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_member_form) {
            is_form_valid = update_member_form[identifier].valid && is_form_valid;
        }
        this.setState({
            member_form: update_member_form,
            is_form_valid: is_form_valid,
        });
    }
    handleChange(data, identifier, type) {
        const update_member_form = { ...this.state.member_form };
        update_member_form[identifier].value = data.value;
        if (identifier === 'country') {
            this.getstate(data.value);
        }
        if (identifier === 'state') {
            this.getcity(data.value)
        }

        this.setState({ member_form: update_member_form });
    }
    handleChangeinfo = (e) => {
        const update_member_form = { ...this.state.member_form };
        const myArray = [];
        for (let key in e) {
            if(e[key].value === '0'){
                const tagsvalue = update_member_form['tags'].options;
                for (let keys in tagsvalue) {
                    if (tagsvalue[keys].value !== '0' && e[key].value !== tagsvalue[keys].value){
                        myArray.push(tagsvalue[keys].value); 
                    }
                }
                
            }
            else{
                myArray.push(e[key].value);
            }
        }
        const resultvalue = myArray.filter((v, i, a) => a.indexOf(v) === i); 
        // console.log(e.find(st => st.label === 'All Tags').label)        
        // const resultvalue = (Array.isArray(e) ? e.map(x => x.value) : []);
        update_member_form['selectedValue'].value = resultvalue;
        update_member_form['tags'].value = resultvalue;
        this.setState({ member_form: update_member_form });
    }
    SubmitHandler = async (event) => {
        event.preventDefault();
        let is_form_valid = true;
        const update_member_form = this.state.member_form;
        for (let key in update_member_form) {
            let form_element = update_member_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_member_form[key] = form_element;
        }
        this.setState({ member_form: update_member_form });
        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.member_form) {
                form_data[key] = this.state.member_form[key].value;
            }
            try {
                const result = await updateMember(this.state.memberId, form_data);
                if (result && result.data.success === true) {                   
                    setTimeout(() => {
                        this.setState({
                            isAuthenticated: true,
                            redirect_url: "/member",
                        });
                        notification.open({
                            message: "Member",
                            description: "Updated Successfully.",
                        });
                    }, 1000);

                } else if (result && result.data.success === false) {
                    setTimeout(() => {
                        notification.open({
                            message: "Member",
                            description: result.data.message,
                        });
                    }, 1000);
                }               
            } catch (e) {
            }
            
        }
    };
    render() {
        const { member_form } = this.state;
        return (
            <React.Fragment>
                {this.state.isAuthenticated ? <Redirect to={this.state.redirect_url} /> : null}
                <div className="d-flex flex-column flex-root header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed h-100">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <LeftSideBar />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <TopBar />

                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="toolbar" id="kt_toolbar">
                                    <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                                        <div
                                            data-kt-swapper="true"
                                            data-kt-swapper-mode="prepend"
                                            data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
                                            className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
                                        >
                                            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
                                                Team Members
                                                <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                                            </h1>

                                            <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                                                <li className="breadcrumb-item text-muted">
                                                    <Link to="/dashboard" className="text-muted text-hover-primary">
                                                        Home
                                                    </Link>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <span className="bullet bg-gray-200 w-5px h-2px"></span>
                                                </li>
                                                <li className="breadcrumb-item text-muted">
                                                    <Link to="/member" className="text-muted text-hover-primary">
                                                        Team Members
                                                    </Link>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <span className="bullet bg-gray-200 w-5px h-2px"></span>
                                                </li>

                                                <li className="breadcrumb-item text-dark">Edit Member</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-xxl">
                                        <form name="normal_login" className="login-form team-form edit-team-form" onSubmit={this.SubmitHandler}>
                                            <div className="row gy-5 g-xl-8">
                                                <div className="col-xl-12">
                                                    <div className="card card-xl-stretch mb-xl-8">
                                                        <div className="card-header border-0">
                                                            <h3 className="card-title fw-bolder text-dark">Generals</h3>
                                                        </div>

                                                        <div className="card-body pt-2">
                                                            <div className="row">
                                                                <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label required fw-bold fs-6">Name</label>

                                                                    <input placeholder="First name" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                                        value={member_form.userName.value ? member_form.userName.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "userName")}
                                                                        onBlur={(event) => {
                                                                            member_form.userName.onBlur_out = true;
                                                                            this.setState({
                                                                                member_form: member_form,
                                                                            });
                                                                        }} />
                                                                    {!member_form.userName.valid && member_form.userName.onBlur_out ? <div className="error field-error">{member_form.userName.error_msg}</div> : ""}
                                                                    <div className="fv-plugins-message-container invalid-feedback"></div>
                                                                </div>

                                                                <div className="col-lg-4 fv-row fv-plugins-icon-container ">
                                                                    <label className=" col-form-label required fw-bold fs-6">Select Level</label>
                                                                    <Select
                                                                        styles={customStyles}
                                                                        name={"level"}
                                                                        className="react-bootstrap-typeahead tag-outer"
                                                                        placeholder="Select a Level..."
                                                                        options={member_form.level.options}
                                                                        value={member_form.level.options.filter(function (option) {
                                                                            return option.value === member_form.level.value;
                                                                        })}
                                                                        onChange={newValue => this.handleChange(newValue, "level", 'levelvalue')}
                                                                    />
                                                                    {!member_form.level.valid && member_form.level.onBlur_out ? <div className="error field-error">{member_form.level.error_msg}</div> : ""}
                                                                </div>
                                                                <div id="tags-main" className="col-lg-4 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label required fw-bold fs-6">Tags</label>
                                                                    <Select
                                                                        styles={customStyles}
                                                                        className="dropdown"
                                                                        placeholder="Select tags.."
                                                                        value={member_form.tags.options.filter(obj => member_form.selectedValue.value.includes(obj.value))} // set selected values
                                                                        options={member_form.tags.options} // set list of the data
                                                                        onChange={(event) => this.handleChangeinfo(event)}
                                                                        isMulti
                                                                        isClearable
                                                                    />
                                                                    {!member_form.tags.valid && member_form.tags.onBlur_out ? <div className="error field-error">{member_form.tags.error_msg}</div> : ""}
                                                                </div>

                                                                <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label required fw-bold fs-6">Age</label>
                                                                    <input placeholder="Enter your age" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" value={member_form.age.value ? member_form.age.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "age")}
                                                                        onBlur={(event) => {
                                                                            member_form.age.onBlur_out = true;
                                                                            this.setState({
                                                                                member_form: member_form,
                                                                            });
                                                                        }} />
                                                                    {!member_form.age.valid && member_form.age.onBlur_out ? <div className="error field-error">{member_form.age.error_msg}</div> : ""}
                                                                    <div className="fv-plugins-message-container invalid-feedback"></div>
                                                                </div>

                                                                <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label required fw-bold fs-6">Position / Title</label>

                                                                    <input placeholder="Position" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" value={member_form.position.value ? member_form.position.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "position")}
                                                                        onBlur={(event) => {
                                                                            member_form.position.onBlur_out = true;
                                                                            this.setState({
                                                                                member_form: member_form,
                                                                            });
                                                                        }} />
                                                                    {!member_form.position.valid && member_form.position.onBlur_out ? <div className="error field-error">{member_form.position.error_msg}</div> : ""}
                                                                    <div className="fv-plugins-message-container invalid-feedback"></div>
                                                                </div>

                                                                <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label required fw-bold fs-6">Gender</label>
                                                                    <Select
                                                                        styles={customStyles}
                                                                        className="react-bootstrap-typeahead tag-outer"
                                                                        placeholder="Select a Gender"
                                                                        options={member_form.gender.options}
                                                                        // value={member_form.gendervalue} 
                                                                        value={member_form.gender.options.filter(function (option) {
                                                                            return option.value === member_form.gender.value;
                                                                        })}
                                                                        onChange={newValue => this.handleChange(newValue, "gender", 'gendervalue')}
                                                                    />

                                                                    {!member_form.gender.valid && member_form.gender.onBlur_out ? <div className="error field-error">{member_form.gender.error_msg}</div> : ""}
                                                                    <div className="fv-plugins-message-container invalid-feedback"></div>
                                                                </div>
                                                                <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label fw-bold fs-6">Zoom Link</label>

                                                                    <input placeholder="Link" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                                        value={member_form.zoomlink.value ? member_form.zoomlink.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "zoomlink")}
                                                                        onBlur={(event) => {
                                                                            member_form.zoomlink.onBlur_out = true;
                                                                            this.setState({
                                                                                member_form: member_form,
                                                                            });
                                                                        }} />
                                                                    {!member_form.zoomlink.valid && member_form.zoomlink.onBlur_out ? <div className="error field-error">{member_form.zoomlink.error_msg}</div> : ""}
                                                                    <div className="fv-plugins-message-container invalid-feedback"></div>
                                                                </div>

                                                                <div className="col-lg-4 fv-row fv-plugins-icon-container"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row gy-5 g-xl-8">
                                                <div className="col-xl-12">
                                                    <div className="card card-xl-stretch mb-xl-8">
                                                        <div className="card-header border-0">
                                                            <h3 className="card-title fw-bolder text-dark">Contact</h3>
                                                        </div>

                                                        <div className="card-body pt-2">
                                                            <div className="row">
                                                                <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label required required fw-bold fs-6">Phone Number</label>

                                                                    <input placeholder="Phone Number" type="text" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" value={member_form.phone.value ? member_form.phone.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "phone")}
                                                                        onBlur={(event) => {
                                                                            member_form.phone.onBlur_out = true;
                                                                            this.setState({
                                                                                member_form: member_form,
                                                                            });
                                                                        }} />
                                                                    {!member_form.phone.valid && member_form.phone.onBlur_out ? <div className="error field-error">{member_form.phone.error_msg}</div> : ""}
                                                                    <div className="fv-plugins-message-container invalid-feedback"></div>
                                                                </div>

                                                                <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label required fw-bold fs-6">Email</label>

                                                                    <input placeholder="Enter your email " type="email" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" value={member_form.email.value ? member_form.email.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "email")}
                                                                        onBlur={(event) => {
                                                                            member_form.email.onBlur_out = true;
                                                                            this.setState({
                                                                                member_form: member_form,
                                                                            });
                                                                        }} />
                                                                    {!member_form.email.valid && member_form.email.onBlur_out ? <div className="error field-error">{member_form.email.error_msg}</div> : ""}
                                                                    <div className="fv-plugins-message-container invalid-feedback"></div>
                                                                </div>

                                                                <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label  fw-bold fs-6">Address</label>
                                                                    <input placeholder="Enter your address " className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" value={member_form.address.value ? member_form.address.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "address")}
                                                                        onBlur={(event) => {
                                                                            member_form.address.onBlur_out = true;
                                                                            this.setState({
                                                                                member_form: member_form,
                                                                            });
                                                                        }} />
                                                                </div>

                                                                <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label required fw-bold fs-6">Country</label>
                                                                    <Select
                                                                        styles={customStyles}
                                                                        className="react-bootstrap-typeahead tag-outer"
                                                                        placeholder="Select a country..."
                                                                        options={member_form.country.options}
                                                                        value={member_form.country.options.filter(function (option) {
                                                                            return option.value === member_form.country.value;
                                                                        })}
                                                                        onChange={newValue => this.handleChange(newValue, "country", 'countryvalue')}
                                                                    />
                                                                    {!member_form.country.valid && member_form.country.onBlur_out ? <div className="error field-error">{member_form.country.error_msg}</div> : ""}
                                                                </div>

                                                                <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label required fw-bold fs-6">State</label>

                                                                    <Select
                                                                        styles={customStyles}
                                                                        className="react-bootstrap-typeahead tag-outer"
                                                                        placeholder="Select a State..."
                                                                        options={member_form.state.options}
                                                                        value={member_form.state.options.filter(function (option) {
                                                                            return option.value === member_form.state.value;
                                                                        })}
                                                                        onChange={newValue => this.handleChange(newValue, "state", 'countryvalue')}
                                                                    />
                                                                    {!member_form.state.valid && member_form.state.onBlur_out ? <div className="error field-error">{member_form.state.error_msg}</div> : ""}
                                                                </div>

                                                                <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label required fw-bold fs-6">City</label>
                                                                    <Select
                                                                        styles={customStyles}
                                                                        className="react-bootstrap-typeahead tag-outer"
                                                                        placeholder="Select a City..."
                                                                        options={member_form.city.options}
                                                                        value={member_form.city.options.filter(function (option) {
                                                                            return option.value === member_form.city.value;
                                                                        })}
                                                                        onChange={newValue => this.handleChange(newValue, "city", 'countryvalue')}
                                                                    />
                                                                    {!member_form.city.valid && member_form.city.onBlur_out ? <div className="error field-error">{member_form.city.error_msg}</div> : ""}
                                                                </div>

                                                                <div className="col-lg-4 mt-8">
                                                                    <button type="submit" id="kt_new_password_submit" className="btn btn-sm btn-primary fw-bolder me-4">
                                                                        <span className="indicator-label">Save</span>
                                                                    </button>
                                                                    <Link to="/member" className="btn btn-sm btn-light-primary fw-bolder">
                                                                        Cancel
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                       
                                    </div>
                                </div>
							</div>
							 <Footer />
                        </div>
                    </div>
                    <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
                        <span className="svg-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="black" />
                                <path
                                    d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                    fill="black"
                                />
                            </svg>
                        </span>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default EditMember;
