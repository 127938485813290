import React from "react";
import Select from "react-select";
import { notification } from "antd";
import { Link, Redirect } from "react-router-dom";
import { editTemplate, getTemplate } from "../../Api/user/userapi";

import LeftSideBar from "../../Constant/LeftSideBar";
import TopBar from "../../Constant/TopBar";
import Footer from "../../Constant/Footer";
import Validations from "../Utility/Validations";
import WysiwygEditor from "../../Component/Utility/WysiwygEditor";

const user_info = JSON.parse(localStorage.getItem("gpt_authuser"));

class EditEmail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email_form: {
                Id: {
                    label: "Email ID",
                    type: "hidden",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },
                title: {
                    type: "text",
                    label: "Name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },


                subject: {
                    type: "text",
                    label: "subject",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                day: {
                    type: "select",
                    label: "Select day",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Select day",
                    validations: { required: false },
                    options: [
                        { label: "Select day", value: "" },
                        { label: "1", value: "1" },
                        { label: "2", value: "2" },
                        { label: "3", value: "3" },
                        { label: "4", value: "4" },
                        { label: "5", value: "5" },
                        { label: "6", value: "6" },
                        { label: "7", value: "7" },
                        { label: "8", value: "8" },
                        { label: "9", value: "9" },
                        { label: "10", value: "10" },

                    ],
                },

                content: {
                    type: "text",
                    label: "content",
                    value: "",
                    valid: false,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                created_id: {
                    value: user_info._id,
                    validations: { required: false },
                },

            },
            // emailcontent:'',
            loader: false,
            isAuthenticated: false,
            emailId: this.props.emailId,
        };

    }

    async componentDidMount() {

        const update_email_form = { ...this.state.email_form };
        this.setState({ email_form: update_email_form });
        this.MemberDetail();
    }
    MemberDetail = async () => {
        try {
            const resp = await getTemplate({ Id: this.props.emailId });
            if (resp && resp.status === 200) {
                const list = resp;
                const email_detail = list.data.data;
                const update_email_form = { ...this.state.email_form };
                for (let key in update_email_form) {
                    let form_element = update_email_form[key];
                    if (email_detail[key]) {
                        if (key === 'tags') {
                            if (email_detail[key] !== '') {
                                const resultvalue = [];
                                for (let keys in email_detail[key]) {
                                    resultvalue.push(email_detail[key][keys]);
                                }
                                update_email_form.selectedValue.value = resultvalue;
                            }

                        }
                        form_element.value = email_detail[key];
                        update_email_form[key] = form_element;
                    }
                }
                update_email_form.Id.value = this.state.emailId;
                this.setState({
                    loader: false,
                    email_form: update_email_form,
                });

            }
        } catch (e) {
        }
    };
    inputChangeHandler(event, identifier) {
        const update_email_form = { ...this.state.email_form };
        const form_element = { ...update_email_form[identifier] };
        form_element.value = event.target.value;
        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_email_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_email_form) {
            is_form_valid = update_email_form[identifier].valid && is_form_valid;
        }
        this.setState({
            email_form: update_email_form,
            is_form_valid: is_form_valid,
        });

    }
    handleChange(data, identifier, type) {
        const update_email_form = { ...this.state.email_form };
        update_email_form[identifier].value = data.value;


        this.setState({ email_form: update_email_form });
    }
    handleChangeediter(content) {
        const update_email_form = { ...this.state.email_form };
        update_email_form['content'].value = content;
        this.setState({ email_form: update_email_form });
    }

    SubmitHandler = async (event) => {
        event.preventDefault();
        let is_form_valid = true;
        const update_email_form = this.state.email_form;
        for (let key in update_email_form) {
            let form_element = update_email_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_email_form[key] = form_element;
        }
        this.setState({ email_form: update_email_form });
        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.email_form) {
                form_data[key] = this.state.email_form[key].value;
            }
            try {
                const result = await editTemplate(form_data);
                if (result && result.data.success === true) {
                    setTimeout(() => {
                        this.setState({
                            isAuthenticated: true,
                            redirect_url: "/email",
                        });
                        notification.open({
                            message: "Email",
                            description: "Email updated successfully.",
                        });
                    }, 1000);

                } else if (result && result.data.success === false) {
                    setTimeout(() => {
                        notification.open({
                            message: "Email",
                            description: result.data.message,
                        });
                    }, 1000);
                }
            } catch (e) {
            }

        }
    };
    render() {
        const { email_form } = this.state;
        return (
            <React.Fragment>
                {this.state.isAuthenticated ? <Redirect to={this.state.redirect_url} /> : null}
                <div className="d-flex flex-column flex-root header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed h-100">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <LeftSideBar />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <TopBar />

                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="toolbar" id="kt_toolbar">
                                    <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                                        <div
                                            data-kt-swapper="true"
                                            data-kt-swapper-mode="prepend"
                                            data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
                                            className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
                                        >
                                            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
                                                Settings
                                                <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                                            </h1>

                                            <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                                                <li className="breadcrumb-item text-muted">
                                                    <Link to="/dashboard" className="text-muted text-hover-primary">
                                                        Home
                                                    </Link>
                                                </li>

                                                <li className="breadcrumb-item">
                                                    <span className="bullet bg-gray-200 w-5px h-2px"></span>
                                                </li>
                                                <li className="breadcrumb-item text-muted">
                                                    <Link to="/email" className="text-muted text-hover-primary">
                                                        Emails
                                                    </Link>

                                                </li>
                                                <li className="breadcrumb-item">
                                                    <span className="bullet bg-gray-200 w-5px h-2px"></span>
                                                </li>
                                                <li className="breadcrumb-item text-dark">Edit Email</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-xxl">
                                        <form name="normal_login" className="login-form" onSubmit={this.SubmitHandler}>
                                            <div className="row gy-5 g-xl-8">
                                                <div className="col-xl-12">
                                                    <div className="card card-xl-stretch mb-xl-8">
                                                        <div className="card-header border-0">
                                                            <h3 className="card-title fw-bolder text-dark">Email</h3>
                                                        </div>

                                                        <div className="card-body pt-0 team-form">
                                                            <div className="row">
                                                                <input type="hidden" placeholder="Title" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                                    value={email_form.title.value ? email_form.title.value : ""}
                                                                    onChange={(event) => this.inputChangeHandler(event, "title")}
                                                                />
                                                                {/* <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label required fw-bold fs-6">Title</label>

                                                                    <input placeholder="Title" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                                        value={email_form.title.value ? email_form.title.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "title")}
                                                                        />
                                                                    {!email_form.title.valid && email_form.title.onBlur_out ? <div className="error field-error">{email_form.title.error_msg}</div> : ""}
                                                                    <div className="fv-plugins-message-container invalid-feedback"></div>
                                                                </div> */}


                                                                <div className="col-lg-6 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label required fw-bold fs-6">Subject</label>

                                                                    <input placeholder="Subject" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" value={email_form.subject.value ? email_form.subject.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "subject")}
                                                                         />
                                                                    {!email_form.subject.valid && email_form.subject.onBlur_out ? <div className="error field-error">{email_form.subject.error_msg}</div> : ""}
                                                                    <div className="fv-plugins-message-container invalid-feedback"></div>
                                                                </div>

                                                                {/* <div className="col-lg-6 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label fw-bold fs-6">Day</label>
                                                                    <Select
                                                                        className="react-bootstrap-typeahead tag-outer"
                                                                        placeholder="Select a day"
                                                                        options={email_form.day.options}
                                                                        // value={email_form.dayvalue} 
                                                                        value={email_form.day.options.filter(function (option) {
                                                                            return option.value === email_form.day.value;
                                                                        })}
                                                                        onChange={newValue => this.handleChange(newValue, "day", 'dayvalue')}
                                                                    />

                                                                    {!email_form.day.valid && email_form.day.onBlur_out ? <div className="error field-error">{email_form.day.error_msg}</div> : ""}
                                                                    <div className="fv-plugins-message-container invalid-feedback"></div>
                                                                </div> */}
                                                                <div className="col-lg-12 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label required fw-bold fs-6">Content</label>
                                                                    <WysiwygEditor
                                                                        value={email_form.content.value}
                                                                        // onClick={(event) => {
                                                                        //     this.props.onSetContent(event, "content");
                                                                        // }}

                                                                        onEditorChangevalue={(event) => this.handleChangeediter(event)}

                                                                    />
                                                                    {!email_form.content.valid && email_form.content.onBlur_out ? <div className="error field-error">{email_form.content.error_msg}</div> : ""}
                                                                </div>
                                                                <div className="col-lg-12 mt-8">
                                                                    <button type="submit" id="kt_new_password_submit" className="btn btn-sm btn-primary fw-bolder me-4">
                                                                        <span className="indicator-label">Save</span>
                                                                    </button>
                                                                    <Link to="/email" className="btn btn-sm btn-light-primary fw-bolder">
                                                                        Cancel
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        
                                    </div>
                                </div>
                            </div>
							<Footer />
                        </div>
                    </div>
                    <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
                        <span className="svg-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="black" />
                                <path
                                    d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                    fill="black"
                                />
                            </svg>
                        </span>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default EditEmail;
