import React from 'react';
// import MainSideBar from '../Constant/MainSideBar';
// import NavBar from '../Constant/NavBar';
// import Cards from './Auth/Cards/Crads';
// import Tasks from './Tasks/Tasks';
import MaindashboardCont from './MaindashboardCont';
const Dashboard = () => {
    return (
        <div>
            <div className="main-wrapper">
                <div className="navbar-bg" />
                {/* <NavBar /> */}
                < MaindashboardCont />
                {/* <MainSideBar /> */}
            </div>


            




            </div>





    );
};

export default Dashboard;