import React from "react";
import { Redirect } from "react-router-dom";
import Tag from "../../Component/Tag/Tag";
class Tags extends React.Component {
    render() {
		const user_info = JSON.parse(localStorage.getItem("gpt_authuser"));
        const user_role = JSON.parse(localStorage.getItem("gpt_user_role"));
        const renderview = () => {
            if (this.props.match.path === "/tag") {
                if(user_info && user_role.MasterTag && user_role.MasterTag === "yes" ) {
                    return <Tag />;
                  
                }
                else{
                    return <Redirect to="/dashboard" />;
                }
            }        
            
        };
		
        return (
            <React.Fragment>
                {/* <Notifications /> */}
                {renderview()}
            </React.Fragment>
        );
		
    }
}
export default Tags;