import React from "react";
// import { useFormik } from "formik";
import { notification } from "antd";
import { Link, Redirect } from "react-router-dom";

import LeftSideBar from "../../Constant/LeftSideBar";
import TopBar from "../../Constant/TopBar";
import Footer from "../../Constant/Footer";
import Validations from "../Utility/Validations";
//import axios from "axios";
import { API_URL } from "../../Constant/index";



const token = localStorage.getItem("dataToken");
const user_info = JSON.parse(localStorage.getItem("gpt_authuser"));

class ZoomUploadFile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            member_form: {
                file: {
                    label: "File",
                    type: "file",
                    value: [],
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                    thumbnail_image: "/assets/media/avatars/blank.png",
                },
                url: {
                    label: "URL",
                    type: "text",
                    value: '',
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                    // thumbnail_image: "/assets/media/avatars/blank.png",
                },
                applicaionId: {
                    label: "Member ID",
                    type: "hidden",
                    value: this.props.applicaionId,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },
                created_id: {
                    value: user_info._id,
                    validations: { required: false },
                },


            },

            loader: false,
            applicaionId: this.props.applicaionId,
            isAuthenticated: false,
            chnagedatavalue:'',
            Levels: [],
            Members: [],

        };

    }

    async componentDidMount() {
    }

    inputChangeHandlerimage(event, identifier) {
        // Assuming only file
        var file = event.target.files[0];
        this.setState({
            chnagedatavalue: file,
      });
        var url = URL.createObjectURL(file);
        const update_member_form = { ...this.state.member_form };
        const form_element = { ...update_member_form[identifier] };
        form_element.thumbnail_image = url;
        form_element.value = file;
        update_member_form[identifier] = form_element;
        this.setState({ member_form: update_member_form });

    }
    inputChangeHandler(event, identifier) {
        const update_member_form = { ...this.state.member_form };
        const form_element = { ...update_member_form[identifier] };
        form_element.value = event.target.value;
        this.setState({
            chnagedatavalue: event.target.value,
        });
        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_member_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_member_form) {
            is_form_valid = update_member_form[identifier].valid && is_form_valid;
        }
        this.setState({
            member_form: update_member_form,
            is_form_valid: is_form_valid,
        });
    }
 

    SubmitHandler = async (event) => {
        event.preventDefault();
        let is_form_valid = true;
        const update_member_form = this.state.member_form;
        for (let key in update_member_form) {
            let form_element = update_member_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_member_form[key] = form_element;
        }
        this.setState({ member_form: update_member_form });
        if (is_form_valid) {
            const formdata = new FormData();
            for (let key in this.state.member_form) {
                if (key === 'file') {
                    formdata.append('file', this.state.member_form[key].value);
                }
                else {
                    formdata.append(key, this.state.member_form[key].value);
                }

            }
            
            let chnagedatavalue = this.state.chnagedatavalue;
            if (chnagedatavalue === ''){
                setTimeout(() => {
                    notification.open({
                        message: "Recorded Link",
                        description: "Please select one option Recorded link or Recorded file.",
                    });
                }, 1000);
            }
            else{
                fetch(`${API_URL}submitRecordUrl`, {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    body: formdata,
                }).then((data) => {
                    if (data.status === 200) {
                        setTimeout(() => {
                            notification.open({
                                message: "Recorded file",
                                description: "Updated Successfully.",
                            });
                        }, 1000);
                        const update_member_form = { ...this.state.member_form };
                        update_member_form.file.value = "";
                        update_member_form.url.value = "";
                        this.setState({ member_form: update_member_form });
                        // this.MemberDetail();
                    }
                    else {
                        setTimeout(() => {
                            notification.open({
                                message: "Recorded file",
                                description: "Please try again.",
                            });
                        }, 1000);
                    }
                }).catch((error) => {
                    // setTimeout(() => {
                    //     notification.open({
                    //         message: "Recorded file",
                    //         description: 'Please Try again',
                    //     });
                    // }, 1000);
                });
            }
            
         

        }
    };
    render() {
        const { member_form } = this.state;
        return (
            <React.Fragment>
                {this.state.isAuthenticated ? <Redirect to={this.state.redirect_url} /> : null}
                <div className="d-flex flex-column flex-root header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed h-100">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <LeftSideBar />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <TopBar />

                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="toolbar" id="kt_toolbar">
                                    <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                                        <div
                                            data-kt-swapper="true"
                                            data-kt-swapper-mode="prepend"
                                            data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
                                            className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
                                        >
                                            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
                                                Settings
                                                <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                                            </h1>

                                            <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                                                <li className="breadcrumb-item text-muted">
                                                    <Link to="/dashboard" className="text-muted text-hover-primary">
                                                        Home
                                                    </Link>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <span className="bullet bg-gray-200 w-5px h-2px"></span>
                                                </li>
                                               

                                                <li className="breadcrumb-item text-dark">Upload Recorded file</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-xxl">
                                        {/* <form name="normal_login" className="login-form" > */}
                                        <form name="normal_login" className="login-form" encType="multipart/form-data" onSubmit={this.SubmitHandler}>
                                            <div className="row gy-5 g-xl-8">
                                                <div className="col-xl-12">
                                                    <div className="card card-xl-stretch mb-xl-8">
                                                        <div className="card-header border-0">
                                                            <h3 className="card-title fw-bolder text-dark">Upload Recorded file</h3>
                                                        </div>

                                                        <div className="card-body pt-2">
                                                            <div className="row">
                                                                <div className="col-lg-5 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label fw-bold fs-6">Recorded Link</label>

                                                                    <input type="url" placeholder="Recorded Link" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                                        value={member_form.url.value ? member_form.url.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "url")}
                                                                        onBlur={(event) => {
                                                                            member_form.url.onBlur_out = true;
                                                                            this.setState({
                                                                                member_form: member_form,
                                                                            });
                                                                        }} />
                                                                    {!member_form.url.valid && member_form.url.onBlur_out ? <div className="error field-error">{member_form.url.error_msg}</div> : ""}
                                                                </div>
                                                                <div className="col-lg-2 fv-row fv-plugins-icon-container d-flex upload_image_or">
                                                                    <label className=" col-form-label fw-bold fs-6 m-0 p-0">OR</label> </div>
                                                                <div className="col-lg-5 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label fw-bold fs-6">Recorded file</label>

                                                                    <input accept="video/mp4,video/x-m4v,video/*" type="file" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" onChange={(event) => this.inputChangeHandlerimage(event, "file")}
                                                                        onBlur={(event) => {
                                                                            member_form.file.onBlur_out = true;
                                                                            this.setState({
                                                                                member_form: member_form,
                                                                            });
                                                                        }} />

                                                                    {!member_form.file.valid && member_form.file.onBlur_out ? <div className="error field-error">{member_form.file.error_msg}</div> : ""}
                                                                </div>  
                                                                <div className="col-lg-12 mt-8">
                                                                    <button type="submit" id="kt_new_password_submit" className="btn btn-lg btn-primary fw-bolder me-4">
                                                                        <span className="indicator-label">Save</span>
                                                                    </button>
                                                                    {/* <Link to="/member" className="btn btn-lg btn-light-primary fw-bolder">
                                                                        Cancel
                                                                    </Link> */}
                                                                </div>
                                                                <div className="col-lg-4 fv-row fv-plugins-icon-container"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <Footer />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
                        <span className="svg-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="black" />
                                <path
                                    d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                    fill="black"
                                />
                            </svg>
                        </span>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ZoomUploadFile;
