import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import { notification } from "antd";
import Select from "react-select";
// import {  updateCourses } from "../../Api/Courses/course";
// import { assignManualUser } from "../../Api/user/userapi";


// const { TextArea } = Input;

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#ffdbdb" : ""
        },
        backgroundColor: state.isSelected ? "#cc0c0c" : "",
    }),
};


function AutoAssignRepleaceMember(props) {
    const { editChapterData, AutoRepleaceMemberApplicationSubmit, getlastMemberId, isExternalMemberTypeModalVisible } = props;
    const [courseValue, setCourseValue] = useState([]);
    const [ValueId, setValueId] = useState("");
    const user_info = JSON.parse(localStorage.getItem("gpt_authuser"));
    const { applicaionId } = useParams();
    useEffect(() => {
        if (editChapterData) {            
            setCourseValue(editChapterData);
        }
    }, [editChapterData]);
    const handleChange = async (result, data) => {
        setValueId(result.value)
    };

    const SubmitHandler = async (event) => {
        event.preventDefault();
        if (ValueId != ''){
            const answer_array = ValueId.split('!!');
            const payload = {
                applicaionId: applicaionId,
                lastmemberId: answer_array[0],
                membertype: answer_array[1],
                memberId: getlastMemberId,
                type: isExternalMemberTypeModalVisible,
                created_id: user_info._id,
            };
            AutoRepleaceMemberApplicationSubmit(payload);
            setValueId('')
        }
       
    };
    return (

        <>
            <form name="normal_login" className="login-form" onSubmit={SubmitHandler}>

                <Select
                    styles={customStyles}
                    className="react-bootstrap-typeahead tag-outer"
                    placeholder="Select a User..."
                    options={courseValue}
                    value={courseValue.filter(function (option) {
                        return option.value === ValueId;
                    })}
                    onChange={handleChange}
                />
                <div className="text-center mt-6">
                    <button type="submit" className='btn btn-sm btn-primary fw-bolder'>Ok</button>
                </div>
            </form>
        </>
    );
}

export default AutoAssignRepleaceMember;
