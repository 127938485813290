import React from "react";
import { Redirect } from "react-router-dom";
import Level from "../../Component/Level/Level";
class Levels extends React.Component {
    render() {
		const user_info = JSON.parse(localStorage.getItem("gpt_authuser"));
        const user_role = JSON.parse(localStorage.getItem("gpt_user_role"));
        const renderview = () => {
            if (this.props.match.path === "/level") {
                if(user_info && user_role.MasterLevel && user_role.MasterLevel === "yes" ) {
                    return <Level />;
                  
                }
                else{
                    return <Redirect to="/dashboard" />;
                }
            }        
            
        };
		
        return (
            <React.Fragment>
                {/* <Notifications /> */}
                {renderview()}
            </React.Fragment>
        );
		
    }
}
export default Levels;