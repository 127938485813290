import React from "react";
import { notification } from "antd";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import { getUpcomingSlots, disableSlotsByTime } from "../../../Api/user/userapi";
// import Select from "react-select";
import moment from "moment-timezone";
const user_info = JSON.parse(localStorage.getItem("gpt_authuser"));
export default class DateOverrides extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            overrides_form: {
                date: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                day: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
            },
            loader: false,
            isAuthenticated: false,
            showselectdate: false,
            getstart_time: [],
            getend_time: [],
            gettimeslot: [],
            getslottime: this.props.getslottime,//[],
            setdate: '',
            getDisableSlotsdata: this.props.getDisableSlotsdata,
        };
    }
    async componentDidMount() {
        let x = 30; //minutes interval
        var times = []; // time array
        var tt = 0; // start time
        var ap = ["AM", "PM"]; // AM-PM
        let gettimeslot = [];
        for (var i = 0; tt < 24 * 60; i++) {
            var hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
            var mm = tt % 60; // getting minutes of the hour in 0-55 format
            times[i] = ("0" + (hh % 12)).slice(-2) + ":" + ("0" + mm).slice(-2) + ap[Math.floor(hh / 12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]
            tt = tt + x;
            gettimeslot.push({
                value: times[i],
                label: times[i],
            });
        }
        this.setState({
            gettimeslot: gettimeslot,
        });
    }
    async componentDidUpdate() {
        if (this.state.getslottime.length > 0 && this.props.setopupresult === 1) {
            this.setState({
                getslottime: [],
            });
            this.setState({ showselectdate: false });
            // const update_overrides_form = { ...this.state.overrides_form };
            // update_overrides_form["date"].value = '';
            // this.setState({
            //     overrides_form: update_overrides_form,
            // });
            // this.handleDateClick('');
        }
    }

    handleDateClick = (arg) => {
        this.props.setopupresults(0);
        let curerntdate = new Date();
        let clickdate = new Date(arg.date);
        let newcurerntdates = moment(curerntdate).format("YYYY-MM-DD 00:00:00");
        let newcurerntdate = new Date(newcurerntdates);
        if (clickdate.getTime() >= newcurerntdate.getTime()) {
            const update_overrides_form = { ...this.state.overrides_form };
            update_overrides_form["date"].value = arg.dateStr;
            this.setState({
                overrides_form: update_overrides_form,
            });
            this.setState({ showselectdate: true });
            const payload = {
                date: arg.dateStr,
                memberId: user_info._id,
                created_id: user_info._id,
                timezoneSearch: true
            };
            this.setState({ setdate: arg.dateStr })
            this.getUpcomingSlotsvalue(payload);
        } else {
            this.setState({ showselectdate: false });
        }
    };
    getUpcomingSlotsvalue = async (payload) => {
        try {
            const resp = await getUpcomingSlots(payload);
            if (resp && resp.data.success !== false) {
                const list = resp;
                const member_detail = list.data.response[0].slot;
                let startarray = [];
                for (let key in member_detail) {
                    let timeextend = member_detail[key].time;
                    let aftergettime = timeextend.split("-");
                    let startdate = moment.unix(aftergettime[0]).format("h:mm A");
                    let enddate = moment.unix(aftergettime[1]).format("h:mm A");
                    if (this.props.timezone && this.props.timezone.value) {
                        startdate = moment.unix(aftergettime[0]).tz(this.props.timezone.value).format("h:mm A");
                        enddate = moment.unix(aftergettime[1]).tz(this.props.timezone.value).format("h:mm A");
                    }
                    startarray.push({
                        label: startdate + ' - ' + enddate,
                        value: member_detail[key].status,
                        id: timeextend,
                    });
                }
                this.setState({
                    getslottime: startarray,
                });

            }
            else {
                let startarray = [];
                this.setState({
                    getslottime: startarray,
                });
            }
        } catch (e) {
        }
    };


    inputChangeHandler(event, identifier, index) {
        const update_email_form = { ...this.state.getslottime };
        const form_element = { ...update_email_form[index] };
        if (event.target.checked === true) {
            form_element.value = 0;

        } else {
            form_element.value = 1;
        }
        update_email_form[index] = form_element;
        const getslottime = this.state.getslottime.map((item, i) => {
            const newData = i === index ? form_element : {};

            return {
                ...item,
                ...newData
            };
        });
        this.setState({ getslottime })
        const payload = {
            date: this.state.overrides_form['date'].value,
            status: form_element.value,
            slot: form_element.id,
            memberId: user_info._id,
            created_id: user_info._id,
        };
        this.getdisableSlotsByTime(payload);

    }
    getdisableSlotsByTime = async (payload) => {
        try {
            let message = '';
            if (payload['status'] === 1) {
                message = 'Slot disabled successfully';
            }
            else {
                message = 'Slot enabled successfully'
            }
            const resp = await disableSlotsByTime(payload);
            if (resp && resp.data.success !== false) {
                setTimeout(() => {
                    notification.open({
                        message: "Availability",
                        description: message,
                    });
                });
                //const list = resp;
                this.props.getDisableSlotsdata();
            }
            else {
                setTimeout(() => {
                    notification.open({
                        message: "Availability",
                        description: message,
                    });
                });
            }
        } catch (e) {
        }
    };

    render() {
        const { overrides_form } = this.state;
        return (
            <div className="text-center mt-6 date_override">
                <FullCalendar
                    className="date_override"
                    plugins={[dayGridPlugin, interactionPlugin]}
                    initialView={'dayGridMonth'}
                    // selectable={true}
                    dateClick={this.handleDateClick}
                    validRange={false}

                // events={[
                //     {

                //         // title: "??",
                //         // allDay: true,
                //         allDay: true,
                //         start: this.state.setdate,
                //         end: this.state.setdate,
                //         //color: "red",
                //         backgroundColor: "#cc0c0c",
                //     },
                // ]}

                />
                {this.state.showselectdate === true ? (
                    <div className="row add_override_popup">
                        <span className="your_available">What hours are you available on {overrides_form.date.value ? moment(overrides_form.date.value).format("DD-MM-YYYY") : ''} ? </span>
                        <div className="col-sm-12 mb-2 mt-4 slot_overflow">
                            <div className="row">
                                {this.state.getslottime && this.state.getslottime.map((getslottimevalue, index) => (
                                    <div key={index} className="col-sm-4  mb-4">
                                        <div className="time-border">
                                            <h6 className="fs-7">{getslottimevalue.label}</h6>
                                            <div className="form-check form-switch form-check-custom form-check-solid">
                                                <input className="form-check-input" type="checkbox" value="" id="status" name="status"
                                                    checked={getslottimevalue.value !== 1 ? (true) : (false)}
                                                    onChange={(event) => this.inputChangeHandler(event, getslottimevalue.id, index)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>

                    </div>
                ) : (
                    ""
                )}
            </div>
        );
    }
}
