import React from "react";
import { Link, Redirect } from "react-router-dom";
import {notification,Spin } from "antd";
import Loader from "../Utility/Loader";
import Validations from "../Utility/Validations";
import { forgotApi } from "../../Api/auth/auth";

class Forgot extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            forgot_form: {               
                email: {
                    type: "email",
                    label: "Email",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Enter your email",
                    validations: { required: true, email: true },
                }
            },
            loading: false,
            isAuthenticated: false,
        };
    }
    inputChangeHandler(event, identifier) {
        const update_forgot_form = { ...this.state.forgot_form };
        const form_element = { ...update_forgot_form[identifier] };
        form_element.value = event.target.value;

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_forgot_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_forgot_form) {
            is_form_valid = update_forgot_form[identifier].valid && is_form_valid;
        }

        this.setState({
            forgot_form: update_forgot_form,
            is_form_valid: is_form_valid,
        });
    }
    SubmitHandler = async (event) => {
        event.preventDefault();
        let is_form_valid = true;
        const update_forgot_form = this.state.forgot_form;
        for (let key in update_forgot_form) {
            let form_element = update_forgot_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_forgot_form[key] = form_element;
        }
        this.setState({ forgot_form: update_forgot_form });

        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.forgot_form) {
                form_data[key] = this.state.forgot_form[key].value;
            }
            this.setState({ loading: true });
            const userResp = await forgotApi(form_data);
              if (userResp && userResp.data.success === true) {
                   setTimeout(() => {
                        notification.open({
                            message: "Forgot Password",
                            description: "Please check your email to reset your password.",
                        });
                    }, 1000);
                  this.setState({
                      isAuthenticated: true,
                      redirect_url: "/",
                  });
                  this.setState({ loading: false });
              }
              else{
                  this.setState({ Loading: true });
                    setTimeout(() => {
                        notification.open({
                            message: "Forgot Password",
                            description: userResp.data.message,
                        });
                    }, 1000);
                  this.setState({ loading: false });
              }
        }
    };
    render() {
        const { forgot_form } = this.state;
    return (
        <React.Fragment>  
            {this.state.isAuthenticated ? <Redirect to={this.state.redirect_url} /> : null}     
        <div className="bg-body h-100 login-screen">
            <div className="d-flex flex-column flex-root h-100">
                <div
                        className="authentication_img d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
                        style={{ backgroundImage: `url(/assets/media/logos/white-bg.png)` }}
                >
                    <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
                       <Link to="/" className="mb-12">
                            <img alt="Logo" src="/assets/media/logos/logo.png" className="h-40px mt-20" />
                        </Link>
                        <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
                                <Spin spinning={this.state.loading}>
                            <form className="form w-100" noValidate="noValidate" id="kt_password_reset_form" onSubmit={this.SubmitHandler}>
                                <div className="text-center mb-10">
                                    <h1 className="text-dark mb-3">Forgot Password ?</h1>
                                    <div className="text-gray-400 fw-bold fs-4">Enter your email to reset your password.</div>
                                </div>
                                <div className="fv-row mb-10">
                                    <label className="form-label fw-bolder text-gray-900 fs-6">Email</label>
                                    <input
                                            type={forgot_form.email.type}
                                            className="form-control form-control-solid"
                                            placeholder={forgot_form.email.placeholder}
                                            value={forgot_form.email.value ? forgot_form.email.value : ""}
                                            onChange={(event) => this.inputChangeHandler(event, "email")}
                                            onBlur={(event) => {
                                                forgot_form.email.onBlur_out = true;
                                                this.setState({
                                                    forgot_form: forgot_form,
                                                });
                                            }}
                                        />
                                        {!forgot_form.email.valid && forgot_form.email.onBlur_out ? <div className="error field-error">{forgot_form.email.error_msg}</div> : ""}
                                </div>
                                <div className="d-flex flex-wrap justify-content-center pb-lg-0">
                                    <button type="submit" id="kt_password_reset_submit" className="btn btn-lg btn-primary fw-bolder me-4">
                                        <span className="indicator-label">Submit</span>
                                        <span className="indicator-progress">
                                            Please wait...
                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                    </button>
                                </div>
                            </form>
                            </Spin>
                        </div>
                    </div>
                    <div className="d-flex flex-center flex-column-auto p-10">
                        <div className="d-flex align-items-center fw-bold fs-6">
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </React.Fragment>
    );
}
};

export default Forgot;