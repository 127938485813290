import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { SITE_URL } from "../../../Constant/index";

function ApplicationArchive(props) {
    var [copySuccess, setCopySuccess] = useState('Copy');
    const { applicaionId } = useParams();
    const { setIsSendEmailModalVisible, editChapterData, messagedata, ApplicationSendEmailSubmit, chapterId, } = props;
    useEffect(() => {
        if (editChapterData) {
        }
    }, [editChapterData]);


    const SubmitHandler = async (event) => {
        event.preventDefault();
        ApplicationSendEmailSubmit(chapterId);
    };
    const handleCancel = () => {
        setIsSendEmailModalVisible(false);
    };
    function copyToClipboard(e) {
        console.log('chapterId====', applicaionId)
        navigator.clipboard.writeText(SITE_URL + 'application-progress/' + applicaionId)
        e.target.focus();
        setCopySuccess('Copied!');
    };
    return (
        <form name="normal_login" className="login-form" onSubmit={SubmitHandler}>

            <h4>{messagedata}</h4>
            <div className="model text-right mt-3">
                <span className="btn btn-sm btn-light-primary fw-bolder me-2" 
                    onClick={copyToClipboard}
                    
                    // onClick={() => }
                >{copySuccess}</span>
                
                <button type="submit" className='btn btn-sm btn-primary fw-bolder'>Ok</button>
                <span className="btn btn-sm btn-light-primary fw-bolder cancel_popup" onClick={() => handleCancel()} >Cancel</span>
            </div>
        </form>
    );
}

export default ApplicationArchive;
