import React, { useEffect, useState, useCallback } from "react";
import { getApplicationList, getApplicationListByUser, getflaggedApplication, getflaggedApplicationByUser, countRoundTwo, countRoundOne, getlogsByUser, getTasksByUser, getInterviewsByUser } from "../Api/user/userapi";
import Moment from "moment";
// import FullCalendar from "@fullcalendar/react"; // must go before plugins
// import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
// import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
// import timeGridPlugin from "@fullcalendar/timegrid"; // needed for timeClick

import { Link } from "react-router-dom";
import LeftSideBar from "../Constant/LeftSideBar";
import TopBar from "../Constant/TopBar";
import Footer from "../Constant/Footer";
const MaindashboardCont = () => {
    //const user_info = JSON.parse(localStorage.getItem("gpt_authuser"));
    const user_role = JSON.parse(localStorage.getItem("gpt_user_role"));
    const user_info = JSON.parse(localStorage.getItem("gpt_authuser"));
    const [AllApplication, setAllApplication] = useState(0);
    const [AllFlaggedApplication, setAllFlaggedApplication] = useState(0);
    const [Allrount1count, setround1count] = useState(0);
    const [Allrount2count, setround2count] = useState(0);
    const [memberId, setmemberId] = useState("");
    // const [Assignuserdata, setAssignuserdata] = useState([]);
    const [ActivityData, setActivityDataa] = useState([]);
    const [TasksData, setTasksData] = useState([]);
    const [CalendarDate, setCalendarDate] = useState([]);
    //const [endDate, setendDate] = useState("");
    //const [startDate, setstartDate] = useState("");

    const GetcountRoundOne = useCallback(async () => {
        try {
            if (user_role && user_role.Allapplication !== "yes") {
                const payload = {
                    search: "",
                    memberId: user_info._id,
                };
                const resp = await countRoundOne(payload);
                if (resp && resp !== "" && resp.data.success === true) {
                    const list = resp;
                    setround1count(list.data.data);
                }
            } 
            else if ( user_role && user_role.Allapplication === "yes") {
                // else {
                const payload = {
                    search: "",
                    memberId: "",
                };
                const resp = await countRoundOne(payload);
                if (resp && resp !== "" && resp.data.success === true) {
                    const list = resp;
                    setround1count(list.data.data);
                }
            }
        } catch (e) { }
    }, [memberId]);
    const GetcountRoundTwo = useCallback(async () => {
        try {
            if (user_info && user_info.usertype !== "admin" && user_role && user_role.Allapplication !== "yes") {
                const payload = {
                    search: "",
                    memberId: user_info._id,
                };
                const resp = await countRoundTwo(payload);
                if (resp && resp !== "" && resp.data.success === true) {
                    const list = resp;
                    setround2count(list.data.data);
                }
            } else {
                const payload = {
                    search: "",
                    memberId: "",
                };
                const resp = await countRoundTwo(payload);
                if (resp && resp !== "" && resp.data.success === true) {
                    const list = resp;
                    setround2count(list.data.data);
                }
            }
        } catch (e) { }
    }, [memberId]);
    const GetAllApplication = useCallback(async () => {
        try {
            if (user_info && user_info.usertype !== "admin" && user_role && user_role.Allapplication !== "yes") {
                const payload = {
                    search: "",
                    memberId: user_info._id,
                };
                const resp = await getApplicationListByUser(payload);
                if (resp && resp !== "" && resp.data.success === true) {
                    const list = resp;
                    setAllApplication(list.data.count);
                }
            } else {
                const payload = {
                    search: "",
                };
                const resp = await getApplicationList(payload);
                if (resp && resp !== "" && resp.data.success === true) {
                    const list = resp;
                    setAllApplication(list.data.count);
                }
            }
        } catch (e) { }
    }, [memberId]);
    const GetAllFlaggedApplication = useCallback(async () => {
        try {
            if (user_info && user_info.usertype !== "admin" && user_role && user_role.Allflaggedapplication !== "yes") {
                const payload = {
                    search: "",
                    memberId: user_info._id,
                };
                const resp = await getflaggedApplicationByUser(payload);
                if (resp && resp !== "" && resp.data.success === true) {
                    const list = resp;
                    setAllFlaggedApplication(list.data.count);
                }
            } else {
                const payload = {
                    search: "",
                };
                const resp = await getflaggedApplication(payload);
                if (resp && resp !== "" && resp.data.success === true) {
                    const list = resp;
                    setAllFlaggedApplication(list.data.count);
                }
            }
        } catch (e) { }
    }, [memberId]);

    const ActivitygetlogsByUser = useCallback(async () => {
        try {
            var usermemberid = "";
            if (user_info && user_info.usertype !== "admin" && user_role && user_role.Allapplication !== "yes") {
                usermemberid = user_info._id;
            }
            const payload = {
                memberId: usermemberid, //'61fbd05149549eef5596d2a3',
            };
            const resp = await getlogsByUser(payload);
            const list = resp;
            // setActivityDataa(result);
            if (resp && resp !== "" && resp.data.success === true) {
                setActivityDataa(list.data.data);
            }
        } catch (e) { }
    }, [memberId]);

    const TaskgetTasksByUser = useCallback(async () => {
        try {
            var usermemberid = "";
            if (user_info && user_info.usertype !== "admin" && user_role && user_role.Allapplication !== "yes") {
                usermemberid = user_info._id;
            }
            const payload = {
                memberId: usermemberid, //'61fbd05149549eef5596d2a3',
            };
            const resp = await getTasksByUser(payload);
            // var result = [];
            // setTasksData(result);
            if (resp && resp !== "" && resp.data.success === true) {
                const list = resp;
                setTasksData(list.data.data);
            }
        } catch (e) { }
    }, [memberId]);

    const calandergetInterviewsByUser = useCallback(
        async (startDate, endDate) => {
            try {
                var usermemberid = "";
                if (user_info && user_info.usertype !== "admin" && user_role && user_role.Allapplication !== "yes") {
                    usermemberid = user_info._id;
                } else {
                    usermemberid = memberId;
                }
                const payload = {
                    memberId: usermemberid, 
                };
                const resp = await getInterviewsByUser(payload);
                // var result = [];
                // setActivityDataa(result);
                if (resp && resp !== "" && resp.data.success === true) {
                    const list = resp;
                    // console.log('result===', list.data.data)
                    const resultdata = list.data.data;
                    let application_accepted_assign = [];
                    setCalendarDate(resultdata);
                }
            } catch (e) { }
        },
        [memberId]
    );

    useEffect(() => {
        GetAllFlaggedApplication();
        GetAllApplication();
        GetcountRoundTwo();
        GetcountRoundOne();
        ActivitygetlogsByUser();
        TaskgetTasksByUser();
        calandergetInterviewsByUser();

        if (user_info && user_info.usertype !== "admin" && user_role && user_role.Allapplication !== "yes") {
            setmemberId(user_info._id);
        }
    }, [GetAllApplication, GetAllFlaggedApplication, GetcountRoundTwo, GetcountRoundOne, ActivitygetlogsByUser, TaskgetTasksByUser, calandergetInterviewsByUser]);

    // function inputChangeHandler(event) {
    //     setmemberId(event.target.value);
    // }

    return (
        <div className="d-flex flex-column flex-root header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed h-100">
            <div className="page d-flex flex-row flex-column-fluid">
                <LeftSideBar />
                <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                    <TopBar />

                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                        <div className="toolbar" id="kt_toolbar">
                            <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                                <div
                                    data-kt-swapper="true"
                                    data-kt-swapper-mode="prepend"
                                    data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
                                    className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
                                >
                                    <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">Dashboard</h1>
                                </div>
                            </div>
                        </div>

                        <div className="post d-flex flex-column-fluid" id="kt_post">
                            <div id="kt_content_container" className="container-xxl">
                                <div className="row g-5 g-xl-8 mt-0">
                                    {user_role && user_role.dashboardApplications && user_role.dashboardApplications === "yes" && (
                                        <div className="col-xl-3 mt-0">
                                            {((user_role && user_role.applicationAccept && user_role.applicationAccept === "yes") ||
                                                (user_role && user_role.applicationReject && user_role.applicationReject === "yes") ||
                                                (user_role && user_role.applicationUrl && user_role.applicationUrl === "yes") ||
                                                (user_role && user_role.applicationComment && user_role.applicationComment === "yes") ||
                                                (user_role && user_role.applicationAssign && user_role.applicationAssign === "yes") || (user_role && user_role.Allapplication && user_role.Allapplication === "yes")) ? (
                                                    <>
                                            <Link to="/application">
                                                <span className="card bg-danger hoverable card-xl-stretch mb-5 mb-xl-8">
                                                    <div className="card-body">
                                                        <span className="svg-icon svg-icon-3x svg-icon-white d-block ">
                                                            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                    <rect x="0" y="0" width="24" height="24" />
                                                                    <rect fill="#000000" opacity="0.3" x="4" y="4" width="4" height="4" rx="1" />
                                                                    <path
                                                                        d="M5,10 L7,10 C7.55228475,10 8,10.4477153 8,11 L8,13 C8,13.5522847 7.55228475,14 7,14 L5,14 C4.44771525,14 4,13.5522847 4,13 L4,11 C4,10.4477153 4.44771525,10 5,10 Z M11,4 L13,4 C13.5522847,4 14,4.44771525 14,5 L14,7 C14,7.55228475 13.5522847,8 13,8 L11,8 C10.4477153,8 10,7.55228475 10,7 L10,5 C10,4.44771525 10.4477153,4 11,4 Z M11,10 L13,10 C13.5522847,10 14,10.4477153 14,11 L14,13 C14,13.5522847 13.5522847,14 13,14 L11,14 C10.4477153,14 10,13.5522847 10,13 L10,11 C10,10.4477153 10.4477153,10 11,10 Z M17,4 L19,4 C19.5522847,4 20,4.44771525 20,5 L20,7 C20,7.55228475 19.5522847,8 19,8 L17,8 C16.4477153,8 16,7.55228475 16,7 L16,5 C16,4.44771525 16.4477153,4 17,4 Z M17,10 L19,10 C19.5522847,10 20,10.4477153 20,11 L20,13 C20,13.5522847 19.5522847,14 19,14 L17,14 C16.4477153,14 16,13.5522847 16,13 L16,11 C16,10.4477153 16.4477153,10 17,10 Z M5,16 L7,16 C7.55228475,16 8,16.4477153 8,17 L8,19 C8,19.5522847 7.55228475,20 7,20 L5,20 C4.44771525,20 4,19.5522847 4,19 L4,17 C4,16.4477153 4.44771525,16 5,16 Z M11,16 L13,16 C13.5522847,16 14,16.4477153 14,17 L14,19 C14,19.5522847 13.5522847,20 13,20 L11,20 C10.4477153,20 10,19.5522847 10,19 L10,17 C10,16.4477153 10.4477153,16 11,16 Z M17,16 L19,16 C19.5522847,16 20,16.4477153 20,17 L20,19 C20,19.5522847 19.5522847,20 19,20 L17,20 C16.4477153,20 16,19.5522847 16,19 L16,17 C16,16.4477153 16.4477153,16 17,16 Z"
                                                                        fill="#000000"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        <div className="text-white fw-bolder fs-2 mb-2 mt-5">{AllApplication}</div>
                                                        <div className="fw-bold text-white fs-6">Numbers of Applications</div>
                                                    </div>
                                                </span>
                                            </Link>
                                            </>
                                                ):(
                                                    <span className="card bg-danger hoverable card-xl-stretch mb-5 mb-xl-8">
                                                        <div className="card-body">
                                                            <span className="svg-icon svg-icon-3x svg-icon-white d-block ">
                                                                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                        <rect x="0" y="0" width="24" height="24" />
                                                                        <rect fill="#000000" opacity="0.3" x="4" y="4" width="4" height="4" rx="1" />
                                                                        <path
                                                                            d="M5,10 L7,10 C7.55228475,10 8,10.4477153 8,11 L8,13 C8,13.5522847 7.55228475,14 7,14 L5,14 C4.44771525,14 4,13.5522847 4,13 L4,11 C4,10.4477153 4.44771525,10 5,10 Z M11,4 L13,4 C13.5522847,4 14,4.44771525 14,5 L14,7 C14,7.55228475 13.5522847,8 13,8 L11,8 C10.4477153,8 10,7.55228475 10,7 L10,5 C10,4.44771525 10.4477153,4 11,4 Z M11,10 L13,10 C13.5522847,10 14,10.4477153 14,11 L14,13 C14,13.5522847 13.5522847,14 13,14 L11,14 C10.4477153,14 10,13.5522847 10,13 L10,11 C10,10.4477153 10.4477153,10 11,10 Z M17,4 L19,4 C19.5522847,4 20,4.44771525 20,5 L20,7 C20,7.55228475 19.5522847,8 19,8 L17,8 C16.4477153,8 16,7.55228475 16,7 L16,5 C16,4.44771525 16.4477153,4 17,4 Z M17,10 L19,10 C19.5522847,10 20,10.4477153 20,11 L20,13 C20,13.5522847 19.5522847,14 19,14 L17,14 C16.4477153,14 16,13.5522847 16,13 L16,11 C16,10.4477153 16.4477153,10 17,10 Z M5,16 L7,16 C7.55228475,16 8,16.4477153 8,17 L8,19 C8,19.5522847 7.55228475,20 7,20 L5,20 C4.44771525,20 4,19.5522847 4,19 L4,17 C4,16.4477153 4.44771525,16 5,16 Z M11,16 L13,16 C13.5522847,16 14,16.4477153 14,17 L14,19 C14,19.5522847 13.5522847,20 13,20 L11,20 C10.4477153,20 10,19.5522847 10,19 L10,17 C10,16.4477153 10.4477153,16 11,16 Z M17,16 L19,16 C19.5522847,16 20,16.4477153 20,17 L20,19 C20,19.5522847 19.5522847,20 19,20 L17,20 C16.4477153,20 16,19.5522847 16,19 L16,17 C16,16.4477153 16.4477153,16 17,16 Z"
                                                                            fill="#000000"
                                                                        />
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                            <div className="text-white fw-bolder fs-2 mb-2 mt-5">{AllApplication}</div>
                                                            <div className="fw-bold text-white fs-6">Numbers of Applications</div>
                                                        </div>
                                                    </span> 
                                                )}
                                        </div>
                                    )}
                                    {/* {(user_role && user_role.dashboardQualified && user_role.dashboardQualified === "yes") &&
                                    (
                                    <div className="col-xl-3  mt-0">
                                            <Link to="/application">
                                        <span className="card bg-primary hoverable card-xl-stretch mb-5 mb-xl-8">
                                            <div className="card-body">
                                                <span className="svg-icon svg-icon-3x svg-icon-white d-block ">
                                                    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24"></rect>
                                                            <rect fill="#000000" x="4" y="4" width="7" height="7" rx="1.5"></rect>
                                                            <path
                                                                d="M5.5,13 L9.5,13 C10.3284271,13 11,13.6715729 11,14.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,14.5 C4,13.6715729 4.67157288,13 5.5,13 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,9.5 C20,10.3284271 19.3284271,11 18.5,11 L14.5,11 C13.6715729,11 13,10.3284271 13,9.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z M14.5,13 L18.5,13 C19.3284271,13 20,13.6715729 20,14.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,14.5 C13,13.6715729 13.6715729,13 14.5,13 Z"
                                                                fill="#000000"
                                                                opacity="0.3"
                                                            ></path>
                                                        </g>
                                                    </svg>
                                                </span>
                                                <div className="text-white fw-bolder fs-2 mb-2 mt-5">23</div>
                                                <div className="fw-bold text-white fs-6">Qualified Applications</div>
                                            </div>
                                        </span>
                                        </Link>
                                    </div>
                                    )} */}
                                    {user_role && user_role.dashboardRound1 && user_role.dashboardRound1 === "yes" && (
                                        <div className="col-xl-3 mt-0">
                                            {((user_role && user_role.applicationAccept && user_role.applicationAccept === "yes") ||
                                                (user_role && user_role.applicationReject && user_role.applicationReject === "yes") ||
                                                (user_role && user_role.applicationUrl && user_role.applicationUrl === "yes") ||
                                                (user_role && user_role.applicationComment && user_role.applicationComment === "yes") ||
                                                (user_role && user_role.applicationAssign && user_role.applicationAssign === "yes") || (user_role && user_role.Allapplication && user_role.Allapplication === "yes")) ? (
                                                    <>
                                                    <Link to="/application/?applicationtype=round1">
                                                        <span className="card bg-success hoverable card-xl-stretch mb-5 mb-xl-8">
                                                    <div className="card-body">
                                                        <span className="svg-icon svg-icon-3x svg-icon-white d-block ">
                                                            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                    <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                                    <path
                                                                        d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                                                                        fill="#000000"
                                                                        fillRule="nonzero"
                                                                        opacity="0.3"
                                                                    ></path>
                                                                    <path
                                                                        d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                                                                        fill="#000000"
                                                                        fillRule="nonzero"
                                                                    ></path>
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        <div className="text-white fw-bolder fs-2 mb-2 mt-5">{Allrount1count}</div>
                                                        <div className="fw-bold text-white fs-6">Selected Candidates (Round 1)</div>
                                                    </div>
                                                </span>
                                            </Link>
                                            </>
                                                ):(
                                                    <span className="card bg-success hoverable card-xl-stretch mb-5 mb-xl-8">
                                                        <div className="card-body">
                                                            <span className="svg-icon svg-icon-3x svg-icon-white d-block ">
                                                                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                        <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                                        <path
                                                                            d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                                                                            fill="#000000"
                                                                            fillRule="nonzero"
                                                                            opacity="0.3"
                                                                        ></path>
                                                                        <path
                                                                            d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                                                                            fill="#000000"
                                                                            fillRule="nonzero"
                                                                        ></path>
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                            <div className="text-white fw-bolder fs-2 mb-2 mt-5">{Allrount1count}</div>
                                                            <div className="fw-bold text-white fs-6">Selected Candidates (Round 1)</div>
                                                        </div>
                                                    </span>
                                                )}
                                            
                                                
                                        </div>
                                    )}
                                    {user_role && user_role.dashboardRound2 && user_role.dashboardRound2 === "yes" && (
                                        <div className="col-xl-3 mt-0">
                                            {((user_role && user_role.applicationAccept && user_role.applicationAccept === "yes") ||
                                                (user_role && user_role.applicationReject && user_role.applicationReject === "yes") ||
                                                (user_role && user_role.applicationUrl && user_role.applicationUrl === "yes") ||
                                                (user_role && user_role.applicationComment && user_role.applicationComment === "yes") ||
                                                (user_role && user_role.applicationAssign && user_role.applicationAssign === "yes") || (user_role && user_role.Allapplication && user_role.Allapplication === "yes")) ? (
                                            <Link to="/application/?applicationtype=round2">
                                                <span className="card bg-info hoverable card-xl-stretch mb-5 mb-xl-8">
                                                    <div className="card-body">
                                                        <span className="svg-icon svg-icon-3x svg-icon-white d-block">
                                                            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                    <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                                    <path
                                                                        d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                                                                        fill="#000000"
                                                                        fillRule="nonzero"
                                                                        opacity="0.3"
                                                                    ></path>
                                                                    <path
                                                                        d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                                                                        fill="#000000"
                                                                        fillRule="nonzero"
                                                                    ></path>
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        <div className="text-white fw-bolder fs-2 mb-2 mt-5">{Allrount2count}</div>
                                                        <div className="fw-bold text-white fs-6">Selected Candidates (Round 2)</div>
                                                    </div>
                                                </span>
                                            </Link>
                                                ):(
                                                    <span className="card bg-info hoverable card-xl-stretch mb-5 mb-xl-8">
                                                        <div className="card-body">
                                                            <span className="svg-icon svg-icon-3x svg-icon-white d-block">
                                                                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                        <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                                        <path
                                                                            d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                                                                            fill="#000000"
                                                                            fillRule="nonzero"
                                                                            opacity="0.3"
                                                                        ></path>
                                                                        <path
                                                                            d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                                                                            fill="#000000"
                                                                            fillRule="nonzero"
                                                                        ></path>
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                            <div className="text-white fw-bolder fs-2 mb-2 mt-5">{Allrount2count}</div>
                                                            <div className="fw-bold text-white fs-6">Selected Candidates (Round 2)</div>
                                                        </div>
                                                    </span>
                                                )}
                                        </div>
                                    )}
                                    {/* {(user_role && user_role.dashboardRound3 && user_role.dashboardRound3 === "yes") &&
                                    (
                                    <div className="col-xl-3 mt-0">
                                            <Link to="/application">
									<span className="card bg-one1 hoverable card-xl-stretch mb-5 mb-xl-8">
										<div className="card-body">
											<span className="svg-icon svg-icon-3x svg-icon-white d-block">
																<svg  width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																	<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
																		<polygon points="0 0 24 0 24 24 0 24"></polygon>
																		<path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3"></path>
																		<path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero"></path>
																	</g>
																</svg>
															</span>
											<div className="text-white fw-bolder fs-2 mb-2 mt-5">37</div>
											<div className="fw-bold text-white fs-6">Selected Candidates (Round 3)</div>
										</div>
									</span>
                                    </Link>
								</div>
                                )} */}
                                    {/* {(user_role && user_role.dashboardRound4 && user_role.dashboardRound4 === "yes") &&
                                    (
								<div className="col-xl-3 mt-0">
                                            <Link to="/application">
									<span className="card bg-new hoverable card-xl-stretch mb-5 mb-xl-8">
										<div className="card-body">
											<span className="svg-icon svg-icon-3x svg-icon-white d-block">
																<svg  width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																	<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
																		<polygon points="0 0 24 0 24 24 0 24"></polygon>
																		<path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3"></path>
																		<path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero"></path>
																	</g>
																</svg>
															</span>
											<div className="text-white fw-bolder fs-2 mb-2 mt-5">37</div>
											<div className="fw-bold text-white fs-6">Selected Candidates (Round 4)</div>
										</div>
									</span>
                                    </Link>
								</div>
                                    )} */}
                                    {user_role && user_role.dashboardFlagged && user_role.dashboardFlagged === "yes" && (
                                        <div className="col-xl-3 mt-0">
                                            {((user_role && user_role.flaggedView && user_role.flaggedView === "yes") || (user_role && user_role.flaggedAccept && user_role.flaggedAccept === "yes") || (user_role && user_role.flaggedReject && user_role.flaggedReject === "yes")) ?(
                                                <Link to="/flagged-application">
                                                    <span className="card bg-warning hoverable card-xl-stretch mb-5 mb-xl-8">
                                                        <div className="card-body">
                                                            <span className="svg-icon svg-icon-3x svg-icon-white d-block">
                                                                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                        <rect x="0" y="0" width="24" height="24"></rect>
                                                                        <path
                                                                            d="M5.5,4 L9.5,4 C10.3284271,4 11,4.67157288 11,5.5 L11,6.5 C11,7.32842712 10.3284271,8 9.5,8 L5.5,8 C4.67157288,8 4,7.32842712 4,6.5 L4,5.5 C4,4.67157288 4.67157288,4 5.5,4 Z M14.5,16 L18.5,16 C19.3284271,16 20,16.6715729 20,17.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,17.5 C13,16.6715729 13.6715729,16 14.5,16 Z"
                                                                            fill="#000000"
                                                                        ></path>
                                                                        <path
                                                                            d="M5.5,10 L9.5,10 C10.3284271,10 11,10.6715729 11,11.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,11.5 C4,10.6715729 4.67157288,10 5.5,10 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,12.5 C20,13.3284271 19.3284271,14 18.5,14 L14.5,14 C13.6715729,14 13,13.3284271 13,12.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z"
                                                                            fill="#000000"
                                                                            opacity="0.3"
                                                                        ></path>
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                            <div className="text-white fw-bolder fs-2 mb-2 mt-5">{AllFlaggedApplication}</div>
                                                            <div className="fw-bold text-white fs-6">Flagged Applications</div>
                                                        </div>
                                                    </span>
                                                </Link>
                                            ):(
                                                    <span className="card bg-warning hoverable card-xl-stretch mb-5 mb-xl-8">
                                                        <div className="card-body">
                                                            <span className="svg-icon svg-icon-3x svg-icon-white d-block">
                                                                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                        <rect x="0" y="0" width="24" height="24"></rect>
                                                                        <path
                                                                            d="M5.5,4 L9.5,4 C10.3284271,4 11,4.67157288 11,5.5 L11,6.5 C11,7.32842712 10.3284271,8 9.5,8 L5.5,8 C4.67157288,8 4,7.32842712 4,6.5 L4,5.5 C4,4.67157288 4.67157288,4 5.5,4 Z M14.5,16 L18.5,16 C19.3284271,16 20,16.6715729 20,17.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,17.5 C13,16.6715729 13.6715729,16 14.5,16 Z"
                                                                            fill="#000000"
                                                                        ></path>
                                                                        <path
                                                                            d="M5.5,10 L9.5,10 C10.3284271,10 11,10.6715729 11,11.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,11.5 C4,10.6715729 4.67157288,10 5.5,10 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,12.5 C20,13.3284271 19.3284271,14 18.5,14 L14.5,14 C13.6715729,14 13,13.3284271 13,12.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z"
                                                                            fill="#000000"
                                                                            opacity="0.3"
                                                                        ></path>
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                            <div className="text-white fw-bolder fs-2 mb-2 mt-5">{AllFlaggedApplication}</div>
                                                            <div className="fw-bold text-white fs-6">Flagged Applications</div>
                                                        </div>
                                                    </span>
                                            )}
                                            
                                        </div>
                                    )}
                                </div>

                                <div className="row gy-5 g-xl-8">
                                    <div className="col-xl-12">
                                        <div className="card card-xl-stretch mb-8">
                                            <div className="card-header border-0">
                                                <h3 className="card-title fw-bolder text-dark">Activities</h3>
                                            </div>

                                            <div className="card-body pt-2 dashboard_activity">
                                                {ActivityData && ActivityData.length > 0 ? (
                                                    <>
                                                        {ActivityData.map((item, idx6) => (
                                                          
                                                                <div className="d-flex align-items-center mb-4" key={idx6}>
                                                                    <div className="form-check form-check-custom form-check-solid me-5">
                                                                        <div className="cursor-pointer symbol symbol-30px reply_user_pic">
                                                                            <img src={item.CreatedImage ? item.CreatedImage : "/assets/media/avatars/blank.png"} alt="user" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex-grow-1">
                                                                    <span className="text-gray-800 fs-7" dangerouslySetInnerHTML={{ __html: item.content }} />
                                                                        {/* <span className="text-gray-800 fs-7">{item.content}</span> */}
                                                                    </div>
                                                                    <span className="badge badge-light fs-8 fw-bolder">{item.createdAt ? Moment(item.createdAt).format("DD MMM YYYY h:mm a") : ""}</span>
                                                                </div>
                                                            
                                                        ))}
                                                    </>
                                                ) : (
                                                    <p>No records found</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row gy-5 g-xl-8">
                                    <div className="col-xl-12">
                                        <div className="card card-xl-stretch mb-8">
                                            <div className="card-header border-0">
                                                <h3 className="card-title fw-bolder text-dark">Tasks</h3>
                                            </div>

                                            <div className="card-body pt-2 dashboard_activity">
                                                {TasksData && TasksData.length > 0 ? (
                                                    <>
                                                        {TasksData.map((Taskitem, idx67) => (
                                                            <div key={idx67}>
                                                                {Taskitem.status !== "1" && (
                                                                    <>
                                                                        {Taskitem.type === "assign_user" && (
                                                                           
                                                                                <div className="d-flex align-items-center mb-4" key={idx67}>
                                                                                    {Taskitem.status === "2" ? (
                                                                                        <span className="bullet bullet-vertical h-40px bg-danger"></span>
                                                                                    ) : (
                                                                                        <span className="bullet bullet-vertical h-40px bg-success"></span>
                                                                                    )}
                                                                                    <div className="form-check form-check-custom form-check-solid mx-5">
                                                                                        <div className="cursor-pointer symbol symbol-30px reply_user_pic">
                                                                                            <img src={Taskitem.UserImage ? Taskitem.UserImage : "/assets/media/avatars/blank.png"} alt="user" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="flex-grow-1">
                                                                                        <span className="text-gray-800 fs-7">
                                                                                            {Taskitem.UserName} have been assigned the application ({Taskitem.TagName}) of{" "}
                                                                                        <Link to={`/application-detail/${Taskitem.applicationid}`}>{Taskitem.CandidateName}</Link>
                                                                                        </span>
                                                                                    </div>
                                                                                    <span className="badge badge-light fs-8 fw-bolder">{Taskitem.createdAt ? Moment(Taskitem.createdAt).format("DD MMM YYYY h:mm a") : ""}</span>
                                                                                </div>
                                                                            
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <p>No records found</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row gy-5 g-xl-8">
                                    <div className="col-xl-12">
                                        <div className="card card-xl-stretch mb-8">
                                            <div className="card-header border-0">
                                                <h3 className="card-title fw-bolder text-dark">Interviews</h3>
                                            </div>

                                            <div className="card-body pt-2 dashboard_activity">
                                                {CalendarDate && CalendarDate.length > 0 ? (
                                                    <>
                                                        {CalendarDate.map((Calendaritem, idx69) => (
                                                            
                                                                <div className="d-flex align-items-center mb-4" key={idx69}>
                                                                    <div className="form-check form-check-custom form-check-solid me-5">
                                                                        <div className="cursor-pointer symbol symbol-30px reply_user_pic">
                                                                            <img src={Calendaritem.UserImage ? Calendaritem.UserImage : "/assets/media/avatars/blank.png"} alt="user" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex-grow-1">
                                                                        <span className="text-gray-800 fs-7">
                                                                            {Calendaritem.UserName} have been scheduled an interview on {Calendaritem.date}{" "}
                                                                        {Calendaritem.time} for application({Calendaritem.TagName}) of <Link to={`/application-detail/${Calendaritem.applicationid}`}>{Calendaritem.appname}</Link>
                                                                        </span>
                                                                    </div>
                                                                    <span className="badge badge-light fs-8 fw-bolder">{Calendaritem.createdAt ? Moment(Calendaritem.createdAt).format("DD MMM YYYY h:mm a") : ""}</span>
                                                                </div>
                                                            
                                                        ))}
                                                    </>
                                                ) : (
                                                    <p>No records found</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
            <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
                <span className="svg-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="black" />
                        <path
                            d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                            fill="black"
                        />
                    </svg>
                </span>
            </div>
        </div>
    );
};

export default MaindashboardCont;
