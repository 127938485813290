import React, { useEffect } from "react";
// import { Link, useParams } from "react-router-dom";

function AutoAssignAdmin(props) {
    const user_info = JSON.parse(localStorage.getItem("gpt_authuser"));
    const { setIsAutoAcceptModalVisible, editChapterData, messagedata, MenualAutoAssignAdminApplicationSubmit, chapterId, isExternalMemberTypeModalVisible } = props;
    useEffect(() => {
        if (editChapterData) {
        }
    }, [editChapterData]);

    const SubmitHandler = async (event) => {
        event.preventDefault();
        const payload = {
            applicaionId: chapterId,
            memberId: user_info._id,
            type: isExternalMemberTypeModalVisible,
            created_id: user_info._id,
        };
        MenualAutoAssignAdminApplicationSubmit(payload);
    };
    const handleCancel = () => {
        setIsAutoAcceptModalVisible(false);
    };
    return (
        <form name="normal_login" className="login-form" onSubmit={SubmitHandler}>

            <h4>{messagedata}</h4>
            <div className="model text-right mt-3">
                <button type="submit" className='btn btn-sm btn-primary fw-bolder'>Ok</button>
                <span className="btn btn-sm btn-light-primary fw-bolder cancel_popup" onClick={() => handleCancel()} >Cancel</span>
            </div>
        </form>
    );
}

export default AutoAssignAdmin;
