import React, { useEffect, useState } from "react";
import Validations from "../../Utility/Validations";
import { notification } from "antd";
import Moment from "moment";
import Select from "react-select";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getUpcomingSlots } from "../../../Api/user/userapi";

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#ffdbdb" : ""
        },
        backgroundColor: state.isSelected ? "#cc0c0c" : "",
    }),
};

function InterviewRoundTwoSchelude(props) {
    const [application_form, setapplication_form] = useState([]);
    const [courseValue, setCourseValue] = useState([]);
    const [ValueId, setValueId] = useState("");
    const [courseValueTwo, setCourseValueTwo] = useState([]);
    const [ValueIdTwo, setValueIdTwo] = useState("");
    const { applicaionId } = useParams();
    const user_info = JSON.parse(localStorage.getItem("gpt_authuser"));
    const { Calendardata, ScheduleInterviewTwoRoundSubmit, editChapterData, getroundoneassignuser } = props;
    useEffect(() => {
        setapplication_form({
            scheduledate: {
                label: "Schedule date",
                type: "datepicker",
                value: "",
                valid: true,
                error_msg: "",
                validations: { required: false },
            },
            availabletime: {
                type: "select",
                label: "Select Level",
                value: "",
                valid: true,
                error_msg: "",
                onBlur_out: false,
                placeholder: "Select Level",
                validations: { required: true },
                options: [],
            },
            created_id: {
                value: user_info._id,
                validations: { required: false },
            },
        });
        let userdata = [];
        for (let key in editChapterData) {
            // if (getroundoneassignuser.indexOf(editChapterData[key]._id) === -1) {
                userdata.push({
                    value: editChapterData[key]._id,
                    label: editChapterData[key].userName,
                });
            // }
        }
        setCourseValue(userdata);
    }, [editChapterData])

    const SubmitHandler = async (event) => {
        event.preventDefault();
        if (application_form.scheduledate.value === '' || application_form.availabletime.value === '') {
            setTimeout(() => {
                notification.open({
                    message: "Schedule Interview Round Two",
                    description: "Please select date & time.",
                });
            });
        }
        else {
            const payload = {
                applicaionId: Calendardata.applicationid,
                nextdate: Moment(application_form.scheduledate.value).format("YYYY-MM-DD"),
                oneMemberId: ValueId,
                twoMemberId: ValueIdTwo,
                availabletime: application_form.availabletime.value,
                created_id: user_info._id,
            };
            ScheduleInterviewTwoRoundSubmit(payload);
        }
    };


    async function inputChangeHandler(event, identifier) {
        const update_application_form = { ...application_form };
        const form_element = { ...update_application_form[identifier] };
        if (form_element.type === "datepicker") {
            form_element.value = event;
        } else {
            form_element.value = event.value;
        }
        let validate_element = Validations(form_element);
        form_element.error_msg = validate_element.error_msg;

        update_application_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_application_form) {
            is_form_valid = update_application_form[identifier].valid && is_form_valid;
        }
        setapplication_form(update_application_form);
        if (form_element.type === "datepicker") {
            getUpcomingSlotsvalue(event);
        }
    }

    async function getUpcomingSlotsvalue(date) {
        try {
            const update_application_form = { ...application_form };
            let start = Moment(date).startOf('day')
            let end = Moment(date).startOf('day').add(30, 'minute')
            let startarray = []
            let i = 0
            while (i < 48) {
                startarray.push({
                    label: start.format("h:mm A") + ' - ' + end.format("h:mm A"),
                    value: start.unix() + '-' + end.unix()
                })
                start = start.add(30, 'minute')
                end = end.add(30, 'minute')
                i++
            }
            const form_element = { ...update_application_form["scheduledate"] };
            form_element.value = date;
            update_application_form['scheduledate'] = form_element;
            update_application_form.availabletime.options = startarray;
            setapplication_form(update_application_form);
        } catch (e) {
            console.log(e)
        }
    };

    const handleChange = async (result, data) => {
        setValueId(result.value)
        let userdata = [];
        for (let key in editChapterData) {
            if (result.value !== editChapterData[key]._id) {
                // if (getroundoneassignuser.indexOf(editChapterData[key]._id) === -1) {
                    userdata.push({
                        value: editChapterData[key]._id,
                        label: editChapterData[key].userName,
                    });
                // }
            }
        }
        setCourseValueTwo(userdata);
    };

    const handleChangeTwo = async (result, data) => {
        setValueIdTwo(result.value)
    };

    return (
        <form name="normal_login" className="login-form" onSubmit={SubmitHandler}>
            <div className="col-lg-12 fv-row fv-plugins-icon-container">
                <label className=" col-form-label required fw-bold fs-6">Memeber One</label>
                <Select
                    styles={customStyles}
                    className="react-bootstrap-typeahead tag-outer"
                    placeholder="Select Memeber One"
                    options={courseValue}
                    value={courseValue.filter(function (option) {
                        return option.value === ValueId;
                    })}
                    onChange={handleChange}
                />
            </div>
            <div className="col-lg-12 fv-row fv-plugins-icon-container">
                <label className=" col-form-label required fw-bold fs-6">Memeber Two</label>
                <Select
                    styles={customStyles}
                    className="react-bootstrap-typeahead tag-outer"
                    placeholder="Select Memeber Two"
                    options={courseValueTwo}
                    value={courseValueTwo.filter(function (option) {
                        return option.value === ValueIdTwo;
                    })}
                    onChange={handleChangeTwo}
                />
            </div>
            <div className="col-lg-12 fv-row fv-plugins-icon-container">
                <label className=" col-form-label required fw-bold fs-6">Date</label>
                <DatePicker
                    peekNextMonth
                    showYearDropdown
                    showMonthDropdown
                    minDate={new Date()}
                    placeholder="Select a Date"
                    className="form-control date-picker-outer"
                    dateFormat="dd/MM/yyyy"
                    selected={application_form.scheduledate && application_form.scheduledate.value ? application_form.scheduledate.value : ""}
                    onChange={(event) => inputChangeHandler(event, "scheduledate")}
                />
            </div>
            <div className="col-lg-12 fv-row fv-plugins-icon-container">
                <label className=" col-form-label required fw-bold fs-6">Time</label>
                <Select
                    styles={customStyles}
                    name={"availabletime"}
                    className="react-bootstrap-typeahead tag-outer"
                    placeholder="Select a Time"
                    options={application_form.availabletime && application_form.availabletime.options ? application_form.availabletime.options : ""}
                    value={application_form.availabletime && application_form.availabletime.options.filter(function (option) {
                        return option.value === application_form.availabletime.value;
                    })}
                    onChange={newValue => inputChangeHandler(newValue, "availabletime")}
                />
            </div>

            <div className="model text-right mt-3">
                <button type="submit" className='btn btn-sm btn-primary fw-bolder mx-3'>Submit</button>

            </div>
        </form>
    );
}
export default InterviewRoundTwoSchelude;