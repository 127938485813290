import React from "react";
import { notification } from "antd";
import { Link, Redirect } from "react-router-dom";
import { getConfig, updateConfig } from "../../Api/user/userapi";
import Select from "react-select";

import LeftSideBar from "../../Constant/LeftSideBar";
import TopBar from "../../Constant/TopBar";
import Footer from "../../Constant/Footer";
import Validations from "../Utility/Validations";

const user_info = JSON.parse(localStorage.getItem("gpt_authuser"));
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#ffdbdb" : ""
        },
        backgroundColor: state.isSelected ? "#cc0c0c" : "",
    }),
};

class RejectionDayTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rejection_day: {               
                Plagiarism_Reject_Email_Delay: {
                    type: "select",
                    label: "Select plagiarism reject email day",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Select plagiarism reject email day",
                    validations: { required: true },
                    options: [],
                },
                Reject_Email_Delay: {
                    type: "select",
                    label: "Select Reject email day",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Select reject email day",
                    validations: { required: true },
                    options: [],
                },  
                Not_Show_Up_Reject_Days: {
                    type: "select",
                    label: "Select Did't Show Up Reject Days",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Select did't show up reject days",
                    validations: { required: true },
                    options: [],
                },
                Rejection_Days: {
                    type: "select",
                    label: "Select Reject Days",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Select reject days",
                    validations: { required: true },
                    options: [],
                },                 
                created_id: {
                    value: user_info._id,
                    validations: { required: false },
                },

            },
            // emailcontent:'',
            loader: false,
            isAuthenticated: false,

        };

    }

    async componentDidMount() {
        const update_rejection_day = { ...this.state.rejection_day };
        const N = 31;
        const daysdropdown = Array.from({length: N}, (_, index) => index + 1);
        let LevelId_option = [];
        let LevelId_option_1 = [];
        LevelId_option.push({
            value: 0,
            label: 0,
        });
        for (let key in daysdropdown) {
            LevelId_option.push({
                value: daysdropdown[key],
                label: daysdropdown[key],
            });
            LevelId_option_1.push({
                value: daysdropdown[key],
                label: daysdropdown[key],
            });
        }
        update_rejection_day.Plagiarism_Reject_Email_Delay.options = LevelId_option;
        update_rejection_day.Reject_Email_Delay.options = LevelId_option;
        update_rejection_day.Rejection_Days.options = LevelId_option;
        update_rejection_day.Not_Show_Up_Reject_Days.options = LevelId_option_1;
        this.setState({ rejection_day: update_rejection_day });
        this.MemberDetail();
    }
    MemberDetail = async () => {
        const payloadPl = {
            key: 'Plagiarism_Reject_Email_Delay',
        };
        try {            
            const resp = await getConfig(payloadPl);
            if (resp && resp.status === 200) {
                const list = resp;
                
                const smtp_detail = list.data.entity;
                const update_rejection_day = { ...this.state.rejection_day };
                update_rejection_day['Plagiarism_Reject_Email_Delay']['value'] = parseInt(smtp_detail.value);
                this.setState({
                    loader: false,
                    rejection_day: update_rejection_day,
                });
            }
        } catch (e) {
        }
        const payloadPlred = {
            key: 'Reject_Email_Delay',
        };
        try {
            const resp = await getConfig(payloadPlred);
            if (resp && resp.status === 200) {
                const list = resp;

                const smtp_detailre = list.data.entity;
                const update_rejection_day_re = { ...this.state.rejection_day };
                update_rejection_day_re['Reject_Email_Delay']['value'] = parseInt(smtp_detailre.value);
                this.setState({
                    loader: false,
                    rejection_day: update_rejection_day_re,
                });
            }
        } catch (e) {
        }
        const payloadNotShow = {
            key: 'Not_Show_Up_Reject_Days',
        };
        try {
            const resp = await getConfig(payloadNotShow);
            if (resp && resp.status === 200) {
                const list = resp;

                const smtp_detailre = list.data.entity;
                const update_rejection_day_re = { ...this.state.rejection_day };
                update_rejection_day_re['Not_Show_Up_Reject_Days']['value'] = parseInt(smtp_detailre.value);
                this.setState({
                    loader: false,
                    rejection_day: update_rejection_day_re,
                });
            }
        } catch (e) {
        }
        const payloadRejection = {
            key: 'Rejection_Days',
        };
        try {
            const resp = await getConfig(payloadRejection);
            if (resp && resp.status === 200) {
                const list = resp;

                const smtp_detailre = list.data.entity;
                const update_rejection_day_re = { ...this.state.rejection_day };
                update_rejection_day_re['Rejection_Days']['value'] = parseInt(smtp_detailre.value);
                this.setState({
                    loader: false,
                    rejection_day: update_rejection_day_re,
                });
            }
        } catch (e) {
        }
    };
    inputChangeHandler(event, identifier) {
        const update_rejection_day = { ...this.state.rejection_day };
        const form_element = { ...update_rejection_day[identifier] };
        form_element.value = event.target.value;
        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_rejection_day[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_rejection_day) {
            is_form_valid = update_rejection_day[identifier].valid && is_form_valid;
        }
        this.setState({
            rejection_day: update_rejection_day,
            is_form_valid: is_form_valid,
        });

    }
    handleChange(data, identifier, type) {
        const update_rejection_day = { ...this.state.rejection_day };
        update_rejection_day[identifier].value = data.value;


        this.setState({ rejection_day: update_rejection_day });
    }
    handleChangeediter(content) {
        const update_rejection_day = { ...this.state.rejection_day };
        update_rejection_day['content'].value = content;
        this.setState({ rejection_day: update_rejection_day });
    }

    SubmitHandler = async (event) => {
        event.preventDefault();
        let is_form_valid = true;
        const update_rejection_day = this.state.rejection_day;
        for (let key in update_rejection_day) {
            let form_element = update_rejection_day[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_rejection_day[key] = form_element;
        }
        this.setState({ rejection_day: update_rejection_day });
        console.log('this.state.rejection_day 123===', this.state.rejection_day)
        if (is_form_valid) {
            
            for (let key in this.state.rejection_day) {
                if (key === 'Plagiarism_Reject_Email_Delay'){
                    const payloadPlred = {
                        key: 'Plagiarism_Reject_Email_Delay',
                        value: this.state.rejection_day[key].value
                    };
                    try {
                        const result = await updateConfig(payloadPlred);
                        if (result && result.data.success === true) {
                            setTimeout(() => {
                                notification.open({
                                    message: "Rejection Email",
                                    description: result.data.message,
                                });
                            }, 1000);

                        } else if (result && result.data.success === false) {
                            setTimeout(() => {
                                notification.open({
                                    message: "Rejection Email",
                                    description: result.data.message,
                                });
                            }, 1000);
                        }
                    } catch (e) {
                    }
                }
                if (key === 'Reject_Email_Delay') {
                    const payloadPlred = {
                        key: 'Reject_Email_Delay',
                        value: this.state.rejection_day[key].value
                    };
                    try {
                        const result = await updateConfig(payloadPlred);
                        if (result && result.data.success === true) {
                            setTimeout(() => {
                                notification.open({
                                    message: "Rejection Email",
                                    description: result.data.message,
                                });
                            }, 1000);

                        } else if (result && result.data.success === false) {
                            setTimeout(() => {
                                notification.open({
                                    message: "Rejection Email",
                                    description: result.data.message,
                                });
                            }, 1000);
                        }
                    } catch (e) {
                    }
                }
                if (key === 'Not_Show_Up_Reject_Days') {
                    const payloadPlred = {
                        key: 'Not_Show_Up_Reject_Days',
                        value: this.state.rejection_day[key].value
                    };
                    try {
                        const result = await updateConfig(payloadPlred);
                        if (result && result.data.success === true) {
                            setTimeout(() => {
                                notification.open({
                                    message: "Did't Show Up Days",
                                    description: result.data.message,
                                });
                            }, 1000);

                        } else if (result && result.data.success === false) {
                            setTimeout(() => {
                                notification.open({
                                    message: "Did't Show Up Days",
                                    description: result.data.message,
                                });
                            }, 1000);
                        }
                    } catch (e) {
                    }
                }
                if (key === 'Rejection_Days') {
                    const payloadPlred = {
                        key: 'Rejection_Days',
                        value: this.state.rejection_day[key].value
                    };
                    try {
                        const result = await updateConfig(payloadPlred);
                        if (result && result.data.success === true) {
                            setTimeout(() => {
                                notification.open({
                                    message: "Rejection Days",
                                    description: result.data.message,
                                });
                            }, 1000);

                        } else if (result && result.data.success === false) {
                            setTimeout(() => {
                                notification.open({
                                    message: "Rejection Days",
                                    description: result.data.message,
                                });
                            }, 1000);
                        }
                    } catch (e) {
                    }
                }
            }
            // try {
            //     const result = await updateConfig(form_data);
            //     if (result && result.data.success === true) {
            //         setTimeout(() => {
            //             notification.open({
            //                 message: "SMTP Setting",
            //                 description: result.data.message,
            //             });
            //         }, 1000);

            //     } else if (result && result.data.success === false) {
            //         setTimeout(() => {
            //             notification.open({
            //                 message: "SMTP Setting",
            //                 description: result.data.message,
            //             });
            //         }, 1000);
            //     }
            // } catch (e) {
            // }

        }
    };
    render() {
        const { rejection_day } = this.state;
        console.log('rejection_day=123==', rejection_day)
        return (
            <React.Fragment>
                {this.state.isAuthenticated ? <Redirect to={this.state.redirect_url} /> : null}
                <div className="d-flex flex-column flex-root header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed h-100">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <LeftSideBar />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <TopBar />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="toolbar" id="kt_toolbar">
                                    <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                                        <div
                                            data-kt-swapper="true"
                                            data-kt-swapper-mode="prepend"
                                            data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
                                            className="page-username d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
                                        >
                                            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
                                                Rejection Day
                                                <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                                            </h1>

                                            <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                                                <li className="breadcrumb-item text-muted">
                                                    <Link to="/dashboard" className="text-muted text-hover-primary">
                                                        Home
                                                    </Link>
                                                </li>

                                                <li className="breadcrumb-item">
                                                    <span className="bullet bg-gray-200 w-5px h-2px"></span>
                                                </li>

                                                <li className="breadcrumb-item text-dark"> Rejection Email Day</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-xxl">
                                        <form name="normal_login" className="login-form" onSubmit={this.SubmitHandler}>
                                            <div className="row gy-5 g-xl-8">
                                                <div className="col-xl-12">
                                                    <div className="card card-xl-stretch mb-xl-8">
                                                        <div className="card-body pt-2">
                                                            <div className="row">
                                                                <div className="col-lg-6 fv-row fv-plugins-icon-container ">
                                                                    <label className=" col-form-label required fw-bold fs-6">Select Plagiarism Reject Email Day</label>
                                                                    <Select
                                                                        styles={customStyles}
                                                                        name={"Plagiarism_Reject_Email_Delay"}
                                                                        className="react-bootstrap-typeahead tag-outer"
                                                                        placeholder="Select a plagiarism reject email delay"
                                                                        options={rejection_day.Plagiarism_Reject_Email_Delay.options}
                                                                        value={rejection_day.Plagiarism_Reject_Email_Delay.options.filter(function (option) {
                                                                            return option.value === rejection_day.Plagiarism_Reject_Email_Delay.value;
                                                                        })}
                                                                        onChange={newValue => this.handleChange(newValue, "Plagiarism_Reject_Email_Delay", 'Plagiarism_Reject_Emailvalue')}
                                                                    />
                                                                    {!rejection_day.Plagiarism_Reject_Email_Delay.valid && rejection_day.Plagiarism_Reject_Email_Delay.onBlur_out ? <div className="error field-error">{rejection_day.Plagiarism_Reject_Email_Delay.error_msg}</div> : ""}
                                                                </div>
                                                                <div className="col-lg-6 fv-row fv-plugins-icon-container ">
                                                                    <label className=" col-form-label required fw-bold fs-6">Select Reject Email Day</label>
                                                                    <Select
                                                                        styles={customStyles}
                                                                        name={"Reject_Email_Delay"}
                                                                        className="react-bootstrap-typeahead tag-outer"
                                                                        placeholder="Select a reject email delay"
                                                                        options={rejection_day.Reject_Email_Delay.options}
                                                                        value={rejection_day.Reject_Email_Delay.options.filter(function (option) {
                                                                            return option.value === rejection_day.Reject_Email_Delay.value;
                                                                        })}
                                                                        onChange={newValue => this.handleChange(newValue, "Reject_Email_Delay", 'Reject_Emailvalue')}
                                                                    />
                                                                    {!rejection_day.Reject_Email_Delay.valid && rejection_day.Reject_Email_Delay.onBlur_out ? <div className="error field-error">{rejection_day.Reject_Email_Delay.error_msg}</div> : ""}
                                                                </div>
                                                                <div className="col-lg-6 fv-row fv-plugins-icon-container ">
                                                                    <label className=" col-form-label required fw-bold fs-6">Select Did't Show Up Reject Days</label>
                                                                    <Select
                                                                        styles={customStyles}
                                                                        name={"Not_Show_Up_Reject_Days"}
                                                                        className="react-bootstrap-typeahead tag-outer"
                                                                        placeholder="Select a not showing up reject delay"
                                                                        options={rejection_day.Not_Show_Up_Reject_Days.options}
                                                                        value={rejection_day.Not_Show_Up_Reject_Days.options.filter(function (option) {
                                                                            return option.value === rejection_day.Not_Show_Up_Reject_Days.value;
                                                                        })}
                                                                        onChange={newValue => this.handleChange(newValue, "Not_Show_Up_Reject_Days", 'Not_Show_Up_Reject_Daysvalue')}
                                                                    />
                                                                    {!rejection_day.Not_Show_Up_Reject_Days.valid && rejection_day.Not_Show_Up_Reject_Days.onBlur_out ? <div className="error field-error">{rejection_day.Not_Show_Up_Reject_Days.error_msg}</div> : ""}
                                                                </div>
                                                                <div className="col-lg-6 fv-row fv-plugins-icon-container ">
                                                                    <label className=" col-form-label required fw-bold fs-6">Select Auto Reject Days</label>
                                                                    <Select
                                                                        styles={customStyles}
                                                                        name={"Rejection_Days"}
                                                                        className="react-bootstrap-typeahead tag-outer"
                                                                        placeholder="Select rejection delay"
                                                                        options={rejection_day.Rejection_Days.options}
                                                                        value={rejection_day.Rejection_Days.options.filter(function (option) {
                                                                            return option.value === rejection_day.Rejection_Days.value;
                                                                        })}
                                                                        onChange={newValue => this.handleChange(newValue, "Rejection_Days", 'Rejection_Daysvalue')}
                                                                    />
                                                                    {!rejection_day.Rejection_Days.valid && rejection_day.Rejection_Days.onBlur_out ? <div className="error field-error">{rejection_day.Rejection_Days.error_msg}</div> : ""}
                                                                </div>
                                                                
                                                                <div className="col-lg-12 mt-8">
                                                                    <button type="submit" id="kt_new_password_submit" className="btn btn-lg btn-primary fw-bolder me-4">
                                                                        <span className="indicator-label">Save</span>
                                                                    </button>
                                                                    <Link to="#" className="btn btn-lg btn-light-primary fw-bolder">
                                                                        Cancel
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                    <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
                        <span className="svg-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="black" />
                                <path
                                    d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                    fill="black"
                                />
                            </svg>
                        </span>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default RejectionDayTemplate;
