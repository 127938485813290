import React, { useState } from "react";
import {useHistory, useParams, Link } from "react-router-dom";
import {Input, Spin, notification } from "antd";
// import { Buffers } from "react-frontend-developer/buffers";
import { Buffer } from 'buffer';

import { updatepassword } from "../../Api/user/userapi";
// const memberId = localStorage.getItem("userMemberId");
const Registration = () => {
    const [loading, setLoading] = useState(false);

    const [password, setPassword] = useState("");
    const [confirmPass, setConfirmPass] = useState("");
    const [term, setterm] = useState("");
    const [inputerror, setinputerror] = useState([]);
    const [emails, setemails] = useState("");
    
    let history = useHistory();

    const { id, email } = useParams();    
    console.log("params", id, emails);

    // console.log(password, confirmPass, "confirmPassconfirmPass");

    const handleSubmit = async (e) => {
          e.preventDefault();
          console.log(password, confirmPass, term,  "confirmPassconfirmPass");
           const errors = [];
           errors["password"] = '';
           errors["confirmPass"] = '';
           errors["term"] = '';
           if(password === ''){
               errors["password"] = "Please enter your password.";
           }
           if(confirmPass === ''){
               errors["confirmPass"] = "Please enter your confirm password.";
           }
           else if(confirmPass !== password){
               errors["confirmPass"] = "Please enter the same password";
           }
           if(term === ''){
               errors["term"] = "Please Select Terms and conditions.";
           }
          setinputerror(errors);
        const payload = {
            userId: id,
            newPassword: password,
        };

        console.log(payload, "payload");
        if((errors['password'] === '') && (errors['confirmPass'] === '')  && (errors['term'] === '')){
        try {
            setLoading(true);
            const res = await updatepassword(payload);

            console.log(res, "res");
            if (res.status === 200) {
                history.push("/");
                setTimeout(() => {
                    notification.open({
                        message: "password",
                        description: "Password updated successfully.",
                    });
                }, 1000);
            }
        } catch (e) {
            console.log(e, "error");
            setTimeout(() => {
                notification.open({
                    message: "password",
                    description: "Unknown error in update password.",
                });
            }, 1000);
            
        } finally {
            setLoading(false);
        }
        }
    };
    return (
        <div className="d-flex flex-column flex-root">
            <div className="authentication_img d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed" style={{ backgroundImage: `url(/assets/media/logos/white-bg.png)` }}>
                <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
                    <Link to="/" className="mb-12">
                        <img alt="Logo" src="/assets/media/logos/logo.png" className="h-40px" />
                    </Link>
                    <div className="w-lg-550px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
                        <Spin spinning={loading}>
                            <form className="form w-100" onSubmit={handleSubmit} noValidate="noValidate" id="kt_new_password_form">
                                <div className="text-center mb-10">
                                    <h1 className="text-dark mb-3">Create Your Password</h1>
                                </div>
                                <div className="fv-row mb-10">
                                    <label className="form-label fs-6 fw-bolder text-dark">Email</label>
                                    <input className="form-control form-control-lg form-control-solid" readOnly type="text" name="email" value={Buffer.from(email, 'base64').toString("ascii")} placeholder="test@girlpower.com" autoComplete="off" />
                                </div>
                                <div className="mb-10 fv-row" data-kt-password-meter="true">
                                    <div className="mb-1">
                                        <label className="form-label fw-bolder text-dark fs-6">Password</label>
                                        <div className="position-relative mb-3">
                                            <Input.Password className="form-control form-control-lg form-control-solid" onChange={(e) => setPassword(e.target.value)} type="password" placeholder="" name="password" autoComplete="off" />
                                            {inputerror['password'] ? <div className="error field-error">{inputerror['password']}</div> : ""}
                                            {/* <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
											<i className="bi bi-eye-slash fs-2"></i>
											<i className="bi bi-eye fs-2 d-none"></i>
										</span> */}
                                        </div>
                                        <div className="d-flex align-items-center mb-3" data-kt-password-meter-control="highlight">
                                            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                                            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                                            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                                            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
                                        </div>
                                    </div>
                                    {/* <div className="text-muted">Use 8 or more characters with a mix of letters, numbers &amp; symbols.</div> */}
                                </div>
                                <div className="fv-row mb-10">
                                    <label className="form-label fw-bolder text-dark fs-6">Confirm Password</label>
                                    <input className="form-control form-control-lg form-control-solid" onChange={(e) => setConfirmPass(e.target.value)} type="password" placeholder="" name="confirm-password" autoComplete="off" />
                                    {inputerror['confirmPass'] ? <div className="error field-error">{inputerror['confirmPass']}</div> : ""}
                                </div>
                                <div className="fv-row mb-10">
                                    <div className="form-check form-check-custom form-check-solid form-check-inline">
                                        <input className="form-check-input" type="checkbox" name="toc" value="1"  onChange={(e) => setterm(e.target.value)}/>                                        
                                        <label className="form-check-label fw-bold text-gray-700 fs-6">
                                            I Agree
                                            <Link to="#" className="ms-1 link-primary">
                                                terms and conditions
                                            </Link>
                                            .
                                        </label>
                                    </div>
                                    {inputerror['term'] ? <div className="error field-error">{inputerror['term']}</div> : ""}
                                </div>
                                <div className="text-center">
                                    <button type="submit" id="kt_new_password_submit" className="btn btn-lg btn-primary fw-bolder me-4">
                                        <span className="indicator-label">Save</span>
                                        <span className="indicator-progress">
                                            Please wait...
                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                    </button>
                                    <Link to="/" className="btn btn-lg btn-light-primary fw-bolder">
                                        Cancel
                                    </Link>
                                </div>
                            </form>
                        </Spin>
                    </div>
                </div>
                <div className="d-flex flex-center flex-column-auto p-10">
                    <div className="d-flex align-items-center fw-bold fs-6">
                        
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Registration;