import React from "react";
import { Redirect } from "react-router-dom";
import Permission from "../../Component/Permissions/Permission";
import SmtpSetting from "../../Component/Permissions/SmtpSetting";
import RejectionDayTemplate from "../../Component/Permissions/RejectionDayTemplate";
class Permissions extends React.Component {
    render() {
		const user_info = JSON.parse(localStorage.getItem("gpt_authuser"));
        // const user_role = JSON.parse(localStorage.getItem("gpt_user_role"));
        const renderview = () => {
            if (this.props.match.path === "/permission") {
                if(user_info && user_info.usertype && user_info.usertype === "admin" ) {
                    return <Permission />;
                  
                }
                else{
                    return <Redirect to="/dashboard" />;
                }
            }        
            if (this.props.match.path === "/smtp-setting") {
                if (user_info && user_info.usertype && user_info.usertype === "admin") {
                    return <SmtpSetting />;

                }
                else {
                    return <Redirect to="/dashboard" />;
                }
            } 
            if (this.props.match.path === "/rejection-email") {
                if (user_info && user_info.usertype && user_info.usertype === "admin") {
                    return <RejectionDayTemplate />;

                }
                else {
                    return <Redirect to="/dashboard" />;
                }
            } 
        };
		
        return (
            <React.Fragment>
                {/* <Notifications /> */}
                {renderview()}
            </React.Fragment>
        );
		
    }
}
export default Permissions;