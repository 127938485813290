import React, { PureComponent } from "react";
import { Redirect } from "react-router-dom";

class logout extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: false,
        };
    }

    componentDidMount() {
        localStorage.removeItem("gpt_authuser");
        localStorage.removeItem("dataToken");
        localStorage.removeItem("userID");
        localStorage.removeItem("gpt_user_role");
        localStorage.removeItem("user_profile");
        localStorage.removeItem("login_user_name");
        this.setState({ loader: false, isAuthenticated: true });
        window.location.href = "/";
    }

    render() {
        return (
            <React.Fragment>
                {this.state.isAuthenticated ? <Redirect to="/" /> : null}
                loading ...
            </React.Fragment>
        );
    }
}

export default logout;