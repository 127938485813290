import React, { useEffect, useState, useCallback } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Moment from "moment";
// import { Form, Input, Select, notification } from "antd";
import { Modal, notification, Tooltip } from "antd";
import {
    getActiveMember,
    getApplication,
    addComment,
    assignManualUser,
    assignTwoUser,
    rejectApplication,
    userOneStatus,
    userTwoStatus,
    userThreeStatus,
    updateApplicationStatus,
    acceptByAdmin,
    sendProgressUrl,
    interviewOneStatus,
    interviewTwoStatus,
    interviewThreeStatus,
    interviewacceptByAdmin,
    interviewTwoRoundOneStatus,
    interviewTwoRoundTwoStatus,
    interviewTwoRoundThreeStatus,
    assignOtherMember,
    sendExternalLinkEmail,
    intialAcceptByAdmin,
    getApplicationPdf,
    notShowUpInterview,
    scheduleInterviewTwoRound,
    interviewTwoRoundacceptByAdmin,
    acceptRejectOffer
} from "../../Api/user/userapi";
import csc from "country-state-city";
import AssignApplicationUser from "./AssignApplicationUser";
//import { Member_Image_URL } from "../../Constant/index";
import LeftSideBar from "../../Constant/LeftSideBar";
import TopBar from "../../Constant/TopBar";
import Footer from "../../Constant/Footer";
import Validations from "../Utility/Validations";
import RejectApplication from "./Modal/RejectApplication";
import AcceptApplication from "./Modal/AcceptApplication";
import AcceptRejectByUserApplication from "./Modal/AcceptRejectByUserApplication";
import ApplicationArchive from "../Applications/Modal/ApplicationArchive";
import ApplicationSendEmail from "../Applications/Modal/ApplicationSendEmail";
import FinalAcceptApplication from "../Applications/Modal/FinalAcceptApplication";
import ExternalMember from "../Applications/Modal/ExternalMember";
import AutoAssignOtherMember from "../Applications/Modal/AutoAssignOtherMember";
import AutoAssignRepleaceMember from "../Applications/Modal/AutoAssignRepleaceMember";
import AcceptRejectByInterviewApplication from "./Modal/AcceptRejectByInterviewApplication";
import AutoAssignAdmin from "./Modal/AutoAssignAdmin";
import AcceptFinalReviewByAllApplication from "./Modal/AcceptFinalReviewByAllApplication";
import InterviewRoundTwoSchelude from "../Interviews/Modal/InterviewRoundTwoSchelude"

const ApplicationDetail = (props) => {
    let history = useHistory();
    const [applicationdetaildata, setArray] = useState([]);
    const [Assignuserdata, setAssignuserdata] = useState([]);
    const [isAssignModalVisible, setIsAssignModalVisible] = useState(false);
    const [application_response_tabs, setapplication_response_tabs] = useState("application_que_hide");
    const [viewmoreform, setviewmoreform] = useState("View More");
    const [application_response_text, setapplication_response_text] = useState("application_que_show");
    const [applicationformreply, setapplicationformreply] = useState("hide");
    const [replyapplicationformreply, setreplyapplicationformreply] = useState("show");
    const [application_form, setapplication_form] = useState([]);
    // const [is_form_valid, setis_form_valid] = useState(false);
    const [apptype, setapptype] = useState("");

    const [getapplication_form, setgetapplication_form] = useState([]);
    const [getclarification_received, setgetclarification_received] = useState([]);
    const [getassign_for_review, setgetassign_for_review] = useState([]);
    const [getreview_application, setgetreview_application] = useState([]);
    const [getreview_application_by_admin, setreview_application_by_admin] = useState([]);
    const [getreview_application_by_user, setgetreview_application_by_usern] = useState([]);
    const [getapplication_accepted, setgetapplication_accepted] = useState([]);
    const [getfinal_review, setgetfinal_review] = useState([]);
    const [getinterview_round, setgetinterview_round] = useState([]);
    const [getinterviewTwoRound_round, setgetinterviewTwoRound_round] = useState([]);
    const [Plagiarismresponcedata, setPlagiarismresponcedata] = useState([]);
    const [getreview_interview_round, setgetreview_interview_round] = useState([]);
    const [getinterview_round_2, setgetinterview_round_2] = useState([]);
    const [emailsData, setEmailsData] = useState([]);

    const { applicaionId } = useParams();
    const user_info = JSON.parse(localStorage.getItem("gpt_authuser"));
    const user_role = JSON.parse(localStorage.getItem("gpt_user_role"));
    const [isAcceptModalVisible, setIsAcceptModalVisible] = useState(false);
    const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
    const [isAcceptByUserModalVisible, setIsAcceptByUserModalVisible] = useState(false);
    const [isArchiveModalVisible, setIsArchiveModalVisible] = useState(false);
    const [isSendEmailModalVisible, setIsSendEmailModalVisible] = useState(false);
    const [isFinalAcceptModalVisible, setIsFinalAcceptModalVisible] = useState(false);
    const [isExternalMemberModalVisible, setIsExternalMemberModalVisible] = useState(false);
    const [isExternalMemberTypeModalVisible, setIsExternalMemberTypeModalVisible] = useState("");
    const [isAcceptByInterviewModalVisible, setIsAcceptByInterviewModalVisible] = useState(false);
    const [isAcceptByInterviewTwoRoundModalVisible, setIsAcceptByInterviewTwoRoundModalVisible] = useState(false);

    const [isInterviewTwoModalVisible, setIsInterviewTwoModalVisible] = useState(false);
    const [isAcceptFinalReviewModalVisible, setIsAcceptFinalReviewModalVisible] = useState(false);

    const [ischnageOtherMemberModalVisible, setIschnageOtherMemberModalVisible] = useState(false);
    const [isAutoAssignOtherMemberrModalVisible, setIsAutoAssignOtherMemberrModalVisible] = useState(false);
    const [isAutoAcceptModalVisible, setIsAutoAcceptModalVisible] = useState(false);
    const [isAutoAcceptModalVisibleTwoRound, setIsAutoAcceptModalVisibleTwoRound] = useState(false);
    const [getlastMemberId, setgetlastMemberId] = useState("");
    const [getassignresult, setassignresult] = useState("");

    const [getapplication_accepted_assign, setgetapplication_accepted_assign] = useState([]);
    const [getapplication_interview_assign, setgetapplication_interview_assign] = useState([]);
    const [getapplication_interviewTwoRound_assign, setgetapplication_interviewTwoRound_assign] = useState([]);
    const [getroundoneassignuser, setgetroundoneassignuser] = useState([]);

    // const [isRejectByUserModalVisible, setIsRejectByUserModalVisible] = useState(false);
    const [gettitle, setgettitle] = useState("");
    const [getstatus, setgetstatus] = useState("");
    const [getmessagedata, setgetmessagedata] = useState("");
    const [getrepeat, setrepeat] = useState(0);
    const [editChapterData, setEditChapterData] = useState({});
    const [id, setId] = useState();
    const [archivestatus, setarchivestatus] = useState();

    const [arrayulrvalue, setarrayulrvalue] = useState('');
    const [externalMemberId, setexternalMemberId] = useState('');

    // const handleCancel = () => {
    //     setIsAssignModalVisible(false);
    // };
    const countries = csc.getAllCountries();

    const updatedCountries = countries.map((country) => ({
        label: country.name,
        value: country.id,
        ...country,
    }));
    const userDetails = useCallback(async () => {
        const queryParamsapp = new URLSearchParams(window.location.search);
        const arrayulr = [];
        var gender = '';
        var age = '';
        var search = '';
        var status = '';
        var tagid = '';
        var filterMemberId = '';
        var order = 'desc';
        var sortBy = '_id';
        var userType = 'admin';
        var applicationType = 'all';
        var memberId = '';

        if (queryParamsapp.get("gender")) {
            gender = queryParamsapp.get("gender")
            arrayulr.push({
                value: gender,
                label: 'gender'
            })
        }
        if (queryParamsapp.get("age")) {
            age = queryParamsapp.get("age")
            arrayulr.push({
                value: age,
                label: 'age'
            })
        }
        if (queryParamsapp.get("search")) {
            search = queryParamsapp.get("search")
            arrayulr.push({
                value: search,
                label: 'search'
            })
        }
        if (queryParamsapp.get("status")) {
            status = queryParamsapp.get("status")
            arrayulr.push({
                value: status,
                label: 'status'
            })
        }
        if (queryParamsapp.get("tagid")) {
            tagid = queryParamsapp.get("tagid")
            arrayulr.push({
                value: tagid,
                label: 'tagid'
            })
        }
        if (queryParamsapp.get("filterMemberId")) {
            filterMemberId = queryParamsapp.get("filterMemberId")
            arrayulr.push({
                value: filterMemberId,
                label: 'filterMemberId'
            })
        }

        if (queryParamsapp.get("order")) {
            order = queryParamsapp.get("order")
            arrayulr.push({
                value: order,
                label: 'order'
            })
        }
        if (queryParamsapp.get("sortBy")) {
            sortBy = queryParamsapp.get("sortBy")
            arrayulr.push({
                value: sortBy,
                label: 'sortBy'
            })
        }
        if (queryParamsapp.get("applicationType")) {
            applicationType = queryParamsapp.get("applicationType")
            arrayulr.push({
                value: applicationType,
                label: 'applicationType'
            })
        }

        var urlfilter = '';
        if (arrayulr.length > 0) {
            urlfilter = arrayulr.map(({ value, label }) => `${label}=${value}`).join('&');
            urlfilter = '?' + urlfilter;
        }

        if (user_role && user_role.Allapplication !== "yes") {
            userType = 'all';
            memberId = user_info._id;
        }
        setarrayulrvalue(urlfilter);
        const Payload = {
            applicaionId: applicaionId,
            gender: gender,
            age: age,
            search: search,
            status: status,
            tagid: tagid,
            filterMemberId: filterMemberId,
            order: order,
            sortBy: sortBy,
            userType: userType,
            applicationType: applicationType,
            memberId: memberId,
            timeZoneMemberId: user_info._id
        };
        // const userDetails = async () => {
        try {
            const resp = await getApplication(Payload);
            if (resp && resp.data.success !== false) {
                const list = resp;

                const arrayData = list.data.data;
                const resultgetapplication_form = [];
                const resultgetclarification_received = [];
                const resultgetassign_for_review = [];
                const resultgetreview_application = [];
                const resultgetapplication_accepted = [];
                const resultgetfinal_review = [];
                const resultgetinterview_round = [];
                const resultgetinterviewTwoRound_round = [];
                const resultgetreview_application_by_user = [];
                const resultgetreview_application_by_admin = [];
                const resultgetreview_interview_round = [];
                const resultgetinterview_round_2 = [];
                //setgetapplication_accepted
                let application_accepted_assign = [];
                if (arrayData.externalMembers) {
                    const extrnmemberid = arrayData.externalMembers.includes(user_info._id)
                    // if (extrnmemberid){
                    setexternalMemberId(user_info._id)
                    //}

                }
                if (arrayData["application_accepted"]) {
                    if (!arrayData["application_accepted"]["user_one"]["user_one_status"]) {
                        const resultvalue = arrayData["application_accepted"]["user_one"]["user_one_id"] + "!!user_one";
                        application_accepted_assign.push({
                            value: resultvalue,
                            label: arrayData["application_accepted"]["user_one"]["user_one_name"],
                        });
                    }
                    if (!arrayData["application_accepted"]["user_two"]["user_two_status"]) {
                        const resultvalue = arrayData["application_accepted"]["user_two"]["user_two_id"] + "!!user_two";
                        application_accepted_assign.push({
                            value: resultvalue,
                            label: arrayData["application_accepted"]["user_two"]["user_two_name"],
                        });
                    }
                    if (arrayData["application_accepted"]["user_three"]["user_three_name"]) {
                        const resultvalue = arrayData["application_accepted"]["user_three"]["user_three_id"] + "!!user_three";
                        application_accepted_assign.push({
                            value: resultvalue,
                            label: arrayData["application_accepted"]["user_three"]["user_three_name"],
                        });
                    }
                    setgetapplication_accepted_assign(application_accepted_assign);
                }
                let application_interview_assign = [];
                if (arrayData["application_interview"]) {
                    if (!arrayData["application_interview"]["interview_one"]["interview_one_status"]) {
                        const resultvalue = arrayData["application_interview"]["interview_one"]["interview_one_id"] + "!!interview_one";
                        application_interview_assign.push({
                            value: resultvalue,
                            label: arrayData["application_interview"]["interview_one"]["interview_one_name"],
                        });
                    }
                    if (!arrayData["application_interview"]["interview_two"]["interview_two_status"]) {
                        const resultvalue = arrayData["application_interview"]["interview_two"]["interview_two_id"] + "!!interview_two";
                        application_interview_assign.push({
                            value: resultvalue,
                            label: arrayData["application_interview"]["interview_two"]["interview_two_name"],
                        });
                    }
                    if (arrayData["application_interview"]["interview_three"]["interview_three_name"]) {
                        const resultvalue = arrayData["application_interview"]["interview_three"]["interview_three_id"] + "!!interview_three";
                        application_interview_assign.push({
                            value: resultvalue,
                            label: arrayData["application_interview"]["interview_three"]["interview_three_name"],
                        });
                    }
                    setgetapplication_interview_assign(application_interview_assign);
                }

                let application_interviewTwoRound_assign = [];
                if (arrayData["application_interviewTwoRound"]) {
                    if (!arrayData["application_interviewTwoRound"]["interviewTwoRound_one"]["interviewTwoRound_one_status"]) {
                        const resultvalue = arrayData["application_interviewTwoRound"]["interviewTwoRound_one"]["interviewTwoRound_one_id"] + "!!interviewTwoRound_one";
                        application_interviewTwoRound_assign.push({
                            value: resultvalue,
                            label: arrayData["application_interviewTwoRound"]["interviewTwoRound_one"]["interviewTwoRound_one_name"],
                        });
                    }
                    if (!arrayData["application_interviewTwoRound"]["interviewTwoRound_two"]["interviewTwoRound_two_status"]) {
                        const resultvalue = arrayData["application_interviewTwoRound"]["interviewTwoRound_two"]["interviewTwoRound_two_id"] + "!!interviewTwoRound_two";
                        application_interviewTwoRound_assign.push({
                            value: resultvalue,
                            label: arrayData["application_interviewTwoRound"]["interviewTwoRound_two"]["interviewTwoRound_two_name"],
                        });
                    }
                    setgetapplication_interviewTwoRound_assign(application_interviewTwoRound_assign);
                }



                if (arrayData["commentsdata"][0]) {
                    const Commentsdatas = arrayData["commentsdata"];
                    for (let key in Commentsdatas) {
                        if (Commentsdatas[key]["type"] === "application_form") {
                            resultgetapplication_form.push(Commentsdatas[key]);
                        } else if (Commentsdatas[key]["type"] === "clarification_received") {
                            resultgetclarification_received.push(Commentsdatas[key]);
                        } else if (Commentsdatas[key]["type"] === "assign_for_review") {
                            resultgetassign_for_review.push(Commentsdatas[key]);
                        } else if (Commentsdatas[key]["type"] === "review_application") {
                            resultgetreview_application.push(Commentsdatas[key]);
                        } else if (Commentsdatas[key]["type"] === "application_accepted") {
                            resultgetapplication_accepted.push(Commentsdatas[key]);
                        } else if (Commentsdatas[key]["type"] === "final_review") {
                            resultgetfinal_review.push(Commentsdatas[key]);
                        } else if (Commentsdatas[key]["type"] === "interview_round") {
                            resultgetinterview_round.push(Commentsdatas[key]);
                        } else if (Commentsdatas[key]["type"] === "interviewTwoRound_round") {
                            resultgetinterviewTwoRound_round.push(Commentsdatas[key]);
                        } else if (Commentsdatas[key]["type"] === "review_application_by_user") {
                            resultgetreview_application_by_user.push(Commentsdatas[key]);
                        } else if (Commentsdatas[key]["type"] === "review_application_by_admin") {
                            resultgetreview_application_by_admin.push(Commentsdatas[key]);
                        } else if (Commentsdatas[key]["type"] === "review_interview_round") {
                            resultgetreview_interview_round.push(Commentsdatas[key]);
                        } else if (Commentsdatas[key]["type"] === "interview_round_2") {
                            resultgetinterview_round_2.push(Commentsdatas[key]);
                        }


                    }
                }
                if (arrayData["roundoneassignuser"]) {
                    setgetroundoneassignuser(arrayData["roundoneassignuser"]);
                }
                if (arrayData["reportsdata"][0]) {
                    const reportsdata = arrayData["reportsdata"][0];
                    let Plagiarismdata = [];
                    for (let key in reportsdata) {
                        if (reportsdata[key]["html"]) {
                            const resultdata = reportsdata[key]["html"].replace("", "\n");
                            Plagiarismdata.push({
                                order: reportsdata[key]["order"],
                                html: resultdata,
                            });
                        } else {
                            Plagiarismdata.push({
                                order: reportsdata[key]["order"],
                                html: "",
                            });
                        }
                    }
                    setPlagiarismresponcedata(Plagiarismdata);
                }
                setEmailsData(arrayData["emailsdata"])
                setgetapplication_form(resultgetapplication_form);
                setgetclarification_received(resultgetclarification_received);
                setgetassign_for_review(resultgetassign_for_review);
                setgetreview_application(resultgetreview_application);
                setreview_application_by_admin(resultgetreview_application_by_admin);
                setgetreview_application_by_usern(resultgetreview_application_by_user);
                setgetapplication_accepted(resultgetapplication_accepted);
                setgetfinal_review(resultgetfinal_review);
                setgetinterview_round(resultgetinterview_round);
                setgetinterviewTwoRound_round(resultgetinterviewTwoRound_round);
                setgetreview_interview_round(resultgetreview_interview_round);
                setgetinterview_round_2(resultgetinterview_round_2);

                setArray(arrayData);
            } else {
                history.push("/application");
                setTimeout(() => {
                    notification.open({
                        message: "Application",
                        description: "Sorry, this application does not exist.",
                    });
                });
            }
        } catch (e) {
            //     history.push("/application");
            //     setTimeout(() => {
            //         notification.open({
            //             message: "Application",
            //             description: "Sorry something went wrong",
            //         });
            // });
        }
    }, [applicaionId]);
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        if (queryParams.get("repeat") === "yes") {
            setrepeat(1);
        }
        // super(props);
        setapplication_form({
            comment: {
                label: "Comment",
                type: "text",
                value: "",
                valid: true,
                error_msg: "",
                validations: { required: false },
            },
            type: {
                type: "hidden",
                label: "Type",
                value: "",
                valid: true,
                error_msg: "",
                onBlur_out: false,
                placeholder: "",
                validations: { required: false },
            },
            applicationid: {
                type: "hidden",
                label: "",
                value: "",
                valid: true,
                error_msg: "",
                onBlur_out: false,
                placeholder: "",
                validations: { required: false },
            },
            userid: {
                type: "hidden",
                label: "",
                value: "",
                valid: true,
                error_msg: "",
                onBlur_out: false,
                placeholder: "",
                validations: { required: false },
            },
            uesrname: {
                type: "hidden",
                label: "",
                value: "",
                valid: true,
                error_msg: "",
                onBlur_out: false,
                placeholder: "",
                validations: { required: false },
            },
            created_id: {
                value: user_info._id,
                validations: { required: false },
            },
        });
        userDetails();
    }, [userDetails]);

    const showAssignModalHandle = (id) => {
        setIsAssignModalVisible(true);
        GetAssignMemberdata(id);
    };
    const HideShowApplicationForm = (data) => {
        if (data === "application_que_hide") {
            setviewmoreform("View More");
            setapplication_response_text("application_que_show");
        } else {
            setviewmoreform("View Less");
            setapplication_response_text("application_que_hide");
        }
        setapplication_response_tabs(data);
    };
    const showapplicationreply = (data, type) => {
        if (data === "hide") {
            setreplyapplicationformreply("show");
        } else {
            setreplyapplicationformreply("hide");
        }
        setapplicationformreply(data);
        setapptype(type);
    };

    async function GetAssignMemberdata(id) {
        const Payload = {
            applicaionId: id,
            memberId: user_info._id,
            created_id: user_info._id,
        };
        try {
            const resp = await getActiveMember(Payload);
            if (resp && resp.status === 200) {
                const list = resp;
                const arrayData = list.data.data;
                setAssignuserdata(arrayData);
            }
        } catch (e) { }
    }
    async function inputChangeHandler(event, identifier) {
        const update_application_form = { ...application_form };
        const form_element = { ...update_application_form["comment"] };

        form_element.value = event.target.value;

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_application_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_application_form) {
            is_form_valid = update_application_form[identifier].valid && is_form_valid;
        }

        setapplication_form(update_application_form);
        // setis_form_valid(is_form_valid);
    }
    // async SubmitHandler = (event, resultvalue) => {
    async function SubmitHandler(event, resultvalue) {
        event.preventDefault();
        let is_form_valid = true;
        // const applicationid = applicationid;
        const update_application_form = application_form;
        for (let key in update_application_form) {
            let form_element = update_application_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;
            if (key === resultvalue) {
                update_application_form["comment"] = form_element;
            }
            update_application_form[key] = form_element;
        }
        update_application_form.applicationid.value = applicaionId;
        update_application_form.userid.value = user_info["_id"];
        update_application_form.uesrname.value = user_info["userName"];
        update_application_form.type.value = resultvalue;
        setapplication_form(update_application_form);

        if (is_form_valid) {
            const form_data = {};
            for (let key in application_form) {
                // let form_element = application_form[key].value;
                form_data[key] = application_form[key].value;
            }
            try {
                if (form_data.comment === "") {
                    notification.open({
                        message: "Comment",
                        description: "Please add comment.",
                    });
                } else {
                    // setLoading(true);
                    const result = await addComment(form_data);
                    if (result && result.status === 200) {
                        setTimeout(() => {
                            notification.open({
                                message: "Comment",
                                description: "Comment added.",
                            });
                            const update_application_form_last = { ...application_form };
                            for (let key in update_application_form_last) {
                                update_application_form_last[key].value = "";
                            }
                            setapplication_form(update_application_form_last);
                        }, 1000);
                        // setreplyapplicationformreply("show");
                        // setapplicationformreply('show');
                        // setapptype(resultvalue);
                        userDetails();
                    } else if (result && result.status === 201) {
                        setTimeout(() => {
                            notification.open({
                                message: "Application",
                                description: "email/username already registered",
                            });
                        }, 1000);
                    }
                }
            } catch (e) {
            } finally {
            }
        }
    }

    async function MenualAssignApplicationSubmit(payload) {
        try {
            const resp = await assignManualUser(payload);
            if (resp && resp.data.success === true) {
                setTimeout(() => {
                    notification.open({
                        message: "Application",
                        description: "Member Assigned for review successfully.",
                    });
                }, 1000);
                setIsAssignModalVisible(false);
                userDetails();
            } else if (resp && resp.data.success === false) {
                setTimeout(() => {
                    notification.open({
                        message: "Application",
                        description: resp.data.message,
                    });
                }, 1000);
            }
        } catch (e) {
            console.log(e);
        }
    }
    // accpet Application
    const showAcceptModalHandle = (data) => {
        setIsAcceptModalVisible(true);
        setEditChapterData(data);
        setId(data._id);
    };
    // const showFinalAcceptModalHandle = (data) => {
    //     setIsFinalAcceptModalVisible(true);
    //     setEditChapterData(data);
    //     setId(data._id);
    // };
    const showExternalMemberModalHandle = (data, type) => {
        setIsExternalMemberModalVisible(true);
        setEditChapterData(data);
        setId(data._id);
        GetAssignMemberdata(data._id);
        setIsExternalMemberTypeModalVisible(type);
    };
    const showchnageothermemberpoup = (data, type) => {
        setIschnageOtherMemberModalVisible(true);
        setEditChapterData(data);
        setId(data._id);
        setIsExternalMemberTypeModalVisible(type);
    };
    const showAutoAcceptModalHandle = (data, type) => {
        setIsAutoAcceptModalVisible(true);
        setEditChapterData(data);
        setId(data._id);
        setIsExternalMemberTypeModalVisible(type);
    };
    const showAutoAcceptModalHandleTwoRound = (data, type) => {
        setIsAutoAcceptModalVisibleTwoRound(true);
        setEditChapterData(data);
        setId(data._id);
        setIsExternalMemberTypeModalVisible(type);
    };
    const showAutoAssignOtherMemberpoup = (data, resultdata, type) => {
        setIsAutoAssignOtherMemberrModalVisible(true);
        GetAssignMemberdata(data._id);
        // setEditChapterData(data);
        setId(data._id);
        setIsExternalMemberTypeModalVisible(type);
        setassignresult(resultdata);
        // console.log('resultdata 123===', getassignresult)
    };
    const handleCancel = () => {
        setIsAssignModalVisible(false);
        setIsRejectModalVisible(false);
        setIsAcceptModalVisible(false);
        setIsAcceptByUserModalVisible(false);
        setIsArchiveModalVisible(false);
        setIsSendEmailModalVisible(false);
        setIsFinalAcceptModalVisible(false);
        setIsExternalMemberModalVisible(false);
        setIschnageOtherMemberModalVisible(false);
        setIsAutoAssignOtherMemberrModalVisible(false);
        setIsAcceptByInterviewModalVisible(false);
        setIsAcceptByInterviewTwoRoundModalVisible(false);
        setIsAutoAcceptModalVisible(false);
        setIsAutoAcceptModalVisibleTwoRound(false);
        setIsInterviewTwoModalVisible(false);
        setIsAcceptFinalReviewModalVisible(false);
    };
    const showAcceptByUserModalHandle = (data) => {
        setIsAcceptByUserModalVisible(true);
        setEditChapterData(data);
        setId(data._id);
        setgettitle("Accept Application");
        setgetstatus("accept");
        setgetmessagedata("Are you sure you want to accept this application?");
    };
    const showAcceptFinalReviewModalHandle = (data) => {
        setIsAcceptFinalReviewModalVisible(true);
        setEditChapterData(data);
        setId(data._id);
        setgettitle("Accept Application");
        setgetstatus("accept");
        setgetmessagedata("Are you sure you want to accept this application?");
    };
    const showRejectByUserModalHandle = (data) => {
        setIsAcceptByUserModalVisible(true);
        setEditChapterData(data);
        setId(data._id);
        setgettitle("Reject Application");
        setgetstatus("reject");
        setgetmessagedata("Are you sure you want to reject this application?");
    };
    const showAcceptByInterviewModalHandle = (data) => {
        setIsAcceptByInterviewModalVisible(true);
        setEditChapterData(data);
        setId(data._id);
        setgettitle("Accept Interview Application");
        setgetstatus("accept");
        setgetmessagedata("Are you sure you want to accept this application?");
    };
    const showAcceptByInterviewTwoRoundModalHandle = (data) => {
        setIsAcceptByInterviewTwoRoundModalVisible(true);
        setEditChapterData(data);
        setId(data._id);
        setgettitle("Accept Interview Round Two Application");
        setgetstatus("accept");
        setgetmessagedata("Are you sure you want to accept this application?");
    };


    const showRejectByInterviewModalHandle = (data) => {
        setIsAcceptByInterviewModalVisible(true);
        setEditChapterData(data);
        setId(data._id);
        setgettitle("Reject Interview Application");
        setgetstatus("reject");
        setgetmessagedata("Are you sure you want to reject this application?");
    };

    const showRejectByInterviewTwoRoundModalHandle = (data) => {
        setIsAcceptByInterviewTwoRoundModalVisible(true);
        setEditChapterData(data);
        setId(data._id);
        setgettitle("Reject Interview Round Two Application");
        setgetstatus("reject");
        setgetmessagedata("Are you sure you want to reject this application?");
    };


    const showArchiveModalHandle = (data, status) => {
        setIsArchiveModalVisible(true);
        setEditChapterData(data);
        setId(data._id);
        setarchivestatus(status);
        if (status === 1) {
            setgettitle("Archive Application");
            setgetmessagedata("Are you sure you want to archive this application?");
        } else {
            setgettitle("Unarchive Application");
            setgetmessagedata("Are you sure you want to unarchive this application?");
        }
    };
    const showSendEmailModalHandle = (data) => {
        setIsSendEmailModalVisible(true);
        setEditChapterData(data);
        setId(data._id);
    };
    const showInterviewTwoModalHandle = (data) => {
        GetAssignMemberdata(data._id);
        setIsInterviewTwoModalVisible(true);
        setEditChapterData(data);
        setId(data._id);
    };

    async function ScheduleInterviewTwoRound(payload) {
        try {
            const resp = await scheduleInterviewTwoRound(payload);
            if (resp && resp.data.success === true) {
                history.push("/application-detail/" + applicaionId);
                userDetails();
                setIsInterviewTwoModalVisible(false);
                setTimeout(() => {
                    notification.open({
                        message: "Round Two Schedule",
                        description: 'Interview Round Two Scheduled successfully',
                    });
                }, 1000);
            } else if (resp && resp.data.success === false) {
                setTimeout(() => {
                    notification.open({
                        message: "Application",
                        description: resp.data.message,
                    });
                }, 1000);
            }
        } catch (e) {
            console.log(e);
        }
    }

    async function Donotsetup(resultdata) {
        const payload = {
            applicaionId: resultdata._id,
            memberId: user_info._id
        };
        console.log('Donotsetup====', payload)
        try {
            const resp = await notShowUpInterview(payload);
            if (resp && resp.data.success === true) {
                history.push("/flagged-application-review/" + resultdata._id);
                setTimeout(() => {
                    notification.open({
                        message: "Application",
                        description: "Updated successfully.",
                    });
                }, 1000);
                userDetails();
            } else if (resp && resp.data.success === false) {
                setTimeout(() => {
                    notification.open({
                        message: "Application",
                        description: resp.data.message,
                    });
                }, 1000);
            }
        } catch (e) {
            console.log(e);
        }
    }
    async function DownloadPdfFile(resultdata) {

        const payload = {
            applicaionId: resultdata._id,
            memberId: user_info._id,
            created_id: user_info._id,
        };
        try {
            const resp = await getApplicationPdf(payload);
            if (resp && resp.data.success === true) {
                const csv_url = resp.data.data.url;
                DownloadCsv(csv_url);
                setTimeout(() => {
                    notification.open({
                        message: "Application",
                        description: "Application pdf download successfully",
                    });
                }, 1000);
                userDetails();
            } else if (resp && resp.data.success === false) {
                setTimeout(() => {
                    notification.open({
                        message: "Application",
                        description: resp.data.message,
                    });
                }, 1000);
            }
        } catch (e) {
            console.log(e);
        }
    }
    const DownloadCsv = (csv_url) => {
        const link = document.createElement("a");
        link.href = csv_url;
        link.setAttribute('target', "_blank");
        link.setAttribute("download", "download");
        document.body.appendChild(link);
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }
    async function MenualAutoAssignAdminApplicationSubmit(payload) {
        try {
            const resp = await interviewacceptByAdmin(payload);
            if (resp && resp.data.success === true) {
                setTimeout(() => {
                    notification.open({
                        message: "Application",
                        description: "Application accepted successfully",
                    });
                }, 1000);
                userDetails();
                setIsAutoAcceptModalVisible(false);
            } else if (resp && resp.data.success === false) {
                setTimeout(() => {
                    notification.open({
                        message: "Application",
                        description: resp.data.message,
                    });
                }, 1000);
            }
        } catch (e) {
            console.log(e);
        }
    }
    async function MenualAutoAssignAdminApplicationSubmitTwoRound(payload) {
        try {
            const resp = await interviewTwoRoundacceptByAdmin(payload);
            if (resp && resp.data.success === true) {
                setTimeout(() => {
                    notification.open({
                        message: "Application",
                        description: "Application accepted successfully",
                    });
                }, 1000);
                userDetails();
                setIsAutoAcceptModalVisibleTwoRound(false);
            } else if (resp && resp.data.success === false) {
                setTimeout(() => {
                    notification.open({
                        message: "Application",
                        description: resp.data.message,
                    });
                }, 1000);
            }
        } catch (e) {
            console.log(e);
        }
    }
    async function AutoRepleaceMemberApplicationSubmit(payload) {
        console.log("payload==", payload);
        // setIschnageOtherMemberModalVisible(false);
        // setIsAutoAssignOtherMemberrModalVisible(false);
        // setIschnageOtherMemberModalVisible(true);
        // setEditChapterData(getapplication_accepted_assign);
        // setId(payload.applicaionId);
        // setIsExternalMemberTypeModalVisible(payload.type);
        // setgetlastMemberId(payload.memberId)
        // setIsSendEmailModalVisible(false);
        try {
            const resp = await assignOtherMember(payload);
            if (resp && resp.data.success === true) {
                setTimeout(() => {
                    notification.open({
                        message: "Application",
                        description: "Application assigned to other member successfully. ",
                    });
                }, 1000);
                userDetails();
                setIschnageOtherMemberModalVisible(false);
            } else if (resp && resp.data.success === false) {
                setTimeout(() => {
                    notification.open({
                        message: "Application",
                        description: resp.data.message,
                    });
                }, 1000);
                setIschnageOtherMemberModalVisible(false);
            }
        } catch (e) {
            console.log(e);
        }
    }
    async function MenualAutoAssignOtherMemberApplicationSubmit(payload) {
        console.log("payload==", payload);
        // console.log('getassignresult==11==', getassignresult)
        setIsAutoAssignOtherMemberrModalVisible(false);
        setIschnageOtherMemberModalVisible(true);
        setEditChapterData(getassignresult);
        setId(payload.applicaionId);
        setIsExternalMemberTypeModalVisible(payload.type);
        setgetlastMemberId(payload.memberId);
    }
    async function ExternalMemberApplicationSubmit(payload) {
        console.log("payload==", payload);
        try {
            const resp = await sendExternalLinkEmail(payload);
            if (resp && resp.data.success === true) {
                setTimeout(() => {
                    notification.open({
                        message: "Application",
                        description: "Link sent to email successfully.",
                    });
                }, 1000);
                userDetails();
                setIsExternalMemberModalVisible(false);
            } else if (resp && resp.data.success === false) {
                setTimeout(() => {
                    notification.open({
                        message: "Application",
                        description: resp.data.message,
                    });
                }, 1000);
            }
        } catch (e) {
            console.log(e);
        }
    }
    async function ApplicationSendEmailSubmit(value) {
        const payload = {
            applicaionId: value,
            memberId: user_info._id,
            created_id: user_info._id,
        };
        console.log("payload==", payload);
        // setIsSendEmailModalVisible(false);
        try {
            const resp = await sendProgressUrl(payload);
            if (resp && resp.data.success === true) {
                setTimeout(() => {
                    notification.open({
                        message: "Application",
                        description: "Progress URL sent successfully",
                    });
                }, 1000);
                userDetails();
                setIsSendEmailModalVisible(false);
            } else if (resp && resp.data.success === false) {
                setTimeout(() => {
                    notification.open({
                        message: "Application",
                        description: resp.data.message,
                    });
                }, 1000);
            }
        } catch (e) {
            console.log(e);
        }
    }
    async function ApplicationArchiveSubmit(payload) {
        try {
            let description = "";
            if (payload.status === 1) {
                description = "Application archived.";
            } else {
                description = "Application unarchived.";
            }
            const resp = await updateApplicationStatus(payload);
            if (resp && resp.data.success === true) {
                if (payload.status === 1) {
                    history.push("/archive-application");
                } else {
                    history.push("/application");
                }
                setTimeout(() => {
                    notification.open({
                        message: "Application",
                        description: description,
                    });
                }, 1000);
                userDetails();
                setIsArchiveModalVisible(false);
            } else if (resp && resp.data.success === false) {
                setTimeout(() => {
                    notification.open({
                        message: "Application",
                        description: resp.data.message,
                    });
                }, 1000);
            }
        } catch (e) {
            console.log(e);
        }
    }
    async function AcceptRejectByUserApplicationSubmit(payload) {
        // const payload = {
        //     applicaionId: value,
        // };
        try {
            if (applicationdetaildata.application_accepted.user_one.user_one_id === user_info._id) {
                const resp = await userOneStatus(payload);
                if (resp && resp.data.success === true) {
                    setTimeout(() => {
                        notification.open({
                            message: "Application",
                            description: "Application " + payload.status + "ed successfully", //resp.data.message,
                        });
                    }, 1000);
                    userDetails();
                    setIsAcceptByUserModalVisible(false);
                } else if (resp && resp.data.success === false) {
                    setTimeout(() => {
                        notification.open({
                            message: "Application",
                            // description: resp.data.message,
                            description: "Application " + payload.status + "ed successfully", //resp.data.message,
                        });
                    }, 1000);
                }
            } else if (applicationdetaildata.application_accepted.user_two.user_two_id === user_info._id) {
                const resp = await userTwoStatus(payload);
                if (resp && resp.data.success === true) {
                    setTimeout(() => {
                        notification.open({
                            message: "Application",
                            // description: resp.data.message,
                            description: "Application " + payload.status + "ed successfully", //resp.data.message,
                        });
                    }, 1000);
                    userDetails();
                    setIsAcceptByUserModalVisible(false);
                } else if (resp && resp.data.success === false) {
                    setTimeout(() => {
                        notification.open({
                            message: "Application",
                            description: resp.data.message,
                        });
                    }, 1000);
                }
            } else if (applicationdetaildata.application_accepted.user_three.user_three_id === user_info._id) {
                const resp = await userThreeStatus(payload);
                if (resp && resp.data.success === true) {
                    setTimeout(() => {
                        notification.open({
                            message: "Application",
                            //description: resp.data.message,
                            description: "Application " + payload.status + "ed successfully", //resp.data.message,
                        });
                    }, 1000);
                    userDetails();
                    setIsAcceptByUserModalVisible(false);
                } else if (resp && resp.data.success === false) {
                    setTimeout(() => {
                        notification.open({
                            message: "Application",
                            description: resp.data.message,
                        });
                    }, 1000);
                }
            }
            // const resp = await assignTwoUser(payload);
        } catch (e) {
            console.log(e);
        }
    }
    // Final accpet Applicetion
    // accpet Interview round 1
    async function AcceptRejectByInterviewApplicationSubmit(payload) {
        console.log("payload 123==", payload);
        // const payload = {
        //     applicaionId: value,
        // };
        try {
            if (applicationdetaildata.application_interview.interview_one.interview_one_id === user_info._id) {
                const resp = await interviewOneStatus(payload);
                if (resp && resp.data.success === true) {
                    setTimeout(() => {
                        notification.open({
                            message: "Application",
                            description: "Application " + payload.status + "ed successfully", //resp.data.message,
                        });
                    }, 1000);
                    userDetails();
                    setIsAcceptByInterviewModalVisible(false);
                } else if (resp && resp.data.success === false) {
                    setTimeout(() => {
                        notification.open({
                            message: "Application",
                            // description: resp.data.message,
                            description: "Application " + payload.status + "ed successfully", //resp.data.message,
                        });
                    }, 1000);
                }
            } else if (applicationdetaildata.application_interview.interview_two.interview_two_id === user_info._id) {
                const resp = await interviewTwoStatus(payload);
                if (resp && resp.data.success === true) {
                    setTimeout(() => {
                        notification.open({
                            message: "Application",
                            // description: resp.data.message,
                            description: "Application " + payload.status + "ed successfully", //resp.data.message,
                        });
                    }, 1000);
                    userDetails();
                    setIsAcceptByInterviewModalVisible(false);
                } else if (resp && resp.data.success === false) {
                    setTimeout(() => {
                        notification.open({
                            message: "Application",
                            description: resp.data.message,
                        });
                    }, 1000);
                }
            } else if (applicationdetaildata.application_interview.interview_three.interview_three_id === user_info._id) {
                const resp = await interviewThreeStatus(payload);
                if (resp && resp.data.success === true) {
                    setTimeout(() => {
                        notification.open({
                            message: "Application",
                            //description: resp.data.message,
                            description: "Application " + payload.status + "ed successfully", //resp.data.message,
                        });
                    }, 1000);
                    userDetails();
                    setIsAcceptByInterviewModalVisible(false);
                } else if (resp && resp.data.success === false) {
                    setTimeout(() => {
                        notification.open({
                            message: "Application",
                            description: resp.data.message,
                        });
                    }, 1000);
                }
            } else if (user_role.Allapplication === "yes") {
                console.log("ttttt");
                let resp
                if (payload.status === "accept") {
                    resp = await interviewacceptByAdmin(payload);
                } else {
                    resp = await rejectApplication(payload);
                }
                if (resp && resp.data.success === true) {
                    setTimeout(() => {
                        notification.open({
                            message: "Application",
                            //description: resp.data.message,
                            description: "Application " + payload.status + "ed successfully", //resp.data.message,
                        });
                    }, 1000);
                    userDetails();
                    setIsAcceptByInterviewModalVisible(false);
                } else if (resp && resp.data.success === false) {
                    setTimeout(() => {
                        notification.open({
                            message: "Application",
                            description: resp.data.message,
                        });
                    }, 1000);
                }
            }

            // const resp = await assignTwointerview(payload);
        } catch (e) {
            console.log(e);
        }
    }

    // accpet Interview round 2
    async function AcceptRejectByInterviewTwoRoundApplicationSubmit(payload) {
        console.log("payload 123==", payload);
        // const payload = {
        //     applicaionId: value,
        // };
        try {
            if (applicationdetaildata.application_interviewTwoRound.interviewTwoRound_one.interviewTwoRound_one_id === user_info._id) {
                const resp = await interviewTwoRoundOneStatus(payload);
                if (resp && resp.data.success === true) {
                    setTimeout(() => {
                        notification.open({
                            message: "Application",
                            description: "Application " + payload.status + "ed successfully", //resp.data.message,
                        });
                    }, 1000);
                    userDetails();
                    setIsAcceptByInterviewTwoRoundModalVisible(false);
                } else if (resp && resp.data.success === false) {
                    setTimeout(() => {
                        notification.open({
                            message: "Application",
                            // description: resp.data.message,
                            description: "Application " + payload.status + "ed successfully", //resp.data.message,
                        });
                    }, 1000);
                }
            } else if (applicationdetaildata.application_interviewTwoRound.interviewTwoRound_two.interviewTwoRound_two_id === user_info._id) {
                const resp = await interviewTwoRoundTwoStatus(payload);
                if (resp && resp.data.success === true) {
                    setTimeout(() => {
                        notification.open({
                            message: "Application",
                            // description: resp.data.message,
                            description: "Application " + payload.status + "ed successfully", //resp.data.message,
                        });
                    }, 1000);
                    userDetails();
                    setIsAcceptByInterviewTwoRoundModalVisible(false);
                } else if (resp && resp.data.success === false) {
                    setTimeout(() => {
                        notification.open({
                            message: "Application",
                            description: resp.data.message,
                        });
                    }, 1000);
                }
            } else if (user_role.Allapplication === "yes") {
                console.log("ttttt");
                let resp
                if (payload.status === "accept") {
                    resp = await interviewTwoRoundacceptByAdmin(payload);
                } else {
                    resp = await rejectApplication(payload);
                }
                if (resp && resp.data.success === true) {
                    setTimeout(() => {
                        notification.open({
                            message: "Application",
                            //description: resp.data.message,
                            description: "Application " + payload.status + "ed successfully", //resp.data.message,
                        });
                    }, 1000);
                    userDetails();
                    setIsAcceptByInterviewTwoRoundModalVisible(false);
                } else if (resp && resp.data.success === false) {
                    setTimeout(() => {
                        notification.open({
                            message: "Application",
                            description: resp.data.message,
                        });
                    }, 1000);
                }
            }

            // const resp = await assignTwointerviewTwoRound(payload);
        } catch (e) {
            console.log(e);
        }
    }

    async function AcceptFinalReviewByAllApplicationSubmit(payload) {
        console.log("value====", payload);
        try {
            if (applicationdetaildata.application_accepted.user_one.user_one_id === user_info._id) {
                const resp = await userOneStatus(payload);
                if (resp && resp.data.success === true) {
                    setTimeout(() => {
                        notification.open({
                            message: "Application",
                            description: "Application " + payload.status + "ed successfully", //resp.data.message,
                        });
                    }, 1000);
                    userDetails();
                    setIsAcceptFinalReviewModalVisible(false);
                } else if (resp && resp.data.success === false) {
                    setTimeout(() => {
                        notification.open({
                            message: "Application",
                            // description: resp.data.message,
                            description: "Application " + payload.status + "ed successfully", //resp.data.message,
                        });
                    }, 1000);
                }
            } else if (applicationdetaildata.application_accepted.user_two.user_two_id === user_info._id) {
                const resp = await userTwoStatus(payload);
                if (resp && resp.data.success === true) {
                    setTimeout(() => {
                        notification.open({
                            message: "Application",
                            // description: resp.data.message,
                            description: "Application " + payload.status + "ed successfully", //resp.data.message,
                        });
                    }, 1000);
                    userDetails();
                    setIsAcceptFinalReviewModalVisible(false);
                } else if (resp && resp.data.success === false) {
                    setTimeout(() => {
                        notification.open({
                            message: "Application",
                            description: resp.data.message,
                        });
                    }, 1000);
                }
            } else if (applicationdetaildata.application_accepted.user_three.user_three_id === user_info._id) {
                const resp = await userThreeStatus(payload);
                if (resp && resp.data.success === true) {
                    setTimeout(() => {
                        notification.open({
                            message: "Application",
                            //description: resp.data.message,
                            description: "Application " + payload.status + "ed successfully", //resp.data.message,
                        });
                    }, 1000);
                    userDetails();
                    setIsAcceptFinalReviewModalVisible(false);
                } else if (resp && resp.data.success === false) {
                    setTimeout(() => {
                        notification.open({
                            message: "Application",
                            description: resp.data.message,
                        });
                    }, 1000);
                }
            } else {
                const resp = await intialAcceptByAdmin(payload);
                if (resp && resp.data.success === true) {
                    setTimeout(() => {
                        notification.open({
                            message: "Application",
                            description: "Application accepted successfully",
                        });
                    }, 1000);
                    userDetails();
                    setIsAcceptFinalReviewModalVisible(false);
                } else if (resp && resp.data.success === false) {
                    setTimeout(() => {
                        notification.open({
                            message: "Application",
                            description: resp.data.message,
                        });
                    }, 1000);
                }
            }
            // const resp = await intialAcceptByAdmin(payload);
            // if (resp && resp.data.success === true) {
            //     setTimeout(() => {
            //         notification.open({
            //             message: "Application",
            //             description: "Application accepted successfully",
            //         });
            //     }, 1000);
            //     userDetails();
            //     setIsAcceptFinalReviewModalVisible(false);
            // } else if (resp && resp.data.success === false) {
            //     setTimeout(() => {
            //         notification.open({
            //             message: "Application",
            //             description: resp.data.message,
            //         });
            //     }, 1000);
            // }
        } catch (e) {
            console.log(e);
        }
    }
    // end accept interview rount 1
    async function FinalAcceptApplicationSubmit(value) {
        const payload = {
            applicaionId: value,
            memberId: user_info._id,
            created_id: user_info._id,
        };
        try {
            const resp = await acceptByAdmin(payload);
            if (resp && resp.data.success === true) {
                setTimeout(() => {
                    notification.open({
                        message: "Application",
                        description: "Application accepted successfully",
                    });
                }, 1000);
                userDetails();
                setIsFinalAcceptModalVisible(false);
            } else if (resp && resp.data.success === false) {
                setTimeout(() => {
                    notification.open({
                        message: "Application",
                        description: resp.data.message,
                    });
                }, 1000);
            }
        } catch (e) {
            console.log(e);
        }
    }
    async function AcceptApplicationSubmit(value) {
        const payload = {
            applicaionId: value,
            memberId: user_info._id,
            created_id: user_info._id,
        };
        try {
            const resp = await assignTwoUser(payload);
            if (resp && resp.data.success === true) {
                setTimeout(() => {
                    notification.open({
                        message: "Application",
                        description: "Application accepted successfully",
                    });
                }, 1000);
                userDetails();
                setIsAcceptModalVisible(false);
            } else if (resp && resp.data.success === false) {
                setTimeout(() => {
                    notification.open({
                        message: "Application",
                        description: resp.data.message,
                    });
                }, 1000);
            }
        } catch (e) {
            console.log(e);
        }
    }

    async function showOfferLetterModalHandle(data, status) {
        const payload = {
            applicaionId: data._id,
            memberId: user_info._id,
            created_id: user_info._id,
            status: status
        };
        try {
            const resp = await acceptRejectOffer(payload);
            if (resp && resp.data.success === true) {
                setTimeout(() => {
                    if (status === "accept") {
                        notification.open({
                            message: "Application",
                            description: "Application offer letter accepted.",
                        });
                    } else {
                        notification.open({
                            message: "Application",
                            description: "Application offer letter rejcted.",
                        });
                    }
                }, 1000);
                userDetails();
            } else if (resp && resp.data.success === false) {
                setTimeout(() => {
                    notification.open({
                        message: "Application",
                        description: resp.data.message,
                    });
                }, 1000);
            }
        } catch (e) {
            console.log(e);
        }
    }

    // Reject Application
    const showRejectModalHandle = (data) => {
        setIsRejectModalVisible(true);
        setEditChapterData(data);
        setId(data._id);
    };

    async function RejectApplicationSubmit(value) {
        const payload = {
            applicaionId: value,
            memberId: user_info._id,
            created_id: user_info._id,
            status: "reject",
        };
        try {
            if (applicationdetaildata.application_accepted.user_one.user_one_id === user_info._id) {
                const resp = await userOneStatus(payload);
                if (resp && resp.data.success === true) {
                    setTimeout(() => {
                        notification.open({
                            message: "Application",
                            description: "Application rejected successfully", //resp.data.message,
                        });
                    }, 1000);
                    userDetails();
                    setIsRejectModalVisible(false);
                } else if (resp && resp.data.success === false) {
                    setTimeout(() => {
                        notification.open({
                            message: "Application",
                            // description: resp.data.message,
                            description: resp.data.message,
                        });
                    }, 1000);
                }
            } else if (applicationdetaildata.application_accepted.user_two.user_two_id === user_info._id) {
                const resp = await userTwoStatus(payload);
                if (resp && resp.data.success === true) {
                    setTimeout(() => {
                        notification.open({
                            message: "Application",
                            // description: resp.data.message,
                            description: "Application rejected successfully", //resp.data.message,
                        });
                    }, 1000);
                    userDetails();
                    setIsRejectModalVisible(false);
                } else if (resp && resp.data.success === false) {
                    setTimeout(() => {
                        notification.open({
                            message: "Application",
                            description: resp.data.message,
                        });
                    }, 1000);
                }
            } else if (applicationdetaildata.application_accepted.user_three.user_three_id === user_info._id) {
                const resp = await userThreeStatus(payload);
                if (resp && resp.data.success === true) {
                    setTimeout(() => {
                        notification.open({
                            message: "Application",
                            //description: resp.data.message,
                            description: "Application rejected successfully", //resp.data.message,
                        });
                    }, 1000);
                    userDetails();
                    setIsRejectModalVisible(false);
                } else if (resp && resp.data.success === false) {
                    setTimeout(() => {
                        notification.open({
                            message: "Application",
                            description: resp.data.message,
                        });
                    }, 1000);
                }
            } else {
                // else if (user_role.Allapplication === "yes") {
                const resp = await rejectApplication(payload);
                if (resp && resp.data.success === true) {
                    setTimeout(() => {
                        notification.open({
                            message: "Application",
                            description: "Application rejected successfully",
                        });
                    }, 1000);
                    userDetails();
                    setIsRejectModalVisible(false);
                } else if (resp && resp.data.success === false) {
                    setTimeout(() => {
                        notification.open({
                            message: "Application",
                            description: resp.data.message,
                        });
                    }, 1000);
                }
            }
        } catch (e) {
            console.log(e);
        }
    }
    // console.log("setgetapplication_accepted_assign==", isExternalMemberTypeModalVisible);
    return (
        <div className="d-flex flex-column flex-root header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed h-100">
            <div className="page d-flex flex-row flex-column-fluid">
                <LeftSideBar />
                <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                    <TopBar />

                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                        <div className="toolbar" id="kt_toolbar">
                            <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                                <div
                                    data-kt-swapper="true"
                                    data-kt-swapper-mode="prepend"
                                    data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
                                    className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
                                >
                                    <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
                                        Applications
                                        <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                                    </h1>

                                    <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                                        <li className="breadcrumb-item text-muted">
                                            <Link to="/dashboard" className="text-muted text-hover-primary">
                                                Home
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <span className="bullet bg-gray-200 w-5px h-2px"></span>
                                        </li>
                                        <li className="breadcrumb-item text-muted">
                                            {applicationdetaildata.archive && applicationdetaildata.archive === 1 ? applicationdetaildata.archive && applicationdetaildata.rejected === 1 && applicationdetaildata.autoReject === 0 ? (
                                                <Link to="/rejected-application" className="text-muted text-hover-primary">
                                                    Rejected application
                                                </Link>
                                            ) : (
                                                <Link to="/archive-application" className="text-muted text-hover-primary">
                                                    Archive application
                                                </Link>
                                            ) : (
                                                <Link to="/application" className="text-muted text-hover-primary">
                                                    Application
                                                </Link>
                                            )}
                                        </li>
                                        <li className="breadcrumb-item">
                                            <span className="bullet bg-gray-200 w-5px h-2px"></span>
                                        </li>

                                        {getrepeat === 1 ? <li className="breadcrumb-item text-dark">Repeated Applications</li> : <li className="breadcrumb-item text-dark">Candidate Details</li>}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="post d-flex flex-column-fluid" id="kt_post">
                            <div id="kt_content_container" className="container-xxl">
                                {/* <Form name="normal_login" className="login-form" initialValues={{ remember: true }}> */}
                                <div className="row gy-5 g-xl-8">
                                    <div className="col-xl-12">
                                        <div className="card card-xl-stretch mb-8">
                                            <div className="card-header border-0">
                                                <h3 className="card-title fw-bolder text-dark">
                                                    {getrepeat === 1 ? (
                                                        <>Repeated Applications</>
                                                    ) : (
                                                        <>
                                                            Candidate Details
                                                            {applicationdetaildata.rejected !== 1 && applicationdetaildata.rejected !== "1" ? (
                                                                applicationdetaildata.archive && applicationdetaildata.archive === 1 ? (
                                                                    <Tooltip title="Unarchive">
                                                                        <span onClick={() => showArchiveModalHandle(applicationdetaildata, 0)} className="btn-sm archive_click">
                                                                            <i className="fa fa-archive"></i>
                                                                        </span>
                                                                    </Tooltip>
                                                                ) : (
                                                                    <Tooltip title="Archive">
                                                                        <span onClick={() => showArchiveModalHandle(applicationdetaildata, 1)} className="btn-sm archive_click">
                                                                            <i className="fa fa-archive"></i>
                                                                        </span>
                                                                    </Tooltip>
                                                                )) : (<></>)}
                                                        </>
                                                    )}
                                                </h3>

                                                <div className="card-toolbar my-1">
                                                    <div className="me-3 app-next-last btn-sm ">
                                                        {applicationdetaildata.previous && (
                                                            <Link to={`/application-detail/${applicationdetaildata.previous}${arrayulrvalue}`}><i className="fa fa-arrow-circle-left me-3 fs-2" aria-hidden="true"></i></Link>
                                                        )}
                                                        {applicationdetaildata.next && (
                                                            <Link to={`/application-detail/${applicationdetaildata.next}${arrayulrvalue}`}><i className="fa fa-arrow-circle-right me-3 fs-2" aria-hidden="true"></i></Link>
                                                        )}

                                                    </div>
                                                    {/* {user_role && user_role.applicationPlaigarism && user_role.applicationPlaigarism === "yes" && ( */}
                                                    <div className="result-main bg-light-primary border-primary border border-dashed mt-4">
                                                        <span className="fw-bolder">
                                                            Plagiarism <br />
                                                            {applicationdetaildata.palagrism ? applicationdetaildata.palagrism : ""}%
                                                        </span>
                                                    </div>
                                                    {/* )} */}
                                                </div>

                                            </div>
                                            {getrepeat === 1 ? (
                                                <div className="card-body pt-2">
                                                    <div className="row">
                                                        <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                            <label className="col-form-label fw-bold fs-6">Name</label>
                                                            <div className="form-control form-control-lg form-control-solid mb-3 mb-lg-0">
                                                                {applicationdetaildata.firstname ? applicationdetaildata.firstname : ""} {applicationdetaildata.lastname ? applicationdetaildata.lastname : ""}
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                            <label className="col-form-label fw-bold fs-6">Email</label>
                                                            <div className="form-control form-control-lg form-control-solid mb-3 mb-lg-0">{applicationdetaildata.email ? applicationdetaildata.email : ""}</div>
                                                        </div>

                                                        <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                            <label className="col-form-label  fw-bold fs-6">Applied For</label>
                                                            <div className="form-control form-control-lg form-control-solid mb-3 mb-lg-0">{applicationdetaildata.TagName ? applicationdetaildata.TagName : ""}</div>
                                                        </div>

                                                        <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                            <label className="col-form-label  fw-bold fs-6">Phone Number</label>
                                                            <div className="form-control form-control-lg form-control-solid mb-3 mb-lg-0">{applicationdetaildata.phone ? applicationdetaildata.phone : ""}</div>
                                                        </div>
                                                        <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                            <label className="col-form-label  fw-bold fs-6">Date</label>
                                                            <div className="form-control form-control-lg form-control-solid mb-3 mb-lg-0">
                                                                {applicationdetaildata.createdAt ? Moment(applicationdetaildata.createdAt).format("DD-MM-YYYY") : ""}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                            <label className="col-form-label  fw-bold fs-6">Status (Last Time)</label>
                                                            <div className="form-control form-control-lg form-control-solid mb-3 mb-lg-0">{applicationdetaildata.status ? applicationdetaildata.status : ""}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="card-body pt-2">
                                                    <div className="row">
                                                        <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                            <label className="col-form-label fw-bold fs-6">Name</label>
                                                            <div className="form-control form-control-lg form-control-solid mb-3 mb-lg-0">
                                                                {applicationdetaildata.firstname ? applicationdetaildata.firstname : ""} {applicationdetaildata.lastname ? applicationdetaildata.lastname : ""}
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                            <label className="col-form-label fw-bold fs-6">Email</label>
                                                            <input
                                                                type="email"
                                                                name="fname"
                                                                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                                value={applicationdetaildata.email ? applicationdetaildata.email : ""}
                                                                readOnly
                                                            />
                                                        </div>

                                                        <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                            <label className="col-form-label  fw-bold fs-6">Applied For</label>
                                                            <input
                                                                type="text"
                                                                name="fname"
                                                                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                                value={applicationdetaildata.TagName ? applicationdetaildata.TagName : ""}
                                                                readOnly
                                                            />
                                                        </div>
                                                        <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                            <label className="col-form-label  fw-bold fs-6">Age</label>
                                                            <input
                                                                type="text"
                                                                name="fname"
                                                                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                                value={applicationdetaildata.age ? applicationdetaildata.age : ""}
                                                                readOnly
                                                            />
                                                        </div>
                                                        <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                            <label className="col-form-label  fw-bold fs-6">Gender</label>
                                                            <input
                                                                type="text"
                                                                name="fname"
                                                                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                                value={applicationdetaildata.gender ? applicationdetaildata.gender : ""}
                                                                readOnly
                                                            />
                                                        </div>
                                                        <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                            <label className="col-form-label  fw-bold fs-6">Country</label>
                                                            {updatedCountries.map((option) => (
                                                                <>{option.id === applicationdetaildata.country && <div className="form-control form-control-lg form-control-solid mb-3 mb-lg-0">{option.name}</div>}</>
                                                            ))}
                                                        </div>

                                                        <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                            <label className="col-form-label  fw-bold fs-6">Phone Number</label>
                                                            <input
                                                                type="text"
                                                                name="fname"
                                                                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                                value={applicationdetaildata.phone ? applicationdetaildata.phone : ""}
                                                                readOnly
                                                            />
                                                        </div>
                                                        <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                            <label className="col-form-label  fw-bold fs-6">Date</label>
                                                            <input
                                                                type="text"
                                                                name="fname"
                                                                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                                value={applicationdetaildata.createdAt ? Moment(applicationdetaildata.createdAt).format("DD-MM-YYYY") : ""}
                                                                readOnly
                                                            />
                                                        </div>
                                                        <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                            <label className="col-form-label  fw-bold fs-6">Repeated Candidates</label>
                                                            <div className="form-control form-control-lg form-control-solid mb-3 mb-lg-0">
                                                                {applicationdetaildata && applicationdetaildata.repeat && applicationdetaildata.repeat === "Yes" && applicationdetaildata.repeatlastid ? (
                                                                    <span className="text">
                                                                        Yes{" "}
                                                                        <Link target="_blank" to={`/application-detail/${applicationdetaildata.repeatlastid}?repeat=yes`}>
                                                                            (View)
                                                                        </Link>{" "}
                                                                    </span>
                                                                ) : (
                                                                    "No"
                                                                )}
                                                            </div>
                                                        </div>
                                                        {applicationdetaildata.image && (
                                                            <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                                <label className="col-form-label  fw-bold fs-6">Resume</label>
                                                                <span className="doc-main">
                                                                    {applicationdetaildata.image ? applicationdetaildata.image.split("/").pop() : ""}
                                                                    <Link to={{ pathname: applicationdetaildata.image }} target="_blank" download>
                                                                        {" "}
                                                                        Download
                                                                    </Link>
                                                                </span>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {/* start que form */}
                                <div className="row gy-5 g-xl-8">
                                    <div className="col-xl-12">
                                        <div className="card card-xl-stretch mb-xl-8">
                                            <div className="card-header border-0 progress-mobile">
                                                {getrepeat === 1 ? (
                                                    <h3 className="card-title fw-bolder text-dark previous_application">Previous Applications (Overview)</h3>
                                                ) : (
                                                    <>
                                                        <h3 className="card-title fw-bolder text-dark">Progress</h3>
                                                        <div className="card-toolbar mt-2">
                                                            <div className="me-6 my-4">
                                                                <div className="symbol pdf-doc ">
                                                                    {user_role.applicationUrl && user_role.applicationUrl === "yes" ? (
                                                                        <span className="me-4 text-hover-primary archive_click fw-bolder" onClick={() => showSendEmailModalHandle(applicationdetaildata)}>
                                                                            <i className="bi bi-box-arrow-right fs-6"></i> Send progress URL{" "}
                                                                        </span>
                                                                    ) : (
                                                                        ""
                                                                    )}

                                                                    <span className="me-4  text-hover-primary archive_click fw-bolder" onClick={() => DownloadPdfFile(applicationdetaildata)}>
                                                                        <i className="fa fa-file-pdf" aria-hidden="true"></i> Download Pdf
                                                                    </span>
                                                                    {/*
                                                                <span className="text-hover-primary send-email-txt fw-bolder">
                                                                    <span className="me-4 archive_click">
                                                                        <i className="bi bi-envelope-fill"></i> Send Email
                                                                    </span>
                                                                </span> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}

                                                <div className="card-body pt-sm-2 timeline-outer-padding">
                                                    <div className="row">
                                                        <div className="timeline timeline-new-one">
                                                            <div className="timeline-items">
                                                                <div className="timeline-item">
                                                                    <div className="timeline-media">
                                                                        <span className="fs-2 fw-bolder date-one">{applicationdetaildata.createdAt ? Moment(applicationdetaildata.createdAt).format("DD") : ""} </span>
                                                                        <br />
                                                                        <span className="opacity-50 fs-8 fw-bold">{applicationdetaildata.createdAt ? Moment(applicationdetaildata.createdAt).format("MMM YYYY") : ""}</span>
                                                                    </div>
                                                                    <div className="timeline-content">
                                                                        <div className="d-flex align-items-center justify-content-between mb-3">
                                                                            <div className="mr-2">
                                                                                <h3 className="text-dark-75 font-weight-bold fs-4">Application Form</h3>
                                                                                <span className="text-muted ml-2">{applicationdetaildata.createdAt ? Moment(applicationdetaildata.createdAt).format("h:mm a") : ""}</span>
                                                                            </div>
                                                                        </div>

                                                                        <h5>Q1: How did you learn about Girl Power Talk? If you were referred by someone, please provide their full name and your relationship to that person.</h5>
                                                                        <p className="p-0">{applicationdetaildata.learnabout ? applicationdetaildata.learnabout : ""}</p>
                                                                        {/* <p className="p-0">{applicationdetaildata.learnabout ? applicationdetaildata.learnabout : ""}</p> */}
                                                                        {/* {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                            <>
                                                                                {Plagiarismresponcedata.map((item, idx) => (
                                                                                    <div key={idx}>
                                                                                        {item.order && item.order === 1 && (
                                                                                            <>
                                                                                                {item.html && item.html !== '' ? (
                                                                                                    <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />
                                                                                                ) : (
                                                                                                    <>
                                                                                                        {applicationdetaildata.learnabout ? applicationdetaildata.learnabout : ""}
                                                                                                    </>
                                                                                                )}


                                                                                            </>
                                                                                        )}
                                                                                        </div>
                                                                                ))}
                                                                            </>
                                                                        ) : (
                                                                            <p className="p-0">{applicationdetaildata.learnabout ? applicationdetaildata.learnabout : ""}</p>
                                                                        )} */}

                                                                        {application_response_tabs === "application_que_show" ? (
                                                                            <>
                                                                                <div className="que_ans">
                                                                                    <h5>Q2: Name one or more people, living or deceased, that you admire, and explain why.</h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.mentor ? applicationdetaildata.mentor : ""}</p> */}

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx1) => (
                                                                                                <div key={idx1}>
                                                                                                    {item.order && item.order === 2 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.mentor ? applicationdetaildata.mentor : ""}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>
                                                                                        Q3: Leadership comes in many forms, and we value diversity in leadership. What do you feel are your strongest leadership qualities? Please share one or
                                                                                        more examples of times when you have applied them in your life.
                                                                                    </h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.admireinhistory ? applicationdetaildata.admireinhistory : ""}</p> */}

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx2) => (
                                                                                                <div key={idx2}>
                                                                                                    {item.order && item.order === 3 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.admireinhistory ? applicationdetaildata.admireinhistory : ""}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>Q4: What are your biggest strengths? Name at least two, and explain how you would use them to make the world better.</h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.leadership ? applicationdetaildata.leadership : ""}</p> */}

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx4) => (
                                                                                                <div key={idx4}>
                                                                                                    {item.order && item.order === 4 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.leadership ? applicationdetaildata.leadership : ""}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>
                                                                                        Q5: What are your most significant weaknesses? Name at least two, and explain how you mitigate them. Everyone has weaknesses, so please be honest in
                                                                                        sharing yours here.
                                                                                    </h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.exerciseleadership ? applicationdetaildata.exerciseleadership : ""}</p> */}

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx5) => (
                                                                                                <div key={idx5}>
                                                                                                    {item.order && item.order === 5 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.exerciseleadership ? applicationdetaildata.exerciseleadership : ""}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>
                                                                                        Q6: Write a brief autobiography of your life. Feel free to provide a truthful account or be imaginative and creative. A minimum of 200 words is kindly
                                                                                        requested.
                                                                                    </h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.biggeststrength ? applicationdetaildata.biggeststrength : ""}</p> */}

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx6) => (
                                                                                                <div key={idx6}>
                                                                                                    {item.order && item.order === 6 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.biggeststrength ? applicationdetaildata.biggeststrength : ""}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>Q7: Do you have any personal or professional mentors? If so, please explain who and why.</h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.autobiography ? applicationdetaildata.autobiography : ""}</p> */}

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx7) => (
                                                                                                <div key={idx7}>
                                                                                                    {item.order && item.order === 7 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.autobiography ? applicationdetaildata.autobiography : ""}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>Q8: Please share your favorite quotation, and explain what it means to you personally and/or professionally.</h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.favoritequote ? applicationdetaildata.favoritequote : ""}</p> */}
                                                                                    <p className="p-0">{applicationdetaildata.favoritequote ? applicationdetaildata.favoritequote : ""}</p>
                                                                                    {/* {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx8) => (
                                                                                                <div key={idx8}>
                                                                                                    {item.order && item.order === 8 && (
                                                                                                        <>
                                                                                                            {item.html && item.html !== '' ? (
                                                                                                                <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />
                                                                                                            ) : (
                                                                                                                <>
                                                                                                                    {applicationdetaildata.favoritequote ? applicationdetaildata.favoritequote : ""}
                                                                                                                </>
                                                                                                            )}


                                                                                                        </>
                                                                                                    )}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.favoritequote ? applicationdetaildata.favoritequote : ""}</p>
                                                                                    )} */}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>Q9: What does confidence mean to you?</h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.confidence ? applicationdetaildata.confidence : ""}</p> */}

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx9) => (
                                                                                                <div key={idx9}>
                                                                                                    {item.order && item.order === 9 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.confidence ? applicationdetaildata.confidence : ""}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>Q10: Tell us about a challenge or conflict you’ve faced or are currently facing. How did/do you deal with it?</h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.conflict ? applicationdetaildata.conflict : ""}</p> */}

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx10) => (
                                                                                                <div key={idx10}>
                                                                                                    {item.order && item.order === 10 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.conflict ? applicationdetaildata.conflict : ""}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>
                                                                                        Q11: The Roman Stoic philosopher Seneca (4 BC–65 AD) said, “Our fears are always more numerous than our dangers.” What is your biggest fear, and why?
                                                                                    </h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.gretestachivement ? applicationdetaildata.gretestachivement : ""}</p> */}

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx11) => (
                                                                                                <div key={idx11}>
                                                                                                    {item.order && item.order === 11 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.gretestachivement ? applicationdetaildata.gretestachivement : ""}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>Q12: How do you deal with anxiety or pressure?</h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.skills ? applicationdetaildata.skills : ""}</p> */}

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx12) => (
                                                                                                <div key={idx12}>
                                                                                                    {item.order && item.order === 12 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.skills ? applicationdetaildata.skills : ""}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>Q13: What has been your greatest achievement to date, either personally or professionally?</h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.fear ? applicationdetaildata.fear : ""}</p> */}

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx13) => (
                                                                                                <div key={idx13}>
                                                                                                    {item.order && item.order === 13 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.fear ? applicationdetaildata.fear : ""}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>Q14: What skills do you hope to develop if you join Girl Power Talk?</h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.weakness ? applicationdetaildata.weakness : ""}</p> */}

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx14) => (
                                                                                                <div key={idx14}>
                                                                                                    {item.order && item.order === 14 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.weakness ? applicationdetaildata.weakness : ""}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>
                                                                                        Q15: What do you feel is the most concerning issue for women today in the world or your home country? How would you work to drive change in this area if
                                                                                        you were afforded the necessary power and resources?
                                                                                    </h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.womenissue ? applicationdetaildata.womenissue : ""}</p> */}

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx15) => (
                                                                                                <div key={idx15}>
                                                                                                    {item.order && item.order === 15 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.womenissue ? applicationdetaildata.womenissue : ""}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>Q16: What is your favorite book, and why?</h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.favoritebook ? applicationdetaildata.favoritebook : ""}</p> */}

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx16) => (
                                                                                                <div key={idx16}>
                                                                                                    {item.order && item.order === 16 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.favoritebook ? applicationdetaildata.favoritebook : ""}</p>
                                                                                    )}
                                                                                </div>

                                                                                <div className="que_ans">
                                                                                    <h5>Q17: Share one interesting fact about yourself that we might not otherwise know in evaluating your candidacy to join Girl Power Talk.</h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.information ? applicationdetaildata.information : ""}</p> */}

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx17) => (
                                                                                                <div key={idx17}>
                                                                                                    {item.order && item.order === 17 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.information ? applicationdetaildata.information : ""}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>Q18: Name a woman other than your mother or sister who has influenced your life. Describe how she has done so.</h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.interstingfacts ? applicationdetaildata.interstingfacts : ""}</p> */}

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx18) => (
                                                                                                <div key={idx18}>
                                                                                                    {item.order && item.order === 18 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.interstingfacts ? applicationdetaildata.interstingfacts : ""}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>
                                                                                        Q19: Anne Frank (1929–1945) wrote, “How wonderful it is that nobody need wait a single moment before starting to improve the world.” How does this
                                                                                        quotation resonate with you?
                                                                                    </h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.magicwand ? applicationdetaildata.magicwand : ""}</p> */}

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx19) => (
                                                                                                <div key={idx19}>
                                                                                                    {item.order && item.order === 19 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.magicwand ? applicationdetaildata.magicwand : ""}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>Q20: How do you spend most of your free time (e.g., hobbies, interests)?</h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.milliondollars ? applicationdetaildata.milliondollars : ""}</p> */}

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx20) => (
                                                                                                <div key={idx20}>
                                                                                                    {item.order && item.order === 20 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.milliondollars ? applicationdetaildata.milliondollars : ""}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>Q21: How would your closest friends and family members describe you?</h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.stressfulsitutations ? applicationdetaildata.stressfulsitutations : ""}</p> */}

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx21) => (
                                                                                                <div key={idx21}>
                                                                                                    {item.order && item.order === 21 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.stressfulsitutations ? applicationdetaildata.stressfulsitutations : ""}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>Q22: What excites you about being a part of Girl Power Talk?</h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.inspiringwoman ? applicationdetaildata.inspiringwoman : ""}</p> */}

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx22) => (
                                                                                                <div key={idx22}>
                                                                                                    {item.order && item.order === 22 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.inspiringwoman ? applicationdetaildata.inspiringwoman : ""}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>Q23: What is one creative idea you have for Girl Power Talk that could enhance our culture or business?</h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.quotemean ? applicationdetaildata.quotemean : ""}</p> */}

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx23) => (
                                                                                                <div key={idx23}>
                                                                                                    {item.order && item.order === 23 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.quotemean ? applicationdetaildata.quotemean : ""}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>Q24: Why should you be the next Young Leader at Girl Power Talk?</h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.outsideofwork ? applicationdetaildata.outsideofwork : ""}</p> */}

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx24) => (
                                                                                                <div key={idx24}>
                                                                                                    {item.order && item.order === 24 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.outsideofwork ? applicationdetaildata.outsideofwork : ""}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>Q25: What questions do you have for us?</h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.closestfriend ? applicationdetaildata.closestfriend : ""}</p> */}
                                                                                    <p className="p-0">{applicationdetaildata.closestfriend ? applicationdetaildata.closestfriend : ""}</p>
                                                                                    {/* {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx25) => (
                                                                                                <div key={idx25}>
                                                                                                    {item.order && item.order === 25 && (
                                                                                                        <>
                                                                                                            {item.html && item.html !== ''  ? (
                                                                                                                <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />
                                                                                                            ):(
                                                                                                                <>
                                                                                                                    {applicationdetaildata.closestfriend ? applicationdetaildata.closestfriend : ""}
                                                                                                                  </>  
                                                                                                            )}
                                                                                                            
                                                                                                    
                                                                                                    </>
                                                                                                    )}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.closestfriend ? applicationdetaildata.closestfriend : ""}</p>
                                                                                    )} */}
                                                                                </div>
                                                                                {/* <div className="que_ans">
                                                                                    <h5>
                                                                                        Q26: Why did you decide to complete the indication of interest for Girl Power Talk and our sister company, Blue Ocean Global Technology? In other words,
                                                                                        what inspires you to be part of the Girl Power Talk culture and why do you think you will be a good fit?
                                                                                    </h5>

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx26) => (
                                                                                                <div key={idx26}>
                                                                                                    {item.order && item.order === 26 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.intrestofgirlpower ? applicationdetaildata.intrestofgirlpower : ""}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>Q27: Do you have any questions for us? Please feel free to ask them here.</h5>

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx27) => (
                                                                                                <div key={idx27}>
                                                                                                    {item.order && item.order === 27 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.anyquestion ? applicationdetaildata.anyquestion : ""}</p>
                                                                                    )}
                                                                                </div> */}
                                                                                {/* {user_info && user_info.usertype && user_info.usertype === "admin" && (
                                                                                    <>
                                                                                        {applicationdetaildata.status !== "Accepted" && applicationdetaildata.status !== "Rejected" ? (
                                                                                            <>
                                                                                                <span onClick={() => showFinalAcceptModalHandle(applicationdetaildata)} className="btn btn-sm btn-primary btn-active-primary">
                                                                                                    Accept
                                                                                                </span>
                                                                                                <span onClick={() => showRejectModalHandle(applicationdetaildata)} className="ms-2 btn btn-sm btn-light-primary fw-bolder">
                                                                                                    Reject
                                                                                                </span>
                                                                                            </>
                                                                                        ) : (
                                                                                            ""
                                                                                        )}
                                                                                    </>
                                                                                )} */}
                                                                                <div className="comment-post-main mt-4">
                                                                                    <span className="showreplymessage" onClick={() => showapplicationreply(replyapplicationformreply, "show_application_form")}>
                                                                                        <i className="bi bi-chat-square"></i>
                                                                                        <span>{getapplication_form.length}</span>
                                                                                    </span>
                                                                                    {applicationformreply === "show" && apptype === "show_application_form" ? (
                                                                                        <>
                                                                                            {getapplication_form.map((getapplication_formvalue) => (
                                                                                                <div className="replicationreply" key={getapplication_formvalue._id}>
                                                                                                    <div className="cursor-pointer symbol symbol-30px reply_user_pic">
                                                                                                        <img src={getapplication_formvalue.UserImage ? getapplication_formvalue.UserImage : "/assets/media/avatars/blank.png"} alt="user" />
                                                                                                        <div className="profile-user ms-2">
                                                                                                            <span className="replyusername">{getapplication_formvalue.UserName}</span>
                                                                                                            <span className="date_time">
                                                                                                                {getapplication_formvalue.createdAt ? Moment(getapplication_formvalue.createdAt).format("DD MMM YYYY, h:mm a") : ""}
                                                                                                            </span>
                                                                                                            <span className="replymessage">{getapplication_formvalue.comment}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                </div>
                                                                                {user_role && user_role.applicationComment && user_role.applicationComment === "yes" && (
                                                                                    <div className="comment-box mb-2">
                                                                                        <form className="form w-100" onSubmit={(event) => SubmitHandler(event, "application_form")}>
                                                                                            <textarea
                                                                                                key="application_form_1"
                                                                                                className="form-control form-control-lg mt-3 mb-3"
                                                                                                value={application_form.application_form && application_form.application_form.value ? application_form.application_form.value : ""}
                                                                                                onChange={(event) => inputChangeHandler(event, "application_form")}
                                                                                            ></textarea>

                                                                                            <button type="submit" id="kt_new_password_submit" className="btn btn-sm fw-bolder me-4">
                                                                                                <span className="indicator-label">Post Comment</span>
                                                                                            </button>
                                                                                        </form>
                                                                                    </div>
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                        <span onClick={() => HideShowApplicationForm(application_response_text)} className="myBtn_2 myBtn">
                                                                            {viewmoreform}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                {/* end Application Form */}
                                                                {/* Start Clarification Received */}
                                                                {/* Removing blank and plag check for manually accepted on rejction */}
                                                                {applicationdetaildata && applicationdetaildata.palagrism && (
                                                                    <>
                                                                        {/* {applicationdetaildata && applicationdetaildata.clarification && applicationdetaildata.clarification !== '' && ( */}
                                                                        {/* Removing blank and plag check for manually accepted on rejction */}
                                                                        {applicationdetaildata &&
                                                                            applicationdetaildata.clarification_received &&
                                                                            applicationdetaildata.palagrism &&
                                                                            applicationdetaildata.palagrism > 16 &&
                                                                            applicationdetaildata.palagrism < 36 && (
                                                                                <div className="timeline-item">
                                                                                    <div className="timeline-media">
                                                                                        {!applicationdetaildata.clarification_received ? (
                                                                                            <>
                                                                                                <span className="fs-2 fw-bolder date-one">{Moment(new Date()).format("DD")} </span>
                                                                                                <br />
                                                                                                <span className="opacity-50 fs-8 fw-bold">{Moment(new Date()).format("MMM YYYY")}</span>
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                <span className="fs-2 fw-bolder date-one">
                                                                                                    {applicationdetaildata.clarificationrequesteddate
                                                                                                        ? Moment(applicationdetaildata.clarificationrequesteddate).format("DD")
                                                                                                        : ""}{" "}
                                                                                                </span>
                                                                                                <br />
                                                                                                <span className="opacity-50 fs-8 fw-bold">
                                                                                                    {applicationdetaildata.clarificationrequesteddate
                                                                                                        ? Moment(applicationdetaildata.clarificationrequesteddate).format("MMM YYYY")
                                                                                                        : ""}
                                                                                                </span>
                                                                                            </>
                                                                                        )}
                                                                                    </div>
                                                                                    <div className="timeline-content">
                                                                                        <div className="d-flex align-items-center justify-content-between mb-3">
                                                                                            <div className="mr-2">
                                                                                                <h3 className="text-dark-75 font-weight-bold fs-4">Clarification Requested</h3>
                                                                                                {!applicationdetaildata.clarificationrequesteddate ? (
                                                                                                    <>
                                                                                                        <span className="text-muted ml-2">{Moment(new Date()).format("h:mm a")}</span>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <span className="text-muted ml-2">
                                                                                                            {applicationdetaildata.clarificationrequesteddate
                                                                                                                ? Moment(applicationdetaildata.clarificationrequesteddate).format("h:mm a")
                                                                                                                : ""}
                                                                                                        </span>
                                                                                                    </>
                                                                                                )}
                                                                                                <p className="mt-2">{applicationdetaildata.clarification}</p>
                                                                                                {applicationdetaildata.assign_for_review && applicationdetaildata.assign_for_review.assign_member.membername ? (
                                                                                                    <>
                                                                                                        <div className="align-items-center mb-5 mt-5">
                                                                                                            <h5>Assigned Member</h5>
                                                                                                            <div className="d-flex text-gray-600 fs-6 interviewr-outer">
                                                                                                                <div className="d-flex align-items-center mb-5 mt-5 me-8">
                                                                                                                    <div className="me-4 position-relative">
                                                                                                                        <div className="symbol symbol-30px symbol-circle">
                                                                                                                            <img
                                                                                                                                className="mx-2"
                                                                                                                                alt="Pic"
                                                                                                                                src={
                                                                                                                                    applicationdetaildata.assign_for_review.assign_member.member_image
                                                                                                                                        ? applicationdetaildata.assign_for_review.assign_member.member_image
                                                                                                                                        : "/assets/media/avatars/blank.png"
                                                                                                                                }
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="fw-bold">
                                                                                                                        <div className="fs-5 text-gray-900">
                                                                                                                            {applicationdetaildata.assign_for_review.assign_member.membername
                                                                                                                                ? applicationdetaildata.assign_for_review.assign_member.membername
                                                                                                                                : ""}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    ""
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                        {/* <p>{applicationdetaildata.clarification}</p> */}

                                                                                        {/* {user_info && user_info.usertype && user_info.usertype === "admin" && ( */}
                                                                                        <div className="mb-4 ">

                                                                                            {applicationdetaildata.status !== "Accepted" && applicationdetaildata.status !== "Rejected" ? (
                                                                                                <>
                                                                                                    {!applicationdetaildata.manualassignuser ? (
                                                                                                        <>
                                                                                                            {user_role && user_role.applicationAssign && user_role.applicationAssign === "yes" && (
                                                                                                                <span onClick={() => showAssignModalHandle(applicationdetaildata._id)} className="btn btn-sm btn-primary btn-active-primary">
                                                                                                                    Assign Team Member
                                                                                                                </span>
                                                                                                            )}
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        ""
                                                                                                    )}
                                                                                                    {applicationdetaildata.assign_for_review && applicationdetaildata.assign_for_review.assign_member.membername ? (
                                                                                                        ""
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            {user_role &&
                                                                                                                user_role.applicationAssignOtherMember &&
                                                                                                                user_role.applicationAssignOtherMember === "yes" &&
                                                                                                                applicationdetaildata.manualassignuser &&
                                                                                                                applicationdetaildata.manualassignuser !== "" && (
                                                                                                                    <span
                                                                                                                        onClick={() => showAssignModalHandle(applicationdetaildata._id)}
                                                                                                                        className="btn btn-sm btn-primary btn-active-primary"
                                                                                                                    >
                                                                                                                        Assign other member
                                                                                                                    </span>
                                                                                                                )}

                                                                                                            {/* {user_role && user_role.applicationAccept && user_role.applicationAccept === "yes" && (
                                                                                                <span onClick={() => showAcceptModalHandle(applicationdetaildata)} className="btn btn-sm btn-primary btn-active-primary" >
                                                                                                    Accept
                                                                                                </span>
                                                                                            )} */}
                                                                                                            {user_role && user_role.applicationReject && user_role.applicationReject === "yes" && (
                                                                                                                <span onClick={() => showRejectModalHandle(applicationdetaildata)} className="ms-2 btn btn-sm btn-light-primary fw-bolder">
                                                                                                                    Reject
                                                                                                                </span>
                                                                                                            )}
                                                                                                        </>
                                                                                                    )}
                                                                                                </>
                                                                                            ) : (
                                                                                                ""
                                                                                            )}
                                                                                        </div>
                                                                                        {/* )} */}
                                                                                        <div className="comment-post-main">
                                                                                            <span className="showreplymessage " onClick={() => showapplicationreply(replyapplicationformreply, "show_clarification_received")}>
                                                                                                <i className="bi bi-chat-square"></i>
                                                                                                <span>{getclarification_received.length}</span>
                                                                                            </span>
                                                                                            {applicationformreply === "show" && apptype === "show_clarification_received" ? (
                                                                                                <>
                                                                                                    {getclarification_received.map((getclarification_receivedvalue) => (
                                                                                                        <div className="replicationreply" key={getclarification_receivedvalue._id}>
                                                                                                            <div className="cursor-pointer symbol symbol-30px  reply_user_pic">
                                                                                                                <img
                                                                                                                    src={
                                                                                                                        getclarification_receivedvalue.UserImage ? getclarification_receivedvalue.UserImage : "/assets/media/avatars/blank.png"
                                                                                                                    }
                                                                                                                    alt="user"
                                                                                                                />
                                                                                                                <div className="profile-user ms-2">
                                                                                                                    <span className="replyusername">{getclarification_receivedvalue.UserName}</span>
                                                                                                                    <span className="date_time">
                                                                                                                        {getclarification_receivedvalue.createdAt
                                                                                                                            ? Moment(getclarification_receivedvalue.createdAt).format("DD MMM YYYY, h:mm a")
                                                                                                                            : ""}
                                                                                                                    </span>
                                                                                                                    <span className="replymessage">{getclarification_receivedvalue.comment}</span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    ))}
                                                                                                </>
                                                                                            ) : (
                                                                                                ""
                                                                                            )}
                                                                                        </div>
                                                                                        {user_role && user_role.applicationComment && user_role.applicationComment === "yes" && (
                                                                                            <div className="comment-box mb-2">
                                                                                                <form className="form w-100" onSubmit={(event) => SubmitHandler(event, "clarification_received")}>
                                                                                                    <textarea
                                                                                                        key="clarification_received_1"
                                                                                                        className="form-control form-control-lg mt-3 mb-3"
                                                                                                        value={
                                                                                                            application_form.clarification_received && application_form.clarification_received.value
                                                                                                                ? application_form.clarification_received.value
                                                                                                                : ""
                                                                                                        }
                                                                                                        onChange={(event) => inputChangeHandler(event, "clarification_received")}
                                                                                                    ></textarea>

                                                                                                    <button type="submit" id="kt_new_password_submit" className="btn btn-sm fw-bolder me-4">
                                                                                                        <span className="indicator-label">Post Comment</span>
                                                                                                    </button>
                                                                                                </form>
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                                //)}
                                                                            )}
                                                                        {/* End Clarification Received */}
                                                                        {/* Start Assign for review */}
                                                                        {/* Removing blank and plag check for manually accepted on rejction */}

                                                                        {applicationdetaildata && applicationdetaildata.palagrism && (
                                                                            <>
                                                                                {user_role && (applicationdetaildata.manualUName === "" || (user_role.Allapplication === "yes" || (externalMemberId !== applicationdetaildata.assign_for_review.assign_member.member_id))) && (
                                                                                    <div className="timeline-item">
                                                                                        <div className="timeline-media">
                                                                                            {applicationdetaildata.assign_for_review && applicationdetaildata.assign_for_review.assign_date ? (
                                                                                                <>
                                                                                                    <span className="fs-2 fw-bolder date-one">
                                                                                                        {applicationdetaildata.assign_for_review.assign_date ? Moment(applicationdetaildata.assign_for_review.assign_date).format("DD") : ""}{" "}
                                                                                                    </span>
                                                                                                    <br />
                                                                                                    <span className="opacity-50 fs-8 fw-bold">
                                                                                                        {applicationdetaildata.assign_for_review.assign_date
                                                                                                            ? Moment(applicationdetaildata.assign_for_review.assign_date).format("MMM YYYY")
                                                                                                            : ""}
                                                                                                    </span>
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    <span className="fs-2 fw-bolder date-one">{Moment(new Date()).format("DD")} </span>
                                                                                                    <br />
                                                                                                    <span className="opacity-50 fs-8 fw-bold">{Moment(new Date()).format("MMM YYYY")}</span>
                                                                                                </>
                                                                                            )}
                                                                                        </div>
                                                                                        <div className="timeline-content">
                                                                                            <div className="d-flex align-items-center justify-content-between mb-3">
                                                                                                <div className="mr-2">
                                                                                                    {applicationdetaildata.assign_for_review && applicationdetaildata.assign_for_review.assign_member.membername ? (
                                                                                                        <>
                                                                                                            <h3 className="text-dark-75 font-weight-bold fs-4">Assigned to review</h3>
                                                                                                            <span className="text-muted ml-2 review-assign-date">
                                                                                                                {applicationdetaildata.assign_for_review.assign_date
                                                                                                                    ? Moment(applicationdetaildata.assign_for_review.assign_date).format("h:mm a")
                                                                                                                    : ""}
                                                                                                            </span>
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            <h3 className="text-dark-75 font-weight-bold">Assign to review </h3>

                                                                                                            <span className="opacity-50 fs-8 fw-bold">{Moment(new Date()).format("h:mm a")}</span>
                                                                                                        </>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                            {getreview_application_by_admin.map((getreview_application_by_adminvalue, index) => (
                                                                                                <>
                                                                                                    {index < 3 && (
                                                                                                        <div className="replicationreply" key={getreview_application_by_adminvalue._id}>
                                                                                                            <div className="cursor-pointer symbol symbol-30px symbol-md-30px reply_user_pic">
                                                                                                                <img
                                                                                                                    className="me-2"
                                                                                                                    src={
                                                                                                                        getreview_application_by_adminvalue.UserImage
                                                                                                                            ? getreview_application_by_adminvalue.UserImage
                                                                                                                            : "/assets/media/avatars/blank.png"
                                                                                                                    }
                                                                                                                    alt="user"
                                                                                                                />
                                                                                                                <div className="profile-user ms-2">
                                                                                                                    <span className="replyusername">{getreview_application_by_adminvalue.UserName}</span>
                                                                                                                    <span className="date_time">
                                                                                                                        {getreview_application_by_adminvalue.createdAt
                                                                                                                            ? Moment(getreview_application_by_adminvalue.createdAt).format("DD MMM YYYY, h:mm a")
                                                                                                                            : ""}
                                                                                                                    </span>
                                                                                                                    <span className="replymessage">{getreview_application_by_adminvalue.comment}</span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )}
                                                                                                </>
                                                                                            ))}
                                                                                            {applicationdetaildata.assign_for_review && applicationdetaildata.assign_for_review.assign_member.member_id ? (
                                                                                                <div className="align-items-center mb-2 mt-4">
                                                                                                    <h5>Assigned Member</h5>
                                                                                                    <div className="d-flex text-gray-600 fs-6 interviewr-outer">
                                                                                                        <div className="d-flex align-items-center mb-1 mt-1 me-8">
                                                                                                            <div className="me-1 position-relative">
                                                                                                                <div className="symbol symbol-30px symbol-circle">
                                                                                                                    <img
                                                                                                                        className="me-2"
                                                                                                                        alt="Pic"
                                                                                                                        src={
                                                                                                                            applicationdetaildata.assign_for_review.assign_member.member_image
                                                                                                                                ? applicationdetaildata.assign_for_review.assign_member.member_image
                                                                                                                                : "/assets/media/avatars/blank.png"
                                                                                                                        }
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="fw-bold">
                                                                                                                <div className="fs-7 text-gray-900">
                                                                                                                    {applicationdetaildata.assign_for_review.assign_member.membername
                                                                                                                        ? applicationdetaildata.assign_for_review.assign_member.membername
                                                                                                                        : ""}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ) : (
                                                                                                <>
                                                                                                    {user_info.usertype && user_info.usertype === "admin" && (
                                                                                                        <>
                                                                                                            <span onClick={() => showAssignModalHandle(applicationdetaildata._id)} className="btn btn-sm btn-primary btn-active-primary">
                                                                                                                Assign a Member
                                                                                                            </span>
                                                                                                            <span onClick={() => showRejectModalHandle(applicationdetaildata)} className="ms-2 btn btn-sm btn-light-primary fw-bolder">
                                                                                                                Reject
                                                                                                            </span>
                                                                                                        </>
                                                                                                    )}
                                                                                                </>
                                                                                            )}

                                                                                            {applicationdetaildata.OneAUName ? (
                                                                                                ""
                                                                                            ) : (
                                                                                                <>
                                                                                                    {applicationdetaildata.assign_for_review.assign_member.membername !== "" && (
                                                                                                        <div className="mb-4">
                                                                                                            {" "}
                                                                                                            {applicationdetaildata.status !== "Accepted" && applicationdetaildata.status !== "Rejected" ? (
                                                                                                                <>
                                                                                                                    {!applicationdetaildata.manualassignuser ? (
                                                                                                                        <>
                                                                                                                            {user_role && user_role.applicationAssign && user_role.applicationAssign === "yes" && (
                                                                                                                                <span
                                                                                                                                    onClick={() => showAssignModalHandle(applicationdetaildata._id)}
                                                                                                                                    className="btn btn-sm btn-primary btn-active-primary"
                                                                                                                                >
                                                                                                                                    Assign Team Member
                                                                                                                                </span>
                                                                                                                            )}
                                                                                                                        </>
                                                                                                                    ) : (
                                                                                                                        ""
                                                                                                                    )}

                                                                                                                    {user_role.Allapplication && user_role.Allapplication === "yes" && user_role &&
                                                                                                                        user_role.applicationAssignOtherMember &&
                                                                                                                        user_role.applicationAssignOtherMember === "yes" &&
                                                                                                                        applicationdetaildata.manualassignuser &&
                                                                                                                        applicationdetaildata.manualassignuser !== "" && (
                                                                                                                            <span
                                                                                                                                onClick={() => showAssignModalHandle(applicationdetaildata._id)}
                                                                                                                                className="btn btn-sm btn-primary btn-active-primary"
                                                                                                                            >
                                                                                                                                Assign other member
                                                                                                                            </span>
                                                                                                                        )}
                                                                                                                    {(user_role.Allapplication &&
                                                                                                                        user_role.Allapplication === "yes") &&
                                                                                                                        applicationdetaildata.manualassignuser &&
                                                                                                                        applicationdetaildata.manualassignuser !== "" && (
                                                                                                                            <>
                                                                                                                                {!applicationdetaildata.assigndate ? (
                                                                                                                                    <>
                                                                                                                                        {user_role && user_role.applicationAccept && user_role.applicationAccept === "yes" && (
                                                                                                                                            <span
                                                                                                                                                onClick={() => showAcceptModalHandle(applicationdetaildata)}
                                                                                                                                                className="btn btn-sm btn-primary btn-active-primary"
                                                                                                                                            >
                                                                                                                                                {" "}
                                                                                                                                                Accept
                                                                                                                                            </span>
                                                                                                                                        )}
                                                                                                                                    </>
                                                                                                                                ) : (
                                                                                                                                    ""
                                                                                                                                )}
                                                                                                                            </>
                                                                                                                        )}
                                                                                                                    {user_role.Allapplication && user_role.Allapplication === "yes" && user_role && user_role.applicationReject && user_role.applicationReject === "yes" && (
                                                                                                                        <span
                                                                                                                            onClick={() => showRejectModalHandle(applicationdetaildata)}
                                                                                                                            className="ms-2 btn btn-sm btn-light-primary fw-bolder"
                                                                                                                        >
                                                                                                                            Reject
                                                                                                                        </span>
                                                                                                                    )}
                                                                                                                </>
                                                                                                            ) : (
                                                                                                                ""
                                                                                                            )}
                                                                                                        </div>
                                                                                                    )}
                                                                                                </>
                                                                                            )}

                                                                                            <div className="comment-post-main">
                                                                                                <span className="showreplymessage" onClick={() => showapplicationreply(replyapplicationformreply, "show_assign_for_review")}>
                                                                                                    <i className="bi bi-chat-square"></i>
                                                                                                    <span>{getassign_for_review.length}</span>
                                                                                                </span>
                                                                                                {applicationformreply === "show" && apptype === "show_assign_for_review" ? (
                                                                                                    <>
                                                                                                        {getassign_for_review.map((getassign_for_reviewvalue) => (
                                                                                                            <div className="replicationreply" key={getassign_for_reviewvalue._id}>
                                                                                                                <div className="cursor-pointer symbol symbol-30px reply_user_pic">
                                                                                                                    <img
                                                                                                                        className="me-1"
                                                                                                                        src={getassign_for_reviewvalue.UserImage ? getassign_for_reviewvalue.UserImage : "/assets/media/avatars/blank.png"}
                                                                                                                        alt="user"
                                                                                                                    />
                                                                                                                    <div className="profile-user ms-2">
                                                                                                                        <span className="replyusername">{getassign_for_reviewvalue.UserName}</span>
                                                                                                                        <span className="date_time">
                                                                                                                            {getassign_for_reviewvalue.createdAt
                                                                                                                                ? Moment(getassign_for_reviewvalue.createdAt).format("DD MMM YYYY, h:mm a")
                                                                                                                                : ""}
                                                                                                                        </span>
                                                                                                                        <span className="replymessage">{getassign_for_reviewvalue.comment}</span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        ))}
                                                                                                    </>
                                                                                                ) : (
                                                                                                    ""
                                                                                                )}
                                                                                            </div>
                                                                                            {user_role && user_role.applicationComment && user_role.applicationComment === "yes" && (
                                                                                                <div className="comment-box mb-2">
                                                                                                    <form className="form w-100" onSubmit={(event) => SubmitHandler(event, "assign_for_review")}>
                                                                                                        <textarea
                                                                                                            key="assign_for_review_1"
                                                                                                            className="form-control form-control-lg mt-1 mb-3"
                                                                                                            value={
                                                                                                                application_form.assign_for_review && application_form.assign_for_review.value ? application_form.assign_for_review.value : ""
                                                                                                            }
                                                                                                            onChange={(event) => inputChangeHandler(event, "assign_for_review")}
                                                                                                        ></textarea>

                                                                                                        <button type="submit" id="kt_new_password_submit" className="btn btn-sm fw-bolder me-4">
                                                                                                            <span className="indicator-label">Post Comment</span>
                                                                                                        </button>
                                                                                                    </form>
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                        {/* End Assign for review */}
                                                                        {/* Start Review Application */}
                                                                        {applicationdetaildata.manualassignuser && user_role.Allapplication !== "yes" && applicationdetaildata.OneAUName ? (
                                                                            <div className="timeline-item">
                                                                                <div className="timeline-media">
                                                                                    {!applicationdetaildata.assigndate ? (
                                                                                        <>
                                                                                            <span className="fs-2 fw-bolder date-one">{Moment(new Date()).format("DD")} </span>
                                                                                            <br />
                                                                                            <span className="opacity-50 fs-8 fw-bold">{Moment(new Date()).format("MMM YYYY")}</span>
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            <span className="fs-2 fw-bolder date-one">{applicationdetaildata.assigndate ? Moment(applicationdetaildata.assigndate).format("DD") : ""} </span>
                                                                                            <br />
                                                                                            <span className="opacity-50 fs-8 fw-bold">
                                                                                                {applicationdetaildata.assigndate ? Moment(applicationdetaildata.assigndate).format("MMM YYYY") : ""}
                                                                                            </span>
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                                <div className="timeline-content">
                                                                                    <div className="d-flex align-items-center justify-content-between mb-3">
                                                                                        <div className="mr-2">
                                                                                            {!applicationdetaildata.assigndate ? (
                                                                                                <>
                                                                                                    <h3 className="text-dark-75 font-weight-bold fs-4">Review Application</h3>

                                                                                                    <span className="opacity-50 fs-8 fw-bold">{Moment(new Date()).format("h:mm a")}</span>
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    <h3 className="text-dark-75 font-weight-bold">Reviewed Application</h3>
                                                                                                    <span className="text-muted ml-2">{applicationdetaildata.assigndate ? Moment(applicationdetaildata.assigndate).format("h:mm a") : ""}</span>
                                                                                                </>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                    {getassign_for_review.map((getassign_for_reviewvalue, index) => (
                                                                                        <>
                                                                                            {index < 3 && (
                                                                                                <div className="replicationreply" key={getassign_for_reviewvalue._id}>
                                                                                                    <div className="cursor-pointer symbol symbol-30px symbol-md-3px reply_user_pic">
                                                                                                        <img src={getassign_for_reviewvalue.UserImage ? getassign_for_reviewvalue.UserImage : "/assets/media/avatars/blank.png"} alt="user" />
                                                                                                        <div className="profile-user ms-2">
                                                                                                            <span className="replyusername">{getassign_for_reviewvalue.UserName}</span>
                                                                                                            <span className="date_time">
                                                                                                                {getassign_for_reviewvalue.createdAt ? Moment(getassign_for_reviewvalue.createdAt).format("DD MMM YYYY, h:mm a") : ""}
                                                                                                            </span>
                                                                                                            <span className="replymessage">{getassign_for_reviewvalue.comment}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )}
                                                                                        </>
                                                                                    ))}
                                                                                    <div className="align-items-center mb-5 mt-5">
                                                                                        <h5>Reviewed Member</h5>
                                                                                        <div className="d-flex text-gray-600 fs-6 interviewr-outer">
                                                                                            <div className="d-flex align-items-center mb-1 mt-1 me-8">
                                                                                                <div className="me-1 position-relative">
                                                                                                    <div className="symbol symbol-30px symbol-circle">
                                                                                                        <img
                                                                                                            className="mx-2"
                                                                                                            alt="Pic"
                                                                                                            src={applicationdetaildata.ManualAcceptImage ? applicationdetaildata.ManualAcceptImage : "/assets/media/avatars/blank.png"}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="fw-bold">
                                                                                                    <div className="fs-7 text-gray-900">
                                                                                                        {applicationdetaildata.ManualAcceptName ? applicationdetaildata.ManualAcceptName : ""}

                                                                                                        {applicationdetaildata.ManualAcceptName !== applicationdetaildata.assign_for_review.assign_member.membername ? (
                                                                                                            <>(on the behalf of {applicationdetaildata.assign_for_review.assign_member.membername})</>
                                                                                                        ) : (
                                                                                                            ""
                                                                                                        )}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="mb-4 mt-4">
                                                                                        {applicationdetaildata.status !== "Accepted" && applicationdetaildata.status !== "Rejected" ? (
                                                                                            <>
                                                                                                {!applicationdetaildata.assigndate ? (
                                                                                                    <>
                                                                                                        {user_role && user_role.applicationAccept && user_role.applicationAccept === "yes" && (
                                                                                                            <span onClick={() => showAcceptModalHandle(applicationdetaildata)} className="btn btn-sm btn-primary btn-active-primary">
                                                                                                                {" "}
                                                                                                                Accept
                                                                                                            </span>
                                                                                                        )}
                                                                                                        {user_role && user_role.applicationReject && user_role.applicationReject === "yes" && (
                                                                                                            <span onClick={() => showRejectModalHandle(applicationdetaildata)} className="ms-2 btn btn-sm btn-light-primary fw-bolder">
                                                                                                                Reject
                                                                                                            </span>
                                                                                                        )}
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        {/* {user_role && user_role.applicationAssignOtherMember && user_role.applicationAssignOtherMember === "yes" && (
                                                                                                    <span className="btn btn-sm btn-primary btn-active-primary"> Assign other member</span>
                                                                                                )} */}
                                                                                                    </>
                                                                                                )}
                                                                                            </>
                                                                                        ) : (
                                                                                            ""
                                                                                        )}
                                                                                    </div>
                                                                                    <div className="comment-post-main">
                                                                                        <span className="showreplymessage" onClick={() => showapplicationreply(replyapplicationformreply, "show_review_application_by_admin")}>
                                                                                            <i className="bi bi-chat-square"></i>
                                                                                            <span>{getreview_application_by_admin.length}</span>
                                                                                        </span>
                                                                                        {applicationformreply === "show" && apptype === "show_review_application_by_admin" ? (
                                                                                            <>
                                                                                                {getreview_application_by_admin.map((getreview_application_by_adminvalue) => (
                                                                                                    <div className="replicationreply" key={getreview_application_by_adminvalue._id}>
                                                                                                        <div className="cursor-pointer symbol symbol-30px reply_user_pic">
                                                                                                            <img
                                                                                                                src={
                                                                                                                    getreview_application_by_adminvalue.UserImage
                                                                                                                        ? getreview_application_by_adminvalue.UserImage
                                                                                                                        : "/assets/media/avatars/blank.png"
                                                                                                                }
                                                                                                                alt="user"
                                                                                                            />
                                                                                                            <div className="profile-user ms-2">
                                                                                                                <span className="replyusername">{getreview_application_by_adminvalue.UserName}</span>
                                                                                                                <span className="date_time">
                                                                                                                    {getreview_application_by_adminvalue.createdAt
                                                                                                                        ? Moment(getreview_application_by_adminvalue.createdAt).format("DD MMM YYYY, h:mm a")
                                                                                                                        : ""}
                                                                                                                </span>
                                                                                                                <span className="replymessage">{getreview_application_by_adminvalue.comment}</span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                ))}
                                                                                            </>
                                                                                        ) : (
                                                                                            ""
                                                                                        )}
                                                                                    </div>
                                                                                    {/* <div className="comment-post-main">
                                                                                        <span className="showreplymessage" onClick={() => showapplicationreply(replyapplicationformreply, "show_review_application")}>
                                                                                            <i className="bi bi-chat-square"></i>
                                                                                            <span>{getreview_application.length}</span>
                                                                                        </span>
                                                                                        {applicationformreply === "show" && apptype === "show_review_application" ? (
                                                                                            <>
                                                                                                {getreview_application.map((getreview_applicationvalue) => (
                                                                                                    <div className="replicationreply" key={getreview_applicationvalue._id}>
                                                                                                        <div className="cursor-pointer symbol symbol-30px  reply_user_pic">
                                                                                                            <img
                                                                                                                src={getreview_applicationvalue.UserImage ? getreview_applicationvalue.UserImage : "/assets/media/avatars/blank.png"}
                                                                                                                alt="user"
                                                                                                            />
                                                                                                            <div className="profile-user ms-2">
                                                                                                                <span className="replyusername">{getreview_applicationvalue.UserName}</span>
                                                                                                                <span className="date_time">
                                                                                                                    {getreview_applicationvalue.createdAt ? Moment(getreview_applicationvalue.createdAt).format("DD MMM YYYY, h:mm a") : ""}
                                                                                                                </span>
                                                                                                                <span className="replymessage">{getreview_applicationvalue.comment}</span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                ))}
                                                                                            </>
                                                                                        ) : (
                                                                                            ""
                                                                                        )}
                                                                                    </div> */}
                                                                                    {/* {user_role && user_role.applicationComment && user_role.applicationComment === "yes" && (
                                                                                        <div className="comment-box mb-2">
                                                                                            <form className="form w-100" onSubmit={(event) => SubmitHandler(event, "review_application")}>
                                                                                                <textarea
                                                                                                    key="review_application_1"
                                                                                                    className="form-control form-control-lg mt-3 mb-3"
                                                                                                    value={application_form.review_application && application_form.review_application.value ? application_form.review_application.value : ""}
                                                                                                    onChange={(event) => inputChangeHandler(event, "review_application")}
                                                                                                ></textarea>

                                                                                                <button type="submit" id="kt_new_password_submit" className="btn btn-sm fw-bolder me-4">
                                                                                                    <span className="indicator-label">Post Comment</span>
                                                                                                </button>
                                                                                            </form>
                                                                                        </div>
                                                                                    )} */}
                                                                                    {user_role && user_role.applicationComment && user_role.applicationComment === "yes" && (
                                                                                        <div className="comment-box mb-2">
                                                                                            <form className="form w-100" onSubmit={(event) => SubmitHandler(event, "review_application_by_admin")}>
                                                                                                <textarea
                                                                                                    key="review_application_by_admin_1"
                                                                                                    className="form-control form-control-lg mt-3 mb-3"
                                                                                                    value={
                                                                                                        application_form.review_application_by_admin && application_form.review_application_by_admin.value
                                                                                                            ? application_form.review_application_by_admin.value
                                                                                                            : ""
                                                                                                    }
                                                                                                    onChange={(event) => inputChangeHandler(event, "review_application_by_admin")}
                                                                                                ></textarea>

                                                                                                <button type="submit" id="kt_new_password_submit" className="btn btn-sm fw-bolder me-4">
                                                                                                    <span className="indicator-label">Post Comment</span>
                                                                                                </button>
                                                                                            </form>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            <>
                                                                                {/* Start Review Application by admin */}
                                                                                {(applicationdetaildata.manualassignuser && applicationdetaildata.manualassignuser === user_info._id) ||
                                                                                    (user_role.Allapplication === "yes" && applicationdetaildata.OneAUName) ? (
                                                                                    <div className="timeline-item">
                                                                                        <div className="timeline-media">
                                                                                            {!applicationdetaildata.assigndate ? (
                                                                                                <>
                                                                                                    <span className="fs-2 fw-bolder date-one">{Moment(new Date()).format("DD")} </span>
                                                                                                    <br />
                                                                                                    <span className="opacity-50 fs-8 fw-bold">{Moment(new Date()).format("MMM YYYY")}</span>
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    <span className="fs-2 fw-bolder date-one">
                                                                                                        {applicationdetaildata.assigndate ? Moment(applicationdetaildata.assigndate).format("DD") : ""}{" "}
                                                                                                    </span>
                                                                                                    <br />
                                                                                                    <span className="opacity-50 fs-8 fw-bold">
                                                                                                        {applicationdetaildata.assigndate ? Moment(applicationdetaildata.assigndate).format("MMM YYYY") : ""}
                                                                                                    </span>
                                                                                                </>
                                                                                            )}
                                                                                        </div>
                                                                                        <div className="timeline-content">
                                                                                            <div className="d-flex align-items-center justify-content-between mb-3">
                                                                                                <div className="mr-2">
                                                                                                    {!applicationdetaildata.assigndate ? (
                                                                                                        <>
                                                                                                            <h3 className="text-dark-75 font-weight-bold fs-4">Review Application</h3>

                                                                                                            <span className="opacity-50 fs-8 fw-bold">
                                                                                                                {applicationdetaildata.assign_for_review.assign_date
                                                                                                                    ? Moment(applicationdetaildata.assign_for_review.assign_date).format("h:mm a")
                                                                                                                    : ""}
                                                                                                            </span>
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            <h3 className="text-dark-75 font-weight-bold">Reviewed Application</h3>
                                                                                                            <span className="text-muted ml-2">
                                                                                                                {applicationdetaildata.assigndate ? Moment(applicationdetaildata.assigndate).format("h:mm a") : ""}
                                                                                                            </span>
                                                                                                        </>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>

                                                                                            {getassign_for_review.map((getassign_for_reviewvalue, index) => (
                                                                                                <>
                                                                                                    {index < 3 && (
                                                                                                        <div className="replicationreply" key={getassign_for_reviewvalue._id}>
                                                                                                            <div className="cursor-pointer symbol symbol-30px reply_user_pic">
                                                                                                                <img
                                                                                                                    src={getassign_for_reviewvalue.UserImage ? getassign_for_reviewvalue.UserImage : "/assets/media/avatars/blank.png"}
                                                                                                                    alt="user"
                                                                                                                />
                                                                                                                <div className="profile-user ms-2">
                                                                                                                    <span className="replyusername">{getassign_for_reviewvalue.UserName}</span>
                                                                                                                    <span className="date_time">
                                                                                                                        {getassign_for_reviewvalue.createdAt ? Moment(getassign_for_reviewvalue.createdAt).format("DD MMM YYYY, h:mm a") : ""}
                                                                                                                    </span>
                                                                                                                    <span className="replymessage">{getassign_for_reviewvalue.comment}</span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )}
                                                                                                </>
                                                                                            ))}
                                                                                            {user_info.usertype && user_info.usertype === "admin" ? (
                                                                                                <div className="align-items-center mb-5 mt-5">
                                                                                                    <h5>Reviewed Member</h5>
                                                                                                    <div className="d-flex text-gray-600 fs-6 interviewr-outer">
                                                                                                        <div className="d-flex align-items-center mb-1 mt-1 me-8">
                                                                                                            <div className="me-1 position-relative">
                                                                                                                <div className="symbol symbol-30px symbol-circle">
                                                                                                                    <img
                                                                                                                        className="mx-2"
                                                                                                                        alt="Pic"
                                                                                                                        src={
                                                                                                                            applicationdetaildata.ManualAcceptImage
                                                                                                                                ? applicationdetaildata.ManualAcceptImage
                                                                                                                                : "/assets/media/avatars/blank.png"
                                                                                                                        }
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="fw-bold">
                                                                                                                <div className="fs-7 text-gray-900">
                                                                                                                    {applicationdetaildata.ManualAcceptName ? applicationdetaildata.ManualAcceptName : ""}

                                                                                                                    {applicationdetaildata.ManualAcceptName !== applicationdetaildata.assign_for_review.assign_member.membername ? (
                                                                                                                        <>(on the behalf of {applicationdetaildata.assign_for_review.assign_member.membername})</>
                                                                                                                    ) : (
                                                                                                                        ""
                                                                                                                    )}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ) : (
                                                                                                ""
                                                                                            )}
                                                                                            {/* {getreview_application.map((getreview_applicationvalue, index) => (
                                                                                                <>
                                                                                                    {index < 3 && (
                                                                                                        <div className="replicationreply" key={getreview_applicationvalue._id}>
                                                                                                            <div className="cursor-pointer symbol symbol-30px reply_user_pic">
                                                                                                                <img
                                                                                                                    src={getreview_applicationvalue.UserImage ? getreview_applicationvalue.UserImage : "/assets/media/avatars/blank.png"}
                                                                                                                    alt="user"
                                                                                                                />
                                                                                                                <div className="profile-user ms-2">
                                                                                                                    <span className="replyusername">{getreview_applicationvalue.UserName}</span>
                                                                                                                    <span className="date_time">
                                                                                                                        {getreview_applicationvalue.createdAt ? Moment(getreview_applicationvalue.createdAt).format("DD MMM YYYY, h:mm a") : ""}
                                                                                                                    </span>
                                                                                                                    <span className="replymessage">{getreview_applicationvalue.comment}</span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )}
                                                                                                </>
                                                                                            ))} */}
                                                                                            <div className="mb-4 mt-4">
                                                                                                {applicationdetaildata.status !== "Accepted" && applicationdetaildata.status !== "Rejected" ? (
                                                                                                    <>
                                                                                                        {!applicationdetaildata.assigndate ? (
                                                                                                            <>
                                                                                                                {user_role && user_role.applicationAccept && user_role.applicationAccept === "yes" && (
                                                                                                                    <span onClick={() => showAcceptModalHandle(applicationdetaildata)} className="btn btn-sm btn-primary btn-active-primary">
                                                                                                                        {" "}
                                                                                                                        Accept
                                                                                                                    </span>
                                                                                                                )}
                                                                                                                {user_role && user_role.applicationReject && user_role.applicationReject === "yes" && (
                                                                                                                    <span onClick={() => showRejectModalHandle(applicationdetaildata)} className="ms-2 btn btn-sm btn-light-primary fw-bolder">
                                                                                                                        Reject
                                                                                                                    </span>
                                                                                                                )}
                                                                                                                {user_role && user_role.applicationAssignOtherMember && user_role.applicationAssignOtherMember === "yes" && (
                                                                                                                    <span
                                                                                                                        onClick={() => showAssignModalHandle(applicationdetaildata._id)}
                                                                                                                        className="btn btn-sm btn-primary btn-active-primary"
                                                                                                                    >
                                                                                                                        Assign a Member
                                                                                                                    </span>
                                                                                                                )}
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <></>
                                                                                                        )}
                                                                                                    </>
                                                                                                ) : (
                                                                                                    ""
                                                                                                )}
                                                                                            </div>
                                                                                            <div className="comment-post-main">
                                                                                                <span className="showreplymessage" onClick={() => showapplicationreply(replyapplicationformreply, "show_review_application_by_admin")}>
                                                                                                    <i className="bi bi-chat-square"></i>
                                                                                                    <span>{getreview_application_by_admin.length}</span>
                                                                                                </span>
                                                                                                {applicationformreply === "show" && apptype === "show_review_application_by_admin" ? (
                                                                                                    <>
                                                                                                        {getreview_application_by_admin.map((getreview_application_by_adminvalue) => (
                                                                                                            <div className="replicationreply" key={getreview_application_by_adminvalue._id}>
                                                                                                                <div className="cursor-pointer symbol symbol-30px reply_user_pic">
                                                                                                                    <img
                                                                                                                        src={
                                                                                                                            getreview_application_by_adminvalue.UserImage
                                                                                                                                ? getreview_application_by_adminvalue.UserImage
                                                                                                                                : "/assets/media/avatars/blank.png"
                                                                                                                        }
                                                                                                                        alt="user"
                                                                                                                    />
                                                                                                                    <div className="profile-user ms-2">
                                                                                                                        <span className="replyusername">{getreview_application_by_adminvalue.UserName}</span>
                                                                                                                        <span className="date_time">
                                                                                                                            {getreview_application_by_adminvalue.createdAt
                                                                                                                                ? Moment(getreview_application_by_adminvalue.createdAt).format("DD MMM YYYY, h:mm a")
                                                                                                                                : ""}
                                                                                                                        </span>
                                                                                                                        <span className="replymessage">{getreview_application_by_adminvalue.comment}</span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        ))}
                                                                                                    </>
                                                                                                ) : (
                                                                                                    ""
                                                                                                )}
                                                                                            </div>
                                                                                            {user_role && user_role.applicationComment && user_role.applicationComment === "yes" && (
                                                                                                <div className="comment-box mb-2">
                                                                                                    <form className="form w-100" onSubmit={(event) => SubmitHandler(event, "review_application_by_admin")}>
                                                                                                        <textarea
                                                                                                            key="review_application_by_admin_1"
                                                                                                            className="form-control form-control-lg mt-1 mb-3"
                                                                                                            value={
                                                                                                                application_form.review_application_by_admin && application_form.review_application_by_admin.value
                                                                                                                    ? application_form.review_application_by_admin.value
                                                                                                                    : ""
                                                                                                            }
                                                                                                            onChange={(event) => inputChangeHandler(event, "review_application_by_admin")}
                                                                                                        ></textarea>

                                                                                                        <button type="submit" id="kt_new_password_submit" className="btn btn-sm fw-bolder me-4">
                                                                                                            <span className="indicator-label">Post Comment</span>
                                                                                                        </button>
                                                                                                    </form>
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                ) : (
                                                                                    ""
                                                                                )}

                                                                                {/* End Review Application by admin */}
                                                                            </>
                                                                        )}
                                                                        {/* End Review Application */}

                                                                        {/* Start 2 member Review Application */}
                                                                        {/* {applicationdetaildata.assignuserone}==={user_info._id} */}

                                                                        {(applicationdetaildata.application_accepted && applicationdetaildata.application_accepted.user_one.user_one_id) &&
                                                                            (applicationdetaildata.assign_for_review && applicationdetaildata.assign_for_review.assign_member.member_id === user_info._id) ||
                                                                            (applicationdetaildata.application_accepted && applicationdetaildata.application_accepted.user_one.user_one_id && applicationdetaildata.application_accepted.user_one.user_one_id === user_info._id) ||
                                                                            (applicationdetaildata.application_accepted &&
                                                                                applicationdetaildata.application_accepted.user_one.user_one_id &&
                                                                                applicationdetaildata.application_accepted.user_two.user_two_id === user_info._id) ||
                                                                            (applicationdetaildata.application_accepted &&
                                                                                applicationdetaildata.application_accepted.user_one.user_one_id &&
                                                                                applicationdetaildata.application_accepted.user_three.user_three_id === user_info._id) ||
                                                                            (applicationdetaildata.application_interview && applicationdetaildata.application_interview.interview_one.interview_one_id === user_info._id) ||
                                                                            (applicationdetaildata.application_interview && applicationdetaildata.application_interview.interview_two.interview_two_id === user_info._id) ||
                                                                            (applicationdetaildata.application_interview && applicationdetaildata.application_interview.interview_three.interview_three_id === user_info._id)
                                                                            || (externalMemberId === user_info._id)
                                                                            && (applicationdetaildata.application_accepted.user_one.user_one_id)
                                                                            // (applicationdetaildata.application_accepted && applicationdetaildata.application_accepted.user_one.user_one_id === user_info._id) ||
                                                                            // (applicationdetaildata.application_accepted && applicationdetaildata.application_accepted.user_two.user_two_id === user_info._id) ||
                                                                            // (applicationdetaildata.application_accepted && applicationdetaildata.application_accepted.user_three.user_three_id === user_info._id) 
                                                                            //     || (
                                                                            //         (applicationdetaildata.application_interview && applicationdetaildata.application_interview.interview_one.interview_one_id === user_info._id) ||
                                                                            //         (applicationdetaildata.application_interview && applicationdetaildata.application_interview.interview_two.interview_two_id === user_info._id) ||
                                                                            //         (applicationdetaildata.application_interview && applicationdetaildata.application_interview.interview_three.interview_three_id === user_info._id))
                                                                            ? (
                                                                                <>
                                                                                    {user_role.Allapplication !== "yes" ? (
                                                                                        <div className="timeline-item">
                                                                                            <div className="timeline-media">
                                                                                                {!applicationdetaildata.assigndate ? (
                                                                                                    <>
                                                                                                        <span className="fs-2 fw-bolder date-one">{Moment(new Date()).format("DD")} </span>
                                                                                                        <br />
                                                                                                        <span className="opacity-50 fs-8 fw-bold">{Moment(new Date()).format("MMM YYYY")}</span>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <span className="fs-2 fw-bolder date-one">
                                                                                                            {applicationdetaildata.assigndate ? Moment(applicationdetaildata.assigndate).format("DD") : ""}{" "}
                                                                                                        </span>
                                                                                                        <br />
                                                                                                        <span className="opacity-50 fs-8 fw-bold">
                                                                                                            {applicationdetaildata.assigndate ? Moment(applicationdetaildata.assigndate).format("MMM YYYY") : ""}
                                                                                                        </span>
                                                                                                    </>
                                                                                                )}
                                                                                            </div>
                                                                                            <div className="timeline-content">
                                                                                                <div className="d-flex align-items-center justify-content-between mb-3">
                                                                                                    <div className="mr-2">
                                                                                                        {
                                                                                                            ((applicationdetaildata.application_interview && applicationdetaildata.application_interview.interview_one.interview_one_id === user_info._id) ||
                                                                                                                (applicationdetaildata.application_interview && applicationdetaildata.application_interview.interview_two.interview_two_id === user_info._id) ||
                                                                                                                (applicationdetaildata.application_interview && applicationdetaildata.application_interview.interview_three.interview_three_id === user_info._id)) ||
                                                                                                                (applicationdetaildata.application_accepted &&
                                                                                                                    applicationdetaildata.application_accepted.user_one.user_one_id === user_info._id &&
                                                                                                                    applicationdetaildata.application_accepted.user_one.user_one_status) ||
                                                                                                                (applicationdetaildata.application_accepted &&
                                                                                                                    applicationdetaildata.application_accepted.user_two.user_two_id === user_info._id &&
                                                                                                                    applicationdetaildata.application_accepted.user_two.user_two_status) ||
                                                                                                                (applicationdetaildata.application_accepted &&
                                                                                                                    applicationdetaildata.application_accepted.user_three.user_three_id === user_info._id &&
                                                                                                                    applicationdetaildata.application_accepted.user_three.user_three_status) ||
                                                                                                                (applicationdetaildata.application_accepted && applicationdetaildata.application_accepted.user_setinterview === '1')
                                                                                                                ? (
                                                                                                                    <h3 className="text-dark-75 font-weight-bold">Final Review Completed</h3>
                                                                                                                ) : (
                                                                                                                    <h3 className="text-dark-75 font-weight-bold">Final Review Application</h3>
                                                                                                                )}
                                                                                                        {!applicationdetaildata.assigndate ? (
                                                                                                            <>
                                                                                                                <span className="opacity-50 fs-8 fw-bold">{Moment(new Date()).format("h:mm a")}</span>
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                <span className="text-muted ml-2">
                                                                                                                    {applicationdetaildata.assigndate ? Moment(applicationdetaildata.assigndate).format("h:mm a") : ""}
                                                                                                                </span>
                                                                                                            </>
                                                                                                        )}
                                                                                                    </div>
                                                                                                </div>
                                                                                                {applicationdetaildata.OneAcceptName &&
                                                                                                    applicationdetaildata.OneAcceptName !== applicationdetaildata.application_accepted.user_one.user_one_name ? (
                                                                                                    ""
                                                                                                ) : (
                                                                                                    <>
                                                                                                        {applicationdetaildata.application_accepted.user_one.user_one_status && (
                                                                                                            <div className="d-flex align-items-center mb-5 mt-5 me-8">
                                                                                                                <div className="me-4 position-relative">
                                                                                                                    <div className="symbol symbol-30px symbol-circle">
                                                                                                                        <img
                                                                                                                            alt="Pic"
                                                                                                                            src={
                                                                                                                                applicationdetaildata.application_accepted.user_one.user_one_image
                                                                                                                                    ? applicationdetaildata.application_accepted.user_one.user_one_image
                                                                                                                                    : "/assets/media/avatars/blank.png"
                                                                                                                            }
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="fw-bold">
                                                                                                                    <div className="">
                                                                                                                        {applicationdetaildata.application_accepted.user_one.user_one_name
                                                                                                                            ? applicationdetaildata.application_accepted.user_one.user_one_name
                                                                                                                            : ""}
                                                                                                                        <span className="text_cap">({applicationdetaildata.application_accepted.user_one.user_one_status}ed)</span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )}{" "}
                                                                                                        {applicationdetaildata.application_accepted.user_two.user_two_status && (
                                                                                                            <div className="d-flex align-items-center mb-5 mt-5 me-8">
                                                                                                                <div className="me-4 position-relative">
                                                                                                                    <div className="symbol symbol-30px symbol-circle">
                                                                                                                        <img
                                                                                                                            alt="Pic"
                                                                                                                            src={
                                                                                                                                applicationdetaildata.application_accepted.user_two.user_two_image
                                                                                                                                    ? applicationdetaildata.application_accepted.user_two.user_two_image
                                                                                                                                    : "/assets/media/avatars/blank.png"
                                                                                                                            }
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="fw-bold">
                                                                                                                    <div className="">
                                                                                                                        {applicationdetaildata.application_accepted.user_two.user_two_name
                                                                                                                            ? applicationdetaildata.application_accepted.user_two.user_two_name
                                                                                                                            : ""}
                                                                                                                        <span className="text_cap">({applicationdetaildata.application_accepted.user_two.user_two_status}ed)</span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )}{" "}
                                                                                                        {applicationdetaildata.application_accepted.user_three.user_three_status && (
                                                                                                            <div className="d-flex align-items-center mb-5 mt-5 me-8">
                                                                                                                <div className="me-4 position-relative">
                                                                                                                    <div className="symbol symbol-30px symbol-circle">
                                                                                                                        <img
                                                                                                                            alt="Pic"
                                                                                                                            src={
                                                                                                                                applicationdetaildata.application_accepted.user_three.user_three_image
                                                                                                                                    ? applicationdetaildata.application_accepted.user_three.user_three_image
                                                                                                                                    : "/assets/media/avatars/blank.png"
                                                                                                                            }
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="fw-bold">
                                                                                                                    <div className="">
                                                                                                                        {applicationdetaildata.application_accepted.user_three.user_three_name
                                                                                                                            ? applicationdetaildata.application_accepted.user_three.user_three_name
                                                                                                                            : ""}
                                                                                                                        <span className="text_cap">({applicationdetaildata.application_accepted.user_three.user_three_status}ed)</span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </>
                                                                                                )}
                                                                                                {applicationdetaildata.setinterview && applicationdetaildata.setinterview === "1" ? (
                                                                                                    <>
                                                                                                        {applicationdetaildata.OneAcceptName ? (
                                                                                                            <>
                                                                                                                {applicationdetaildata.OneAcceptName !== applicationdetaildata.application_accepted.user_one.user_one_name ? (
                                                                                                                    <div className="d-flex align-items-center mb-1 mt-1 me-8">
                                                                                                                        <div className="me-1 position-relative">
                                                                                                                            <div className="symbol symbol-30px symbol-circle">
                                                                                                                                <img
                                                                                                                                    className="me-2"
                                                                                                                                    alt="Pic"
                                                                                                                                    src={
                                                                                                                                        applicationdetaildata.OneAcceptImage
                                                                                                                                            ? applicationdetaildata.OneAcceptImage
                                                                                                                                            : "/assets/media/avatars/blank.png"
                                                                                                                                    }
                                                                                                                                />
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="fw-bold">
                                                                                                                            <div className="fs-7 text-gray-900">
                                                                                                                                {applicationdetaildata.OneAcceptName}
                                                                                                                                <span className="me-2">(on the behalf of </span>
                                                                                                                                <div className="me-1 symbol symbol-30px symbol-circle">
                                                                                                                                    <img
                                                                                                                                        alt="Pic"
                                                                                                                                        src={
                                                                                                                                            applicationdetaildata.application_accepted.user_one.user_one_image
                                                                                                                                                ? applicationdetaildata.application_accepted.user_one.user_one_image
                                                                                                                                                : "/assets/media/avatars/blank.png"
                                                                                                                                        }
                                                                                                                                    />
                                                                                                                                </div>
                                                                                                                                {applicationdetaildata.application_accepted.user_one.user_one_name} <span className="me-1">and</span>
                                                                                                                                <div className="me-1 symbol symbol-30px symbol-circle">
                                                                                                                                    <img
                                                                                                                                        className="mx-1"
                                                                                                                                        alt="Pic"
                                                                                                                                        src={
                                                                                                                                            applicationdetaildata.application_accepted.user_two.user_two_image
                                                                                                                                                ? applicationdetaildata.application_accepted.user_two.user_two_image
                                                                                                                                                : "/assets/media/avatars/blank.png"
                                                                                                                                        }
                                                                                                                                    />
                                                                                                                                </div>
                                                                                                                                {applicationdetaildata.application_accepted.user_two.user_two_name})
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                ) : (
                                                                                                                    ""
                                                                                                                )}
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            ""
                                                                                                        )}
                                                                                                    </>
                                                                                                ) : (
                                                                                                    ""
                                                                                                )}

                                                                                                <h5>Assigned Users</h5>
                                                                                                <div className="d-flex text-gray-600  fs-6 userr-outer">
                                                                                                    <div className="d-flex align-items-center mb-5 mt-5 me-8">
                                                                                                        <div className="me-4 position-relative">
                                                                                                            <div className="symbol symbol-30px symbol-circle">
                                                                                                                <img
                                                                                                                    alt="Pic"
                                                                                                                    src={
                                                                                                                        applicationdetaildata.application_accepted.user_one.user_one_image
                                                                                                                            ? applicationdetaildata.application_accepted.user_one.user_one_image
                                                                                                                            : "/assets/media/avatars/blank.png"
                                                                                                                    }
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="fw-bold">
                                                                                                            <div className="fs-7 text-gray-900">
                                                                                                                {applicationdetaildata.application_accepted.user_one.user_one_name
                                                                                                                    ? applicationdetaildata.application_accepted.user_one.user_one_name
                                                                                                                    : ""}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div className="d-flex align-items-center mb-5 mt-5 me-8">
                                                                                                        <div className="me-2 position-relative">
                                                                                                            <div className="symbol symbol-30px symbol-circle">
                                                                                                                <img
                                                                                                                    alt="Pic"
                                                                                                                    src={
                                                                                                                        applicationdetaildata.application_accepted.user_two.user_two_image
                                                                                                                            ? applicationdetaildata.application_accepted.user_two.user_two_image
                                                                                                                            : "/assets/media/avatars/blank.png"
                                                                                                                    }
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="fw-bold">
                                                                                                            <div className="fs-7 text-gray-900">
                                                                                                                {applicationdetaildata.application_accepted.user_two.user_two_name
                                                                                                                    ? applicationdetaildata.application_accepted.user_two.user_two_name
                                                                                                                    : ""}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {applicationdetaildata.application_accepted.user_three.user_three_name && (
                                                                                                        <div className="d-flex align-items-center mb-5 mt-5 me-8">
                                                                                                            <div className="me-4 position-relative">
                                                                                                                <div className="symbol symbol-30px symbol-circle">
                                                                                                                    <img
                                                                                                                        alt="Pic"
                                                                                                                        src={
                                                                                                                            applicationdetaildata.application_accepted.user_three.user_three_image
                                                                                                                                ? applicationdetaildata.application_accepted.user_three.user_three_image
                                                                                                                                : "/assets/media/avatars/blank.png"
                                                                                                                        }
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="fw-bold">
                                                                                                                <div className="fs-7 text-gray-900">
                                                                                                                    {applicationdetaildata.application_accepted.user_three.user_three_name
                                                                                                                        ? applicationdetaildata.application_accepted.user_three.user_three_name
                                                                                                                        : ""}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )}
                                                                                                </div>
                                                                                                {/* {getreview_application.map((getassign_for_reviewvalue, index) => (
                                                                                        <>
                                                                                            {index < 3 && (
                                                                                                <div className="replicationreply" key={getassign_for_reviewvalue._id}>
                                                                                                    <div className="cursor-pointer symbol symbol-30px reply_user_pic">
                                                                                                        <img src={getassign_for_reviewvalue.UserImage ? getassign_for_reviewvalue.UserImage : "/assets/media/avatars/blank.png"} alt="user" />
                                                                                                        <div className="profile-user ms-2">
                                                                                                            <span className="replyusername">{getassign_for_reviewvalue.UserName}</span>
                                                                                                            <span className="date_time">
                                                                                                                {getassign_for_reviewvalue.createdAt ? Moment(getassign_for_reviewvalue.createdAt).format("DD MMM YYYY, h:mm a") : ""}
                                                                                                            </span>
                                                                                                            <span className="replymessage">{getassign_for_reviewvalue.comment}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )}
                                                                                        </>
                                                                                    ))} */}
                                                                                                <>
                                                                                                    {getapplication_accepted.map((getapplication_acceptedvalue, index) => (
                                                                                                        <>
                                                                                                            {index < 3 && (
                                                                                                                <div className="replicationreply" key={getapplication_acceptedvalue._id}>
                                                                                                                    <div className="cursor-pointer symbol symbol-30px  reply_user_pic">
                                                                                                                        <img
                                                                                                                            src={
                                                                                                                                getapplication_acceptedvalue.UserImage ? getapplication_acceptedvalue.UserImage : "/assets/media/avatars/blank.png"
                                                                                                                            }
                                                                                                                            alt="user"
                                                                                                                        />
                                                                                                                        <div className="profile-user ms-2">
                                                                                                                            <span className="replyusername">{getapplication_acceptedvalue.UserName}</span>
                                                                                                                            <span className="date_time">
                                                                                                                                {getapplication_acceptedvalue.createdAt
                                                                                                                                    ? Moment(getapplication_acceptedvalue.createdAt).format("DD MMM YYYY, h:mm a")
                                                                                                                                    : ""}
                                                                                                                            </span>
                                                                                                                            <span className="replymessage">{getapplication_acceptedvalue.comment}</span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </>
                                                                                                    ))}
                                                                                                </>
                                                                                                <div className="mb-4 mt-4">
                                                                                                    {(applicationdetaildata.application_accepted &&
                                                                                                        applicationdetaildata.application_accepted.user_one.user_one_id === user_info._id &&
                                                                                                        applicationdetaildata.application_accepted.user_one.user_one_status
                                                                                                    ) ||
                                                                                                        (applicationdetaildata.application_accepted &&
                                                                                                            applicationdetaildata.application_accepted.user_two.user_two_id === user_info._id &&
                                                                                                            applicationdetaildata.application_accepted.user_two.user_two_status
                                                                                                        ) ||
                                                                                                        (applicationdetaildata.application_accepted &&
                                                                                                            applicationdetaildata.application_accepted.user_three.user_three_id === user_info._id &&
                                                                                                            applicationdetaildata.application_accepted.user_three.user_three_status


                                                                                                        )
                                                                                                        || (
                                                                                                            (applicationdetaildata.assign_for_review && applicationdetaildata.assign_for_review.assign_member.member_id === user_info._id)
                                                                                                            //&&

                                                                                                        )




                                                                                                        // || ((applicationdetaildata.application_interview && applicationdetaildata.application_interview.interview_one.interview_one_id !== user_info._id) ||
                                                                                                        //         (applicationdetaildata.application_interview && applicationdetaildata.application_interview.interview_two.interview_two_id !== user_info._id) ||
                                                                                                        //         (applicationdetaildata.application_interview && applicationdetaildata.application_interview.interview_three.interview_three_id !== user_info._id))

                                                                                                        ? (
                                                                                                            <>
                                                                                                                {((applicationdetaildata.assign_for_review.assign_member.member_id === applicationdetaildata.application_accepted.user_one.user_one_id) ||
                                                                                                                    (applicationdetaildata.assign_for_review.assign_member.member_id === applicationdetaildata.application_accepted.user_two.user_two_id) ||
                                                                                                                    (applicationdetaildata.assign_for_review.assign_member.member_id === applicationdetaildata.application_accepted.user_two.user_two_id)
                                                                                                                ) && (!applicationdetaildata.application_accepted || applicationdetaildata.application_accepted.user_setinterview !== '1') ? (<>
                                                                                                                    {
                                                                                                                        applicationdetaildata.status !== "Accepted" && applicationdetaildata.status !== "Rejected" ? (
                                                                                                                            <>
                                                                                                                                <>
                                                                                                                                    {user_role && user_role.applicationAccept && user_role.applicationAccept === "yes" && (
                                                                                                                                        <span
                                                                                                                                            onClick={() => showAcceptByUserModalHandle(applicationdetaildata)}
                                                                                                                                            className="btn btn-sm btn-primary btn-active-primary"
                                                                                                                                        >
                                                                                                                                            {" "}
                                                                                                                                            Accept
                                                                                                                                        </span>
                                                                                                                                    )}
                                                                                                                                </>
                                                                                                                                <>
                                                                                                                                    {user_role && user_role.applicationAssignOtherMember && user_role.applicationAssignOtherMember === "yes" && (
                                                                                                                                        <span
                                                                                                                                            onClick={() =>
                                                                                                                                                showAutoAssignOtherMemberpoup(applicationdetaildata, getapplication_accepted_assign, "application_accepted")
                                                                                                                                            }
                                                                                                                                            className="btn btn-sm btn-primary btn-active-primary"
                                                                                                                                        >
                                                                                                                                            Assign Other member
                                                                                                                                        </span>
                                                                                                                                    )}

                                                                                                                                </>
                                                                                                                                {user_role && user_role.applicationReject && user_role.applicationReject === "yes" && (
                                                                                                                                    <span
                                                                                                                                        onClick={() => showRejectByUserModalHandle(applicationdetaildata)}
                                                                                                                                        className="ms-2 btn btn-sm btn-light-primary fw-bolder"
                                                                                                                                    >
                                                                                                                                        Reject
                                                                                                                                    </span>
                                                                                                                                )}
                                                                                                                            </>
                                                                                                                        ) : (
                                                                                                                            ""
                                                                                                                        )
                                                                                                                    }
                                                                                                                </>

                                                                                                                ) : (
                                                                                                                    ""
                                                                                                                )

                                                                                                                }
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                {/* {!applicationdetaildata.assigndate ? ( */}
                                                                                                                {
                                                                                                                    (((applicationdetaildata.application_accepted.user_one.user_one_id === user_info._id ||
                                                                                                                        applicationdetaildata.application_accepted.user_two.user_two_id === user_info._id ||
                                                                                                                        applicationdetaildata.application_accepted.user_three.user_three_id === user_info._id)
                                                                                                                    )) &&

                                                                                                                        applicationdetaildata.status !== "Accepted" && applicationdetaildata.status !== "Rejected" ? (
                                                                                                                        <>
                                                                                                                            <>
                                                                                                                                {user_role && user_role.applicationAccept && user_role.applicationAccept === "yes" && (
                                                                                                                                    <span
                                                                                                                                        onClick={() => showAcceptByUserModalHandle(applicationdetaildata)}
                                                                                                                                        className="btn btn-sm btn-primary btn-active-primary"
                                                                                                                                    >
                                                                                                                                        {" "}
                                                                                                                                        Accept
                                                                                                                                    </span>
                                                                                                                                )}
                                                                                                                            </>
                                                                                                                            {/* // ) : ( */}
                                                                                                                            <>
                                                                                                                                {user_role && user_role.applicationAssignOtherMember && user_role.applicationAssignOtherMember === "yes" && (
                                                                                                                                    <span
                                                                                                                                        onClick={() =>
                                                                                                                                            showAutoAssignOtherMemberpoup(applicationdetaildata, getapplication_accepted_assign, "application_accepted")
                                                                                                                                        }
                                                                                                                                        className="btn btn-sm btn-primary btn-active-primary"
                                                                                                                                    >
                                                                                                                                        Assign Other member
                                                                                                                                    </span>
                                                                                                                                )}
                                                                                                                                {/* {user_role && user_role.applicationAssignOtherMember && user_role.applicationAssignOtherMember === "yes" && (
                                                                                                        <span className="btn btn-sm btn-primary btn-active-primary" > Assign other member
                                                                                                        </span>
                                                                                                    )} */}
                                                                                                                            </>
                                                                                                                            {/* // )} */}
                                                                                                                            {user_role && user_role.applicationReject && user_role.applicationReject === "yes" && (
                                                                                                                                <span
                                                                                                                                    onClick={() => showRejectByUserModalHandle(applicationdetaildata)}
                                                                                                                                    className="ms-2 btn btn-sm btn-light-primary fw-bolder"
                                                                                                                                >
                                                                                                                                    Reject
                                                                                                                                </span>
                                                                                                                            )}
                                                                                                                        </>
                                                                                                                    ) : (
                                                                                                                        ""
                                                                                                                    )}
                                                                                                            </>
                                                                                                        )}
                                                                                                </div>
                                                                                                <div className="comment-post-main">
                                                                                                    <span className="showreplymessage" onClick={() => showapplicationreply(replyapplicationformreply, "review_application_by_user")}>
                                                                                                        <i className="bi bi-chat-square"></i>
                                                                                                        <span>{getreview_application_by_user.length}</span>
                                                                                                    </span>
                                                                                                    {applicationformreply === "show" && apptype === "review_application_by_user" ? (
                                                                                                        <>
                                                                                                            {getreview_application_by_user.map((getreview_application_by_uservalue) => (
                                                                                                                <div className="replicationreply" key={getreview_application_by_uservalue._id}>
                                                                                                                    <div className="cursor-pointer symbol symbol-30px reply_user_pic">
                                                                                                                        <img
                                                                                                                            src={
                                                                                                                                getreview_application_by_uservalue.UserImage
                                                                                                                                    ? getreview_application_by_uservalue.UserImage
                                                                                                                                    : "/assets/media/avatars/blank.png"
                                                                                                                            }
                                                                                                                            alt="user"
                                                                                                                        />
                                                                                                                        <div className="profile-user ms-2">
                                                                                                                            <span className="replyusername">{getreview_application_by_uservalue.UserName}</span>
                                                                                                                            <span className="date_time">
                                                                                                                                {getreview_application_by_uservalue.createdAt
                                                                                                                                    ? Moment(getreview_application_by_uservalue.createdAt).format("DD MMM YYYY, h:mm a")
                                                                                                                                    : ""}
                                                                                                                            </span>
                                                                                                                            <span className="replymessage">{getreview_application_by_uservalue.comment}</span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            ))}
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        ""
                                                                                                    )}
                                                                                                </div>
                                                                                                {user_role && user_role.applicationComment && user_role.applicationComment === "yes" && (
                                                                                                    <div className="comment-box mb-2">
                                                                                                        <form className="form w-100" onSubmit={(event) => SubmitHandler(event, "review_application_by_user")}>
                                                                                                            <textarea
                                                                                                                key="review_application_1"
                                                                                                                className="form-control form-control-lg mt-3 mb-3"
                                                                                                                value={
                                                                                                                    application_form.review_application_by_user && application_form.review_application_by_user.value
                                                                                                                        ? application_form.review_application_by_user.value
                                                                                                                        : ""
                                                                                                                }
                                                                                                                onChange={(event) => inputChangeHandler(event, "review_application_by_user")}
                                                                                                            ></textarea>

                                                                                                            <button type="submit" id="kt_new_password_submit" className="btn btn-sm fw-bolder me-4">
                                                                                                                <span className="indicator-label">Post Comment</span>
                                                                                                            </button>
                                                                                                        </form>
                                                                                                    </div>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                </>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        {/* End 2 member Review Application */}
                                                                        {/* Start Initial Review Completed */}
                                                                        {(user_role.Allapplication && user_role.Allapplication === "yes") && (
                                                                            <>
                                                                                {((user_role.Allapplication && user_role.Allapplication === "yes") ||
                                                                                    (applicationdetaildata.assign_for_review && applicationdetaildata.assign_for_review.assign_member.member_id === user_info._id)) && (
                                                                                        <>
                                                                                            {applicationdetaildata.application_accepted && applicationdetaildata.application_accepted.application_accepted_date ? (
                                                                                                <div className="timeline-item">
                                                                                                    <div className="timeline-media">
                                                                                                        {applicationdetaildata.application_accepted && applicationdetaildata.application_accepted.application_accepted_date ? (
                                                                                                            <>
                                                                                                                <span className="fs-2 fw-bolder date-one">
                                                                                                                    {applicationdetaildata.application_accepted.application_accepted_date
                                                                                                                        ? Moment(applicationdetaildata.application_accepted.application_accepted_date).format("DD")
                                                                                                                        : ""}{" "}
                                                                                                                </span>
                                                                                                                <br />
                                                                                                                <span className="opacity-50 fs-8 fw-bold">
                                                                                                                    {applicationdetaildata.application_accepted.application_accepted_date
                                                                                                                        ? Moment(applicationdetaildata.application_accepted.application_accepted_date).format("MMM YYYY")
                                                                                                                        : ""}
                                                                                                                </span>
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                <span className="fs-2 fw-bolder date-one">{Moment(new Date()).format("DD")} </span>
                                                                                                                <br />
                                                                                                                <span className="opacity-50 fs-8 fw-bold">{Moment(new Date()).format("MMM YYYY")}</span>
                                                                                                            </>
                                                                                                        )}
                                                                                                    </div>
                                                                                                    <div className="timeline-content">
                                                                                                        <div className="d-flex align-items-center justify-content-between mb-3">
                                                                                                            <div className="mr-2">
                                                                                                                <h3 className="text-dark-75 font-weight-bold fs-4">
                                                                                                                    {applicationdetaildata.setinterview && applicationdetaildata.setinterview === "1" ? (
                                                                                                                        "Final Review Completed"
                                                                                                                    ) : (
                                                                                                                        <>
                                                                                                                            {applicationdetaildata.application_accepted && applicationdetaildata.application_accepted.user_three.user_three_name ? (
                                                                                                                                <span>
                                                                                                                                    Application rejected by
                                                                                                                                    {applicationdetaildata.application_accepted &&
                                                                                                                                        applicationdetaildata.application_accepted.user_one.user_one_status === "reject" && (
                                                                                                                                            <>
                                                                                                                                                {applicationdetaildata.application_accepted &&
                                                                                                                                                    applicationdetaildata.application_accepted.user_one.user_one_name ? (
                                                                                                                                                    <div className="symbol symbol-30px symbol-circle accepted_application">
                                                                                                                                                        <img
                                                                                                                                                            className="mx-2"
                                                                                                                                                            alt="Pic"
                                                                                                                                                            src={
                                                                                                                                                                applicationdetaildata.application_accepted.user_one.user_one_image
                                                                                                                                                                    ? applicationdetaildata.application_accepted.user_one.user_one_image
                                                                                                                                                                    : "/assets/media/avatars/blank.png"
                                                                                                                                                            }
                                                                                                                                                        />
                                                                                                                                                        {applicationdetaildata.application_accepted.user_one.user_one_name
                                                                                                                                                            ? applicationdetaildata.application_accepted.user_one.user_one_name
                                                                                                                                                            : ""}
                                                                                                                                                    </div>
                                                                                                                                                ) : (
                                                                                                                                                    ""
                                                                                                                                                )}
                                                                                                                                                <span className=""> and assign to</span>
                                                                                                                                                {applicationdetaildata.application_accepted &&
                                                                                                                                                    applicationdetaildata.application_accepted.user_three.user_three_name ? (
                                                                                                                                                    <>
                                                                                                                                                        <div className="symbol symbol-30px symbol-circle accepted_application">
                                                                                                                                                            <img
                                                                                                                                                                className="mx-2"
                                                                                                                                                                alt="Pic"
                                                                                                                                                                src={
                                                                                                                                                                    applicationdetaildata.application_accepted.user_three.user_three_image
                                                                                                                                                                        ? applicationdetaildata.application_accepted.user_three.user_three_image
                                                                                                                                                                        : "/assets/media/avatars/blank.png"
                                                                                                                                                                }
                                                                                                                                                            />
                                                                                                                                                            {applicationdetaildata.application_accepted.user_three.user_three_name
                                                                                                                                                                ? applicationdetaildata.application_accepted.user_three.user_three_name
                                                                                                                                                                : ""}
                                                                                                                                                        </div>
                                                                                                                                                    </>
                                                                                                                                                ) : (
                                                                                                                                                    ""
                                                                                                                                                )}
                                                                                                                                            </>
                                                                                                                                        )}
                                                                                                                                    <>
                                                                                                                                        {applicationdetaildata.application_accepted &&
                                                                                                                                            applicationdetaildata.application_accepted.user_two.user_two_status === "reject" && (
                                                                                                                                                <>
                                                                                                                                                    {applicationdetaildata.application_accepted &&
                                                                                                                                                        applicationdetaildata.application_accepted.user_two.user_two_name ? (
                                                                                                                                                        <div className="symbol symbol-30px symbol-circle accepted_application">
                                                                                                                                                            <img
                                                                                                                                                                className="mx-2"
                                                                                                                                                                alt="Pic"
                                                                                                                                                                src={
                                                                                                                                                                    applicationdetaildata.application_accepted.user_two.user_two_image
                                                                                                                                                                        ? applicationdetaildata.application_accepted.user_two.user_two_image
                                                                                                                                                                        : "/assets/media/avatars/blank.png"
                                                                                                                                                                }
                                                                                                                                                            />
                                                                                                                                                            {applicationdetaildata.application_accepted.user_two.user_two_name
                                                                                                                                                                ? applicationdetaildata.application_accepted.user_two.user_two_name
                                                                                                                                                                : ""}
                                                                                                                                                        </div>
                                                                                                                                                    ) : (
                                                                                                                                                        ""
                                                                                                                                                    )}
                                                                                                                                                    <span className=""> and assign to</span>
                                                                                                                                                    {applicationdetaildata.application_accepted &&
                                                                                                                                                        applicationdetaildata.application_accepted.user_three.user_three_name ? (
                                                                                                                                                        <>
                                                                                                                                                            <div className="symbol symbol-30px symbol-circle accepted_application">
                                                                                                                                                                <img
                                                                                                                                                                    className="mx-2"
                                                                                                                                                                    alt="Pic"
                                                                                                                                                                    src={
                                                                                                                                                                        applicationdetaildata.application_accepted.user_three.user_three_image
                                                                                                                                                                            ? applicationdetaildata.application_accepted.user_three.user_three_image
                                                                                                                                                                            : "/assets/media/avatars/blank.png"
                                                                                                                                                                    }
                                                                                                                                                                />
                                                                                                                                                                {applicationdetaildata.application_accepted.user_three.user_three_name
                                                                                                                                                                    ? applicationdetaildata.application_accepted.user_three.user_three_name
                                                                                                                                                                    : ""}
                                                                                                                                                            </div>
                                                                                                                                                        </>
                                                                                                                                                    ) : (
                                                                                                                                                        ""
                                                                                                                                                    )}
                                                                                                                                                </>
                                                                                                                                            )}
                                                                                                                                    </>
                                                                                                                                </span>
                                                                                                                            ) : (
                                                                                                                                <span>
                                                                                                                                    {applicationdetaildata.assign_for_review && applicationdetaildata.assign_for_review.assign_member.membername ? (
                                                                                                                                        <>
                                                                                                                                            Application accepted by
                                                                                                                                            <div className="symbol symbol-30px symbol-circle accepted_application">
                                                                                                                                                <img
                                                                                                                                                    className="mx-2"
                                                                                                                                                    alt="Pic"
                                                                                                                                                    src={
                                                                                                                                                        applicationdetaildata.ManualAcceptImage
                                                                                                                                                            ? applicationdetaildata.ManualAcceptImage
                                                                                                                                                            : "/assets/media/avatars/blank.png"
                                                                                                                                                    }
                                                                                                                                                />
                                                                                                                                                {applicationdetaildata.ManualAcceptName ? applicationdetaildata.ManualAcceptName : ""}
                                                                                                                                                {applicationdetaildata.ManualAcceptName !==
                                                                                                                                                    applicationdetaildata.assign_for_review.assign_member.membername ? (
                                                                                                                                                    <>(on the behalf of {applicationdetaildata.assign_for_review.assign_member.membername})</>
                                                                                                                                                ) : (
                                                                                                                                                    ""
                                                                                                                                                )}
                                                                                                                                            </div>
                                                                                                                                            {/* <div className="symbol symbol-30px symbol-circle accepted_application">
                                                                                                                                    <img className="mx-2"
                                                                                                                                        alt="Pic"
                                                                                                                                        src={
                                                                                                                                            applicationdetaildata.assign_for_review.assign_member.member_image
                                                                                                                                                ? applicationdetaildata.assign_for_review.assign_member.member_image
                                                                                                                                                : "/assets/media/avatars/blank.png"
                                                                                                                                        }
                                                                                                                                    />
                                                                                                                                    {applicationdetaildata.assign_for_review.assign_member.membername
                                                                                                                                        ? applicationdetaildata.assign_for_review.assign_member.membername
                                                                                                                                        : ""}
                                                                                                                                </div> */}
                                                                                                                                            and
                                                                                                                                        </>
                                                                                                                                    ) : (
                                                                                                                                        ""
                                                                                                                                    )}
                                                                                                                                    <span className="accepted_application">assigned to </span>
                                                                                                                                    {applicationdetaildata.application_accepted && applicationdetaildata.application_accepted.user_one.user_one_name ? (
                                                                                                                                        <>
                                                                                                                                            <div className="symbol symbol-30px symbol-circle accepted_application me-2">
                                                                                                                                                <img
                                                                                                                                                    className="mx-2"
                                                                                                                                                    alt="Pic"
                                                                                                                                                    src={
                                                                                                                                                        applicationdetaildata.application_accepted.user_one.user_one_image
                                                                                                                                                            ? applicationdetaildata.application_accepted.user_one.user_one_image
                                                                                                                                                            : "/assets/media/avatars/blank.png"
                                                                                                                                                    }
                                                                                                                                                />
                                                                                                                                                {applicationdetaildata.application_accepted.user_one.user_one_name
                                                                                                                                                    ? applicationdetaildata.application_accepted.user_one.user_one_name
                                                                                                                                                    : ""}
                                                                                                                                            </div>
                                                                                                                                        </>
                                                                                                                                    ) : (
                                                                                                                                        ""
                                                                                                                                    )}
                                                                                                                                    and
                                                                                                                                    {applicationdetaildata.application_accepted && applicationdetaildata.application_accepted.user_two.user_two_name ? (
                                                                                                                                        <div className="symbol symbol-30px symbol-circle accepted_application">
                                                                                                                                            <img
                                                                                                                                                className="mx-2"
                                                                                                                                                alt="Pic"
                                                                                                                                                src={
                                                                                                                                                    applicationdetaildata.application_accepted.user_two.user_two_image
                                                                                                                                                        ? applicationdetaildata.application_accepted.user_two.user_two_image
                                                                                                                                                        : "/assets/media/avatars/blank.png"
                                                                                                                                                }
                                                                                                                                            />
                                                                                                                                            {applicationdetaildata.application_accepted.user_two.user_two_name
                                                                                                                                                ? applicationdetaildata.application_accepted.user_two.user_two_name
                                                                                                                                                : ""}
                                                                                                                                        </div>
                                                                                                                                    ) : (
                                                                                                                                        ""
                                                                                                                                    )}
                                                                                                                                    {applicationdetaildata.application_accepted && applicationdetaildata.application_accepted.user_three.user_three_name ? (
                                                                                                                                        <>
                                                                                                                                            and
                                                                                                                                            <div className="symbol symbol-30px symbol-circle accepted_application">
                                                                                                                                                <img
                                                                                                                                                    className="mx-2"
                                                                                                                                                    alt="Pic"
                                                                                                                                                    src={
                                                                                                                                                        applicationdetaildata.application_accepted.user_three.user_three_image
                                                                                                                                                            ? applicationdetaildata.application_accepted.user_three.user_three_image
                                                                                                                                                            : "/assets/media/avatars/blank.png"
                                                                                                                                                    }
                                                                                                                                                />
                                                                                                                                                {applicationdetaildata.application_accepted.user_three.user_three_name
                                                                                                                                                    ? applicationdetaildata.application_accepted.user_three.user_three_name
                                                                                                                                                    : ""}
                                                                                                                                            </div>
                                                                                                                                        </>
                                                                                                                                    ) : (
                                                                                                                                        ""
                                                                                                                                    )}
                                                                                                                                </span>
                                                                                                                            )}
                                                                                                                        </>
                                                                                                                    )}
                                                                                                                </h3>

                                                                                                                {applicationdetaildata.application_accepted && applicationdetaildata.application_accepted.application_accepted_date ? (
                                                                                                                    <>
                                                                                                                        <span className="text-muted ml-2">
                                                                                                                            {applicationdetaildata.application_accepted.application_accepted_date
                                                                                                                                ? Moment(applicationdetaildata.application_accepted.application_accepted_date).format("h:mm a")
                                                                                                                                : ""}
                                                                                                                        </span>
                                                                                                                    </>
                                                                                                                ) : (
                                                                                                                    <>
                                                                                                                        <span className="text-muted ml-2">{Moment(new Date()).format("h:mm a")}</span>
                                                                                                                    </>
                                                                                                                )}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        {applicationdetaildata.OneAcceptName &&
                                                                                                            applicationdetaildata.OneAcceptName !== applicationdetaildata.application_accepted.user_one.user_one_name ? (
                                                                                                            ""
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                {applicationdetaildata.application_accepted.user_one.user_one_status && (
                                                                                                                    <div className="d-flex align-items-center mb-5 mt-5 me-8">
                                                                                                                        <div className="me-4 position-relative">
                                                                                                                            <div className="symbol symbol-30px symbol-circle">
                                                                                                                                <img
                                                                                                                                    alt="Pic"
                                                                                                                                    src={
                                                                                                                                        applicationdetaildata.application_accepted.user_one.user_one_image
                                                                                                                                            ? applicationdetaildata.application_accepted.user_one.user_one_image
                                                                                                                                            : "/assets/media/avatars/blank.png"
                                                                                                                                    }
                                                                                                                                />
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="fw-bold">
                                                                                                                            <div className="">
                                                                                                                                {applicationdetaildata.application_accepted.user_one.user_one_name
                                                                                                                                    ? applicationdetaildata.application_accepted.user_one.user_one_name
                                                                                                                                    : ""}
                                                                                                                                <span className="text_cap">({applicationdetaildata.application_accepted.user_one.user_one_status}ed)</span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                )}{" "}
                                                                                                                {applicationdetaildata.application_accepted.user_two.user_two_status && (
                                                                                                                    <div className="d-flex align-items-center mb-5 mt-5 me-8">
                                                                                                                        <div className="me-4 position-relative">
                                                                                                                            <div className="symbol symbol-30px symbol-circle">
                                                                                                                                <img
                                                                                                                                    alt="Pic"
                                                                                                                                    src={
                                                                                                                                        applicationdetaildata.application_accepted.user_two.user_two_image
                                                                                                                                            ? applicationdetaildata.application_accepted.user_two.user_two_image
                                                                                                                                            : "/assets/media/avatars/blank.png"
                                                                                                                                    }
                                                                                                                                />
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="fw-bold">
                                                                                                                            <div className="">
                                                                                                                                {applicationdetaildata.application_accepted.user_two.user_two_name
                                                                                                                                    ? applicationdetaildata.application_accepted.user_two.user_two_name
                                                                                                                                    : ""}
                                                                                                                                <span className="text_cap">({applicationdetaildata.application_accepted.user_two.user_two_status}ed)</span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                )}{" "}
                                                                                                                {applicationdetaildata.application_accepted.user_three.user_three_status && (
                                                                                                                    <div className="d-flex align-items-center mb-5 mt-5 me-8">
                                                                                                                        <div className="me-4 position-relative">
                                                                                                                            <div className="symbol symbol-30px symbol-circle">
                                                                                                                                <img
                                                                                                                                    alt="Pic"
                                                                                                                                    src={
                                                                                                                                        applicationdetaildata.application_accepted.user_three.user_three_image
                                                                                                                                            ? applicationdetaildata.application_accepted.user_three.user_three_image
                                                                                                                                            : "/assets/media/avatars/blank.png"
                                                                                                                                    }
                                                                                                                                />
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="fw-bold">
                                                                                                                            <div className="">
                                                                                                                                {applicationdetaildata.application_accepted.user_three.user_three_name
                                                                                                                                    ? applicationdetaildata.application_accepted.user_three.user_three_name
                                                                                                                                    : ""}
                                                                                                                                <span className="text_cap">({applicationdetaildata.application_accepted.user_three.user_three_status}ed)</span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                )}
                                                                                                            </>
                                                                                                        )}

                                                                                                        {applicationdetaildata.setinterview && applicationdetaildata.setinterview === "1" ? (
                                                                                                            <>
                                                                                                                {applicationdetaildata.OneAcceptName ? (
                                                                                                                    <>
                                                                                                                        {applicationdetaildata.OneAcceptName !== applicationdetaildata.application_accepted.user_one.user_one_name ? (
                                                                                                                            <div className="d-flex align-items-center mb-1 mt-1 me-8">
                                                                                                                                <div className="me-1 position-relative">
                                                                                                                                    <div className="symbol symbol-30px symbol-circle">
                                                                                                                                        <img
                                                                                                                                            className="me-2"
                                                                                                                                            alt="Pic"
                                                                                                                                            src={
                                                                                                                                                applicationdetaildata.OneAcceptImage
                                                                                                                                                    ? applicationdetaildata.OneAcceptImage
                                                                                                                                                    : "/assets/media/avatars/blank.png"
                                                                                                                                            }
                                                                                                                                        />
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className="fw-bold">
                                                                                                                                    <div className="fs-7 text-gray-900">
                                                                                                                                        {applicationdetaildata.OneAcceptName}
                                                                                                                                        <span className="me-2">(on the behalf of </span>
                                                                                                                                        <div className="me-1 symbol symbol-30px symbol-circle">
                                                                                                                                            <img
                                                                                                                                                alt="Pic"
                                                                                                                                                src={
                                                                                                                                                    applicationdetaildata.application_accepted.user_one.user_one_image
                                                                                                                                                        ? applicationdetaildata.application_accepted.user_one.user_one_image
                                                                                                                                                        : "/assets/media/avatars/blank.png"
                                                                                                                                                }
                                                                                                                                            />
                                                                                                                                        </div>
                                                                                                                                        {applicationdetaildata.application_accepted.user_one.user_one_name} <span className="me-1">and</span>
                                                                                                                                        <div className="me-1 symbol symbol-30px symbol-circle">
                                                                                                                                            <img
                                                                                                                                                className="mx-1"
                                                                                                                                                alt="Pic"
                                                                                                                                                src={
                                                                                                                                                    applicationdetaildata.application_accepted.user_two.user_two_image
                                                                                                                                                        ? applicationdetaildata.application_accepted.user_two.user_two_image
                                                                                                                                                        : "/assets/media/avatars/blank.png"
                                                                                                                                                }
                                                                                                                                            />
                                                                                                                                        </div>
                                                                                                                                        {applicationdetaildata.application_accepted.user_two.user_two_name})
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        ) : (
                                                                                                                            ""
                                                                                                                        )}
                                                                                                                    </>
                                                                                                                ) : (
                                                                                                                    ""
                                                                                                                )}
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            ""
                                                                                                        )}
                                                                                                        {getreview_application_by_user.map((getreview_application_by_uservalue, index) => (
                                                                                                            <>
                                                                                                                {index < 3 && (
                                                                                                                    <div className="replicationreply" key={getreview_application_by_uservalue._id}>
                                                                                                                        <div className="cursor-pointer symbol symbol-30px reply_user_pic">
                                                                                                                            <img
                                                                                                                                className="me-1"
                                                                                                                                src={
                                                                                                                                    getreview_application_by_uservalue.UserImage
                                                                                                                                        ? getreview_application_by_uservalue.UserImage
                                                                                                                                        : "/assets/media/avatars/blank.png"
                                                                                                                                }
                                                                                                                                alt="user"
                                                                                                                            />
                                                                                                                            <div className="profile-user ms-2">
                                                                                                                                <span className="replyusername">{getreview_application_by_uservalue.UserName}</span>
                                                                                                                                <span className="date_time">
                                                                                                                                    {getreview_application_by_uservalue.createdAt
                                                                                                                                        ? Moment(getreview_application_by_uservalue.createdAt).format("DD MMM YYYY, h:mm a")
                                                                                                                                        : ""}
                                                                                                                                </span>
                                                                                                                                <span className="replymessage">{getreview_application_by_uservalue.comment}</span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                )}
                                                                                                            </>
                                                                                                        ))}

                                                                                                        <div className="text-gray-600  fs-6">
                                                                                                            <div className=" align-items-center mb-2 mt-2">
                                                                                                                {applicationdetaildata.statusint !== 5 && applicationdetaildata.statusint !== 4 && applicationdetaildata.setinterview !== "1" ? (
                                                                                                                    <>
                                                                                                                        {user_role &&
                                                                                                                            user_role.Allapplication &&
                                                                                                                            user_role.Allapplication === "yes" &&
                                                                                                                            applicationdetaildata.status !== "Accepted" &&
                                                                                                                            applicationdetaildata.status !== "Rejected" ? (
                                                                                                                            <>
                                                                                                                                <>
                                                                                                                                    {user_role && user_role.applicationAssignOtherMember && user_role.applicationAssignOtherMember === "yes" && (
                                                                                                                                        <span
                                                                                                                                            onClick={() =>
                                                                                                                                                showAutoAssignOtherMemberpoup(applicationdetaildata, getapplication_accepted_assign, "application_accepted")
                                                                                                                                            }
                                                                                                                                            className="btn btn-sm btn-primary btn-active-primary"
                                                                                                                                        >
                                                                                                                                            Assign Other member
                                                                                                                                        </span>
                                                                                                                                    )}
                                                                                                                                    {user_role && user_role.applicationAccept && user_role.applicationAccept === "yes" && (
                                                                                                                                        <span
                                                                                                                                            onClick={() => showAcceptFinalReviewModalHandle(applicationdetaildata)}
                                                                                                                                            className="btn btn-sm btn-primary btn-active-primary"
                                                                                                                                        >
                                                                                                                                            {" "}
                                                                                                                                            Accept
                                                                                                                                        </span>
                                                                                                                                    )}
                                                                                                                                </>
                                                                                                                                {user_role && user_role.applicationReject && user_role.applicationReject === "yes" && (
                                                                                                                                    <span
                                                                                                                                        onClick={() => showRejectModalHandle(applicationdetaildata)}
                                                                                                                                        className="ms-2 btn btn-sm btn-light-primary fw-bolder"
                                                                                                                                    >
                                                                                                                                        Reject
                                                                                                                                    </span>
                                                                                                                                )}
                                                                                                                            </>
                                                                                                                        ) : (
                                                                                                                            //     ""
                                                                                                                            // ) }
                                                                                                                            <>
                                                                                                                                {(applicationdetaildata.application_accepted &&
                                                                                                                                    applicationdetaildata.application_accepted.user_one.user_one_id === user_info._id &&
                                                                                                                                    !applicationdetaildata.application_accepted.user_one.user_one_status) ||
                                                                                                                                    (applicationdetaildata.application_accepted &&
                                                                                                                                        applicationdetaildata.application_accepted.user_two.user_two_id === user_info._id &&
                                                                                                                                        !applicationdetaildata.application_accepted.user_two.user_two_status) ||
                                                                                                                                    (applicationdetaildata.application_accepted &&
                                                                                                                                        applicationdetaildata.application_accepted.user_three.user_three_id === user_info._id &&
                                                                                                                                        !applicationdetaildata.application_accepted.user_three.user_three_status) ? (
                                                                                                                                    <>
                                                                                                                                        {/* {!applicationdetaildata.assigndate ? ( */}
                                                                                                                                        {applicationdetaildata.status !== "Accepted" && applicationdetaildata.status !== "Rejected" ? (
                                                                                                                                            <>
                                                                                                                                                <>
                                                                                                                                                    {user_role && user_role.applicationAccept && user_role.applicationAccept === "yes" && (
                                                                                                                                                        <span
                                                                                                                                                            onClick={() => showAcceptByUserModalHandle(applicationdetaildata)}
                                                                                                                                                            className="btn btn-sm btn-primary btn-active-primary"
                                                                                                                                                        >
                                                                                                                                                            {" "}
                                                                                                                                                            Accept
                                                                                                                                                        </span>
                                                                                                                                                    )}
                                                                                                                                                </>
                                                                                                                                                {user_role && user_role.applicationReject && user_role.applicationReject === "yes" && (
                                                                                                                                                    <span
                                                                                                                                                        onClick={() => showRejectByUserModalHandle(applicationdetaildata)}
                                                                                                                                                        className="ms-2 btn btn-sm btn-light-primary fw-bolder"
                                                                                                                                                    >
                                                                                                                                                        Reject
                                                                                                                                                    </span>
                                                                                                                                                )}
                                                                                                                                            </>
                                                                                                                                        ) : (
                                                                                                                                            ""
                                                                                                                                        )}
                                                                                                                                    </>
                                                                                                                                ) : (
                                                                                                                                    ""
                                                                                                                                )}
                                                                                                                            </>
                                                                                                                        )}
                                                                                                                    </>
                                                                                                                ) : (
                                                                                                                    ""
                                                                                                                )}
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div className="comment-post-main">
                                                                                                            <span className="showreplymessage" onClick={() => showapplicationreply(replyapplicationformreply, "show_application_accepted")}>
                                                                                                                <i className="bi bi-chat-square"></i>
                                                                                                                <span>{getapplication_accepted.length}</span>
                                                                                                            </span>
                                                                                                            {applicationformreply === "show" && apptype === "show_application_accepted" ? (
                                                                                                                <>
                                                                                                                    {getapplication_accepted.map((getapplication_acceptedvalue) => (
                                                                                                                        <div className="replicationreply" key={getapplication_acceptedvalue._id}>
                                                                                                                            <div className="cursor-pointer symbol symbol-30px reply_user_pic">
                                                                                                                                <img
                                                                                                                                    src={
                                                                                                                                        getapplication_acceptedvalue.UserImage ? getapplication_acceptedvalue.UserImage : "/assets/media/avatars/blank.png"
                                                                                                                                    }
                                                                                                                                    alt="user"
                                                                                                                                />
                                                                                                                                <div className="profile-user ms-2">
                                                                                                                                    <span className="replyusername">{getapplication_acceptedvalue.UserName}</span>
                                                                                                                                    <span className="date_time">
                                                                                                                                        {getapplication_acceptedvalue.createdAt
                                                                                                                                            ? Moment(getapplication_acceptedvalue.createdAt).format("DD MMM YYYY, h:mm a")
                                                                                                                                            : ""}
                                                                                                                                    </span>
                                                                                                                                    <span className="replymessage">{getapplication_acceptedvalue.comment}</span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    ))}
                                                                                                                </>
                                                                                                            ) : (
                                                                                                                ""
                                                                                                            )}
                                                                                                        </div>
                                                                                                        {user_role && user_role.applicationComment && user_role.applicationComment === "yes" && (
                                                                                                            <div className="comment-box mb-2">
                                                                                                                <form className="form w-100" onSubmit={(event) => SubmitHandler(event, "application_accepted")}>
                                                                                                                    <textarea
                                                                                                                        key="application_accepted_1"
                                                                                                                        className="form-control form-control-lg mt-3 mb-3"
                                                                                                                        value={
                                                                                                                            application_form.application_accepted && application_form.application_accepted.value
                                                                                                                                ? application_form.application_accepted.value
                                                                                                                                : ""
                                                                                                                        }
                                                                                                                        onChange={(event) => inputChangeHandler(event, "application_accepted")}
                                                                                                                    ></textarea>

                                                                                                                    <button type="submit" id="kt_new_password_submit" className="btn btn-sm fw-bolder me-4">
                                                                                                                        <span className="indicator-label">Post Comment</span>
                                                                                                                    </button>
                                                                                                                </form>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </div>
                                                                                                </div>
                                                                                            ) : (
                                                                                                ""
                                                                                            )}
                                                                                        </>
                                                                                    )}
                                                                            </>
                                                                        )}


                                                                        {/* end Initial Review Completed */}
                                                                        {/* Start Final Review Completed */}
                                                                        {/* <div className="timeline-item">
                                                                            <div className="timeline-media">
                                                                                <span className="fs-2 fw-bolder date-one">28</span>
                                                                                <br />
                                                                                <span className="opacity-50 fs-8 fw-bold">July 2021</span>
                                                                            </div>
                                                                            <div className="timeline-content">
                                                                                <div className="d-flex align-items-center justify-content-between mb-3">
                                                                                    <div className="mr-2">
                                                                                        <h3 className="text-dark-75 font-weight-bold fs-4">Final Review Completed 456</h3>
                                                                                        <span className="text-muted ml-2">4:20 PM</span>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="d-flex align-items-center mb-5 mt-5">
                                                                                    <div className="text-gray-600  fs-6">
                                                                                        <p className="p-0">
                                                                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the
                                                                                            1500s, when an unknown printer took a galley of type and scrambled it
                                                                                        </p>

                                                                                        <div className="d-flex align-items-center mb-5 mt-5">
                                                                                            <span className="btn btn-sm btn-primary btn-active-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_invite_friends">
                                                                                                SetUp Interview
                                                                                            </span>
                                                                                            {user_role && user_role.applicationReject && user_role.applicationReject === "yes" && (
                                                                                                <span onClick={() => showRejectModalHandle(applicationdetaildata)} className="ms-2 btn btn-sm btn-light-primary fw-bolder">
                                                                                                    Reject
                                                                                                </span>
                                                                                            )}
                                                                                        </div>

                                                                                        <div className="comment-post-main">
                                                                                            <span className="showreplymessage" onClick={() => showapplicationreply(replyapplicationformreply, "show_final_review")}>
                                                                                                <i className="bi bi-chat-square"></i>
                                                                                                <span>{getfinal_review.length}</span>
                                                                                            </span>
                                                                                            {applicationformreply === "show" && apptype === "show_final_review" ? (
                                                                                                <>
                                                                                                    {getfinal_review.map((getfinal_reviewvalue) => (
                                                                                                        <div className="replicationreply" key={getfinal_reviewvalue._id}>
                                                                                                            <div className="cursor-pointer symbol symbol-30px  reply_user_pic">
                                                                                                                <img src={getfinal_reviewvalue.UserImage ? getfinal_reviewvalue.UserImage : "/assets/media/avatars/blank.png"} alt="user" />
                                                                                                                <div className="profile-user ms-2">
                                                                                                                    <span className="replyusername">{getfinal_reviewvalue.UserName}</span>
                                                                                                                    <span className="date_time">
                                                                                                                        {getfinal_reviewvalue.createdAt ? Moment(getfinal_reviewvalue.createdAt).format("DD MMM YYYY, h:mm a") : ""}
                                                                                                                    </span>
                                                                                                                    <span className="replymessage">{getfinal_reviewvalue.comment}</span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    ))}
                                                                                                </>
                                                                                            ) : (
                                                                                                ""
                                                                                            )}
                                                                                        </div>
                                                                                        {user_role && user_role.applicationComment && user_role.applicationComment === "yes" && (
                                                                                            <div className="comment-box mb-2">
                                                                                                <form className="form w-100" onSubmit={(event) => SubmitHandler(event, "final_review")}>
                                                                                                    <textarea
                                                                                                        key="final_review_1"
                                                                                                        className="form-control form-control-lg mt-3 mb-3"
                                                                                                        value={application_form.final_review && application_form.final_review.value ? application_form.final_review.value : ""}
                                                                                                        onChange={(event) => inputChangeHandler(event, "final_review")}
                                                                                                    ></textarea>

                                                                                                    <button type="submit" id="kt_new_password_submit" className="btn btn-sm fw-bolder me-4">
                                                                                                        <span className="indicator-label">Post Comment</span>
                                                                                                    </button>
                                                                                                </form>
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div> */}

                                                                        {/* end Final Review Completed */}

                                                                        {/* Start Interview Round 1 Completed */}
                                                                        {((user_role.Allapplication && user_role.Allapplication === "yes") ||
                                                                            (applicationdetaildata.assign_for_review && applicationdetaildata.assign_for_review.assign_member.member_id === user_info._id) ||
                                                                            (applicationdetaildata.application_accepted &&
                                                                                applicationdetaildata.application_accepted.user_one.user_one_id &&
                                                                                applicationdetaildata.application_accepted.user_one.user_one_id === user_info._id &&
                                                                                applicationdetaildata.application_interview.interview_one &&
                                                                                applicationdetaildata.application_interview.interview_one.interview_one_id !== user_info._id &&
                                                                                applicationdetaildata.application_interview.interview_two &&
                                                                                applicationdetaildata.application_interview.interview_two.interview_two_id !== user_info._id &&
                                                                                applicationdetaildata.application_interview.interview_three &&
                                                                                applicationdetaildata.application_interview.interview_three.interview_three_id !== user_info._id) ||
                                                                            (applicationdetaildata.application_accepted &&
                                                                                applicationdetaildata.application_accepted.user_one.user_one_id &&
                                                                                applicationdetaildata.application_accepted.user_two.user_two_id === user_info._id &&
                                                                                applicationdetaildata.application_interview.interview_one &&
                                                                                applicationdetaildata.application_interview.interview_one.interview_one_id !== user_info._id &&
                                                                                applicationdetaildata.application_interview.interview_two &&
                                                                                applicationdetaildata.application_interview.interview_two.interview_two_id !== user_info._id &&
                                                                                applicationdetaildata.application_interview.interview_three &&
                                                                                applicationdetaildata.application_interview.interview_three.interview_three_id !== user_info._id) ||
                                                                            (applicationdetaildata.application_accepted &&
                                                                                applicationdetaildata.application_accepted.user_one.user_one_id &&
                                                                                applicationdetaildata.application_accepted.user_three.user_three_id === user_info._id &&
                                                                                applicationdetaildata.application_interview.interview_one &&
                                                                                applicationdetaildata.application_interview.interview_one.interview_one_id !== user_info._id &&
                                                                                applicationdetaildata.application_interview.interview_two &&
                                                                                applicationdetaildata.application_interview.interview_two.interview_two_id !== user_info._id &&
                                                                                applicationdetaildata.application_interview.interview_three &&
                                                                                applicationdetaildata.application_interview.interview_three.interview_three_id !== user_info._id)) &&
                                                                            applicationdetaildata.setinterview &&
                                                                            applicationdetaildata.setinterview === "1" && (


                                                                                <>
                                                                                    {(user_role.Allapplication && user_role.Allapplication === "yes") && (
                                                                                        <div className="timeline-item">
                                                                                            <div className="timeline-media">
                                                                                                <span className="fs-2 fw-bolder date-one">
                                                                                                    {applicationdetaildata.application_interview.interview_accepted_date
                                                                                                        ? Moment(applicationdetaildata.application_interview.interview_accepted_date).format("DD")
                                                                                                        : ""}{" "}
                                                                                                </span>
                                                                                                <br />
                                                                                                <span className="opacity-50 fs-8 fw-bold">
                                                                                                    {applicationdetaildata.application_interview.interview_accepted_date
                                                                                                        ? Moment(applicationdetaildata.application_interview.interview_accepted_date).format("MMM YYYY")
                                                                                                        : ""}
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className="timeline-content">
                                                                                                <div className="d-flex align-items-center justify-content-between mb-3">
                                                                                                    <div className="mr-2">
                                                                                                        {applicationdetaildata.application_interview.user_setroundone &&
                                                                                                            applicationdetaildata.application_interview.user_setroundone !== "1" &&
                                                                                                            applicationdetaildata.statusint !== 4 ? (
                                                                                                            <>
                                                                                                                {applicationdetaildata.application_interview && applicationdetaildata.application_interview.interview_three.interview_three_id ? (
                                                                                                                    <>
                                                                                                                        <h3 className="text-dark-75 font-weight-bold fs-4">
                                                                                                                            Interview Round 1 rejected by
                                                                                                                            {applicationdetaildata.application_interview &&
                                                                                                                                applicationdetaildata.application_interview.interview_one.interview_one_status === "reject" && (
                                                                                                                                    <>
                                                                                                                                        {applicationdetaildata.application_interview &&
                                                                                                                                            applicationdetaildata.application_interview.interview_one.interview_one_name ? (
                                                                                                                                            <div className="symbol symbol-30px symbol-circle accepted_application">
                                                                                                                                                {/* <img
                                                                                                                                    alt="Pic"
                                                                                                                                    src={
                                                                                                                                        applicationdetaildata.application_interview.interview_one.interview_one_image
                                                                                                                                            ? applicationdetaildata.application_interview.interview_one.interview_one_image
                                                                                                                                            : "/assets/media/avatars/blank.png"
                                                                                                                                    }
                                                                                                                                /> */}
                                                                                                                                                {applicationdetaildata.application_interview.interview_one.interview_one_name
                                                                                                                                                    ? applicationdetaildata.application_interview.interview_one.interview_one_name
                                                                                                                                                    : ""}
                                                                                                                                            </div>
                                                                                                                                        ) : (
                                                                                                                                            ""
                                                                                                                                        )}
                                                                                                                                    </>
                                                                                                                                )}
                                                                                                                            {applicationdetaildata.application_interview &&
                                                                                                                                applicationdetaildata.application_interview.interview_two.interview_two_status === "reject" && (
                                                                                                                                    <>
                                                                                                                                        {applicationdetaildata.application_interview &&
                                                                                                                                            applicationdetaildata.application_interview.interview_two.interview_two_name ? (
                                                                                                                                            <div className="symbol symbol-30px symbol-circle accepted_application">
                                                                                                                                                {/* <img
                                                                                                                                    alt="Pic"
                                                                                                                                    src={
                                                                                                                                        applicationdetaildata.application_interview.interview_two.interview_two_image
                                                                                                                                            ? applicationdetaildata.application_interview.interview_two.interview_two_image
                                                                                                                                            : "/assets/media/avatars/blank.png"
                                                                                                                                    }
                                                                                                                                /> */}
                                                                                                                                                {applicationdetaildata.application_interview.interview_two.interview_two_name
                                                                                                                                                    ? applicationdetaildata.application_interview.interview_two.interview_two_name
                                                                                                                                                    : ""}
                                                                                                                                            </div>
                                                                                                                                        ) : (
                                                                                                                                            ""
                                                                                                                                        )}
                                                                                                                                    </>
                                                                                                                                )}
                                                                                                                            and assigned to
                                                                                                                            {applicationdetaildata.application_interview &&
                                                                                                                                applicationdetaildata.application_interview.interview_three.interview_three_name ? (
                                                                                                                                <div className="symbol symbol-30px symbol-circle accepted_application">
                                                                                                                                    {/* <img
                                                                                                                    alt="Pic"
                                                                                                                    src={
                                                                                                                        applicationdetaildata.application_interview.interview_three.interview_three_image
                                                                                                                            ? applicationdetaildata.application_interview.interview_three.interview_three_image
                                                                                                                            : "/assets/media/avatars/blank.png"
                                                                                                                    }
                                                                                                                /> */}
                                                                                                                                    {applicationdetaildata.application_interview.interview_three.interview_three_name
                                                                                                                                        ? applicationdetaildata.application_interview.interview_three.interview_three_name
                                                                                                                                        : ""}
                                                                                                                                </div>
                                                                                                                            ) : (
                                                                                                                                ""
                                                                                                                            )}
                                                                                                                            for examine
                                                                                                                        </h3>
                                                                                                                        <h6>({applicationdetaildata.recoredurl ? <Link to={{ pathname: applicationdetaildata.recoredurl }} target="_blank">{applicationdetaildata.recoredurl}</Link> : ""})</h6>
                                                                                                                    </>
                                                                                                                ) : (
                                                                                                                    <>
                                                                                                                        <h3 className="text-dark-75 font-weight-bold">
                                                                                                                            Final Review Completed And Setup Interview Round 1

                                                                                                                        </h3>
                                                                                                                        <h6>
                                                                                                                            {applicationdetaildata.interviewdate && (
                                                                                                                                <>
                                                                                                                                    ({applicationdetaildata.meetingdate ? applicationdetaildata.meetingdate : ""},
                                                                                                                                    <span className="ms-2">
                                                                                                                                        {applicationdetaildata.meetingtime ? applicationdetaildata.meetingtime : ""},
                                                                                                                                    </span>
                                                                                                                                    <span className="ms-2">{applicationdetaildata.zoomlink ? applicationdetaildata.zoomlink : ""},</span>
                                                                                                                                    <span className="ms-2">{applicationdetaildata.recoredurl ? <Link to={{ pathname: applicationdetaildata.recoredurl }} target="_blank">{applicationdetaildata.recoredurl}</Link> : ""}</span>)
                                                                                                                                </>
                                                                                                                            )}
                                                                                                                        </h6>
                                                                                                                    </>
                                                                                                                )}
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                {applicationdetaildata.application_interview &&
                                                                                                                    applicationdetaildata.application_interview.interview_three.interview_three_id &&
                                                                                                                    applicationdetaildata.statusint === 4 ? (
                                                                                                                    <h3 className="text-dark-75 font-weight-bold">
                                                                                                                        Interview Round 1 Rejected by
                                                                                                                        <div className="symbol symbol-30px symbol-circle accepted_application">
                                                                                                                            <span>
                                                                                                                                {applicationdetaildata.application_interview.interview_three.interview_three_name
                                                                                                                                    ? applicationdetaildata.application_interview.interview_three.interview_three_name
                                                                                                                                    : ""}
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                    </h3>
                                                                                                                ) : (
                                                                                                                    <h3 className="text-dark-75 font-weight-bold">Interview Round 1 completed</h3>
                                                                                                                )}
                                                                                                            </>
                                                                                                        )}
                                                                                                        <span className="text-muted ml-2">
                                                                                                            {applicationdetaildata.application_interview.interview_accepted_date
                                                                                                                ? Moment(applicationdetaildata.application_interview.interview_accepted_date).format("h:mm a")
                                                                                                                : ""}
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                {getreview_interview_round.map((getreview_interview_roundvalue, index) => (
                                                                                                    <>
                                                                                                        {index > getreview_interview_round.length - 4 && (
                                                                                                            <div className="replicationreply" key={getreview_interview_roundvalue._id}>
                                                                                                                <div className="cursor-pointer symbol symbol-30px symbol-md-50px reply_user_pic">
                                                                                                                    <img
                                                                                                                        src={getreview_interview_roundvalue.UserImage ? getreview_interview_roundvalue.UserImage : "/assets/media/avatars/blank.png"}
                                                                                                                        alt="user"
                                                                                                                    />
                                                                                                                    <div className="profile-user ms-2">
                                                                                                                        <span className="replyusername">{getreview_interview_roundvalue.UserName}</span>
                                                                                                                        <span className="date_time">
                                                                                                                            {getreview_interview_roundvalue.createdAt
                                                                                                                                ? Moment(getreview_interview_roundvalue.createdAt).format("DD MMM YYYY, h:mm a")
                                                                                                                                : ""}
                                                                                                                        </span>
                                                                                                                        <span className="replymessage">{getreview_interview_roundvalue.comment}</span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </>
                                                                                                ))}
                                                                                                <div className="align-items-center mb-5 mt-5">
                                                                                                    <h5>Assigned Interviewer </h5>
                                                                                                    <div className="d-flex text-gray-600  fs-6 interviewr-outer">
                                                                                                        <div className="d-flex align-items-center mb-5 mt-5 me-8">
                                                                                                            <div className="me-4 position-relative">
                                                                                                                <div className="symbol symbol-30px symbol-circle">
                                                                                                                    <img
                                                                                                                        alt="Pic"
                                                                                                                        src={
                                                                                                                            applicationdetaildata.application_interview.interview_one.interview_one_image
                                                                                                                                ? applicationdetaildata.application_interview.interview_one.interview_one_image
                                                                                                                                : "/assets/media/avatars/blank.png"
                                                                                                                        }
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="fw-bold">
                                                                                                                <div className="fs-7 text-gray-900">
                                                                                                                    {applicationdetaildata.application_interview.interview_one.interview_one_name
                                                                                                                        ? applicationdetaildata.application_interview.interview_one.interview_one_name
                                                                                                                        : ""}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div className="d-flex align-items-center mb-5 mt-5 me-8">
                                                                                                            <div className="me-2 position-relative">
                                                                                                                <div className="symbol symbol-30px symbol-circle">
                                                                                                                    <img
                                                                                                                        alt="Pic"
                                                                                                                        src={
                                                                                                                            applicationdetaildata.application_interview.interview_two.interview_two_image
                                                                                                                                ? applicationdetaildata.application_interview.interview_two.interview_two_image
                                                                                                                                : "/assets/media/avatars/blank.png"
                                                                                                                        }
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="fw-bold">
                                                                                                                <div className="fs-7 text-gray-900">
                                                                                                                    {applicationdetaildata.application_interview.interview_two.interview_two_name
                                                                                                                        ? applicationdetaildata.application_interview.interview_two.interview_two_name
                                                                                                                        : ""}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        {applicationdetaildata.application_interview.interview_three.interview_three_name && (
                                                                                                            <div className="d-flex align-items-center mb-5 mt-5 me-8">
                                                                                                                <div className="me-4 position-relative">
                                                                                                                    <div className="symbol symbol-30px symbol-circle">
                                                                                                                        <img
                                                                                                                            alt="Pic"
                                                                                                                            src={
                                                                                                                                applicationdetaildata.application_interview.interview_three.interview_three_image
                                                                                                                                    ? applicationdetaildata.application_interview.interview_three.interview_three_image
                                                                                                                                    : "/assets/media/avatars/blank.png"
                                                                                                                            }
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="fw-bold">
                                                                                                                    <div className="fs-7 text-gray-900">
                                                                                                                        {applicationdetaildata.application_interview.interview_three.interview_three_name
                                                                                                                            ? applicationdetaildata.application_interview.interview_three.interview_three_name
                                                                                                                            : ""}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </div>
                                                                                                    {applicationdetaildata.application_interview.interview_one.interview_one_status && (
                                                                                                        <div className="d-flex align-items-center mb-5 mt-5 me-8">
                                                                                                            <div className="me-4 position-relative">
                                                                                                                <div className="symbol symbol-30px symbol-circle">
                                                                                                                    <img
                                                                                                                        alt="Pic"
                                                                                                                        src={
                                                                                                                            applicationdetaildata.application_interview.interview_one.interview_one_image
                                                                                                                                ? applicationdetaildata.application_interview.interview_one.interview_one_image
                                                                                                                                : "/assets/media/avatars/blank.png"
                                                                                                                        }
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="fw-bold">
                                                                                                                <div className="">
                                                                                                                    {applicationdetaildata.application_interview.interview_one.interview_one_name
                                                                                                                        ? applicationdetaildata.application_interview.interview_one.interview_one_name
                                                                                                                        : ""}{" "}
                                                                                                                    <span className="text_cap">({applicationdetaildata.application_interview.interview_one.interview_one_status}ed)</span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )}
                                                                                                    {applicationdetaildata.application_interview.interview_two.interview_two_status && (
                                                                                                        <div className="d-flex align-items-center mb-5 mt-5 me-8">
                                                                                                            <div className="me-4 position-relative">
                                                                                                                <div className="symbol symbol-30px symbol-circle">
                                                                                                                    <img
                                                                                                                        alt="Pic"
                                                                                                                        src={
                                                                                                                            applicationdetaildata.application_interview.interview_two.interview_two_image
                                                                                                                                ? applicationdetaildata.application_interview.interview_two.interview_two_image
                                                                                                                                : "/assets/media/avatars/blank.png"
                                                                                                                        }
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="fw-bold">
                                                                                                                <div className="">
                                                                                                                    {applicationdetaildata.application_interview.interview_two.interview_two_name
                                                                                                                        ? applicationdetaildata.application_interview.interview_two.interview_two_name
                                                                                                                        : ""}{" "}
                                                                                                                    <span className="text_cap">({applicationdetaildata.application_interview.interview_two.interview_two_status}ed)</span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )}
                                                                                                    {applicationdetaildata.application_interview.interview_three.interview_three_status && (
                                                                                                        <div className="d-flex align-items-center mb-5 mt-5 me-8">
                                                                                                            <div className="me-4 position-relative">
                                                                                                                <div className="symbol symbol-30px symbol-circle">
                                                                                                                    <img
                                                                                                                        alt="Pic"
                                                                                                                        src={
                                                                                                                            applicationdetaildata.application_interview.interview_three.interview_three_image
                                                                                                                                ? applicationdetaildata.application_interview.interview_three.interview_three_image
                                                                                                                                : "/assets/media/avatars/blank.png"
                                                                                                                        }
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="fw-bold">
                                                                                                                <div className="">
                                                                                                                    {applicationdetaildata.application_interview.interview_three.interview_three_name
                                                                                                                        ? applicationdetaildata.application_interview.interview_three.interview_three_name
                                                                                                                        : ""}{" "}
                                                                                                                    <span className="text_cap">({applicationdetaildata.application_interview.interview_three.interview_three_status}ed)</span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )}
                                                                                                    {user_role && user_role.Allapplication && user_role.Allapplication === "yes" && user_info && user_info.usertype && user_info.usertype === "admin" && (
                                                                                                        <>
                                                                                                            {applicationdetaildata.application_interview.user_setroundone &&
                                                                                                                applicationdetaildata.application_interview.user_setroundone !== "1" &&
                                                                                                                applicationdetaildata.statusint !== 4 ? (
                                                                                                                <>
                                                                                                                    {user_role && user_role.applicationAccept && user_role.applicationAccept === "yes" && (
                                                                                                                        <span
                                                                                                                            onClick={() => showAutoAcceptModalHandle(applicationdetaildata, "interview_round_1")}
                                                                                                                            className="btn btn-sm btn-primary btn-active-primary"
                                                                                                                        >
                                                                                                                            Accept
                                                                                                                        </span>
                                                                                                                    )}
                                                                                                                    {user_role && user_role.applicationReject && user_role.applicationReject === "yes" && (
                                                                                                                        <span onClick={() => showRejectModalHandle(applicationdetaildata)} className="ms-2 btn btn-sm btn-light-primary fw-bolder">
                                                                                                                            Reject
                                                                                                                        </span>
                                                                                                                    )}
                                                                                                                    {user_role && user_role.applicationAssignOtherMember && user_role.applicationAssignOtherMember === "yes" && (
                                                                                                                        <span
                                                                                                                            onClick={() => showAutoAssignOtherMemberpoup(applicationdetaildata, getapplication_interview_assign, "interview_round_1")}
                                                                                                                            className="btn btn-sm btn-primary btn-active-primary"
                                                                                                                        >
                                                                                                                            {" "}
                                                                                                                            Assign Other member {" "}
                                                                                                                        </span>
                                                                                                                    )}
                                                                                                                    {!applicationdetaildata.application_interview.interview_one.interview_one_status && !applicationdetaildata.application_interview.interview_two.interview_two_status && <span
                                                                                                                        onClick={() => Donotsetup(applicationdetaildata)}
                                                                                                                        className="btn btn-sm btn-primary btn-active-primary"
                                                                                                                    >Didn't Show Up
                                                                                                                    </span>}
                                                                                                                    {user_role && user_role.applicationExternalMember && user_role.applicationExternalMember === "yes" && (
                                                                                                                        <span
                                                                                                                            onClick={() => showExternalMemberModalHandle(applicationdetaildata, "interview_round_1")}
                                                                                                                            className="fw-bolder external_member_add"
                                                                                                                        >
                                                                                                                            {" "}
                                                                                                                            + External Member
                                                                                                                        </span>
                                                                                                                    )}
                                                                                                                </>
                                                                                                            ) : (
                                                                                                                ""
                                                                                                            )}
                                                                                                        </>
                                                                                                    )}
                                                                                                    {user_role && user_role.Allapplication && user_role.Allapplication === "yes" && user_info && user_info.usertype && user_info.usertype !== "admin"
                                                                                                        && (
                                                                                                            <>
                                                                                                                {((applicationdetaildata.application_interview &&
                                                                                                                    applicationdetaildata.application_interview.interview_one.interview_one_id === user_info._id &&
                                                                                                                    applicationdetaildata.application_interview.interview_one.interview_one_status) ||
                                                                                                                    (applicationdetaildata.application_interview &&
                                                                                                                        applicationdetaildata.application_interview.interview_two.interview_two_id === user_info._id &&
                                                                                                                        applicationdetaildata.application_interview.interview_two.interview_two_status) ||
                                                                                                                    (applicationdetaildata.application_interview &&
                                                                                                                        applicationdetaildata.application_interview.interview_three.interview_three_id === user_info._id &&
                                                                                                                        applicationdetaildata.application_interview.interview_three.interview_three_status))
                                                                                                                    ? (
                                                                                                                        <>
                                                                                                                            {user_role && user_role.applicationAssignOtherMember && user_role.applicationAssignOtherMember === "yes" && (
                                                                                                                                <span
                                                                                                                                    onClick={() =>
                                                                                                                                        showAutoAssignOtherMemberpoup(
                                                                                                                                            applicationdetaildata,
                                                                                                                                            getapplication_interview_assign,
                                                                                                                                            "interview_round_1"
                                                                                                                                        )
                                                                                                                                    }
                                                                                                                                    className="btn btn-sm btn-primary btn-active-primary"
                                                                                                                                >
                                                                                                                                    {" "}
                                                                                                                                    Assign Other member{" "}
                                                                                                                                </span>
                                                                                                                            )}
                                                                                                                        </>
                                                                                                                    ) : ('')}
                                                                                                            </>
                                                                                                        )}
                                                                                                    <>
                                                                                                        {user_role &&
                                                                                                            user_role.Allapplication &&
                                                                                                            user_role.Allapplication === "yes" &&
                                                                                                            user_info &&
                                                                                                            user_info.usertype &&
                                                                                                            user_info.usertype !== "admin" && (
                                                                                                                <>
                                                                                                                    {applicationdetaildata.application_interview.user_setroundone &&
                                                                                                                        applicationdetaildata.application_interview.user_setroundone !== "1" &&
                                                                                                                        applicationdetaildata.statusint !== 4 ? (
                                                                                                                        <>
                                                                                                                            {(applicationdetaildata.application_interview &&
                                                                                                                                applicationdetaildata.application_interview.interview_one.interview_one_id === user_info._id &&
                                                                                                                                applicationdetaildata.application_interview.interview_one.interview_one_status) ||
                                                                                                                                (applicationdetaildata.application_interview &&
                                                                                                                                    applicationdetaildata.application_interview.interview_two.interview_two_id === user_info._id &&
                                                                                                                                    applicationdetaildata.application_interview.interview_two.interview_two_status) ||
                                                                                                                                (applicationdetaildata.application_interview &&
                                                                                                                                    applicationdetaildata.application_interview.interview_three.interview_three_id === user_info._id &&
                                                                                                                                    applicationdetaildata.application_interview.interview_three.interview_three_status) ||
                                                                                                                                (applicationdetaildata.application_interview && applicationdetaildata.application_interview.user_setroundone === "1") ? (
                                                                                                                                ""
                                                                                                                            ) : (
                                                                                                                                <>
                                                                                                                                    {applicationdetaildata.statusint &&
                                                                                                                                        applicationdetaildata.statusint !== 5 &&
                                                                                                                                        applicationdetaildata.statusint &&
                                                                                                                                        applicationdetaildata.statusint !== 4 ? (
                                                                                                                                        <div className="align-items-center mb-5 mt-5">
                                                                                                                                            <>
                                                                                                                                                {user_role && user_role.applicationAccept && user_role.applicationAccept === "yes" && (
                                                                                                                                                    <span
                                                                                                                                                        onClick={() => showAcceptByInterviewModalHandle(applicationdetaildata)}
                                                                                                                                                        className="btn btn-sm btn-primary btn-active-primary"
                                                                                                                                                    >
                                                                                                                                                        Accept
                                                                                                                                                    </span>
                                                                                                                                                )}
                                                                                                                                                {user_role && user_role.applicationReject && user_role.applicationReject === "yes" && (
                                                                                                                                                    <span
                                                                                                                                                        onClick={() => showRejectByInterviewModalHandle(applicationdetaildata)}
                                                                                                                                                        className="ms-2 btn btn-sm btn-light-primary fw-bolder"
                                                                                                                                                    >
                                                                                                                                                        Reject
                                                                                                                                                    </span>
                                                                                                                                                )}
                                                                                                                                            </>
                                                                                                                                            {user_role && user_role.applicationAssignOtherMember && user_role.applicationAssignOtherMember === "yes" && (
                                                                                                                                                <span
                                                                                                                                                    onClick={() =>
                                                                                                                                                        showAutoAssignOtherMemberpoup(
                                                                                                                                                            applicationdetaildata,
                                                                                                                                                            getapplication_interview_assign,
                                                                                                                                                            "interview_round_1"
                                                                                                                                                        )
                                                                                                                                                    }
                                                                                                                                                    className="btn btn-sm btn-primary btn-active-primary"
                                                                                                                                                >
                                                                                                                                                    {" "}
                                                                                                                                                    Assign Other member{" "}
                                                                                                                                                </span>
                                                                                                                                            )}
                                                                                                                                            {!applicationdetaildata.application_interview.interview_one.interview_one_status && !applicationdetaildata.application_interview.interview_two.interview_two_status && <span
                                                                                                                                                onClick={() => Donotsetup(applicationdetaildata)}
                                                                                                                                                className="btn btn-sm btn-primary btn-active-primary"
                                                                                                                                            >Didn't Show Up
                                                                                                                                            </span>}

                                                                                                                                            {user_role && user_role.applicationExternalMember && (
                                                                                                                                                <span
                                                                                                                                                    onClick={() => showExternalMemberModalHandle(applicationdetaildata, "interview_round_1")}
                                                                                                                                                    className="fw-bolder external_member_add"
                                                                                                                                                >
                                                                                                                                                    {" "}
                                                                                                                                                    + External Member
                                                                                                                                                </span>
                                                                                                                                            )}
                                                                                                                                        </div>
                                                                                                                                    ) : (
                                                                                                                                        ""
                                                                                                                                    )}
                                                                                                                                </>
                                                                                                                            )}
                                                                                                                        </>
                                                                                                                    ) : (
                                                                                                                        ""
                                                                                                                    )}
                                                                                                                </>
                                                                                                            )}
                                                                                                    </>
                                                                                                </div>
                                                                                                {applicationdetaildata.setroundone === "1" && (!applicationdetaildata.setinterviewTwoRound || applicationdetaildata.setinterviewTwoRound === "0") && <span onClick={() => showInterviewTwoModalHandle(applicationdetaildata)} className="btn btn-sm btn-primary btn-active-primary">
                                                                                                    Schedule Interview Round Two
                                                                                                </span>}
                                                                                                <div className="comment-post-main">
                                                                                                    <span className="showreplymessage" onClick={() => showapplicationreply(replyapplicationformreply, "show_interview_round")}>
                                                                                                        <i className="bi bi-chat-square"></i>
                                                                                                        <span>{getinterview_round.length}</span>
                                                                                                    </span>
                                                                                                    {applicationformreply === "show" && apptype === "show_interview_round" ? (
                                                                                                        <>
                                                                                                            {getinterview_round.map((getinterview_roundvalue) => (
                                                                                                                <div className="replicationreply" key={getinterview_roundvalue._id}>
                                                                                                                    <div className="cursor-pointer symbol symbol-30px reply_user_pic">
                                                                                                                        <img
                                                                                                                            src={getinterview_roundvalue.UserImage ? getinterview_roundvalue.UserImage : "/assets/media/avatars/blank.png"}
                                                                                                                            alt="user"
                                                                                                                        />
                                                                                                                        <div className="profile-user ms-2">
                                                                                                                            <span className="replyusername">{getinterview_roundvalue.UserName}</span>
                                                                                                                            <span className="date_time">
                                                                                                                                {getinterview_roundvalue.createdAt ? Moment(getinterview_roundvalue.createdAt).format("DD MMM YYYY, h:mm a") : ""}
                                                                                                                            </span>
                                                                                                                            <span className="replymessage">{getinterview_roundvalue.comment}</span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            ))}
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        ""
                                                                                                    )}
                                                                                                </div>
                                                                                                {user_role && user_role.applicationComment && user_role.applicationComment === "yes" && (
                                                                                                    <div className="comment-box mb-2">
                                                                                                        <form className="form w-100" onSubmit={(event) => SubmitHandler(event, "interview_round")}>
                                                                                                            <textarea
                                                                                                                key="interview_round_1"
                                                                                                                className="form-control form-control-lg mt-3 mb-3"
                                                                                                                value={application_form.interview_round && application_form.interview_round.value ? application_form.interview_round.value : ""}
                                                                                                                onChange={(event) => inputChangeHandler(event, "interview_round")}
                                                                                                            ></textarea>

                                                                                                            <button type="submit" id="kt_new_password_submit" className="btn btn-sm fw-bolder me-4">
                                                                                                                <span className="indicator-label">Post Comment</span>
                                                                                                            </button>
                                                                                                        </form>
                                                                                                    </div>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        {/* Start review Interview Round 1 Completed */}
                                                                        {user_role.Allapplication !== "yes" && (
                                                                            <>
                                                                                {(
                                                                                    (applicationdetaildata.assign_for_review && applicationdetaildata.assign_for_review.assign_member.member_id === user_info._id) ||
                                                                                    (applicationdetaildata.application_accepted && applicationdetaildata.application_accepted.user_one.user_one_id && applicationdetaildata.application_accepted.user_one.user_one_id === user_info._id) ||
                                                                                    (applicationdetaildata.application_accepted &&
                                                                                        applicationdetaildata.application_accepted.user_one.user_one_id &&
                                                                                        applicationdetaildata.application_accepted.user_two.user_two_id === user_info._id) ||
                                                                                    (applicationdetaildata.application_accepted &&
                                                                                        applicationdetaildata.application_accepted.user_one.user_one_id &&
                                                                                        applicationdetaildata.application_accepted.user_three.user_three_id === user_info._id) ||
                                                                                    (applicationdetaildata.application_interview && applicationdetaildata.application_interview.interview_one.interview_one_id === user_info._id) ||
                                                                                    (applicationdetaildata.application_interview && applicationdetaildata.application_interview.interview_two.interview_two_id === user_info._id) ||
                                                                                    (applicationdetaildata.application_interview && applicationdetaildata.application_interview.interview_three.interview_three_id === user_info._id)) && (applicationdetaildata.application_interview && applicationdetaildata.application_interview.interview_one.interview_one_id)

                                                                                    || (externalMemberId === user_info._id)
                                                                                    && (applicationdetaildata.application_interview.interview_one.interview_one_id)
                                                                                    ? (
                                                                                        <>
                                                                                            <div className="timeline-item">
                                                                                                <div className="timeline-media">
                                                                                                    <span className="fs-2 fw-bolder date-one">
                                                                                                        {applicationdetaildata.application_interview.interview_accepted_date
                                                                                                            ? Moment(applicationdetaildata.application_interview.interview_accepted_date).format("DD")
                                                                                                            : ""}{" "}
                                                                                                    </span>
                                                                                                    <br />
                                                                                                    <span className="opacity-50 fs-8 fw-bold">
                                                                                                        {applicationdetaildata.application_interview.interview_accepted_date
                                                                                                            ? Moment(applicationdetaildata.application_interview.interview_accepted_date).format("MMM YYYY")
                                                                                                            : ""}
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div className="timeline-content">
                                                                                                    <div className="d-flex align-items-center justify-content-between mb-3">
                                                                                                        <div className="mr-2">
                                                                                                            {((applicationdetaildata.application_interview &&
                                                                                                                applicationdetaildata.application_interview.interview_one.interview_one_id === user_info._id &&
                                                                                                                applicationdetaildata.application_interview.interview_one.interview_one_status) ||
                                                                                                                (applicationdetaildata.application_interview &&
                                                                                                                    applicationdetaildata.application_interview.interview_two.interview_two_id === user_info._id &&
                                                                                                                    applicationdetaildata.application_interview.interview_two.interview_two_status) ||
                                                                                                                (applicationdetaildata.application_interview &&
                                                                                                                    applicationdetaildata.application_interview.interview_three.interview_three_id === user_info._id &&
                                                                                                                    applicationdetaildata.application_interview.interview_three.interview_three_status) ||
                                                                                                                (applicationdetaildata.application_interview && applicationdetaildata.application_interview.user_setroundone === "1")) &&
                                                                                                                applicationdetaildata.application_interview &&
                                                                                                                applicationdetaildata.application_interview.user_setroundone === "1" ? (
                                                                                                                <h3 className="text-dark-75 font-weight-bold fs-4">Interview round 1 completed</h3>
                                                                                                            ) : (
                                                                                                                <>
                                                                                                                    {applicationdetaildata.application_interview &&
                                                                                                                        applicationdetaildata.application_interview.interview_three.interview_three_id ? (
                                                                                                                        <>
                                                                                                                            <h3 className="text-dark-75 font-weight-bold">Interview round 1 examine</h3>
                                                                                                                            <h6>({applicationdetaildata.recoredurl ? <Link to={{ pathname: applicationdetaildata.recoredurl }} target="_blank">{applicationdetaildata.recoredurl}</Link> : ""})</h6>
                                                                                                                        </>
                                                                                                                    ) : (
                                                                                                                        <>
                                                                                                                            {applicationdetaildata.application_interview.user_setroundone === "1" ? (
                                                                                                                                <h3 className="text-dark-75 font-weight-bold">Interview round 1 completed</h3>
                                                                                                                            ) : (
                                                                                                                                <>
                                                                                                                                    <h3 className="text-dark-75 font-weight-bold">
                                                                                                                                        Interview round 1 scheduled
                                                                                                                                    </h3>
                                                                                                                                    <h6>
                                                                                                                                        {applicationdetaildata.interviewdate && (
                                                                                                                                            <>
                                                                                                                                                ({applicationdetaildata.meetingdate ? applicationdetaildata.meetingdate : ""},
                                                                                                                                                <span className="ms-2">
                                                                                                                                                    {applicationdetaildata.meetingtime ? applicationdetaildata.meetingtime : ""},
                                                                                                                                                </span>
                                                                                                                                                <span className="ms-2">{applicationdetaildata.zoomlink ? applicationdetaildata.zoomlink : ""},</span>
                                                                                                                                                <span className="ms-2">{applicationdetaildata.recoredurl ? <Link to={{ pathname: applicationdetaildata.recoredurl }} target="_blank">{applicationdetaildata.recoredurl}</Link> : ""}</span>)
                                                                                                                                            </>
                                                                                                                                        )}
                                                                                                                                    </h6>
                                                                                                                                </>
                                                                                                                            )}
                                                                                                                        </>
                                                                                                                    )}
                                                                                                                </>
                                                                                                            )}
                                                                                                            <span className="text-muted ml-2">
                                                                                                                {applicationdetaildata.application_interview.interview_accepted_date
                                                                                                                    ? Moment(applicationdetaildata.application_interview.interview_accepted_date).format("h:mm a")
                                                                                                                    : ""}
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="align-items-center mb-5 mt-5">
                                                                                                        <h5>Assigned Interviewer</h5>
                                                                                                        <div className="d-flex text-gray-600  fs-6 interviewr-outer">
                                                                                                            <div className="d-flex align-items-center mb-5 mt-5 me-8">
                                                                                                                <div className="me-4 position-relative">
                                                                                                                    <div className="symbol symbol-30px symbol-circle">
                                                                                                                        <img
                                                                                                                            alt="Pic"
                                                                                                                            src={
                                                                                                                                applicationdetaildata.application_interview.interview_one.interview_one_image
                                                                                                                                    ? applicationdetaildata.application_interview.interview_one.interview_one_image
                                                                                                                                    : "/assets/media/avatars/blank.png"
                                                                                                                            }
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="fw-bold">
                                                                                                                    <div className="fs-7 text-gray-900">
                                                                                                                        {applicationdetaildata.application_interview.interview_one.interview_one_name
                                                                                                                            ? applicationdetaildata.application_interview.interview_one.interview_one_name
                                                                                                                            : ""}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                            <div className="d-flex align-items-center mb-5 mt-5 me-8">
                                                                                                                <div className="me-2 position-relative">
                                                                                                                    <div className="symbol symbol-30px symbol-circle">
                                                                                                                        <img
                                                                                                                            alt="Pic"
                                                                                                                            src={
                                                                                                                                applicationdetaildata.application_interview.interview_two.interview_two_image
                                                                                                                                    ? applicationdetaildata.application_interview.interview_two.interview_two_image
                                                                                                                                    : "/assets/media/avatars/blank.png"
                                                                                                                            }
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="fw-bold">
                                                                                                                    <div className="fs-7 text-gray-900">
                                                                                                                        {applicationdetaildata.application_interview.interview_two.interview_two_name
                                                                                                                            ? applicationdetaildata.application_interview.interview_two.interview_two_name
                                                                                                                            : ""}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            {applicationdetaildata.application_interview.interview_three.interview_three_name && (
                                                                                                                <div className="d-flex align-items-center mb-5 mt-5 me-8">
                                                                                                                    <div className="me-4 position-relative">
                                                                                                                        <div className="symbol symbol-30px symbol-circle">
                                                                                                                            <img
                                                                                                                                alt="Pic"
                                                                                                                                src={
                                                                                                                                    applicationdetaildata.application_interview.interview_three.interview_three_image
                                                                                                                                        ? applicationdetaildata.application_interview.interview_three.interview_three_image
                                                                                                                                        : "/assets/media/avatars/blank.png"
                                                                                                                                }
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="fw-bold">
                                                                                                                        <div className="fs-7 text-gray-900">
                                                                                                                            {applicationdetaildata.application_interview.interview_three.interview_three_name
                                                                                                                                ? applicationdetaildata.application_interview.interview_three.interview_three_name
                                                                                                                                : ""}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </div>
                                                                                                        {applicationdetaildata.application_interview.interview_one.interview_one_status && (
                                                                                                            <div className="d-flex align-items-center mb-5 mt-5 me-8">
                                                                                                                <div className="me-4 position-relative">
                                                                                                                    <div className="symbol symbol-30px symbol-circle">
                                                                                                                        <img
                                                                                                                            alt="Pic"
                                                                                                                            src={
                                                                                                                                applicationdetaildata.application_interview.interview_one.interview_one_image
                                                                                                                                    ? applicationdetaildata.application_interview.interview_one.interview_one_image
                                                                                                                                    : "/assets/media/avatars/blank.png"
                                                                                                                            }
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="fw-bold">
                                                                                                                    <div className="">
                                                                                                                        {applicationdetaildata.application_interview.interview_one.interview_one_name
                                                                                                                            ? applicationdetaildata.application_interview.interview_one.interview_one_name
                                                                                                                            : ""}{" "}
                                                                                                                        <span className="text_cap">({applicationdetaildata.application_interview.interview_one.interview_one_status}ed)</span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )}
                                                                                                        {applicationdetaildata.application_interview.interview_two.interview_two_status && (
                                                                                                            <div className="d-flex align-items-center mb-5 mt-5 me-8">
                                                                                                                <div className="me-4 position-relative">
                                                                                                                    <div className="symbol symbol-30px symbol-circle">
                                                                                                                        <img
                                                                                                                            alt="Pic"
                                                                                                                            src={
                                                                                                                                applicationdetaildata.application_interview.interview_two.interview_two_image
                                                                                                                                    ? applicationdetaildata.application_interview.interview_two.interview_two_image
                                                                                                                                    : "/assets/media/avatars/blank.png"
                                                                                                                            }
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="fw-bold">
                                                                                                                    <div className="">
                                                                                                                        {applicationdetaildata.application_interview.interview_two.interview_two_name
                                                                                                                            ? applicationdetaildata.application_interview.interview_two.interview_two_name
                                                                                                                            : ""}{" "}
                                                                                                                        <span className="text_cap">({applicationdetaildata.application_interview.interview_two.interview_two_status}ed)</span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )}
                                                                                                        {applicationdetaildata.application_interview.interview_three.interview_three_status && (
                                                                                                            <div className="d-flex align-items-center mb-5 mt-5 me-8">
                                                                                                                <div className="me-4 position-relative">
                                                                                                                    <div className="symbol symbol-30px symbol-circle">
                                                                                                                        <img
                                                                                                                            alt="Pic"
                                                                                                                            src={
                                                                                                                                applicationdetaildata.application_interview.interview_three.interview_three_image
                                                                                                                                    ? applicationdetaildata.application_interview.interview_three.interview_three_image
                                                                                                                                    : "/assets/media/avatars/blank.png"
                                                                                                                            }
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="fw-bold">
                                                                                                                    <div className="">
                                                                                                                        {applicationdetaildata.application_interview.interview_three.interview_three_name
                                                                                                                            ? applicationdetaildata.application_interview.interview_three.interview_three_name
                                                                                                                            : ""}{" "}
                                                                                                                        <span className="text_cap">({applicationdetaildata.application_interview.interview_three.interview_three_status}ed)</span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </div>
                                                                                                    {applicationdetaildata.setroundone === "1" && (!applicationdetaildata.setinterviewTwoRound || applicationdetaildata.setinterviewTwoRound === "0") && <span onClick={() => showInterviewTwoModalHandle(applicationdetaildata)} className="btn btn-sm btn-primary btn-active-primary">
                                                                                                        Schedule Interview Round Two
                                                                                                    </span>}
                                                                                                    {getinterview_round.map((getinterview_roundvalue, index) => (
                                                                                                        <>
                                                                                                            {index < 3 && (
                                                                                                                <div className="replicationreply" key={getinterview_roundvalue._id}>
                                                                                                                    <div className="cursor-pointer symbol symbol-30px  reply_user_pic">
                                                                                                                        <img
                                                                                                                            src={getinterview_roundvalue.UserImage ? getinterview_roundvalue.UserImage : "/assets/media/avatars/blank.png"}
                                                                                                                            alt="user"
                                                                                                                        />
                                                                                                                        <div className="profile-user ms-2">
                                                                                                                            <span className="replyusername">{getinterview_roundvalue.UserName}</span>
                                                                                                                            <span className="date_time">
                                                                                                                                {getinterview_roundvalue.createdAt ? Moment(getinterview_roundvalue.createdAt).format("DD MMM YYYY, h:mm a") : ""}
                                                                                                                            </span>
                                                                                                                            <span className="replymessage">{getinterview_roundvalue.comment}</span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </>
                                                                                                    ))}

                                                                                                    {(applicationdetaildata.application_interview &&
                                                                                                        applicationdetaildata.application_interview.interview_one.interview_one_id === user_info._id &&
                                                                                                        applicationdetaildata.application_interview.interview_one.interview_one_status) ||
                                                                                                        (applicationdetaildata.application_interview &&
                                                                                                            applicationdetaildata.application_interview.interview_two.interview_two_id === user_info._id &&
                                                                                                            applicationdetaildata.application_interview.interview_two.interview_two_status) ||
                                                                                                        (applicationdetaildata.application_interview &&
                                                                                                            applicationdetaildata.application_interview.interview_three.interview_three_id === user_info._id &&
                                                                                                            applicationdetaildata.application_interview.interview_three.interview_three_status) ||
                                                                                                        (applicationdetaildata.application_interview && applicationdetaildata.application_interview.user_setroundone === "1")
                                                                                                        ? (
                                                                                                            ""
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                {(applicationdetaildata.application_interview.interview_one.interview_one_id === user_info._id ||
                                                                                                                    applicationdetaildata.application_interview.interview_two.interview_two_id === user_info._id ||
                                                                                                                    applicationdetaildata.application_interview.interview_three.interview_three_id === user_info._id
                                                                                                                ) && applicationdetaildata.statusint &&
                                                                                                                    applicationdetaildata.statusint !== 5 &&
                                                                                                                    applicationdetaildata.statusint &&
                                                                                                                    applicationdetaildata.statusint !== 4 ? (
                                                                                                                    <div className="align-items-center mb-5 mt-5">
                                                                                                                        <>
                                                                                                                            {user_role && user_role.applicationAccept && user_role.applicationAccept === "yes" && (
                                                                                                                                <span
                                                                                                                                    onClick={() => showAcceptByInterviewModalHandle(applicationdetaildata)}
                                                                                                                                    className="btn btn-sm btn-primary btn-active-primary"
                                                                                                                                >
                                                                                                                                    Accept
                                                                                                                                </span>
                                                                                                                            )}
                                                                                                                            {user_role && user_role.applicationReject && user_role.applicationReject === "yes" && (
                                                                                                                                <span
                                                                                                                                    onClick={() => showRejectByInterviewModalHandle(applicationdetaildata)}
                                                                                                                                    className="ms-2 btn btn-sm btn-light-primary fw-bolder"
                                                                                                                                >
                                                                                                                                    Reject
                                                                                                                                </span>
                                                                                                                            )}
                                                                                                                            {user_role && user_role.applicationAssignOtherMember && user_role.applicationAssignOtherMember === "yes" && (
                                                                                                                                <span
                                                                                                                                    onClick={() =>
                                                                                                                                        showAutoAssignOtherMemberpoup(applicationdetaildata, getapplication_interview_assign, "interview_round_1")
                                                                                                                                    }
                                                                                                                                    className="btn btn-sm btn-primary btn-active-primary"
                                                                                                                                >
                                                                                                                                    {" "}
                                                                                                                                    Assign Other member {" "}
                                                                                                                                </span>
                                                                                                                            )}
                                                                                                                        </>
                                                                                                                        {!applicationdetaildata.application_interview.interview_one.interview_one_status && !applicationdetaildata.application_interview.interview_two.interview_two_status && <span
                                                                                                                            onClick={() => Donotsetup(applicationdetaildata)}
                                                                                                                            className="btn btn-sm btn-primary btn-active-primary"
                                                                                                                        >Didn't Show Up
                                                                                                                        </span>}
                                                                                                                        {user_role && user_role.applicationExternalMember && (
                                                                                                                            <span
                                                                                                                                onClick={() => showExternalMemberModalHandle(applicationdetaildata, "interview_round_1")}
                                                                                                                                className="fw-bolder external_member_add"
                                                                                                                            >
                                                                                                                                {" "}
                                                                                                                                + External Member
                                                                                                                            </span>
                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                ) : (
                                                                                                                    ""
                                                                                                                )}
                                                                                                            </>
                                                                                                        )}

                                                                                                    <div className="comment-post-main">
                                                                                                        <span className="showreplymessage" onClick={() => showapplicationreply(replyapplicationformreply, "show_review_interview_round")}>
                                                                                                            <i className="bi bi-chat-square"></i>
                                                                                                            <span>{getreview_interview_round.length}</span>
                                                                                                        </span>
                                                                                                        {applicationformreply === "show" && apptype === "show_review_interview_round" ? (
                                                                                                            <>
                                                                                                                {getreview_interview_round.map((getreview_interview_roundvalue) => (
                                                                                                                    <div className="replicationreply" key={getreview_interview_roundvalue._id}>
                                                                                                                        <div className="cursor-pointer symbol symbol-30px reply_user_pic">
                                                                                                                            <img
                                                                                                                                src={
                                                                                                                                    getreview_interview_roundvalue.UserImage
                                                                                                                                        ? getreview_interview_roundvalue.UserImage
                                                                                                                                        : "/assets/media/avatars/blank.png"
                                                                                                                                }
                                                                                                                                alt="user"
                                                                                                                            />
                                                                                                                            <div className="profile-user ms-2">
                                                                                                                                <span className="replyusername">{getreview_interview_roundvalue.UserName}</span>
                                                                                                                                <span className="date_time">
                                                                                                                                    {getreview_interview_roundvalue.createdAt
                                                                                                                                        ? Moment(getreview_interview_roundvalue.createdAt).format("DD MMM YYYY, h:mm a")
                                                                                                                                        : ""}
                                                                                                                                </span>
                                                                                                                                <span className="replymessage">{getreview_interview_roundvalue.comment}</span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                ))}
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            ""
                                                                                                        )}
                                                                                                    </div>
                                                                                                    {user_role && user_role.applicationComment && user_role.applicationComment === "yes" && (
                                                                                                        <div className="comment-box mb-2">
                                                                                                            <form className="form w-100" onSubmit={(event) => SubmitHandler(event, "review_interview_round")}>
                                                                                                                <textarea
                                                                                                                    key="review_interview_round_1"
                                                                                                                    className="form-control form-control-lg mt-3 mb-3"
                                                                                                                    value={
                                                                                                                        application_form.review_interview_round && application_form.review_interview_round.value
                                                                                                                            ? application_form.review_interview_round.value
                                                                                                                            : ""
                                                                                                                    }
                                                                                                                    onChange={(event) => inputChangeHandler(event, "review_interview_round")}
                                                                                                                ></textarea>

                                                                                                                <button type="submit" id="kt_new_password_submit" className="btn btn-sm fw-bolder me-4">
                                                                                                                    <span className="indicator-label">Post Comment</span>
                                                                                                                </button>
                                                                                                            </form>
                                                                                                        </div>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                            </>
                                                                        )}
                                                                        {/* End review Interview Round 1 Completed */}
                                                                        {/* end Interview Round 1 Completed */}



                                                                        {((user_role.Allapplication && user_role.Allapplication === "yes") ||
                                                                            (applicationdetaildata.assign_for_review && applicationdetaildata.assign_for_review.assign_member.member_id === user_info._id) ||
                                                                            (applicationdetaildata.application_accepted &&
                                                                                applicationdetaildata.application_accepted.user_one.user_one_id &&
                                                                                applicationdetaildata.application_accepted.user_one.user_one_id === user_info._id &&
                                                                                applicationdetaildata.application_interviewTwoRound.interviewTwoRound_one &&
                                                                                applicationdetaildata.application_interviewTwoRound.interviewTwoRound_one.interviewTwoRound_one_id !== user_info._id &&
                                                                                applicationdetaildata.application_interviewTwoRound.interviewTwoRound_two &&
                                                                                applicationdetaildata.application_interviewTwoRound.interviewTwoRound_two.interviewTwoRound_two_id !== user_info._id) ||
                                                                            (applicationdetaildata.application_accepted &&
                                                                                applicationdetaildata.application_accepted.user_one.user_one_id &&
                                                                                applicationdetaildata.application_accepted.user_two.user_two_id === user_info._id &&
                                                                                applicationdetaildata.application_interviewTwoRound.interviewTwoRound_one &&
                                                                                applicationdetaildata.application_interviewTwoRound.interviewTwoRound_one.interviewTwoRound_one_id !== user_info._id &&
                                                                                applicationdetaildata.application_interviewTwoRound.interviewTwoRound_two &&
                                                                                applicationdetaildata.application_interviewTwoRound.interviewTwoRound_two.interviewTwoRound_two_id !== user_info._id) ||
                                                                            (applicationdetaildata.application_accepted &&
                                                                                applicationdetaildata.application_accepted.user_one.user_one_id &&
                                                                                applicationdetaildata.application_accepted.user_three.user_three_id === user_info._id &&
                                                                                applicationdetaildata.application_interviewTwoRound.interviewTwoRound_one &&
                                                                                applicationdetaildata.application_interviewTwoRound.interviewTwoRound_one.interviewTwoRound_one_id !== user_info._id &&
                                                                                applicationdetaildata.application_interviewTwoRound.interviewTwoRound_two &&
                                                                                applicationdetaildata.application_interviewTwoRound.interviewTwoRound_two.interviewTwoRound_two_id !== user_info._id)) &&
                                                                            applicationdetaildata.setinterviewTwoRound &&
                                                                            applicationdetaildata.setinterviewTwoRound === "1" && (
                                                                                <>
                                                                                    {(user_role.Allapplication && user_role.Allapplication === "yes") && (
                                                                                        <div className="timeline-item">
                                                                                            <div className="timeline-media">
                                                                                                <span className="fs-2 fw-bolder date-one">
                                                                                                    {applicationdetaildata.application_interviewTwoRound.interviewTwoRound_accepted_date
                                                                                                        ? Moment(applicationdetaildata.application_interviewTwoRound.interviewTwoRound_accepted_date).format("DD")
                                                                                                        : ""}{" "}
                                                                                                </span>
                                                                                                <br />
                                                                                                <span className="opacity-50 fs-8 fw-bold">
                                                                                                    {applicationdetaildata.application_interviewTwoRound.interviewTwoRound_accepted_date
                                                                                                        ? Moment(applicationdetaildata.application_interviewTwoRound.interviewTwoRound_accepted_date).format("MMM YYYY")
                                                                                                        : ""}
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className="timeline-content">
                                                                                                <div className="d-flex align-items-center justify-content-between mb-3">
                                                                                                    <div className="mr-2">
                                                                                                        {applicationdetaildata.application_interviewTwoRound.user_setroundtwo &&
                                                                                                            applicationdetaildata.application_interviewTwoRound.user_setroundtwo !== "1" &&
                                                                                                            applicationdetaildata.statusint !== 4 ? (
                                                                                                            <>
                                                                                                                <h3 className="text-dark-75 font-weight-bold">
                                                                                                                    Setup Interview Round 2
                                                                                                                </h3>
                                                                                                                <h6>
                                                                                                                    {applicationdetaildata.interviewTwoRounddate && (
                                                                                                                        <>
                                                                                                                            ({applicationdetaildata.meetingdate ? applicationdetaildata.meetingdate : ""},
                                                                                                                            <span className="ms-2">
                                                                                                                                {applicationdetaildata.meetingtime ? applicationdetaildata.meetingtime : ""},
                                                                                                                            </span>
                                                                                                                            <span className="ms-2">{applicationdetaildata.zoomlink ? applicationdetaildata.zoomlink : ""},</span>
                                                                                                                            <span className="ms-2">{applicationdetaildata.recoredurl ? <Link to={{ pathname: applicationdetaildata.recoredurl }} target="_blank">{applicationdetaildata.recoredurl}</Link> : ""}</span>)
                                                                                                                        </>
                                                                                                                    )}
                                                                                                                </h6>
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                <h3 className="text-dark-75 font-weight-bold">Interview Round 2 completed
                                                                                                                    {applicationdetaildata.offferDone === "1" && <>
                                                                                                                        {applicationdetaildata.offferStatus === "accept" ? (<span> and offer letter accepted</span>) : (<span> and offer letter rejected</span>)}
                                                                                                                    </>}</h3>
                                                                                                            </>
                                                                                                        )}
                                                                                                        <span className="text-muted ml-2">
                                                                                                            {applicationdetaildata.application_interviewTwoRound.interviewTwoRound_accepted_date
                                                                                                                ? Moment(applicationdetaildata.application_interviewTwoRound.interviewTwoRound_accepted_date).format("h:mm a")
                                                                                                                : ""}
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                {getinterview_round_2.map((get_interview_round_2_value, index) => (
                                                                                                    <>
                                                                                                        {index > getinterview_round_2.length - 4 && (
                                                                                                            <div className="replicationreply" key={get_interview_round_2_value._id}>
                                                                                                                <div className="cursor-pointer symbol symbol-30px symbol-md-50px reply_user_pic">
                                                                                                                    <img
                                                                                                                        src={get_interview_round_2_value.UserImage ? get_interview_round_2_value.UserImage : "/assets/media/avatars/blank.png"}
                                                                                                                        alt="user"
                                                                                                                    />
                                                                                                                    <div className="profile-user ms-2">
                                                                                                                        <span className="replyusername">{get_interview_round_2_value.UserName}</span>
                                                                                                                        <span className="date_time">
                                                                                                                            {get_interview_round_2_value.createdAt
                                                                                                                                ? Moment(get_interview_round_2_value.createdAt).format("DD MMM YYYY, h:mm a")
                                                                                                                                : ""}
                                                                                                                        </span>
                                                                                                                        <span className="replymessage">{get_interview_round_2_value.comment}</span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </>
                                                                                                ))}
                                                                                                <div className="align-items-center mb-5 mt-5">
                                                                                                    <h5>Assigned Interviewer </h5>
                                                                                                    <div className="d-flex text-gray-600  fs-6 interviewr-outer">
                                                                                                        <div className="d-flex align-items-center mb-5 mt-5 me-8">
                                                                                                            <div className="me-4 position-relative">
                                                                                                                <div className="symbol symbol-30px symbol-circle">
                                                                                                                    <img
                                                                                                                        alt="Pic"
                                                                                                                        src={
                                                                                                                            applicationdetaildata.application_interviewTwoRound.interviewTwoRound_one.interviewTwoRound_one_image
                                                                                                                                ? applicationdetaildata.application_interviewTwoRound.interviewTwoRound_one.interviewTwoRound_one_image
                                                                                                                                : "/assets/media/avatars/blank.png"
                                                                                                                        }
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="fw-bold">
                                                                                                                <div className="fs-7 text-gray-900">
                                                                                                                    {applicationdetaildata.application_interviewTwoRound.interviewTwoRound_one.interviewTwoRound_one_name
                                                                                                                        ? applicationdetaildata.application_interviewTwoRound.interviewTwoRound_one.interviewTwoRound_one_name
                                                                                                                        : ""}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div className="d-flex align-items-center mb-5 mt-5 me-8">
                                                                                                            <div className="me-2 position-relative">
                                                                                                                <div className="symbol symbol-30px symbol-circle">
                                                                                                                    <img
                                                                                                                        alt="Pic"
                                                                                                                        src={
                                                                                                                            applicationdetaildata.application_interviewTwoRound.interviewTwoRound_two.interviewTwoRound_two_image
                                                                                                                                ? applicationdetaildata.application_interviewTwoRound.interviewTwoRound_two.interviewTwoRound_two_image
                                                                                                                                : "/assets/media/avatars/blank.png"
                                                                                                                        }
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="fw-bold">
                                                                                                                <div className="fs-7 text-gray-900">
                                                                                                                    {applicationdetaildata.application_interviewTwoRound.interviewTwoRound_two.interviewTwoRound_two_name
                                                                                                                        ? applicationdetaildata.application_interviewTwoRound.interviewTwoRound_two.interviewTwoRound_two_name
                                                                                                                        : ""}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {applicationdetaildata.application_interviewTwoRound.interviewTwoRound_one.interviewTwoRound_one_status && (
                                                                                                        <div className="d-flex align-items-center mb-5 mt-5 me-8">
                                                                                                            <div className="me-4 position-relative">
                                                                                                                <div className="symbol symbol-30px symbol-circle">
                                                                                                                    <img
                                                                                                                        alt="Pic"
                                                                                                                        src={
                                                                                                                            applicationdetaildata.application_interviewTwoRound.interviewTwoRound_one.interviewTwoRound_one_image
                                                                                                                                ? applicationdetaildata.application_interviewTwoRound.interviewTwoRound_one.interviewTwoRound_one_image
                                                                                                                                : "/assets/media/avatars/blank.png"
                                                                                                                        }
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="fw-bold">
                                                                                                                <div className="">
                                                                                                                    {applicationdetaildata.application_interviewTwoRound.interviewTwoRound_one.interviewTwoRound_one_name
                                                                                                                        ? applicationdetaildata.application_interviewTwoRound.interviewTwoRound_one.interviewTwoRound_one_name
                                                                                                                        : ""}{" "}
                                                                                                                    <span className="text_cap">({applicationdetaildata.application_interviewTwoRound.interviewTwoRound_one.interviewTwoRound_one_status}ed)</span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )}
                                                                                                    {applicationdetaildata.application_interviewTwoRound.interviewTwoRound_two.interviewTwoRound_two_status && (
                                                                                                        <div className="d-flex align-items-center mb-5 mt-5 me-8">
                                                                                                            <div className="me-4 position-relative">
                                                                                                                <div className="symbol symbol-30px symbol-circle">
                                                                                                                    <img
                                                                                                                        alt="Pic"
                                                                                                                        src={
                                                                                                                            applicationdetaildata.application_interviewTwoRound.interviewTwoRound_two.interviewTwoRound_two_image
                                                                                                                                ? applicationdetaildata.application_interviewTwoRound.interviewTwoRound_two.interviewTwoRound_two_image
                                                                                                                                : "/assets/media/avatars/blank.png"
                                                                                                                        }
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="fw-bold">
                                                                                                                <div className="">
                                                                                                                    {applicationdetaildata.application_interviewTwoRound.interviewTwoRound_two.interviewTwoRound_two_name
                                                                                                                        ? applicationdetaildata.application_interviewTwoRound.interviewTwoRound_two.interviewTwoRound_two_name
                                                                                                                        : ""}{" "}
                                                                                                                    <span className="text_cap">({applicationdetaildata.application_interviewTwoRound.interviewTwoRound_two.interviewTwoRound_two_status}ed)</span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )}
                                                                                                    {user_role && user_role.Allapplication && user_role.Allapplication === "yes" && user_info && user_info.usertype && user_info.usertype === "admin" && (
                                                                                                        <>
                                                                                                            {applicationdetaildata.application_interviewTwoRound.user_setroundtwo &&
                                                                                                                applicationdetaildata.application_interviewTwoRound.user_setroundtwo !== "1" &&
                                                                                                                applicationdetaildata.statusint !== 4 ? (
                                                                                                                <>
                                                                                                                    {user_role && user_role.applicationAccept && user_role.applicationAccept === "yes" && (
                                                                                                                        <span
                                                                                                                            onClick={() => showAutoAcceptModalHandleTwoRound(applicationdetaildata, "interview_round_2")}
                                                                                                                            className="btn btn-sm btn-primary btn-active-primary"
                                                                                                                        >
                                                                                                                            Accept
                                                                                                                        </span>
                                                                                                                    )}
                                                                                                                    {user_role && user_role.applicationReject && user_role.applicationReject === "yes" && (
                                                                                                                        <span onClick={() => showRejectModalHandle(applicationdetaildata)} className="ms-2 btn btn-sm btn-light-primary fw-bolder">
                                                                                                                            Reject
                                                                                                                        </span>
                                                                                                                    )}
                                                                                                                    {user_role && user_role.applicationAssignOtherMember && user_role.applicationAssignOtherMember === "yes" && (
                                                                                                                        <span
                                                                                                                            onClick={() => showAutoAssignOtherMemberpoup(applicationdetaildata, getapplication_interviewTwoRound_assign, "interview_round_2")}
                                                                                                                            className="btn btn-sm btn-primary btn-active-primary"
                                                                                                                        >
                                                                                                                            {" "}
                                                                                                                            Assign Other member {" "}
                                                                                                                        </span>
                                                                                                                    )}
                                                                                                                </>
                                                                                                            ) : (
                                                                                                                ""
                                                                                                            )}
                                                                                                        </>
                                                                                                    )}
                                                                                                    {user_role && user_role.Allapplication && user_role.Allapplication === "yes" && user_info && user_info.usertype && user_info.usertype !== "admin"
                                                                                                        && (
                                                                                                            <>
                                                                                                                {((applicationdetaildata.application_interviewTwoRound &&
                                                                                                                    applicationdetaildata.application_interviewTwoRound.interviewTwoRound_one.interviewTwoRound_one_id === user_info._id &&
                                                                                                                    applicationdetaildata.application_interviewTwoRound.interviewTwoRound_one.interviewTwoRound_one_status) ||
                                                                                                                    (applicationdetaildata.application_interviewTwoRound &&
                                                                                                                        applicationdetaildata.application_interviewTwoRound.interviewTwoRound_two.interviewTwoRound_two_id === user_info._id &&
                                                                                                                        applicationdetaildata.application_interviewTwoRound.interviewTwoRound_two.interviewTwoRound_two_status))
                                                                                                                    ? (
                                                                                                                        <>
                                                                                                                            {user_role && user_role.applicationAssignOtherMember && user_role.applicationAssignOtherMember === "yes" && (
                                                                                                                                <span
                                                                                                                                    onClick={() =>
                                                                                                                                        showAutoAssignOtherMemberpoup(
                                                                                                                                            applicationdetaildata,
                                                                                                                                            getapplication_interviewTwoRound_assign,
                                                                                                                                            "interview_round_2"
                                                                                                                                        )
                                                                                                                                    }
                                                                                                                                    className="btn btn-sm btn-primary btn-active-primary"
                                                                                                                                >
                                                                                                                                    {" "}
                                                                                                                                    Assign Other member{" "}
                                                                                                                                </span>
                                                                                                                            )}
                                                                                                                        </>
                                                                                                                    ) : ('')}
                                                                                                            </>
                                                                                                        )}
                                                                                                    <>
                                                                                                        {user_role &&
                                                                                                            user_role.Allapplication &&
                                                                                                            user_role.Allapplication === "yes" &&
                                                                                                            user_info &&
                                                                                                            user_info.usertype &&
                                                                                                            user_info.usertype !== "admin" && (
                                                                                                                <>
                                                                                                                    {applicationdetaildata.application_interviewTwoRound.user_setroundtwo &&
                                                                                                                        applicationdetaildata.application_interviewTwoRound.user_setroundtwo !== "1" &&
                                                                                                                        applicationdetaildata.statusint !== 4 ? (
                                                                                                                        <>
                                                                                                                            {(applicationdetaildata.application_interviewTwoRound &&
                                                                                                                                applicationdetaildata.application_interviewTwoRound.interviewTwoRound_one.interviewTwoRound_one_id === user_info._id &&
                                                                                                                                applicationdetaildata.application_interviewTwoRound.interviewTwoRound_one.interviewTwoRound_one_status) ||
                                                                                                                                (applicationdetaildata.application_interviewTwoRound &&
                                                                                                                                    applicationdetaildata.application_interviewTwoRound.interviewTwoRound_two.interviewTwoRound_two_id === user_info._id &&
                                                                                                                                    applicationdetaildata.application_interviewTwoRound.interviewTwoRound_two.interviewTwoRound_two_status) ||
                                                                                                                                (applicationdetaildata.application_interviewTwoRound && applicationdetaildata.application_interviewTwoRound.user_setroundtwo === "1") ? (
                                                                                                                                ""
                                                                                                                            ) : (
                                                                                                                                <>
                                                                                                                                    {applicationdetaildata.statusint &&
                                                                                                                                        applicationdetaildata.statusint !== 5 &&
                                                                                                                                        applicationdetaildata.statusint &&
                                                                                                                                        applicationdetaildata.statusint !== 4 ? (
                                                                                                                                        <div className="align-items-center mb-5 mt-5">
                                                                                                                                            <>
                                                                                                                                                {user_role && user_role.applicationAccept && user_role.applicationAccept === "yes" && (
                                                                                                                                                    <span
                                                                                                                                                        onClick={() => showAcceptByInterviewTwoRoundModalHandle(applicationdetaildata)}
                                                                                                                                                        className="btn btn-sm btn-primary btn-active-primary"
                                                                                                                                                    >
                                                                                                                                                        Accept
                                                                                                                                                    </span>
                                                                                                                                                )}
                                                                                                                                                {user_role && user_role.applicationReject && user_role.applicationReject === "yes" && (
                                                                                                                                                    <span
                                                                                                                                                        onClick={() => showRejectByInterviewTwoRoundModalHandle(applicationdetaildata)}
                                                                                                                                                        className="ms-2 btn btn-sm btn-light-primary fw-bolder"
                                                                                                                                                    >
                                                                                                                                                        Reject
                                                                                                                                                    </span>
                                                                                                                                                )}
                                                                                                                                                {user_role && user_role.applicationAssignOtherMember && user_role.applicationAssignOtherMember === "yes" && (
                                                                                                                                                    <span
                                                                                                                                                        onClick={() =>
                                                                                                                                                            showAutoAssignOtherMemberpoup(
                                                                                                                                                                applicationdetaildata,
                                                                                                                                                                getapplication_interviewTwoRound_assign,
                                                                                                                                                                "interview_round_2"
                                                                                                                                                            )
                                                                                                                                                        }
                                                                                                                                                        className="btn btn-sm btn-primary btn-active-primary"
                                                                                                                                                    >
                                                                                                                                                        {" "}
                                                                                                                                                        Assign Other member{" "}
                                                                                                                                                    </span>
                                                                                                                                                )}
                                                                                                                                            </>
                                                                                                                                        </div>
                                                                                                                                    ) : (
                                                                                                                                        ""
                                                                                                                                    )}
                                                                                                                                </>
                                                                                                                            )}
                                                                                                                        </>
                                                                                                                    ) : (
                                                                                                                        ""
                                                                                                                    )}
                                                                                                                </>
                                                                                                            )}
                                                                                                    </>
                                                                                                </div>
                                                                                                {applicationdetaildata.setroundtwo === "1" && applicationdetaildata.offferDone !== "1" && <>
                                                                                                    <span onClick={() => showOfferLetterModalHandle(applicationdetaildata, 'accept')} className="btn btn-sm btn-primary btn-active-primary">
                                                                                                        Accept offer letter
                                                                                                    </span>
                                                                                                    <span onClick={() => showOfferLetterModalHandle(applicationdetaildata, 'reject')} className="btn btn-sm btn-primary btn-active-primary">
                                                                                                        Reject offer letter
                                                                                                    </span>
                                                                                                </>}
                                                                                                <div className="comment-post-main">
                                                                                                    <span className="showreplymessage" onClick={() => showapplicationreply(replyapplicationformreply, "show_interviewTwoRound_round")}>
                                                                                                        <i className="bi bi-chat-square"></i>
                                                                                                        <span>{getinterviewTwoRound_round.length}</span>
                                                                                                    </span>
                                                                                                    {applicationformreply === "show" && apptype === "show_interviewTwoRound_round" ? (
                                                                                                        <>
                                                                                                            {getinterviewTwoRound_round.map((getinterviewTwoRound_roundvalue) => (
                                                                                                                <div className="replicationreply" key={getinterviewTwoRound_roundvalue._id}>
                                                                                                                    <div className="cursor-pointer symbol symbol-30px reply_user_pic">
                                                                                                                        <img
                                                                                                                            src={getinterviewTwoRound_roundvalue.UserImage ? getinterviewTwoRound_roundvalue.UserImage : "/assets/media/avatars/blank.png"}
                                                                                                                            alt="user"
                                                                                                                        />
                                                                                                                        <div className="profile-user ms-2">
                                                                                                                            <span className="replyusername">{getinterviewTwoRound_roundvalue.UserName}</span>
                                                                                                                            <span className="date_time">
                                                                                                                                {getinterviewTwoRound_roundvalue.createdAt ? Moment(getinterviewTwoRound_roundvalue.createdAt).format("DD MMM YYYY, h:mm a") : ""}
                                                                                                                            </span>
                                                                                                                            <span className="replymessage">{getinterviewTwoRound_roundvalue.comment}</span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            ))}
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        ""
                                                                                                    )}
                                                                                                </div>
                                                                                                {user_role && user_role.applicationComment && user_role.applicationComment === "yes" && (
                                                                                                    <div className="comment-box mb-2">
                                                                                                        <form className="form w-100" onSubmit={(event) => SubmitHandler(event, "interviewTwoRound_round")}>
                                                                                                            <textarea
                                                                                                                key="interview_round_2"
                                                                                                                className="form-control form-control-lg mt-3 mb-3"
                                                                                                                value={application_form.interviewTwoRound_round && application_form.interviewTwoRound_round.value ? application_form.interviewTwoRound_round.value : ""}
                                                                                                                onChange={(event) => inputChangeHandler(event, "interviewTwoRound_round")}
                                                                                                            ></textarea>

                                                                                                            <button type="submit" id="kt_new_password_submit" className="btn btn-sm fw-bolder me-4">
                                                                                                                <span className="indicator-label">Post Comment</span>
                                                                                                            </button>
                                                                                                        </form>
                                                                                                    </div>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        {/* Start review Interview Round 1 Completed */}
                                                                        {user_role.Allapplication !== "yes" && (
                                                                            <>
                                                                                {(
                                                                                    (applicationdetaildata.assign_for_review && applicationdetaildata.assign_for_review.assign_member.member_id === user_info._id) ||
                                                                                    (applicationdetaildata.application_accepted && applicationdetaildata.application_accepted.user_one.user_one_id && applicationdetaildata.application_accepted.user_one.user_one_id === user_info._id) ||
                                                                                    (applicationdetaildata.application_accepted &&
                                                                                        applicationdetaildata.application_accepted.user_one.user_one_id &&
                                                                                        applicationdetaildata.application_accepted.user_two.user_two_id === user_info._id) ||
                                                                                    (applicationdetaildata.application_accepted &&
                                                                                        applicationdetaildata.application_accepted.user_one.user_one_id &&
                                                                                        applicationdetaildata.application_accepted.user_three.user_three_id === user_info._id) ||
                                                                                    (applicationdetaildata.application_interviewTwoRound && applicationdetaildata.application_interviewTwoRound.interviewTwoRound_one.interviewTwoRound_one_id === user_info._id) ||
                                                                                    (applicationdetaildata.application_interviewTwoRound && applicationdetaildata.application_interviewTwoRound.interviewTwoRound_two.interviewTwoRound_two_id === user_info._id)) && (applicationdetaildata.application_interviewTwoRound && applicationdetaildata.application_interviewTwoRound.interviewTwoRound_one.interviewTwoRound_one_id)

                                                                                    || (externalMemberId === user_info._id)
                                                                                    && (applicationdetaildata.application_interviewTwoRound.interviewTwoRound_one.interviewTwoRound_one_id)
                                                                                    ? (
                                                                                        <>
                                                                                            <div className="timeline-item">
                                                                                                <div className="timeline-media">
                                                                                                    <span className="fs-2 fw-bolder date-one">
                                                                                                        {applicationdetaildata.application_interviewTwoRound.interviewTwoRound_accepted_date
                                                                                                            ? Moment(applicationdetaildata.application_interviewTwoRound.interviewTwoRound_accepted_date).format("DD")
                                                                                                            : ""}{" "}
                                                                                                    </span>
                                                                                                    <br />
                                                                                                    <span className="opacity-50 fs-8 fw-bold">
                                                                                                        {applicationdetaildata.application_interviewTwoRound.interviewTwoRound_accepted_date
                                                                                                            ? Moment(applicationdetaildata.application_interviewTwoRound.interviewTwoRound_accepted_date).format("MMM YYYY")
                                                                                                            : ""}
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div className="timeline-content">
                                                                                                    <div className="d-flex align-items-center justify-content-between mb-3">
                                                                                                        <div className="mr-2">
                                                                                                            {((applicationdetaildata.application_interviewTwoRound &&
                                                                                                                applicationdetaildata.application_interviewTwoRound.interviewTwoRound_one.interviewTwoRound_one_id === user_info._id &&
                                                                                                                applicationdetaildata.application_interviewTwoRound.interviewTwoRound_one.interviewTwoRound_one_status) ||
                                                                                                                (applicationdetaildata.application_interview &&
                                                                                                                    applicationdetaildata.application_interviewTwoRound.interviewTwoRound_two.interviewTwoRound_two_id === user_info._id &&
                                                                                                                    applicationdetaildata.application_interviewTwoRound.interviewTwoRound_two.interviewTwoRound_two_status) ||
                                                                                                                (applicationdetaildata.application_interviewTwoRound && applicationdetaildata.application_interviewTwoRound.user_setroundtwo === "1")) &&
                                                                                                                applicationdetaildata.application_interviewTwoRound &&
                                                                                                                applicationdetaildata.application_interviewTwoRound.user_setroundtwo === "1" ? (
                                                                                                                <h3 className="text-dark-75 font-weight-bold">Interview Round 2 completed
                                                                                                                    {applicationdetaildata.offferDone === "1" && <>
                                                                                                                        {applicationdetaildata.offferStatus === "accept" ? (<span> and offer letter accepted</span>) : (<span> and offer letter rejected</span>)}
                                                                                                                    </>}</h3>
                                                                                                            ) : (
                                                                                                                <>
                                                                                                                    {applicationdetaildata.application_interviewTwoRound.user_setroundtwo === "1" ? (
                                                                                                                        <h3 className="text-dark-75 font-weight-bold">Interview Round 2 completed
                                                                                                                            {applicationdetaildata.offferDone === "1" && <>
                                                                                                                                {applicationdetaildata.offferStatus === "accept" ? (<span> and offer letter accepted</span>) : (<span> and offer letter rejected</span>)}
                                                                                                                            </>}</h3>
                                                                                                                    ) : (
                                                                                                                        <>
                                                                                                                            <h3 className="text-dark-75 font-weight-bold">
                                                                                                                                Interview round 2 scheduled
                                                                                                                            </h3>
                                                                                                                            <h6>
                                                                                                                                {applicationdetaildata.interviewTwoRounddate && (
                                                                                                                                    <>
                                                                                                                                        ({applicationdetaildata.meetingdate ? applicationdetaildata.meetingdate : ""},
                                                                                                                                        <span className="ms-2">
                                                                                                                                            {applicationdetaildata.meetingtime ? applicationdetaildata.meetingtime : ""},
                                                                                                                                        </span>
                                                                                                                                        <span className="ms-2">{applicationdetaildata.zoomlink ? applicationdetaildata.zoomlink : ""},</span>
                                                                                                                                        <span className="ms-2">{applicationdetaildata.recoredurl ? <Link to={{ pathname: applicationdetaildata.recoredurl }} target="_blank">{applicationdetaildata.recoredurl}</Link> : ""}</span>)
                                                                                                                                    </>
                                                                                                                                )}
                                                                                                                            </h6>
                                                                                                                        </>
                                                                                                                    )}
                                                                                                                </>
                                                                                                            )}
                                                                                                            <span className="text-muted ml-2">
                                                                                                                {applicationdetaildata.application_interviewTwoRound.interviewTwoRound_accepted_date
                                                                                                                    ? Moment(applicationdetaildata.application_interviewTwoRound.interviewTwoRound_accepted_date).format("h:mm a")
                                                                                                                    : ""}
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="align-items-center mb-5 mt-5">
                                                                                                        <h5>Assigned Interviewer</h5>
                                                                                                        <div className="d-flex text-gray-600  fs-6 interviewr-outer">
                                                                                                            <div className="d-flex align-items-center mb-5 mt-5 me-8">
                                                                                                                <div className="me-4 position-relative">
                                                                                                                    <div className="symbol symbol-30px symbol-circle">
                                                                                                                        <img
                                                                                                                            alt="Pic"
                                                                                                                            src={
                                                                                                                                applicationdetaildata.application_interviewTwoRound.interviewTwoRound_one.interviewTwoRound_one_image
                                                                                                                                    ? applicationdetaildata.application_interviewTwoRound.interviewTwoRound_one.interviewTwoRound_one_image
                                                                                                                                    : "/assets/media/avatars/blank.png"
                                                                                                                            }
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="fw-bold">
                                                                                                                    <div className="fs-7 text-gray-900">
                                                                                                                        {applicationdetaildata.application_interviewTwoRound.interviewTwoRound_one.interviewTwoRound_one_name
                                                                                                                            ? applicationdetaildata.application_interviewTwoRound.interviewTwoRound_one.interviewTwoRound_one_name
                                                                                                                            : ""}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                            <div className="d-flex align-items-center mb-5 mt-5 me-8">
                                                                                                                <div className="me-2 position-relative">
                                                                                                                    <div className="symbol symbol-30px symbol-circle">
                                                                                                                        <img
                                                                                                                            alt="Pic"
                                                                                                                            src={
                                                                                                                                applicationdetaildata.application_interviewTwoRound.interviewTwoRound_two.interviewTwoRound_two_image
                                                                                                                                    ? applicationdetaildata.application_interviewTwoRound.interviewTwoRound_two.interviewTwoRound_two_image
                                                                                                                                    : "/assets/media/avatars/blank.png"
                                                                                                                            }
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="fw-bold">
                                                                                                                    <div className="fs-7 text-gray-900">
                                                                                                                        {applicationdetaildata.application_interviewTwoRound.interviewTwoRound_two.interviewTwoRound_two_name
                                                                                                                            ? applicationdetaildata.application_interviewTwoRound.interviewTwoRound_two.interviewTwoRound_two_name
                                                                                                                            : ""}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        {applicationdetaildata.application_interviewTwoRound.interviewTwoRound_one.interviewTwoRound_one_status && (
                                                                                                            <div className="d-flex align-items-center mb-5 mt-5 me-8">
                                                                                                                <div className="me-4 position-relative">
                                                                                                                    <div className="symbol symbol-30px symbol-circle">
                                                                                                                        <img
                                                                                                                            alt="Pic"
                                                                                                                            src={
                                                                                                                                applicationdetaildata.application_interviewTwoRound.interviewTwoRound_one.interviewTwoRound_one_image
                                                                                                                                    ? applicationdetaildata.application_interviewTwoRound.interviewTwoRound_one.interviewTwoRound_one_image
                                                                                                                                    : "/assets/media/avatars/blank.png"
                                                                                                                            }
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="fw-bold">
                                                                                                                    <div className="">
                                                                                                                        {applicationdetaildata.application_interviewTwoRound.interviewTwoRound_one.interviewTwoRound_one_name
                                                                                                                            ? applicationdetaildata.application_interviewTwoRound.interviewTwoRound_one.interviewTwoRound_one_name
                                                                                                                            : ""}{" "}
                                                                                                                        <span className="text_cap">({applicationdetaildata.application_interviewTwoRound.interviewTwoRound_one.interviewTwoRound_one_status}ed)</span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )}
                                                                                                        {applicationdetaildata.application_interviewTwoRound.interviewTwoRound_two.interviewTwoRound_two_status && (
                                                                                                            <div className="d-flex align-items-center mb-5 mt-5 me-8">
                                                                                                                <div className="me-4 position-relative">
                                                                                                                    <div className="symbol symbol-30px symbol-circle">
                                                                                                                        <img
                                                                                                                            alt="Pic"
                                                                                                                            src={
                                                                                                                                applicationdetaildata.application_interviewTwoRound.interviewTwoRound_two.interviewTwoRound_two_image
                                                                                                                                    ? applicationdetaildata.application_interviewTwoRound.interviewTwoRound_two.interviewTwoRound_two_image
                                                                                                                                    : "/assets/media/avatars/blank.png"
                                                                                                                            }
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="fw-bold">
                                                                                                                    <div className="">
                                                                                                                        {applicationdetaildata.application_interviewTwoRound.interviewTwoRound_two.interviewTwoRound_two_name
                                                                                                                            ? applicationdetaildata.application_interviewTwoRound.interviewTwoRound_two.interviewTwoRound_two_name
                                                                                                                            : ""}{" "}
                                                                                                                        <span className="text_cap">({applicationdetaildata.application_interviewTwoRound.interviewTwoRound_two.interviewTwoRound_two_status}ed)</span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </div>
                                                                                                    {/* {applicationdetaildata.setroundone === "1" && <span onClick={() => showInterviewTwoRoundTwoModalHandle(applicationdetaildata)} className="btn btn-sm btn-primary btn-active-primary">
                                                                                                        Schedule Interview Round Three
                                                                                                    </span>} */}
                                                                                                    {getinterviewTwoRound_round.map((getinterviewTwoRound_roundvalue, index) => (
                                                                                                        <>
                                                                                                            {index < 3 && (
                                                                                                                <div className="replicationreply" key={getinterviewTwoRound_roundvalue._id}>
                                                                                                                    <div className="cursor-pointer symbol symbol-30px  reply_user_pic">
                                                                                                                        <img
                                                                                                                            src={getinterviewTwoRound_roundvalue.UserImage ? getinterviewTwoRound_roundvalue.UserImage : "/assets/media/avatars/blank.png"}
                                                                                                                            alt="user"
                                                                                                                        />
                                                                                                                        <div className="profile-user ms-2">
                                                                                                                            <span className="replyusername">{getinterviewTwoRound_roundvalue.UserName}</span>
                                                                                                                            <span className="date_time">
                                                                                                                                {getinterviewTwoRound_roundvalue.createdAt ? Moment(getinterviewTwoRound_roundvalue.createdAt).format("DD MMM YYYY, h:mm a") : ""}
                                                                                                                            </span>
                                                                                                                            <span className="replymessage">{getinterviewTwoRound_roundvalue.comment}</span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </>
                                                                                                    ))}

                                                                                                    {(applicationdetaildata.application_interviewTwoRound &&
                                                                                                        applicationdetaildata.application_interviewTwoRound.interviewTwoRound_one.interviewTwoRound_one_id === user_info._id &&
                                                                                                        applicationdetaildata.application_interviewTwoRound.interviewTwoRound_one.interviewTwoRound_one_status) ||
                                                                                                        (applicationdetaildata.application_interview &&
                                                                                                            applicationdetaildata.application_interviewTwoRound.interviewTwoRound_two.interviewTwoRound_two_id === user_info._id &&
                                                                                                            applicationdetaildata.application_interviewTwoRound.interviewTwoRound_two.interviewTwoRound_two_status) ||
                                                                                                        (applicationdetaildata.application_interviewTwoRound && applicationdetaildata.application_interviewTwoRound.user_setroundtwo === "1")
                                                                                                        ? (
                                                                                                            ""
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                {(applicationdetaildata.application_interviewTwoRound.interviewTwoRound_one.interviewTwoRound_one_id === user_info._id ||
                                                                                                                    applicationdetaildata.application_interviewTwoRound.interviewTwoRound_two.interviewTwoRound_two_id === user_info._id
                                                                                                                ) && applicationdetaildata.statusint &&
                                                                                                                    applicationdetaildata.statusint !== 5 &&
                                                                                                                    applicationdetaildata.statusint &&
                                                                                                                    applicationdetaildata.statusint !== 4 ? (
                                                                                                                    <div className="align-items-center mb-5 mt-5">
                                                                                                                        <>
                                                                                                                            {user_role && user_role.applicationAccept && user_role.applicationAccept === "yes" && (
                                                                                                                                <span
                                                                                                                                    onClick={() => showAcceptByInterviewTwoRoundModalHandle(applicationdetaildata)}
                                                                                                                                    className="btn btn-sm btn-primary btn-active-primary"
                                                                                                                                >
                                                                                                                                    Accept
                                                                                                                                </span>
                                                                                                                            )}
                                                                                                                            {user_role && user_role.applicationReject && user_role.applicationReject === "yes" && (
                                                                                                                                <span
                                                                                                                                    onClick={() => showRejectByInterviewTwoRoundModalHandle(applicationdetaildata)}
                                                                                                                                    className="ms-2 btn btn-sm btn-light-primary fw-bolder"
                                                                                                                                >
                                                                                                                                    Reject
                                                                                                                                </span>
                                                                                                                            )}
                                                                                                                            {user_role && user_role.applicationAssignOtherMember && user_role.applicationAssignOtherMember === "yes" && (
                                                                                                                                <span
                                                                                                                                    onClick={() => showAutoAssignOtherMemberpoup(applicationdetaildata, getapplication_interviewTwoRound_assign, "interview_round_2")}
                                                                                                                                    className="btn btn-sm btn-primary btn-active-primary"
                                                                                                                                >
                                                                                                                                    {" "}
                                                                                                                                    Assign Other member {" "}
                                                                                                                                </span>
                                                                                                                            )}
                                                                                                                        </>
                                                                                                                    </div>
                                                                                                                ) : (
                                                                                                                    ""
                                                                                                                )}
                                                                                                            </>
                                                                                                        )}

                                                                                                    <div className="comment-post-main">
                                                                                                        <span className="showreplymessage" onClick={() => showapplicationreply(replyapplicationformreply, "show_interview_round_2")}>
                                                                                                            <i className="bi bi-chat-square"></i>
                                                                                                            <span>{getinterview_round_2.length}</span>
                                                                                                        </span>
                                                                                                        {applicationformreply === "show" && apptype === "show_interview_round_2" ? (
                                                                                                            <>
                                                                                                                {getinterview_round_2.map((get_interview_round_2_value) => (
                                                                                                                    <div className="replicationreply" key={get_interview_round_2_value._id}>
                                                                                                                        <div className="cursor-pointer symbol symbol-30px reply_user_pic">
                                                                                                                            <img
                                                                                                                                src={
                                                                                                                                    get_interview_round_2_value.UserImage
                                                                                                                                        ? get_interview_round_2_value.UserImage
                                                                                                                                        : "/assets/media/avatars/blank.png"
                                                                                                                                }
                                                                                                                                alt="user"
                                                                                                                            />
                                                                                                                            <div className="profile-user ms-2">
                                                                                                                                <span className="replyusername">{get_interview_round_2_value.UserName}</span>
                                                                                                                                <span className="date_time">
                                                                                                                                    {get_interview_round_2_value.createdAt
                                                                                                                                        ? Moment(get_interview_round_2_value.createdAt).format("DD MMM YYYY, h:mm a")
                                                                                                                                        : ""}
                                                                                                                                </span>
                                                                                                                                <span className="replymessage">{get_interview_round_2_value.comment}</span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                ))}
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            ""
                                                                                                        )}
                                                                                                    </div>
                                                                                                    {user_role && user_role.applicationComment && user_role.applicationComment === "yes" && (
                                                                                                        <div className="comment-box mb-2">
                                                                                                            <form className="form w-100" onSubmit={(event) => SubmitHandler(event, "interview_round_2")}>
                                                                                                                <textarea
                                                                                                                    key="interview_round_2"
                                                                                                                    className="form-control form-control-lg mt-3 mb-3"
                                                                                                                    value={
                                                                                                                        application_form.interview_round_2 && application_form.interview_round_2.value
                                                                                                                            ? application_form.interview_round_2.value
                                                                                                                            : ""
                                                                                                                    }
                                                                                                                    onChange={(event) => inputChangeHandler(event, "interview_round_2")}
                                                                                                                ></textarea>

                                                                                                                <button type="submit" id="kt_new_password_submit" className="btn btn-sm fw-bolder me-4">
                                                                                                                    <span className="indicator-label">Post Comment</span>
                                                                                                                </button>
                                                                                                            </form>
                                                                                                        </div>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                            </>
                                                                        )}



                                                                        {/* Start Interview Round 2 Completed */}
                                                                        {/* <div className="timeline-item">
                                                                            <div className="timeline-media">
                                                                                <span className="fs-2 fw-bolder date-one">30</span>
                                                                                <br />
                                                                                <span className="opacity-50 fs-8 fw-bold">July 2021</span>
                                                                            </div>
                                                                            <div className="timeline-content">
                                                                                <div className="d-flex align-items-center justify-content-between mb-3">
                                                                                    <div className="mr-2">
                                                                                        <h3 className="text-dark-75 font-weight-bold">Interview Round 2 Completed</h3>
                                                                                        <span className="text-muted ml-2">4:20 PM</span>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="align-items-center mb-5 mt-5">
                                                                                    <h5>Assigned Interviewer </h5>
                                                                                    <div className="d-flex text-gray-600  fs-6 interviewr-outer">
                                                                                        <div className="d-flex align-items-center mb-5 mt-5 me-8">
                                                                                            <div className="me-4 position-relative">
                                                                                                <div className="symbol symbol-30px symbol-circle">
                                                                                                    <img alt="Pic" src="/assets/media/avatars/150-5.jpg" />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="fw-bold">
                                                                                                <div className="fs-5 text-gray-900">Cob Sedwick</div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="d-flex align-items-center mb-5 mt-5 me-8">
                                                                                            <div className="me-4 position-relative">
                                                                                                <div className="symbol symbol-30px symbol-circle">
                                                                                                    <img alt="Pic" src="/assets/media/avatars/150-8.jpg" />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="fw-bold">
                                                                                                <div className="fs-5 text-gray-900">Yorker Scogings</div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="d-flex align-items-center mb-5 mt-5 me-8">
                                                                                            <div className="me-4 position-relative">
                                                                                                <div className="symbol symbol-30px symbol-circle">
                                                                                                    <img
                                                                                                        alt="Pic"
                                                                                                        src="
                                                                                            /assets/media/avatars/150-12.jpg"
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="fw-bold">
                                                                                                <div className="fs-5 text-gray-900">Arlie Larking</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <span className="btn btn-sm btn-primary btn-active-primary">Final Acceptance</span>
                                                                                    {user_role && user_role.applicationReject && user_role.applicationReject === "yes" && (
                                                                                        <span onClick={() => showRejectModalHandle(applicationdetaildata)} className="ms-2 btn btn-sm btn-light-primary fw-bolder">
                                                                                            Reject
                                                                                        </span>
                                                                                    )}
                                                                                </div>

                                                                                <div className="comment-post-main">
                                                                                    <span className="showreplymessage " onClick={() => showapplicationreply(replyapplicationformreply, "show_interview_round_2")}>
                                                                                        <i className="bi bi-chat-square"></i>
                                                                                        <span>{getinterview_round_2.length}</span>
                                                                                    </span>
                                                                                    {applicationformreply === "show" && apptype === "show_interview_round_2" ? (
                                                                                        <>
                                                                                            {getinterview_round_2.map((getinterview_round_2value) => (
                                                                                                <div className="replicationreply" key={getinterview_round_2._id}>
                                                                                                    <div className="cursor-pointer symbol symbol-30px  reply_user_pic">
                                                                                                        <img src={getinterview_round_2.UserImage ? getinterview_round_2.UserImage : "/assets/media/avatars/blank.png"} alt="user" />
                                                                                                        <div className="profile-user ms-2">
                                                                                                            <span className="replyusername">{getinterview_round_2value.UserName}</span>
                                                                                                            <span className="date_time">
                                                                                                                {getinterview_round_2value.createdAt ? Moment(getinterview_round_2value.createdAt).format("DD MMM YYYY, h:mm a") : ""}
                                                                                                            </span>
                                                                                                            <span className="replymessage">{getinterview_round_2value.comment}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                </div>
                                                                                {user_role && user_role.applicationComment && user_role.applicationComment === "yes" && (
                                                                                    <div className="comment-box">
                                                                                        <form className="form w-100" onSubmit={(event) => SubmitHandler(event, "interview_round_2")}>
                                                                                            <textarea
                                                                                                key="interview_round_2"
                                                                                                className="form-control form-control-lg mt-3 mb-3"
                                                                                                value={application_form.interview_round_2 && application_form.interview_round_2.value ? application_form.interview_round_2.value : ""}
                                                                                                onChange={(event) => inputChangeHandler(event, "interview_round_2")}
                                                                                            ></textarea>

                                                                                            <button type="submit" id="kt_new_password_submit" className="btn btn-sm fw-bolder me-4">
                                                                                                <span className="indicator-label">Post Comment</span>
                                                                                            </button>
                                                                                        </form>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div> */}

                                                                        {/* end Interview Round 2 Completed */}
                                                                        <>
                                                                            {/* {(applicationdetaildata.statusint && applicationdetaildata.statusint === 5) || (applicationdetaildata.statusint && applicationdetaildata.statusint === 4 && (applicationdetaildata.RejectUName && applicationdetaildata.RejectUName !== '' ) ) ? (
                                                                                <div className="timeline-item">
                                                                                    <div className="timeline-media">
                                                                                        {!applicationdetaildata.acceptrejectdate ? (
                                                                                            <>
                                                                                                <span className="fs-2 fw-bolder date-one">{Moment(new Date()).format("DD")} </span>
                                                                                                <br />
                                                                                                <span className="opacity-50 fs-8 fw-bold">{Moment(new Date()).format("MMM YYYY")}</span>
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                <span className="fs-2 fw-bolder date-one">
                                                                                                    {applicationdetaildata.acceptrejectdate ? Moment(applicationdetaildata.acceptrejectdate).format("DD") : ""}{" "}
                                                                                                </span>
                                                                                                <br />
                                                                                                <span className="opacity-50 fs-8 fw-bold">
                                                                                                    {applicationdetaildata.acceptrejectdate ? Moment(applicationdetaildata.acceptrejectdate).format("MMM YYYY") : ""}
                                                                                                </span>
                                                                                            </>
                                                                                        )}
                                                                                    </div>
                                                                                    <div className="timeline-content">
                                                                                        <div className="d-flex align-items-center justify-content-between mb-3">
                                                                                            <div className="mr-2">
                                                                                                {applicationdetaildata.statusint === 5 ? (
                                                                                                    <>
                                                                                                        <h3 className="text-dark-75 font-weight-bold"> Application Accepted</h3>

                                                                                                        <span className="opacity-50 fs-8 fw-bold">{Moment(new Date()).format("h:mm a")}</span>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <h3 className="text-dark-75 font-weight-bold">
                                                                                                            <span className="me-2">Application Rejected by </span>{" "}
                                                                                                            <div className="me-2 symbol symbol-30px symbol-circle">
                                                                                                                <img
                                                                                                                    className="mx-2"
                                                                                                                    alt="Pic"
                                                                                                                    src={applicationdetaildata.RejectUImage ? applicationdetaildata.RejectUImage : "/assets/media/avatars/blank.png"}
                                                                                                                />
                                                                                                            </div>{" "}
                                                                                                            {applicationdetaildata.RejectUName}
                                                                                                        </h3>
                                                                                                    </>
                                                                                                )}
                                                                                                
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ) : ( <>
                                                                                    {applicationdetaildata.acceptrejectdate && applicationdetaildata.acceptrejectdate !== '' ? (
                                                                                        <div className="timeline-item">
                                                                                            <div className="timeline-media">
                                                                                                {!applicationdetaildata.acceptrejectdate ? (
                                                                                                    <>
                                                                                                        <span className="fs-2 fw-bolder date-one">{Moment(new Date()).format("DD")} </span>
                                                                                                        <br />
                                                                                                        <span className="opacity-50 fs-8 fw-bold">{Moment(new Date()).format("MMM YYYY")}</span>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <span className="fs-2 fw-bolder date-one">
                                                                                                            {applicationdetaildata.acceptrejectdate ? Moment(applicationdetaildata.acceptrejectdate).format("DD") : ""}{" "}
                                                                                                        </span>
                                                                                                        <br />
                                                                                                        <span className="opacity-50 fs-8 fw-bold">
                                                                                                            {applicationdetaildata.acceptrejectdate ? Moment(applicationdetaildata.acceptrejectdate).format("MMM YYYY") : ""}
                                                                                                        </span>
                                                                                                    </>
                                                                                                )}
                                                                                            </div>
                                                                                            <div className="timeline-content">
                                                                                                <div className="d-flex align-items-center justify-content-between mb-3">
                                                                                                    <div className="mr-2">
                                                                                                                <h3 className="text-dark-75 font-weight-bold">
                                                                                                                    <span className="me-2">Application has been Rejected </span>                                                                                                                    
                                                                                                                </h3>
                                                                                                           

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ):("") }
                                                                                    
                                                                                    </>
                                                                            )} */}
                                                                            {applicationdetaildata.sendclarification &&
                                                                                applicationdetaildata.sendclarification === "2" &&
                                                                                applicationdetaildata.assign_for_review &&
                                                                                applicationdetaildata.assign_for_review.assign_member.membername === "" && (
                                                                                    <div className="timeline-item">
                                                                                        <div className="timeline-media">
                                                                                            {!applicationdetaildata.clarificationrequesteddate ? (
                                                                                                <>
                                                                                                    <span className="fs-2 fw-bolder date-one">{Moment(new Date()).format("DD")} </span>
                                                                                                    <br />
                                                                                                    <span className="opacity-50 fs-8 fw-bold">{Moment(new Date()).format("MMM YYYY")}</span>
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    <span className="fs-2 fw-bolder date-one">
                                                                                                        {applicationdetaildata.clarificationrequesteddate ? Moment(applicationdetaildata.clarificationrequesteddate).format("DD") : ""}{" "}
                                                                                                    </span>
                                                                                                    <br />
                                                                                                    <span className="opacity-50 fs-8 fw-bold">
                                                                                                        {applicationdetaildata.clarificationrequesteddate ? Moment(applicationdetaildata.clarificationrequesteddate).format("MMM YYYY") : ""}
                                                                                                    </span>
                                                                                                </>
                                                                                            )}
                                                                                        </div>
                                                                                        <div className="timeline-content">
                                                                                            <div className="d-flex align-items-center justify-content-between mb-3">
                                                                                                <div className="mr-2">
                                                                                                    <h3 className="text-dark-75 font-weight-bold"> Clarification requested</h3>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                        </>
                                                                    </>
                                                                )}
                                                                {(applicationdetaildata.statusint && applicationdetaildata.statusint === 5) ||
                                                                    (applicationdetaildata.statusint && applicationdetaildata.statusint === 4 && applicationdetaildata.RejectUName && applicationdetaildata.RejectUName !== "") ? (
                                                                    <div className="timeline-item">
                                                                        <div className="timeline-media">
                                                                            {!applicationdetaildata.acceptrejectdate ? (
                                                                                <>
                                                                                    <span className="fs-2 fw-bolder date-one">{Moment(new Date()).format("DD")} </span>
                                                                                    <br />
                                                                                    <span className="opacity-50 fs-8 fw-bold">{Moment(new Date()).format("MMM YYYY")}</span>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <span className="fs-2 fw-bolder date-one">
                                                                                        {applicationdetaildata.acceptrejectdate ? Moment(applicationdetaildata.acceptrejectdate).format("DD") : ""}{" "}
                                                                                    </span>
                                                                                    <br />
                                                                                    <span className="opacity-50 fs-8 fw-bold">
                                                                                        {applicationdetaildata.acceptrejectdate ? Moment(applicationdetaildata.acceptrejectdate).format("MMM YYYY") : ""}
                                                                                    </span>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                        <div className="timeline-content">
                                                                            <div className="d-flex align-items-center justify-content-between mb-3">
                                                                                <div className="mr-2">
                                                                                    {applicationdetaildata.statusint === 5 ? (
                                                                                        <>
                                                                                            <h3 className="text-dark-75 font-weight-bold"> Application Accepted</h3>

                                                                                            <span className="opacity-50 fs-8 fw-bold">{Moment(new Date()).format("h:mm a")}</span>
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            <h3 className="text-dark-75 font-weight-bold">
                                                                                                <span className="me-2">Application Rejected by </span>{" "}
                                                                                                <div className="me-2 symbol symbol-30px symbol-circle">
                                                                                                    <img
                                                                                                        className="mx-2"
                                                                                                        alt="Pic"
                                                                                                        src={applicationdetaildata.RejectUImage ? applicationdetaildata.RejectUImage : "/assets/media/avatars/blank.png"}
                                                                                                    />
                                                                                                </div>{" "}
                                                                                                {applicationdetaildata.RejectUName}
                                                                                            </h3>
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        {applicationdetaildata.statusint && applicationdetaildata.statusint === 4 && applicationdetaildata.acceptrejectdate && applicationdetaildata.acceptrejectdate !== "" ? (
                                                                            <div className="timeline-item">
                                                                                <div className="timeline-media">
                                                                                    {!applicationdetaildata.acceptrejectdate ? (
                                                                                        <>
                                                                                            <span className="fs-2 fw-bolder date-one">{Moment(new Date()).format("DD")} </span>
                                                                                            <br />
                                                                                            <span className="opacity-50 fs-8 fw-bold">{Moment(new Date()).format("MMM YYYY")}</span>
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            <span className="fs-2 fw-bolder date-one">
                                                                                                {applicationdetaildata.acceptrejectdate ? Moment(applicationdetaildata.acceptrejectdate).format("DD") : ""}{" "}
                                                                                            </span>
                                                                                            <br />
                                                                                            <span className="opacity-50 fs-8 fw-bold">
                                                                                                {applicationdetaildata.acceptrejectdate ? Moment(applicationdetaildata.acceptrejectdate).format("MMM YYYY") : ""}
                                                                                            </span>
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                                <div className="timeline-content">
                                                                                    <div className="d-flex align-items-center justify-content-between mb-3">
                                                                                        <div className="mr-2">
                                                                                            <h3 className="text-dark-75 font-weight-bold">
                                                                                                <span className="me-2">
                                                                                                    Application has been Rejected {applicationdetaildata.isuesroneassign === "0" && applicationdetaildata.setinterview === "0" && applicationdetaildata.palagrism && applicationdetaildata.palagrism && applicationdetaildata.palagrism > 35 ? " by plagiarism" : ""}
                                                                                                </span>
                                                                                            </h3>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </>
                                                                )}
                                                                {emailsData.length ? (<div className="timeline-item">
                                                                    <div className="timeline-media">
                                                                        <span className="fs-5 fw-bold">Emails</span>
                                                                    </div>
                                                                    <div className="timeline-content">
                                                                        {emailsData.map((emailData) => (
                                                                            <div className="replicationreply" key={emailData._id}>
                                                                                <div className="cursor-pointer symbol symbol-30px reply_user_pic">
                                                                                    <div className="profile-user ms-2">
                                                                                        <span className="date_time">
                                                                                            {emailData.createdAt ? Moment(emailData.createdAt).format("DD MMM YYYY, h:mm a") : ""}
                                                                                        </span>
                                                                                        <span className="replymessage" dangerouslySetInnerHTML={{ __html: emailData.content }}></span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>) : ""}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* end que form */}
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
            <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
                <span className="svg-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="black" />
                        <path
                            d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                            fill="black"
                        />
                    </svg>
                </span>
            </div>
            <Modal footer={null} title="Assign for Review" visible={isAssignModalVisible} onCancel={handleCancel}>
                <AssignApplicationUser showData={userDetails} setIsAssignModalVisible={setIsAssignModalVisible} editChapterData={Assignuserdata} MenualAssignApplicationSubmit={(event) => MenualAssignApplicationSubmit(event)} />
            </Modal>
            <Modal footer={null} title="Assign Other Member for Review" visible={isAutoAssignOtherMemberrModalVisible} onCancel={handleCancel}>
                <AutoAssignOtherMember
                    showData={userDetails}
                    setIsAutoAssignOtherMemberrModalVisible={setIsAutoAssignOtherMemberrModalVisible}
                    isExternalMemberTypeModalVisible={isExternalMemberTypeModalVisible}
                    editChapterData={Assignuserdata}
                    getroundoneassignuser={getroundoneassignuser}
                    getassignresult={getassignresult}
                    MenualAutoAssignOtherMemberApplicationSubmit={(event) => MenualAutoAssignOtherMemberApplicationSubmit(event)}
                />
            </Modal>
            <Modal footer={null} title="Accept Application" visible={isAutoAcceptModalVisible} onCancel={handleCancel}>
                <AutoAssignAdmin
                    showData={userDetails}
                    setIsAutoAcceptModalVisible={setIsAutoAcceptModalVisible}
                    isExternalMemberTypeModalVisible={isExternalMemberTypeModalVisible}
                    messagedata="Are you sure you want to Accept this application?"
                    editChapterData={Assignuserdata}
                    chapterId={id}
                    MenualAutoAssignAdminApplicationSubmit={(event) => MenualAutoAssignAdminApplicationSubmit(event)}
                />
            </Modal>
            <Modal footer={null} title="Accept Application" visible={isAutoAcceptModalVisibleTwoRound} onCancel={handleCancel}>
                <AutoAssignAdmin
                    showData={userDetails}
                    setIsAutoAcceptModalVisible={setIsAutoAcceptModalVisibleTwoRound}
                    isExternalMemberTypeModalVisible={isExternalMemberTypeModalVisible}
                    messagedata="Are you sure you want to Accept this application?"
                    editChapterData={Assignuserdata}
                    chapterId={id}
                    MenualAutoAssignAdminApplicationSubmit={(event) => MenualAutoAssignAdminApplicationSubmitTwoRound(event)}
                />
            </Modal>

            <Modal footer={null} title="Please select one member to replace" visible={ischnageOtherMemberModalVisible} onCancel={handleCancel}>
                <AutoAssignRepleaceMember
                    showData={userDetails}
                    setIschnageOtherMemberModalVisible={setIschnageOtherMemberModalVisible}
                    isExternalMemberTypeModalVisible={isExternalMemberTypeModalVisible}
                    editChapterData={editChapterData}
                    getlastMemberId={getlastMemberId}
                    AutoRepleaceMemberApplicationSubmit={(event) => AutoRepleaceMemberApplicationSubmit(event)}
                />
            </Modal>
            <Modal footer={null} title="External Member for Review" visible={isExternalMemberModalVisible} onCancel={handleCancel}>
                <ExternalMember
                    showData={userDetails}
                    isExternalMemberTypeModalVisible={isExternalMemberTypeModalVisible}
                    setIsExternalMemberModalVisible={setIsExternalMemberModalVisible}

                    editChapterData={Assignuserdata}
                    getroundoneassignuser={getroundoneassignuser}
                    getassignresult={getassignresult}
                    ExternalMemberApplicationSubmit={(event) => ExternalMemberApplicationSubmit(event)}
                />
            </Modal>
            <Modal footer={null} title="Accept Application" visible={isAcceptModalVisible} onCancel={handleCancel}>
                <AcceptApplication
                    chapterId={id}
                    setIsAcceptModalVisible={setIsAcceptModalVisible}
                    showData={userDetails}
                    editChapterData={editChapterData}
                    messagedata="Are you sure you want to Accept this application?"
                    AcceptApplicationSubmit={(event) => AcceptApplicationSubmit(event)}
                />
            </Modal>
            <Modal footer={null} title="Accept Application" visible={isFinalAcceptModalVisible} onCancel={handleCancel}>
                <FinalAcceptApplication
                    chapterId={id}
                    setIsFinalAcceptModalVisible={setIsFinalAcceptModalVisible}
                    showData={userDetails}
                    editChapterData={editChapterData}
                    messagedata="Are you sure you want to Final Accept this application?"
                    FinalAcceptApplicationSubmit={(event) => FinalAcceptApplicationSubmit(event)}
                />
            </Modal>
            <Modal footer={null} title={gettitle} visible={isAcceptByUserModalVisible} onCancel={handleCancel}>
                <AcceptRejectByUserApplication
                    chapterId={id}
                    setIsAcceptByUserModalVisible={setIsAcceptByUserModalVisible}
                    showData={userDetails}
                    editChapterData={editChapterData}
                    status={getstatus}
                    messagedata={getmessagedata}
                    AcceptRejectByUserApplicationSubmit={(event) => AcceptRejectByUserApplicationSubmit(event)}
                />
            </Modal>

            <Modal footer={null} title={gettitle} visible={isAcceptFinalReviewModalVisible} onCancel={handleCancel}>
                <AcceptFinalReviewByAllApplication
                    chapterId={id}
                    setIsAcceptFinalReviewModalVisible={setIsAcceptFinalReviewModalVisible}
                    showData={userDetails}
                    editChapterData={editChapterData}
                    status={getstatus}
                    messagedata={getmessagedata}
                    AcceptFinalReviewByAllApplicationSubmit={(event) => AcceptFinalReviewByAllApplicationSubmit(event)}
                />
            </Modal>

            <Modal footer={null} title={gettitle} visible={isAcceptByInterviewModalVisible} onCancel={handleCancel}>
                <AcceptRejectByInterviewApplication
                    chapterId={id}
                    setIsAcceptByInterviewModalVisible={setIsAcceptByInterviewModalVisible}
                    showData={userDetails}
                    editChapterData={editChapterData}
                    status={getstatus}
                    messagedata={getmessagedata}
                    AcceptRejectByInterviewApplicationSubmit={(event) => AcceptRejectByInterviewApplicationSubmit(event)}
                />
            </Modal>

            <Modal footer={null} title={gettitle} visible={isAcceptByInterviewTwoRoundModalVisible} onCancel={handleCancel}>
                <AcceptRejectByInterviewApplication
                    chapterId={id}
                    setIsAcceptByInterviewModalVisible={setIsAcceptByInterviewTwoRoundModalVisible}
                    showData={userDetails}
                    editChapterData={editChapterData}
                    status={getstatus}
                    messagedata={getmessagedata}
                    AcceptRejectByInterviewApplicationSubmit={(event) => AcceptRejectByInterviewTwoRoundApplicationSubmit(event)}
                />
            </Modal>

            <Modal footer={null} title="Reject Application" visible={isRejectModalVisible} onCancel={handleCancel}>
                <RejectApplication
                    chapterId={id}
                    setIsRejectModalVisible={setIsRejectModalVisible}
                    showData={userDetails}
                    editChapterData={editChapterData}
                    messagedata="Are you sure you want to reject this application?"
                    RejectApplicationSubmit={(event) => RejectApplicationSubmit(event)}
                />
            </Modal>
            <Modal footer={null} title={gettitle} visible={isArchiveModalVisible} onCancel={handleCancel}>
                <ApplicationArchive
                    chapterId={id}
                    statusvalue={archivestatus}
                    setIsArchiveModalVisible={setIsArchiveModalVisible}
                    showData={userDetails}
                    editChapterData={editChapterData}
                    messagedata={getmessagedata}
                    ApplicationArchiveSubmit={(event) => ApplicationArchiveSubmit(event)}
                />
            </Modal>
            <Modal footer={null} title="Send progress URL" visible={isSendEmailModalVisible} onCancel={handleCancel}>
                <ApplicationSendEmail
                    chapterId={id}
                    setIsSendEmailModalVisible={setIsSendEmailModalVisible}
                    showData={userDetails}
                    editChapterData={editChapterData}
                    messagedata="Are you sure you want to send progress URL to candidate?"
                    ApplicationSendEmailSubmit={(event) => ApplicationSendEmailSubmit(event)}
                />
            </Modal>
            <Modal footer={null} title="Schedule Interview Round Two" visible={isInterviewTwoModalVisible} onCancel={handleCancel}>
                <InterviewRoundTwoSchelude
                    setIsreshudel={isInterviewTwoModalVisible}
                    Calendardata={{
                        applicationid: applicaionId
                    }}
                    editChapterData={Assignuserdata}
                    getroundoneassignuser={getroundoneassignuser}
                    ScheduleInterviewTwoRoundSubmit={(event) => ScheduleInterviewTwoRound(event)}
                />
            </Modal>
        </div>
    );
};

export default ApplicationDetail;