// import React, { useEffect, useState } from "react";

function InterviewSchedulCalendar(props) {
    const user_role = JSON.parse(localStorage.getItem("gpt_user_role"));
    // const user_info = JSON.parse(localStorage.getItem("gpt_authuser"));
    // const [application_form, setapplication_form] = useState([]);

    const { Calendardata, ApplyReScheduleApplicationSubmit } = props;
    //const { Calendardata, setIsAcceptModalVisible, ApplyCancelScheduleApplicationSubmit, ApplyReScheduleApplicationSubmit } = props;
    // useEffect(() => {
    //     setapplication_form({
    //         rescheduledate: {
    //             label: "Re-schedule date",
    //             type: "text",
    //             value: "",
    //             valid: true,
    //             error_msg: "",
    //             validations: { required: false },
    //         },
    //     });
    //     }, [])
    // const handleCancel = () => {
    //     setIsAcceptModalVisible(false);
    // };
    // const showCancelSchedulePopup = (data) => {
    //     ApplyCancelScheduleApplicationSubmit(data);
    // };
    const showReSchedulePopup = (data) => {
        ApplyReScheduleApplicationSubmit(data);
    };
    return (
        <form name="normal_login" className="login-form" >
            <h3>Candidate Details: </h3>
            <p><b>Name:</b> {Calendardata.AppTitle} </p>
            <p><b>Email:</b> {Calendardata.Email}</p>
            <p><b>Mobile:</b> {Calendardata.Mobile}</p>
            <p><b>Applied For:</b> {Calendardata.TagName}</p>
            <p><b>Interview Date:</b> {Calendardata.IterviewDate}</p>
            <p><b>Interview Time:</b> {Calendardata.IterviewTime }</p>
            <p><b>Interview URL:</b> {Calendardata.IterviewUrl}</p>
                     
            <div className="model text-right mt-3"> 
                {user_role && user_role.interviewReschedule === "yes" && (                
                <button type="button" className='btn btn-sm btn-primary fw-bolder mx-3' 
                    onClick={() =>showReSchedulePopup(Calendardata)}
                >Re-schedule</button>    
                )}          
               
            </div>
        </form>
    );
}
export default InterviewSchedulCalendar;