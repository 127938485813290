import React, { useEffect, useState, useCallback } from "react";
//import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { getInterviewsByUser, getActiveMember, reScheduleInterview } from "../../Api/user/userapi";
import { Modal, notification } from "antd";
import Select from "react-select";

import LeftSideBar from "../../Constant/LeftSideBar";
import TopBar from "../../Constant/TopBar";
import Footer from "../../Constant/Footer";

import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import Moment from "moment";

import InterviewSchedulCalendar from "./Modal/InterviewSchedulCalendar"
import CancleInterviewSchedule from "./Modal/CancleInterviewSchedule"
import InterviewReSchedule from "./Modal/InterviewReSchedule"

const customStyles = {
    menu: provided => ({ ...provided, zIndex: 9999 }),
    option: (provided, state) => ({
        ...provided,
        zIndex: "999",
        "&:hover": {
            backgroundColor: state.isFocused ? "#ffdbdb" : ""
        },
        backgroundColor: state.isSelected ? "#cc0c0c" : "",
    }),
};

const InterviewSchedule = () => {
    const user_role = JSON.parse(localStorage.getItem("gpt_user_role"));
    const user_info = JSON.parse(localStorage.getItem("gpt_authuser"));
    const [CalendarDate, setCalendarDate] = useState([]);
    const [endDate, setendDate] = useState("");
    const [startDate, setstartDate] = useState("");
    const [memberId, setmemberId] = useState("");
    //const [ActivityData, setActivityDataa] = useState([]);  
    const [Assignuserdata, setAssignuserdata] = useState([]);
    const [isAssignModalVisible, setIsAssignModalVisible] = useState(false);
    const [iscancleshudel, setIscancleshudel] = useState(false);
    const [isreshudel, setIsreshudel] = useState(false);
    const [calanderdata, setcalanderdata] = useState([]);


    function handleTimeSelection(info) {

        setendDate(Moment(info.end).format("YYYY-MM-DD"))
        setstartDate(Moment(info.start).format("YYYY-MM-DD"))
        calandergetInterviewsByUser(Moment(info.start).format("YYYY-MM-DD"), Moment(info.end).format("YYYY-MM-DD"), memberId);

    }
    function inputChangeHandler(event) {
        setmemberId(event.value);
        // setmemberId(event.target.value);
        calandergetInterviewsByUser(Moment(startDate).format("YYYY-MM-DD"), Moment(endDate).format("YYYY-MM-DD"), event.value);
    }
    const getAllMemberList = useCallback(async () => {
        try {
            const resp = await getActiveMember();
            if (resp && resp.status === 200) {
                const list = resp;
                const arrayData = list.data.data;
                // setAssignuserdata(arrayData);
                let LevelId_option = [];
                for (let key in arrayData) {
                    LevelId_option.push({
                        value: arrayData[key]._id,
                        label: arrayData[key].userName,
                    });
                }
                setAssignuserdata(LevelId_option);
            }
        } catch (e) { }
    }, []);
    const calandergetInterviewsByUser = useCallback(async (startDate, endDate, memberId) => {
        try {
            var usermemberid = memberId;
            if (user_info && user_info.usertype !== "admin" && user_role && user_role.membersInterviewSchedule !== "yes") {
                usermemberid = user_info._id;
            }
            else {
                usermemberid = memberId;
            }
            if (usermemberid !== '') {
                const payload = {
                    memberId: usermemberid, //'61fbd05149549eef5596d2a3',
                    startDate: startDate,
                    endDate: endDate,

                };
                const resp = await getInterviewsByUser(payload);
                var result = [];
                //setActivityDataa(result);
                if (resp && resp !== "" && resp.data.success === true) {
                    const list = resp;
                    const resultdata = list.data.data;
                    let application_accepted_assign = [];
                    for (let key in resultdata) {
                        let appname = resultdata[key].type;
                        if (resultdata[key].appname) {
                            appname = resultdata[key].appname;
                        }
                        let clickdate = new Date(resultdata[key].date);
                        let newcurerntdates = Moment(clickdate).format("YYYY-MM-DD");
                        application_accepted_assign.push({
                            title: appname,
                            date: newcurerntdates,
                            IterviewDate: resultdata[key].date,
                            IterviewTime: resultdata[key].time,
                            IterviewUrl: resultdata[key].link,
                            applicationid: resultdata[key].applicationid,
                            filterdate: resultdata[key].filterdate,
                            TagName: resultdata[key].TagName,
                            Email: resultdata[key].email,
                            AppTitle: appname,
                            Mobile: resultdata[key].mobile,
                            ApplicationUserID: resultdata[key].userid,
                        });
                    }
                    setCalendarDate(application_accepted_assign);
                }
            }
        } catch (e) { }
    }, [memberId, startDate, endDate]);
    useEffect(() => {
        getAllMemberList();
        const date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        setendDate(Moment(lastDay).format("YYYY-MM-DD"))
        setstartDate(Moment(firstDay).format("YYYY-MM-DD"))

        if (user_info && user_info.usertype !== "admin" && user_role.membersInterviewSchedule !== "yes") {
            setmemberId(user_info._id);
            calandergetInterviewsByUser(Moment(Moment(firstDay).format("YYYY-MM-DD")).format("YYYY-MM-DD"), Moment(Moment(lastDay).format("YYYY-MM-DD")).format("YYYY-MM-DD"), user_info._id);
        }

    }, [getAllMemberList]);

    const handleCancel = () => {
        setIsAssignModalVisible(false);
        setIscancleshudel(false);
        setIsreshudel(false);
    };
    async function ApplyCancelScheduleApplicationSubmit(payload) {
        setIsAssignModalVisible(false);
        setIscancleshudel(true);
    }
    async function ApplyReScheduleApplicationSubmit(payload) {
        setIsAssignModalVisible(false);
        setIsreshudel(true);
    }



    async function CancelScheduleApplicationSubmit(payload) {
        setIscancleshudel(false);
    }
    async function ReScheduleApplicationSubmit(payload) {
        try {
            const resp = await reScheduleInterview(payload);
            if (resp.data.success !== false) {
                calandergetInterviewsByUser(Moment(startDate).format("YYYY-MM-DD"), Moment(endDate).format("YYYY-MM-DD"), memberId);
                setIsreshudel(false);
                setTimeout(() => {
                    notification.open({
                        message: "Re-schedule",
                        description: 'Interview Re-scheduled successfully',
                    });
                }, 1000);
            }
            else {
                setTimeout(() => {
                    notification.open({
                        message: "Re-schedule",
                        description: resp.data.message,
                    });
                }, 1000);
                // setLoading(false);
            }
        } catch (e) { }
    }


    return (
        <div className="d-flex flex-column flex-root header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed h-100">
            <div className="page d-flex flex-row flex-column-fluid">
                <LeftSideBar />
                <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                    <TopBar />

                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                        <div className="toolbar" id="kt_toolbar">
                            <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                                <div
                                    data-kt-swapper="true"
                                    data-kt-swapper-mode="prepend"
                                    data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
                                    className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
                                >
                                    <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
                                        Interview Calendar
                                        <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                                    </h1>

                                    <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                                        <li className="breadcrumb-item text-muted">
                                            <Link to="/dashboard" className="text-muted text-hover-primary">
                                                Home
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <span className="bullet bg-gray-200 w-5px h-2px"></span>
                                        </li>

                                        <li className="breadcrumb-item text-dark">Interview Calendar</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="post d-flex flex-column-fluid" id="kt_post">
                            <div id="kt_content_container" className="container-xxl">
                                <div className="card card-flush mb-xl-8 interview-top-header" data-select2-id="select2-data-160-5h6n ">
                                    <div className="card-header mt-5">

                                        <div className="card-toolbar my-1 team-member-box" data-select2-id="select2-data-159-5lwa">
                                            <div className="me-4 my-1 app-one">
                                                {(user_role &&
                                                    user_role.membersInterviewSchedule &&
                                                    user_role.membersInterviewSchedule === "yes") && (
                                                        <div className="d-flex align-items-center py-1">
                                                            <Select
                                                                styles={customStyles}
                                                                name={"Member"}
                                                                className="react-bootstrap-typeahead tag-outer"
                                                                placeholder="Select a Member"
                                                                options={Assignuserdata}
                                                                value={Assignuserdata.filter(function (option) {
                                                                    return option.value === memberId;
                                                                })}
                                                                onChange={inputChangeHandler}
                                                            />
                                                        </div>
                                                    )}

                                            </div>


                                        </div>
                                    </div>
                                    <div className="card-body pt-0 mt-5 interview_schedule_calendar">
                                        <FullCalendar
                                            plugins={[dayGridPlugin, interactionPlugin]}
                                            datesSet={handleTimeSelection}
                                            eventClick={
                                                function (arg) {
                                                    setcalanderdata(arg.event.extendedProps);
                                                    setIsAssignModalVisible(true);
                                                }
                                            }
                                            events={CalendarDate}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>

            <Modal footer={null} title="Interview Details" visible={isAssignModalVisible} onCancel={handleCancel}>
                <InterviewSchedulCalendar
                    Calendardata={calanderdata}
                    setIsAssignModalVisible={setIsAssignModalVisible}
                    editChapterData={Assignuserdata}
                    ApplyCancelScheduleApplicationSubmit={(event) => ApplyCancelScheduleApplicationSubmit(event)}
                    ApplyReScheduleApplicationSubmit={(event) => ApplyReScheduleApplicationSubmit(event)}
                />
            </Modal>
            <Modal footer={null} title="cancel Interview" visible={iscancleshudel} onCancel={handleCancel}>
                <CancleInterviewSchedule
                    Calendardata={calanderdata}
                    setAssignuserdata={setAssignuserdata}
                    editChapterData={Assignuserdata}
                    CancelScheduleApplicationSubmit={(event) => CancelScheduleApplicationSubmit(event)}
                />
            </Modal>
            <Modal footer={null} title="Re Schedule Interview" visible={isreshudel} onCancel={handleCancel}>
                <InterviewReSchedule
                    setIsreshudel={setIsreshudel}
                    Calendardata={calanderdata}
                    setAssignuserdata={setAssignuserdata}
                    editChapterData={Assignuserdata}
                    ReScheduleApplicationSubmit={(event) => ReScheduleApplicationSubmit(event)}
                />
            </Modal>
            <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
                <span className="svg-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="black" />
                        <path
                            d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                            fill="black"
                        />
                    </svg>
                </span>
            </div>
        </div>
    );
};
export default InterviewSchedule;
