import React, { useEffect, useState } from "react";
import Validations from "../../Utility/Validations";
import { notification } from "antd";
import Moment from "moment";
import Select from "react-select";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getUpcomingSlots } from "../../../Api/user/userapi";

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#ffdbdb" : ""
        },
        backgroundColor: state.isSelected ? "#cc0c0c" : "",
    }),
};

function InterviewReSchedule(props) {
    const [application_form, setapplication_form] = useState([]);
    const user_info = JSON.parse(localStorage.getItem("gpt_authuser"));
    const { Calendardata, ReScheduleApplicationSubmit } = props;
    useEffect(() => {
        setapplication_form({
            rescheduledate: {
                label: "Re-schedule date",
                type: "datepicker",
                value: "",
                valid: true,
                error_msg: "",
                validations: { required: false },
            },
            availabletime: {
                type: "select",
                label: "Select Level",
                value: "",
                valid: true,
                error_msg: "",
                onBlur_out: false,
                placeholder: "Select Level",
                validations: { required: true },
                options: [],
            },
            created_id: {
                value: user_info._id,
                validations: { required: false },
            },
        });
    }, [])


    const SubmitHandler = async (event) => {
        event.preventDefault();
        if (application_form.rescheduledate.value === '' || application_form.availabletime.value === '') {
            setTimeout(() => {
                notification.open({
                    message: "Re Schedule Interview",
                    description: "Please select date & time.",
                });
            });

        }
        else {
            const payload = {
                applicaionId: Calendardata.applicationid,
                nextdate: Moment(application_form.rescheduledate.value).format("YYYY-MM-DD"),
                memberId: user_info._id,
                availabletime: application_form.availabletime.value,
                created_id: user_info._id,
            };
            ReScheduleApplicationSubmit(payload);
        }
    };


    async function inputChangeHandler(event, identifier) {
        const update_application_form = { ...application_form };
        const form_element = { ...update_application_form[identifier] };

        if (form_element.type === "datepicker") {
            form_element.value = event;
        } else {
            form_element.value = event.value;
        }
        let validate_element = Validations(form_element);
        form_element.error_msg = validate_element.error_msg;

        update_application_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_application_form) {
            is_form_valid = update_application_form[identifier].valid && is_form_valid;
        }

        setapplication_form(update_application_form);

        if (form_element.type === "datepicker") {
            const payload = {
                date: Moment(event).format("YYYY-MM-DD"),
                memberId: Calendardata.ApplicationUserID,
                eventtime: event,
                created_id: user_info._id,
            };
            getUpcomingSlotsvalue(payload);
        }

    }

    async function getUpcomingSlotsvalue(payload) {
        try {
            const resp = await getUpcomingSlots(payload);
            const update_application_form = { ...application_form };
            if (resp && resp.data.success !== false) {
                const list = resp;
                const member_detail = list.data.response[0].slot;
                let startarray = [];
                for (let key in member_detail) {
                    let timeextend = member_detail[key].time;
                    let aftergettime = timeextend.split("-");
                    let startdate = Moment.unix(aftergettime[0]).format("h:mm A");
                    let enddate = Moment.unix(aftergettime[1]).format("h:mm A");
                    startarray.push({
                        label: startdate + ' - ' + enddate,
                        value: timeextend,
                        start: Moment(Moment.unix(aftergettime[0]).format("h:mm A"), 'h:mm A').toDate()
                    });
                }
                startarray.sort(function (a, b) {
                    return Moment(a.start).format('X')-Moment(b.start).format('X');
                });
                const form_element = { ...update_application_form["rescheduledate"] };
                form_element.value = payload.eventtime;
                update_application_form['rescheduledate'] = form_element;
                update_application_form.availabletime.options = startarray;
                setapplication_form(update_application_form);


            }
            else {
                let startarray = [];
                const form_element = { ...update_application_form["rescheduledate"] };
                form_element.value = payload.eventtime;
                update_application_form['rescheduledate'] = form_element;
                update_application_form.availabletime.options = startarray;
                setapplication_form(update_application_form);

            }
        } catch (e) {
        }
    };

    return (
        <form name="normal_login" className="login-form" onSubmit={SubmitHandler}>
            <div className="col-lg-12 fv-row fv-plugins-icon-container">
                <label className=" col-form-label required fw-bold fs-6">Date</label>
                <DatePicker
                    peekNextMonth
                    showYearDropdown
                    showMonthDropdown
                    minDate={new Date()}
                    placeholder="Select a Date"
                    className="form-control date-picker-outer"
                    dateFormat="dd/MM/yyyy"
                    selected={application_form.rescheduledate && application_form.rescheduledate.value ? application_form.rescheduledate.value : ""}

                    onChange={(event) => inputChangeHandler(event, "rescheduledate")}

                />
            </div>
            <div className="col-lg-12 fv-row fv-plugins-icon-container">
                <label className=" col-form-label required fw-bold fs-6">Time</label>
                <Select
                    styles={customStyles}
                    name={"availabletime"}
                    className="react-bootstrap-typeahead tag-outer"
                    placeholder="Select a Time"
                    options={application_form.availabletime && application_form.availabletime.options ? application_form.availabletime.options : ""}
                    value={application_form.availabletime && application_form.availabletime.options.filter(function (option) {
                        return option.value === application_form.availabletime.value;
                    })}
                    onChange={newValue => inputChangeHandler(newValue, "availabletime")}
                />
            </div>

            <div className="model text-right mt-3">
                <button type="submit" className='btn btn-sm btn-primary fw-bolder mx-3'>Submit</button>

            </div>
        </form>
    );
}
export default InterviewReSchedule;