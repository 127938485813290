import React, { useEffect, useState, useCallback } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Moment from "moment";
// import { Form, Input, Select, notification } from "antd";
import { Modal, notification } from "antd";
import { GetAssignMember, getApplication, addComment, rejectApplication, acceptFlaggedApplication, reScheduleInterview, acceptInterviewFlaggedApplication } from "../../Api/user/userapi";
// import AssignApplicationUser from "./AssignApplicationUser";

import LeftSideBar from "../../Constant/LeftSideBar";
import TopBar from "../../Constant/TopBar";
import Footer from "../../Constant/Footer";
import Validations from "../Utility/Validations";
import RejectApplication from "../Applications/Modal/RejectApplication";
import AcceptApplication from "../Applications/Modal/AcceptApplication";
import InterviewReSchedule from "../Interviews/Modal/InterviewReSchedule"

const FlaggApplicationReview = (props) => {
    let history = useHistory();
    const [applicationdetaildata, setArray] = useState([]);
    const [Assignuserdata, setAssignuserdata] = useState([]);
    const [isAssignModalVisible, setIsAssignModalVisible] = useState(false);
    const [application_response_tabs, setapplication_response_tabs] = useState("application_que_hide");
    const [viewmoreform, setviewmoreform] = useState("View More");
    const [application_response_text, setapplication_response_text] = useState("application_que_show");
    const [applicationformreply, setapplicationformreply] = useState("hide");
    const [replyapplicationformreply, setreplyapplicationformreply] = useState("show");
    const [application_form, setapplication_form] = useState([]);
    const [is_form_valid, setis_form_valid] = useState(false);
    const [apptype, setapptype] = useState("");

    const [getapplication_form, setgetapplication_form] = useState([]);
    const [getclarification_received, setgetclarification_received] = useState([]);
    const [getassign_for_review, setgetassign_for_review] = useState([]);
    const [getreview_application, setgetreview_application] = useState([]);
    const [getapplication_accepted, setgetapplication_accepted] = useState([]);
    const [getfinal_review, setgetfinal_review] = useState([]);
    const [getinterview_round, setgetinterview_round] = useState([]);
    const [getinterview_round_2, setgetinterview_round_2] = useState([]);

    const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
    const [isAcceptModalVisible, setIsAcceptModalVisible] = useState(false);
    const [isRescheduleModalVisible, setIsRescheduleModalVisible] = useState(false);
    const [editChapterData, setEditChapterData] = useState({});
    const [Plagiarismresponcedata, setPlagiarismresponcedata] = useState([]);
    const [id, setId] = useState();
    const [arrayulrvalue, setarrayulrvalue] = useState('');

    const { applicaionId } = useParams();

    // const answer = props.location.search;
    const user_info = JSON.parse(localStorage.getItem("gpt_authuser"));
    const user_role = JSON.parse(localStorage.getItem("gpt_user_role"));

    const handleCancel = () => {
        setIsAssignModalVisible(false);
        setIsRejectModalVisible(false);
        setIsAcceptModalVisible(false);
        setIsRescheduleModalVisible(false);
    };
    const showRejectModalHandle = (data) => {
        setIsRejectModalVisible(true);
        setEditChapterData(data);
        setId(data._id);
    };
    const showAcceptModalHandle = (data) => {
        setIsAcceptModalVisible(true);
        setEditChapterData(data);
        setId(data._id);
    };

    const showRescheduleModalHandle = (data) => {
        setIsRescheduleModalVisible(true);
        setEditChapterData(data);
        setId(data._id);
    };

    const userDetails = useCallback(async () => {
        try {
            const queryParamsapp = new URLSearchParams(window.location.search);
            const arrayulr = [];
            var gender = '';
            var age = '';
            var search = '';
            var status = '';
            var tagid = '';
            var order = 'desc';
            var sortBy = '_id';
            var userType = 'admin';
            var applicationType = 'all';
            var memberId = '';
            if (queryParamsapp.get("gender")) {
                gender = queryParamsapp.get("gender")
                arrayulr.push({
                    value: gender,
                    label: 'gender'
                })
            }
            if (queryParamsapp.get("age")) {
                age = queryParamsapp.get("age")
                arrayulr.push({
                    value: age,
                    label: 'age'
                })
            }
            if (queryParamsapp.get("search")) {
                search = queryParamsapp.get("search")
                arrayulr.push({
                    value: search,
                    label: 'search'
                })
            }
            if (queryParamsapp.get("status")) {
                status = queryParamsapp.get("status")
                arrayulr.push({
                    value: status,
                    label: 'status'
                })
            }
            if (queryParamsapp.get("tagid")) {
                tagid = queryParamsapp.get("tagid")
                arrayulr.push({
                    value: tagid,
                    label: 'tagid'
                })
            }

            if (queryParamsapp.get("order")) {
                order = queryParamsapp.get("order")
                arrayulr.push({
                    value: order,
                    label: 'order'
                })
            }
            if (queryParamsapp.get("sortBy")) {
                sortBy = queryParamsapp.get("sortBy")
                arrayulr.push({
                    value: sortBy,
                    label: 'sortBy'
                })
            }
            if (queryParamsapp.get("applicationType")) {
                applicationType = queryParamsapp.get("applicationType")
                arrayulr.push({
                    value: applicationType,
                    label: 'applicationType'
                })
            }
            var urlfilter = '';
            if (arrayulr.length > 0) {
                urlfilter = arrayulr.map(({ value, label }) => `${label}=${value}`).join('&');
                urlfilter = '?' + urlfilter;
            }

            if (user_role && user_role.Allapplication !== "yes") {
                userType = 'all';
                memberId = user_info._id;
            }
            setarrayulrvalue(urlfilter);
            const Payload = {
                applicaionId: applicaionId,
                gender: gender,
                age: age,
                search: search,
                status: status,
                tagid: tagid,
                order: order,
                sortBy: sortBy,
                userType: userType,
                applicationType: applicationType,
                memberId: memberId,
                timeZoneMemberId: user_info._id
            };
            const resp = await getApplication(Payload);
            if (resp && resp.status === 200) {
                const list = resp;
                const arrayData = list.data.data;
                const resultgetapplication_form = [];
                const resultgetclarification_received = [];
                const resultgetassign_for_review = [];
                const resultgetreview_application = [];
                const resultgetapplication_accepted = [];
                const resultgetfinal_review = [];
                const resultgetinterview_round = [];
                const resultgetinterview_round_2 = [];
                if (arrayData["commentsdata"][0]) {
                    const Commentsdatas = arrayData["commentsdata"];
                    for (let key in Commentsdatas) {
                        if (Commentsdatas[key]["type"] === "application_form") {
                            resultgetapplication_form.push(Commentsdatas[key]);
                        } else if (Commentsdatas[key]["type"] === "clarification_received") {
                            resultgetclarification_received.push(Commentsdatas[key]);
                        } else if (Commentsdatas[key]["type"] === "assign_for_review") {
                            resultgetassign_for_review.push(Commentsdatas[key]);
                        } else if (Commentsdatas[key]["type"] === "review_application") {
                            resultgetreview_application.push(Commentsdatas[key]);
                        } else if (Commentsdatas[key]["type"] === "application_accepted") {
                            resultgetapplication_accepted.push(Commentsdatas[key]);
                        } else if (Commentsdatas[key]["type"] === "final_review") {
                            resultgetfinal_review.push(Commentsdatas[key]);
                        } else if (Commentsdatas[key]["type"] === "interview_round") {
                            resultgetinterview_round.push(Commentsdatas[key]);
                        } else if (Commentsdatas[key]["type"] === "interview_round_2") {
                            resultgetinterview_round_2.push(Commentsdatas[key]);
                        }
                    }
                }
                if (arrayData["reportsdata"][0]) {
                    const reportsdata = arrayData["reportsdata"][0];
                    let Plagiarismdata = [];
                    for (let key in reportsdata) {
                        if (reportsdata[key]["html"]) {
                            const resultdata = reportsdata[key]["html"].replace("", "\n");
                            Plagiarismdata.push({
                                order: reportsdata[key]["order"],
                                html: resultdata,
                            });
                        }
                        else {
                            Plagiarismdata.push({
                                order: reportsdata[key]["order"],
                                html: '',
                            });
                        }
                    }
                    setPlagiarismresponcedata(Plagiarismdata);
                }
                setgetapplication_form(resultgetapplication_form);
                setgetclarification_received(resultgetclarification_received);
                setgetassign_for_review(resultgetassign_for_review);
                setgetreview_application(resultgetreview_application);
                setgetapplication_accepted(resultgetapplication_accepted);
                setgetfinal_review(resultgetfinal_review);
                setgetinterview_round(resultgetinterview_round);
                setgetinterview_round_2(resultgetinterview_round_2);
                setArray(arrayData);
            }
        } catch (e) { }
    }, [applicaionId]);
    useEffect(() => {
        // super(props);
        setapplication_form({
            comment: {
                label: "Comment",
                type: "text",
                value: "",
                valid: true,
                error_msg: "",
                validations: { required: false },
            },
            type: {
                type: "hidden",
                label: "Bid name",
                value: "",
                valid: true,
                error_msg: "",
                onBlur_out: false,
                placeholder: "",
                validations: { required: false },
            },
            applicationid: {
                type: "hidden",
                label: "",
                value: "",
                valid: true,
                error_msg: "",
                onBlur_out: false,
                placeholder: "",
                validations: { required: false },
            },
            userid: {
                type: "hidden",
                label: "",
                value: "",
                valid: true,
                error_msg: "",
                onBlur_out: false,
                placeholder: "",
                validations: { required: false },
            },
            uesrname: {
                type: "hidden",
                label: "",
                value: "",
                valid: true,
                error_msg: "",
                onBlur_out: false,
                placeholder: "",
                validations: { required: false },
            },
            created_id: {
                value: user_info._id,
                validations: { required: false },
            },
        });
        userDetails();
    }, [userDetails]);
    async function AcceptApplicationSubmit(value) {
        const payload = {
            applicaionId: value,
            created_id: user_info._id,
            memberId: user_info._id,
        };
        try {
            const resp = await acceptFlaggedApplication(payload);
            if (resp && resp.data.success === true) {
                history.push("/application-detail/" + value);
                setTimeout(() => {
                    notification.open({
                        message: "Application",
                        description: "Application accepted successfully",
                    });
                }, 1000);
                userDetails();
                setIsAcceptModalVisible(false);
            } else if (resp && resp.data.success === false) {
                setTimeout(() => {
                    notification.open({
                        message: "Application",
                        description: resp.data.message,
                    });
                }, 1000);
            }
        } catch (e) {
            console.log(e);
        }
    }
    async function RejectApplicationSubmit(value) {
        const payload = {
            applicaionId: value,
            memberId: user_info._id,
            created_id: user_info._id,
        };
        try {
            const resp = await rejectApplication(payload);
            if (resp && resp.data.success === true) {
                history.push("/application");
                setTimeout(() => {
                    notification.open({
                        message: "Flagged Application",
                        description: "Application rejected successfully",
                    });
                }, 1000);
                userDetails();
                setIsRejectModalVisible(false);
            } else if (resp && resp.data.success === false) {
                setTimeout(() => {
                    notification.open({
                        message: "Application",
                        description: resp.data.message,
                    });
                }, 1000);
            }
        } catch (e) {
            console.log(e);
        }
    }
    const showAssignModalHandle = (id) => {
        setIsAssignModalVisible(true);
        GetAssignMemberdata(id);
    };
    const HideShowApplicationForm = (data) => {
        if (data === "application_que_hide") {
            setviewmoreform("View More");
            setapplication_response_text("application_que_show");
        } else {
            setviewmoreform("View Less");
            setapplication_response_text("application_que_hide");
        }
        setapplication_response_tabs(data);
    };
    const showapplicationreply = (data, type) => {
        if (data === "hide") {
            setreplyapplicationformreply("show");
        } else {
            setreplyapplicationformreply("hide");
        }
        setapplicationformreply(data);
        setapptype(type);
    };

    async function GetAssignMemberdata(id) {
        const Payload = {
            staapplicaionIdtus: id,
            created_id: user_info._id,
        };
        try {
            const resp = await GetAssignMember(Payload);
            if (resp && resp.status === 200) {
                const list = resp;
                const arrayData = list.data.data;
                setAssignuserdata(arrayData);
            }
        } catch (e) { }
    }
    async function inputChangeHandler(event, identifier) {
        const update_application_form = { ...application_form };
        const form_element = { ...update_application_form["comment"] };

        form_element.value = event.target.value;

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_application_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_application_form) {
            is_form_valid = update_application_form[identifier].valid && is_form_valid;
        }

        setapplication_form(update_application_form);
        setis_form_valid(is_form_valid);
    }
    // async SubmitHandler = (event, resultvalue) => {
    async function SubmitHandler(event, resultvalue) {
        event.preventDefault();
        let is_form_valid = true;
        // const applicationid = applicationid;
        const update_application_form = application_form;
        for (let key in update_application_form) {
            let form_element = update_application_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;
            if (key === resultvalue) {
                update_application_form["comment"] = form_element;
            }
            update_application_form[key] = form_element;
        }
        update_application_form.applicationid.value = applicaionId;
        update_application_form.userid.value = user_info["_id"];
        update_application_form.uesrname.value = user_info["userName"];
        update_application_form.type.value = resultvalue;
        setapplication_form(update_application_form);

        if (is_form_valid) {
            const form_data = {};
            for (let key in application_form) {
                // let form_element = application_form[key].value;
                form_data[key] = application_form[key].value;
            }
            try {
                if (form_data.comment === "") {
                    notification.open({
                        message: "Comment",
                        description: "Please add comment.",
                    });
                } else {
                    // setLoading(true);
                    const result = await addComment(form_data);
                    if (result && result.status === 200) {
                        setTimeout(() => {
                            notification.open({
                                message: "Comment",
                                description: "Added Successfully.",
                            });
                            const update_application_form_last = { ...application_form };
                            for (let key in update_application_form_last) {
                                update_application_form_last[key].value = "";
                            }
                            setapplication_form(update_application_form_last);
                        }, 1000);
                        userDetails();
                    } else if (result && result.status === 201) {
                        setTimeout(() => {
                            notification.open({
                                message: "Application",
                                description: "email/username already registered",
                            });
                        }, 1000);
                    }
                }
            } catch (e) {
            } finally {
            }
        }
    }

    async function AcceptInterviewApplicationSubmit(applicaionId) {
        const acceptPayload = {
            applicaionId: applicaionId,
            created_id: user_info._id,
            memberId: user_info._id,
        };
        try {
            const resp = await acceptInterviewFlaggedApplication(acceptPayload);
            if (resp && resp.data.success === true) {
                history.push("/application-detail/" + applicaionId);
                userDetails();
                setIsRescheduleModalVisible(false);
                setTimeout(() => {
                    notification.open({
                        message: "Re-schedule",
                        description: 'Interview Re-scheduled successfully',
                    });
                }, 1000);
            } else if (resp && resp.data.success === false) {
                setTimeout(() => {
                    notification.open({
                        message: "Application",
                        description: resp.data.message,
                    });
                }, 1000);
            }
        } catch (e) {
            console.log(e);
        }
    }

    async function ReScheduleApplicationSubmit(payload) {
        try {
            const resp = await reScheduleInterview(payload);
            if (resp.data.success !== false) {
                await AcceptInterviewApplicationSubmit(applicaionId)
            }
            else {
                setTimeout(() => {
                    notification.open({
                        message: "Re-schedule",
                        description: resp.data.message,
                    });
                }, 1000);
                // setLoading(false);
            }
        } catch (e) { }
    }

    // const { application_form } = this.state;
    return (
        <div className="d-flex flex-column flex-root header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed h-100">
            <div className="page d-flex flex-row flex-column-fluid">
                <LeftSideBar />
                <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                    <TopBar />

                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                        <div className="toolbar" id="kt_toolbar">
                            <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                                <div
                                    data-kt-swapper="true"
                                    data-kt-swapper-mode="prepend"
                                    data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
                                    className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
                                >
                                    <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
                                        Flagged Applications
                                        <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                                    </h1>

                                    <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                                        <li className="breadcrumb-item text-muted">
                                            <Link to="/dashboard" className="text-muted text-hover-primary">
                                                Home
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <span className="bullet bg-gray-200 w-5px h-2px"></span>
                                        </li>
                                        <li className="breadcrumb-item text-muted">
                                            <Link to="/flagged-application" className="text-muted text-hover-primary">
                                                Flagged Application
                                            </Link>

                                        </li>
                                        <li className="breadcrumb-item">
                                            <span className="bullet bg-gray-200 w-5px h-2px"></span>
                                        </li>

                                        <li className="breadcrumb-item text-dark">Candidate Details</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="post d-flex flex-column-fluid" id="kt_post">
                            <div id="kt_content_container" className="container-xxl">
                                {/* <Form name="normal_login" className="login-form" initialValues={{ remember: true }}> */}
                                <div className="row gy-5 g-xl-8">
                                    <div className="col-xl-12">
                                        <div className="card card-xl-stretch mb-8">
                                            <div className="card-header border-0">
                                                <h3 className="card-title fw-bolder text-dark">Candidate Details</h3>
                                                <div className="card-toolbar my-1">
                                                    <div className="me-3 app-next-last btn-sm ">
                                                        {applicationdetaildata.previous && (
                                                            <Link to={`/flagged-application-review/${applicationdetaildata.previous}${arrayulrvalue}`}><i className="fa fa-arrow-circle-left me-3 fs-2" aria-hidden="true"></i></Link>
                                                        )}
                                                        {applicationdetaildata.next && (
                                                            <Link to={`/flagged-application-review/${applicationdetaildata.next}${arrayulrvalue}`}><i className="fa fa-arrow-circle-right me-3 fs-2" aria-hidden="true"></i></Link>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body pt-2">
                                                <div className="row">
                                                    <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                        <label className="col-form-label fw-bold fs-6">Name</label>
                                                        <div className="form-control form-control-lg form-control-solid mb-3 mb-lg-0">
                                                            {applicationdetaildata.firstname ? applicationdetaildata.firstname : ""} {applicationdetaildata.lastname ? applicationdetaildata.lastname : ""}
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                        <label className="col-form-label fw-bold fs-6">Email</label>
                                                        <input
                                                            type="email"
                                                            name="fname"
                                                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                            value={applicationdetaildata.email ? applicationdetaildata.email : ""}
                                                            readOnly
                                                        />
                                                    </div>

                                                    <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                        <label className="col-form-label  fw-bold fs-6">Applied For</label>
                                                        <input
                                                            type="text"
                                                            name="fname"
                                                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                            value={applicationdetaildata.TagName ? applicationdetaildata.TagName : ""}
                                                            readOnly
                                                        />
                                                    </div>
                                                    <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                        <label className="col-form-label  fw-bold fs-6">Age</label>
                                                        <input type="text" name="fname" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" value={applicationdetaildata.age ? applicationdetaildata.age : ""} readOnly />
                                                    </div>
                                                    <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                        <label className="col-form-label  fw-bold fs-6">Gender</label>
                                                        <input
                                                            type="text"
                                                            name="fname"
                                                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                            value={applicationdetaildata.gender ? applicationdetaildata.gender : ""}
                                                            readOnly
                                                        />
                                                    </div>
                                                    {/* <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                            <label className="col-form-label  fw-bold fs-6">Country</label>
                                                            <input readOnly type="text" name="fname" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="India" readOnly />
                                                        </div> */}

                                                    <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                        <label className="col-form-label  fw-bold fs-6">Phone Number</label>
                                                        <input
                                                            type="text"
                                                            name="fname"
                                                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                            value={applicationdetaildata.phone ? applicationdetaildata.phone : ""}
                                                            readOnly
                                                        />
                                                    </div>
                                                    <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                        <label className="col-form-label  fw-bold fs-6">Date</label>
                                                        <input
                                                            type="text"
                                                            name="fname"
                                                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                            value={applicationdetaildata.createdAt ? Moment(applicationdetaildata.createdAt).format("DD-MM-YYYY") : ""}
                                                            readOnly
                                                        />
                                                    </div>

                                                    {applicationdetaildata.image && (
                                                        <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                            <label className="col-form-label  fw-bold fs-6">Resume</label>
                                                            <span className="doc-main">
                                                                {applicationdetaildata.image ? applicationdetaildata.image.split('/').pop() : ""}
                                                                <Link to={{ pathname: applicationdetaildata.image }} target="_blank" download>
                                                                    {" "}
                                                                    Download
                                                                </Link>
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* start que form */}
                                <div className="row gy-5 g-xl-8">
                                    <div className="col-xl-12">
                                        <div className="card card-xl-stretch mb-8">
                                            <div className="card-header border-0">
                                                <h3 className="card-title fw-bolder text-dark">Progress</h3>

                                                <div className="card-toolbar mt-2">
                                                    <div className="me-6 my-1">
                                                        <div className="symbol pdf-doc ">
                                                            {/* <Link className="me-4" to="#" download="">
                                                                <img src="./assets/media/svg/files/pdf-donload.png" alt="" />
                                                                Download Pdf
                                                            </Link>

                                                            <span className="send-email-txt">
                                                                <Link to="mailto: girlspowertalk@example.com">
                                                                    <i className="bi bi-envelope-fill"></i> Send Email
                                                                </Link>
                                                            </span> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body pt-2">
                                                    <div className="row">
                                                        <div className="timeline timeline-new-one">
                                                            <div className="timeline-items">
                                                                <div className="timeline-item">
                                                                    <div className="timeline-media">
                                                                        <span className="fs-1 fw-bolder date-one">{applicationdetaildata.createdAt ? Moment(applicationdetaildata.createdAt).format("DD") : ""} </span>
                                                                        <br />
                                                                        <span className="opacity-50 fs-8 fw-bold">{applicationdetaildata.createdAt ? Moment(applicationdetaildata.createdAt).format("MMM YYYY") : ""}</span>
                                                                    </div>
                                                                    <div className="timeline-content">
                                                                        <div className="d-flex align-items-center justify-content-between mb-3">
                                                                            <div className="mr-2">
                                                                                <h3 className="text-dark-75 text-hover-primary font-weight-bold">Application Form</h3>
                                                                                <span className="text-muted ml-2">{applicationdetaildata.createdAt ? Moment(applicationdetaildata.createdAt).format("h:mm a") : ""}</span>
                                                                            </div>
                                                                        </div>

                                                                        <h5>Q1: How did you learn about Girl Power Talk? If you were referred by someone, please provide their full name and your relationship to that person.</h5>
                                                                        <p className="p-0">{applicationdetaildata.learnabout ? applicationdetaildata.learnabout : ""}</p>
                                                                        {/* <p className="p-0">{applicationdetaildata.learnabout ? applicationdetaildata.learnabout : ""}</p> */}
                                                                        {/* {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                            <>
                                                                                {Plagiarismresponcedata.map((item, idx) => (
                                                                                    <div key={idx}>
                                                                                        {item.order && item.order === 1 && (
                                                                                            <>
                                                                                                {item.html && item.html !== '' ? (
                                                                                                    <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />
                                                                                                ) : (
                                                                                                    <>
                                                                                                        {applicationdetaildata.learnabout ? applicationdetaildata.learnabout : ""}
                                                                                                    </>
                                                                                                )}


                                                                                            </>
                                                                                        )}
                                                                                        </div>
                                                                                ))}
                                                                            </>
                                                                        ) : (
                                                                            <p className="p-0">{applicationdetaildata.learnabout ? applicationdetaildata.learnabout : ""}</p>
                                                                        )} */}
                                                                        {application_response_tabs === "application_que_show" ? (
                                                                            <>
                                                                                <div className="que_ans">
                                                                                    <h5>Q2: Name one or more people, living or deceased, that you admire, and explain why.</h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.mentor ? applicationdetaildata.mentor : ""}</p> */}

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx1) => (
                                                                                                <div key={idx1}>
                                                                                                    {item.order && item.order === 2 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.mentor ? applicationdetaildata.mentor : ""}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>
                                                                                        Q3: Leadership comes in many forms, and we value diversity in leadership. What do you feel are your strongest leadership qualities? Please share one or
                                                                                        more examples of times when you have applied them in your life.
                                                                                    </h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.admireinhistory ? applicationdetaildata.admireinhistory : ""}</p> */}

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx2) => (
                                                                                                <div key={idx2}>
                                                                                                    {item.order && item.order === 3 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.admireinhistory ? applicationdetaildata.admireinhistory : ""}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>Q4: What are your biggest strengths? Name at least two, and explain how you would use them to make the world better.</h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.leadership ? applicationdetaildata.leadership : ""}</p> */}

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx4) => (
                                                                                                <div key={idx4}>
                                                                                                    {item.order && item.order === 4 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.leadership ? applicationdetaildata.leadership : ""}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>
                                                                                        Q5: What are your most significant weaknesses? Name at least two, and explain how you mitigate them. Everyone has weaknesses, so please be honest in
                                                                                        sharing yours here.
                                                                                    </h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.exerciseleadership ? applicationdetaildata.exerciseleadership : ""}</p> */}

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx5) => (
                                                                                                <div key={idx5}>
                                                                                                    {item.order && item.order === 5 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.exerciseleadership ? applicationdetaildata.exerciseleadership : ""}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>
                                                                                        Q6: Write a brief autobiography of your life. Feel free to provide a truthful account or be imaginative and creative. A minimum of 200 words is kindly
                                                                                        requested.
                                                                                    </h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.biggeststrength ? applicationdetaildata.biggeststrength : ""}</p> */}

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx6) => (
                                                                                                <div key={idx6}>
                                                                                                    {item.order && item.order === 6 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.biggeststrength ? applicationdetaildata.biggeststrength : ""}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>Q7: Do you have any personal or professional mentors? If so, please explain who and why.</h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.autobiography ? applicationdetaildata.autobiography : ""}</p> */}

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx7) => (
                                                                                                <div key={idx7}>
                                                                                                    {item.order && item.order === 7 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.autobiography ? applicationdetaildata.autobiography : ""}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>Q8: Please share your favorite quotation, and explain what it means to you personally and/or professionally.</h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.favoritequote ? applicationdetaildata.favoritequote : ""}</p> */}
                                                                                    <p className="p-0">{applicationdetaildata.favoritequote ? applicationdetaildata.favoritequote : ""}</p>
                                                                                    {/* {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx8) => (
                                                                                                <div key={idx8}>
                                                                                                    {item.order && item.order === 8 && (
                                                                                                        <>
                                                                                                            {item.html && item.html !== '' ? (
                                                                                                                <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />
                                                                                                            ) : (
                                                                                                                <>
                                                                                                                    {applicationdetaildata.favoritequote ? applicationdetaildata.favoritequote : ""}
                                                                                                                </>
                                                                                                            )}


                                                                                                        </>
                                                                                                    )}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.favoritequote ? applicationdetaildata.favoritequote : ""}</p>
                                                                                    )} */}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>Q9: What does confidence mean to you?</h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.confidence ? applicationdetaildata.confidence : ""}</p> */}

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx9) => (
                                                                                                <div key={idx9}>
                                                                                                    {item.order && item.order === 9 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.confidence ? applicationdetaildata.confidence : ""}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>Q10: Tell us about a challenge or conflict you’ve faced or are currently facing. How did/do you deal with it?</h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.conflict ? applicationdetaildata.conflict : ""}</p> */}

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx10) => (
                                                                                                <div key={idx10}>
                                                                                                    {item.order && item.order === 10 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.conflict ? applicationdetaildata.conflict : ""}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>
                                                                                        Q11: The Roman Stoic philosopher Seneca (4 BC–65 AD) said, “Our fears are always more numerous than our dangers.” What is your biggest fear, and why?
                                                                                    </h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.gretestachivement ? applicationdetaildata.gretestachivement : ""}</p> */}

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx11) => (
                                                                                                <div key={idx11}>
                                                                                                    {item.order && item.order === 11 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.gretestachivement ? applicationdetaildata.gretestachivement : ""}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>Q12: How do you deal with anxiety or pressure?</h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.skills ? applicationdetaildata.skills : ""}</p> */}

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx12) => (
                                                                                                <div key={idx12}>
                                                                                                    {item.order && item.order === 12 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.skills ? applicationdetaildata.skills : ""}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>Q13: What has been your greatest achievement to date, either personally or professionally?</h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.fear ? applicationdetaildata.fear : ""}</p> */}

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx13) => (
                                                                                                <div key={idx13}>
                                                                                                    {item.order && item.order === 13 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.fear ? applicationdetaildata.fear : ""}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>Q14: What skills do you hope to develop if you join Girl Power Talk?</h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.weakness ? applicationdetaildata.weakness : ""}</p> */}

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx14) => (
                                                                                                <div key={idx14}>
                                                                                                    {item.order && item.order === 14 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.weakness ? applicationdetaildata.weakness : ""}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>
                                                                                        Q15: What do you feel is the most concerning issue for women today in the world or your home country? How would you work to drive change in this area if
                                                                                        you were afforded the necessary power and resources?
                                                                                    </h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.womenissue ? applicationdetaildata.womenissue : ""}</p> */}

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx15) => (
                                                                                                <div key={idx15}>
                                                                                                    {item.order && item.order === 15 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.womenissue ? applicationdetaildata.womenissue : ""}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>Q16: What is your favorite book, and why?</h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.favoritebook ? applicationdetaildata.favoritebook : ""}</p> */}

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx16) => (
                                                                                                <div key={idx16}>
                                                                                                    {item.order && item.order === 16 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.favoritebook ? applicationdetaildata.favoritebook : ""}</p>
                                                                                    )}
                                                                                </div>

                                                                                <div className="que_ans">
                                                                                    <h5>Q17: Share one interesting fact about yourself that we might not otherwise know in evaluating your candidacy to join Girl Power Talk.</h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.information ? applicationdetaildata.information : ""}</p> */}

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx17) => (
                                                                                                <div key={idx17}>
                                                                                                    {item.order && item.order === 17 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.information ? applicationdetaildata.information : ""}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>Q18: Name a woman other than your mother or sister who has influenced your life. Describe how she has done so.</h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.interstingfacts ? applicationdetaildata.interstingfacts : ""}</p> */}

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx18) => (
                                                                                                <div key={idx18}>
                                                                                                    {item.order && item.order === 18 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.interstingfacts ? applicationdetaildata.interstingfacts : ""}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>
                                                                                        Q19: Anne Frank (1929–1945) wrote, “How wonderful it is that nobody need wait a single moment before starting to improve the world.” How does this
                                                                                        quotation resonate with you?
                                                                                    </h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.magicwand ? applicationdetaildata.magicwand : ""}</p> */}

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx19) => (
                                                                                                <div key={idx19}>
                                                                                                    {item.order && item.order === 19 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.magicwand ? applicationdetaildata.magicwand : ""}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>Q20: How do you spend most of your free time (e.g., hobbies, interests)?</h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.milliondollars ? applicationdetaildata.milliondollars : ""}</p> */}

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx20) => (
                                                                                                <div key={idx20}>
                                                                                                    {item.order && item.order === 20 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.milliondollars ? applicationdetaildata.milliondollars : ""}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>Q21: How would your closest friends and family members describe you?</h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.stressfulsitutations ? applicationdetaildata.stressfulsitutations : ""}</p> */}

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx21) => (
                                                                                                <div key={idx21}>
                                                                                                    {item.order && item.order === 21 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.stressfulsitutations ? applicationdetaildata.stressfulsitutations : ""}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>Q22: What excites you about being a part of Girl Power Talk?</h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.inspiringwoman ? applicationdetaildata.inspiringwoman : ""}</p> */}

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx22) => (
                                                                                                <div key={idx22}>
                                                                                                    {item.order && item.order === 22 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.inspiringwoman ? applicationdetaildata.inspiringwoman : ""}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>Q23: What is one creative idea you have for Girl Power Talk that could enhance our culture or business?</h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.quotemean ? applicationdetaildata.quotemean : ""}</p> */}

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx23) => (
                                                                                                <div key={idx23}>
                                                                                                    {item.order && item.order === 23 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.quotemean ? applicationdetaildata.quotemean : ""}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>Q24: Why should you be the next Young Leader at Girl Power Talk?</h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.outsideofwork ? applicationdetaildata.outsideofwork : ""}</p> */}

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx24) => (
                                                                                                <div key={idx24}>
                                                                                                    {item.order && item.order === 24 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.outsideofwork ? applicationdetaildata.outsideofwork : ""}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>Q25: What questions do you have for us?</h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.closestfriend ? applicationdetaildata.closestfriend : ""}</p> */}
                                                                                    <p className="p-0">{applicationdetaildata.closestfriend ? applicationdetaildata.closestfriend : ""}</p>
                                                                                    {/* {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx25) => (
                                                                                                <div key={idx25}>
                                                                                                    {item.order && item.order === 25 && (
                                                                                                        <>
                                                                                                            {item.html && item.html !== ''  ? (
                                                                                                                <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />
                                                                                                            ):(
                                                                                                                <>
                                                                                                                    {applicationdetaildata.closestfriend ? applicationdetaildata.closestfriend : ""}
                                                                                                                  </>  
                                                                                                            )}
                                                                                                            
                                                                                                    
                                                                                                    </>
                                                                                                    )}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.closestfriend ? applicationdetaildata.closestfriend : ""}</p>
                                                                                    )} */}
                                                                                </div>

                                                                                {/* <div className="que_ans">
                                                                                    <h5>
                                                                                        Q26: Why did you decide to complete the indication of interest for Girl Power Talk and our sister company, Blue Ocean Global Technology? In other words,
                                                                                        what inspires you to be part of the Girl Power Talk culture and why do you think you will be a good fit?
                                                                                    </h5>

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx26) => (
                                                                                                <div key={idx26}>{item.order && item.order === 26 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (<p className="p-0">{applicationdetaildata.intrestofgirlpower ? applicationdetaildata.intrestofgirlpower : ""}</p>)}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>Q27: Do you have any questions for us? Please feel free to ask them here.</h5>

                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx27) => (
                                                                                                <div key={idx27}>{item.order && item.order === 27 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (<p className="p-0">{applicationdetaildata.anyquestion ? applicationdetaildata.anyquestion : ""}</p>)}
                                                                                </div> */}

                                                                                <div className="mb-4">
                                                                                    {/* {(user_role && user_role.flaggedAccept && user_role.flaggedAccept === "yes") && (
                                                                                    <span to="/#" className="btn btn-sm btn-primary btn-active-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_invite_friends">
                                                                                        {" "}
                                                                                        Accept
                                                                                    </span>
                                                                                     )}
                                                                                     {(user_role && user_role.flaggedReject && user_role.flaggedReject === "yes") && (
                                                                                    <span to="/#" className="ms-2 btn btn-sm btn-light-primary fw-bolder">
                                                                                        Reject
                                                                                    </span>
                                                                                     )} */}
                                                                                </div>
                                                                                <div className="comment-post-main">
                                                                                    <span className="showreplymessage mb-3" onClick={() => showapplicationreply(replyapplicationformreply, "show_application_form")}>
                                                                                        <i className="bi bi-chat-square"></i>
                                                                                        <span>{getapplication_form.length}</span>
                                                                                    </span>
                                                                                    {applicationformreply === "show" && apptype === "show_application_form" ? (
                                                                                        <>
                                                                                            {getapplication_form.map((getapplication_formvalue) => (
                                                                                                <div className="replicationreply" key={getapplication_formvalue._id}>
                                                                                                    <div className="cursor-pointer symbol symbol-40px symbol-md-50px reply_user_pic">
                                                                                                        <img src={getapplication_formvalue.UserImage ? getapplication_formvalue.UserImage : "/assets/media/avatars/blank.png"} alt="user" />
                                                                                                        <div className="profile-user ms-2">
                                                                                                            <span className="replyusername">{getapplication_formvalue.UserName}</span>
                                                                                                            <span className="date_time">
                                                                                                                {getapplication_formvalue.createdAt ? Moment(getapplication_formvalue.createdAt).format("DD MMM YYYY, h:mm a") : ""}
                                                                                                            </span>
                                                                                                            <span className="replymessage">{getapplication_formvalue.comment}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                </div>
                                                                                {user_role && user_role.flaggedComment && user_role.flaggedComment === "yes" && (
                                                                                    <div className="comment-box mb-2">
                                                                                        <form className="form w-100" onSubmit={(event) => SubmitHandler(event, "application_form")}>
                                                                                            <textarea
                                                                                                key="application_form_1"
                                                                                                className="form-control form-control-lg mt-3 mb-3"
                                                                                                value={application_form.application_form && application_form.application_form.value ? application_form.application_form.value : ""}
                                                                                                onChange={(event) => inputChangeHandler(event, "application_form")}
                                                                                            ></textarea>

                                                                                            <button type="submit" id="kt_new_password_submit" className="btn btn-sm fw-bolder me-4">
                                                                                                <span className="indicator-label">Post Comment</span>
                                                                                            </button>
                                                                                        </form>
                                                                                    </div>
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                        <span onClick={() => HideShowApplicationForm(application_response_text)} className="myBtn_2 myBtn">
                                                                            {viewmoreform}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                {/* end Application Form */}
                                                                {/* Start Clarification Received */}
                                                                <div className="timeline-item">
                                                                    <div className="timeline-media">
                                                                        {!applicationdetaildata.clarificationdate ? (
                                                                            <>
                                                                                <span className="fs-1 fw-bolder date-one">{Moment(new Date()).format("DD")} </span>
                                                                                <br />
                                                                                <span className="opacity-50 fs-8 fw-bold">{Moment(new Date()).format("MMM YYYY")}</span>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <span className="fs-1 fw-bolder date-one">{applicationdetaildata.clarificationdate ? Moment(applicationdetaildata.clarificationdate).format("DD") : ""} </span>
                                                                                <br />
                                                                                <span className="opacity-50 fs-8 fw-bold">
                                                                                    {applicationdetaildata.clarificationdate ? Moment(applicationdetaildata.clarificationdate).format("MMM YYYY") : ""}
                                                                                </span>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                    {applicationdetaildata.setinterview === "1"
                                                                        ? (
                                                                            <div className="timeline-content">
                                                                                <div className="d-flex align-items-center justify-content-between mb-3">
                                                                                    <div className="mr-2">
                                                                                        <h3 className="text-dark-75 text-hover-primary font-weight-bold">Did't Show Up Clarification</h3>
                                                                                        {!applicationdetaildata.clarificationdate ? (
                                                                                            <>
                                                                                                <span className="text-muted ml-2">{Moment(new Date()).format("h:mm a")}</span>
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                <span className="text-muted ml-2">
                                                                                                    {applicationdetaildata.clarificationdate ? Moment(applicationdetaildata.clarificationdate).format("h:mm a") : ""}
                                                                                                </span>
                                                                                            </>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                                <p>{applicationdetaildata.clarification ? applicationdetaildata.clarification : ""}</p>
                                                                                <div className="align-items-center mb-5 mt-5">
                                                                                    <h5>Assigned Interviewer </h5>
                                                                                    <div className="d-flex text-gray-600  fs-6 interviewr-outer">
                                                                                        <div className="d-flex align-items-center mb-5 mt-5 me-8">
                                                                                            <div className="me-4 position-relative">
                                                                                                <div className="symbol symbol-30px symbol-circle">
                                                                                                    <img
                                                                                                        alt="Pic"
                                                                                                        src={
                                                                                                            applicationdetaildata.application_interview.interview_one.interview_one_image
                                                                                                                ? applicationdetaildata.application_interview.interview_one.interview_one_image
                                                                                                                : "/assets/media/avatars/blank.png"
                                                                                                        }
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="fw-bold">
                                                                                                <div className="fs-7 text-gray-900">
                                                                                                    {applicationdetaildata.application_interview.interview_one.interview_one_name
                                                                                                        ? applicationdetaildata.application_interview.interview_one.interview_one_name
                                                                                                        : ""}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="d-flex align-items-center mb-5 mt-5 me-8">
                                                                                            <div className="me-2 position-relative">
                                                                                                <div className="symbol symbol-30px symbol-circle">
                                                                                                    <img
                                                                                                        alt="Pic"
                                                                                                        src={
                                                                                                            applicationdetaildata.application_interview.interview_two.interview_two_image
                                                                                                                ? applicationdetaildata.application_interview.interview_two.interview_two_image
                                                                                                                : "/assets/media/avatars/blank.png"
                                                                                                        }
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="fw-bold">
                                                                                                <div className="fs-7 text-gray-900">
                                                                                                    {applicationdetaildata.application_interview.interview_two.interview_two_name
                                                                                                        ? applicationdetaildata.application_interview.interview_two.interview_two_name
                                                                                                        : ""}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {applicationdetaildata.application_interview.interview_three.interview_three_name && (
                                                                                            <div className="d-flex align-items-center mb-5 mt-5 me-8">
                                                                                                <div className="me-4 position-relative">
                                                                                                    <div className="symbol symbol-30px symbol-circle">
                                                                                                        <img
                                                                                                            alt="Pic"
                                                                                                            src={
                                                                                                                applicationdetaildata.application_interview.interview_three.interview_three_image
                                                                                                                    ? applicationdetaildata.application_interview.interview_three.interview_three_image
                                                                                                                    : "/assets/media/avatars/blank.png"
                                                                                                            }
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="fw-bold">
                                                                                                    <div className="fs-7 text-gray-900">
                                                                                                        {applicationdetaildata.application_interview.interview_three.interview_three_name
                                                                                                            ? applicationdetaildata.application_interview.interview_three.interview_three_name
                                                                                                            : ""}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                                {applicationdetaildata && applicationdetaildata.status !== "Rejected" ? (
                                                                                    <div className="mb-4">
                                                                                        {user_role && user_role.flaggedAccept && user_role.flaggedAccept === "yes" && (
                                                                                            <span onClick={() => showRescheduleModalHandle(applicationdetaildata)} className="btn btn-sm btn-primary btn-active-primary">
                                                                                                Reschedule
                                                                                            </span>
                                                                                        )}
                                                                                        {user_role && user_role.flaggedReject && user_role.flaggedReject === "yes" && (
                                                                                            <span onClick={() => showRejectModalHandle(applicationdetaildata)} className="ms-2 btn btn-sm btn-light-primary fw-bolder">
                                                                                                Reject
                                                                                            </span>
                                                                                        )}
                                                                                    </div>
                                                                                ) : (
                                                                                    ""
                                                                                )}
                                                                                <div className="comment-post-main">
                                                                                    <span className="showreplymessage mb-3" onClick={() => showapplicationreply(replyapplicationformreply, "show_clarification_received")}>
                                                                                        <i className="bi bi-chat-square"></i>
                                                                                        <span>{getclarification_received.length}</span>
                                                                                    </span>
                                                                                    {applicationformreply === "show" && apptype === "show_clarification_received" ? (
                                                                                        <>
                                                                                            {getclarification_received.map((getclarification_receivedvalue) => (
                                                                                                <div className="replicationreply" key={getclarification_receivedvalue._id}>
                                                                                                    <div className="cursor-pointer symbol symbol-40px symbol-md-50px reply_user_pic">
                                                                                                        <img src={getclarification_receivedvalue.UserImage ? getclarification_receivedvalue.UserImage : "/assets/media/avatars/blank.png"} alt="user" />
                                                                                                        <div className="profile-user ms-2">
                                                                                                            <span className="replyusername">{getclarification_receivedvalue.UserName}</span>
                                                                                                            <span className="date_time">
                                                                                                                {getclarification_receivedvalue.createdAt ? Moment(getclarification_receivedvalue.createdAt).format("DD MMM YYYY, h:mm a") : ""}
                                                                                                            </span>
                                                                                                            <span className="replymessage">{getclarification_receivedvalue.comment}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                </div>
                                                                                {user_role && user_role.flaggedComment && user_role.flaggedComment === "yes" && (
                                                                                    <div className="comment-box mb-2">
                                                                                        <form className="form w-100" onSubmit={(event) => SubmitHandler(event, "clarification_received")}>
                                                                                            <textarea
                                                                                                key="clarification_received_1"
                                                                                                className="form-control form-control-lg mt-3 mb-3"
                                                                                                value={application_form.clarification_received && application_form.clarification_received.value ? application_form.clarification_received.value : ""}
                                                                                                onChange={(event) => inputChangeHandler(event, "clarification_received")}
                                                                                            ></textarea>

                                                                                            <button type="submit" id="kt_new_password_submit" className="btn btn-sm fw-bolder me-4">
                                                                                                <span className="indicator-label">Post Comment</span>
                                                                                            </button>
                                                                                        </form>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        ) : (
                                                                            <>
                                                                                {applicationdetaildata.isDirectReject === "1"
                                                                                    ? (
                                                                                        <>
                                                                                            {applicationdetaildata.palagrismstatus === "1" ? (
                                                                                                <div className="timeline-content">
                                                                                                    <div className="d-flex align-items-center justify-content-between mb-3">
                                                                                                        <div className="mr-2">
                                                                                                            <h3 className="text-dark-75 text-hover-primary font-weight-bold">Plagrism Reject</h3>
                                                                                                            {!applicationdetaildata.clarificationdate ? (
                                                                                                                <>
                                                                                                                    <span className="text-muted ml-2">{Moment(new Date()).format("h:mm a")}</span>
                                                                                                                </>
                                                                                                            ) : (
                                                                                                                <>
                                                                                                                    <span className="text-muted ml-2">
                                                                                                                        {applicationdetaildata.clarificationdate ? Moment(applicationdetaildata.clarificationdate).format("h:mm a") : ""}
                                                                                                                    </span>
                                                                                                                </>
                                                                                                            )}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <p>{applicationdetaildata.clarification ? applicationdetaildata.clarification : ""}</p>
                                                                                                    {applicationdetaildata && applicationdetaildata.status !== "Rejected" ? (
                                                                                                        <div className="mb-4">
                                                                                                            {user_role && user_role.flaggedAccept && user_role.flaggedAccept === "yes" && (
                                                                                                                <span onClick={() => showAcceptModalHandle(applicationdetaildata)} className="btn btn-sm btn-primary btn-active-primary">
                                                                                                                    Accept
                                                                                                                </span>
                                                                                                            )}
                                                                                                        </div>
                                                                                                    ) : (
                                                                                                        ""
                                                                                                    )}
                                                                                                    <div className="comment-post-main">
                                                                                                        <span className="showreplymessage mb-3" onClick={() => showapplicationreply(replyapplicationformreply, "show_clarification_received")}>
                                                                                                            <i className="bi bi-chat-square"></i>
                                                                                                            <span>{getclarification_received.length}</span>
                                                                                                        </span>
                                                                                                        {applicationformreply === "show" && apptype === "show_clarification_received" ? (
                                                                                                            <>
                                                                                                                {getclarification_received.map((getclarification_receivedvalue) => (
                                                                                                                    <div className="replicationreply" key={getclarification_receivedvalue._id}>
                                                                                                                        <div className="cursor-pointer symbol symbol-40px symbol-md-50px reply_user_pic">
                                                                                                                            <img src={getclarification_receivedvalue.UserImage ? getclarification_receivedvalue.UserImage : "/assets/media/avatars/blank.png"} alt="user" />
                                                                                                                            <div className="profile-user ms-2">
                                                                                                                                <span className="replyusername">{getclarification_receivedvalue.UserName}</span>
                                                                                                                                <span className="date_time">
                                                                                                                                    {getclarification_receivedvalue.createdAt ? Moment(getclarification_receivedvalue.createdAt).format("DD MMM YYYY, h:mm a") : ""}
                                                                                                                                </span>
                                                                                                                                <span className="replymessage">{getclarification_receivedvalue.comment}</span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                ))}
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            ""
                                                                                                        )}
                                                                                                    </div>
                                                                                                    {user_role && user_role.flaggedComment && user_role.flaggedComment === "yes" && (
                                                                                                        <div className="comment-box mb-2">
                                                                                                            <form className="form w-100" onSubmit={(event) => SubmitHandler(event, "clarification_received")}>
                                                                                                                <textarea
                                                                                                                    key="clarification_received_1"
                                                                                                                    className="form-control form-control-lg mt-3 mb-3"
                                                                                                                    value={application_form.clarification_received && application_form.clarification_received.value ? application_form.clarification_received.value : ""}
                                                                                                                    onChange={(event) => inputChangeHandler(event, "clarification_received")}
                                                                                                                ></textarea>

                                                                                                                <button type="submit" id="kt_new_password_submit" className="btn btn-sm fw-bolder me-4">
                                                                                                                    <span className="indicator-label">Post Comment</span>
                                                                                                                </button>
                                                                                                            </form>
                                                                                                        </div>
                                                                                                    )}
                                                                                                </div>
                                                                                            ) : (
                                                                                                <div className="timeline-content">
                                                                                                    <div className="d-flex align-items-center justify-content-between mb-3">
                                                                                                        <div className="mr-2">
                                                                                                            <h3 className="text-dark-75 text-hover-primary font-weight-bold">More then 5 Questions Blank</h3>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <p>{applicationdetaildata.clarification ? applicationdetaildata.clarification : ""}</p>
                                                                                                    {applicationdetaildata && applicationdetaildata.status !== "Rejected" ? (
                                                                                                        <div className="mb-4">
                                                                                                            {user_role && user_role.flaggedAccept && user_role.flaggedAccept === "yes" && (
                                                                                                                <span onClick={() => showAcceptModalHandle(applicationdetaildata)} className="btn btn-sm btn-primary btn-active-primary">
                                                                                                                    Accept
                                                                                                                </span>
                                                                                                            )}
                                                                                                        </div>
                                                                                                    ) : (
                                                                                                        ""
                                                                                                    )}
                                                                                                    <div className="comment-post-main">
                                                                                                        <span className="showreplymessage mb-3" onClick={() => showapplicationreply(replyapplicationformreply, "show_clarification_received")}>
                                                                                                            <i className="bi bi-chat-square"></i>
                                                                                                            <span>{getclarification_received.length}</span>
                                                                                                        </span>
                                                                                                        {applicationformreply === "show" && apptype === "show_clarification_received" ? (
                                                                                                            <>
                                                                                                                {getclarification_received.map((getclarification_receivedvalue) => (
                                                                                                                    <div className="replicationreply" key={getclarification_receivedvalue._id}>
                                                                                                                        <div className="cursor-pointer symbol symbol-40px symbol-md-50px reply_user_pic">
                                                                                                                            <img src={getclarification_receivedvalue.UserImage ? getclarification_receivedvalue.UserImage : "/assets/media/avatars/blank.png"} alt="user" />
                                                                                                                            <div className="profile-user ms-2">
                                                                                                                                <span className="replyusername">{getclarification_receivedvalue.UserName}</span>
                                                                                                                                <span className="date_time">
                                                                                                                                    {getclarification_receivedvalue.createdAt ? Moment(getclarification_receivedvalue.createdAt).format("DD MMM YYYY, h:mm a") : ""}
                                                                                                                                </span>
                                                                                                                                <span className="replymessage">{getclarification_receivedvalue.comment}</span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                ))}
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            ""
                                                                                                        )}
                                                                                                    </div>
                                                                                                    {user_role && user_role.flaggedComment && user_role.flaggedComment === "yes" && (
                                                                                                        <div className="comment-box mb-2">
                                                                                                            <form className="form w-100" onSubmit={(event) => SubmitHandler(event, "clarification_received")}>
                                                                                                                <textarea
                                                                                                                    key="clarification_received_1"
                                                                                                                    className="form-control form-control-lg mt-3 mb-3"
                                                                                                                    value={application_form.clarification_received && application_form.clarification_received.value ? application_form.clarification_received.value : ""}
                                                                                                                    onChange={(event) => inputChangeHandler(event, "clarification_received")}
                                                                                                                ></textarea>

                                                                                                                <button type="submit" id="kt_new_password_submit" className="btn btn-sm fw-bolder me-4">
                                                                                                                    <span className="indicator-label">Post Comment</span>
                                                                                                                </button>
                                                                                                            </form>
                                                                                                        </div>
                                                                                                    )}
                                                                                                </div>
                                                                                            )}
                                                                                        </>
                                                                                    ) : (
                                                                                        <div className="timeline-content">
                                                                                            <div className="d-flex align-items-center justify-content-between mb-3">
                                                                                                <div className="mr-2">
                                                                                                    <h3 className="text-dark-75 text-hover-primary font-weight-bold">Student clarification</h3>
                                                                                                    {!applicationdetaildata.clarificationdate ? (
                                                                                                        <>
                                                                                                            <span className="text-muted ml-2">{Moment(new Date()).format("h:mm a")}</span>
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            <span className="text-muted ml-2">
                                                                                                                {applicationdetaildata.clarificationdate ? Moment(applicationdetaildata.clarificationdate).format("h:mm a") : ""}
                                                                                                            </span>
                                                                                                        </>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                            <p>{applicationdetaildata.clarification ? applicationdetaildata.clarification : ""}</p>
                                                                                            {user_role.Allflaggedapplication && user_role.Allflaggedapplication === "yes" && applicationdetaildata.flagassignuser && applicationdetaildata.flagassignuser ? (
                                                                                                <div className="align-items-center mb-5 mt-5">
                                                                                                    <h5>Assigned Member </h5>
                                                                                                    <div className="d-flex text-gray-600 fs-6 interviewr-outer">
                                                                                                        <div className="d-flex align-items-center mb-5 mt-5 me-8">
                                                                                                            <div className="me-4 position-relative">
                                                                                                                <div className="symbol symbol-35px symbol-circle">
                                                                                                                    <img alt="Pic" src={applicationdetaildata.flagUImage ? applicationdetaildata.flagUImage : "/assets/media/avatars/blank.png"} />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="fw-bold">
                                                                                                                <div className="fs-5 text-gray-900">{applicationdetaildata.flagUName ? applicationdetaildata.flagUName : ""}</div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ) : (
                                                                                                ""
                                                                                            )}
                                                                                            {applicationdetaildata && applicationdetaildata.status !== "Rejected" ? (
                                                                                                <div className="mb-4">
                                                                                                    {user_role && user_role.flaggedAccept && user_role.flaggedAccept === "yes" && (
                                                                                                        <span onClick={() => showAcceptModalHandle(applicationdetaildata)} className="btn btn-sm btn-primary btn-active-primary">
                                                                                                            Accept
                                                                                                        </span>
                                                                                                    )}
                                                                                                    {user_role && user_role.flaggedReject && user_role.flaggedReject === "yes" && (
                                                                                                        <span onClick={() => showRejectModalHandle(applicationdetaildata)} className="ms-2 btn btn-sm btn-light-primary fw-bolder">
                                                                                                            Reject
                                                                                                        </span>
                                                                                                    )}
                                                                                                </div>
                                                                                            ) : (
                                                                                                ""
                                                                                            )}
                                                                                            <div className="comment-post-main">
                                                                                                <span className="showreplymessage mb-3" onClick={() => showapplicationreply(replyapplicationformreply, "show_clarification_received")}>
                                                                                                    <i className="bi bi-chat-square"></i>
                                                                                                    <span>{getclarification_received.length}</span>
                                                                                                </span>
                                                                                                {applicationformreply === "show" && apptype === "show_clarification_received" ? (
                                                                                                    <>
                                                                                                        {getclarification_received.map((getclarification_receivedvalue) => (
                                                                                                            <div className="replicationreply" key={getclarification_receivedvalue._id}>
                                                                                                                <div className="cursor-pointer symbol symbol-40px symbol-md-50px reply_user_pic">
                                                                                                                    <img src={getclarification_receivedvalue.UserImage ? getclarification_receivedvalue.UserImage : "/assets/media/avatars/blank.png"} alt="user" />
                                                                                                                    <div className="profile-user ms-2">
                                                                                                                        <span className="replyusername">{getclarification_receivedvalue.UserName}</span>
                                                                                                                        <span className="date_time">
                                                                                                                            {getclarification_receivedvalue.createdAt ? Moment(getclarification_receivedvalue.createdAt).format("DD MMM YYYY, h:mm a") : ""}
                                                                                                                        </span>
                                                                                                                        <span className="replymessage">{getclarification_receivedvalue.comment}</span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        ))}
                                                                                                    </>
                                                                                                ) : (
                                                                                                    ""
                                                                                                )}
                                                                                            </div>
                                                                                            {user_role && user_role.flaggedComment && user_role.flaggedComment === "yes" && (
                                                                                                <div className="comment-box mb-2">
                                                                                                    <form className="form w-100" onSubmit={(event) => SubmitHandler(event, "clarification_received")}>
                                                                                                        <textarea
                                                                                                            key="clarification_received_1"
                                                                                                            className="form-control form-control-lg mt-3 mb-3"
                                                                                                            value={application_form.clarification_received && application_form.clarification_received.value ? application_form.clarification_received.value : ""}
                                                                                                            onChange={(event) => inputChangeHandler(event, "clarification_received")}
                                                                                                        ></textarea>

                                                                                                        <button type="submit" id="kt_new_password_submit" className="btn btn-sm fw-bolder me-4">
                                                                                                            <span className="indicator-label">Post Comment</span>
                                                                                                        </button>
                                                                                                    </form>
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    )}
                                                                            </>
                                                                        )
                                                                    }
                                                                </div>
                                                                {/* End Clarification Received */}
                                                                {applicationdetaildata.sendclarification && applicationdetaildata.sendclarification === '1' && applicationdetaildata.assign_for_review && applicationdetaildata.assign_for_review.assign_member.membername === '' && (
                                                                    <div className="timeline-item">
                                                                        <div className="timeline-media">
                                                                            {!applicationdetaildata.clarificationrequesteddate ? (
                                                                                <>
                                                                                    <span className="fs-2 fw-bolder date-one">{Moment(new Date()).format("DD")} </span>
                                                                                    <br />
                                                                                    <span className="opacity-50 fs-8 fw-bold">{Moment(new Date()).format("MMM YYYY")}</span>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <span className="fs-2 fw-bolder date-one">
                                                                                        {applicationdetaildata.clarificationrequesteddate ? Moment(applicationdetaildata.clarificationrequesteddate).format("DD") : ""}{" "}
                                                                                    </span>
                                                                                    <br />
                                                                                    <span className="opacity-50 fs-8 fw-bold">
                                                                                        {applicationdetaildata.clarificationrequesteddate ? Moment(applicationdetaildata.clarificationrequesteddate).format("MMM YYYY") : ""}
                                                                                    </span>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                        <div className="timeline-content">
                                                                            <div className="d-flex align-items-center justify-content-between mb-3">
                                                                                <div className="mr-2">
                                                                                    <h3 className="text-dark-75 font-weight-bold"> Clarification requested</h3>



                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* end que form */}
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
            <Modal footer={null} title="Reject flagged application" visible={isRejectModalVisible} onCancel={handleCancel}>
                <RejectApplication
                    chapterId={id}
                    setIsRejectModalVisible={setIsRejectModalVisible}
                    showData={userDetails}
                    editChapterData={editChapterData}
                    messagedata="Are you sure you want to reject this flagged application?"
                    RejectApplicationSubmit={(event) => RejectApplicationSubmit(event)}
                />
            </Modal>
            <Modal footer={null} title="Accept Application" visible={isAcceptModalVisible} onCancel={handleCancel}>
                <AcceptApplication
                    chapterId={id}
                    setIsAcceptModalVisible={setIsAcceptModalVisible}
                    showData={userDetails}
                    editChapterData={editChapterData}
                    messagedata="Are you sure you want to accept this application? Note: This application will move to main application list"
                    AcceptApplicationSubmit={(event) => AcceptApplicationSubmit(event)}
                />
            </Modal>
            <Modal footer={null} title="Re Schedule Interview" visible={isRescheduleModalVisible} onCancel={handleCancel}>
                <InterviewReSchedule
                    setIsreshudel={isRescheduleModalVisible}
                    Calendardata={{
                        ApplicationUserID: applicationdetaildata.oneinterviewmemberId,
                        applicationid: applicaionId
                    }}
                    setAssignuserdata={setAssignuserdata}
                    editChapterData={Assignuserdata}
                    ReScheduleApplicationSubmit={(event) => ReScheduleApplicationSubmit(event)}
                />
            </Modal>
            <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
                <span className="svg-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="black" />
                        <path
                            d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                            fill="black"
                        />
                    </svg>
                </span>
            </div>
            {/* <Modal footer={null} title="Assign for Review" visible={isAssignModalVisible} onCancel={handleCancel}>
                <AssignApplicationUser showData={userDetails} editChapterData={Assignuserdata} />
            </Modal> */}
        </div>
    );
};

export default FlaggApplicationReview;
