import React from "react";
import Validations from "../../Utility/Validations";
const user_info = JSON.parse(localStorage.getItem("gpt_authuser"));
class CopyTimeSlot extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email_form: {
                created_id: {
                    value: user_info._id,
                    validations: { required: false },
                },
                Sun: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                Mon: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                Tue: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                Wed: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                Thu: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                Fri: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                Sat: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                Sun_start_time: {
                    valid: true,
                    error_msg: "",
                    value: [],
                    type: "text",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
            },
            gettimeslot: [],
        }
    }

    inputChangeHandler(event, identifier) {
        const update_email_form = { ...this.state.email_form };
        const form_element = { ...update_email_form[identifier] };

        if (event.target.checked === true) {
            form_element.value = "yes";
            form_element.isChecked = event.target.checked;            
        } else {
            form_element.value = "";
            form_element.isChecked = "";
            
        }
        
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_email_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_email_form) {
            is_form_valid = update_email_form[identifier].valid && is_form_valid;
        }
        this.setState({
            email_form: update_email_form,
            is_form_valid: is_form_valid,
        });
    }

    SubmitHandlerdata = async () => {
        // event.preventDefault();
        let is_form_valid = true;
        const update_email_form = this.state.email_form;
        for (let key in update_email_form) {
            let form_element = update_email_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_email_form[key] = form_element;
        }
        this.setState({ email_form: update_email_form });
        const form_data = {};
        for (let key in this.state.email_form) {
            form_data[key] = this.state.email_form[key].value;
        }
        // console.log('form_data===', form_data)
        this.props.submitApplytimeslot(form_data);
    }
    render() {
        const { email_form } = this.state;
        // console.log('email_form compy==', email_form)      
        const setcopytimes = this.props.setcopytimes ? this.props.setcopytimes : "";
        return <div className="copy-time">
            {/* <form name="normal_login" className="login-form" onSubmit={this.SubmitHandlerdata}> */}
            <p className="">Copy times to...</p>
            <label className="week-day">
                <div className="one-day">
                    Sunday
                    <input
                        className="checkbox-day"
                        type={email_form.Sun.type}
                        value={email_form.Sun.value}
                        checked={setcopytimes === 'Sun' ? (true) : (email_form.Sun.isChecked)}
                        onChange={(event) => this.inputChangeHandler(event, "Sun")}
                    />
                    {/* <input type="checkbox" className="checkbox-day" checked={setcopytimes === 'Sun' ? (true):('')} /> */}
                </div>
            </label>
            <label className="week-day">
                <div className="one-day">
                    Monday
                    <input
                        className="checkbox-day"
                        type={email_form.Mon.type}
                        value={email_form.Mon.value}
                        checked={setcopytimes === 'Mon' ? (true) : (email_form.Mon.isChecked)}
                        onChange={(event) => this.inputChangeHandler(event, "Mon")}
                    />
                </div>
            </label>
            <label className="week-day">
                <div className="one-day">
                    Tuesday
                    <input
                        className="checkbox-day"
                        type={email_form.Tue.type}
                        value={email_form.Tue.value}
                        checked={setcopytimes === 'Tue' ? (true) : (email_form.Tue.isChecked)}
                        onChange={(event) => this.inputChangeHandler(event, "Tue")}
                    />
                </div>
            </label>
            <label className="week-day">
                <div className="one-day">
                    Wednesday
                    <input
                        className="checkbox-day"
                        type={email_form.Wed.type}
                        value={email_form.Wed.value}
                        checked={setcopytimes === 'Wed' ? (true) : (email_form.Wed.isChecked)}
                        onChange={(event) => this.inputChangeHandler(event, "Wed")}
                    />
                </div>
            </label>
            <label className="week-day">
                <div className="one-day">
                    Thursday
                    <input
                        className="checkbox-day"
                        type={email_form.Thu.type}
                        value={email_form.Thu.value}
                        checked={setcopytimes === 'Thu' ? (true) : (email_form.Thu.isChecked)}
                        onChange={(event) => this.inputChangeHandler(event, "Thu")}
                    />
                </div>
            </label>
            <label className="week-day">
                <div className="one-day">
                    Friday
                    <input
                        className="checkbox-day"
                        type={email_form.Fri.type}
                        value={email_form.Fri.value}
                        checked={setcopytimes === 'Fri' ? (true) : (email_form.Fri.isChecked)}
                        onChange={(event) => this.inputChangeHandler(event, "Fri")}
                    />
                </div>
            </label>
            <label className="week-day">
                <div className="one-day">
                    Saturday
                    <input
                        className="checkbox-day"
                        type={email_form.Sat.type}
                        value={email_form.Sat.value}
                        checked={setcopytimes === 'Sat' ? (true) : (email_form.Sat.isChecked)}
                        onChange={(event) => this.inputChangeHandler(event, "Sat")}
                    />
                </div>
            </label>
            <button onClick={() => this.SubmitHandlerdata()} type="button" id="kt_new_password_submit" className=" btn btn-sm btn-primary fw-bolder m-4">
                <span className="indicator-label">Apply</span>
            </button>
            {/* </form> */}
        </div>
    }
}
export default CopyTimeSlot;