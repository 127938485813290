import React from "react";
import { Link } from "react-router-dom";
import { notification } from "antd";
// import PermissionNavbar from "../../Constant/PermissionNavbar";
import LeftSideBar from "../../Constant/LeftSideBar";
import TopBar from "../../Constant/TopBar";
import Footer from "../../Constant/Footer";
import { getLevels, addRole, getRole, getMemberbyLeve } from "../../Api/user/userapi";
import Validations from "../Utility/Validations";
import Select from "react-select";

const user_info = JSON.parse(localStorage.getItem("gpt_authuser"));

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#ffdbdb" : ""
        },
        backgroundColor: state.isSelected ? "#cc0c0c" : "",
    }),
};

class Permission extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            roleuser_form: {
                created_id: {
                    value: user_info._id,
                    validations: { required: false },
                },
                dashboardView: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                dashboardApplications: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                dashboardQualified: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                dashboardRound1: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                dashboardRound2: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                dashboardRound3: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                dashboardRound4: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                dashboardFlagged: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                memberView: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                memberAdd: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                memberEdit: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                memberDelete: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                memberArchive: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                memberRejected: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                memberSelected: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                memberInterview: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                applicationView: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                applicationAccept: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                applicationReject: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                applicationPlaigarism: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                applicationAction: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                applicationUrl: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                applicationComment: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                applicationAssign: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                flaggedView: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                flaggedAccept: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                flaggedReject: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                interviewView: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                interviewComplete: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                membersInterviewAvailability: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                membersInterviewSchedule: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                interviewReschedule: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },

                interviewAvailability: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                interviewAvailabilityDelete: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                interviewDelete: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                settingGeneral: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },

                settingEmail: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                settingAddEmail: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                settingAccount: {
                    valid: true,
                    error_msg: "",
                    value: "yes",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                MasterLevel: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                MasterTag: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                MasterManageInterviews: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                flaggedComment: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                applicationInitialReview: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                applicationAssignOtherMember: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                Allapplication: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                interviewRound1: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                interviewRound2: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                interviewRound3: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                levelId: {
                    type: "select",
                    label: "Select Level",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Select Level",
                    validations: { required: true },
                    // options: [],
                    options: [{ label: "Select Level", value: "" }],
                },
                MemberId: {
                    type: "select",
                    label: "Select Members",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Select Members",
                    validations: { required: false },
                    options: [{ label: "Select Member", value: "" }],
                },
                SelectAll: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                Allflaggedapplication: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                Assignedflaggedapplication: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                applicationExternalMember: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                applicationFinalReview: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
            },
            Levels: [],
            Members: [],
        };
    }
    componentDidMount() {
        this.userLevels();
        // this.userDetails();
    }
    userLevels = async () => {
        try {
            const resp = await getLevels();
            if (resp && resp.status === 200) {
                const list = resp;
                const all_bidtype = list.data.data;
                const update_roleuser_form = { ...this.state.roleuser_form };
                let LevelId_option = [{ label: "Select Level", value: "" }];
                for (let key in all_bidtype) {
                    // LevelId_option.push({
                    //     opk: all_bidtype[key]._id,
                    //     name: all_bidtype[key].name,
                    //     value: all_bidtype[key]._id,
                    // });
                    LevelId_option.push({
                        value: all_bidtype[key]._id,
                        label: all_bidtype[key].name,
                    });
                }
                update_roleuser_form.levelId.options = LevelId_option;
                this.setState({ roleuser_form: update_roleuser_form });
                // this.setState({
                //     Levels: arrayData,
                // });
                // setLevels(arrayData);
            }
        } catch (e) { }
    };

    inputChangeHandlerAll(event, identifier) {
        const update_roleuser_form = { ...this.state.roleuser_form };
        for (let key in update_roleuser_form) {
            let form_element = update_roleuser_form[key];
            form_element.isChecked = "";

            if (key !== 'MemberId' && key !== 'levelId' && key !== 'created_id') {
                form_element.value = "";
                if (event.target.checked === true) {
                    form_element.value = "yes";
                    form_element.isChecked = event.target.checked;
                } else {
                    form_element.value = "";
                    form_element.isChecked = "";
                }
            }



            update_roleuser_form[key] = form_element;
        }

        this.setState({
            roleuser_form: update_roleuser_form,
        });
    }
    inputChangeHandler(event, identifier) {
        const update_roleuser_form = { ...this.state.roleuser_form };
        const form_element = { ...update_roleuser_form[identifier] };
        if (identifier === "levelId") {
            const MemberId = "";
            update_roleuser_form.MemberId.value = '';
            form_element.value = event.value;
            this.MemberbyLeve(event.value);
            this.getrole(event.value, MemberId);
        } else if (identifier === "MemberId") {
            // console.log('result==', event.target.value)
            const levelId = update_roleuser_form.levelId.value;
            form_element.value = event.value;
            this.getrole(levelId, event.value);
        } else {
            if (event.target.checked === true) {
                form_element.value = "yes";
                form_element.isChecked = event.target.checked;
            } else {
                form_element.value = "";
                form_element.isChecked = "";
            }
        }

        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_roleuser_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_roleuser_form) {
            is_form_valid = update_roleuser_form[identifier].valid && is_form_valid;
        }

        this.setState({
            roleuser_form: update_roleuser_form,
            is_form_valid: is_form_valid,
        });
    }
    MemberbyLeve = async (levelId) => {
        try {
            const resp = await getMemberbyLeve({ levelId: levelId });
            if (resp && resp.status === 200) {
                const list = resp;
                const arrayData = list.data.data;
                const update_roleuser_form = { ...this.state.roleuser_form };
                // let MemberId_option = [{ opk: "role_opk_0", label: "Select Member", value: "" }];
                let MemberId_option = [{ label: "Select Member", value: "" }];
                for (let key in arrayData) {
                    // MemberId_option.push({
                    //     opk: arrayData[key]._id,
                    //     name: arrayData[key].userName,
                    //     value: arrayData[key]._id,
                    // });
                    MemberId_option.push({
                        value: arrayData[key]._id,
                        label: arrayData[key].userName,
                    });
                }
                update_roleuser_form.MemberId.options = MemberId_option;
                this.setState({ roleuser_form: update_roleuser_form });
            }
        } catch (e) { }
    };
    getrole = async (levelId, MemberId) => {
        try {
            const resp = await getRole({ levelId: levelId, MemberId: MemberId });
            if (resp && resp.data.success === true) {
                const list = resp;
                const member_detail = list.data.data;
                const update_roleuser_form = { ...this.state.roleuser_form };
                for (let key in update_roleuser_form) {
                    let form_element = update_roleuser_form[key];
                    form_element.isChecked = "";
                    form_element.value = "";
                    if (key === 'MemberId') {
                        form_element.value = MemberId;
                    }
                    if (key === 'created_id') {
                        form_element.value = user_info._id;
                    }
                    if (member_detail[key]) {
                        form_element.value = member_detail[key];
                        if (member_detail[key] === "yes") {
                            form_element.isChecked = "checked";
                        }
                    }
                    if (key === 'MemberId') {
                        form_element.value = MemberId;
                    }
                    if (key === 'created_id') {
                        form_element.value = user_info._id;
                    }
                    update_roleuser_form[key] = form_element;
                }
                this.setState({
                    loader: false,
                    roleuser_form: update_roleuser_form,
                });
            }
        } catch (e) { }
    };
    SubmitHandler = async (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_roleuser_form = this.state.roleuser_form;
        for (let key in update_roleuser_form) {
            let form_element = update_roleuser_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_roleuser_form[key] = form_element;
        }
        this.setState({ roleuser_form: update_roleuser_form });
        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.roleuser_form) {
                form_data[key] = this.state.roleuser_form[key].value;
            }
            // return;
            const userResp = await addRole(form_data);
            if (userResp && userResp.data.success === true) {
                this.setState({ Loading: false });
                setTimeout(() => {
                    notification.open({
                        message: "Role & Permission",
                        description: userResp.data.message,
                    });
                }, 1000);
            } else {
                this.setState({ Loading: false });
                setTimeout(() => {
                    notification.open({
                        message: "Role & Permission",
                        description: userResp.data.message,
                    });
                }, 1000);
            }
            //}
        }
    };
    render() {
        const { roleuser_form } = this.state;
        console.log('roleuser_form==', roleuser_form)
        // const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
        return (
            <div className="d-flex flex-column flex-root header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed h-100">
                <div className="page d-flex flex-row flex-column-fluid">
                    <LeftSideBar />
                    <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                        <TopBar />

                        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                            <div className="toolbar" id="kt_toolbar">
                                <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                                    <div
                                        data-kt-swapper="true"
                                        data-kt-swapper-mode="prepend"
                                        data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
                                        className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
                                    >
                                        <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
                                            Roles & Permissions
                                            <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                                        </h1>

                                        <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                                            <li className="breadcrumb-item text-muted">
                                                <Link to="/dashboard" className="text-muted text-hover-primary">
                                                    Home
                                                </Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <span className="bullet bg-gray-200 w-5px h-2px"></span>
                                            </li>
                                            <li className="breadcrumb-item text-muted">Roles & Permissions</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="post d-flex flex-column-fluid" id="kt_post">
                                <div id="kt_content_container" className="container-xxl">
                                    <div className="row g-5 g-xl-8">
                                        <div className="col-xxl-12">
                                            <div className="card card-xxl-stretch mb-5 mb-xl-8 roles-outer">
                                                <div className="card-header mt-5">
                                                    <div className="card-title flex-column">
                                                        {/**<h3 className="fw-bolder mb-1">Roles & Permissions</h3> **/}
                                                    </div>
                                                    <div className="card-toolbar my-1" data-select2-id="select2-data-159-5lwa">
                                                        <div className="me-6 my-1">
                                                            <div className="checkbox-inline">
                                                                <label className="checkbox">
                                                                    <input
                                                                        className="form-check-input me-2"
                                                                        type={roleuser_form.SelectAll.type}
                                                                        value={roleuser_form.SelectAll.value}
                                                                        checked={roleuser_form.SelectAll.isChecked}
                                                                        onChange={(event) => this.inputChangeHandlerAll(event, "SelectAll")}
                                                                    />
                                                                    <span className="fw-bolder fs-6 mb-0">Select All</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="me-6 my-1">
                                                            {/* <select className="form-select form-select-solid form-select-sm fw-bold" value={roleuser_form.levelId.value} onChange={(event) => this.inputChangeHandler(event, "levelId")}>
                                                                {roleuser_form.levelId.options.map((option) => (
                                                                    <option value={option.value} key={option.opk}>
                                                                        {option.name}
                                                                    </option>
                                                                ))}
                                                            </select> */}
                                                            <Select
                                                                styles={customStyles}
                                                                name={"levelId"}
                                                                className="react-bootstrap-typeahead tag-outer"
                                                                placeholder="Select a Level"
                                                                options={roleuser_form.levelId.options}
                                                                value={roleuser_form.levelId.options.filter(function (option) {
                                                                    return option.value === roleuser_form.levelId.value;
                                                                })}
                                                                onChange={(event) => this.inputChangeHandler(event, "levelId")}
                                                            // onChange={newValue => this.inputChangeHandler(newValue, "levelId")}
                                                            />
                                                            {!roleuser_form.levelId.valid && roleuser_form.levelId.onBlur_out ? <div className="error field-error">{roleuser_form.levelId.error_msg}</div> : ""}
                                                        </div>
                                                        <div className="me-4 my-1">
                                                            {/* <select className="form-select form-select-solid form-select-sm fw-bold" value={roleuser_form.MemberId.value} onChange={(event) => this.inputChangeHandler(event, "MemberId")}>
                                                                {roleuser_form.MemberId.options.map((option) => (
                                                                    <option value={option.value} key={option.opk}>
                                                                        {option.name}
                                                                    </option>
                                                                ))}
                                                            </select> */}
                                                            <Select
                                                                styles={customStyles}
                                                                name={"MemberId"}
                                                                className="react-bootstrap-typeahead tag-outer"
                                                                placeholder="Select a Member"
                                                                options={roleuser_form.MemberId.options}
                                                                value={roleuser_form.MemberId.options.filter(function (option) {
                                                                    return option.value === roleuser_form.MemberId.value;
                                                                })}
                                                                onChange={(event) => this.inputChangeHandler(event, "MemberId")}
                                                            // onChange={newValue => this.inputChangeHandler(newValue, "levelId")}
                                                            />
                                                            {!roleuser_form.MemberId.valid && roleuser_form.MemberId.onBlur_out ? <div className="error field-error">{roleuser_form.MemberId.error_msg}</div> : ""}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="card-body ">
                                                    <form onSubmit={this.SubmitHandler}>
                                                        <div className="form-group mb-10">
                                                            <h3 className="fw-bolder mb-4">Dashboard</h3>
                                                            <div className="row">
                                                                {/* <div className="col-sm-6 col-xl-3 mb-4">
                                                                <div className="checkbox-inline">
                                                                    <label className="checkbox">
                                                                        <input className="form-check-input me-1"  type={roleuser_form.dashboardView.type}
                                                                        value={roleuser_form.dashboardView.value}
                                                                        checked={roleuser_form.dashboardView.isChecked}
                                                                        onChange={(event) => this.inputChangeHandler(event, "dashboardView")} />
                                                                        <span className="fw-bolder fs-6 mb-0">View</span>
                                                                    </label>
                                                                </div>
                                                            </div> */}
                                                                <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-2"
                                                                                type={roleuser_form.dashboardApplications.type}
                                                                                value={roleuser_form.dashboardApplications.value}
                                                                                checked={roleuser_form.dashboardApplications.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "dashboardApplications")}
                                                                            />
                                                                            <span className="fw-normal fs-6 mb-0">Numbers of Applications</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-2"
                                                                                type={roleuser_form.dashboardQualified.type}
                                                                                value={roleuser_form.dashboardQualified.value}
                                                                                checked={roleuser_form.dashboardQualified.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "dashboardQualified")}
                                                                            />
                                                                            <span className="fw-normal fs-6 mb-0">Qualified Applications</span>
                                                                        </label>
                                                                    </div>
                                                                </div> */}
                                                                <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-2"
                                                                                type={roleuser_form.dashboardRound1.type}
                                                                                value={roleuser_form.dashboardRound1.value}
                                                                                checked={roleuser_form.dashboardRound1.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "dashboardRound1")}
                                                                            />
                                                                            <span className="fw-normal fs-6 mb-0">Selected Candidates (Round 1)</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-2"
                                                                                type={roleuser_form.dashboardRound2.type}
                                                                                value={roleuser_form.dashboardRound2.value}
                                                                                checked={roleuser_form.dashboardRound2.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "dashboardRound2")}
                                                                            />
                                                                            <span className="fw-normal fs-6 mb-0">Selected Candidates (Round 2)</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-2"
                                                                                type={roleuser_form.dashboardRound3.type}
                                                                                value={roleuser_form.dashboardRound3.value}
                                                                                checked={roleuser_form.dashboardRound3.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "dashboardRound3")}
                                                                            />
                                                                            <span className="fw-normal fs-6 mb-0">Selected Candidates (Round 3)</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-2"
                                                                                type={roleuser_form.dashboardRound4.type}
                                                                                value={roleuser_form.dashboardRound4.value}
                                                                                checked={roleuser_form.dashboardRound4.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "dashboardRound4")}
                                                                            />
                                                                            <span className="fw-normal fs-6 mb-0">Selected Candidates (Round 4)</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-2"
                                                                                type={roleuser_form.dashboardFlagged.type}
                                                                                value={roleuser_form.dashboardFlagged.value}
                                                                                checked={roleuser_form.dashboardFlagged.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "dashboardFlagged")}
                                                                            />
                                                                            <span className="fw-normal fs-6 mb-0">Flagged Applications</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="form-group mb-10">
                                                            <h3 className="fw-bolder mb-4">Team Members</h3>
                                                            <div className="row">
                                                                <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-2"
                                                                                type={roleuser_form.memberAdd.type}
                                                                                value={roleuser_form.memberAdd.value}
                                                                                checked={roleuser_form.memberAdd.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "memberAdd")}
                                                                            />

                                                                            <span className="fw-normal fs-6 mb-0">Add</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-2"
                                                                                type={roleuser_form.memberEdit.type}
                                                                                value={roleuser_form.memberEdit.value}
                                                                                checked={roleuser_form.memberEdit.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "memberEdit")}
                                                                            />

                                                                            <span className="fw-normal fs-6 mb-0">Edit</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-2"
                                                                                type={roleuser_form.memberDelete.type}
                                                                                value={roleuser_form.memberDelete.value}
                                                                                checked={roleuser_form.memberDelete.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "memberDelete")}
                                                                            />

                                                                            <span className="fw-normal fs-6 mb-0">Active / Inactive</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-2"
                                                                                type={roleuser_form.memberView.type}
                                                                                value={roleuser_form.memberView.value}
                                                                                checked={roleuser_form.memberView.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "memberView")}
                                                                            />

                                                                            <span className="fw-normal fs-6 mb-0">View</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-1"
                                                                                type={roleuser_form.memberInterview.type}
                                                                                value={roleuser_form.memberInterview.value}
                                                                                checked={roleuser_form.memberInterview.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "memberInterview")}
                                                                            />

                                                                            <span className="fw-normal fs-6 mb-0">Upcoming Interviews</span>
                                                                        </label>
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                        </div>

                                                        <div className="form-group mb-10">
                                                            <h3 className="fw-bolder mb-4">Applications</h3>
                                                            <div className="row">
                                                                <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-2"
                                                                                type={roleuser_form.Allapplication.type}
                                                                                value={roleuser_form.Allapplication.value}
                                                                                checked={roleuser_form.Allapplication.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "Allapplication")}
                                                                            />

                                                                            <span className="fw-normal fs-6 mb-0">All Applications</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-1"
                                                                                type={roleuser_form.applicationView.type}
                                                                                value={roleuser_form.applicationView.value}
                                                                                checked={roleuser_form.applicationView.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "applicationView")}
                                                                            />

                                                                            <span className="fw-normal fs-6 mb-0">View</span>
                                                                        </label>
                                                                    </div>
                                                                </div>                                                                 */}
                                                                <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-2"
                                                                                type={roleuser_form.applicationAccept.type}
                                                                                value={roleuser_form.applicationAccept.value}
                                                                                checked={roleuser_form.applicationAccept.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "applicationAccept")}
                                                                            />

                                                                            <span className="fw-normal fs-6 mb-0">Accept</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-2"
                                                                                type={roleuser_form.applicationReject.type}
                                                                                value={roleuser_form.applicationReject.value}
                                                                                checked={roleuser_form.applicationReject.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "applicationReject")}
                                                                            />

                                                                            <span className="fw-normal fs-6 mb-0">Reject</span>
                                                                        </label>
                                                                    </div>
                                                                </div>

                                                                {/* <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-1"
                                                                                type={roleuser_form.applicationPlaigarism.type}
                                                                                value={roleuser_form.applicationPlaigarism.value}
                                                                                checked={roleuser_form.applicationPlaigarism.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "applicationPlaigarism")}
                                                                            />

                                                                            <span className="fw-bolder fs-6 mb-0">Plaigarism</span>
                                                                        </label>
                                                                    </div>
                                                                </div> */}
                                                                {/* <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-1"
                                                                                type={roleuser_form.applicationAction.type}
                                                                                value={roleuser_form.applicationAction.value}
                                                                                checked={roleuser_form.applicationAction.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "applicationAction")}
                                                                            />

                                                                            <span className="fw-bolder fs-6 mb-0">Actions</span>
                                                                        </label>
                                                                    </div>
                                                                </div> */}
                                                                <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-2"
                                                                                type={roleuser_form.applicationUrl.type}
                                                                                value={roleuser_form.applicationUrl.value}
                                                                                checked={roleuser_form.applicationUrl.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "applicationUrl")}
                                                                            />

                                                                            <span className="fw-normal fs-6 mb-0">Progress URL</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-2"
                                                                                type={roleuser_form.applicationComment.type}
                                                                                value={roleuser_form.applicationComment.value}
                                                                                checked={roleuser_form.applicationComment.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "applicationComment")}
                                                                            />

                                                                            <span className="fw-normal fs-6 mb-0">Comment</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-1"
                                                                                type={roleuser_form.applicationAssign.type}
                                                                                value={roleuser_form.applicationAssign.value}
                                                                                checked={roleuser_form.applicationAssign.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "applicationAssign")}
                                                                            />

                                                                            <span className="fw-normal fs-6 mb-0">Assign</span>
                                                                        </label>
                                                                    </div>
                                                                </div> */}
                                                                <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-2"
                                                                                type={roleuser_form.applicationAssignOtherMember.type}
                                                                                value={roleuser_form.applicationAssignOtherMember.value}
                                                                                checked={roleuser_form.applicationAssignOtherMember.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "applicationAssignOtherMember")}
                                                                            />

                                                                            <span className="fw-normal fs-6 mb-0">Assign other member</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-2"
                                                                                type={roleuser_form.applicationInitialReview.type}
                                                                                value={roleuser_form.applicationInitialReview.value}
                                                                                checked={roleuser_form.applicationInitialReview.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "applicationInitialReview")}
                                                                            />

                                                                            <span className="fw-normal fs-6 mb-0">Initial Review</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-2"
                                                                                type={roleuser_form.applicationFinalReview.type}
                                                                                value={roleuser_form.applicationFinalReview.value}
                                                                                checked={roleuser_form.applicationFinalReview.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "applicationFinalReview")}
                                                                            />

                                                                            <span className="fw-normal fs-6 mb-0">Final Review</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-2"
                                                                                type={roleuser_form.applicationExternalMember.type}
                                                                                value={roleuser_form.applicationExternalMember.value}
                                                                                checked={roleuser_form.applicationExternalMember.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "applicationExternalMember")}
                                                                            />

                                                                            <span className="fw-normal fs-6 mb-0">External Member</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-2"
                                                                                type={roleuser_form.interviewRound1.type}
                                                                                value={roleuser_form.interviewRound1.value}
                                                                                checked={roleuser_form.interviewRound1.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "interviewRound1")}
                                                                            />
                                                                            <span className="fw-normal fs-6 mb-0">Interview Round 1</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-1"
                                                                                type={roleuser_form.memberArchive.type}
                                                                                value={roleuser_form.memberArchive.value}
                                                                                checked={roleuser_form.memberArchive.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "memberArchive")}
                                                                            />

                                                                            <span className="fw-normal fs-6 mb-0">Archive Application</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-1"
                                                                                type={roleuser_form.memberRejected.type}
                                                                                value={roleuser_form.memberRejected.value}
                                                                                checked={roleuser_form.memberRejected.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "memberRejected")}
                                                                            />

                                                                            <span className="fw-normal fs-6 mb-0">Rejected Application</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-1"
                                                                                type={roleuser_form.memberSelected.type}
                                                                                value={roleuser_form.memberSelected.value}
                                                                                checked={roleuser_form.memberSelected.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "memberSelected")}
                                                                            />
                                                                            <span className="fw-normal fs-6 mb-0">Selected Application</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-1"
                                                                                type={roleuser_form.interviewRound2.type}
                                                                                value={roleuser_form.interviewRound2.value}
                                                                                checked={roleuser_form.interviewRound2.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "interviewRound2")}
                                                                            />
                                                                            <span className="fw-bolder fs-6 mb-0">Interview Round 2</span>
                                                                        </label>
                                                                    </div>
                                                                </div> */}
                                                                {/* <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-1"
                                                                                type={roleuser_form.interviewRound3.type}
                                                                                value={roleuser_form.interviewRound3.value}
                                                                                checked={roleuser_form.interviewRound3.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "interviewRound3")}
                                                                            />
                                                                            <span className="fw-normal fs-6 mb-0">Interview Round 3</span>
                                                                        </label>
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                        <div className="form-group mb-10">
                                                            <h3 className="fw-bolder mb-4">Flagged Applications</h3>
                                                            <div className="row">

                                                                <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-2"
                                                                                type={roleuser_form.Allflaggedapplication.type}
                                                                                value={roleuser_form.Allflaggedapplication.value}
                                                                                checked={roleuser_form.Allflaggedapplication.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "Allflaggedapplication")}
                                                                            />
                                                                            <span className="fw-normal fs-6 mb-0">All Application</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-2"
                                                                                type={roleuser_form.Assignedflaggedapplication.type}
                                                                                value={roleuser_form.Assignedflaggedapplication.value}
                                                                                checked={roleuser_form.Assignedflaggedapplication.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "Assignedflaggedapplication")}
                                                                            />
                                                                            <span className="fw-normal fs-6 mb-0">Assigned Application</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-2"
                                                                                type={roleuser_form.flaggedView.type}
                                                                                value={roleuser_form.flaggedView.value}
                                                                                checked={roleuser_form.flaggedView.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "flaggedView")}
                                                                            />
                                                                            <span className="fw-normal fs-6 mb-0">View</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-2"
                                                                                type={roleuser_form.flaggedAccept.type}
                                                                                value={roleuser_form.flaggedAccept.value}
                                                                                checked={roleuser_form.flaggedAccept.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "flaggedAccept")}
                                                                            />
                                                                            <span className="fw-normal fs-6 mb-0">Accept</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-2"
                                                                                type={roleuser_form.flaggedReject.type}
                                                                                value={roleuser_form.flaggedReject.value}
                                                                                checked={roleuser_form.flaggedReject.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "flaggedReject")}
                                                                            />
                                                                            <span className="fw-normal fs-6 mb-0">Reject</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-2"
                                                                                type={roleuser_form.flaggedComment.type}
                                                                                value={roleuser_form.flaggedComment.value}
                                                                                checked={roleuser_form.flaggedComment.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "flaggedComment")}
                                                                            />
                                                                            <span className="fw-normal fs-6 mb-0">Comment</span>
                                                                        </label>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="form-group mb-10">
                                                            <h3 className="fw-bolder mb-4">Interviews</h3>
                                                            <div className="row">
                                                                {/* <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-2"
                                                                                type={roleuser_form.interviewView.type}
                                                                                value={roleuser_form.interviewView.value}
                                                                                checked={roleuser_form.interviewView.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "interviewView")}
                                                                            />
                                                                            <span className="fw-normal fs-6 mb-0">View</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-2"
                                                                                type={roleuser_form.interviewComplete.type}
                                                                                value={roleuser_form.interviewComplete.value}
                                                                                checked={roleuser_form.interviewComplete.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "interviewComplete")}
                                                                            />
                                                                            <span className="fw-normal fs-6 mb-0">Complete</span>
                                                                        </label>
                                                                    </div>
                                                                </div> */}

                                                                <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-2"
                                                                                type={roleuser_form.membersInterviewAvailability.type}
                                                                                value={roleuser_form.membersInterviewAvailability.value}
                                                                                checked={roleuser_form.membersInterviewAvailability.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "membersInterviewAvailability")}
                                                                            />
                                                                            <span className="fw-normal fs-6 mb-0">Members Interview Availability</span>
                                                                        </label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-2"
                                                                                type={roleuser_form.membersInterviewSchedule.type}
                                                                                value={roleuser_form.membersInterviewSchedule.value}
                                                                                checked={roleuser_form.membersInterviewSchedule.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "membersInterviewSchedule")}
                                                                            />
                                                                            <span className="fw-normal fs-6 mb-0">Members Interview Schedule</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-2"
                                                                                type={roleuser_form.interviewReschedule.type}
                                                                                value={roleuser_form.interviewReschedule.value}
                                                                                checked={roleuser_form.interviewReschedule.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "interviewReschedule")}
                                                                            />
                                                                            <span className="fw-normal fs-6 mb-0">Reschedule</span>
                                                                        </label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-2"
                                                                                type={roleuser_form.interviewAvailability.type}
                                                                                value={roleuser_form.interviewAvailability.value}
                                                                                checked={roleuser_form.interviewAvailability.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "interviewAvailability")}
                                                                            />
                                                                            <span className="fw-normal fs-6 mb-0">Add/Edit Availability</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-2"
                                                                                type={roleuser_form.interviewAvailabilityDelete.type}
                                                                                value={roleuser_form.interviewAvailabilityDelete.value}
                                                                                checked={roleuser_form.interviewAvailabilityDelete.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "interviewAvailabilityDelete")}
                                                                            />
                                                                            <span className="fw-normal fs-6 mb-0">Delete Availability</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-2"
                                                                                type={roleuser_form.interviewDelete.type}
                                                                                value={roleuser_form.interviewDelete.value}
                                                                                checked={roleuser_form.interviewDelete.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "interviewDelete")}
                                                                            />
                                                                            <span className="fw-normal fs-6 mb-0">Delete</span>
                                                                        </label>
                                                                    </div>
                                                                </div>                                                                 */}
                                                            </div>
                                                        </div>

                                                        <div className="form-group mb-10">
                                                            <h3 className="fw-bolder mb-4">Settings</h3>
                                                            <div className="row">
                                                                {/* <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-1"
                                                                                type={roleuser_form.settingGeneral.type}
                                                                                value={roleuser_form.settingGeneral.value}
                                                                                checked={roleuser_form.settingGeneral.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "settingGeneral")}
                                                                            />

                                                                            <span className="fw-bolder fs-6 mb-0">General</span>
                                                                        </label>
                                                                    </div>
                                                                </div> */}
                                                                <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-2"
                                                                                type={roleuser_form.settingEmail.type}
                                                                                value={roleuser_form.settingEmail.value}
                                                                                checked={roleuser_form.settingEmail.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "settingEmail")}
                                                                            />

                                                                            <span className="fw-normal fs-6 mb-0">Email</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-2"
                                                                                type={roleuser_form.settingAddEmail.type}
                                                                                value={roleuser_form.settingAddEmail.value}
                                                                                checked={roleuser_form.settingAddEmail.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "settingAddEmail")}
                                                                            />

                                                                            <span className="fw-normal fs-6 mb-0">Add Email</span>
                                                                        </label>
                                                                    </div>
                                                                </div> */}

                                                                {/* <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-1"
                                                                                type={roleuser_form.settingAccount.type}
                                                                                value={roleuser_form.settingAccount.value}
                                                                                checked={roleuser_form.settingAccount.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "settingAccount")}
                                                                            />

                                                                            <span className="fw-bolder fs-6 mb-0">Account</span>
                                                                        </label>
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                        <div className="form-group mb-10">
                                                            <h3 className="fw-bolder mb-4">Masters</h3>
                                                            <div className="row">
                                                                <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-2"
                                                                                type={roleuser_form.MasterLevel.type}
                                                                                value={roleuser_form.MasterLevel.value}
                                                                                checked={roleuser_form.MasterLevel.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "MasterLevel")}
                                                                            />

                                                                            <span className="fw-normal fs-6 mb-0">Level</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-2"
                                                                                type={roleuser_form.MasterTag.type}
                                                                                value={roleuser_form.MasterTag.value}
                                                                                checked={roleuser_form.MasterTag.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "MasterTag")}
                                                                            />

                                                                            <span className="fw-normal fs-6 mb-0">Tag</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="col-sm-6 col-xl-3 mb-4">
                                                                    <div className="checkbox-inline">
                                                                        <label className="checkbox">
                                                                            <input
                                                                                className="form-check-input me-1"
                                                                                type={roleuser_form.MasterManageInterviews.type}
                                                                                value={roleuser_form.MasterManageInterviews.value}
                                                                                checked={roleuser_form.MasterManageInterviews.isChecked}
                                                                                onChange={(event) => this.inputChangeHandler(event, "MasterManageInterviews")}
                                                                            />

                                                                            <span className="fw-normal fs-6 mb-0">Manage Interviews</span>
                                                                        </label>
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                        <div className="form-group mb-4">
                                                            <div className="col-lg-12 mt-8">
                                                                <button type="submit" id="kt_new_password_submit" className="btn btn-sm btn-primary fw-bolder me-4">
                                                                    <span className="indicator-label">Save</span>
                                                                </button>
                                                                <span className="btn btn-sm btn-light-primary fw-bolder">
                                                                    Cancel
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>

                </div>
            </div>
        );
    }
}

export default Permission;
