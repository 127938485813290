import React from "react";
import { Route, Switch, } from "react-router-dom";
import Login from "./Component/Auth/Login";
import Clarification from "./Component/Auth/Clarification";
import Clarificationthankyou from "./Component/Auth/ClarificationThankyou";
import Clarificationalready from "./Component/Auth/Clarificationalready";
import ApplicationProgress from "./Component/Auth/ApplicationProgress";
import FlaggsClarification from "./Component/Auth/FlaggClarification";
import InterviewClarification from "./Component/Auth/InterviewClarification";

import Logout from "./Component/Auth/logout";
import Forgot from "./Component/Auth/Forgot";
import Registration from "./Component/Auth/Registration";
import ResetPassword from "./Component/Auth/ResetPassword";
import Dashboard from "./Component/Dashboard";

// import Level from "./Component/Level/Level";
// import Member from "./Component/Member/Member";
// import AddMember from "./Component/Member/AddMember";
// import EditMember from "./Component/Member/EditMember";
// import Tag from "./Component/Tag/Tag";
// import MemberDetails from "./Component/Member/MemberDetails";
// import Permission from "./Component/Permissions/Permission";
// import Applications from "./Component/Applications/Application";
// import ApplicationDetail from "./Component/Applications/ApplicationDetail";
// import FlaggApplication from "./Component/FlaggApplication/FlaggApplication";
// import FlaggApplicationReview from "./Component/FlaggApplication/FlaggApplicationReview";
import Thankyou from "./Component/ThankuPage/Thankyou";


import Member from "./containers/Application/Member";
import Applications from "./containers/Application/Application";
import FlaggApplication from "./containers/Application/FlaggApplication";
import Level from "./containers/Application/Level";
import Tag from "./containers/Application/Tag";
import Permission from "./containers/Application/Permission";
import Account from "./containers/Application/Account";
import SettingEmail from "./containers/Application/Email";
import Interviews from "./containers/Application/Interviews";
// import Activity from "./containers/Application/Interviews";



import AuthRouter from "./AuthRouter";
import ProtectedRouter from "./ProtectedRouters";

// const user_info = JSON.parse(localStorage.getItem("gpt_authuser"));
// const user_role = JSON.parse(localStorage.getItem("gpt_user_role"));

const Router = (props) => (
    <Switch>
        <AuthRouter component={Login} path="/" exact />
        {/* <AuthRouter component={Clarification} path="/clarification/:applicationid" exact /> */}
        
        <Route component={FlaggsClarification} path="/flaggedclarification/:applicationid" exact />
        <Route component={Clarification} path="/clarification/:applicationid" exact />
        <Route component={InterviewClarification} path="/interviewClarification/:applicationid" exact />
        <Route component={Clarificationthankyou} path="/clarification-thankyou" exact />
        <Route component={Clarificationalready} path="/clarification-already" exact />
        <Route component={ApplicationProgress} path="/application-progress/:applicationid" exact />

        <Route component={Logout} path="/logout" exact />
        <AuthRouter component={Forgot} path="/forgot" exact />
        <AuthRouter component={Registration} path="/confirm-registration/:id/:email" exact />
        <AuthRouter component={ResetPassword} path="/forgotpassword/:id" exact />

        <ProtectedRouter component={Dashboard} path="/dashboard" exact />
        {/* Mamber Route */}
        <ProtectedRouter component={Member} path="/member" exact />
        <ProtectedRouter component={Member} path="/Addmember" exact />
        <ProtectedRouter component={Member} path="/edit-member/:memberId" exact />
        <ProtectedRouter component={Member} path="/view-member/:memberId" exact />
        {/* <ProtectedRouter component={MemberDetails} path="/memberDetail" exact /> */}

         {/* Applications Route */}
        <ProtectedRouter component={Applications} path="/application-detail/:applicaionId" exact />
        <ProtectedRouter component={Applications} path="/repeated-application/:applicaionId" exact />
        <ProtectedRouter component={Applications} path="/application" exact />
        <ProtectedRouter component={Applications} path="/assigned-application" exact />
        <ProtectedRouter component={Applications} path="/archive-application" exact />
        <ProtectedRouter component={Applications} path="/rejected-application" exact />
        <ProtectedRouter component={Applications} path="/selected-application" exact />

        {/* flagged Applications Route */}
        <ProtectedRouter component={FlaggApplication} path="/flagged-application" exact />
        <ProtectedRouter component={FlaggApplication} path="/flagged-application-review/:applicaionId" exact />

         {/* Level Route */}
        <ProtectedRouter component={Level} path="/level" exact />

        {/* tag Route */}
         <ProtectedRouter component={Tag} path="/tag" exact />

        {/* Permission Route */}
        <ProtectedRouter component={Permission} path="/permission" exact />
        <ProtectedRouter component={Permission} path="/smtp-setting" exact />
        <ProtectedRouter component={Permission} path="/rejection-email" exact />
        
        {/* Account Route */}
        <ProtectedRouter component={Account} path="/account" exact />
        <ProtectedRouter component={Account} path="/zoom-link-upload/:applicaionId" exact />

        {/*Setting Email Route */}
        <ProtectedRouter component={SettingEmail} path="/edit-email/:emailId" exact />
        <ProtectedRouter component={SettingEmail} path="/email" exact />
        <ProtectedRouter component={SettingEmail} path="/addemail" exact />

        {/* Interviews Route */}
        <ProtectedRouter component={Interviews} path="/interviews" exact />
        <ProtectedRouter component={Interviews} path="/availability" exact />
        <ProtectedRouter component={Interviews} path="/members-availability" exact />
        <ProtectedRouter component={Interviews} path="/interview-schedule" exact />
        <ProtectedRouter component={Interviews} path="/activity" exact />
        
        
        <Route component={Thankyou} path="/thanks" exact />
        
        <Route component={Thankyou} path="/thanks" exact /> 
    </Switch>
);
export default Router;