import React, { useEffect, useState, useCallback } from "react";

import { Link } from "react-router-dom";
import { notification } from "antd";
import Moment from "moment";
import { getAlllogsByUser } from "../../Api/user/userapi";
import ActivityViewItem from "./ActivityViewItem";

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";



import LeftSideBar from "../../Constant/LeftSideBar";
import TopBar from "../../Constant/TopBar";
import Footer from "../../Constant/Footer";

const Activity = () => {
    const [array, setArray] = useState([]);
    const [loading, setLoading] = useState(false);

    const user_info = JSON.parse(localStorage.getItem("gpt_authuser"));
    const user_role = JSON.parse(localStorage.getItem("gpt_user_role"));
    // const [filterlimit] = useState(5);
    // const [filterpage, setfilterpage] = useState(1);
    const [filterlimit, setfilterlimit] = useState(10);
    let [filterpage, setfilterpage] = useState(1);
    const [filtersort, setfiltersort] = useState("_id");
    const [filterorder, setfilterorder] = useState("desc");
    const [filtersearch, setfiltersearch] = useState("");
    const [filterlevel, setfilterlevel] = useState("");
    const [filtertag, setfiltertag] = useState("");
    const [membercount, setmembercount] = useState(0);
    const [filterstatus, setfilterstatus] = useState("");    

    const GetMembers = useCallback(async () => {
        var usermemberid = "";
        if (user_info && user_info.usertype !== "admin" && user_role && user_role.Allapplication !== "yes") {
            usermemberid = user_info._id;
        }
        const payload = {
            memberId: usermemberid, //'61fbd05149549eef5596d2a3',
            search: filtersearch,
            pageNumber: filterpage,
            limit: filterlimit,
            sortBy: filtersort,
            order: filterorder,
           // levelid: filterlevel,
            //tagid: filtertag,
            //status: filterstatus,
        };
        try {
            setLoading(true);

            const resp = await getAlllogsByUser(payload);            
            if (resp && resp !== "" && resp.data.success === true) {
                const list = resp;
                setmembercount(list.data.count);
                // setmembercount(100);
                setArray(list.data.data);
                setLoading(false);
            }
            else {
                setTimeout(() => {
                    notification.open({
                        message: "Activity",
                        description: resp.data.message,
                    });
                }, 1000);
                setLoading(false);
            }
        } catch (e) { }
    }, [filtersearch, filterpage, filterlimit, filtersort, filterorder, filterlevel, filtertag, filterstatus]);
    useEffect(() => {
        GetMembers();
    }, [GetMembers, filtersearch, filterpage, filterlimit, filtersort, filterorder, filterlevel, filtertag, filterstatus]);

    const newlyAddedChapter =
        array && array.length
            ? array.map((items) => {
                return {
                    ...items,
                };
            })
            : [];
    const handleTableChange = (type, { page, sizePerPage }) => {
        setfilterpage(page);
    };
    const RemotePagination = ({ columns, data, sizePerPage, page, totalSize, onTableChange }) => (

        <PaginationProvider
            pagination={paginationFactory()}
        >
            {({ paginationProps, paginationTableProps }) => (
                <>
                    <div className="table-responsive">
                        <BootstrapTable
                            keyField="_id"
                            bootstrap4
                            data={data}
                            columns={columns}
                            {...paginationTableProps}
                            onTableChange={onTableChange}
                            headerClasses="fw-bolder text-white bg-dark-row"
                            classes="table-bordered table-row-bordered align-middle custom-table-style table-fields"
                            noDataIndication={() => <span className="text-center text-gray-400">No records found</span>}
                        />
                    </div>
                </>
            )}
        </PaginationProvider>
    );
    return (
        <div className="d-flex flex-column flex-root header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed h-100">
            <div className="page d-flex flex-row flex-column-fluid">
                <LeftSideBar />
                <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                    <TopBar />

                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                        <div className="toolbar" id="kt_toolbar">
                            <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                                <div
                                    data-kt-swapper="true"
                                    data-kt-swapper-mode="prepend"
                                    data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
                                    className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
                                >
                                    <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
                                        Activity
                                        <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                                    </h1>

                                    <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                                        <li className="breadcrumb-item text-muted">
                                            <Link to="/dashboard" className="text-muted text-hover-primary">
                                                Home
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <span className="bullet bg-gray-200 w-5px h-2px"></span>
                                        </li>

                                        <li className="breadcrumb-item text-dark">Activity List</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="post d-flex flex-column-fluid" id="kt_post">
                            <div id="kt_content_container" className="container-xxl">
                                <ActivityViewItem
                                    membercount={membercount}
                                    filterlimit={filterlimit}
                                    filterpage={filterpage}
                                    // filterstatus={filterstatus}
                                    data={array}
                                    loading={loading}
                                    filtersort={filtersort}
                                    filterorder={filterorder}

                                    propsfiltersort={(field) => setfiltersort(field)}
                                    propsfilterorder={(order) => setfilterorder(order)}

                                    propsfilterpage={(event) => setfilterpage(event)}
                                    propsfiltersearch={(event) => setfiltersearch(event)}
                                   // removeChapterHandle={(event) => removeChapterHandle(event)}
                                  //  handleChange={(value, event) => handleChange(value, event)}

                                    propsfilterstatus={(status) => {
                                        setfilterstatus(status);
                                    }}

                                    propsfilterlevel={(level) => {
                                        setfilterlevel(level);
                                    }}
                                    propsfiltertag={(tags) => {
                                        setfiltertag(tags);
                                    }}
                                    propsfilterlimit={(filterlimit) => {
                                        setfilterlimit(filterlimit);
                                    }}
                                />
                                {/* <div className="card card-flush mb-xl-8" data-select2-id="select2-data-160-5h6n">
                                    <div className="card-body pt-0">
                                        <div className="table-responsive">
                                            <div id="kt_profile_overview_table" className="dataTables_wrapper dt-bootstrap4 no-footer leveltable team-list-table">
                                                <div className="table-responsive tags-pagination">
                                                    <div className="card-body p-0 bg-white radious-style radious-users mt-5 activity_page">
                                                        <RemotePagination key={columns} data={newlyAddedChapter} columns={columns} page={filterpage} sizePerPage={filterlimit} totalSize={newlyAddedChapter.length} onTableChange={handleTableChange} />
                                                       
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
            <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
                <span className="svg-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="black" />
                        <path
                            d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                            fill="black"
                        />
                    </svg>
                </span>
            </div>
        </div>
    );
};
export default Activity;