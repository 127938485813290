import React, { useEffect, useState, useCallback } from "react";
import { Link} from "react-router-dom";
import { Modal, notification } from "antd";
import ApplicationItem from "./ApplicationItem";

import { getApplicationList, deleteApplication, rejectApplication, assignTwoUser, getApplicationListByUser, getTag, getActiveMember, getRoundOneList, getRoundTwoList } from "../../Api/user/userapi";

import LeftSideBar from "../../Constant/LeftSideBar";
import TopBar from "../../Constant/TopBar";
import Footer from "../../Constant/Footer";
import RejectApplication from "./Modal/RejectApplication";
import AcceptApplication from "./Modal/AcceptApplication";

const Application = () => {
    const [loading, setLoading] = useState(false);

    const [array, setArray] = useState([]);
    const [id, setId] = useState();
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
    const [isAcceptModalVisible, setIsAcceptModalVisible] = useState(false);

    const [editChapterData, setEditChapterData] = useState({});

    const user_info = JSON.parse(localStorage.getItem("gpt_authuser"));
    const user_role = JSON.parse(localStorage.getItem("gpt_user_role"));

    const [filterlimit, setfilterlimit] = useState(10);
    const [filterpage, setfilterpage] = useState(1);
    const [filtersort, setfiltersort] = useState("_id");
    const [filterorder, setfilterorder] = useState("desc");
    const [filtersearch, setfiltersearch] = useState("");
    const [membercount, setmembercount] = useState(0);

    const [filtergender, setfiltergender] = useState("");
    const [filterstatus, setfilterstatus] = useState("");
    const [filtertag, setfiltertag] = useState("");
    const [filterMember, setfilterMember] = useState("");
    const [filterage, setfilterage] = useState("");
    const [Tags, setTags] = useState([]);
    const [Assignuserdata, setAssignuserdata] = useState([]); 
    const [memberId, setmemberId] = useState("");
    const [selectedValue, setselectedValue] = useState([]);
    const [arrayulrvalue, setarrayulrvalue] = useState([]);
    const [permvalue, setpermvalue] = useState("");
    const [applicationtype, setapplicationtype] = useState("normal");
   
    const GetApplication = useCallback(async () => {
        try {
            setLoading(true);           
            const queryParams = new URLSearchParams(window.location.search);
            const arrayulr = [];
            if (filtersearch !== ''){
                arrayulr.push({
                    value: filtersearch,
                    label:'search'
                })
            }
            if (filtergender !== '') {
                arrayulr.push({
                    value: filtergender,
                    label: 'gender'
                })
            }
            if (filterstatus !== '') {
                arrayulr.push({
                    value: filterstatus,
                    label: 'status'
                })
            }
            if (filterage !== '') {
                arrayulr.push({
                    value: filterage,
                    label: 'age'
                })
            }
            if (filtertag !== '' && filtertag.length > 0) {
                arrayulr.push({
                    value: filtertag,
                    label: 'tagid'
                })
            }
            if (filterMember !== '' && filterMember.length > 0) {
                arrayulr.push({
                    value: filterMember,
                    label: 'filterMemberId'
                })
            }

            if (filterorder !== '') {
                arrayulr.push({
                    value: filterorder,
                    label: 'order'
                })
            }

            if (filtersort !== '') {
                arrayulr.push({
                    value: filtersort,
                    label: 'sortBy'
                })
            }
            setarrayulrvalue(arrayulr);
            localStorage.setItem("gpt_application_filter", JSON.stringify(arrayulr));
            if (queryParams.get("applicationtype") === 'round1') {
                const getmemberid = '';
                if (user_info && user_info.usertype !== "admin" && user_role && user_role.Allapplication !== "yes") {
                    const payload = {
                        search: filtersearch,
                        pageNumber: filterpage,
                        limit: filterlimit,
                        sortBy: filtersort,
                        order: filterorder,
                        gender: filtergender,
                        status: filterstatus,
                        tagid: filtertag,
                        filterMemberId: filterMember,
                        age: filterage,
                        memberId: user_info._id,
                    };
                   
                    const resp = await getRoundOneList(payload);
                    if (resp && resp !== "" && resp.data.success === true) {
                        const list = resp;
                        const arrayData = list.data.data;
                        setmembercount(list.data.count);
                        setArray(arrayData);
                        setLoading(false);
                    }
                    else {
                        setTimeout(() => {
                            notification.open({
                                message: "Application",
                                description: resp.data.message,
                            });
                        }, 1000);
                        setLoading(false);
                    }
                }
                else{
                    const payload = {
                        search: filtersearch,
                        pageNumber: filterpage,
                        limit: filterlimit,
                        sortBy: filtersort,
                        order: filterorder,
                        gender: filtergender,
                        status: filterstatus,
                        tagid: filtertag,
                        filterMemberId: filterMember,
                        age: filterage,
                        memberId: '',
                    };
                  
                    const resp = await getRoundOneList(payload);
                    if (resp && resp !== "" && resp.data.success === true) {
                        const list = resp;
                        const arrayData = list.data.data;
                        setmembercount(list.data.count);
                        setArray(arrayData);
                        setLoading(false);
                    }
                    else {
                        setTimeout(() => {
                            notification.open({
                                message: "Application",
                                description: resp.data.message,
                            });
                        }, 1000);
                        setLoading(false);
                    }
                }
               
            }
            else if (queryParams.get("applicationtype") === 'round2') {
                const getmemberid = '';
                if (user_info && user_info.usertype !== "admin" && user_role && user_role.Allapplication !== "yes") {
                    const payload = {
                        search: filtersearch,
                        pageNumber: filterpage,
                        limit: filterlimit,
                        sortBy: filtersort,
                        order: filterorder,
                        gender: filtergender,
                        status: filterstatus,
                        tagid: filtertag,
                        filterMemberId: filterMember,
                        age: filterage,
                        memberId: user_info._id,
                    };
                    const resp = await getRoundTwoList(payload);
                    if (resp && resp !== "" && resp.data.success === true) {
                        const list = resp;
                        const arrayData = list.data.data;
                        setmembercount(list.data.count);
                        setArray(arrayData);
                        setLoading(false);
                    }
                    else {
                        setTimeout(() => {
                            notification.open({
                                message: "Application",
                                description: resp.data.message,
                            });
                        }, 1000);
                        setLoading(false);
                    }
                }
                else{
                    const payload = {
                        search: filtersearch,
                        pageNumber: filterpage,
                        limit: filterlimit,
                        sortBy: filtersort,
                        order: filterorder,
                        gender: filtergender,
                        status: filterstatus,
                        tagid: filtertag,
                        filterMemberId: filterMember,
                        age: filterage,
                        memberId: '',
                    };
                    const resp = await getRoundTwoList(payload);
                    if (resp && resp !== "" && resp.data.success === true) {
                        const list = resp;
                        const arrayData = list.data.data;
                        setmembercount(list.data.count);
                        setArray(arrayData);
                        setLoading(false);
                    }
                    else {
                        setTimeout(() => {
                            notification.open({
                                message: "Application",
                                description: resp.data.message,
                            });
                        }, 1000);
                        setLoading(false);
                    }
                }
               
            }
            else{
                if (queryParams.get("applicationassinged") === 'tome') {
                    const payload = {
                        search: filtersearch,
                        pageNumber: filterpage,
                        limit: filterlimit,
                        sortBy: filtersort,
                        order: filterorder,
                        gender: filtergender,
                        status: filterstatus,
                        age: filterage,
                        tagid: filtertag,
                        filterMemberId: filterMember,
                        memberId: user_info._id,
                    };
                    const resp = await getApplicationListByUser(payload);
                    if (resp && resp !== "" && resp.data.success === true) {
                        const list = resp;
                        const arrayData = list.data.data;
                        setmembercount(list.data.count);
                        setArray(arrayData);
                        setLoading(false);
                    }
                    else {
                        setTimeout(() => {
                            notification.open({
                                message: "Application",
                                description: resp.data.message,
                            });
                        }, 1000);
                        setLoading(false);
                    }
                 }
                 else if (user_info && user_info.usertype !== "admin" && user_role && user_role.Allapplication !== "yes") {
                    const payload = {
                        search: filtersearch,
                        pageNumber: filterpage,
                        limit: filterlimit,
                        sortBy: filtersort,
                        order: filterorder,
                        gender: filtergender,
                        status: filterstatus,
                        age: filterage,
                        tagid: filtertag,
                        filterMemberId: filterMember,
                        memberId: user_info._id,
                    };
                    const resp = await getApplicationListByUser(payload);
                    if (resp && resp !== "" && resp.data.success === true) {
                        const list = resp;
                        const arrayData = list.data.data;
                        setmembercount(list.data.count);
                        setArray(arrayData);
                        setLoading(false);
                    }
                    else {
                        setTimeout(() => {
                            notification.open({
                                message: "Application",
                                description: resp.data.message,
                            });
                        }, 1000);
                        setLoading(false);
                    }
                } else {
                    const payload = {
                        search: filtersearch,
                        pageNumber: filterpage,
                        limit: filterlimit,
                        sortBy: filtersort,
                        order: filterorder,
                        gender: filtergender,
                        status: filterstatus,
                        tagid: filtertag,
                        filterMemberId: filterMember,
                        age: filterage,
                        memberId: '',
                    };
                    
                    const resp = await getApplicationList(payload);
                    if (resp && resp !== "" && resp.data.success === true) {
                        const list = resp;
                        const arrayData = list.data.data;
                        setmembercount(list.data.count);
                        setArray(arrayData);
                        setLoading(false);
                    }
                    else {
                        setTimeout(() => {
                            notification.open({
                                message: "Application",
                                description: resp.data.message,
                            });
                        }, 1000);
                        setLoading(false);
                    }
                }
            }
        } catch (e) { }
        setLoading(false);
    }, [filtersearch, filterpage, filterlimit, filtersort, filterorder, filtergender, filterstatus, filterage, filtertag, filterMember,, memberId]);

    useEffect(() => {
        GetApplication();
        userTags();
        getAllMemberList();
        
        if (user_info && user_info.usertype !== "admin" && user_role && user_role.Allapplication !== "yes") {
            setmemberId(user_info._id);
        }
        const queryParams = new URLSearchParams(window.location.search);
        if (queryParams.get("applicationtype") === 'round1') {
            setapplicationtype('round1')
        }
        if (queryParams.get("applicationtype") === 'round2') {
            setapplicationtype('round2')
        }
    }, [GetApplication, filtersearch, filterpage, filterlimit, filtersort, filterorder, filtergender, filterstatus, filterage, filtertag, filterMember,, memberId]);
    
    const userTags = async () => {
        try {
            const resp = await getTag();
            if (resp && resp.status === 200) {
                const list = resp;
                const arrayData = list.data.data;
                arrayData.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1)
                let MemberId_option = [];
                for (let key in arrayData) {
                    MemberId_option.push({
                        label: arrayData[key].name,
                        value: arrayData[key]._id,
                    });
                }
                setTags(MemberId_option);
            }
        } catch (e) {
        }
    };

    const getAllMemberList = async () => {
        try {
            const resp = await getActiveMember();
            if (resp && resp.status === 200) {
                const list = resp;
                const arrayData = list.data.data;
                let MemberId_option = [];
                for (let key in arrayData) {
                    MemberId_option.push({
                        value: arrayData[key]._id,
                        label: arrayData[key].userName,
                    });
                }
                setAssignuserdata(MemberId_option);
            }
        } catch (e) {
        }
    };

    const handleCancel = () => {
        setIsEditModalVisible(false);
        setIsRejectModalVisible(false);
        setIsAcceptModalVisible(false);
    };

    const showRejectModalHandle = (data) => {
        setIsRejectModalVisible(true);
        setEditChapterData(data);
        setId(data._id);
    };
    const showAcceptModalHandle = (data) => {
        setIsAcceptModalVisible(true);
        setEditChapterData(data);
        setId(data._id);
    };
    async function RejectApplicationSubmit(value) {
        const payload = {
            applicaionId: value,
            created_id: user_info._id,
        };
        try {
            const resp = await rejectApplication(payload);
            if (resp && resp.data.success === true) {
                setTimeout(() => {
                    notification.open({
                        message: "Application",
                        description: "Application rejected successfully",
                    });
                }, 1000);
                GetApplication();
                setIsRejectModalVisible(false);
            } else if (resp && resp.data.success === false) {
                setTimeout(() => {
                    notification.open({
                        message: "Application",
                        description: resp.data.message,
                    });
                }, 1000);
            }
        } catch (e) {
            console.log(e);
        }
    }
    async function AcceptApplicationSubmit(value) {
        const payload = {
            applicaionId: value,
            created_id: user_info._id,
        };
        try {
            const resp = await assignTwoUser(payload);
            if (resp && resp.data.success === true) {
                setTimeout(() => {
                    notification.open({
                        message: "Application",
                        description: "Application accepted successfully",
                    });
                }, 1000);
                GetApplication();
                setIsAcceptModalVisible(false);
            } else if (resp && resp.data.success === false) {
                setTimeout(() => {
                    notification.open({
                        message: "Application",
                        description: resp.data.message,
                    });
                }, 1000);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const removeChapterHandle = async (id) => {
        const Payload = {
            applicaionId: id,
            created_id: user_info._id,
        };

        try {
            const resp = await deleteApplication(Payload);
            if (resp && resp.status === 200) {
                setTimeout(() => {
                    notification.open({
                        message: "Application",
                        description: "Deleted successfully.",
                    });
                }, 1000);
                GetApplication();
            }
            else {
                setTimeout(() => {
                    notification.open({
                        message: "Application",
                        description: resp.data.message,
                    });
                }, 1000);
                setLoading(false);
            }
        } catch (e) {
            setTimeout(() => {
                notification.open({
                    message: "Application",
                    description: "Application not Deleted..",
                });
            }, 1000);
        }
    };
    // if (arrayulrvalue.length > 0){
    //     console.log('arrayulrvalue====', arrayulrvalue)
    //     const stringData = arrayulrvalue.map(({ value, label }) => `${label}=${value}`).join('&');
    //     setpermvalue(stringData);
    // }
    

    return (
        <div className="d-flex flex-column flex-root header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed h-100">
            <div className="page d-flex flex-row flex-column-fluid">
                <LeftSideBar />
                <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                    <TopBar />
                    <div className="toolbar" id="kt_toolbar">
                        <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                            <div
                                data-kt-swapper="true"
                                data-kt-swapper-mode="prepend"
                                data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
                                className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
                            >
                                <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
                                    Applications
                                    <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                                </h1>

                                <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                                    <li className="breadcrumb-item text-muted">
                                        <Link to="/dashboard" className="text-muted text-hover-primary">
                                            Home
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <span className="bullet bg-gray-200 w-5px h-2px"></span>
                                    </li>
                                    <li className="breadcrumb-item text-dark">Applications</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                        <div className="post d-flex flex-column-fluid" id="kt_post">
                            <div id="kt_content_container" className="container-xxl">
                                <ApplicationItem
                                    membercount={membercount}
                                    filterlimit={filterlimit}
                                    filterpage={filterpage}
                                    data={array}
                                    Tags={Tags}
                                    AllapplicationList={true} 
                                    Assignuserdata={Assignuserdata}
                                    loading={loading}
                                    selectedValue={selectedValue}
                                    arrayulrvalue={arrayulrvalue}
                                    permvalue={permvalue}
                                    applicationtype={applicationtype}
                                    //Levels={Levels}
                                    filtersort={filtersort}
                                    filterorder={filterorder}
                                    propsfiltersort={(field) => setfiltersort(field)}
                                    propsfilterorder={(order) => setfilterorder(order)}

                                    propsfilterpage={(event) => setfilterpage(event)}
                                    propsfiltersearch={(event) => setfiltersearch(event)}
                                    removeChapterHandle={(event) => removeChapterHandle(event)}
                                    showRejectModalHandle={(event) => showRejectModalHandle(event)}
                                    showAcceptModalHandle={(event) => showAcceptModalHandle(event)}
                                    propsfiltergender={(gender) => {
                                        setfiltergender(gender);
                                    }}
                                    propsfilterstatus={(status) => {
                                        setfilterstatus(status);
                                    }}
                                    propsfilterage={(age) => {
                                        setfilterage(age);
                                    }}

                                    propsfilterlimit={(filterlimit) => {
                                        setfilterlimit(filterlimit);
                                    }}
                                    propsfiltertag={(e) => {
                                        const myArray = [];
                                        for (let key in e) {
                                            if (e[key].value === '0') {
                                                const tagsvalue = Tags;
                                                for (let keys in tagsvalue) {
                                                    if (tagsvalue[keys].value !== '0' && e[key].value !== tagsvalue[keys].value) {
                                                        myArray.push(tagsvalue[keys].value);
                                                    }
                                                }

                                            }
                                            else {
                                                myArray.push(e[key].value);
                                            }
                                        }
                                        const resultvalue = myArray.filter((v, i, a) => a.indexOf(v) === i);
                                        setselectedValue(resultvalue);
                                        if (resultvalue.length > 0){
                                            setfiltertag(resultvalue);  
                                        }
                                        else{
                                            setfiltertag('');
                                        }
                                    }}
                                    propsfilterMember={(e) => {
                                        const myArray = [];
                                        for (let key in e) {
                                            if (e[key].value === '0') {
                                                const values = Assignuserdata;
                                                for (let keys in values) {
                                                    if (values[keys].value !== '0' && e[key].value !== values[keys].value) {
                                                        myArray.push(values[keys].value);
                                                    }
                                                }

                                            }
                                            else {
                                                myArray.push(e[key].value);
                                            }
                                        }
                                        const resultvalue = myArray.filter((v, i, a) => a.indexOf(v) === i);
                                        setselectedValue(resultvalue);
                                        if (resultvalue.length > 0){
                                            setfilterMember(resultvalue);  
                                        }
                                        else{
                                            setfilterMember('');
                                        }
                                    }}
                                />
                                
                            </div>
                        </div>
						
                    </div>
					<Footer />
                </div>
            </div>

            <Modal footer={null} title="Reject Application" visible={isRejectModalVisible} onCancel={handleCancel}>
                <RejectApplication
                    chapterId={id}
                    setIsRejectModalVisible={setIsRejectModalVisible}
                    showData={array}
                    editChapterData={editChapterData}
                    messagedata="Are you sure you want to reject this application?"
                    RejectApplicationSubmit={(event) => RejectApplicationSubmit(event)}
                />
            </Modal>
            <Modal footer={null} title="Accept Application" visible={isAcceptModalVisible} onCancel={handleCancel}>
                <AcceptApplication
                    chapterId={id}
                    setIsAcceptModalVisible={setIsAcceptModalVisible}
                    showData={array}
                    editChapterData={editChapterData}
                    messagedata="Are you sure you want to Accept this application?"
                    AcceptApplicationSubmit={(event) => AcceptApplicationSubmit(event)}
                />
            </Modal>
            <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
                <span className="svg-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="black" />
                        <path
                            d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                            fill="black"
                        />
                    </svg>
                </span>
            </div>
        </div>
    );
};

export default Application;
