import React, { useState } from "react";

// import Moment from 'moment';
import { Link } from "react-router-dom";
import Select from "react-select";

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";
import { Spin,  Tooltip } from "antd";

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#ffdbdb" : ""
        },
        backgroundColor: state.isSelected ? "#cc0c0c" : "",
    }),
};

const SelectedApplicationItem = (props) => {
    // const user_role = JSON.parse(localStorage.getItem("gpt_user_role"));

    // const [ages, setages] = useState([]);
    const [tagvalue, settagvalue] = useState("");
    const [perpagedata, setperpagedata] = useState(10);
    const [agevalue, setagevalue] = useState("");
    const [gendervalue, setgendervalue] = useState("");
    const [statusvalue, setstatusvalue] = useState("");
    const getgender = [{ label: "Filter By Gender", value: "" }, { label: "Woman", value: "Woman" }, { label: "Man", value: "Man" }, { label: "Trans Woman", value: "Trans Woman" }, { label: "Trans Man", value: "Trans Man" }, { label: "Gender Queer", value: "Gender Queer" }, { label: "Gender Fluid", value: "Gender Fluid" }, { label: "Prefer Not To Disclose", value: "Prefer Not To Disclose" }];

    const getstatus = [{ label: "Filter By Status", value: "" }, { label: "Clarification Received", value: "Clarification Received" }, { label: "Clarification Requested", value: "Clarification Requested" }, { label: "To be reviewed ", value: "To be reviewed " }, { label: "Under Initial Review", value: "Under Initial Review" }, { label: "Initial review completed ", value: "Initial review completed " }, { label: "Under Final Review", value: "Under Final Review" }, { label: "Ready for interview", value: "Ready for interview" }, { label: "Interview Review", value: "Interview Review" }, { label: "Interview Reviewed", value: "Interview reviewed" }, { label: "Interview Round One Completed", value: "Interview Round One Completed" }, { label: "Rejected", value: "Rejected" }]; 
    //{ label: "Accepted", value: "Accepted" }

    const ages = [{ label: "Filter By Age", value: "" }, { label: "15-25", value: "15-25" }, { label: "26-35", value: "26-35" }, { label: "36-45", value: "36-45" }, { label: "46 an above", value: "46-90" }];

    // function cancel(e) {
    //     setTimeout(() => {
    //         notification.open({
    //             message: "Application",
    //             description: "Data is not deleted..",
    //         });
    //     }, 1000);
    //     // message.error('Data is not deleted');
    // }

    const [columns, setcolumns] = useState([
        {
            sort: true,
            onSort: (field, order) => {
                props.propsfiltersort(field);
                props.propsfilterorder(order);
            },

            text: "Name",
            dataField: "firstname",
            formatter: (cellContent, row, index, extraData) => {
                var geturls = JSON.parse(localStorage.getItem("gpt_application_filter"));
                var urlfilter = '';
                if (geturls.length > 0) {
                    urlfilter = geturls.map(({ value, label }) => `${label}=${value}`).join('&');
                    urlfilter = '?' + urlfilter;
                }
                return (
                    <div key={"firstname_" + row._id}>
                        {row && row.firstname ? (
                            <>
                                {row && row.flaggedstatus && row.flaggedstatus === '1' ? (<span className="text" >
                                    <Link to={`/flagged-application-review/${row._id}`}>
                                        {row.firstname} {row.lastname}
                                    </Link>

                                </span>):
                                    (<span className="text" >
                                        <Link to={`/application-detail/${row._id}${urlfilter}`}>
                                            {row.firstname} {row.lastname}
                                        </Link>

                                    </span>)}
                            </>
                            

                        ) : (
                            "--"
                        )}
                    </div>
                );
            },
        },

        {
            // sort: true,
            // onSort: (field, order) => {
            //     props.propsfiltersort(field);
            //     props.propsfilterorder(order);
            // },
            text: "Applied For",
            dataField: "TagName",
        },
        {
            sort: true,
            onSort: (field, order) => {
                props.propsfiltersort(field);
                props.propsfilterorder(order);
            },
            text: "Age",
            dataField: "age",
        },
        {
            sort: true,
            onSort: (field, order) => {
                props.propsfiltersort(field);
                props.propsfilterorder(order);
            },
            text: "Gender",
            dataField: "gender",
        },
        {
            sort: true,
            onSort: (field, order) => {
                props.propsfiltersort(field);
                props.propsfilterorder(order);
            },
            text: "Status",
            dataField: "status",
        },
        {
            text: "Actions",
            dataField: "actions",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <div key={"actions_" + row._id}>
                       
                           
                            <Tooltip title="Archive">
                                {/* onClick={() => props.showAcceptModalHandle(row)}  */}
                                <span onClick={() => props.showArchiveModalHandle(row, 1)}  className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"  ><i className="fa fa-archive"></i></span>
                            </Tooltip>
                    </div>
                );
            },

        },
    ])

    // show data with pagination
    const handleTableChange = (type, { sortField, sortOrder, page }) => {
        if (type === "sort") {
            props.propsfiltersort(sortField);
            props.propsfilterorder(sortOrder);
        }

        if (type === "pagination") {
            props.propsfilterpage(page);
        }
    };
    const RemotePagination = ({ columns, dataField, order, data, page, sizePerPage, onTableChange, totalSize }) => (
        <PaginationProvider
            pagination={paginationFactory({
                custom: true,
                page,
                sizePerPage,
                totalSize,

            })}
        >
            {({ paginationProps, paginationTableProps }) => (
                <>
                    <div className="table-responsive">
                        <BootstrapTable

                            remote
                            bootstrap4
                            data={data}
                            keyField="_id"
                            sort={{ dataField: dataField, order: order }}
                            columns={columns}
                            {...paginationTableProps}
                            onTableChange={onTableChange}
                            sizePerPageList={sizePerPage}
                            headerClasses="fw-bolder text-white bg-dark-row"
                            classes="table-bordered table-row-bordered table-row-dashed align-middle custom-table-style table-fields"
                            noDataIndication={() => <span className="text-center text-gray-400">No records found</span>}
                        />
                    </div>
                    <div className="d-flex flex-stack justify-content-between flex-wrap pt-3 pagination-main">
                        {totalSize > 0 && ( 
                        <div className="pagination_limt">
                            <select id='select' name='group' className='form-control input-sm form-select form-select-sm form-select-solid' size='1' value={perpagedata}
                                onChange={(event) => {
                                    props.propsfilterpage(1);
                                    setperpagedata(event.target.value);
                                    props.propsfilterlimit(parseInt(event.target.value));
                                }}>
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={250}>250</option>
                                <option value={500}>500</option>
                            </select>
                                {(parseInt(sizePerPage) * parseInt(page)) > totalSize ? (
                                    <div className="count_number">Displaying {(parseInt(sizePerPage) * parseInt(page) + parseInt(1)) - (parseInt(perpagedata))}-{totalSize} of {totalSize} records</div>
                                ) : (
                                    <div className="count_number">Displaying {(parseInt(sizePerPage) * parseInt(page) + parseInt(1)) - (parseInt(perpagedata))}-{parseInt(sizePerPage) * parseInt(page)} of {totalSize} records</div>
                                )}
                        </div>
                        )}
                        <div className="pagination_page">
                            <PaginationListStandalone {...paginationProps} />
                        </div>
                    </div>
                </>
            )}
        </PaginationProvider>
    );

    return (
        <React.Fragment>
            <div className="card card-flush mb-xl-8" data-select2-id="select2-data-160-5h6n">
                <div className="card-header mt-5">
                    <div className="card-title flex-column">
                        
                    </div>
                    <div className="card-toolbar my-1 team-member-box" data-select2-id="select2-data-159-5lwa">
                        <div className="me-4 my-1 app-one">
                            <Select
                                styles={customStyles}
                                className="react-bootstrap-typeahead tag-outer"
                                placeholder="Filter By Applied for"
                                options={props.Tags}
                                value={props.Tags.filter(obj => props.selectedValue.includes(obj.value))} // set selected values
                                isMulti
                                isClearable

                                onChange={(event) => {
                                    settagvalue(event.value);
                                    props.propsfilterpage(1);
                                    props.propsfiltertag(event);
                                }}
                            >

                            </Select>
                        </div>
                        <div className="me-4 my-1 app-one">
                            <Select
                                styles={customStyles}
                                className="react-bootstrap-typeahead tag-outer"
                                placeholder="Filter By Age"
                                options={ages}
                                value={ages.filter(function (option) {
                                    return option.value === agevalue;
                                })}
                                onChange={(event) => {
                                    setagevalue(event.value);
                                    props.propsfilterpage(1);
                                    props.propsfilterage(event.value);
                                }}
                            >

                            </Select>
                        </div>
                        <div className="me-4 my-1 app-one">
                            <Select
                                styles={customStyles}
                                className="react-bootstrap-typeahead tag-outer"
                                placeholder="Filter By Gender"
                                options={getgender}
                                value={getgender.filter(function (option) {
                                    return option.value === gendervalue;
                                })}
                                onChange={(event) => {
                                    setgendervalue(event.value);
                                    props.propsfilterpage(1);
                                    props.propsfiltergender(event.value);
                                }}
                            ></Select>

                        </div>
                        <div className="me-4 my-1 app-one">
                            <Select
                                styles={customStyles}
                                className="react-bootstrap-typeahead tag-outer"
                                placeholder="Filter By Status"
                                options={getstatus}
                                value={getstatus.filter(function (option) {
                                    return option.value === statusvalue;
                                })}
                                onChange={(event) => {
                                    setstatusvalue(event.value);
                                    props.propsfilterpage(1);
                                    props.propsfilterstatus(event.value);
                                }}
                            ></Select>
                        </div>
                        <div className="d-flex align-items-center position-relative my-1">
                            <span className="svg-icon svg-icon-3 position-absolute ms-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
                                    <path
                                        d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                        fill="black"
                                    ></path>
                                </svg>
                            </span>
                            <input type="text" className="form-control form-control-solid form-select-sm ps-9" placeholder="Search by Name / Email" onChange={(event) => {
                                props.propsfiltersearch(event.target.value); props.propsfilterpage(1);
                            }} />
                        </div>
                    </div>
                </div>
                <div className="card-body pt-0">
                    <div className="table-responsive">
                        <div id="kt_profile_overview_table" className="dataTables_wrapper dt-bootstrap4 no-footer team-list-table">
                            <div className="table-responsive">
                                <div className="card-body p-0 bg-white radious-style radious-users  mt-5">
                                    <Spin spinning={props.loading}>
                                    <RemotePagination data={props.data} 
                                    columns={columns} 
                                    page={props.filterpage} 
                                    totalSize={props.membercount} 
                                    sizePerPage={props.filterlimit}
                                    order={props.filterorder}
                                    dataField={props.filtersort} 
                                    onTableChange={handleTableChange} />
                                    </Spin>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >

    );
};

export default SelectedApplicationItem;