// Dev URL

// export const API_URL = 'https://girlpowertalk.dikonia.in/api/';
// export const Member_Image_URL = 'https://girlpowertalk.dikonia.in/uploads/member/';
// export const SITE_URL = 'https://girlpowertalk.dikonia.in/';

// export const API_URL = 'http://localhost:8003/api/';
// export const Member_Image_URL = 'http://localhost:8003/uploads/member/';
// export const SITE_URL = 'http://localhost:8003/';

//Live URL

export const API_URL = 'https://dashboard.girlpowertalk.com/api/';
export const Member_Image_URL = 'https://dashboard.girlpowertalk.com/uploads/member/';
export const SITE_URL = 'https://dashboard.girlpowertalk.com/';

export const SHOW_FILE = "SHOW_FILE"
export const LOGIN_USER = "LOGIN_USER"
export const LOGOUT_USER = "LOGOUT_USER"
