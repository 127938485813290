import React from "react";
import { notification } from "antd";
import { Link, Redirect } from "react-router-dom";
import { addSmtp, getSmtp } from "../../Api/user/userapi";

import LeftSideBar from "../../Constant/LeftSideBar";
import TopBar from "../../Constant/TopBar";
import Footer from "../../Constant/Footer";
import Validations from "../Utility/Validations";

const user_info = JSON.parse(localStorage.getItem("gpt_authuser"));

class SmtpSetting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            smtp_setting: {
                username: {
                    type: "text",
                    label: "Name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },

                password: {
                    type: "text",
                    label: "password",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                host: {
                    type: "text",
                    label: "host",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                port: {
                    type: "text",
                    label: "port",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                email: {
                    type: "text",
                    label: "email",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                created_id: {
                    value: user_info._id,
                    validations: { required: false },
                },

            },
            // emailcontent:'',
            loader: false,
            isAuthenticated: false,

        };

    }

    async componentDidMount() {

        const update_smtp_setting = { ...this.state.smtp_setting };
        this.setState({ smtp_setting: update_smtp_setting });
        this.MemberDetail();
    }
    MemberDetail = async () => {
        try {
            const resp = await getSmtp();
            if (resp && resp.status === 200) {
                const list = resp;
                const smtp_detail = list.data.data;
                const update_smtp_setting = { ...this.state.smtp_setting };
                for (let key in update_smtp_setting) {
                    let form_element = update_smtp_setting[key];
                    if (smtp_detail[key]) {
                        form_element.value = smtp_detail[key];
                        update_smtp_setting[key] = form_element;
                    }
                }
                this.setState({
                    loader: false,
                    smtp_setting: update_smtp_setting,
                });

            }
        } catch (e) {
        }
    };
    inputChangeHandler(event, identifier) {
        const update_smtp_setting = { ...this.state.smtp_setting };
        const form_element = { ...update_smtp_setting[identifier] };
        form_element.value = event.target.value;
        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_smtp_setting[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_smtp_setting) {
            is_form_valid = update_smtp_setting[identifier].valid && is_form_valid;
        }
        this.setState({
            smtp_setting: update_smtp_setting,
            is_form_valid: is_form_valid,
        });

    }
    handleChange(data, identifier, type) {
        const update_smtp_setting = { ...this.state.smtp_setting };
        update_smtp_setting[identifier].value = data.value;


        this.setState({ smtp_setting: update_smtp_setting });
    }
    handleChangeediter(content) {
        const update_smtp_setting = { ...this.state.smtp_setting };
        update_smtp_setting['content'].value = content;
        this.setState({ smtp_setting: update_smtp_setting });
    }

    SubmitHandler = async (event) => {
        event.preventDefault();
        let is_form_valid = true;
        const update_smtp_setting = this.state.smtp_setting;
        for (let key in update_smtp_setting) {
            let form_element = update_smtp_setting[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_smtp_setting[key] = form_element;
        }
        this.setState({ smtp_setting: update_smtp_setting });
        console.log('this.state.smtp_setting===', this.state.smtp_setting)
        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.smtp_setting) {
                form_data[key] = this.state.smtp_setting[key].value;
            }
            try {
                const result = await addSmtp(form_data);
                if (result && result.data.success === true) {
                    setTimeout(() => {                        
                        notification.open({
                            message: "SMTP Setting",
                            description: result.data.message,
                        });
                    }, 1000);

                } else if (result && result.data.success === false) {
                    setTimeout(() => {
                        notification.open({
                            message: "SMTP Setting",
                            description: result.data.message,
                        });
                    }, 1000);
                }
            } catch (e) {
            }

        }
    };
    render() {
        const { smtp_setting } = this.state;
        return (
            <React.Fragment>
                {this.state.isAuthenticated ? <Redirect to={this.state.redirect_url} /> : null}
                <div className="d-flex flex-column flex-root header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed h-100">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <LeftSideBar />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <TopBar />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="toolbar" id="kt_toolbar">
                                    <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                                        <div
                                            data-kt-swapper="true"
                                            data-kt-swapper-mode="prepend"
                                            data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
                                            className="page-username d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
                                        >
                                            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
                                                SMTP Setting
                                                <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                                            </h1>

                                            <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                                                <li className="breadcrumb-item text-muted">
                                                    <Link to="/dashboard" className="text-muted text-hover-primary">
                                                        Home
                                                    </Link>
                                                </li>

                                                <li className="breadcrumb-item">
                                                    <span className="bullet bg-gray-200 w-5px h-2px"></span>
                                                </li>

                                                <li className="breadcrumb-item text-dark">SMTP Setting</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-xxl">
                                        <form name="normal_login" className="login-form" onSubmit={this.SubmitHandler}>
                                            <div className="row gy-5 g-xl-8">
                                                <div className="col-xl-12">
                                                    <div className="card card-xl-stretch mb-xl-8">
                                                        <div className="card-body pt-2">
                                                            <div className="row">
                                                                <div className="col-lg-6 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label required fw-bold fs-6">Host</label>

                                                                    <input placeholder="smtp.gmail.com" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                                        value={smtp_setting.host.value ? smtp_setting.host.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "host")}
                                                                    />
                                                                    {!smtp_setting.host.valid && smtp_setting.host.onBlur_out ? <div className="error field-error">{smtp_setting.host.error_msg}</div> : ""}
                                                                    <div className="fv-plugins-message-container invalid-feedback"></div>
                                                                </div>
                                                                <div className="col-lg-6 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label required fw-bold fs-6">Port</label>

                                                                    <input placeholder="465" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                                        value={smtp_setting.port.value ? smtp_setting.port.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "port")}
                                                                    />
                                                                    {!smtp_setting.port.valid && smtp_setting.port.onBlur_out ? <div className="error field-error">{smtp_setting.port.error_msg}</div> : ""}
                                                                    <div className="fv-plugins-message-container invalid-feedback"></div>
                                                                </div>
                                                                <div className="col-lg-6 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label required fw-bold fs-6">From Email</label>

                                                                    <input placeholder="From Email" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                                        value={smtp_setting.email.value ? smtp_setting.email.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "email")}
                                                                    />
                                                                    {!smtp_setting.email.valid && smtp_setting.email.onBlur_out ? <div className="error field-error">{smtp_setting.email.error_msg}</div> : ""}
                                                                    <div className="fv-plugins-message-container invalid-feedback"></div>
                                                                </div>
                                                                <div className="col-lg-6 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label required fw-bold fs-6">User Name</label>

                                                                    <input placeholder="User Name" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                                        value={smtp_setting.username.value ? smtp_setting.username.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "username")}
                                                                    />
                                                                    {!smtp_setting.username.valid && smtp_setting.username.onBlur_out ? <div className="error field-error">{smtp_setting.username.error_msg}</div> : ""}
                                                                    <div className="fv-plugins-message-container invalid-feedback"></div>
                                                                </div>


                                                                <div className="col-lg-6 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label required fw-bold fs-6">password</label>

                                                                    <input placeholder="password" type="password" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" value={smtp_setting.password.value ? smtp_setting.password.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "password")}
                                                                    />
                                                                    {!smtp_setting.password.valid && smtp_setting.password.onBlur_out ? <div className="error field-error">{smtp_setting.password.error_msg}</div> : ""}
                                                                    <div className="fv-plugins-message-container invalid-feedback"></div>
                                                                </div>
                                                                <div className="col-lg-12 mt-8">
                                                                    <button type="submit" id="kt_new_password_submit" className="btn btn-lg btn-primary fw-bolder me-4">
                                                                        <span className="indicator-label">Save</span>
                                                                    </button>
                                                                    <Link to="#" className="btn btn-lg btn-light-primary fw-bolder">
                                                                        Cancel
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                       
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                    <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
                        <span className="svg-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="black" />
                                <path
                                    d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                    fill="black"
                                />
                            </svg>
                        </span>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default SmtpSetting;
