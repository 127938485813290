import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import { PlusSquareOutlined } from "@ant-design/icons";
import { Tooltip, Modal, Popconfirm, notification, Switch } from "antd";

import { addTag, deleteTag,  changeTagStatus, getAllTags } from "../../Api/user/userapi";
// import { DeleteOutlined, EditOutlined, PlusCircleOutlined } from "@ant-design/icons";

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import EditTag from "./EditTag";
import AddTag from "./AddTag";

import LeftSideBar from "../../Constant/LeftSideBar";
import TopBar from "../../Constant/TopBar";
import Footer from "../../Constant/Footer";

const Tag = (props) => {
    const [loading, setLoading] = useState(false);
    // const [Name, setName] = useState("");
    const [array, setArray] = useState([]);
    const [id, setId] = useState();
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [editChapterData, setEditChapterData] = useState({});
    const [isAddModalVisible, setIsAddModalVisible] = useState(false);
    const [filterlimit] = useState(5);
    const [filterpage, setfilterpage] = useState(1);
    const user_info = JSON.parse(localStorage.getItem("gpt_authuser"));

    useEffect(() => {
        userDetails();
    }, []);
    const userDetails = async () => {
        try {
            setLoading(true);

            const resp = await getAllTags();
            if (resp && resp.status === 200) {
                const list = resp;
                const arrayData = list.data.data;
                setArray(arrayData);
            }
        } catch (e) {
        }
        setLoading(false);
    };
    
    async function AddTagSubmit(data) {
        const payload = {
            name: data.name,
            created_id: user_info._id,
        };       
            try {
                setLoading(true);
                const res = await addTag(payload);
                if (res && res.status === 200) {
                    setTimeout(() => {
                        notification.open({
                            message: "Tag",
                            description: "Added successfully.",
                        });
                        userDetails();
                    }, 1000);
                    setIsAddModalVisible(false);
                }
                else if (res && res.data.success === false) {
                    setTimeout(() => {
                        notification.open({
                            message: "Tag",
                            description: res.data.message,
                        });
                    }, 1000);
                }
                // userDetails();
            } catch (e) {
                setTimeout(() => {
                    notification.open({
                        message: "Login",
                        description: "Wrong credentials.",
                    });
                }, 1000);

            } finally {
                setLoading(false);
            }
    };
    const handleCancel = () => {
        // setIsModalVisible(false);
        setIsEditModalVisible(false);
        setIsAddModalVisible(false);
        // setModalVisible(false)
        // message.error("Chapter is not deleted!");
    };
    function cancel(e) {
        setTimeout(() => {
            notification.open({
                message: "Tag",
                description: "Data is not deleted..",
            });
        }, 1000);
        // message.error('Data is not deleted');
    }
    const showEditModalHandle = (data) => {
        setIsEditModalVisible(true);
        setEditChapterData(data);
        setId(data._id);
    };
    const showAddModalHandle = () => {
        setIsAddModalVisible(true);
    };
    const handleChange = async (value, event) => {
        let status = "";
        if (event === false) {
            status = "inactive";
        } else {
            status = "active";
        }

        const Payload = {
            status: status,
            tagId: value,
            created_id: user_info._id,
        };
        console.log('Payload==', Payload)

        try {
            const resp = await changeTagStatus(Payload);
            console.log(resp, "rsp");
            if (resp && resp.status === 200) {
                setTimeout(() => {
                    notification.open({
                        message: "Tag",
                        description: "Status updated successfully.",
                    });
                }, 1000);
                userDetails();
                // message.success("updated successfully.");
                // userDetails();

                // setIsEditModalVisible(false);
            }
        } catch (e) {
            console.log(e);
        }
    }
    const removeChapterHandle = async (id) => {
        const Payload = {
            tagId: id,
            created_id: user_info._id,
        };
        try {
            const resp = await deleteTag(Payload);
            if (resp && resp.status === 200) {
                setTimeout(() => {
                    notification.open({
                        message: "Tag",
                        description: "Deleted successfully.",
                    });
                }, 1000);
                // message.success("deleted successfully");
                // showChapter();
                userDetails();
            }
        } catch (e) {
            // message.error("Role note Deleted..");
            setTimeout(() => {
                notification.open({
                    message: "Tag",
                    description: "Tag not Deleted..",
                });
            }, 1000);
        }
    };
    const [columns, setcolumns] = useState([
     
        {
            text: "Tag",
            dataField: "name",
            sort: true,
        },
        {
            text: "Actions",
            dataField: "actions",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <div key={row._id}
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            
                        }}>
                        <span
                            className="edit_icon me-2 cursor-pointer"
                            style={{
                                verticalAlign: "text-bottom",
                            }}
                        >
                            <Tooltip title="Edit">
                                <span className="" onClick={() => showEditModalHandle(row)} data-bs-placement="bottom" data-bs-original-title="Edit">
                                    <i className="bi bi-pencil-fill fs-7"></i>
                                </span>
                            </Tooltip>
                        </span>
                        <span className="switch-btn">
                            <Tooltip title="Active & Inactive">
                                {row.status === "active" ? <Switch
                                    defaultChecked
                                    onChange={(event) => {
                                        handleChange(row._id, event);
                                    }} /> : <Switch
                                    onChange={(event) => {
                                        handleChange(row._id, event)
                                    }}
                                />}
                            </Tooltip>
                        </span>
                        {/* <span>
                            <Tooltip title="Delete">
                                <Popconfirm title="Are you sure you want to delete?" onConfirm={() => removeChapterHandle(row._id)} onCancel={cancel} okText="Yes" cancelText="No">
                                    <span className="cursor-pointer">
                                        <i className="bi bi-trash fs-7"></i>
                                    </span>
                                </Popconfirm>
                            </Tooltip>
                        </span> */}
                    </div>
                );
            },
        },
    ])

    const newlyAddedChapter =
        array && array.length
            ? array.map((items) => {
                localStorage.setItem("rolesid", items.id);
                return {
                    ...items,
                };
            })
            : [];
    const handleTableChange = (type, { page, sizePerPage }) => {
        setfilterpage(page);
    };
    const RemotePagination = ({ columns, data, sizePerPage, page, totalSize, onTableChange }) => (

        <PaginationProvider
            pagination={paginationFactory()}
        >
            {({ paginationProps, paginationTableProps }) => (
                <>
                    <div className="table-responsive">
                        <BootstrapTable
                            keyField="_id"
                            bootstrap4
                            data={data}
                            columns={columns}
                            {...paginationTableProps}
                            onTableChange={onTableChange}
                            headerClasses="fw-bolder text-white bg-dark-row"
                            classes="table-bordered table-row-bordered align-middle custom-table-style table-fields"
                            noDataIndication={() => <span className="text-center text-gray-400">No records found</span>}
                        />
                    </div>
                    {/* <div className="d-flex flex-stack justify-content-end flex-wrap pt-3 pb-7 pagination-main ">
                        <PaginationListStandalone {...paginationProps} />
                    </div> */}
                </>
            )}
        </PaginationProvider>
    );
    return (
        <div className="d-flex flex-column flex-root header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed h-100">
            <div className="page d-flex flex-row flex-column-fluid">
                <LeftSideBar />
                <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                    <TopBar />

                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                        <div className="toolbar" id="kt_toolbar">
                            <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                                <div
                                    data-kt-swapper="true"
                                    data-kt-swapper-mode="prepend"
                                    data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
                                    className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
                                >
                                    <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
                                        Masters
                                        <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                                    </h1>

                                    <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                                        <li className="breadcrumb-item text-muted">
                                            <Link to="/dashboard" className="text-muted text-hover-primary">Home</Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <span className="bullet bg-gray-200 w-5px h-2px"></span>
                                        </li>
                                        

                                        <li className="breadcrumb-item text-dark">Tag</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="post d-flex flex-column-fluid" id="kt_post">
                            <div id="kt_content_container" className="container-xxl">
                                <div className="card card-flush mb-xl-8" data-select2-id="select2-data-160-5h6n">
                                    <div className="card-header mt-5">
                                        <div className="card-title flex-column">
                                            
                                        </div>
                                        <div className="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" title="" data-bs-original-title="Click to add a Level">
                                            <span className="btn btn-sm btn-primary btn-active-primary" onClick={() => showAddModalHandle()}>
                                                <i className="bi bi-plus fs-2"></i>
                                                Add Tag
                                            </span>
                                        </div>
                                    </div>
                                    <div className="card-body pt-0">
                                        <div className="table-responsive">
                                            <div id="kt_profile_overview_table" className="dataTables_wrapper dt-bootstrap4 no-footer leveltable team-list-table">
                                                <div className="table-responsive tags-pagination">                                                   
                                                    <RemotePagination key={columns} data={newlyAddedChapter} columns={columns} page={filterpage} sizePerPage={filterlimit} totalSize={newlyAddedChapter.length} onTableChange={handleTableChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                
                                
                            </div>
                        </div>
                    </div>
				<Footer />
                </div>
            </div>

            <Modal footer={null} title="Edit Tag" visible={isEditModalVisible} onCancel={handleCancel}>
                <EditTag chapterId={id} setIsEditModalVisible={setIsEditModalVisible} showData={userDetails} editChapterData={editChapterData} />
            </Modal>
            <Modal footer={null} title="Add Tag" visible={isAddModalVisible} onCancel={handleCancel}>
                <AddTag chapterId={id} setIsAddModalVisible={setIsAddModalVisible} showData={userDetails} AddTagSubmit={(event) => AddTagSubmit(event)} />
            </Modal>
            <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
                <span className="svg-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="black" />
                        <path
                            d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                            fill="black"
                        />
                    </svg>
                </span>
            </div>
        </div>
    );
};

export default Tag;
