import React, { useEffect, useState } from "react";

function CancleInterviewSchedule(props) {
    const user_info = JSON.parse(localStorage.getItem("gpt_authuser"));
    const { Calendardata,  CancelScheduleApplicationSubmit } = props;
    
    const showCancelSchedulePopup = (data) => {
        const payload = {
            applicaionId: Calendardata.applicationid,
            memberId: user_info._id,
            created_id: user_info._id,
        };
        CancelScheduleApplicationSubmit(payload);
    };
    return (
        <form name="normal_login" className="login-form" >
            <h4>Are you sure you want to cancel the interview?</h4>
            <div className="model text-right mt-3">
                <button type="button" className='btn btn-sm btn-primary fw-bolder' onClick={() =>
                        showCancelSchedulePopup(Calendardata)
                    } >Ok</button>
            </div>
        </form>
    );
}
export default CancleInterviewSchedule;