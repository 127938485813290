import React from "react";
import { Redirect } from "react-router-dom";
import EmailList from "../../Component/Email/Email";
import EditEmail from "../../Component/Email/EditEmail";
import AddEmail from "../../Component/Email/AddEmail";
class SettingEmail extends React.Component {
    render() {
        // const user_info = JSON.parse(localStorage.getItem("gpt_authuser"));
        const user_role = JSON.parse(localStorage.getItem("gpt_user_role"));
        const renderview = () => {
            if (this.props.match.path === "/email") {
                if ((user_role && user_role.settingEmail && user_role.settingEmail === "yes")) {
                    return <EmailList />;

                }
                else {
                    return <Redirect to="/dashboard" />;
                }
            }

            if (this.props.match.path === "/edit-email/:emailId") {
                if ((user_role && user_role.settingEmail && user_role.settingEmail === "yes")) {
                    return <EditEmail emailId={this.props.match.params.emailId} />;

                }
                else{
                    return <Redirect to="/dashboard" />;
                }

            }
            // if (this.props.match.path === "/repeated-application/:applicaionId") {
            //     // if (user_role && user_role.applicationView && user_role.applicationView === "yes") {
            //     return <RepeatedApplication applicaionId={this.props.match.params.applicaionId} />;

            //     // }
            //     // else {
            //     //     return <Redirect to="/dashboard" />;
            //     // }

            // }
            if (this.props.match.path === "/addemail") {
                if ((user_role && user_role.settingEmail && user_role.settingEmail === "yes")) {
                    return <AddEmail />;

                }
                else {
                    return <Redirect to="/dashboard" />;
                }

            }
        };

        return (
            <React.Fragment>
                {/* <Notifications /> */}
                {renderview()}
            </React.Fragment>
        );

    }
}
export default SettingEmail;