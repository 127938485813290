import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
// import { notification } from "antd";
import Validations from "../../Utility/Validations";
// import {  updateCourses } from "../../Api/Courses/course";
// import { assignManualUser } from "../../Api/user/userapi";


// const { TextArea } = Input;

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#ffdbdb" : ""
        },
        backgroundColor: state.isSelected ? "#cc0c0c" : "",
    }),
};

function ExternalMember(props) {
    const user_info = JSON.parse(localStorage.getItem("gpt_authuser"));
    const { ExternalMemberApplicationSubmit, isExternalMemberTypeModalVisible, editChapterData, getroundoneassignuser, getassignresult } = props;
    const [application_form, setapplication_form] = useState([]);
    const [courseValue, setCourseValue] = useState([]);
    const { applicaionId } = useParams();
    useEffect(() => {
        if (editChapterData) {
            let application_interview_assign = [];
            for (let key in getassignresult) {
                // let ValueId = getassignresult[key]['value'];
                // const answer_array = ValueId.split('!!');
                application_interview_assign.push(getassignresult[key]);
            }
            let userdata = [];
            for (let key in editChapterData) {
                let IDs = editChapterData[key]._id;
                let memberresult = application_interview_assign.includes(IDs);
                if (memberresult === false) {
                    userdata.push({
                        value: editChapterData[key]._id,
                        label: editChapterData[key].userName,
                    });
                }

            }
            setCourseValue(userdata);
        }
        setapplication_form({
            email: {
                label: "Email",
                type: "text",
                value: "",
                valid: true,
                error_msg: "",
                validations: { required: true },
            },
           
            
        });
    }, [editChapterData]);

    async function inputChangeHandler(event, identifier) {
        const update_application_form = { ...application_form };
        const form_element = { ...update_application_form[identifier] };

        form_element.value = event.value;

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_application_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_application_form) {
            is_form_valid = update_application_form[identifier].valid && is_form_valid;
        }
        setapplication_form(update_application_form);
    }
    const SubmitHandler = async (event) => {
        event.preventDefault();
        let is_form_valid = true;
        const update_application_form = application_form;
        for (let key in update_application_form) {
            let form_element = update_application_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;           
            update_application_form[key] = form_element;
        }
        if (is_form_valid) {
            const form_data = {};
            for (let key in application_form) {
                form_data[key] = application_form[key].value;
            }
            const payload = {
                applicaionId: applicaionId,
                memberId: user_info._id,
                externalMemberId: form_data.email,
                type: isExternalMemberTypeModalVisible,
                name:'test',
                created_id: user_info._id,
            };
            ExternalMemberApplicationSubmit(payload);

            const update_application_form = application_form;
            update_application_form.email.value = "";
            setapplication_form(update_application_form );
        }
        
    };
    return (
        <>
            <form name="normal_login" className="login-form" onSubmit={(event) => SubmitHandler(event)}>
                <Select
                    styles={customStyles}
                    className="react-bootstrap-typeahead tag-outer"
                    placeholder="Select a User..."
                    options={courseValue}
                    value={courseValue.filter(function (option) {
                        return option.value === application_form.email.value;
                    })}
                    onChange={(event) => inputChangeHandler(event, "email")}
                />
                {application_form.email && application_form.email.valid ? <div className="error field-error">{application_form.email.error_msg}</div> : ""} 
                <div className="text-center mt-6">
                    <button type="submit" className='btn btn-sm btn-primary fw-bolder'>Send</button>
                </div>
            </form>
        </>
    );
}

export default ExternalMember;
