import React from "react";
// import Select from "react-select";
// import { useFormik } from "formik";
// import { notification } from "antd";
import { Link, Redirect } from "react-router-dom";
import { getLevels, getTag, MemberDetail } from "../../Api/user/userapi";
import csc from "country-state-city";

import LeftSideBar from "../../Constant/LeftSideBar";
import TopBar from "../../Constant/TopBar";
import Footer from "../../Constant/Footer";
// import Validations from "../Utility/Validations";

class ViewMember extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            member_form: {
                id: {
                    label: "Member ID",
                    type: "hidden",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },
                userName: {
                    type: "text",
                    label: "Name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                level: {
                    type: "select",
                    label: "Select Level",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Select Level",
                    validations: { required: true },
                    options: [],
                },
                tags: {
                    type: "select",
                    label: "Select tags",
                    value: [],
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Select tags",
                    validations: { required: false },
                    options: [
                        { label: "Select tags", value: "" },
                    ],
                },
                age: {
                    type: "text",
                    label: "Age",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                position: {
                    type: "text",
                    label: "Position",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                zoomlink: {
                    type: "text",
                    label: "Link",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                gender: {
                    type: "select",
                    label: "Select gender",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Select gender",
                    validations: { required: true },
                    options: [
                        { label: "Filter By Gender", value: "" }, { label: "Woman", value: "Woman" }, { label: "Man", value: "Man" }, { label: "Trans Woman", value: "Trans Woman" }, { label: "Trans Man", value: "Trans Man" }, { label: "Gender Queer", value: "Gender Queer" }, { label: "Gender Fluid", value: "Gender Fluid" }, { label: "Prefer Not To Disclose", value: "Prefer Not To Disclose" },
                        // { label: "Select gender", value: "" },
                        // { label: "Male", value: "Male" },
                        // { label: "Female", value: "Female" },
                        // { label: "Transgender", value: "Transgender" },
                        // { label: "Non-Binary", value: "Non-Binary" },
                        // { label: "Other", value: "Other" },

                    ],
                },
                phone: {
                    type: "text",
                    label: "Phone",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                email: {
                    type: "email",
                    label: "Eail",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, email: true },
                },

                address: {
                    type: "text",
                    label: "Address",
                    value: "",
                    valid: false,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                country: {
                    type: "select",
                    label: "Select country",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Select country",
                    validations: { required: true },
                    options: [],
                },
                state: {
                    type: "select",
                    label: "Select state",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Select state",
                    validations: { required: true },
                    options: [],
                },
                city: {
                    type: "select",
                    label: "Select city",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Select city",
                    validations: { required: true },
                    options: [],
                },
                selectedValue: {
                    value: [],
                    validations: { required: false },
                },

            },

            loader: false,
            memberId: this.props.memberId,
            isAuthenticated: false,
            Levels: [],
            Members: [],

        };

    }

    async componentDidMount() {

        const update_member_form = { ...this.state.member_form };

        this.MemberDetail();
        this.userLevels();
        this.userDetails();


        const countries = csc.getAllCountries();
        let country = [];
        for (let key in countries) {
            country.push({
                value: countries[key].id,
                label: countries[key].name,
            });
        }
        update_member_form.country.options = country;
        this.setState({ member_form: update_member_form });
    }

    MemberDetail = async () => {
        try {
            const resp = await MemberDetail({ memberId: this.props.memberId });
            if (resp && resp.status === 200) {
                const list = resp;
                const member_detail = list.data.data;
                const update_member_form = { ...this.state.member_form };
                for (let key in update_member_form) {
                    let form_element = update_member_form[key];
                    if (member_detail[key]) {
                        if (key === 'tags') {
                            if (member_detail[key] !== '') {
                                const resultvalue = [];
                                for (let keys in member_detail[key]) {
                                    resultvalue.push(member_detail[key][keys]);
                                }
                                update_member_form.selectedValue.value = resultvalue;
                            }

                        }
                        if (key === 'country') {
                            this.getstate(member_detail[key]);
                        }
                        if (key === 'state') {
                            this.getcity(member_detail[key])
                        }



                        form_element.value = member_detail[key];
                        update_member_form[key] = form_element;
                    }
                }
                update_member_form.id.value = this.state.memberId;
                this.setState({
                    loader: false,
                    member_form: update_member_form,
                });

            }
        } catch (e) {
        }
    };
    getstate = async (countryId) => {
        const update_member_form = { ...this.state.member_form };
        const states = csc.getStatesOfCountry(countryId);
        let statevalue = [];
        for (let key in states) {
            statevalue.push({
                value: states[key].id,
                label: states[key].name,
            });
        }
        update_member_form.state.options = statevalue;
        this.setState({ member_form: update_member_form });
    };
    getcity = async (stateId) => {
        const update_member_form = { ...this.state.member_form };
        const citys = csc.getCitiesOfState(stateId);
        let cityvalue = [];
        for (let key in citys) {
            cityvalue.push({
                value: citys[key].id,
                label: citys[key].name,
            });
        }
        update_member_form.city.options = cityvalue;
        this.setState({ member_form: update_member_form });
    };
    userLevels = async () => {
        try {
            const resp = await getLevels();
            if (resp && resp.status === 200) {
                const list = resp;
                const all_bidtype = list.data.data;
                const update_member_form = { ...this.state.member_form };
                let LevelId_option = [];
                for (let key in all_bidtype) {
                    LevelId_option.push({
                        value: all_bidtype[key]._id,
                        label: all_bidtype[key].name,
                    });
                }
                update_member_form.level.options = LevelId_option;
                this.setState({ member_form: update_member_form });
                // this.setState({
                //     Levels: arrayData,
                // });
                // setLevels(arrayData);
            }
        } catch (e) {
        }
    };
    userDetails = async () => {
        try {

            const resp = await getTag();
            if (resp && resp.status === 200) {
                const list = resp;
                const arrayData = list.data.data;
                const update_member_form = { ...this.state.member_form };
                let MemberId_option = [];
                for (let key in arrayData) {
                    MemberId_option.push({
                        label: arrayData[key].name,
                        value: arrayData[key]._id,
                    });
                }
                update_member_form.tags.options = MemberId_option;
                this.setState({ member_form: update_member_form });
            }
        } catch (e) {
        }
    };
    
    render() {
        const { member_form } = this.state;
        return (
            <React.Fragment>
                {this.state.isAuthenticated ? <Redirect to={this.state.redirect_url} /> : null}
                <div className="d-flex flex-column flex-root header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed h-100">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <LeftSideBar />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <TopBar />

                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="toolbar" id="kt_toolbar">
                                    <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                                        <div
                                            data-kt-swapper="true"
                                            data-kt-swapper-mode="prepend"
                                            data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
                                            className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
                                        >
                                            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
                                                Team Members
                                                <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                                            </h1>

                                            <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                                                <li className="breadcrumb-item text-muted">
                                                    <Link to="/dashboard" className="text-muted text-hover-primary">
                                                        Home
                                                    </Link>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <span className="bullet bg-gray-200 w-5px h-2px"></span>
                                                </li>
                                                <li className="breadcrumb-item text-muted"> 
                                                    <Link to="/member" className="text-muted text-hover-primary">
                                                        Team Members
                                                    </Link>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <span className="bullet bg-gray-200 w-5px h-2px"></span>
                                                </li>

                                                <li className="breadcrumb-item text-dark">View Member</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-xxl">
                                        <form name="normal_login" className="login-form team-form" >
                                            <div className="row gy-5 g-xl-8">
                                                <div className="col-xl-12">
                                                    <div className="card card-xl-stretch mb-8">
                                                        <div className="card-header border-0">
                                                            <h3 className="card-title fw-bolder text-dark">Generals</h3>
                                                        </div>

                                                        <div className="card-body pt-2">
                                                            <div className="row">
                                                                <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label required fw-bold fs-6">Name</label>

                                                                    <div className="form-control form-control-lg form-control-solid mb-3 mb-lg-0">
                                                                        {member_form.userName.value ? member_form.userName.value : ""}
                                                                        </div>
                                                                </div>

                                                                <div className="col-lg-4 fv-row fv-plugins-icon-container ">
                                                                    <label className=" col-form-label  fw-bold fs-6">Select Level</label>

                                                                    {member_form.level.options.map((option) => (
                                                                        <>
                                                                            {option.value === member_form.level.value && (
                                                                                <div className="form-control form-control-lg form-control-solid mb-3 mb-lg-0">
                                                                                    {option.label}
                                                                                </div>

                                                                            )}
                                                                        </>
                                                                    ))}
                                                                </div>
                                                                <div id="tags-main" className="col-lg-4 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label  fw-bold fs-6">Tags</label>
                                                                    <div className="tagmaincalsss form-control form-control-lg form-control-solid mb-3 mb-lg-0" >
                                                                    {member_form.tags.options.map((option) => (
                                                                        <>
                                                                            {member_form.tags.value.includes(option.value )  && (
                                                                                <span className="view_tags">{option.label}</span>
                                                                               

                                                                            )}
                                                                        </>                                                                       
                                                                    ))}
                                                                    </div>
                                                                   
                                                                </div>

                                                                <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label fw-bold fs-6">Age</label>                                                                   
                                                                    <div className="form-control form-control-lg form-control-solid mb-3 mb-lg-0">
                                                                        {member_form.age.value ? member_form.age.value : ""}
                                                                    </div>
                                                                </div>

                                                                <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label  fw-bold fs-6">Position / Title</label>
                                                                  
                                                                    <div className="form-control form-control-lg form-control-solid mb-3 mb-lg-0">
                                                                        {member_form.position.value ? member_form.position.value : ""}
                                                                    </div>
                                                                </div>

                                                                <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label  fw-bold fs-6">Gender</label>
                                                                    {member_form.gender.options.map((option) => (
                                                                        <>
                                                                            {option.value === member_form.gender.value && (
                                                                                <div className="form-control form-control-lg form-control-solid mb-3 mb-lg-0">
                                                                       {option.label}
                                                                    </div>

                                                                            )}
                                                                        </>
                                                                    ))}
                                                                   
                                                                </div>
                                                                <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label fw-bold fs-6">Zoom Link</label>
                                                                    <div className="form-control form-control-lg form-control-solid mb-3 mb-lg-0">
                                                                        {member_form.zoomlink.value ? member_form.zoomlink.value : ""}
                                                                    </div>
                                                                </div>

                                                                <div className="col-lg-4 fv-row fv-plugins-icon-container"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row gy-5 g-xl-8">
                                                <div className="col-xl-12">
                                                    <div className="card card-xl-stretch mb-8">
                                                        <div className="card-header border-0">
                                                            <h3 className="card-title fw-bolder text-dark">Contact</h3>
                                                        </div>

                                                        <div className="card-body pt-2">
                                                            <div className="row">
                                                                <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label required fw-bold fs-6">Phone Number</label>

                                                                    <div className="form-control form-control-lg form-control-solid mb-3 mb-lg-0">
                                                                        {member_form.phone.value ? member_form.phone.value : ""}
                                                                    </div>
                                                                </div>

                                                                <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label fw-bold fs-6">Email</label>
                                                                    <div className="form-control form-control-lg form-control-solid mb-3 mb-lg-0">
                                                                        {member_form.email.value ? member_form.email.value : ""}
                                                                    </div>
                                                                </div>

                                                                <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label  fw-bold fs-6">Address</label>
                                                                    
                                                                    <div className="form-control form-control-lg form-control-solid mb-3 mb-lg-0">
                                                                        {member_form.address.value ? member_form.address.value : ""}
                                                                    </div>
                                                                </div>

                                                                <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label  fw-bold fs-6">Country</label>
                                                                    {member_form.country.options.map((option) => (
                                                                        <>
                                                                            {option.value === member_form.country.value && (
                                                                                <div className="form-control form-control-lg form-control-solid mb-3 mb-lg-0">
                                                                       {option.label}
                                                                    </div>

                                                                            )}
                                                                        </>
                                                                    ))}                                                                    
                                                                </div>

                                                                <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label  fw-bold fs-6">State</label>
                                                                    {member_form.state.options.map((option) => (
                                                                        <>
                                                                            {option.value === member_form.state.value && (
                                                                                <div className="form-control form-control-lg form-control-solid mb-3 mb-lg-0">
                                                                                    {option.label}
                                                                                </div>

                                                                            )}
                                                                        </>
                                                                    ))}
                                                                    
                                                                </div>

                                                                <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label  fw-bold fs-6">City</label>
                                                                    {member_form.city.options.map((option) => (
                                                                        <>
                                                                            {option.value === member_form.city.value && (
                                                                               
                                                                                <div className="form-control form-control-lg form-control-solid mb-3 mb-lg-0">
                                                                       {option.label}
                                                                    </div>

                                                                            )}
                                                                        </>
                                                                    ))}
                                                                   
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                       
                                    </div>
                                </div>
								
                            </div>
							 <Footer />
                        </div>
                    </div>
                    <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
                        <span className="svg-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="black" />
                                <path
                                    d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                    fill="black"
                                />
                            </svg>
                        </span>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ViewMember;
