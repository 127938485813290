import React from "react";
import moment from "moment-timezone";
import Select from "react-select";

import { Link, Redirect } from "react-router-dom";
import { Modal, notification } from "antd";
import { addWeeklyAvailability, getWeeklyAvailability, getDisableSlots, disableSlotsByTime, getActiveMember } from "../../Api/user/userapi";
import LeftSideBar from "../../Constant/LeftSideBar";
import TopBar from "../../Constant/TopBar";
import Footer from "../../Constant/Footer";
import Validations from "../Utility/Validations";
import TimezoneSelect, { allTimezones } from "react-timezone-select";
import CopyTimeSlot from "./Modal/CopyTimeSlot";
import DateOverridesMember from "./Modal/DateOverridesMember";
const user_info = JSON.parse(localStorage.getItem("gpt_authuser"));
const user_role = JSON.parse(localStorage.getItem("gpt_user_role"));
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#ffdbdb" : "",
        },
        backgroundColor: state.isSelected ? "#cc0c0c" : "",
    }),
};

class MembersAvailability extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email_form: {
                created_id: {
                    value: user_info._id,
                    validations: { required: false },
                },
                memberId: {
                    type: "text",
                    label: "Member Id",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                timezone: {
                    type: "text",
                    label: "Time Zone",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },

                Sun: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                Mon: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                Tue: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                Wed: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                Thu: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                Fri: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                Sat: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                Sun_startTime: {
                    valid: true,
                    error_msg: "",
                    value: [],
                    type: "text",
                    label: "Sun start time ",
                    isChecked: "",
                    validations: { required: false },
                },
                Sun_endTime: {
                    valid: true,
                    error_msg: "",
                    value: [],
                    type: "text",
                    label: "Sun end time",
                    isChecked: "",
                    validations: { required: false },
                },
                Mon_startTime: {
                    valid: true,
                    error_msg: "",
                    value: [],
                    type: "text",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                Mon_endTime: {
                    valid: true,
                    error_msg: "",
                    value: [],
                    type: "text",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                Tue_startTime: {
                    valid: true,
                    error_msg: "",
                    value: [],
                    type: "text",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                Tue_endTime: {
                    valid: true,
                    error_msg: "",
                    value: [],
                    type: "text",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                Wed_startTime: {
                    valid: true,
                    error_msg: "",
                    value: [],
                    type: "text",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                Wed_endTime: {
                    valid: true,
                    error_msg: "",
                    value: [],
                    type: "text",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                Thu_startTime: {
                    valid: true,
                    error_msg: "",
                    value: [],
                    type: "text",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                Thu_endTime: {
                    valid: true,
                    error_msg: "",
                    value: [],
                    type: "text",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                Fri_startTime: {
                    valid: true,
                    error_msg: "",
                    value: [],
                    type: "text",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                Fri_endTime: {
                    valid: true,
                    error_msg: "",
                    value: [],
                    type: "text",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                Sat_startTime: {
                    valid: true,
                    error_msg: "",
                    value: [],
                    type: "text",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
                Sat_endTime: {
                    valid: true,
                    error_msg: "",
                    value: [],
                    type: "text",
                    label: "",
                    isChecked: "",
                    validations: { required: false },
                },
            },
            loader: false,
            isAuthenticated: false,
            gettimeslot: [],
            timesloat: "",
            setcopytimestatus: false,
            isDateOverrides: false,
            setcopytimes: "",
            errorpostion: "",
            errortype: "",
            errormessage: "",
            statustyle: "",
            getslottime: [],
            setopupresult: 0,
            Assignuserdata: [],
            memberId: ""
        };
        // this.addsunday = this.addsunday.bind(this);
    }

    async componentDidMount() {
        let x = 30; //minutes interval
        var times = []; // time array
        var tt = 0; // start time
        var ap = ["AM", "PM"]; // AM-PM
        let gettimeslot = [];
        for (var i = 0; tt < 24 * 60; i++) {
            var hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
            var mm = tt % 60; // getting minutes of the hour in 0-55 format
            let hoursvalue = hh % 12;
            if (hoursvalue === 0) {
                hoursvalue = 12;
            }
            times[i] = ("0" + hoursvalue).slice(-2) + ":" + ("0" + mm).slice(-2) + " " + ap[Math.floor(hh / 12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]
            tt = tt + x;
            gettimeslot.push({
                value: times[i],
                label: times[i],
            });
        }
        this.setState({
            gettimeslot: gettimeslot,
        });
        this.getAllMemberList()
    }
    getAllMemberList = async () => {
        try {
            const resp = await getActiveMember();
            if (resp && resp.status === 200) {
                const list = resp;
                const arrayData = list.data.data;
                let LevelId_option = [];
                for (let key in arrayData) {
                    LevelId_option.push({
                        value: arrayData[key]._id,
                        label: arrayData[key].userName,
                    });
                }
                this.setState({ Assignuserdata: LevelId_option });
            }
        } catch (e) { }
    };
    memberInputChangeHandler = (event) => {
        this.setState({
            memberId: event.value
        })
        this.gettimeavailability(event.value);
        this.getDisableSlotsdata(event.value);
    }
    getDisableSlotsdata = async (memberId) => {
        if(!memberId) {
            memberId = this.state.memberId
        }
        try {
            const resp = await getDisableSlots({ memberId: memberId });
            if (resp && resp.status === 200) {
                const list = resp;
                const get_disable_time = list.data.response[0].field;

                let startarray = [];
                for (let key in get_disable_time) {
                    let timeextend = get_disable_time[key].slot.time;
                    let aftergettime = timeextend.split("-");
                    let startdate = moment.unix(aftergettime[0]).format("h:mm A");
                    let enddate = moment.unix(aftergettime[1]).format("h:mm A");
                    startarray.push({
                        label: startdate + " - " + enddate,
                        value: get_disable_time[key].slot.status,
                        id: timeextend,
                        date: get_disable_time[key].date,
                    });
                }
                this.setState({
                    getslottime: startarray,
                });
            }
        } catch (e) { }
    };
    gettimeavailability = async (memberId) => {
        try {
            const resp = await getWeeklyAvailability({ memberId: memberId });
            if (resp && resp.status === 200) {
                const list = resp;
                const member_detail = list.data.data.availability;
                const update_email_form = { ...this.state.email_form };
                for (let key in member_detail) {
                    if (member_detail[key].status !== 'active') {
                        continue
                    }
                    let keydata = member_detail[key].day;
                    let keydata_startdays = member_detail[key].day + "_startTime";
                    let keydata_endtdays = member_detail[key].day + "_endTime";
                    let form_element = update_email_form[keydata];
                    form_element.value = "yes";
                    form_element.isChecked = "checked";
                    let gettime = member_detail[key].time;
                    let startarray = [];
                    let endarray = [];
                    for (let keys in gettime) {
                        let aftergettime = gettime[keys].split(" - ");
                        startarray.push(aftergettime[0]);
                        endarray.push(aftergettime[1]);
                    }
                    update_email_form[keydata_startdays].value = startarray;
                    update_email_form[keydata_endtdays].value = endarray;
                }
                update_email_form["timezone"].value = list.data.data.timezone;
                this.setState({
                    loader: false,
                    email_form: update_email_form,
                });
            }
        } catch (e) { }
    };
    addcopytimeslot = async (identifier) => {
        this.setState({ setcopytimes: identifier });
        if (this.state.setcopytimestatus === false) {
            this.setState({ setcopytimestatus: true });
        } else if (this.state.setcopytimestatus === true && this.state.setcopytimes === identifier) {
            this.setState({ setcopytimestatus: false });
        } else {
            this.setState({ setcopytimestatus: false });
        }
    };
    addnewtimeslot = async (identifier) => {
        const update_email_form = { ...this.state.email_form };
        // update_email_form["date"].value = arg.dateStr;
        if (update_email_form[identifier].value === "yes") {
            let gettimeslotstart = [];
            let gettimeslotend = [];
            let lastvalue = "";
            let start_value = update_email_form[identifier + "_startTime"].value;
            let end_value = update_email_form[identifier + "_endTime"].value;
            let start_value_array = [];
            let end_value_array = [];
            for (let key in start_value) {
                //let getstartvalue = start_value[key];
                start_value_array.push(moment(start_value[key], "HH:mm A").unix());
                gettimeslotstart.push(start_value[key]);
            }
            for (let key in end_value) {
                //let getendvalue = end_value[key];
                end_value_array.push(moment(end_value[key], "HH:mm A").unix());
                gettimeslotend.push(end_value[key]);
            }
            const startvaluearray = Math.max(...start_value_array);
            const endvaluearray = Math.max(...end_value_array);
            const starttimeaddhoures = endvaluearray + 3600;
            const endtimeaddhoures = endvaluearray + 7200;
            gettimeslotstart.push(moment.unix(starttimeaddhoures).format("hh:mm A"));
            gettimeslotend.push(moment.unix(endtimeaddhoures).format("hh:mm A"));
            update_email_form[identifier + "_startTime"].value = gettimeslotstart;
            update_email_form[identifier + "_endTime"].value = gettimeslotend;
        }
        this.setState({
            email_form: update_email_form,
        });
    };

    handleCancel = () => {
        this.setState({ isDateOverrides: false });
        //this.setState({ getslottime: []})
        this.setState({
            setopupresult: 0,
        });
    };
    setopupresults = (value) => {
        this.setState({
            setopupresult: 0,
        });
    }
    adddataoverrides = () => {
        this.setState({
            setopupresult: 1,
        });
        this.setState({ isDateOverrides: true });
    };
    DeletDisableTimeSlots(event, identifier, index, date) {
        const update_email_form = { ...this.state.getslottime };
        const form_element = { ...update_email_form[index] };
        form_element.value = 0;
        update_email_form[index] = form_element;
        const getslottime = this.state.getslottime.map((item, i) => {
            const newData = i === index ? form_element : {};

            return {
                ...item,
                ...newData,
            };
        });
        const getdate = moment(date).format("Y-M-D");
        this.setState({ getslottime });
        const payload = {
            date: getdate, //this.state.overrides_form['date'].value,
            status: form_element.value,
            slot: form_element.id,
            memberId: this.state.memberId,
            created_id: user_info._id,
        };
        this.getdisableSlotsByTime(payload);
    }

    getdisableSlotsByTime = async (payload) => {
        try {
            let message = "";
            message = "Slot enabled successfully";
            const resp = await disableSlotsByTime(payload);
            if (resp && resp.data.success !== false) {
                setTimeout(() => {
                    notification.open({
                        message: "Availability",
                        description: message,
                    });
                });
                const list = resp;
                this.getDisableSlotsdata(this.state.memberId);
            } else {
                setTimeout(() => {
                    notification.open({
                        message: "Availability",
                        description: message,
                    });
                });
            }
        } catch (e) { }
    };
    inputChangeHandler(event, identifier) {
        const update_email_form = { ...this.state.email_form };
        const form_element = { ...update_email_form[identifier] };

        if (event.target.checked === true) {
            form_element.value = "yes";
            form_element.isChecked = event.target.checked;
            let gettimeslotstart = ["10:00 AM"];
            let gettimeslotend = ["06:00 PM"];

            update_email_form[identifier + "_startTime"].value = gettimeslotstart;
            update_email_form[identifier + "_endTime"].value = gettimeslotend;
        } else {
            form_element.value = "";
            form_element.isChecked = "";
            update_email_form[identifier + "_startTime"].value = [];
            update_email_form[identifier + "_endTime"].value = [];
        }

        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_email_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_email_form) {
            is_form_valid = update_email_form[identifier].valid && is_form_valid;
        }
        this.setState({ timesloat: identifier });
        this.setState({
            email_form: update_email_form,
            is_form_valid: is_form_valid,
        });
    }
    submitApplytimeslot = async (result) => {
        const types = this.state.setcopytimes;
        const update_email_form = this.state.email_form;
        const get_startTime_value = update_email_form[types + "_startTime"].value;
        const get_endTime_value = update_email_form[types + "_endTime"].value;

        if (result.Sun !== this.state.setcopytimes && result.Sun !== "") {
            update_email_form["Sun"].isChecked = true;
            update_email_form["Sun"].value = "yes";
            update_email_form["Sun_startTime"].value = get_startTime_value;
            update_email_form["Sun_endTime"].value = get_endTime_value;
        }
        if (result.Mon !== this.state.setcopytimes && result.Mon !== "") {
            update_email_form["Mon"].isChecked = true;
            update_email_form["Mon"].value = "yes";
            update_email_form["Mon_startTime"].value = get_startTime_value;
            update_email_form["Mon_endTime"].value = get_endTime_value;
        }
        if (result.Tue !== this.state.setcopytimes && result.Tue !== "") {
            update_email_form["Tue"].isChecked = true;
            update_email_form["Tue"].value = "yes";
            update_email_form["Tue_startTime"].value = get_startTime_value;
            update_email_form["Tue_endTime"].value = get_endTime_value;
        }
        if (result.Wed !== this.state.setcopytimes && result.Wed !== "") {
            update_email_form["Wed"].isChecked = true;
            update_email_form["Wed"].value = "yes";
            update_email_form["Wed_startTime"].value = get_startTime_value;
            update_email_form["Wed_endTime"].value = get_endTime_value;
        }
        if (result.Thu !== this.state.setcopytimes && result.Thu !== "") {
            update_email_form["Thu"].isChecked = true;
            update_email_form["Thu"].value = "yes";
            update_email_form["Thu_startTime"].value = get_startTime_value;
            update_email_form["Thu_endTime"].value = get_endTime_value;
        }
        if (result.Fri !== this.state.setcopytimes && result.Fri !== "") {
            update_email_form["Fri"].isChecked = true;
            update_email_form["Fri"].value = "yes";
            update_email_form["Fri_startTime"].value = get_startTime_value;
            update_email_form["Fri_endTime"].value = get_endTime_value;
        }
        if (result.Sat !== this.state.setcopytimes && result.Sat !== "") {
            update_email_form["Sat"].isChecked = true;
            update_email_form["Sat"].value = "yes";
            update_email_form["Sat_startTime"].value = get_startTime_value;
            update_email_form["Sat_endTime"].value = get_endTime_value;
        }

        for (let key in update_email_form) {
            let form_element = update_email_form[key];

            update_email_form[key] = form_element;
        }
        this.setState({ email_form: update_email_form });

        this.setState({ setcopytimestatus: false });
        this.setState({ setcopytimes: "" });
    };

    deletevalue(index_start_time, index_end_time, postion, type) {
        const update_email_form = { ...this.state.email_form };

        let start_time_value = [];
        const start_time_element = { ...update_email_form[index_start_time] };
        if (start_time_element.value.length > 0) {
            let start_time_index = 0;
            let key_values = start_time_element.value;
            for (let key in key_values) {
                if (start_time_index !== postion) {
                    start_time_value.push(key_values[key]);
                }
                start_time_index++;
            }
        }
        start_time_element.value = start_time_value;
        update_email_form[index_start_time] = start_time_element;

        let end_time_value = [];
        const end_time_element = { ...update_email_form[index_end_time] };
        if (end_time_element.value.length > 0) {
            let end_time_index = 0;
            let key_values = end_time_element.value;
            for (let key in key_values) {
                if (end_time_index !== postion) {
                    end_time_value.push(key_values[key]);
                }
                end_time_index++;
            }
        }
        end_time_element.value = end_time_value;
        update_email_form[index_end_time] = end_time_element;

        if (end_time_value.length === 0) {
            update_email_form[type].value = "";
            update_email_form[type].isChecked = "";
        }

        this.setState({ email_form: update_email_form });
    }

    handleChangeselect(data, identifier, postion) {
        const update_email_form = { ...this.state.email_form };
        update_email_form[identifier].value = data.value;

        this.setState({ email_form: update_email_form });
    }

    handleChange(event, identifier, postion, type) {
        const update_email_form = { ...this.state.email_form };
        const form_element = { ...update_email_form[identifier] };

        /*** Check timeslot validations Start ***/
        let index_start_time = type + "_startTime";
        let index_end_time = type + "_endTime";
        const start_time_element = { ...update_email_form[index_start_time] };
        const end_time_element = { ...update_email_form[index_end_time] };
        let timeslot_array = [];
        if (start_time_element.value.length > 1) {
            let start_time_values = start_time_element.value;
            for (let loop_key in start_time_values) {
                if (start_time_values[loop_key] && end_time_element.value[loop_key]) {
                    timeslot_array.push({
                        start_time: start_time_values[loop_key],
                        end_time: end_time_element.value[loop_key],
                    });
                }
            }
        }
        let errorpostion = postion;
        let errortype = type;
        let errormessage = "";
        this.setState({ errorpostion: errorpostion });
        this.setState({ errortype: errortype });
        this.setState({ errormessage: errormessage });
        if (start_time_element.value.length > 0) {
            for (let i = 0; i < start_time_element.value.length; i++) {
                let current_strtotime = moment(event.value, "HH:mm A").unix();
                let starttime_strtotime = moment(start_time_element.value[i], "HH:mm A").unix();
                let endtime_strtotime = moment(end_time_element.value[i], "HH:mm A").unix();
                //    console.log('==event==', event, '==identifier===', identifier, '==postion==', postion, '==type',type)
                //     console.log('current_strtotime===', event.value, '===starttime_strtotime===', start_time_element.value[i], '==endtime_strtotime==', end_time_element.value[i])

                //     console.log('start_time_element==', start_time_element.value[i], '===end_time_element==', end_time_element.value[i], '==index_start_time==', index_start_time, '==get possition==', i)

                let aftergettime = identifier.split("_");
                if (aftergettime[1] === 'startTime' && current_strtotime >= endtime_strtotime && postion === i) {
                    errormessage = "Times overlap with another set of times.";
                    this.setState({ errorpostion: errorpostion });
                    this.setState({ errortype: errortype });
                    this.setState({ errormessage: errormessage });
                }
                else if (aftergettime[1] === 'endTime' && current_strtotime <= starttime_strtotime && postion === i) {
                    errormessage = "Times overlap with another set of times.";
                    this.setState({ errorpostion: errorpostion });
                    this.setState({ errortype: errortype });
                    this.setState({ errormessage: errormessage });
                }
                // else if (current_strtotime >= endtime_strtotime && endtime_strtotime >= current_strtotime && postion === i) {
                else if (current_strtotime >= starttime_strtotime && endtime_strtotime >= current_strtotime && postion !== i) {
                    errormessage = "Times overlap with another set of times.";
                    this.setState({ errorpostion: errorpostion });
                    this.setState({ errortype: errortype });
                    this.setState({ errormessage: errormessage });
                }
                else if (start_time_element.value[i] !== "" && end_time_element.value[i] !== "" && postion === i) {
                    if (index_start_time === identifier && current_strtotime >= endtime_strtotime) {
                        errormessage = "Choose an end time later than the start time.";
                        this.setState({ errorpostion: errorpostion });
                        this.setState({ errortype: errortype });
                        this.setState({ errormessage: errormessage });
                    } else if (index_end_time === identifier && current_strtotime <= starttime_strtotime) {
                        errormessage = "Choose an end time later than the start time.";
                        this.setState({ errorpostion: errorpostion });
                        this.setState({ errortype: errortype });
                        this.setState({ errormessage: errormessage });
                    } else {
                        errormessage = "";
                    }
                } else {
                    errormessage = "";
                }

                // errorpostion
                // errortype
                // errormessage
            }
        }
        /*** Check timeslot validations End ***/

        if (form_element.value.length > 0) {
            let key_index = 0;
            let custom_value = [];
            let key_values = form_element.value;
            for (let key in key_values) {
                if (key_index === postion) {
                    custom_value.push(event.value);
                } else {
                    custom_value.push(key_values[key]);
                }
                key_index++;
            }
            form_element.value = custom_value;
        } else {
            form_element.value.push(event.value);
        }

        update_email_form[identifier] = form_element;

        this.setState({
            email_form: update_email_form,
        });
    }

    SubmitHandler = async (event) => {
        event.preventDefault();
        let is_form_valid = true;
        const update_email_form = this.state.email_form;
        for (let key in update_email_form) {
            let form_element = update_email_form[key];
            let validate_element = Validations(form_element);
            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_email_form[key] = form_element;
        }
        this.setState({ email_form: update_email_form });
        const errormessage = this.state.errormessage;
        if (errormessage !== "") {
            notification.open({
                message: "Availability",
                description: errormessage,
            });
        }
        if (is_form_valid && errormessage === "") {
            const form_data = {};
            const datatimezone = {};
            const daysArray = [];
            const startTimeArray = [];
            const endTimeArray = [];
            for (let key in this.state.email_form) {
                const resultvalue = this.state.email_form[key].value;
                if (key === "Sun_startTime") {
                    for (let Sun in resultvalue) {
                        if (resultvalue[Sun] !== "") {
                            daysArray.push("Sun");
                            startTimeArray.push({
                                Sun: resultvalue[Sun],
                            });
                        }
                    }
                }
                if (key === "Sun_endTime") {
                    for (let Sun in resultvalue) {
                        if (resultvalue[Sun] !== "") {
                            endTimeArray.push({
                                Sun: resultvalue[Sun],
                            });
                        }
                    }
                }
                if (key === "Mon_startTime") {
                    for (let Sun in resultvalue) {
                        if (resultvalue[Sun] !== "") {
                            daysArray.push("Mon");
                            startTimeArray.push({
                                Mon: resultvalue[Sun],
                            });
                        }
                    }
                }
                if (key === "Mon_endTime") {
                    for (let Sun in resultvalue) {
                        if (resultvalue[Sun] !== "") {
                            endTimeArray.push({
                                Mon: resultvalue[Sun],
                            });
                        }
                    }
                }
                if (key === "Tue_startTime") {
                    for (let Sun in resultvalue) {
                        if (resultvalue[Sun] !== "") {
                            daysArray.push("Tue");
                            startTimeArray.push({
                                Tue: resultvalue[Sun],
                            });
                        }
                    }
                }
                if (key === "Tue_endTime") {
                    for (let Sun in resultvalue) {
                        if (resultvalue[Sun] !== "") {
                            endTimeArray.push({
                                Tue: resultvalue[Sun],
                            });
                        }
                    }
                }
                if (key === "Wed_startTime") {
                    for (let Sun in resultvalue) {
                        if (resultvalue[Sun] !== "") {
                            daysArray.push("Wed");
                            startTimeArray.push({
                                Wed: resultvalue[Sun],
                            });
                        }
                    }
                }
                if (key === "Wed_endTime") {
                    for (let Sun in resultvalue) {
                        if (resultvalue[Sun] !== "") {
                            endTimeArray.push({
                                Wed: resultvalue[Sun],
                            });
                        }
                    }
                }
                if (key === "Thu_startTime") {
                    for (let Sun in resultvalue) {
                        if (resultvalue[Sun] !== "") {
                            daysArray.push("Thu");
                            startTimeArray.push({
                                Thu: resultvalue[Sun],
                            });
                        }
                    }
                }
                if (key === "Thu_endTime") {
                    for (let Sun in resultvalue) {
                        if (resultvalue[Sun] !== "") {
                            endTimeArray.push({
                                Thu: resultvalue[Sun],
                            });
                        }
                    }
                }
                if (key === "Fri_startTime") {
                    for (let Sun in resultvalue) {
                        if (resultvalue[Sun] !== "") {
                            daysArray.push("Fri");
                            startTimeArray.push({
                                Fri: resultvalue[Sun],
                            });
                        }
                    }
                }
                if (key === "Fri_endTime") {
                    for (let Sun in resultvalue) {
                        if (resultvalue[Sun] !== "") {
                            endTimeArray.push({
                                Fri: resultvalue[Sun],
                            });
                        }
                    }
                }
                if (key === "Sat_startTime") {
                    for (let Sun in resultvalue) {
                        if (resultvalue[Sun] !== "") {
                            daysArray.push("Sat");
                            startTimeArray.push({
                                Sat: resultvalue[Sun],
                            });
                        }
                    }
                }
                if (key === "Sat_endTime") {
                    for (let Sun in resultvalue) {
                        if (resultvalue[Sun] !== "") {
                            endTimeArray.push({
                                Sat: resultvalue[Sun],
                            });
                        }
                    }
                }
                if (key === "timezone") {
                    datatimezone[key] = this.state.email_form[key].value;
                }
                if (key === "memberId") {
                    form_data[key] = this.state.memberId;
                }
                if (key === "created_id") {
                    form_data[key] = this.state.email_form[key].value;
                }
            }
            form_data["data"] = datatimezone;
            form_data["daysArray"] = daysArray;
            form_data["startTimeArray"] = startTimeArray;
            form_data["endTimeArray"] = endTimeArray;
            try {
                const result = await addWeeklyAvailability(form_data);
                if (result && result.data.success === true) {
                    setTimeout(() => {
                        // this.setState({
                        //     isAuthenticated: true,
                        //     redirect_url: "/email",
                        // });
                        notification.open({
                            message: "Availability",
                            description: "Availability updated.",
                        });
                    }, 1000);
                    this.gettimeavailability(this.state.memberId);
                    this.getDisableSlotsdata(this.state.memberId);
                } else if (result && result.data.success === false) {
                    setTimeout(() => {
                        notification.open({
                            message: "Availability",
                            description: result.data.message,
                        });
                    }, 1000);
                }
            } catch (e) { }
        }
    };

    getDateByTimeZone = (item) => {
        if (this.state.email_form.timezone.value) {
            var date = new Date(0);
            date.setUTCSeconds(Number(item.id.split('-')[0]));
            return moment(date).tz(this.state.email_form.timezone.value).format("ddd, DD MMM YYYY")
        } else {
            return item.date
        }
    }

    getTimeByTimeZone = (item) => {
        if (this.state.email_form.timezone.value) {
            var start = new Date(0);
            start.setUTCSeconds(Number(item.id.split('-')[0]));
            var end = new Date(0);
            end.setUTCSeconds(Number(item.id.split('-')[1]));
            return moment(start).tz(this.state.email_form.timezone.value).format("hh:mm A") + ' - ' + moment(end).tz(this.state.email_form.timezone.value).format("hh:mm A")
        } else {
            return item.label
        }
    }

    render() {
        const { email_form, getslottime, Assignuserdata, memberId } = this.state;
        return (
            <React.Fragment>
                {this.state.isAuthenticated ? <Redirect to={this.state.redirect_url} /> : null}
                <div className="d-flex flex-column flex-root header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed h-100">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <LeftSideBar />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <TopBar />

                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="toolbar" id="kt_toolbar">
                                    <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                                        <div
                                            data-kt-swapper="true"
                                            data-kt-swapper-mode="prepend"
                                            data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
                                            className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
                                        >
                                            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
                                                Interview
                                                <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                                            </h1>

                                            <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                                                <li className="breadcrumb-item text-muted">
                                                    <Link to="/dashboard" className="text-muted text-hover-primary">
                                                        Home
                                                    </Link>
                                                </li>

                                                <li className="breadcrumb-item">
                                                    <span className="bullet bg-gray-200 w-5px h-2px"></span>
                                                </li>

                                                <li className="breadcrumb-item text-dark">Availability</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-xxl">
                                        <form name="normal_login" className="login-form" onSubmit={this.SubmitHandler}>
                                            <div className="row gy-5 g-xl-8">
                                                <div className="col-xl-12">
                                                    <div className="card card-xl-stretch mb-xl-8">
                                                        <div className="card-header border-0">
                                                            <h3 className="card-title fw-bolder text-dark">Set weekly hours</h3>
                                                            <div className="fv-plugins-icon-container">
                                                                <div className="col-lg-12 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label required fw-bold fs-6">Member</label>
                                                                    <Select
                                                                        styles={customStyles}
                                                                        name={"Member"}
                                                                        className="react-bootstrap-typeahead tag-outer"
                                                                        placeholder="Select a Member"
                                                                        options={Assignuserdata}
                                                                        value={Assignuserdata.filter(function (option) {
                                                                            return option.value === memberId;
                                                                        })}
                                                                        onChange={this.memberInputChangeHandler}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="fv-plugins-icon-container">
                                                                <div className="col-lg-12 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label required fw-bold fs-6">Time Zone</label>
                                                                    <TimezoneSelect
                                                                        styles={customStyles}
                                                                        value={email_form.timezone.value ? email_form.timezone.value : ""}
                                                                        onChange={(event) => this.handleChangeselect(event, "timezone", "timezonevalue")}
                                                                        timezones={{
                                                                            ...allTimezones,
                                                                            "America/Lima": "Pittsburgh",
                                                                            "Europe/Berlin": "Frankfurt",
                                                                        }}
                                                                    />
                                                                    {!email_form.timezone.valid && email_form.timezone.onBlur_out ? <div className="error field-error">{email_form.timezone.error_msg}</div> : ""}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card-body pt-2">
                                                            <div className="row">
                                                                <div className="col-lg-12 mt-4">
                                                                    <div className="set_time-border availability-main py-0">
                                                                        <div className="row">
                                                                            <div className="col-lg-8 fv-row fv-plugins-icon-container set_border_inner p-0">
                                                                                <div className="col-lg-12 fv-row fv-plugins-icon-container padding_data">
                                                                                    <div className="col-lg-12 fv-row fv-plugins-icon-container">
                                                                                        <div className="checkbox-inline ">
                                                                                            <div className="row">
                                                                                                <div className="col-sm-12">
                                                                                                    <div className="row">
                                                                                                        {" "}
                                                                                                        <div className="col-6 col-sm-2 align-items-center">
                                                                                                            <label className="checkbox mt-3">
                                                                                                                <input
                                                                                                                    className="form-check-input me-2"
                                                                                                                    type={email_form.Sun.type}
                                                                                                                    value={email_form.Sun.value}
                                                                                                                    checked={email_form.Sun.isChecked}
                                                                                                                    onChange={(event) => this.inputChangeHandler(event, "Sun")}
                                                                                                                />
                                                                                                                <span className="fw-bolder fs-6 mb-0">SUN</span>
                                                                                                            </label>
                                                                                                        </div>
                                                                                                        <div className="time-dropdown order-mob-3 col-sm-8 align-items-center content_sun">
                                                                                                            {email_form.Sun_startTime.value &&
                                                                                                                email_form.Sun_startTime.value.map((Sun_startTime, index) => (
                                                                                                                    <>
                                                                                                                        <div className="row" key={index}>
                                                                                                                            <Select
                                                                                                                                styles={customStyles}
                                                                                                                                name={"Sun_startTime"}
                                                                                                                                className="col-5 my-1"
                                                                                                                                placeholder="Start time"
                                                                                                                                options={this.state.gettimeslot}
                                                                                                                                value={this.state.gettimeslot.filter(function (option) {
                                                                                                                                    return option.value === Sun_startTime;
                                                                                                                                })}
                                                                                                                                onChange={(newValue) => this.handleChange(newValue, "Sun_startTime", index, "Sun")}
                                                                                                                            />
                                                                                                                            <Select
                                                                                                                                styles={customStyles}
                                                                                                                                name={"Sun_endTime"}
                                                                                                                                className="col-5 my-1"
                                                                                                                                placeholder="End time"
                                                                                                                                options={this.state.gettimeslot}
                                                                                                                                value={this.state.gettimeslot.filter(function (option) {
                                                                                                                                    return option.value === email_form.Sun_endTime.value[index];
                                                                                                                                })}
                                                                                                                                onChange={(newValue) => this.handleChange(newValue, "Sun_endTime", index, "Sun")}
                                                                                                                            />
                                                                                                                            {user_role && user_role.interviewAvailabilityDelete === "yes" && (
                                                                                                                                <div className="col-2 d-flex align-items-center">
                                                                                                                                    <div
                                                                                                                                        className="times-icons d-flex justify-content-start"
                                                                                                                                        onClick={() => this.deletevalue("Sun_startTime", "Sun_endTime", index, "Sun")}
                                                                                                                                    >
                                                                                                                                        <span className="icon-one">
                                                                                                                                            <i className="bi bi-trash fs-3"></i>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            )}
                                                                                                                        </div>

                                                                                                                        {this.state.errorpostion === index && this.state.errortype === "Sun" ? (
                                                                                                                            <div key="Sun_1" className="error field-error">
                                                                                                                                {this.state.errormessage}
                                                                                                                            </div>
                                                                                                                        ) : (
                                                                                                                            ""
                                                                                                                        )}
                                                                                                                    </>
                                                                                                                ))}
                                                                                                            {email_form.Sun.value ? "" : "Unavailable"}
                                                                                                        </div>
                                                                                                        <div className="time-dropdown col-6 order-mob-2 col-sm-2 justify-content-end">
                                                                                                            <div className="times-icons d-flex mt-2">
                                                                                                                <span className="icon-one" onClick={() => this.addnewtimeslot("Sun")}>
                                                                                                                    <i className="bi bi-plus fs-3"></i>
                                                                                                                </span>

                                                                                                                <span className="icon-one" onClick={() => this.addcopytimeslot("Sun")}>
                                                                                                                    <i className="fa fa-copy fs-3"></i>
                                                                                                                </span>
                                                                                                                {this.state.setcopytimestatus === true && this.state.setcopytimes === "Sun" && (
                                                                                                                    <CopyTimeSlot setcopytimes={this.state.setcopytimes} submitApplytimeslot={(event) => this.submitApplytimeslot(event)} />
                                                                                                                )}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-12 fv-row fv-plugins-icon-container padding_data">
                                                                                    <div className="col-lg-12 fv-row fv-plugins-icon-container">
                                                                                        <div className="checkbox-inline ">
                                                                                            <div className="row">
                                                                                                <div className="col-sm-12">
                                                                                                    <div className="row">
                                                                                                        {" "}
                                                                                                        <div className="col-6 col-sm-2 align-items-center  mb-5">
                                                                                                            <label className="checkbox mt-3">
                                                                                                                <input
                                                                                                                    className="form-check-input me-2"
                                                                                                                    type={email_form.Mon.type}
                                                                                                                    value={email_form.Mon.value}
                                                                                                                    checked={email_form.Mon.isChecked}
                                                                                                                    onChange={(event) => this.inputChangeHandler(event, "Mon")}
                                                                                                                />
                                                                                                                <span className="fw-bolder fs-6 mb-0">MON</span>
                                                                                                            </label>
                                                                                                        </div>
                                                                                                        <div className="time-dropdown order-mob-3 col-sm-8 align-items-center content_mon">
                                                                                                            {email_form.Mon_startTime.value &&
                                                                                                                email_form.Mon_startTime.value.map((Mon_startTime, index) => (
                                                                                                                    <>
                                                                                                                        <div className="row" key={index}>
                                                                                                                            <Select
                                                                                                                                styles={customStyles}
                                                                                                                                name={"Mon_startTime"}
                                                                                                                                className="col-5 my-1"
                                                                                                                                placeholder="Start time"
                                                                                                                                options={this.state.gettimeslot}
                                                                                                                                value={this.state.gettimeslot.filter(function (option) {
                                                                                                                                    return option.value === Mon_startTime;
                                                                                                                                })}
                                                                                                                                onChange={(newValue) => this.handleChange(newValue, "Mon_startTime", index, "Mon")}
                                                                                                                            />
                                                                                                                            <Select
                                                                                                                                styles={customStyles}
                                                                                                                                name={"Mon_endTime"}
                                                                                                                                className="col-5 my-1"
                                                                                                                                placeholder="End time"
                                                                                                                                options={this.state.gettimeslot}
                                                                                                                                value={this.state.gettimeslot.filter(function (option) {
                                                                                                                                    return option.value === email_form.Mon_endTime.value[index];
                                                                                                                                })}
                                                                                                                                onChange={(newValue) => this.handleChange(newValue, "Mon_endTime", index, "Mon")}
                                                                                                                            />
                                                                                                                            {user_role && user_role.interviewAvailabilityDelete === "yes" && (

                                                                                                                                <div className="col-2 d-flex align-items-center">
                                                                                                                                    <div
                                                                                                                                        className="times-icons d-flex justify-content-start"
                                                                                                                                        onClick={() => this.deletevalue("Mon_startTime", "Mon_endTime", index, "Mon")}
                                                                                                                                    >
                                                                                                                                        <span className="icon-one">
                                                                                                                                            <i className="bi bi-trash fs-3"></i>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            )}
                                                                                                                        </div>
                                                                                                                        {this.state.errorpostion === index && this.state.errortype === "Mon" ? (
                                                                                                                            <div key="Mon_1" className="error field-error">
                                                                                                                                {this.state.errormessage}
                                                                                                                            </div>
                                                                                                                        ) : (
                                                                                                                            ""
                                                                                                                        )}
                                                                                                                    </>
                                                                                                                ))}
                                                                                                            {email_form.Mon.value ? "" : "Unavailable"}
                                                                                                        </div>
                                                                                                        <div className="time-dropdown col-6 order-mob-2 col-sm-2 justify-content-end">
                                                                                                            <div className="times-icons d-flex mt-2">
                                                                                                                <span className="icon-one" onClick={() => this.addnewtimeslot("Mon")}>
                                                                                                                    <i className="bi bi-plus fs-3"></i>
                                                                                                                </span>

                                                                                                                <span className="icon-one" onClick={() => this.addcopytimeslot("Mon")}>
                                                                                                                    <i className="fa fa-copy fs-3"></i>
                                                                                                                </span>
                                                                                                                {this.state.setcopytimestatus === true && this.state.setcopytimes === "Mon" && (
                                                                                                                    <CopyTimeSlot setcopytimes={this.state.setcopytimes} submitApplytimeslot={(event) => this.submitApplytimeslot(event)} />
                                                                                                                )}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-12 fv-row fv-plugins-icon-container padding_data">
                                                                                    <div className="col-lg-12 fv-row fv-plugins-icon-container">
                                                                                        <div className="checkbox-inline ">
                                                                                            <div className="row">
                                                                                                <div className="col-sm-12">
                                                                                                    <div className="row">
                                                                                                        {" "}
                                                                                                        <div className="col-6 col-sm-2 align-items-center  mb-5">
                                                                                                            <label className="checkbox mt-3">
                                                                                                                <input
                                                                                                                    className="form-check-input me-2"
                                                                                                                    type={email_form.Tue.type}
                                                                                                                    value={email_form.Tue.value}
                                                                                                                    checked={email_form.Tue.isChecked}
                                                                                                                    onChange={(event) => this.inputChangeHandler(event, "Tue")}
                                                                                                                />
                                                                                                                <span className="fw-bolder fs-6 mb-0">TUE</span>
                                                                                                            </label>
                                                                                                        </div>
                                                                                                        <div className="time-dropdown order-mob-3 col-sm-8 align-items-center content_tue">
                                                                                                            {email_form.Tue_startTime.value &&
                                                                                                                email_form.Tue_startTime.value.map((Tue_startTime, index) => (
                                                                                                                    <>
                                                                                                                        <div className="row" key={index}>
                                                                                                                            <Select
                                                                                                                                styles={customStyles}
                                                                                                                                name={"Tue_startTime"}
                                                                                                                                className="col-5 my-1"
                                                                                                                                placeholder="Start time"
                                                                                                                                options={this.state.gettimeslot}
                                                                                                                                value={this.state.gettimeslot.filter(function (option) {
                                                                                                                                    return option.value === Tue_startTime;
                                                                                                                                })}
                                                                                                                                onChange={(newValue) => this.handleChange(newValue, "Tue_startTime", index, "Tue")}
                                                                                                                            />
                                                                                                                            <Select
                                                                                                                                styles={customStyles}
                                                                                                                                name={"Tue_endTime"}
                                                                                                                                className="col-5 my-1"
                                                                                                                                placeholder="End time"
                                                                                                                                options={this.state.gettimeslot}
                                                                                                                                value={this.state.gettimeslot.filter(function (option) {
                                                                                                                                    return option.value === email_form.Tue_endTime.value[index];
                                                                                                                                })}
                                                                                                                                onChange={(newValue) => this.handleChange(newValue, "Tue_endTime", index, "Tue")}
                                                                                                                            />
                                                                                                                            {user_role && user_role.interviewAvailabilityDelete === "yes" && (
                                                                                                                                <div className="col-2 d-flex align-items-center">
                                                                                                                                    <div
                                                                                                                                        className="times-icons d-flex justify-content-start"
                                                                                                                                        onClick={() => this.deletevalue("Tue_startTime", "Tue_endTime", index, "Tue")}
                                                                                                                                    >
                                                                                                                                        <span className="icon-one">
                                                                                                                                            <i className="bi bi-trash fs-3"></i>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            )}
                                                                                                                        </div>
                                                                                                                        {this.state.errorpostion === index && this.state.errortype === "Tue" ? (
                                                                                                                            <div key="Tue_1" className="error field-error">
                                                                                                                                {this.state.errormessage}
                                                                                                                            </div>
                                                                                                                        ) : (
                                                                                                                            ""
                                                                                                                        )}
                                                                                                                    </>
                                                                                                                ))}
                                                                                                            {email_form.Tue.value ? "" : "Unavailable"}
                                                                                                        </div>
                                                                                                        <div className="time-dropdown col-6 order-mob-2 col-sm-2 justify-content-end">
                                                                                                            <div className="times-icons d-flex mt-2">
                                                                                                                <span className="icon-one" onClick={() => this.addnewtimeslot("Tue")}>
                                                                                                                    <i className="bi bi-plus fs-3"></i>
                                                                                                                </span>

                                                                                                                <span className="icon-one" onClick={() => this.addcopytimeslot("Tue")}>
                                                                                                                    <i className="fa fa-copy fs-3"></i>
                                                                                                                </span>
                                                                                                                {this.state.setcopytimestatus === true && this.state.setcopytimes === "Tue" && (
                                                                                                                    <CopyTimeSlot setcopytimes={this.state.setcopytimes} submitApplytimeslot={(event) => this.submitApplytimeslot(event)} />
                                                                                                                )}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-12 fv-row fv-plugins-icon-container padding_data">
                                                                                    <div className="col-lg-12 fv-row fv-plugins-icon-container">
                                                                                        <div className="checkbox-inline ">
                                                                                            <div className="row">
                                                                                                <div className="col-sm-12">
                                                                                                    <div className="row">
                                                                                                        {" "}
                                                                                                        <div className="col-6 col-sm-2 align-items-center  mb-5">
                                                                                                            <label className="checkbox mt-3">
                                                                                                                <input
                                                                                                                    className="form-check-input me-2"
                                                                                                                    type={email_form.Wed.type}
                                                                                                                    value={email_form.Wed.value}
                                                                                                                    checked={email_form.Wed.isChecked}
                                                                                                                    onChange={(event) => this.inputChangeHandler(event, "Wed")}
                                                                                                                />
                                                                                                                <span className="fw-bolder fs-6 mb-0">WED</span>
                                                                                                            </label>
                                                                                                        </div>
                                                                                                        <div className="time-dropdown order-mob-3 col-sm-8 align-items-center content_wed">
                                                                                                            {email_form.Wed_startTime.value &&
                                                                                                                email_form.Wed_startTime.value.map((Wed_startTime, index) => (
                                                                                                                    <>
                                                                                                                        <div className="row" key={index}>
                                                                                                                            <Select
                                                                                                                                styles={customStyles}
                                                                                                                                name={"Wed_startTime"}
                                                                                                                                className="col-5 my-1"
                                                                                                                                placeholder="Start time"
                                                                                                                                options={this.state.gettimeslot}
                                                                                                                                value={this.state.gettimeslot.filter(function (option) {
                                                                                                                                    return option.value === Wed_startTime;
                                                                                                                                })}
                                                                                                                                onChange={(newValue) => this.handleChange(newValue, "Wed_startTime", index, "Wed")}
                                                                                                                            />
                                                                                                                            <Select
                                                                                                                                styles={customStyles}
                                                                                                                                name={"Wed_endTime"}
                                                                                                                                className="col-5 my-1"
                                                                                                                                placeholder="End time"
                                                                                                                                options={this.state.gettimeslot}
                                                                                                                                value={this.state.gettimeslot.filter(function (option) {
                                                                                                                                    return option.value === email_form.Wed_endTime.value[index];
                                                                                                                                })}
                                                                                                                                onChange={(newValue) => this.handleChange(newValue, "Wed_endTime", index, "Wed")}
                                                                                                                            />
                                                                                                                            {user_role && user_role.interviewAvailabilityDelete === "yes" && (
                                                                                                                                <div className="col-2 d-flex align-items-center">
                                                                                                                                    <div
                                                                                                                                        className="times-icons d-flex justify-content-start"
                                                                                                                                        onClick={() => this.deletevalue("Wed_startTime", "Wed_endTime", index, "Wed")}
                                                                                                                                    >
                                                                                                                                        <span className="icon-one">
                                                                                                                                            <i className="bi bi-trash fs-3"></i>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            )}
                                                                                                                        </div>
                                                                                                                        {this.state.errorpostion === index && this.state.errortype === "Wed" ? (
                                                                                                                            <div key="Wed_1" className="error field-error">
                                                                                                                                {this.state.errormessage}
                                                                                                                            </div>
                                                                                                                        ) : (
                                                                                                                            ""
                                                                                                                        )}
                                                                                                                    </>
                                                                                                                ))}
                                                                                                            {email_form.Wed.value ? "" : "Unavailable"}
                                                                                                        </div>
                                                                                                        <div className="time-dropdown col-6 order-mob-2 col-sm-2 justify-content-end">
                                                                                                            <div className="times-icons d-flex mt-2">
                                                                                                                <span className="icon-one" onClick={() => this.addnewtimeslot("Wed")}>
                                                                                                                    <i className="bi bi-plus fs-3"></i>
                                                                                                                </span>

                                                                                                                <span className="icon-one" onClick={() => this.addcopytimeslot("Wed")}>
                                                                                                                    <i className="fa fa-copy fs-3"></i>
                                                                                                                </span>
                                                                                                                {this.state.setcopytimestatus === true && this.state.setcopytimes === "Wed" && (
                                                                                                                    <CopyTimeSlot setcopytimes={this.state.setcopytimes} submitApplytimeslot={(event) => this.submitApplytimeslot(event)} />
                                                                                                                )}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-12 fv-row fv-plugins-icon-container padding_data">
                                                                                    <div className="col-lg-12 fv-row fv-plugins-icon-container">
                                                                                        <div className="checkbox-inline ">
                                                                                            <div className="row">
                                                                                                <div className="col-sm-12">
                                                                                                    <div className="row">
                                                                                                        {" "}
                                                                                                        <div className="col-6 col-sm-2 align-items-center  mb-5">
                                                                                                            <label className="checkbox mt-3">
                                                                                                                <input
                                                                                                                    className="form-check-input me-2"
                                                                                                                    type={email_form.Thu.type}
                                                                                                                    value={email_form.Thu.value}
                                                                                                                    checked={email_form.Thu.isChecked}
                                                                                                                    onChange={(event) => this.inputChangeHandler(event, "Thu")}
                                                                                                                />
                                                                                                                <span className="fw-bolder fs-6 mb-0">THU</span>
                                                                                                            </label>
                                                                                                        </div>
                                                                                                        <div className="time-dropdown order-mob-3 col-sm-8 align-items-center content_thu">
                                                                                                            {email_form.Thu_startTime.value &&
                                                                                                                email_form.Thu_startTime.value.map((Thu_startTime, index) => (
                                                                                                                    <>
                                                                                                                        <div className="row" key={index}>
                                                                                                                            <Select
                                                                                                                                styles={customStyles}
                                                                                                                                name={"Thu_startTime"}
                                                                                                                                className="col-5 my-1"
                                                                                                                                placeholder="Start time"
                                                                                                                                options={this.state.gettimeslot}
                                                                                                                                value={this.state.gettimeslot.filter(function (option) {
                                                                                                                                    return option.value === Thu_startTime;
                                                                                                                                })}
                                                                                                                                onChange={(newValue) => this.handleChange(newValue, "Thu_startTime", index, "Thu")}
                                                                                                                            />
                                                                                                                            <Select
                                                                                                                                styles={customStyles}
                                                                                                                                name={"Thu_endTime"}
                                                                                                                                className="col-5 my-1"
                                                                                                                                placeholder="End time"
                                                                                                                                options={this.state.gettimeslot}
                                                                                                                                value={this.state.gettimeslot.filter(function (option) {
                                                                                                                                    return option.value === email_form.Thu_endTime.value[index];
                                                                                                                                })}
                                                                                                                                onChange={(newValue) => this.handleChange(newValue, "Thu_endTime", index, "Thu")}
                                                                                                                            />
                                                                                                                            {user_role && user_role.interviewAvailabilityDelete === "yes" && (
                                                                                                                                <div className="col-2 d-flex align-items-center">
                                                                                                                                    <div
                                                                                                                                        className="times-icons d-flex justify-content-start"
                                                                                                                                        onClick={() => this.deletevalue("Thu_startTime", "Thu_endTime", index, "Thu")}
                                                                                                                                    >
                                                                                                                                        <span className="icon-one">
                                                                                                                                            <i className="bi bi-trash fs-3"></i>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            )}
                                                                                                                        </div>
                                                                                                                        {this.state.errorpostion === index && this.state.errortype === "Thu" ? (
                                                                                                                            <div key="Thu_1" className="error field-error">
                                                                                                                                {this.state.errormessage}
                                                                                                                            </div>
                                                                                                                        ) : (
                                                                                                                            ""
                                                                                                                        )}
                                                                                                                    </>
                                                                                                                ))}
                                                                                                            {email_form.Thu.value ? "" : "Unavailable"}
                                                                                                        </div>
                                                                                                        <div className="time-dropdown col-6 order-mob-2 col-sm-2 justify-content-end">
                                                                                                            <div className="times-icons d-flex mt-2">
                                                                                                                <span className="icon-one" onClick={() => this.addnewtimeslot("Thu")}>
                                                                                                                    <i className="bi bi-plus fs-3"></i>
                                                                                                                </span>

                                                                                                                <span className="icon-one" onClick={() => this.addcopytimeslot("Thu")}>
                                                                                                                    <i className="fa fa-copy fs-3"></i>
                                                                                                                </span>
                                                                                                                {this.state.setcopytimestatus === true && this.state.setcopytimes === "Thu" && (
                                                                                                                    <CopyTimeSlot setcopytimes={this.state.setcopytimes} submitApplytimeslot={(event) => this.submitApplytimeslot(event)} />
                                                                                                                )}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-12 fv-row fv-plugins-icon-container padding_data">
                                                                                    <div className="col-lg-12 fv-row fv-plugins-icon-container">
                                                                                        <div className="checkbox-inline ">
                                                                                            <div className="row">
                                                                                                <div className="col-sm-12">
                                                                                                    <div className="row">
                                                                                                        {" "}
                                                                                                        <div className="col-6 col-sm-2 align-items-center  mb-5">
                                                                                                            <label className="checkbox mt-3">
                                                                                                                <input
                                                                                                                    className="form-check-input me-2"
                                                                                                                    type={email_form.Fri.type}
                                                                                                                    value={email_form.Fri.value}
                                                                                                                    checked={email_form.Fri.isChecked}
                                                                                                                    onChange={(event) => this.inputChangeHandler(event, "Fri")}
                                                                                                                />
                                                                                                                <span className="fw-bolder fs-6 mb-0">FRI</span>
                                                                                                            </label>
                                                                                                        </div>
                                                                                                        <div className="time-dropdown order-mob-3 col-sm-8 align-items-center content_fri">
                                                                                                            {email_form.Fri_startTime.value &&
                                                                                                                email_form.Fri_startTime.value.map((Fri_startTime, index) => (
                                                                                                                    <>
                                                                                                                        <div className="row" key={index}>
                                                                                                                            <Select
                                                                                                                                styles={customStyles}
                                                                                                                                name={"Fri_startTime"}
                                                                                                                                className="col-5 my-1"
                                                                                                                                placeholder="Start time"
                                                                                                                                options={this.state.gettimeslot}
                                                                                                                                value={this.state.gettimeslot.filter(function (option) {
                                                                                                                                    return option.value === Fri_startTime;
                                                                                                                                })}
                                                                                                                                onChange={(newValue) => this.handleChange(newValue, "Fri_startTime", index, "Fri")}
                                                                                                                            />
                                                                                                                            <Select
                                                                                                                                styles={customStyles}
                                                                                                                                name={"Fri_endTime"}
                                                                                                                                className="col-5 my-1"
                                                                                                                                placeholder="End time"
                                                                                                                                options={this.state.gettimeslot}
                                                                                                                                value={this.state.gettimeslot.filter(function (option) {
                                                                                                                                    return option.value === email_form.Fri_endTime.value[index];
                                                                                                                                })}
                                                                                                                                onChange={(newValue) => this.handleChange(newValue, "Fri_endTime", index, "Fri")}
                                                                                                                            />
                                                                                                                            {user_role && user_role.interviewAvailabilityDelete === "yes" && (
                                                                                                                                <div className="col-2 d-flex align-items-center">
                                                                                                                                    <div
                                                                                                                                        className="times-icons d-flex justify-content-start"
                                                                                                                                        onClick={() => this.deletevalue("Fri_startTime", "Fri_endTime", index, "Fri")}
                                                                                                                                    >
                                                                                                                                        <span className="icon-one">
                                                                                                                                            <i className="bi bi-trash fs-3"></i>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            )}
                                                                                                                        </div>
                                                                                                                        {this.state.errorpostion === index && this.state.errortype === "Fri" ? (
                                                                                                                            <div key="Fri_1" className="error field-error">
                                                                                                                                {this.state.errormessage}
                                                                                                                            </div>
                                                                                                                        ) : (
                                                                                                                            ""
                                                                                                                        )}
                                                                                                                    </>
                                                                                                                ))}
                                                                                                            {email_form.Fri.value ? "" : "Unavailable"}
                                                                                                        </div>
                                                                                                        <div className="time-dropdown col-6 order-mob-2 col-sm-2 justify-content-end">
                                                                                                            <div className="times-icons d-flex mt-2">
                                                                                                                <span className="icon-one" onClick={() => this.addnewtimeslot("Fri")}>
                                                                                                                    <i className="bi bi-plus fs-3"></i>
                                                                                                                </span>

                                                                                                                <span className="icon-one" onClick={() => this.addcopytimeslot("Fri")}>
                                                                                                                    <i className="fa fa-copy fs-3"></i>
                                                                                                                </span>
                                                                                                                {this.state.setcopytimestatus === true && this.state.setcopytimes === "Fri" && (
                                                                                                                    <CopyTimeSlot setcopytimes={this.state.setcopytimes} submitApplytimeslot={(event) => this.submitApplytimeslot(event)} />
                                                                                                                )}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-12 fv-row fv-plugins-icon-container padding_data">
                                                                                    <div className="col-lg-12 fv-row fv-plugins-icon-container">
                                                                                        <div className="checkbox-inline ">
                                                                                            <div className="row">
                                                                                                <div className="col-sm-12">
                                                                                                    <div className="row">
                                                                                                        {" "}
                                                                                                        <div className="col-6 col-sm-2 align-items-center  mb-5">
                                                                                                            <label className="checkbox mt-3">
                                                                                                                <input
                                                                                                                    className="form-check-input me-2"
                                                                                                                    type={email_form.Sat.type}
                                                                                                                    value={email_form.Sat.value}
                                                                                                                    checked={email_form.Sat.isChecked}
                                                                                                                    onChange={(event) => this.inputChangeHandler(event, "Sat")}
                                                                                                                />
                                                                                                                <span className="fw-bolder fs-6 mb-0">SAT</span>
                                                                                                            </label>
                                                                                                        </div>
                                                                                                        <div className="time-dropdown order-mob-3 col-sm-8 align-items-center content_sat">
                                                                                                            {email_form.Sat_startTime.value &&
                                                                                                                email_form.Sat_startTime.value.map((Sat_startTime, index) => (
                                                                                                                    <>
                                                                                                                        <div className="row" key={index}>
                                                                                                                            <Select
                                                                                                                                styles={customStyles}
                                                                                                                                name={"Sat_startTime"}
                                                                                                                                className="col-5 my-1"
                                                                                                                                placeholder="Start time"
                                                                                                                                options={this.state.gettimeslot}
                                                                                                                                value={this.state.gettimeslot.filter(function (option) {
                                                                                                                                    return option.value === Sat_startTime;
                                                                                                                                })}
                                                                                                                                onChange={(newValue) => this.handleChange(newValue, "Sat_startTime", index, "Sat")}
                                                                                                                            />
                                                                                                                            <Select
                                                                                                                                styles={customStyles}
                                                                                                                                name={"Sat_endTime"}
                                                                                                                                className="col-5 my-1"
                                                                                                                                placeholder="End time"
                                                                                                                                options={this.state.gettimeslot}
                                                                                                                                value={this.state.gettimeslot.filter(function (option) {
                                                                                                                                    return option.value === email_form.Sat_endTime.value[index];
                                                                                                                                })}
                                                                                                                                onChange={(newValue) => this.handleChange(newValue, "Sat_endTime", index, "Sat")}
                                                                                                                            />
                                                                                                                            {user_role && user_role.interviewAvailabilityDelete === "yes" && (
                                                                                                                                <div className="col-2 d-flex align-items-center">
                                                                                                                                    <div
                                                                                                                                        className="times-icons d-flex justify-content-start"
                                                                                                                                        onClick={() => this.deletevalue("Sat_startTime", "Sat_endTime", index, "Sat")}
                                                                                                                                    >
                                                                                                                                        <span className="icon-one">
                                                                                                                                            <i className="bi bi-trash fs-3"></i>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            )}
                                                                                                                        </div>
                                                                                                                        {this.state.errorpostion === index && this.state.errortype === "Sat" ? (
                                                                                                                            <div key="Sat_1" className="error field-error">
                                                                                                                                {this.state.errormessage}
                                                                                                                            </div>
                                                                                                                        ) : (
                                                                                                                            ""
                                                                                                                        )}
                                                                                                                    </>
                                                                                                                ))}
                                                                                                            {email_form.Sat.value ? "" : "Unavailable"}
                                                                                                        </div>
                                                                                                        <div className="time-dropdown col-6 order-mob-2 col-sm-2 justify-content-end">
                                                                                                            <div className="times-icons d-flex mt-2">
                                                                                                                <span className="icon-one" onClick={() => this.addnewtimeslot("Sat")}>
                                                                                                                    <i className="bi bi-plus fs-3"></i>
                                                                                                                </span>

                                                                                                                <span className="icon-one" onClick={() => this.addcopytimeslot("susatn")}>
                                                                                                                    <i className="fa fa-copy fs-3"></i>
                                                                                                                </span>
                                                                                                                {this.state.setcopytimestatus === true && this.state.setcopytimes === "Sat" && (
                                                                                                                    <CopyTimeSlot setcopytimes={this.state.setcopytimes} submitApplytimeslot={(event) => this.submitApplytimeslot(event)} />
                                                                                                                )}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-lg-12 p-4">
                                                                                    <button type="submit" id="kt_new_password_submit" className="btn btn-sm btn-primary fw-bolder me-4">
                                                                                        <span className="indicator-label">Save</span>
                                                                                    </button>
                                                                                    {/* <Link to="/email" className="btn btn-sm btn-light-primary fw-bolder">
                                                                                Cancel
                                                                            </Link> */}
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-4 fv-row fv-plugins-icon-container pt-6">
                                                                                <h3 className="card-title fw-bolder text-dark">Add date overrides</h3>
                                                                                <span className="btn add_data_overrides mt-5" onClick={() => this.adddataoverrides("susatn")}>
                                                                                    <span className="align-items-center">Add a date override</span>
                                                                                </span>
                                                                                <div className="time_slot_main">
                                                                                    {this.state.getslottime &&
                                                                                        this.state.getslottime.map((getslottimevalue, index) => (
                                                                                            <div key={index} className="unavail-time-slot">
                                                                                                <div className="row ">
                                                                                                    <div className="col-sm-4">
                                                                                                        <p className="m-0">{this.getDateByTimeZone(getslottimevalue)}</p>
                                                                                                    </div>
                                                                                                    <div className="col-sm-6">{this.getTimeByTimeZone(getslottimevalue)}</div>
                                                                                                    <div className="col-sm-2">
                                                                                                        <div className="times-icons d-flex justify-content-start">
                                                                                                            {user_role && user_role.interviewAvailabilityDelete && (
                                                                                                                <span className="icon-one" onClick={(event) => this.DeletDisableTimeSlots(event, getslottimevalue.id, index, getslottimevalue.date)}>
                                                                                                                    <i className="bi bi-trash fs-3"></i>
                                                                                                                </span>
                                                                                                            )}

                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        ))}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                        <Modal footer={null} title="Select the date(s) you want to assign specific hours" visible={this.state.isDateOverrides} onCancel={this.handleCancel}>
                            <DateOverridesMember messagedata="Select the date(s) you want to assign specific hours" memberId={memberId} handleCancel={(event) => this.handleCancel(event)} getslottime={this.state.getslottime} setopupresult={this.state.setopupresult} timezone={this.state.email_form.timezone} getDisableSlotsdata={(event) => this.getDisableSlotsdata(event)} setopupresults={(event) => this.setopupresults(this.state.setopupresult)} />
                        </Modal>
                        {/* setopupresult={this.state.setopupresult}  */}
                    </div>
                    <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
                        <span className="svg-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="black" />
                                <path
                                    d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                    fill="black"
                                />
                            </svg>
                        </span>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default MembersAvailability;
