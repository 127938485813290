import React from "react";
import { Redirect } from "react-router-dom";
import FlaggApplication from "../../Component/FlaggApplication/FlaggApplication";
import FlaggApplicationReview from "../../Component/FlaggApplication/FlaggApplicationReview";
class FlaggApplications extends React.Component {
    render() {
		// const user_info = JSON.parse(localStorage.getItem("gpt_authuser"));
        const user_role = JSON.parse(localStorage.getItem("gpt_user_role"));
        const renderview = () => {
            if (this.props.match.path === "/flagged-application") {
                if((user_role && user_role.flaggedView && user_role.flaggedView === "yes") || (user_role && user_role.flaggedAccept && user_role.flaggedAccept === "yes") || (user_role && user_role.flaggedReject && user_role.flaggedReject === "yes") ) {
                    return <FlaggApplication />;
                  
                }
                else{
                    return <Redirect to="/dashboard" />;
                }
            }
                      
            if (this.props.match.path === "/flagged-application-review/:applicaionId") {
                if(user_role && user_role.flaggedView && user_role.flaggedView === "yes" ) {
                   return <FlaggApplicationReview applicaionId={this.props.match.params.applicaionId} />;
                  
                }
                else{
                    return <Redirect to="/dashboard" />;
                }
                
            }
        };
		
        return (
            <React.Fragment>
                {/* <Notifications /> */}
                {renderview()}
            </React.Fragment>
        );
		
    }
}
export default FlaggApplications;