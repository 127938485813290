import React from "react";
import { Redirect } from "react-router-dom";
import ZoomUploadFile from "../../Component/Account/ZoomUploadFile";
import Account from "../../Component/Account/Account";


class Tags extends React.Component {
    render() {
        const user_info = JSON.parse(localStorage.getItem("gpt_authuser"));
        const user_role = JSON.parse(localStorage.getItem("gpt_user_role"));
        const renderview = () => {
            if (this.props.match.path === "/account") {
                // if (user_info && user_role.settingAccount && user_role.settingAccount === "yes") {
                    return <Account />;

                // }
                // else {
                //     return <Redirect to="/dashboard" />;
                // }
            }
            if (this.props.match.path === "/zoom-link-upload/:applicaionId") {
                return <ZoomUploadFile applicaionId={this.props.match.params.applicaionId} />;

            }
            else{
                    return <Redirect to="/dashboard" />;
                }

        };

        return (
            <React.Fragment>
                {/* <Notifications /> */}
                {renderview()}
            </React.Fragment>
        );

    }
}
export default Tags;