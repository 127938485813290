import React from "react";
// import { Modal } from "react-bootstrap";
import Validations from "../Utility/Validations";

class AddTag extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addfolder_form: {
                name: {
                    label: "Add Tag",
                    type: "text",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: { required: true },
                },
            },

            isAddModalVisible: false,
        };
    }

    inputChangeHandler(event, identifier) {
        const update_addfolder_form = { ...this.state.addfolder_form };
        const form_element = { ...update_addfolder_form[identifier] };
        form_element.value = event.target.value;

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_addfolder_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_addfolder_form) {
            is_form_valid = update_addfolder_form[identifier].valid && is_form_valid;
        }

        this.setState({
            addfolder_form: update_addfolder_form,
            is_form_valid: is_form_valid,
        });
    }

    SubmitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_addfolder_form = this.state.addfolder_form;
        for (let key in update_addfolder_form) {
            let form_element = update_addfolder_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_addfolder_form[key] = form_element;
        }
        this.setState({ addfolder_form: update_addfolder_form });

        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.addfolder_form) {
                form_data[key] = this.state.addfolder_form[key].value;
            }
            const resultvalue = this.props.AddTagSubmit(form_data);

            const update_addfolder_form = { ...this.state.addfolder_form };
            update_addfolder_form.name.value = "";
            this.setState({ addfolder_form: update_addfolder_form });
        }
    };
    // handleCancel = () => {
    //     console.log('hlooo')
    //     this.setState({ isAddModalVisible: false });
    //     console.log('isAddModalVisible===', this.state.isAddModalVisible)
    //     // isAddModalVisible(false);
    // };
    render() {
        const { addfolder_form } = this.state;
        return (
            <React.Fragment>

                <form className="form w-100" onSubmit={this.SubmitHandler}>
                    <div className="row">
                        <div className="col-lg-12 fv-row mb-4">
                            {/* <label className="form-label fs-6">{addfolder_form.name.label}</label> */}
                            <input
                                type={addfolder_form.name.type}
                                className="form-control form-control-lg form-control-solid"
                                placeholder={addfolder_form.name.placeholder}
                                value={addfolder_form.name.value ? addfolder_form.name.value : ""}
                                onChange={(event) => this.inputChangeHandler(event, "name")}
                                onBlur={(event) => {
                                    addfolder_form.name.onBlur_out = true;
                                    this.setState({
                                        addfolder_form: addfolder_form,
                                    });
                                }}
                            />
                            {!addfolder_form.name.valid && addfolder_form.name.onBlur_out ? <div className="error field-error">{addfolder_form.name.error_msg}</div> : ""}
                        </div>
                    </div>
                    <div className="d-flex align-items-center my-2 justify-content-center">
                        <button type="submit" className='btn btn-lg btn-primary fw-bolder'>Ok</button>
                        {/* <span className="btn btn-lg btn-light-primary fw-bolder" onClick={() => this.handleCancel()} >Cancel</span> */}
                    </div>
                </form>

            </React.Fragment>
        );
    }
}
export default AddTag;