import React from "react";
import { Redirect } from "react-router-dom";
import Interview from "../../Component/Interviews/Interviews";
import Availability from "../../Component/Interviews/Availability";
import MembersAvailability from "../../Component/Interviews/MembersAvailability";
import InterviewSchedule from "../../Component/Interviews/InterviewSchedule";
import Activitys from "../../Component/Activity/Activity";



class Interviews extends React.Component {
    render() {
        const user_info = JSON.parse(localStorage.getItem("gpt_authuser"));
        const user_role = JSON.parse(localStorage.getItem("gpt_user_role"));
        const renderview = () => {
            if (this.props.match.path === "/interviews") {
                if ((user_role && user_role.interviewView && user_role.interviewView === "yes")) {
                    return <Interview />;

                }
                else {
                    return <Redirect to="/dashboard" />;
                }
            }
            if (this.props.match.path === "/availability") {
                if ((user_role && user_role.interviewAvailability && user_role.interviewAvailability === "yes")) {
                    return <Availability />;

                }
                else {
                    return <Redirect to="/dashboard" />;
                }
            }
            if (this.props.match.path === "/members-availability") {
                if ((user_role && user_role.membersInterviewAvailability && user_role.membersInterviewAvailability === "yes")) {
                    return <MembersAvailability />;

                }
                else {
                    return <Redirect to="/dashboard" />;
                }
            }
            if (this.props.match.path === "/interview-schedule") {
                return <InterviewSchedule />;
            }
            if (this.props.match.path === "/activity") {
                // if ((user_role && user_role.interviewView && user_role.interviewView === "yes")) {
                return <Activitys />;

                // }
                // else {
                //     return <Redirect to="/dashboard" />;
                // }
            }

        };

        return (
            <React.Fragment>
                {/* <Notifications /> */}
                {renderview()}
            </React.Fragment>
        );

    }
}
export default Interviews;