import axios from "axios";
import { API_URL } from "../../Constant/index";

const token = localStorage.getItem("dataToken");
// console.log(token, "token>>");

// export const getLevels = (data) => {
//   return axios.get(`${API_URL}admin/getLevels`, data,{
//     headers: {
//       'Content-type': 'application/json',
//       // Authorization: `Bearer ${token}`,
//     },
//   });
// };

export const editLevel = (data) => {
    return axios.post(`${API_URL}admin/editLevel`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const editTag = (data) => {
    return axios.post(`${API_URL}admin/editTag`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const updatepassword = (data) => {
    return axios.post(`${API_URL}updatePassword`, data, {
        headers: {
            "Content-type": "application/json",
        },
    });
};
export const updateforgotPassword = (data) => {
    return axios.post(`${API_URL}updateforgotPassword`, data, {
        headers: {
            "Content-type": "application/json",
        },
    });
};
export const updateStatu = (data) => {
    return axios.post(`${API_URL}admin/changeStatus`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getLevels = (data) => {
    return axios.post(`${API_URL}admin/getLevels`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const getMember = (data) => {
    return axios.post(`${API_URL}admin/getMemberlist`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getTag = (data) => {
    return axios.post(`${API_URL}admin/getTags`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const addMember = (data) => {
    return axios.post(`${API_URL}admin/addmember`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const MemberDetail = (data) => {
    return axios.post(`${API_URL}admin/getMember`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const addLeavel = (data) => {
    return axios.post(`${API_URL}admin/addLevel`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const addTag = (data) => {
    return axios.post(`${API_URL}admin/addTag`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const deleteLevel = (data) => {
    return axios.post(`${API_URL}admin/deleteLevel`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const deleteTag = (data) => {
    return axios.post(`${API_URL}admin/deleteTag`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const deleteMemebr = (data) => {
    return axios.post(`${API_URL}admin/deleteMember`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const addPlatform = (data) => {
    return axios.post(`${API_URL}platform/add`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const addRoles = (data) => {
    return axios.post(`${API_URL}role/add`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getPlatforms = () => {
    return axios.get(`${API_URL}platforms`, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getCategories = () => {
    return axios.get(`${API_URL}allcategories`, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getUsers = () => {
    return axios.get(`${API_URL}users?sortBy=asc&count=1000`, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getUsersdetails = (id) => {
    return axios.get(`${API_URL}userDetail?id=${id}`, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getProjectdetails = (id) => {
    return axios.get(`${API_URL}project?id=${id}`, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getWeeklyplanbyprojectID = (id) => {
    return axios.get(`${API_URL}getWeeklyplanbyprojectID?id=${id}`, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const getWeeklyplanjson = (id) => {
    return axios.get(`${API_URL}getWeeklyplanjson?id=${id}`, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getsingleleave = (id) => {
    return axios.get(`${API_URL}getsingleleave?id=${id}`, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getsingleresign = (id) => {
    return axios.get(`${API_URL}getsingleresign?id=${id}`, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getPostDetails = (id) => {
    return axios.get(`${API_URL}post?id=${id}`, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getProjects = () => {
    return axios.get(`${API_URL}projects?sortBy=asc&count=100`, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getEmployee = () => {
    return axios.get(`${API_URL}users?role=employee&count=100`, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const alappraisal = () => {
    return axios.get(`${API_URL}getallappraisal?count=100000`, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const userassignlist = (id) => {
    return axios.get(`${API_URL}user/projectassignlist?projectId=${id}&count=10000000`, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const projectAddTeam = (id, memberId) => {
    return axios.get(`${API_URL}project/add/member?id=${id}&memberId=${memberId}`, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getRoles = () => {
    return axios.get(`${API_URL}getAllRoles?sortBy=asc&count=100`, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getKownledge = () => {
    return axios.get(`${API_URL}posts?sortBy=asc&count=100`, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getAllAttandance = () => {
    return axios.get(`${API_URL}allleaves`, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const xlsingledata = (id) => {
    return axios.get(`${API_URL}getsingleWeeklyplan?id=${id}`, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const mailinglist = () => {
    return axios.get(`${API_URL}user/mailingList`, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getAllResign = () => {
    return axios.get(`${API_URL}allresign?sortBy=asc&count=100`, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const deleteProject = (id) => {
    return axios.get(`${API_URL}project/delete?id=${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
export const deletePlatform = (id) => {
    return axios.get(`${API_URL}platform/delete?id=${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
export const getAttendace = (today) => {
    return axios.get(`${API_URL}attendance/allusers?attendancedate=${today}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const datadates = (newDates) => {
    return axios.get(`${API_URL}attendance/allusers?attendancedate=${newDates}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
export const attendancce = (data) => {
    return axios.post(`${API_URL}attendance/checkin`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const Weeklyplan = (data) => {
    return axios.post(`${API_URL}add/Weeklyplan`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getAttendaces = () => {
    return axios.get(`${API_URL}attendances?count=100`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const deleteRoles = (data) => {
    return axios.put(`${API_URL}delete`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

// export const deleteKnowledge = (id, data) => {
//   return axios.put(`${API_URL}post/delete?id=${id}`,data, {
//     headers: {
//       'Content-type': 'application/json',
//       Authorization: `Bearer ${token}`,
//     },
//   });
// }

export const deleteKnowledge = (id, data) => {
    return axios.put(`${API_URL}post/delete?id=${id}`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
export const updateStatusUser = (data) => {
    return axios.put(`${API_URL}update/status`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const updateProject = (id, data) => {
    return axios.put(`${API_URL}project/update/?id=${id}`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
            // "Access-Control-Request-Method": "PATCH",
            // "Access-Control-Allow-Origin": "http://localhost:3000"
        },
    });
};

export const updateUser = (id, data) => {
    return axios.put(`${API_URL}editProfile?id=${id}`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
            // "Access-Control-Request-Method": "PATCH",
            // "Access-Control-Allow-Origin": "http://localhost:3000"
        },
    });
};
export const UpdateStatus = (id, data) => {
    return axios.put(`${API_URL}editProfile?id=${id}`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
            // "Access-Control-Request-Method": "PATCH",
            // "Access-Control-Allow-Origin": "http://localhost:3000"
        },
    });
};

export const updateAttandec = (id, leaveStatus, fromemail, toemail, Email) => {
    return axios.put(`${API_URL}leaves/status/change?id=${id}&leaveStatus=${leaveStatus}&fromemail=${Email}&toemployeeemail=${fromemail}&tomanagementemail=${toemail}`);
};

export const updatexlstatus = (id, data) => {
    return axios.put(`${API_URL}Weeklyplan?id=${id}`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
            // "Access-Control-Request-Method": "PATCH",
            // "Access-Control-Allow-Origin": "http://localhost:3000"
        },
    });
};

export const updateResign = (id, resignStatus, fromemail, toemail, Email) => {
    return axios.put(`${API_URL}resign/status/change?id=${id}&resignStatus=${resignStatus}&fromemail=${Email}&toemployeeemail=${fromemail}&tomanagementemail=${toemail}`);
};

// export const updateAttandec = (id) => {
//   return axios.put(`${API_URL}leaves/status/change/?id=${id}`,{
//     headers: {
//       "Authorization": `Bearer ${token}`,

//       // "Access-Control-Request-Method": "PATCH",
//       // "Access-Control-Allow-Origin": "http://localhost:3000"
//   }})
// }

export const updatePlatform = (id, data) => {
    return axios.put(`${API_URL}platform/update/?id=${id}`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
            // "Access-Control-Request-Method": "PATCH",
            // "Access-Control-Allow-Origin": "http://localhost:3000"
        },
    });
};

export const updateRoles = (id, data) => {
    return axios.put(`${API_URL}role/?id=${id}`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
            // "Access-Control-Request-Method": "PATCH",
            // "Access-Control-Allow-Origin": "http://localhost:3000"
        },
    });
};

export const updateMember = (id, data) => {
    return axios.post(`${API_URL}admin/editmember`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
            // "Access-Control-Request-Method": "PATCH",
            // "Access-Control-Allow-Origin": "http://localhost:3000"
        },
    });
};

export const updateKnowledge = (id, data) => {
    return axios.put(`${API_URL}post/update?id=${id}`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
            // "Access-Control-Request-Method": "PATCH",
            // "Access-Control-Allow-Origin": "http://localhost:3000"
        },
    });
};

export const updateProfile = (id, data) => {
    return axios.put(`${API_URL}editProfile?id=${id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
    });
};

export const updateStatus = (id, status, data) => {
    return axios.put(`${API_URL}update/status?id=${id}&status=${status}`, data, {
        headers: { Authorization: `Bearer ${token}` },
    });
};

export const documentsdownload = (id) => {
    return axios.get(`${API_URL}getprojectdocuments?id=${id}`, {
        headers: { Authorization: `Bearer ${token}` },
    });
};

export const uploadProfile = (data) => {
    return axios.post(`${API_URL}user/uploadProfileImage`, data, {
        headers: { Authorization: `Bearer ${token}` },
    });
};

export const projectstatuscount = () => {
    return axios.get(`${API_URL}getadminprojectstatuscount`, {
        headers: { Authorization: `Bearer ${token}` },
    });
};

export const graphtotalcount = () => {
    return axios.get(`${API_URL}getgraphcount`, {
        headers: { Authorization: `Bearer ${token}` },
    });
};

export const adminleavestatus = () => {
    return axios.get(`${API_URL}getadminleavesstatuscount`, {
        headers: { Authorization: `Bearer ${token}` },
    });
};

// dikonia

export const getApplicationList = (data) => {
    return axios.post(`${API_URL}admin/getApplicationList`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const getRoundOneList = (data) => {
    return axios.post(`${API_URL}admin/getRoundOneList`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const getRoundTwoList = (data) => {
    return axios.post(`${API_URL}admin/getRoundTwoList`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const deleteApplication = (data) => {
    return axios.post(`${API_URL}admin/deleteApplication`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getApplication = (data) => {
    return axios.post(`${API_URL}admin/getApplication`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const submitClarification = (data) => {
    return axios.post(`${API_URL}submitClarification`, data, {
        headers: {
            "Content-type": "application/json",
            // Authorization: `Bearer ${token}`,
        },
    });
};
export const flaggedsubmitClarification = (data) => {
    return axios.post(`${API_URL}flaggedsubmitClarification`, data, {
        headers: {
            "Content-type": "application/json",
            // Authorization: `Bearer ${token}`,
        },
    });
};
export const GetAssignMember = (data) => {
    return axios.post(`${API_URL}admin/getMemberlist`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const getAllMember = (data) => {
    return axios.post(`${API_URL}admin/getAllMember`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const getActiveMember = (data) => {
    return axios.post(`${API_URL}admin/getActiveMember`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const addComment = (data) => {
    return axios.post(`${API_URL}admin/addComment`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getflaggedApplication = (data) => {
    return axios.post(`${API_URL}admin/getflaggedApplication`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const addRole = (data) => {
    return axios.post(`${API_URL}admin/addRole`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

// export const getRole = (data) => {
	// return new Promise((resolve) => {
		// axios({
			// method: "post",
			// data: data,
			// url: `${API_URL}admin/getRole`,
			// headers: {"Content-type": "application/json", Authorization: `Bearer ${token}`},
		// })
		// .then(function (response) {
			// resolve(response);
		// })
		// .catch(function (error) {
			// resolve(error);
		// });
	// });
// };

export const getRole = (data) => {
    return axios.post(`${API_URL}admin/getRole`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getMemberbyLeve = (data) => {
    return axios.post(`${API_URL}admin/getMemberbyLevelid`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const assignManualUser = (data) => {
    return axios.post(`${API_URL}admin/assignManualUser`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const rejectApplication = (data) => {
    return axios.post(`${API_URL}admin/rejectApplication`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const assignTwoUser = (data) => {
    return axios.post(`${API_URL}admin/assignTwoUser`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const getApplicationListByUser = (data) => {
    return axios.post(`${API_URL}admin/getApplicationListByUser`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const userOneStatus = (data) => {
    return axios.post(`${API_URL}admin/userOneStatus`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const userTwoStatus = (data) => {
    return axios.post(`${API_URL}admin/userTwoStatus`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const userThreeStatus = (data) => {
    return axios.post(`${API_URL}admin/userThreeStatus`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const acceptFlaggedApplication = (data) => {
    return axios.post(`${API_URL}admin/acceptFlaggedApplication`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const acceptInterviewFlaggedApplication = (data) => {
    return axios.post(`${API_URL}admin/acceptInterviewFlaggedApplication`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const getArchiveApplicationList = (data) => {
    return axios.post(`${API_URL}admin/getArchiveApplicationList`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const getRejectedApplicationList = (data) => {
    return axios.post(`${API_URL}admin/getRejectedApplicationList`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getSelectedApplicationList = (data) => {
    return axios.post(`${API_URL}admin/getSelectedApplicationList`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getflaggedApplicationByUser = (data) => {
    return axios.post(`${API_URL}admin/getflaggedApplicationByUser`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const acceptByAdmin = (data) => {
    return axios.post(`${API_URL}admin/acceptByAdmin`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const acceptRejectOffer = (data) => {
    return axios.post(`${API_URL}admin/acceptRejectOffer`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const updateApplicationStatus = (data) => {
    return axios.post(`${API_URL}admin/updateApplicationStatus`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const updateAccountdata = (data) => {
    return axios.post(`${API_URL}admin/updateAccount`, data, {
        headers: {
            "Content-Type": "multipart/form-data",
            // "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const userRefresh = (data) => {
    return axios.post(`${API_URL}userRefresh`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getApplicationwithouttoken = (data) => {
    return axios.post(`${API_URL}admin/getApplicationwithouttoken`, data, {
        headers: {
            "Content-type": "application/json",
           // Authorization: `Bearer ${token}`,
        },
    });
};
export const getProgressById = (data) => {
    return axios.post(`${API_URL}getProgressById`, data, {
        headers: {
            "Content-type": "application/json",
            // Authorization: `Bearer ${token}`,
        },
    });
};
export const sendProgressUrl = (data) => {
    return axios.post(`${API_URL}sendProgressUrl`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const addTemplate = (data) => {
    return axios.post(`${API_URL}admin/addTemplate`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const getTemplatelist = (data) => {
    return axios.post(`${API_URL}admin/getTemplatelist`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const editTemplate = (data) => {
    return axios.post(`${API_URL}admin/editTemplate`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const getTemplate = (data) => {
    return axios.post(`${API_URL}admin/getTemplate`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const updateTemplateStatus = (data) => {
    return axios.post(`${API_URL}admin/updateTemplateStatus`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const interviewOneStatus = (data) => {
    return axios.post(`${API_URL}admin/interviewOneStatus`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const interviewTwoStatus = (data) => {
    return axios.post(`${API_URL}admin/interviewTwoStatus`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const interviewThreeStatus = (data) => {
    return axios.post(`${API_URL}admin/interviewThreeStatus`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const interviewacceptByAdmin = (data) => {
    return axios.post(`${API_URL}admin/interviewacceptByAdmin`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const interviewTwoRoundTwoStatus = (data) => {
    return axios.post(`${API_URL}admin/interviewTwoRoundTwoStatus`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const interviewTwoRoundThreeStatus = (data) => {
    return axios.post(`${API_URL}admin/interviewTwoRoundThreeStatus`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const interviewTwoRoundacceptByAdmin = (data) => {
    return axios.post(`${API_URL}admin/interviewTwoRoundacceptByAdmin`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const interviewTwoRoundOneStatus = (data) => {
    return axios.post(`${API_URL}admin/interviewTwoRoundOneStatus`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const assignOtherMember = (data) => {
    return axios.post(`${API_URL}admin/assignOtherMember`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const sendExternalLinkEmail = (data) => {
    return axios.post(`${API_URL}admin/sendExternalLink`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const notShowUpInterview = (data) => {
    return axios.post(`${API_URL}admin/notShowUpInterview`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const addWeeklyAvailability = (data) => {
    return axios.post(`${API_URL}admin/addWeeklyAvailability`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const getWeeklyAvailability = (data) => {
    return axios.post(`${API_URL}admin/getWeeklyAvailability`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const getDisableSlots = (data) => {
    return axios.post(`${API_URL}admin/getDisableSlots`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const getUpcomingSlots = (data) => {
    return axios.post(`${API_URL}admin/getUpcomingSlots`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const disableSlotsByTime = (data) => {
    return axios.post(`${API_URL}admin/disableSlotsByTime`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const countRoundTwo = (data) => {
    return axios.post(`${API_URL}admin/countRoundTwo`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const countRoundOne = (data) => {
    return axios.post(`${API_URL}admin/countRoundOne`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const sendResendEmail = (data) => {
    return axios.post(`${API_URL}sendResendEmail`, data, {
        headers: {
            "Content-type": "application/json",
            //Authorization: `Bearer ${token}`,
        },
    });
};
export const intialAcceptByAdmin = (data) => {
    return axios.post(`${API_URL}admin/intialAcceptByAdmin`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const getlogsByUser = (data) => {
    return axios.post(`${API_URL}admin/getlogsByUser`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const getInterviewsByUser = (data) => {
    return axios.post(`${API_URL}admin/getInterviewsByUser`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getTasksByUser = (data) => {
    return axios.post(`${API_URL}admin/getTasksByUser`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const reScheduleInterview = (data) => {
    return axios.post(`${API_URL}admin/reScheduleInterview`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const scheduleInterviewTwoRound = (data) => {
    return axios.post(`${API_URL}admin/scheduleInterviewTwoRound`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

// new API's
export const getAlllogsByUser = (data) => {
    return axios.post(`${API_URL}admin/getAlllogsByUser`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const changeTagStatus = (data) => {
    return axios.post(`${API_URL}admin/changeTagStatus`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const changeLevelStatus = (data) => {
    return axios.post(`${API_URL}admin/changeLevelStatus`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const getAllLevels = (data) => {
    return axios.post(`${API_URL}admin/getAllLevels`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const getAllTags = (data) => {
    return axios.post(`${API_URL}admin/getAllTags`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const addSmtp = (data) => {
    return axios.post(`${API_URL}admin/addSmtp`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const getSmtp = (data) => {
    return axios.post(`${API_URL}admin/getSmtp`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const getConfig = (data) => {
    return axios.post(`${API_URL}admin/getConfig`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};
export const updateConfig = (data) => {
    return axios.post(`${API_URL}admin/updateConfig`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getApplicationPdf = (data) => {
    return axios.post(`${API_URL}admin/getApplicationPdf`, data, {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};