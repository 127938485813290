import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
// import { useFormik } from "formik";
import Moment from "moment";
import { Form, Input, notification } from "antd";
import { flaggedsubmitClarification, getApplicationwithouttoken } from "../../Api/user/userapi";
import Footer from "../../Constant/Footer";
import TextArea from "antd/lib/input/TextArea";

// useEffect(() => {}, [values]);

const InterviewClarification = (props) => {
    const [applicationdetaildata, setArray] = useState([]);
    // const application_response_tabs = "application_que_hide";
    const [Plagiarismresponcedata, setPlagiarismresponcedata] = useState([]);
    // const application_response_tabs = "application_que_hide";

    // useEffect(() => {}, [values]);
    const { applicationid } = useParams();
    useEffect(() => {
        userDetails();
    }, []);
    const userDetails = async () => {
        try {
            const resp = await getApplicationwithouttoken({ applicaionId: applicationid });
            if (resp && resp.status === 200) {
                const list = resp;
                const arrayData = list.data.data;
                if (arrayData.clarification) {
                    props.history.push("/clarification-already");
                }
                if (arrayData["reportsdata"][0]) {
                    const reportsdata = arrayData["reportsdata"][0];
                    let Plagiarismdata = [];
                    for (let key in reportsdata) {
                        if (reportsdata[key]["html"]) {
                            const resultdata = reportsdata[key]["html"].replace("", "\n");
                            Plagiarismdata.push({
                                order: reportsdata[key]["order"],
                                html: resultdata,
                            });
                        }
                        else {
                            Plagiarismdata.push({
                                order: reportsdata[key]["order"],
                                html: '',
                            });
                        }
                    }
                    setPlagiarismresponcedata(Plagiarismdata);
                }
                setArray(arrayData);
            }
        } catch (e) { }
    };
    const handleSubmit = async (e) => {
        const payload = {
            text: e.clarification,
            applicaionId: applicationid,
        };

        try {
            // setLoading(true);
            const result = await flaggedsubmitClarification(payload);

            if (result && result.status === 200) {
                props.history.push("/clarification-thankyou");
                setTimeout(() => {
                    notification.open({
                        message: "Clarification",
                        description: "Added Successfully.",
                    });
                }, 1000);
            }
        } catch (e) {
        } finally {
            // setLoading(false);
        }
    };
    return (
        <div className="d-flex flex-column flex-root header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed">
            <div className="page d-flex flex-row flex-column-fluid">
                <div className="d-flex flex-column flex-row-fluid" id="kt_wrapper">
                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                        <div className="post d-flex flex-column-fluid" id="kt_post">
                            <div id="kt_content_container" className="container-xxl">
                                <div className="card mb-xl-8">
                                    <div className="card-body p-0">
                                        <div className="card-px text-center py-20 my-10">
                                            <Link to="/" className="mb-10">
                                                <img alt="Logo" src="/assets/media/logos/logo.png" className="h-50px logo" />
                                            </Link>

                                            <div className="row my-10" style={{ justifyContent: "center" }}>
                                                <div className="col-lg-3 fv-row fv-plugins-icon-container text-start">
                                                    <label className="col-form-label fw-bold fs-6">Name</label>
                                                    <div className="form-control form-control-lg form-control-solid mb-3 mb-lg-0">
                                                        {applicationdetaildata.firstname ? applicationdetaildata.firstname : ""} {applicationdetaildata.lastname ? applicationdetaildata.lastname : ""}
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 fv-row fv-plugins-icon-container text-start">
                                                    <label className="col-form-label fw-bold fs-6">Email</label>
                                                    <div className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"> {applicationdetaildata.email ? applicationdetaildata.email : ""}</div>
                                                </div>

                                                <div className="col-lg-3 fv-row fv-plugins-icon-container text-start">
                                                    <label className="col-form-label fw-bold fs-6">Applied For</label>
                                                    <div className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"> {applicationdetaildata.TagName ? applicationdetaildata.TagName : ""}</div>
                                                </div>

                                                <div className="col-lg-9 fv-row fv-plugins-icon-container text-start">
                                                    <Form name="normal_login" className="login-form" initialValues={{ remember: true }} onFinish={handleSubmit}>
                                                        <label className="col-form-label fw-bold fs-6">Enter Your Clarification for Not Joining Interview</label>
                                                        <Form.Item name="clarification" rules={[{ required: true, message: "Please enter your clarification for not joining interview!" }]}>
                                                            <TextArea placeholder="Clarification" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" />
                                                        </Form.Item>

                                                        <button type="submit" id="kt_new_password_submit" className="btn btn-sm btn-primary fw-bolder me-4 mt-4">
                                                            <span className="indicator-label">Send</span>
                                                        </button>
                                                    </Form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Footer />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
                <span className="svg-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="black" />
                        <path
                            d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                            fill="black"
                        />
                    </svg>
                </span>
            </div>
        </div>
    );
};

export default InterviewClarification;
