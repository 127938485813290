import React from "react";
import { Redirect } from "react-router-dom";
import Member from "../../Component/Member/Member";
import AddMember from "../../Component/Member/AddMember";
import EditMember from "../../Component/Member/EditMember";
import ViewMember from "../../Component/Member/ViewMember";

class Members extends React.Component {
    render() {
		//const user_info = JSON.parse(localStorage.getItem("gpt_authuser"));
        const user_role = JSON.parse(localStorage.getItem("gpt_user_role"));
        const renderview = () => {
            if (this.props.match.path === "/member") {
                if ((user_role && user_role.memberEdit && user_role.memberEdit === "yes") || (user_role && user_role.memberDelete && user_role.memberDelete === "yes") || (user_role && user_role.memberDelete && user_role.memberDelete === "yes") ) {
                    return <Member />;
                  
                }
                else{
                    return <Redirect to="/dashboard" />;
                }
            }
            if (this.props.match.path === "/Addmember") {
                if(user_role && user_role.memberAdd && user_role.memberAdd === "yes" ) {
                    return <AddMember />;
                  
                }
                else{
                    return <Redirect to="/dashboard" />;
                }
            }            
            if (this.props.match.path === "/edit-member/:memberId") {
                if(user_role && user_role.memberEdit && user_role.memberEdit === "yes" ) {
                   return <EditMember memberId={this.props.match.params.memberId} />;
                  
                }
                else{
                    return <Redirect to="/dashboard" />;
                }
                
            }
            if (this.props.match.path === "/view-member/:memberId") {
                if (user_role && user_role.memberView && user_role.memberView === "yes") {
                    return <ViewMember memberId={this.props.match.params.memberId} />;

                }
                else {
                    return <Redirect to="/dashboard" />;
                }

            }
        };
		
        return (
            <React.Fragment>
                {/* <Notifications /> */}
                {renderview()}
            </React.Fragment>
        );
		
    }
}
export default Members;