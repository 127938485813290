import React, { useEffect, useState, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import Moment from "moment";
// import { Form, Input, Select, notification } from "antd";
// import { Modal, notification } from "antd";
import { getApplication } from "../../Api/user/userapi";
// import csc from "country-state-city";
// import AssignApplicationUser from "./AssignApplicationUser";

import LeftSideBar from "../../Constant/LeftSideBar";
import TopBar from "../../Constant/TopBar";
import Footer from "../../Constant/Footer";
// import Validations from "../Utility/Validations";

const RepeatedApplication = (props) => {
    const [applicationdetaildata, setArray] = useState([]);
    const [application_response_tabs, setapplication_response_tabs] = useState("application_que_hide");
    const [viewmoreform, setviewmoreform] = useState("View More");
    const [application_response_text, setapplication_response_text] = useState("application_que_show");
    // const [application_form, setapplication_form] = useState([]);

    const [Plagiarismresponcedata, setPlagiarismresponcedata] = useState([]);

    const { applicaionId } = useParams();
    // const user_info = JSON.parse(localStorage.getItem("gpt_authuser"));
    const user_role = JSON.parse(localStorage.getItem("gpt_user_role"));

    // const [editChapterData, setEditChapterData] = useState({});
    // const [id, setId] = useState();

    // const handleCancel = () => {
    //     setIsAssignModalVisible(false);
    // };
    // const countries = csc.getAllCountries();

    // const updatedCountries = countries.map((country) => ({
    //     label: country.name,
    //     value: country.id,
    //     ...country,
    // }));
	
	
	const userDetails = useCallback( async () => {
		try {
			const resp = await getApplication({ applicaionId: applicaionId });
			if (resp && resp.status === 200) {
				const list = resp;

				const arrayData = list.data.data;

				if (arrayData["reportsdata"][0]) {
					const reportsdata = arrayData["reportsdata"][0];
					let Plagiarismdata = [];
					for (let key in reportsdata) {
						if (reportsdata[key]["html"]) {
							const resultdata = reportsdata[key]["html"].replace("", "\n");
							Plagiarismdata.push({
								order: reportsdata[key]["order"],
								html: resultdata,
							});
						}
					}
					setPlagiarismresponcedata(Plagiarismdata);
				}

				setArray(arrayData);
			}
		} catch (e) { }
	}, [applicaionId]);

    // const userDetails = async () => {
        // try {
            // const resp = await getApplication({ applicaionId: applicaionId });
            // if (resp && resp.status === 200) {
                // const list = resp;

                // const arrayData = list.data.data;

                // if (arrayData["reportsdata"][0]) {
                    // const reportsdata = arrayData["reportsdata"][0];
                    // let Plagiarismdata = [];
                    // for (let key in reportsdata) {
                        // if (reportsdata[key]["html"]) {
                            // const resultdata = reportsdata[key]["html"].replace("", "\n");
                            // Plagiarismdata.push({
                                // order: reportsdata[key]["order"],
                                // html: resultdata,
                            // });
                        // }
                    // }
                    // setPlagiarismresponcedata(Plagiarismdata);
                // }

                // setArray(arrayData);
            // }
        // } catch (e) { }
    // };
    
    useEffect(() => {        
        userDetails();
    }, [userDetails]);

    const HideShowApplicationForm = (data) => {
        if (data === "application_que_hide") {
            setviewmoreform("View More");
            setapplication_response_text("application_que_show");
        } else {
            setviewmoreform("View Less");
            setapplication_response_text("application_que_hide");
        }
        setapplication_response_tabs(data);
    };
   

    console.log("user_role==", user_role);
    return (
        <div className="d-flex flex-column flex-root header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed h-100">
            <div className="page d-flex flex-row flex-column-fluid">
                <LeftSideBar />
                <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                    <TopBar />

                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                        <div className="toolbar" id="kt_toolbar">
                            <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                                <div
                                    data-kt-swapper="true"
                                    data-kt-swapper-mode="prepend"
                                    data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
                                    className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
                                >
                                    <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
                                        Applications
                                        <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                                    </h1>

                                    <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                                        <li className="breadcrumb-item text-muted">
                                            <Link to="/dashboard" className="text-muted text-hover-primary">
                                                Home
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <span className="bullet bg-gray-200 w-5px h-2px"></span>
                                        </li>

                                        <li className="breadcrumb-item text-dark">Repeated Applications</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="post d-flex flex-column-fluid" id="kt_post">
                            <div id="kt_content_container" className="container-xxl">
                                {/* <Form name="normal_login" className="login-form" initialValues={{ remember: true }}> */}
                                <div className="row gy-5 g-xl-8">
                                    <div className="col-xl-12">
                                        <div className="card card-xl-stretch mb-8">
                                            <div className="card-header border-0">
                                                <h3 className="card-title fw-bolder text-dark">Repeated Application</h3>
                                                {user_role && user_role.applicationPlaigarism && user_role.applicationPlaigarism === "yes" && (
                                                    <div className="card-toolbar my-1">
                                                        <div className="result-main bg-light-primary border-primary border border-dashed mt-4">
                                                            <span className="fs-5 fw-bolder">
                                                                Plagiarism <br />
                                                                {applicationdetaildata.palagrism ? applicationdetaildata.palagrism : ""}%
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="card-body pt-2">
                                                <div className="row">
                                                    <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                        <label className="col-form-label fw-bold fs-6">Name</label>
                                                        <div className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" >
                                                            {applicationdetaildata.firstname ? applicationdetaildata.firstname : ""} {applicationdetaildata.lastname ? applicationdetaildata.lastname : ""}
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                        <label className="col-form-label fw-bold fs-6">Email</label>
                                                        <div className="form-control form-control-lg form-control-solid mb-3 mb-lg-0">{applicationdetaildata.email ? applicationdetaildata.email : ""}</div>
                                                    </div>

                                                    <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                        <label className="col-form-label  fw-bold fs-6">Applied For</label>
                                                        <div className="form-control form-control-lg form-control-solid mb-3 mb-lg-0">{applicationdetaildata.TagName ? applicationdetaildata.TagName : ""}</div>
                                                    </div>

                                                    <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                        <label className="col-form-label  fw-bold fs-6">Phone Number</label>
                                                        <div className="form-control form-control-lg form-control-solid mb-3 mb-lg-0">{applicationdetaildata.phone ? applicationdetaildata.phone : ""}</div>
                                                    </div>
                                                    <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                        <label className="col-form-label  fw-bold fs-6">Date</label>
                                                        <div className="form-control form-control-lg form-control-solid mb-3 mb-lg-0">
                                                            {applicationdetaildata.createdAt ? Moment(applicationdetaildata.createdAt).format("DD-MM-YYYY") : ""}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                        <label className="col-form-label  fw-bold fs-6">Status (Last Time)</label>
                                                        <div className="form-control form-control-lg form-control-solid mb-3 mb-lg-0">{applicationdetaildata.status ? applicationdetaildata.status : ""}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* start que form */}
                                <div className="row">
								<div className="col-xl-12">
                                    <div className="card card-xl-stretch mb-xl-8">
                                        <div className="card-header border-0">
                                            <h3 className="card-title fw-bolder text-dark">Previous Applications (Overview)</h3>
                                        </div>
                                        <div className="card-body pt-2">
                                            <div className="d-flex align-items-center justify-content-between mb-3">
                                                <div className="mr-2"></div>
                                            </div>

                                            <h5>Q1: How did you learn about the opportunity to apply to Girl Power Talk? If you were referred by someone please list their name.</h5>
                                            {/* <p className="p-0">{applicationdetaildata.learnabout ? applicationdetaildata.learnabout : ""}</p> */}
                                            {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                <>
                                                    {Plagiarismresponcedata.map((item, idx) => (
                                                        <div key={idx}>{item.order && item.order === 1 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                    ))}
                                                </>
                                            ) : (<p className="p-0">{applicationdetaildata.learnabout ? applicationdetaildata.learnabout : ""}</p>)}
                                            {application_response_tabs === "application_que_show" ? (
                                                <>
                                                    <div className="que_ans">
                                                        <h5>Q2: Do you have any mentors? If so, describe one or several of them.</h5>
                                                        {/* <p className="p-0">{applicationdetaildata.mentor ? applicationdetaildata.mentor : ""}</p> */}

                                                        {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                            <>
                                                                {Plagiarismresponcedata.map((item, idx1) => (
                                                                    <div key={idx1}>{item.order && item.order === 2 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                ))}
                                                            </>
                                                        ) : (<p className="p-0">{applicationdetaildata.mentor ? applicationdetaildata.mentor : ""}</p>)}
                                                    </div>
                                                    <div className="que_ans">
                                                        <h5>Q3: Name one person or even several people you admire in history and why. They could be living or have already passed.</h5>
                                                        {/* <p className="p-0">{applicationdetaildata.admireinhistory ? applicationdetaildata.admireinhistory : ""}</p> */}

                                                        {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                            <>
                                                                {Plagiarismresponcedata.map((item, idx2) => (
                                                                    <div key={idx2}>{item.order && item.order === 3 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                ))}
                                                            </>
                                                        ) : (<p className="p-0">{applicationdetaildata.admireinhistory ? applicationdetaildata.admireinhistory : ""}</p>)}
                                                    </div>
                                                    <div className="que_ans">
                                                        <h5>Q4: Leadership comes in many forms. We value diversity of leadership. Describe your leadership qualities.</h5>
                                                        {/* <p className="p-0">{applicationdetaildata.leadership ? applicationdetaildata.leadership : ""}</p> */}

                                                        {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                            <>
                                                                {Plagiarismresponcedata.map((item, idx4) => (
                                                                    <div key={idx4}>{item.order && item.order === 4 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                ))}
                                                            </>
                                                        ) : (<p className="p-0">{applicationdetaildata.leadership ? applicationdetaildata.leadership : ""}</p>)}
                                                    </div>
                                                    <div className="que_ans">
                                                        <h5>
                                                            Q5: What was a time you exercised leadership? Tell us about a situation where you were a leader. How did you keep others in your group, team, family or
                                                            friend circle united and productive? Describe how you led, perhaps vocally, by example or both.
                                                        </h5>
                                                        {/* <p className="p-0">{applicationdetaildata.exerciseleadership ? applicationdetaildata.exerciseleadership : ""}</p> */}

                                                        {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                            <>
                                                                {Plagiarismresponcedata.map((item, idx5) => (
                                                                    <div key={idx5}>{item.order && item.order === 5 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                ))}
                                                            </>
                                                        ) : (<p className="p-0">{applicationdetaildata.exerciseleadership ? applicationdetaildata.exerciseleadership : ""}</p>)}
                                                    </div>
                                                    <div className="que_ans">
                                                        <h5>Q6: What are your biggest strengths? Describe at least two of them and how can you use them to make the world better.</h5>
                                                        {/* <p className="p-0">{applicationdetaildata.biggeststrength ? applicationdetaildata.biggeststrength : ""}</p> */}

                                                        {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                            <>
                                                                {Plagiarismresponcedata.map((item, idx6) => (
                                                                    <div key={idx6}>{item.order && item.order === 6 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                ))}
                                                            </>
                                                        ) : (<p className="p-0">{applicationdetaildata.biggeststrength ? applicationdetaildata.biggeststrength : ""}</p>)}
                                                    </div>
                                                    <div className="que_ans">
                                                        <h5>Q7: Write an autobiography for your life in 250-350 words. You have freedom to be imaginative & creative.</h5>
                                                        {/* <p className="p-0">{applicationdetaildata.autobiography ? applicationdetaildata.autobiography : ""}</p> */}

                                                        {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                            <>
                                                                {Plagiarismresponcedata.map((item, idx7) => (
                                                                    <div key={idx7}>{item.order && item.order === 7 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                ))}
                                                            </>
                                                        ) : (<p className="p-0">{applicationdetaildata.autobiography ? applicationdetaildata.autobiography : ""}</p>)}
                                                    </div>
                                                    <div className="que_ans">
                                                        <h5>Q8: What is your favorite quote? What does it mean to you personally and/or professionally?</h5>
                                                        {/* <p className="p-0">{applicationdetaildata.favoritequote ? applicationdetaildata.favoritequote : ""}</p> */}

                                                        {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                            <>
                                                                {Plagiarismresponcedata.map((item, idx8) => (
                                                                    <div key={idx8}>{item.order && item.order === 8 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                ))}
                                                            </>
                                                        ) : (<p className="p-0">{applicationdetaildata.favoritequote ? applicationdetaildata.favoritequote : ""}</p>)}
                                                    </div>
                                                    <div className="que_ans">
                                                        <h5>Q9: What does confidence mean to you? Describe what being confident means to you.</h5>
                                                        {/* <p className="p-0">{applicationdetaildata.confidence ? applicationdetaildata.confidence : ""}</p> */}

                                                        {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                            <>
                                                                {Plagiarismresponcedata.map((item, idx9) => (
                                                                    <div key={idx9}>{item.order && item.order === 9 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                ))}
                                                            </>
                                                        ) : (<p className="p-0">{applicationdetaildata.confidence ? applicationdetaildata.confidence : ""}</p>)}
                                                    </div>
                                                    <div className="que_ans">
                                                        <h5>Q10: Tell us about a challenge or conflict you've faced, and how you dealt with it.</h5>
                                                        {/* <p className="p-0">{applicationdetaildata.conflict ? applicationdetaildata.conflict : ""}</p> */}

                                                        {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                            <>
                                                                {Plagiarismresponcedata.map((item, idx10) => (
                                                                    <div key={idx10}>{item.order && item.order === 10 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                ))}
                                                            </>
                                                        ) : (<p className="p-0">{applicationdetaildata.conflict ? applicationdetaildata.conflict : ""}</p>)}
                                                    </div>
                                                    <div className="que_ans">
                                                        <h5>Q11: What is your greatest achievement in life to date?</h5>
                                                        {/* <p className="p-0">{applicationdetaildata.gretestachivement ? applicationdetaildata.gretestachivement : ""}</p> */}

                                                        {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                            <>
                                                                {Plagiarismresponcedata.map((item, idx11) => (
                                                                    <div key={idx11}>{item.order && item.order === 11 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                ))}
                                                            </>
                                                        ) : (<p className="p-0">{applicationdetaildata.gretestachivement ? applicationdetaildata.gretestachivement : ""}</p>)}
                                                    </div>
                                                    <div className="que_ans">
                                                        <h5>Q12: What skills do you seek to develop if you join the Girl Power Talk culture?</h5>
                                                        {/* <p className="p-0">{applicationdetaildata.skills ? applicationdetaildata.skills : ""}</p> */}

                                                        {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                            <>
                                                                {Plagiarismresponcedata.map((item, idx12) => (
                                                                    <div key={idx12}>{item.order && item.order === 12 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                ))}
                                                            </>
                                                        ) : (<p className="p-0">{applicationdetaildata.skills ? applicationdetaildata.skills : ""}</p>)}
                                                    </div>
                                                    <div className="que_ans">
                                                        <h5>Q13: “Our fears are more dangerous than our dangers.” (Seneca 4BC – 65 AD) What is your biggest fear? Why?</h5>
                                                        {/* <p className="p-0">{applicationdetaildata.fear ? applicationdetaildata.fear : ""}</p> */}

                                                        {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                            <>
                                                                {Plagiarismresponcedata.map((item, idx13) => (
                                                                    <div key={idx13}>{item.order && item.order === 13 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                ))}
                                                            </>
                                                        ) : (<p className="p-0">{applicationdetaildata.fear ? applicationdetaildata.fear : ""}</p>)}
                                                    </div>
                                                    <div className="que_ans">
                                                        <h5>
                                                            Q14: What are your most significant weaknesses? Describe at least two and how you plan to improve or mitigate them. Note: This is not a trick question.
                                                            Everyone has weaknesses. Please be honest : )
                                                        </h5>
                                                        {/* <p className="p-0">{applicationdetaildata.weakness ? applicationdetaildata.weakness : ""}</p> */}

                                                        {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                            <>
                                                                {Plagiarismresponcedata.map((item, idx14) => (
                                                                    <div key={idx14}>{item.order && item.order === 14 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                ))}
                                                            </>
                                                        ) : (<p className="p-0">{applicationdetaildata.weakness ? applicationdetaildata.weakness : ""}</p>)}
                                                    </div>
                                                    <div className="que_ans">
                                                        <h5>
                                                            Q15: What do you think is the most concerning women issue? If given the power to change the issue, describe briefly the plan you will implement to
                                                            change that.
                                                        </h5>
                                                        {/* <p className="p-0">{applicationdetaildata.womenissue ? applicationdetaildata.womenissue : ""}</p> */}

                                                        {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                            <>
                                                                {Plagiarismresponcedata.map((item, idx15) => (
                                                                    <div key={idx15}>{item.order && item.order === 15 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                ))}
                                                            </>
                                                        ) : (<p className="p-0">{applicationdetaildata.womenissue ? applicationdetaildata.womenissue : ""}</p>)}
                                                    </div>
                                                    <div className="que_ans">
                                                        <h5>Q16: What is your favorite book? Why is it your favorite? Please share what you learned from it and how you apply practically to your life?</h5>
                                                        {/* <p className="p-0">{applicationdetaildata.favoritebook ? applicationdetaildata.favoritebook : ""}</p> */}

                                                        {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                            <>
                                                                {Plagiarismresponcedata.map((item, idx16) => (
                                                                    <div key={idx16}>{item.order && item.order === 16 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                ))}
                                                            </>
                                                        ) : (<p className="p-0">{applicationdetaildata.favoritebook ? applicationdetaildata.favoritebook : ""}</p>)}
                                                    </div>

                                                    <div className="que_ans">
                                                        <h5>
                                                            Q17: One piece of knowledge or information you want to share with us that we may not know. It could be anything, but must be something that is factual
                                                            and you find compelling.
                                                        </h5>
                                                        {/* <p className="p-0">{applicationdetaildata.information ? applicationdetaildata.information : ""}</p> */}

                                                        {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                            <>
                                                                {Plagiarismresponcedata.map((item, idx17) => (
                                                                    <div key={idx17}>{item.order && item.order === 17 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                ))}
                                                            </>
                                                        ) : (<p className="p-0">{applicationdetaildata.information ? applicationdetaildata.information : ""}</p>)}
                                                    </div>
                                                    <div className="que_ans">
                                                        <h5>Q18: One interesting fact that we may not know about you when evaluating your candidacy to join the Girl Power Talk family.</h5>
                                                        {/* <p className="p-0">{applicationdetaildata.interstingfacts ? applicationdetaildata.interstingfacts : ""}</p> */}

                                                        {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                            <>
                                                                {Plagiarismresponcedata.map((item, idx18) => (
                                                                    <div key={idx18}>{item.order && item.order === 18 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                ))}
                                                            </>
                                                        ) : (<p className="p-0">{applicationdetaildata.interstingfacts ? applicationdetaildata.interstingfacts : ""}</p>)}
                                                    </div>
                                                    <div className="que_ans">
                                                        <h5>Q19: If you could wave a magic wand, what qualities would you want in an ideal “boss”, “manager”, or team leader for you?</h5>
                                                        {/* <p className="p-0">{applicationdetaildata.magicwand ? applicationdetaildata.magicwand : ""}</p> */}

                                                        {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                            <>
                                                                {Plagiarismresponcedata.map((item, idx19) => (
                                                                    <div key={idx19}>{item.order && item.order === 19 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                ))}
                                                            </>
                                                        ) : (<p className="p-0">{applicationdetaildata.magicwand ? applicationdetaildata.magicwand : ""}</p>)}
                                                    </div>
                                                    <div className="que_ans">
                                                        <h5>Q20: What will you do if you are given a million dollars?</h5>
                                                        {/* <p className="p-0">{applicationdetaildata.milliondollars ? applicationdetaildata.milliondollars : ""}</p> */}

                                                        {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                            <>
                                                                {Plagiarismresponcedata.map((item, idx20) => (
                                                                    <div key={idx20}>{item.order && item.order === 20 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                ))}
                                                            </>
                                                        ) : (<p className="p-0">{applicationdetaildata.milliondollars ? applicationdetaildata.milliondollars : ""}</p>)}
                                                    </div>
                                                    <div className="que_ans">
                                                        <h5>Q21: How do you deal with pressure or stressful situations?</h5>
                                                        {/* <p className="p-0">{applicationdetaildata.stressfulsitutations ? applicationdetaildata.stressfulsitutations : ""}</p> */}

                                                        {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                            <>
                                                                {Plagiarismresponcedata.map((item, idx21) => (
                                                                    <div key={idx21}>{item.order && item.order === 21 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                ))}
                                                            </>
                                                        ) : (<p className="p-0">{applicationdetaildata.stressfulsitutations ? applicationdetaildata.stressfulsitutations : ""}</p>)}
                                                    </div>
                                                    <div className="que_ans">
                                                        <h5>Q22: Most influential and/or inspiring woman in your life except your mother. Why?</h5>
                                                        {/* <p className="p-0">{applicationdetaildata.inspiringwoman ? applicationdetaildata.inspiringwoman : ""}</p> */}

                                                        {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                            <>
                                                                {Plagiarismresponcedata.map((item, idx22) => (
                                                                    <div key={idx22}>{item.order && item.order === 22 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                ))}
                                                            </>
                                                        ) : (<p className="p-0">{applicationdetaildata.inspiringwoman ? applicationdetaildata.inspiringwoman : ""}</p>)}
                                                    </div>
                                                    <div className="que_ans">
                                                        <h5>
                                                            Q23: "The future belongs to those who believe in the beauty of their dreams." (Eleanor Roosevelt 1884 – 1962). What does this quote mean to you and what
                                                            do you think about when you read it?
                                                        </h5>
                                                        {/* <p className="p-0">{applicationdetaildata.quotemean ? applicationdetaildata.quotemean : ""}</p> */}

                                                        {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                            <>
                                                                {Plagiarismresponcedata.map((item, idx23) => (
                                                                    <div key={idx23}>{item.order && item.order === 23 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                ))}
                                                            </>
                                                        ) : (<p className="p-0">{applicationdetaildata.quotemean ? applicationdetaildata.quotemean : ""}</p>)}
                                                    </div>
                                                    <div className="que_ans">
                                                        <h5>
                                                            Q24: What do you like to do outside of work? Describe how you spend most of your time, including hobbies and interests. This will help us get to know
                                                            you better : )
                                                        </h5>
                                                        {/* <p className="p-0">{applicationdetaildata.outsideofwork ? applicationdetaildata.outsideofwork : ""}</p> */}

                                                        {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                            <>
                                                                {Plagiarismresponcedata.map((item, idx24) => (
                                                                    <div key={idx24}>{item.order && item.order === 24 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                ))}
                                                            </>
                                                        ) : (<p className="p-0">{applicationdetaildata.outsideofwork ? applicationdetaildata.outsideofwork : ""}</p>)}
                                                    </div>
                                                    <div className="que_ans">
                                                        <h5>Q25: How would your closest friends and family describe you?</h5>
                                                        {/* <p className="p-0">{applicationdetaildata.closestfriend ? applicationdetaildata.closestfriend : ""}</p> */}

                                                        {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                            <>
                                                                {Plagiarismresponcedata.map((item, idx25) => (
                                                                    <div key={idx25}>{item.order && item.order === 25 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                ))}
                                                            </>
                                                        ) : (<p className="p-0">{applicationdetaildata.closestfriend ? applicationdetaildata.closestfriend : ""}</p>)}
                                                    </div>
                                                    <div className="que_ans">
                                                        <h5>
                                                            Q26: Why did you decide to complete the indication of interest for Girl Power Talk and our sister company, Blue Ocean Global Technology? In other words,
                                                            what inspires you to be part of the Girl Power Talk culture and why do you think you will be a good fit?
                                                        </h5>
                                                        {/* <p className="p-0">{applicationdetaildata.intrestofgirlpower ? applicationdetaildata.intrestofgirlpower : ""}</p> */}

                                                        {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                            <>
                                                                {Plagiarismresponcedata.map((item, idx26) => (
                                                                    <div key={idx26}>{item.order && item.order === 26 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                ))}
                                                            </>
                                                        ) : (<p className="p-0">{applicationdetaildata.intrestofgirlpower ? applicationdetaildata.intrestofgirlpower : ""}</p>)}
                                                    </div>
                                                    <div className="que_ans">
                                                        <h5>Q27: Do you have any questions for us? Please feel free to ask them here.</h5>
                                                        {/* <p className="p-0">{applicationdetaildata.anyquestion ? applicationdetaildata.anyquestion : ""}</p> */}

                                                        {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                            <>
                                                                {Plagiarismresponcedata.map((item, idx27) => (
                                                                    <div key={idx27}>{item.order && item.order === 27 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                ))}
                                                            </>
                                                        ) : (<p className="p-0">{applicationdetaildata.anyquestion ? applicationdetaildata.anyquestion : ""}</p>)}
                                                    </div>
                                                </>
                                            ) : (
                                                ""
                                            )}
                                            <span onClick={() => HideShowApplicationForm(application_response_text)} className="myBtn_2 myBtn">
                                                {viewmoreform}
                                            </span>
                                            {/* end Application Form */}
                                        </div>
                                    </div>
                                </div>
								</div>
                                {/* end que form */}

                                
                            </div>
                        </div>
                    </div>
					<Footer />
                </div>
            </div>
            <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
                <span className="svg-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="black" />
                        <path
                            d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                            fill="black"
                        />
                    </svg>
                </span>
            </div>
           
        </div>
    );
};

export default RepeatedApplication;
