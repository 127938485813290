import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
// import { useFormik } from "formik";
import Moment from "moment";
import { Form, Input, notification } from "antd";
import { flaggedsubmitClarification, getApplicationwithouttoken } from "../../Api/user/userapi";
import Footer from "../../Constant/Footer";
import TextArea from "antd/lib/input/TextArea";

// useEffect(() => {}, [values]);

const FlaggClarification = (props) => {
    const [applicationdetaildata, setArray] = useState([]);
    // const application_response_tabs = "application_que_hide";
    const [Plagiarismresponcedata, setPlagiarismresponcedata] = useState([]);
    // const application_response_tabs = "application_que_hide";

    // useEffect(() => {}, [values]);
    const { applicationid } = useParams();
    useEffect(() => {
        userDetails();
    }, []);
    const userDetails = async () => {
        try {
            const resp = await getApplicationwithouttoken({ applicaionId: applicationid });
            if (resp && resp.status === 200) {
                const list = resp;
                const arrayData = list.data.data;
                if (arrayData.clarification) {
                    props.history.push("/clarification-already");
                }
                if (arrayData["reportsdata"][0]) {
                    const reportsdata = arrayData["reportsdata"][0];
                    let Plagiarismdata = [];
                    for (let key in reportsdata) {
                        if (reportsdata[key]["html"]) {
                            const resultdata = reportsdata[key]["html"].replace("", "\n");
                            Plagiarismdata.push({
                                order: reportsdata[key]["order"],
                                html: resultdata,
                            });
                        }
                        else{
                            Plagiarismdata.push({
                                order: reportsdata[key]["order"],
                                html: '',
                            });
                        }
                    }
                    setPlagiarismresponcedata(Plagiarismdata);
                }
                setArray(arrayData);
            }
        } catch (e) { }
    };
    const handleSubmit = async (e) => {
        const payload = {
            text: e.clarification,
            applicaionId: applicationid,
        };

        try {
            // setLoading(true);
            const result = await flaggedsubmitClarification(payload);

            if (result && result.status === 200) {
                props.history.push("/clarification-thankyou");
                setTimeout(() => {
                    notification.open({
                        message: "Clarification",
                        description: "Added Successfully.",
                    });
                }, 1000);
            }
        } catch (e) {
        } finally {
            // setLoading(false);
        }
    };
    return (
        <div className="d-flex flex-column flex-root header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed">
            <div className="page d-flex flex-row flex-column-fluid">
                <div className="d-flex flex-column flex-row-fluid" id="kt_wrapper">
                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                        <div className="post d-flex flex-column-fluid" id="kt_post">
                            <div id="kt_content_container" className="container-xxl">
                                <div className="card mb-xl-8">
                                    <div className="card-body p-0">
                                        <div className="card-px text-center py-20 my-10">
                                            <Link to="/" className="mb-10">
                                                <img alt="Logo" src="/assets/media/logos/logo.png" className="h-50px logo" />
                                            </Link>

                                            <div className="row my-10" style={{ justifyContent: "center" }}>
                                                <div className="col-lg-3 fv-row fv-plugins-icon-container text-start">
                                                    <label className="col-form-label fw-bold fs-6">Name</label>
                                                    <div className="form-control form-control-lg form-control-solid mb-3 mb-lg-0">
                                                        {applicationdetaildata.firstname ? applicationdetaildata.firstname : ""} {applicationdetaildata.lastname ? applicationdetaildata.lastname : ""}
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 fv-row fv-plugins-icon-container text-start">
                                                    <label className="col-form-label fw-bold fs-6">Email</label>
                                                    <div className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"> {applicationdetaildata.email ? applicationdetaildata.email : ""}</div>
                                                </div>

                                                <div className="col-lg-3 fv-row fv-plugins-icon-container text-start">
                                                    <label className="col-form-label fw-bold fs-6">Applied For</label>
                                                    <div className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"> {applicationdetaildata.TagName ? applicationdetaildata.TagName : ""}</div>
                                                </div>

                                                <div className="col-lg-9 mt-8 text-start">
                                                    <div className="text-start mb-3 clarification_form">
                                                        <div className="mb-4">
                                                            <h3 className="text-dark-75 font-weight-bold">Application Form</h3>
                                                            <span className="text-muted ml-2">{applicationdetaildata.updatedAt ? Moment(applicationdetaildata.updatedAt).format("h:mm a") : ""}, </span>
                                                            <span className="text-muted ml-2">{applicationdetaildata.updatedAt ? Moment(applicationdetaildata.updatedAt).format("DD MMM YYYY") : ""}</span>
                                                        </div>

                                                        <h5>Q1: How did you learn about Girl Power Talk? If you were referred by someone, please provide their full name and your relationship to that person.</h5>
                                                       {/* <p className="p-0">{applicationdetaildata.learnabout ? applicationdetaildata.learnabout : ""}</p> */}
                                                        <p className="p-0">{applicationdetaildata.learnabout ? applicationdetaildata.learnabout : ""}</p>
                                                                        {/* {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                            <>
                                                                                {Plagiarismresponcedata.map((item, idx) => (
                                                                                    <div key={idx}>
                                                                                        {item.order && item.order === 1 && (
                                                                                            <>
                                                                                                {item.html && item.html !== '' ? (
                                                                                                    <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />
                                                                                                ) : (
                                                                                                    <>
                                                                                                        {applicationdetaildata.learnabout ? applicationdetaildata.learnabout : ""}
                                                                                                    </>
                                                                                                )}


                                                                                            </>
                                                                                        )}
                                                                                        </div>
                                                                                ))}
                                                                            </>
                                                                        ) : (
                                                                            <p className="p-0">{applicationdetaildata.learnabout ? applicationdetaildata.learnabout : ""}</p>
                                                                        )} */}

                                                        <div className="que_ans">
                                                            <h5>Q2: Name one or more people, living or deceased, that you admire, and explain why.</h5>
                                                            {/* <p className="p-0">{applicationdetaildata.mentor ? applicationdetaildata.mentor : ""}</p> */}

                                                            {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                <>
                                                                    {Plagiarismresponcedata.map((item, idx1) => (
                                                                        <div key={idx1}>{item.order && item.order === 2 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                <p className="p-0">{applicationdetaildata.mentor ? applicationdetaildata.mentor : ""}</p>
                                                            )}
                                                        </div>
                                                        <div className="que_ans">
                                                            <h5>
                                                                Q3: Leadership comes in many forms, and we value diversity in leadership. What do you feel are your strongest leadership qualities? Please share one or more examples of times
                                                                when you have applied them in your life.
                                                            </h5>
                                                            {/* <p className="p-0">{applicationdetaildata.admireinhistory ? applicationdetaildata.admireinhistory : ""}</p> */}

                                                            {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                <>
                                                                    {Plagiarismresponcedata.map((item, idx2) => (
                                                                        <div key={idx2}>{item.order && item.order === 3 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                <p className="p-0">{applicationdetaildata.admireinhistory ? applicationdetaildata.admireinhistory : ""}</p>
                                                            )}
                                                        </div>
                                                        <div className="que_ans">
                                                            <h5>Q4: What are your biggest strengths? Name at least two, and explain how you would use them to make the world better.</h5>
                                                            {/* <p className="p-0">{applicationdetaildata.leadership ? applicationdetaildata.leadership : ""}</p> */}

                                                            {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                <>
                                                                    {Plagiarismresponcedata.map((item, idx4) => (
                                                                        <div key={idx4}>{item.order && item.order === 4 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                <p className="p-0">{applicationdetaildata.leadership ? applicationdetaildata.leadership : ""}</p>
                                                            )}
                                                        </div>
                                                        <div className="que_ans">
                                                            <h5>Q5: What are your most significant weaknesses? Name at least two, and explain how you mitigate them. Everyone has weaknesses, so please be honest in sharing yours here.</h5>
                                                            {/* <p className="p-0">{applicationdetaildata.exerciseleadership ? applicationdetaildata.exerciseleadership : ""}</p> */}

                                                            {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                <>
                                                                    {Plagiarismresponcedata.map((item, idx5) => (
                                                                        <div key={idx5}>{item.order && item.order === 5 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                <p className="p-0">{applicationdetaildata.exerciseleadership ? applicationdetaildata.exerciseleadership : ""}</p>
                                                            )}
                                                        </div>
                                                        <div className="que_ans">
                                                            <h5>Q6: Write a brief autobiography of your life. Feel free to provide a truthful account or be imaginative and creative. A minimum of 200 words is kindly requested.</h5>
                                                            {/* <p className="p-0">{applicationdetaildata.biggeststrength ? applicationdetaildata.biggeststrength : ""}</p> */}

                                                            {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                <>
                                                                    {Plagiarismresponcedata.map((item, idx6) => (
                                                                        <div key={idx6}>{item.order && item.order === 6 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                <p className="p-0">{applicationdetaildata.biggeststrength ? applicationdetaildata.biggeststrength : ""}</p>
                                                            )}
                                                        </div>
                                                        <div className="que_ans">
                                                            <h5>Q7: Do you have any personal or professional mentors? If so, please explain who and why.</h5>
                                                            {/* <p className="p-0">{applicationdetaildata.autobiography ? applicationdetaildata.autobiography : ""}</p> */}

                                                            {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                <>
                                                                    {Plagiarismresponcedata.map((item, idx7) => (
                                                                        <div key={idx7}>{item.order && item.order === 7 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                <p className="p-0">{applicationdetaildata.autobiography ? applicationdetaildata.autobiography : ""}</p>
                                                            )}
                                                        </div>
                                                        <div className="que_ans">
                                                                                    <h5>Q8: Please share your favorite quotation, and explain what it means to you personally and/or professionally.</h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.favoritequote ? applicationdetaildata.favoritequote : ""}</p> */}
                                                            <p className="p-0">{applicationdetaildata.favoritequote ? applicationdetaildata.favoritequote : ""}</p>
                                                                                    {/* {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx8) => (
                                                                                                <div key={idx8}>
                                                                                                    {item.order && item.order === 8 && (
                                                                                                        <>
                                                                                                            {item.html && item.html !== '' ? (
                                                                                                                <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />
                                                                                                            ) : (
                                                                                                                <>
                                                                                                                    {applicationdetaildata.favoritequote ? applicationdetaildata.favoritequote : ""}
                                                                                                                </>
                                                                                                            )}


                                                                                                        </>
                                                                                                    )}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.favoritequote ? applicationdetaildata.favoritequote : ""}</p>
                                                                                    )} */}
                                                                                </div>
                                                        <div className="que_ans">
                                                            <h5>Q9: What does confidence mean to you?</h5>
                                                            {/* <p className="p-0">{applicationdetaildata.confidence ? applicationdetaildata.confidence : ""}</p> */}

                                                            {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                <>
                                                                    {Plagiarismresponcedata.map((item, idx9) => (
                                                                        <div key={idx9}>{item.order && item.order === 9 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                <p className="p-0">{applicationdetaildata.confidence ? applicationdetaildata.confidence : ""}</p>
                                                            )}
                                                        </div>
                                                        <div className="que_ans">
                                                            <h5>Q10: Tell us about a challenge or conflict you’ve faced or are currently facing. How did/do you deal with it?</h5>
                                                            {/* <p className="p-0">{applicationdetaildata.conflict ? applicationdetaildata.conflict : ""}</p> */}

                                                            {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                <>
                                                                    {Plagiarismresponcedata.map((item, idx10) => (
                                                                        <div key={idx10}>{item.order && item.order === 10 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                <p className="p-0">{applicationdetaildata.conflict ? applicationdetaildata.conflict : ""}</p>
                                                            )}
                                                        </div>
                                                        <div className="que_ans">
                                                            <h5>Q11: The Roman Stoic philosopher Seneca (4 BC–65 AD) said, “Our fears are always more numerous than our dangers.” What is your biggest fear, and why?</h5>
                                                            {/* <p className="p-0">{applicationdetaildata.gretestachivement ? applicationdetaildata.gretestachivement : ""}</p> */}

                                                            {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                <>
                                                                    {Plagiarismresponcedata.map((item, idx11) => (
                                                                        <div key={idx11}>{item.order && item.order === 11 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                <p className="p-0">{applicationdetaildata.gretestachivement ? applicationdetaildata.gretestachivement : ""}</p>
                                                            )}
                                                        </div>
                                                        <div className="que_ans">
                                                            <h5>Q12: How do you deal with anxiety or pressure?</h5>
                                                            {/* <p className="p-0">{applicationdetaildata.skills ? applicationdetaildata.skills : ""}</p> */}

                                                            {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                <>
                                                                    {Plagiarismresponcedata.map((item, idx12) => (
                                                                        <div key={idx12}>{item.order && item.order === 12 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                <p className="p-0">{applicationdetaildata.skills ? applicationdetaildata.skills : ""}</p>
                                                            )}
                                                        </div>
                                                        <div className="que_ans">
                                                            <h5>Q13: What has been your greatest achievement to date, either personally or professionally?</h5>
                                                            {/* <p className="p-0">{applicationdetaildata.fear ? applicationdetaildata.fear : ""}</p> */}

                                                            {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                <>
                                                                    {Plagiarismresponcedata.map((item, idx13) => (
                                                                        <div key={idx13}>{item.order && item.order === 13 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                <p className="p-0">{applicationdetaildata.fear ? applicationdetaildata.fear : ""}</p>
                                                            )}
                                                        </div>
                                                        <div className="que_ans">
                                                            <h5>Q14: What skills do you hope to develop if you join Girl Power Talk?</h5>
                                                            {/* <p className="p-0">{applicationdetaildata.weakness ? applicationdetaildata.weakness : ""}</p> */}

                                                            {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                <>
                                                                    {Plagiarismresponcedata.map((item, idx14) => (
                                                                        <div key={idx14}>{item.order && item.order === 14 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                <p className="p-0">{applicationdetaildata.weakness ? applicationdetaildata.weakness : ""}</p>
                                                            )}
                                                        </div>
                                                        <div className="que_ans">
                                                            <h5>
                                                                Q15: What do you feel is the most concerning issue for women today in the world or your home country? How would you work to drive change in this area if you were afforded the
                                                                necessary power and resources?
                                                            </h5>
                                                            {/* <p className="p-0">{applicationdetaildata.womenissue ? applicationdetaildata.womenissue : ""}</p> */}

                                                            {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                <>
                                                                    {Plagiarismresponcedata.map((item, idx15) => (
                                                                        <div key={idx15}>{item.order && item.order === 15 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                <p className="p-0">{applicationdetaildata.womenissue ? applicationdetaildata.womenissue : ""}</p>
                                                            )}
                                                        </div>
                                                        <div className="que_ans">
                                                            <h5>Q16: What is your favorite book, and why?</h5>
                                                            {/* <p className="p-0">{applicationdetaildata.favoritebook ? applicationdetaildata.favoritebook : ""}</p> */}

                                                            {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                <>
                                                                    {Plagiarismresponcedata.map((item, idx16) => (
                                                                        <div key={idx16}>{item.order && item.order === 16 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                <p className="p-0">{applicationdetaildata.favoritebook ? applicationdetaildata.favoritebook : ""}</p>
                                                            )}
                                                        </div>

                                                        <div className="que_ans">
                                                            <h5>Q17: Share one interesting fact about yourself that we might not otherwise know in evaluating your candidacy to join Girl Power Talk.</h5>
                                                            {/* <p className="p-0">{applicationdetaildata.information ? applicationdetaildata.information : ""}</p> */}

                                                            {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                <>
                                                                    {Plagiarismresponcedata.map((item, idx17) => (
                                                                        <div key={idx17}>{item.order && item.order === 17 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                <p className="p-0">{applicationdetaildata.information ? applicationdetaildata.information : ""}</p>
                                                            )}
                                                        </div>
                                                        <div className="que_ans">
                                                            <h5>Q18: Name a woman other than your mother or sister who has influenced your life. Describe how she has done so.</h5>
                                                            {/* <p className="p-0">{applicationdetaildata.interstingfacts ? applicationdetaildata.interstingfacts : ""}</p> */}

                                                            {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                <>
                                                                    {Plagiarismresponcedata.map((item, idx18) => (
                                                                        <div key={idx18}>{item.order && item.order === 18 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                <p className="p-0">{applicationdetaildata.interstingfacts ? applicationdetaildata.interstingfacts : ""}</p>
                                                            )}
                                                        </div>
                                                        <div className="que_ans">
                                                            <h5>
                                                                Q19: Anne Frank (1929–1945) wrote, “How wonderful it is that nobody need wait a single moment before starting to improve the world.” How does this quotation resonate with you?
                                                            </h5>
                                                            {/* <p className="p-0">{applicationdetaildata.magicwand ? applicationdetaildata.magicwand : ""}</p> */}

                                                            {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                <>
                                                                    {Plagiarismresponcedata.map((item, idx19) => (
                                                                        <div key={idx19}>{item.order && item.order === 19 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                <p className="p-0">{applicationdetaildata.magicwand ? applicationdetaildata.magicwand : ""}</p>
                                                            )}
                                                        </div>
                                                        <div className="que_ans">
                                                            <h5>Q20: How do you spend most of your free time (e.g., hobbies, interests)?</h5>
                                                            {/* <p className="p-0">{applicationdetaildata.milliondollars ? applicationdetaildata.milliondollars : ""}</p> */}

                                                            {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                <>
                                                                    {Plagiarismresponcedata.map((item, idx20) => (
                                                                        <div key={idx20}>{item.order && item.order === 20 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                <p className="p-0">{applicationdetaildata.milliondollars ? applicationdetaildata.milliondollars : ""}</p>
                                                            )}
                                                        </div>
                                                        <div className="que_ans">
                                                            <h5>Q21: How would your closest friends and family members describe you?</h5>
                                                            {/* <p className="p-0">{applicationdetaildata.stressfulsitutations ? applicationdetaildata.stressfulsitutations : ""}</p> */}

                                                            {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                <>
                                                                    {Plagiarismresponcedata.map((item, idx21) => (
                                                                        <div key={idx21}>{item.order && item.order === 21 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                <p className="p-0">{applicationdetaildata.stressfulsitutations ? applicationdetaildata.stressfulsitutations : ""}</p>
                                                            )}
                                                        </div>
                                                        <div className="que_ans">
                                                            <h5>Q22: What excites you about being a part of Girl Power Talk?</h5>
                                                            {/* <p className="p-0">{applicationdetaildata.inspiringwoman ? applicationdetaildata.inspiringwoman : ""}</p> */}

                                                            {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                <>
                                                                    {Plagiarismresponcedata.map((item, idx22) => (
                                                                        <div key={idx22}>{item.order && item.order === 22 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                <p className="p-0">{applicationdetaildata.inspiringwoman ? applicationdetaildata.inspiringwoman : ""}</p>
                                                            )}
                                                        </div>
                                                        <div className="que_ans">
                                                            <h5>Q23: What is one creative idea you have for Girl Power Talk that could enhance our culture or business?</h5>
                                                            {/* <p className="p-0">{applicationdetaildata.quotemean ? applicationdetaildata.quotemean : ""}</p> */}

                                                            {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                <>
                                                                    {Plagiarismresponcedata.map((item, idx23) => (
                                                                        <div key={idx23}>{item.order && item.order === 23 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                <p className="p-0">{applicationdetaildata.quotemean ? applicationdetaildata.quotemean : ""}</p>
                                                            )}
                                                        </div>
                                                        <div className="que_ans">
                                                            <h5>Q24: Why should you be the next Young Leader at Girl Power Talk?</h5>
                                                            {/* <p className="p-0">{applicationdetaildata.outsideofwork ? applicationdetaildata.outsideofwork : ""}</p> */}

                                                            {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                <>
                                                                    {Plagiarismresponcedata.map((item, idx24) => (
                                                                        <div key={idx24}>{item.order && item.order === 24 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                <p className="p-0">{applicationdetaildata.outsideofwork ? applicationdetaildata.outsideofwork : ""}</p>
                                                            )}
                                                        </div>
                                                        <div className="que_ans">
                                                                                    <h5>Q25: What questions do you have for us?</h5>
                                                                                    {/* <p className="p-0">{applicationdetaildata.closestfriend ? applicationdetaildata.closestfriend : ""}</p> */}
                                                            <p className="p-0">{applicationdetaildata.closestfriend ? applicationdetaildata.closestfriend : ""}</p>
                                                                                    {/* {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx25) => (
                                                                                                <div key={idx25}>
                                                                                                    {item.order && item.order === 25 && (
                                                                                                        <>
                                                                                                            {item.html && item.html !== ''  ? (
                                                                                                                <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />
                                                                                                            ):(
                                                                                                                <>
                                                                                                                    {applicationdetaildata.closestfriend ? applicationdetaildata.closestfriend : ""}
                                                                                                                  </>  
                                                                                                            )}
                                                                                                            
                                                                                                    
                                                                                                    </>
                                                                                                    )}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p className="p-0">{applicationdetaildata.closestfriend ? applicationdetaildata.closestfriend : ""}</p>
                                                                                    )} */}
                                                                                </div>
                                                        {/* <div className="que_ans">
                                                                                    <h5>
                                                                                        Q26: Why did you decide to complete the indication of interest for Girl Power Talk and our sister company, Blue Ocean Global Technology? In other words,
                                                                                        what inspires you to be part of the Girl Power Talk culture and why do you think you will be a good fit?
                                                                                    </h5>
                                                                                   
                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx26) => (
                                                                                                <div key={idx26}>{item.order && item.order === 26 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (<p className="p-0">{applicationdetaildata.intrestofgirlpower ? applicationdetaildata.intrestofgirlpower : ""}</p>)}
                                                                                </div>
                                                                                <div className="que_ans">
                                                                                    <h5>Q27: Do you have any questions for us? Please feel free to ask them here.</h5>
                                                                                    
                                                                                    {Plagiarismresponcedata && Plagiarismresponcedata.length > 0 ? (
                                                                                        <>
                                                                                            {Plagiarismresponcedata.map((item, idx27) => (
                                                                                                <div key={idx27}>{item.order && item.order === 27 && <div className="p-0 plagiarismresponce" dangerouslySetInnerHTML={{ __html: item.html }} />}</div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (<p className="p-0">{applicationdetaildata.anyquestion ? applicationdetaildata.anyquestion : ""}</p>)}
                                                                                </div> */}
                                                    </div>
                                                </div>

                                                <div className="col-lg-9 fv-row fv-plugins-icon-container text-start">
                                                    <Form name="normal_login" className="login-form" initialValues={{ remember: true }} onFinish={handleSubmit}>
                                                        <label className="col-form-label fw-bold fs-6">Enter Your Clarification</label>
                                                        <Form.Item name="clarification" rules={[{ required: true, message: "Please enter your clarification!" }]}>
                                                            <TextArea placeholder="Clarification" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" />
                                                        </Form.Item>

                                                        <button type="submit" id="kt_new_password_submit" className="btn btn-sm btn-primary fw-bolder me-4 mt-4">
                                                            <span className="indicator-label">Send</span>
                                                        </button>
                                                    </Form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Footer />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
                <span className="svg-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="black" />
                        <path
                            d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                            fill="black"
                        />
                    </svg>
                </span>
            </div>
        </div>
    );
};

export default FlaggClarification;
