import React, { useState } from "react";

import Moment from 'moment';
import { Link } from "react-router-dom";
import Select from "react-select";

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";
import { Tooltip,  Spin } from "antd";

const InterviewsItem = (props) => {
    // const user_role = JSON.parse(localStorage.getItem("gpt_user_role"));
    const [tagvalue, settagvalue] = useState("");
    const [levelvalue, setlevelvalue] = useState("");
    const [perpagedata, setperpagedata] = useState(10);


    // function cancel(e) {
    //     setTimeout(() => {
    //         notification.open({
    //             message: "Member",
    //             description: "Data is not deleted..",
    //         });
    //     }, 1000);
    // }


    const [columns] = useState([
        {
            sort: true,
            onSort: (field, order) => {
                props.propsfiltersort(field);
                props.propsfilterorder(order);
            },
            text: "Name",
            dataField: "userName",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <div key={"member_name_out_" + row._id}>
                        {row && row.email ? (
                            <span className="fs-6 text-gray-800">
                                {row.userName}
                            </span>

                        ) : (
                            "--"
                        )}
                        
                    </div>
                );
            },
        },

        {
            sort: true,
            onSort: (field, order) => {
                props.propsfiltersort(field);
                props.propsfilterorder(order);
            },
            text: "Date",
            dataField: "updatedAt",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <div key={"datwe_" + row._id}>
                        {row && row.updatedAt ? (
                            <>
                                <span key={"member_date_" + row._id} className="fs-6 text-gray-800"> {Moment(row.updatedAt).format('DD-MM-YYYY')}</span>
                            </>


                        ) : (
                            "--"
                        )}
                    </div>
                );
            },
        },
       
        {
            // sort: true,
            // onSort: (field, order) => {
            //     props.propsfiltersort(field);
            //     props.propsfilterorder(order);
            // },
            text: "Interviewer",
            dataField: "upcomingInterviews",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <div key={"upcomingInterviews_" + row._id}>
                        {/* {(user_role && user_role && user_role.memberInterview && user_role.memberInterview === "yes") &&
                        ( */}
                        {row && row.userName ? (
                            row.userName

                        ) : (
                            "--"
                        )}

                        {/* )} */}
                    </div>
                );
            },

        },
        {
            // sort: true,
            // onSort: (field, order) => {
            //     props.propsfiltersort(field);
            //     props.propsfilterorder(order);
            // },
            text: "Zoom Link",
            dataField: "login",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <div key={"datwe_" + row._id}>
                        <>
                            <Link to="https://csueb.zoom.us/j/84782872727">https://csueb.zoom.us/j/84782872727</Link>
                        </>
                    </div>
                );
            },

        },

        {
            text: "Actions",
            dataField: "actions",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <div key={"actions_" + row._id}
                        
                    >
                     
                                <span
                                    className="edit_icon me-2"
                                    style={{
                                        verticalAlign: "text-bottom",
                                    }}
                                >
                            <Tooltip title="Schedule">
                                <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"><i className="bi bi-calendar4 fs-6"></i></span>
                                    
                                    </Tooltip>
                                </span>
                        
                       
                       
                       


                    </div>
                );
            },

        },
    ])

    // const handleTableChange = (type, { page, sizePerPage }) => {        
    //     props.propsfilterpage(page);
    // };
    const handleTableChange = (type, { sortField, sortOrder, page }) => {
        if (type === "sort") {
            props.propsfiltersort(sortField);
            props.propsfilterorder(sortOrder);
        }

        if (type === "pagination") {
            props.propsfilterpage(page);
        }
    };
    const RemotePagination = ({ columns, dataField, order, data, page, sizePerPage, onTableChange, totalSize }) => (
        <PaginationProvider
            pagination={paginationFactory({
                custom: true,
                page,
                sizePerPage,
                totalSize,
            })}
        >
            {({ paginationProps, paginationTableProps }) => (
                <>
                    <div className="table-responsive">
                        <BootstrapTable
                            remote
                            bootstrap4
                            data={data}
                            keyField="_id"
                            sort={{ dataField: dataField, order: order }}
                            columns={columns}
                            {...paginationTableProps}
                            onTableChange={onTableChange}
                            headerClasses="fw-bolder text-white bg-dark-row"
                            classes="table-bordered table-row-bordered align-middle custom-table-style table-fields"
                            noDataIndication={() => <span className="text-center text-gray-400">No records found</span>}
                        />
                    </div>
                    <div className="d-flex flex-stack justify-content-between flex-wrap pt-3 pagination-main">
                        {totalSize > 0 && (                       
                        <div className="pagination_limt">
                            <select id='select' name='group' className='form-control input-sm form-select form-select-sm form-select-solid' size='1' value={perpagedata}
                                onChange={(event) => {
                                    props.propsfilterpage(1);
                                    setperpagedata(event.target.value);
                                    props.propsfilterlimit(parseInt(event.target.value));
                                }}
                            >
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={250}>250</option>
                                <option value={500}>500</option>
                            </select>
                        </div>
                        )}
                        <div className="pagination_page">
                            <PaginationListStandalone {...paginationProps} />
                        </div>
                    </div>
                </>
            )}
        </PaginationProvider>
    );

    return (
        <React.Fragment>
            <div className="card card-flush mb-xl-8" data-select2-id="select2-data-160-5h6n">
                <div className="card-header mt-5">
                    <div className="card-title flex-column">
                        <h3 className="fw-bolder mb-1">Interviews List</h3>
                    </div>
                    <div className="card-toolbar my-1 team-member-box" data-select2-id="select2-data-159-5lwa">
                        <div className="me-6 my-1 app-one">
                            <Select
                                className="react-bootstrap-typeahead tag-outer"
                                // defaultValue="All"
                                placeholder="Filter By Levels"
                                options={props.Levels}
                                value={props.Levels.filter(function (option) {
                                    return option.value === levelvalue;
                                })}
                                onChange={(event) => {
                                    setlevelvalue(event.value);
                                    props.propsfilterpage(1);
                                    props.propsfilterlevel(event.value);
                                }}
                            >
                            </Select>
                        </div>
                        <div className="me-4 my-1 app-one">
                            <Select
                                className="react-bootstrap-typeahead tag-outer"
                                placeholder="Filter By Tags"
                                options={props.Tags}
                                value={props.Tags.filter(function (option) {
                                    return option.value === tagvalue;
                                })}
                                onChange={(event) => {
                                    settagvalue(event.value);
                                    props.propsfilterpage(1);
                                    props.propsfiltertag(event.value);
                                }}
                            >

                            </Select>
                        </div>
                        <div className="d-flex align-items-center position-relative my-1">
                            <span className="svg-icon svg-icon-3 position-absolute ms-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
                                    <path
                                        d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                        fill="black"
                                    ></path>
                                </svg>
                            </span>
                            <input type="text" className="form-control form-control-solid form-select-sm w-150px ps-9" placeholder="Search by name" onChange={(event) => {
                                props.propsfiltersearch(event.target.value);
                            }} />
                        </div>
                    </div>
                </div>
                <div className="card-body pt-0">
                    <div className="table-responsive">
                        <div id="kt_profile_overview_table" className="dataTables_wrapper dt-bootstrap4 no-footer team-list-table">
                            <div className="table-responsive">
                                <div className="card-body p-0 bg-white radious-style radious-users  mt-5">
                                    <Spin spinning={props.loading}>
                                        <RemotePagination
                                            data={props.data}
                                            columns={columns}
                                            page={props.filterpage}
                                            totalSize={props.membercount}
                                            sizePerPage={props.filterlimit}
                                            order={props.filterorder}
                                            dataField={props.filtersort}
                                            onTableChange={handleTableChange}
                                        />
                                    </Spin>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </React.Fragment>

    );
};

export default InterviewsItem;