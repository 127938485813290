import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import { notification } from "antd";
import Select from "react-select";
// import {  updateCourses } from "../../Api/Courses/course";
// import { assignManualUser } from "../../Api/user/userapi";


// const { TextArea } = Input;

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#ffdbdb" : ""
        },
        backgroundColor: state.isSelected ? "#cc0c0c" : "",
    }),
};


function AutoAssignOtherMember(props) {
    const { editChapterData, MenualAutoAssignOtherMemberApplicationSubmit, isExternalMemberTypeModalVisible, getroundoneassignuser, getassignresult } = props;
    const [courseValue, setCourseValue] = useState([]);
    const [ValueId, setValueId] = useState("");
    const { applicaionId } = useParams();
    const user_info = JSON.parse(localStorage.getItem("gpt_authuser"));
    useEffect(() => {
        if (editChapterData) {   
            let application_interview_assign = [];
            for (let key in getassignresult) {
                let ValueId = getassignresult[key]['value']; 
                const answer_array = ValueId.split('!!'); 
                application_interview_assign.push(answer_array[0]);
            }  
            let userdata = [];
            for (let key in editChapterData) {
                let IDs = editChapterData[key]._id;               
                let memberresult = application_interview_assign.includes(IDs);
                if (memberresult === false){
                    userdata.push({
                        value: editChapterData[key]._id,
                        label: editChapterData[key].userName,
                    });
                }
                
            }
            setCourseValue(userdata);
        }
    }, [editChapterData]);
    const handleChange = async (result, data) => {
        // setValueId(result.value)
        if (result.value !== ''){
            const payload = {
                applicaionId: applicaionId,
                memberId: result.value,
                type: isExternalMemberTypeModalVisible,
                created_id: user_info._id,
            };
            MenualAutoAssignOtherMemberApplicationSubmit(payload);
        }
    };

    // const SubmitHandler = async (event) => {
    //     event.preventDefault();
    //     const payload = {
    //         applicaionId: applicaionId,
    //         memberId: ValueId,
    //     };
    //     MenualAutoAssignOtherMemberApplicationSubmit(payload);
    // };
    return (

        <>
            <form name="normal_login" className="login-form" >

                <Select
                    styles={customStyles}
                    className="react-bootstrap-typeahead tag-outer"
                    placeholder="Select a User..."
                    options={courseValue}
                    value={courseValue.filter(function (option) {
                        return option.value === ValueId;
                    })}
                    onChange={handleChange}
                />
                {/* <div className="text-center mt-6">
                    <button type="submit" className='btn btn-lg btn-primary fw-bolder'>Ok</button>
                </div> */}
            </form>
        </>
    );
}

export default AutoAssignOtherMember;
