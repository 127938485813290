import React from "react";
import Select from "react-select";
// import { useFormik } from "formik";
import { notification } from "antd";
import { Link, Redirect } from "react-router-dom";
import { MemberDetail } from "../../Api/user/userapi";
import csc from "country-state-city";

import LeftSideBar from "../../Constant/LeftSideBar";
import TopBar from "../../Constant/TopBar";
import Footer from "../../Constant/Footer";
import Validations from "../Utility/Validations";
//import axios from "axios";
import { API_URL } from "../../Constant/index";
const user_info = JSON.parse(localStorage.getItem("gpt_authuser"));

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#ffdbdb" : ""
        },
        backgroundColor: state.isSelected ? "#cc0c0c" : "",
    }),
};


const token = localStorage.getItem("dataToken");

class Account extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            member_form: {
                image: {
                    label: "File",
                    type: "file",
                    value: [],
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                    thumbnail_image: "/assets/media/avatars/blank.png",
                },
                id: {
                    label: "Member ID",
                    type: "hidden",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },
                userName: {
                    type: "text",
                    label: "Name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                }, 
                zoomlink: {
                    type: "text",
                    label: "Link",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },                              
                gender: {
                    type: "select",
                    label: "Select gender",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Select gender",
                    validations: { required: true },
                    options: [
                        // { label: "Select gender", value: "" },
                        { label: "Male", value: "Male" },
                        { label: "Female", value: "Female" },
                        { label: "Transgender", value: "Transgender" },
                        { label: "Non-Binary", value: "Non-Binary" },
                        { label: "Other", value: "Other" },

                    ],
                },
                phone: {
                    type: "number",
                    label: "Phone",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, number: true },
                },
                email: {
                    type: "email",
                    label: "Eail",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, email: true },
                },               
                country: {
                    type: "select",
                    label: "Select country",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Select country",
                    validations: { required: true },
                    options: [],
                },
                address: {
                    type: "text",
                    label: "Address",
                    value: "",
                    valid: false,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                state: {
                    type: "select",
                    label: "Select state",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Select state",
                    validations: { required: true },
                    options: [],
                },
                city: {
                    type: "select",
                    label: "Select city",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Select city",
                    validations: { required: true },
                    options: [],
                },
                created_id: {
                    value: user_info._id,
                    validations: { required: false },
                },

            },

            loader: false,
            memberId: this.props.memberId,
            isAuthenticated: false,
            Levels: [],
            Members: [],

        };

    }

    async componentDidMount() {

        const update_member_form = { ...this.state.member_form };
        // let personal_info = user_info.user;
        // if (member_form.image) {
        // let member_form = member_form;
        //     let form_element = { ...update_member_form["image"] };
        //     form_element.thumbnail_image = member_form.image;
        //     update_member_form["image"] = form_element;
        //}

        this.MemberDetail();


        const countries = csc.getAllCountries();
        let country = [];
        for (let key in countries) {
            country.push({
                value: countries[key].id,
                label: countries[key].name,
            });
        }
        update_member_form.country.options = country;
        update_member_form.id.value = user_info._id;
        this.setState({ member_form: update_member_form });
    }

    MemberDetail = async () => {
        try {
            const resp = await MemberDetail({ memberId: user_info._id });
            if (resp && resp.status === 200) {
                const list = resp;
                const member_detail = list.data.data;
                const update_member_form = { ...this.state.member_form };
                for (let key in update_member_form) {
                    let form_element = update_member_form[key];
                    if (member_detail[key]) {
                        if (key === 'country') {
                            this.getstate(member_detail[key]);
                        }
                        if (key === 'state') {
                            this.getcity(member_detail[key])
                        }
                        if (key === 'userName') {
                            localStorage.setItem("login_user_name", member_detail[key]);
                            //this.getcity(member_detail[key])
                        }
                        if (key === 'image'){
                            if (member_detail[key] !== ''){
                                form_element.thumbnail_image = member_detail[key];
                                localStorage.setItem("user_profile", member_detail[key]);
                                // form_element.value = member_detail[key];
                            }
                        }
                        else{

                            form_element.value = member_detail[key];
                        }

                        
                        update_member_form[key] = form_element;
                    }
                }
                update_member_form.id.value = user_info._id;
                this.setState({
                    loader: false,
                    member_form: update_member_form,
                });

            }
        } catch (e) {
        }
    };
    getstate = async (countryId) => {
        const update_member_form = { ...this.state.member_form };
        const states = csc.getStatesOfCountry(countryId);
        let statevalue = [];
        for (let key in states) {
            statevalue.push({
                value: states[key].id,
                label: states[key].name,
            });
        }
        update_member_form.state.options = statevalue;
        this.setState({ member_form: update_member_form });
    };
    getcity = async (stateId) => {
        const update_member_form = { ...this.state.member_form };
        const citys = csc.getCitiesOfState(stateId);
        let cityvalue = [];
        for (let key in citys) {
            cityvalue.push({
                value: citys[key].id,
                label: citys[key].name,
            });
        }
        update_member_form.city.options = cityvalue;
        this.setState({ member_form: update_member_form });
    };

    
  
    inputChangeHandlerimage(event, identifier) {
    // Assuming only image
        var file = event.target.files[0];
        var url = URL.createObjectURL(file);
        const update_member_form = { ...this.state.member_form };
        const form_element = { ...update_member_form[identifier] };
        form_element.thumbnail_image = url;     
        form_element.value = file;    
        update_member_form[identifier] = form_element;
        this.setState({ member_form: update_member_form });
            
}
    inputChangeHandler(event, identifier) {
        const update_member_form = { ...this.state.member_form };
        const form_element = { ...update_member_form[identifier] };
        form_element.value = event.target.value;
        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_member_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_member_form) {
            is_form_valid = update_member_form[identifier].valid && is_form_valid;
        }
        this.setState({
            member_form: update_member_form,
            is_form_valid: is_form_valid,
        });
    }
    handleChange(data, identifier, type) {
        const update_member_form = { ...this.state.member_form };
        update_member_form[identifier].value = data.value;
        if (identifier === 'country') {
            this.getstate(data.value);
        }
        if (identifier === 'state') {
            this.getcity(data.value)
        }

        this.setState({ member_form: update_member_form });
    }
   
    SubmitHandler = async (event) => {
        event.preventDefault();
        let is_form_valid = true;
        const update_member_form = this.state.member_form;
        for (let key in update_member_form) {
            let form_element = update_member_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_member_form[key] = form_element;
        }
        this.setState({ member_form: update_member_form });
        if (is_form_valid) {
            const formdata = new FormData();
            for (let key in this.state.member_form) {
                if (key === 'image'){
                    formdata.append('image', this.state.member_form[key].value);
                }
                else{
                    formdata.append(key, this.state.member_form[key].value);
                }
                
            }
            fetch(`${API_URL}admin/updateAccount`, {
                method: "POST",
                headers: {
                    //  "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${token}`,
                },
                body: formdata,
            }).then((response) => response.json()).then((data) => {
                if (data && data.success === true) {
                //if (data.status === 200){
                    setTimeout(() => {
                        notification.open({
                            message: "Account",
                            description: "Updated Successfully.",
                        });
                    }, 1000);
                    this.MemberDetail();
                }
                else if (data && data.success === false) {
                    setTimeout(() => {
                        notification.open({
                            message: "Account",
                            description: data.message,
                        });
                    }, 1000);
                }  
                else{
                    setTimeout(() => {
                        notification.open({
                            message: "Account",
                            description: "Please try again.",
                        });
                    }, 1000);
                }
            }).catch((error) => {
                setTimeout(() => {
                        notification.open({
                            message: "Account",
                            description: 'Please Try again',
                        });
                    }, 1000);
            });
            

        }
    };
    render() {
        const { member_form } = this.state;
        return (
            <React.Fragment>
                {this.state.isAuthenticated ? <Redirect to={this.state.redirect_url} /> : null}
                <div className="d-flex flex-column flex-root header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed h-100">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <LeftSideBar />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <TopBar />

                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="toolbar" id="kt_toolbar">
                                    <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                                        <div
                                            data-kt-swapper="true"
                                            data-kt-swapper-mode="prepend"
                                            data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
                                            className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
                                        >
                                            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
                                                Settings
                                                <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                                            </h1>

                                            <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                                                <li className="breadcrumb-item text-muted">
                                                    <Link to="/dashboard" className="text-muted text-hover-primary">
                                                        Home
                                                    </Link>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <span className="bullet bg-gray-200 w-5px h-2px"></span>
                                                </li>
                                                {/* <li className="breadcrumb-item text-muted">
                                                    <Link to="#" className="text-muted text-hover-primary">
                                                        Settings
                                                    </Link>
                                                </li> */}
                                                {/* <li className="breadcrumb-item">
                                                    <span className="bullet bg-gray-200 w-5px h-2px"></span>
                                                </li> */}

                                                <li className="breadcrumb-item text-dark">Account</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-xxl">
                                        {/* <form name="normal_login" className="login-form" > */}
                                        <form name="normal_login" className="login-form team-form team-account-form" encType="multipart/form-data"  onSubmit={this.SubmitHandler}>
                                            <div className="row gy-5 g-xl-8">
                                                <div className="col-xl-12">
                                                    <div className="card card-xl-stretch mb-8">
                                                        <div className="card-header border-0">
                                                            <h3 className="card-title fw-bolder text-dark">My Account</h3>
                                                        </div>

                                                        <div className="card-body pt-2">
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <div className="image-input image-input-outline" data-kt-image-input="true" >
                                                                        <div className="image-input-wrapper w-125px h-125px" style={{
                                                                            backgroundImage: `url(${member_form.image.thumbnail_image})`,
                                                                        }}></div>
                                                                        <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" title="Change image">
                                                                            <i className="bi bi-pencil-fill fs-7"></i>
                                                                            <input type="file"  accept=".png, .jpg, .jpeg" onChange={(event) => this.inputChangeHandlerimage(event, "image")}
                                                                                onBlur={(event) => {
                                                                                    member_form.userName.onBlur_out = true;
                                                                                    this.setState({
                                                                                        member_form: member_form,
                                                                                    });
                                                                                }} />
                                                                            <input type="hidden" name="avatar_remove" />
                                                                        </label>
                                                                        <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="cancel" data-bs-toggle="tooltip" title="Cancel image">
                                                                            <i className="bi bi-x fs-2"></i>
                                                                        </span>
                                                                        {/* <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="remove" data-bs-toggle="tooltip" title="Remove image">
                                                                            <i className="bi bi-x fs-2"></i>
                                                                        </span> */}
                                                                    </div>
                                                                    <div className="form-text">Allowed file types: png, jpg, jpeg.</div>
                                                                </div>
                                                                <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label required fw-bold fs-6">Name</label>

                                                                    <input placeholder="First name" readOnly className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                                        value={member_form.userName.value ? member_form.userName.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "userName")}
                                                                        onBlur={(event) => {
                                                                            member_form.userName.onBlur_out = true;
                                                                            this.setState({
                                                                                member_form: member_form,
                                                                            });
                                                                        }} />
                                                                    {!member_form.userName.valid && member_form.userName.onBlur_out ? <div className="error field-error">{member_form.userName.error_msg}</div> : ""}
                                                                    <div className="fv-plugins-message-container invalid-feedback"></div>
                                                                </div>                                                              
                                                                <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label required required fw-bold fs-6">Phone Number</label>

                                                                    <input placeholder="Phone Number" type="text" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" value={member_form.phone.value ? member_form.phone.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "phone")}
                                                                        onBlur={(event) => {
                                                                            member_form.phone.onBlur_out = true;
                                                                            this.setState({
                                                                                member_form: member_form,
                                                                            });
                                                                        }} />
                                                                    {!member_form.phone.valid && member_form.phone.onBlur_out ? <div className="error field-error">{member_form.phone.error_msg}</div> : ""}
                                                                    <div className="fv-plugins-message-container invalid-feedback"></div>
                                                                </div>

                                                                <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label required fw-bold fs-6">Email</label>

                                                                    <input placeholder="Enter your email " readOnly type="email" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" value={member_form.email.value ? member_form.email.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "email")}
                                                                        onBlur={(event) => {
                                                                            member_form.email.onBlur_out = true;
                                                                            this.setState({
                                                                                member_form: member_form,
                                                                            });
                                                                        }} />
                                                                    {!member_form.email.valid && member_form.email.onBlur_out ? <div className="error field-error">{member_form.email.error_msg}</div> : ""}
                                                                    <div className="fv-plugins-message-container invalid-feedback"></div>
                                                                </div>
                                                                <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label required fw-bold fs-6">Gender</label>
                                                                    <Select
                                                                        styles={customStyles}
                                                                        className="react-bootstrap-typeahead tag-outer"
                                                                        placeholder="Select a Gender"
                                                                        options={member_form.gender.options}
                                                                        // value={member_form.gendervalue} 
                                                                        value={member_form.gender.options.filter(function (option) {
                                                                            return option.value === member_form.gender.value;
                                                                        })}
                                                                        onChange={newValue => this.handleChange(newValue, "gender", 'gendervalue')}
                                                                    />

                                                                    {!member_form.gender.valid && member_form.gender.onBlur_out ? <div className="error field-error">{member_form.gender.error_msg}</div> : ""}
                                                                    <div className="fv-plugins-message-container invalid-feedback"></div>
                                                                </div>
                                                                <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label required fw-bold fs-6">Country</label>
                                                                    <Select
                                                                        styles={customStyles}
                                                                        className="react-bootstrap-typeahead tag-outer"
                                                                        placeholder="Select a country..."
                                                                        options={member_form.country.options}
                                                                        value={member_form.country.options.filter(function (option) {
                                                                            return option.value === member_form.country.value;
                                                                        })}
                                                                        onChange={newValue => this.handleChange(newValue, "country", 'countryvalue')}
                                                                    />
                                                                    {!member_form.country.valid && member_form.country.onBlur_out ? <div className="error field-error">{member_form.country.error_msg}</div> : ""}
                                                                </div>

                                                                <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label required fw-bold fs-6">State</label>

                                                                    <Select
                                                                        styles={customStyles}
                                                                        className="react-bootstrap-typeahead tag-outer"
                                                                        placeholder="Select a State..."
                                                                        options={member_form.state.options}
                                                                        value={member_form.state.options.filter(function (option) {
                                                                            return option.value === member_form.state.value;
                                                                        })}
                                                                        onChange={newValue => this.handleChange(newValue, "state", 'countryvalue')}
                                                                    />
                                                                    {!member_form.state.valid && member_form.state.onBlur_out ? <div className="error field-error">{member_form.state.error_msg}</div> : ""}
                                                                </div>

                                                                <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label required fw-bold fs-6">City</label>
                                                                    <Select
                                                                        styles={customStyles}
                                                                        className="react-bootstrap-typeahead tag-outer"
                                                                        placeholder="Select a City..."
                                                                        options={member_form.city.options}
                                                                        value={member_form.city.options.filter(function (option) {
                                                                            return option.value === member_form.city.value;
                                                                        })}
                                                                        onChange={newValue => this.handleChange(newValue, "city", 'countryvalue')}
                                                                    />
                                                                    {!member_form.city.valid && member_form.city.onBlur_out ? <div className="error field-error">{member_form.city.error_msg}</div> : ""}
                                                                </div>
                                                                <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label fw-bold fs-6">Address</label>

                                                                    <input placeholder="Address" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                                        value={member_form.address.value ? member_form.address.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "address")}
                                                                        onBlur={(event) => {
                                                                            member_form.address.onBlur_out = true;
                                                                            this.setState({
                                                                                member_form: member_form,
                                                                            });
                                                                        }} />
                                                                    {!member_form.address.valid && member_form.address.onBlur_out ? <div className="error field-error">{member_form.address.error_msg}</div> : ""}
                                                                    <div className="fv-plugins-message-container invalid-feedback"></div>
                                                                </div>
                                                                <div className="col-lg-4 fv-row fv-plugins-icon-container">
                                                                    <label className=" col-form-label fw-bold fs-6">Zoom Link</label>

                                                                    <input placeholder="Link" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                                        value={member_form.zoomlink.value ? member_form.zoomlink.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "zoomlink")}
                                                                        onBlur={(event) => {
                                                                            member_form.zoomlink.onBlur_out = true;
                                                                            this.setState({
                                                                                member_form: member_form,
                                                                            });
                                                                        }} />
                                                                    {!member_form.zoomlink.valid && member_form.zoomlink.onBlur_out ? <div className="error field-error">{member_form.zoomlink.error_msg}</div> : ""}
                                                                    <div className="fv-plugins-message-container invalid-feedback"></div>
                                                                </div>
                                                                <div className="col-lg-12 mt-6">
                                                                    <button type="submit" id="kt_new_password_submit" className="btn btn-sm btn-primary fw-bolder me-4">
                                                                        <span className="indicator-label">Save</span>
                                                                    </button>
                                                                    {/* <Link to="/member" className="btn btn-sm btn-light-primary fw-bolder">
                                                                        Cancel
                                                                    </Link> */}
                                                                </div>
                                                                <div className="col-lg-4 fv-row fv-plugins-icon-container"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                                           
                                        </form>
                                        
                                    </div>
                                </div>
                            </div>
							<Footer />
                        </div>
                    </div>
                    <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
                        <span className="svg-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="black" />
                                <path
                                    d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                    fill="black"
                                />
                            </svg>
                        </span>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Account;
