import React, { useEffect, useState } from "react";
import {  useParams } from "react-router-dom";
// import { notification } from "antd";
import Select from "react-select";
// import {  updateCourses } from "../../Api/Courses/course";
// import { assignManualUser } from "../../Api/user/userapi";


// const { TextArea } = Input;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: state.isFocused ? "#ffdbdb" : ""
    },
    backgroundColor: state.isSelected ? "#cc0c0c" : "",
  }),
};

function AssignApplicationUser(props) {
  const { editChapterData, MenualAssignApplicationSubmit } = props;
  const [courseValue, setCourseValue] = useState([]);
  const [ValueId, setValueId] = useState("");
  const { applicaionId } = useParams();
  useEffect(() => {
    if (editChapterData) {
      let userdata = [];
      for (let key in editChapterData) {
        userdata.push({
          value: editChapterData[key]._id,
          label: editChapterData[key].userName,
        });
      }
      setCourseValue(userdata);
    }
  }, [editChapterData]);
  const handleChange = async (result, data) => {
    setValueId(result.value)
  };

  const SubmitHandler = async (event) => {
    event.preventDefault();
    const payload = {
      applicaionId: applicaionId,
      memberId: ValueId,
    };
    MenualAssignApplicationSubmit(payload);    
  };
  return (

    <>
      <form name="normal_login" className="login-form" onSubmit={SubmitHandler}>
       
        <Select
          styles={customStyles}
          className="react-bootstrap-typeahead tag-outer"
          placeholder="Select a User..."
          options={courseValue}
          value={courseValue.filter(function (option) {
            return option.value === ValueId;
          })}
          onChange={handleChange}
        />
        <div className="text-center mt-6">
          <button type="submit" className='btn btn-sm btn-primary fw-bolder'>Ok</button>
        </div>
      </form>
    </>
  );
}

export default AssignApplicationUser;
