import React from "react";
import { Link } from "react-router-dom";

const LeftSideBar = () => {
    const user_info = JSON.parse(localStorage.getItem("gpt_authuser"));
    const user_role = JSON.parse(localStorage.getItem("gpt_user_role"));

    return (
        <>
            <div
                id="kt_aside"
                className="aside aside-dark aside-hoverable"
                data-kt-drawer="true"
                data-kt-drawer-name="aside"
                data-kt-drawer-activate="{default: true, lg: false}"
                data-kt-drawer-overlay="true"
                data-kt-drawer-width="{default:'200px', '300px': '250px'}"
                data-kt-drawer-direction="start"
            // data-kt-drawer-toggle="#kt_aside_mobile_toggle"
            >
                <div className="aside-logo flex-column-auto" id="kt_aside_logo">
                    <Link to="/dashboard">
                        <span>
                            <img alt="Logo" src="/assets/media/logos/logo.png" className="h-25px logo" />
                        </span>
                    </Link>
                    <div className="menu-item menu-accordion">
                        <div className="btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle" data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body" data-kt-toggle-name="aside-minimize">
                            <span className="svg-icon svg-icon-1 rotate-180">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path
                                        opacity="0.5"
                                        d="M14.2657 11.4343L18.45 7.25C18.8642 6.83579 18.8642 6.16421 18.45 5.75C18.0358 5.33579 17.3642 5.33579 16.95 5.75L11.4071 11.2929C11.0166 11.6834 11.0166 12.3166 11.4071 12.7071L16.95 18.25C17.3642 18.6642 18.0358 18.6642 18.45 18.25C18.8642 17.8358 18.8642 17.1642 18.45 16.75L14.2657 12.5657C13.9533 12.2533 13.9533 11.7467 14.2657 11.4343Z"
                                        fill="black"
                                    />
                                    <path
                                        d="M8.2657 11.4343L12.45 7.25C12.8642 6.83579 12.8642 6.16421 12.45 5.75C12.0358 5.33579 11.3642 5.33579 10.95 5.75L5.40712 11.2929C5.01659 11.6834 5.01659 12.3166 5.40712 12.7071L10.95 18.25C11.3642 18.6642 12.0358 18.6642 12.45 18.25C12.8642 17.8358 12.8642 17.1642 12.45 16.75L8.2657 12.5657C7.95328 12.2533 7.95328 11.7467 8.2657 11.4343Z"
                                        fill="black"
                                    />
                                </svg>
                            </span>
                        </div>
                    </div>
                </div>
                <div id="sidebarscrole" className="aside-menu flex-column-fluid sidebarscrole">
                    <div
                        className="hover-scroll-overlay-y my-5 my-lg-5 accordion_sidebar"
                        //id="kt_aside_menu_wrapper"
                        data-kt-scroll="true"
                        data-kt-scroll-activate="{default: false, lg: true}"
                        data-kt-scroll-height="auto"
                        data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
                        data-kt-scroll-wrappers="#kt_aside_menu"
                        data-kt-scroll-offset="0"
                        id="accordion_sidebar"
                    >
                        <div className="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500" id="#kt_aside_menu" data-kt-menu="true">
                            <div className="menu-item">
                                <Link className="menu-link" to="/dashboard">
                                    <span className="menu-icon">
                                        <i className="bi bi-house-door fs-3"></i>
                                    </span>
                                    <span className="menu-title">Dashboard</span>
                                </Link>
                            </div>
                            {/* {(user_info && user_info.usertype && user_info.usertype === "admin") &&
                                    ( */}
                            {(user_role && user_role.memberView && user_role.memberView === "yes") ||
                                (user_role && user_role.memberAdd && user_role.memberAdd === "yes") ||
                                (user_role && user_role.memberEdit && user_role.memberEdit === "yes") ||
                                (user_role && user_role.memberDelete && user_role.memberDelete === "yes") ? (
                                <>
                                    <div id="teammember" data-kt-menu-trigger="click" className="menu-item menu-accordion pagenav">
                                        <span className="menu-link py-3 pageshow">
                                            <span className="menu-icon">
                                                <i className="bi bi-people fs-3"></i>
                                            </span>
                                            <span className="menu-title">Team Members</span>
                                            <span className="menu-arrow"></span>
                                        </span>

                                        <div className="menu-sub menu-sub-accordion menu-active-bg">
                                            <div className="menu-item menu-accordion">
                                                {user_role && user_role && user_role.memberAdd && user_role.memberAdd === "yes" && (
                                                    <Link className="menu-link" to="/Addmember">
                                                        <span className="menu-bullet">
                                                            <span className="bullet bullet-dot"></span>
                                                        </span>
                                                        <span className="menu-title">Add</span>
                                                    </Link>
                                                )}
                                                {(user_role && user_role.memberEdit && user_role.memberEdit === "yes") ||
                                                    (user_role && user_role.memberDelete && user_role.memberDelete === "yes") ||
                                                    (user_role && user_role.memberDelete && user_role.memberDelete === "yes") ? (
                                                    <Link className="menu-link" to="/member">
                                                        <span className="menu-bullet">
                                                            <span className="bullet bullet-dot"></span>
                                                        </span>
                                                        <span className="menu-title">List</span>
                                                    </Link>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                ""
                            )}
                            {/* )} */}
                            {(user_role && user_role.applicationAccept && user_role.applicationAccept === "yes") ||
                                (user_role && user_role.applicationReject && user_role.applicationReject === "yes") ||
                                (user_role && user_role.applicationUrl && user_role.applicationUrl === "yes") ||
                                (user_role && user_role.applicationComment && user_role.applicationComment === "yes") ||
                                (user_role && user_role.applicationAssign && user_role.applicationAssign === "yes") ||
                                (user_role && user_role.Allapplication && user_role.Allapplication === "yes") || (user_role && user_role.memberArchive && user_role.memberArchive === "yes") || (user_role && user_role.memberRejected && user_role.memberRejected === "yes") || (user_role && user_role.memberSelected && user_role.memberSelected === "yes") ? (
                                <>
                                    <div id="teamapplication" data-kt-menu-trigger="click" className="menu-item menu-accordion pagenav">
                                        <span className="menu-link py-3 pageshow">
                                            <span className="menu-icon">
                                                <i className="bi bi-people fs-3"></i>
                                            </span>
                                            <span className="menu-title"> Applications</span>
                                            <span className="menu-arrow"></span>
                                        </span>
                                        <div className="menu-sub menu-sub-accordion menu-active-bg">
                                            <div className="menu-item menu-accordion">
                                                <Link className="menu-link" to="/application">
                                                    <span className="menu-bullet">
                                                        <span className="bullet bullet-dot"></span>
                                                    </span>
                                                    <span className="menu-title">Applications</span>
                                                </Link>
                                                {(user_role && user_role.Allapplication && user_role.Allapplication === "yes" && user_info && user_info.usertype && user_info.usertype !== "admin") && (
                                                    <Link className="menu-link" to="/assigned-application">
                                                        <span className="menu-bullet">
                                                            <span className="bullet bullet-dot"></span>
                                                        </span>
                                                        <span className="menu-title">Assigned application</span>
                                                    </Link>
                                                )}
                                                {(user_role && user_role.memberArchive && user_role.memberArchive === "yes") && (
                                                    <Link className="menu-link" to="/archive-application">
                                                        <span className="menu-bullet">
                                                            <span className="bullet bullet-dot"></span>
                                                        </span>
                                                        <span className="menu-title">Archive</span>
                                                    </Link>
                                                )}
                                                {(user_role && user_role.memberRejected && user_role.memberRejected === "yes") && (
                                                    <Link className="menu-link" to="/rejected-application">
                                                        <span className="menu-bullet">
                                                            <span className="bullet bullet-dot"></span>
                                                        </span>
                                                        <span className="menu-title">Rejected</span>
                                                    </Link>
                                                )}
                                                {(user_role && user_role.memberSelected && user_role.memberSelected === "yes") && (
                                                    <Link className="menu-link" to="/selected-application">
                                                        <span className="menu-bullet">
                                                            <span className="bullet bullet-dot"></span>
                                                        </span>
                                                        <span className="menu-title">Selected</span>
                                                    </Link>
                                                )}
                                            </div>
                                        </div>
                                        {/* <div className="menu-item">                                    
                                                <Link className="menu-link" to="/application">
                                                    <span className="menu-icon">
                                                        <i className="bi bi-calendar3-event fs-3"></i>
                                                    </span>
                                                    <span className="menu-title">Applications</span>
                                                </Link>
                                            </div> */}
                                    </div>
                                </>
                            ) : (
                                ""
                            )}
                            {(user_role && user_role.flaggedView && user_role.flaggedView === "yes") ||
                                (user_role && user_role.flaggedAccept && user_role.flaggedAccept === "yes") ||
                                (user_role && user_role.flaggedReject && user_role.flaggedReject === "yes") ? (
                                <>
                                    <div className="menu-item">
                                        <Link className="menu-link" to="/flagged-application">
                                            <span className="menu-icon">
                                                <i className="bi bi-columns-gap fs-3"></i>
                                            </span>
                                            <span className="menu-title">Flagged Applications</span>
                                        </Link>
                                    </div>
                                </>
                            ) : (
                                ""
                            )}

                            {/* {(user_role && user_role.interviewView && user_role.interviewView === "yes") || (user_role && user_role.interviewReschedule && user_role.interviewReschedule === "yes") ? ( */}
                            <>
                                <div id="interviews_menu" data-kt-menu-trigger="click" className="menu-item menu-accordion pagenav">
                                    <span className="menu-link py-3 pageshow">
                                        <span className="menu-icon">
                                            {/* <i className="bi bi-person-lines-fill fs-3"></i> */}
                                            <i className="fa fa-podcast fs-3" aria-hidden="true"></i>
                                        </span>
                                        <span className="menu-title">Interviews</span>
                                        <span className="menu-arrow"></span>
                                    </span>
                                    <div className="menu-sub menu-sub-accordion menu-active-bg">
                                        <div className="menu-item menu-accordion">
                                            <Link to="/availability" className="menu-link">
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Availability</span>
                                            </Link>
                                            {(user_role && user_role.membersInterviewAvailability && user_role.membersInterviewAvailability === "yes") ?
                                                <Link to="/members-availability" className="menu-link">
                                                    <span className="menu-bullet">
                                                        <span className="bullet bullet-dot"></span>
                                                    </span>
                                                    <span className="menu-title">Members Availability</span>
                                                </Link> : ""}
                                            {/* {(user_role && user_role.interviewView && user_role.interviewView === "yes") &&( 
                                                            <Link to="/interviews" className="menu-link">
                                                            <span className="menu-bullet">
                                                                <span className="bullet bullet-dot"></span>
                                                            </span>
                                                            <span className="menu-title">List</span>
                                                        </Link>
                                                        )} */}
                                            {/* {(user_role && user_role.interviewReschedule && user_role.interviewReschedule === "yes") &&
                                                        (  */}
                                            <Link to="/interview-schedule" className="menu-link">
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Schedule</span>
                                            </Link>
                                            {/* )} */}
                                        </div>
                                    </div>
                                </div>
                            </>
                            {/* ) : (
                                 ""
                             )} */}

                            {/* {(user_role && user_role.settingAccount && user_role.settingAccount === "yes") || (user_role && user_role.settingEmail && user_role.settingEmail === "yes") || (user_role && user_role.settingGeneral && user_role.settingGeneral === "yes") ? ( */}
                            <>
                                <div id="settings_menu" data-kt-menu-trigger="click" className="menu-item menu-accordion pagenav">
                                    <span className="menu-link py-3 pageshow">
                                        <span className="menu-icon">
                                            <i className="bi bi-person fs-3"></i>
                                        </span>
                                        <span className="menu-title">Settings</span>
                                        <span className="menu-arrow"></span>
                                    </span>
                                    <div className="menu-sub menu-sub-accordion menu-active-bg">
                                        <div className="menu-item menu-accordion">
                                            {/* {(user_role && user_role.settingGeneral && user_role.settingGeneral === "yes") &&
                                            (
                                             <Link to="#" className="menu-link">
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">General</span>
                                            </Link>
                                            )} */}
                                            {user_role && user_role.settingEmail && user_role.settingEmail === "yes" && (
                                                <Link to="/email" className="menu-link">
                                                    <span className="menu-bullet">
                                                        <span className="bullet bullet-dot"></span>
                                                    </span>
                                                    <span className="menu-title">Email</span>
                                                </Link>
                                            )}
                                            {/* {(user_role && user_role.settingAccount && user_role.settingAccount === "yes") &&
                                            ( */}
                                            <Link to="/account" className="menu-link">
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Account</span>
                                            </Link>
                                            {/* )} */}
                                        </div>
                                    </div>
                                </div>
                            </>
                            {/* ): ("")
                                  } */}
                            {(user_role && user_role.MasterLevel && user_role.MasterLevel === "yes") ||
                                (user_role && user_role.MasterManageInterviews && user_role.MasterManageInterviews === "yes") ||
                                (user_role && user_role.MasterTag && user_role.MasterTag === "yes") ? (
                                <>
                                    <div id="masters_menu" data-kt-menu-trigger="click" className="menu-item menu-accordion pagenav">
                                        <span className="menu-link py-3 pageshow">
                                            <span className="menu-icon">
                                                <i className="bi bi-person-lines-fill fs-3"></i>
                                            </span>
                                            <span className="menu-title">Masters</span>
                                            <span className="menu-arrow"></span>
                                        </span>
                                        <div className="menu-sub menu-sub-accordion menu-active-bg">
                                            <div className="menu-item menu-accordion">
                                                {user_role && user_role.MasterLevel && user_role.MasterLevel === "yes" && (
                                                    <Link to="/level" className="menu-link">
                                                        <span className="menu-bullet">
                                                            <span className="bullet bullet-dot"></span>
                                                        </span>
                                                        <span className="menu-title">Level</span>
                                                    </Link>
                                                )}
                                                {user_role && user_role.MasterTag && user_role.MasterTag === "yes" && (
                                                    <Link to="/tag" className="menu-link">
                                                        <span className="menu-bullet">
                                                            <span className="bullet bullet-dot"></span>
                                                        </span>
                                                        <span className="menu-title">Tag</span>
                                                    </Link>
                                                )}
                                                {/* {(user_role && user_role.MasterManageInterviews && user_role.MasterManageInterviews === "yes") &&
                                            (
                                            <Link to="#" className="menu-link">
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Manage Interviews</span>
                                            </Link>
                                            )} */}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                ""
                            )}
                            <div className="menu-item">
                                <Link className="menu-link" to="/activity">
                                    <span className="menu-icon">
                                        {/* <i className="bi bi-person-bounding-box fs-3"></i> */}
                                        <i className="fas fa-tasks fs-3" aria-hidden="true"></i>
                                    </span>
                                    <span className="menu-title">Activity</span>
                                </Link>
                            </div>
                            {user_info && user_info.usertype && user_info.usertype === "admin" && (
                                <>
                                    <div className="menu-item">
                                        <Link className="menu-link" to="/permission">
                                            <span className="menu-icon">
                                                <i className="bi bi-person-bounding-box fs-3"></i>
                                            </span>
                                            <span className="menu-title">Roles & Permissions</span>
                                        </Link>
                                    </div>
                                    <div className="menu-item">
                                        <Link className="menu-link" to="/smtp-setting">
                                            <span className="menu-icon">
                                                <i className="fa fa-server  fs-3" aria-hidden="true"></i>
                                            </span>
                                            <span className="menu-title">SMTP Setting</span>
                                        </Link>
                                    </div>
                                    <div className="menu-item">
                                        <Link className="menu-link" to="/rejection-email">
                                            <span className="menu-icon">
                                                <i className="fa fa-ban fs-3" aria-hidden="true"></i>
                                            </span>
                                            <span className="menu-title">Rejection Day</span>
                                        </Link>
                                    </div>
                                </>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LeftSideBar;