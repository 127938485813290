import React from 'react'

function Thankyou() {
    return (
        <>
          <h1>
              Thank you Page
              </h1>  
        </>
    )
}

export default Thankyou
