import React from "react";
import { Redirect } from "react-router-dom";
import Application from "../../Component/Applications/Application";
import AssignedApplication from "../../Component/Applications/AssignedApplication";
import ApplicationDetail from "../../Component/Applications/ApplicationDetail";
import RepeatedApplication from "../../Component/Applications/RepeatedApplication";
import ArchiveApplication from "../../Component/Applications/ArchiveApplication";
import SelectedApplication from "../../Component/Applications/SelectedApplication";
import RejectedApplication from "../../Component/Applications/RejectedApplication";
class Applications extends React.Component {
    render() {
		const user_info = JSON.parse(localStorage.getItem("gpt_authuser"));
        const user_role = JSON.parse(localStorage.getItem("gpt_user_role"));
        const renderview = () => {
            if (this.props.match.path === "/application") {
                if(
        (user_role && user_role.applicationAccept && user_role.applicationAccept === "yes") ||
        (user_role && user_role.applicationReject && user_role.applicationReject === "yes") ||
        (user_role && user_role.applicationUrl && user_role.applicationUrl === "yes") ||
        (user_role && user_role.applicationComment && user_role.applicationComment === "yes") ||
                    (user_role && user_role.applicationAssign && user_role.applicationAssign === "yes") || (user_role && user_role.Allapplication && user_role.Allapplication === "yes")) {
                    return <Application />;
                  
                }
                else{
                    return <Redirect to="/dashboard" />;
                }
            }
            if (this.props.match.path === "/assigned-application") {
                if (user_role && user_role.Allapplication && user_role.Allapplication === "yes" && user_info && user_info.usertype && user_info.usertype !== "admin" ) {
                   <>jkhjhgjh</>
                    return <AssignedApplication />;

                }
                else{
                    return <Redirect to="/dashboard" />;
                }

            }        
            if (this.props.match.path === "/application-detail/:applicaionId") {
                //if(user_role && user_role.applicationView && user_role.applicationView === "yes" ) {
                   return <ApplicationDetail applicaionId={this.props.match.params.applicaionId} />;
                  
                // }
                // else{
                //     return <Redirect to="/dashboard" />;
                // }
                
            }
            if (this.props.match.path === "/repeated-application/:applicaionId") {
                // if (user_role && user_role.applicationView && user_role.applicationView === "yes") {
                    return <RepeatedApplication applicaionId={this.props.match.params.applicaionId} />;

                // }
                // else {
                //     return <Redirect to="/dashboard" />;
                // }

            }
            if (this.props.match.path === "/archive-application") {
                if (user_role && user_role.memberArchive && user_role.memberArchive === "yes") {
                return <ArchiveApplication />;

                }
                else {
                    return <Redirect to="/dashboard" />;
                }

            }
            if (this.props.match.path === "/rejected-application") {
                if (user_role && user_role.memberRejected && user_role.memberRejected === "yes") {
                return <RejectedApplication />;

                }
                else {
                    return <Redirect to="/dashboard" />;
                }

            }
            
            if (this.props.match.path === "/selected-application") {
                if (user_role && user_role.memberSelected && user_role.memberSelected === "yes") {
                return <SelectedApplication />;

                }
                else {
                    return <Redirect to="/dashboard" />;
                }

            }
        };
		
        return (
            <React.Fragment>
                {/* <Notifications /> */}
                {renderview()}
            </React.Fragment>
        );
		
    }
}
export default Applications;