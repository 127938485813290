import React, { useState } from "react";
import { Form, Input, Typography, Spin, Row, notification } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import { Link, Redirect } from "react-router-dom";
import { login } from "../../Api/auth/auth";
import { Member_Image_URL } from "../../Constant/index";

const { Title } = Typography;

let passwordError = "";
function Login(props) {
    const { history } = props;

    const [loading, setLoading] = useState(false);

    const userName = localStorage.getItem("fullname");
    const resultdataToken = localStorage.getItem("dataToken");
    if (resultdataToken && resultdataToken != "") {
        // props.history.push("/dashboard");
    }

    const handleSubmit = async (event) => {
        await login({
            userName: event.userName,
            password: event.password,
        })
            .then(function (response) {
                if (response.data.success !== false) {                    
                    localStorage.setItem("gpt_authuser", JSON.stringify(response.data.data));
                    localStorage.setItem("dataToken", response.data.data.token);
                    localStorage.setItem("login_user_name", response.data.data.userName);
                    if (response.data.data.image !== ''){
                        localStorage.setItem("user_profile", response.data.data.image);
                    }
                    else{
                        localStorage.setItem("user_profile", "/assets/media/avatars/blank.png");
                    }
                    // localStorage.setItem("user_profile", "/assets/media/avatars/blank.png");
                    let user_details = response.data.data;
                    if (user_details.usertype === "admin") {
                        const userroledata = {
                            MasterLevel: "yes",
                            MasterManageInterviews: "yes",
                            MasterTag: "yes",
                            applicationAccept: "yes",
                            applicationAction: "yes",
                            applicationAssign: "yes",
                            applicationComment: "yes",
                            applicationPlaigarism: "yes",
                            applicationReject: "yes",
                            applicationUrl: "yes",
                            applicationView: "yes",
                            dashboardView: "yes",
                            flaggedAccept: "yes",
                            flaggedReject: "yes",
                            flaggedView: "yes",
                            interviewAvailability: "yes",
                            interviewAvailabilityDelete:"yes",
                            interviewComplete: "yes",
                            interviewDelete: "yes",
                            interviewReschedule: "yes",
                            membersInterviewAvailability: "yes",
                            membersInterviewSchedule: "yes",
                            interviewView: "yes",
                            memberAdd: "yes",
                            memberArchive: "yes",
                            memberRejected: "yes",
                            memberSelected: "yes",
                            memberDelete: "yes",
                            memberEdit: "yes",
                            memberInterview: "yes",
                            memberView: "yes",
                            settingAccount: "yes",
                            settingAddEmail: "yes",
                            settingEmail: "yes",
                            settingGeneral: "yes",
                            dashboardApplications: "yes",
                            dashboardQualified: "yes",
                            dashboardRound1: "yes",
                            dashboardRound2: "yes",
                            dashboardRound3: "yes",
                            dashboardRound4: "yes",
                            dashboardFlagged: "yes",
                            flaggedComment: "yes",
                            applicationInitialReview: "yes",
                            applicationAssignOtherMember: "yes",
                            Allapplication: "yes",
                            interviewRound1: "yes",
                            interviewRound2: "yes",
                            interviewRound3: "yes",
                            Allflaggedapplication: "yes",
                            Assignedflaggedapplication: "yes",
                            applicationExternalMember: "yes",
                            applicationFinalReview:"yes",
                        };
                        localStorage.setItem("gpt_user_role", JSON.stringify(userroledata));
                    } else {
                        if (user_details.Levelsdata[0] && user_details.Levelsdata[0] != "") {
                            localStorage.setItem("gpt_user_role", JSON.stringify(user_details.Levelsdata[0]));
                        }
                    }
                    notification.open({
                        message: "Login",
                        description: "Logged in successfully.",
                    });
                    setTimeout(() => {
                        window.location.href = "/dashboard";
                    }, 500);
                } else {                    
                    notification.open({
                        message: "Login",
                        
                        description: response.data.message,
                    });
                }
            })
            .catch(function (error) {
                notification.open({
                    message: "Login",
                    description: "Wrong credentials.",
                });
            });
    };

    return (
        <React.Fragment>
            <div className="bg-body h-100 login-screen">
                <Spin spinning={loading}>
                    <div className="d-flex flex-column flex-root h-100">
                        <div
                            className="authentication_img d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
                            style={{ backgroundImage: `url(/assets/media/logos/white-bg.png)` }}
                        >
                            <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
                                <Link to="/" className="mb-12">
                                    <img alt="Logo" src="assets/media/logos/logo.png" className="h-40px" />
                                </Link>
                                <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
                                    <Form name="normal_login" className="login-form" initialValues={{ remember: true }} onFinish={handleSubmit}>
                                        <div className="text-center mb-10">
                                            <h1 className="text-dark mb-3">Sign In </h1>
                                        </div>
                                        <div className="fv-row mb-10">
                                            <label className="form-label fs-6 fw-bolder text-dark">Email</label>
                                            <Form.Item name="userName" rules={[{ required: true, message: "Please enter your username or email." }]}>
                                                <Input prefix={<MailOutlined className="form-control form-control-lg form-control-solid" />} 
                                                    type="text"
                                                    placeholder="Enter Email" />
                                            </Form.Item>
                                        </div>
                                        <div className="fv-row mb-10">
                                            <div className="d-flex flex-stack mb-2">
                                                <label className="form-label fw-bolder text-dark fs-6 mb-0">Password</label>
                                                <Link to="/forgot" className="link-primary fs-6 fw-bolder">
                                                    Forgot Password ?
                                                </Link>
                                            </div>
                                            <Form.Item name="password" rules={[{ required: true, message: "Please enter your password." }]}>
                                                <Input.Password
                                                    prefix={<LockOutlined className="form-control form-control-lg form-control-solid" />}
                                                    type="password"
                                                    placeholder="Enter password"
                                                    className="form-control form-control-lg form-control-solid"
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="text-center">
                                            <button type="submit" id="kt_sign_in_submit" className="btn btn-lg btn-primary w-100 mb-5">
                                                <span className="indicator-label">Continue</span>
                                                <span className="indicator-progress">
                                                    Please wait...
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span>
                                            </button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                            <div className="d-flex flex-center flex-column-auto p-10">
                                <div className="d-flex align-items-center fw-bold fs-6">
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </Spin>
            </div>
        </React.Fragment>
    );
}

export default Login;