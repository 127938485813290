import React, { useEffect, useState, useCallback } from "react";

import { Link } from "react-router-dom";
import { notification } from "antd";


import { getTemplatelist, updateTemplateStatus } from "../../Api/user/userapi";
import EmailItem from "./EmailItem";

import LeftSideBar from "../../Constant/LeftSideBar";
import TopBar from "../../Constant/TopBar";
import Footer from "../../Constant/Footer";

const SettingEmail = () => {
    const [loading, setLoading] = useState(false);
    const [input, setInput] = useState("");
    const [output, setOutput] = useState("");

    const [array, setArray] = useState([]);
    const [Levels, setLevels] = useState([]);
    const [Tags, setTags] = useState([]);
    const [id, setId] = useState();
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [editChapterData, setEditChapterData] = useState({});

    const user_info = JSON.parse(localStorage.getItem("gpt_authuser"));
    const user_role = JSON.parse(localStorage.getItem("gpt_user_role"));

    const [filterlimit, setfilterlimit] = useState(10);
    const [filterpage, setfilterpage] = useState(1);
    const [filtersort, setfiltersort] = useState("_id");
    const [filterorder, setfilterorder] = useState("desc");
    const [filtersearch, setfiltersearch] = useState("");
    const [filterlevel, setfilterlevel] = useState("");
    const [filtertag, setfiltertag] = useState("");
    const [membercount, setmembercount] = useState(0);

    const GetMembers = useCallback(async () => {
        const payload = {
            search: filtersearch,
            pageNumber: filterpage,
            limit: filterlimit,
            sortBy: filtersort,
            order: filterorder,
            // levelid: filterlevel,
            // tagid: filtertag,
        };
        try {
            setLoading(true);

            const resp = await getTemplatelist(payload);
            if (resp && resp.data.success === true) {
                const list = resp;
                setmembercount(list.data.count);
                const arrayData = list.data.data;
                setArray(arrayData);
                setLoading(false);
            }
            else{
                setTimeout(() => {
                    notification.open({
                        message: "Email",
                        description: resp.data.message,
                    });
                }, 1000);
                setLoading(false); 
            }
        } catch (e) {
        }
    }, [filtersearch, filterpage, filterlimit, filtersort, filterorder, filterlevel, filtertag]);
    useEffect(() => {
        GetMembers();

    }, [GetMembers, filtersearch, filterpage, filterlimit, filtersort, filterorder, filterlevel, filtertag]);
   


    const handleCancel = () => {
        setIsEditModalVisible(false);
    };

    const showEditModalHandle = (data) => {
        setIsEditModalVisible(true);
        setEditChapterData(data);
        setId(data._id);
    };
    const handleChange = async (value, event) => {
        let status = "";
        if (event === false) {
            status = "inactive";
        } else {
            status = "active";
        }

        const Payload = {
            status: status,
            Id: value,
            created_id: user_info._id,
        };

        try {
            const resp = await updateTemplateStatus(Payload);
            console.log(resp, "rsp");
            if (resp && resp.status === 200) {
                setTimeout(() => {
                    notification.open({
                        message: "Email",
                        description: "Status updated successfully.",
                    });
                }, 1000);
                setfilterpage(1);
                GetMembers();
            }
        } catch (e) {
            console.log(e);
        }
    }
    const removeChapterHandle = async (id) => {
        const Payload = {
            memberId: id,
        };
        // try {
        //     const resp = await deleteMemebr(Payload);
        //     if (resp && resp.status === 200) {
        //         setTimeout(() => {
        //             notification.open({
        //                 message: "Member",
        //                 description: "Deleted successfully.",
        //             });
        //         }, 1000);
        //         GetMembers();
        //     }
        // } catch (e) {
        //     setTimeout(() => {
        //         notification.open({
        //             message: "Member",
        //             description: "Member not Deleted..",
        //         });
        //     }, 1000);
        // }
    };


    return (
        <div className="d-flex flex-column flex-root header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed h-100">
            <div className="page d-flex flex-row flex-column-fluid">
                <LeftSideBar />
                <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                    <TopBar />

                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                        <div className="toolbar" id="kt_toolbar">
                            <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                                <div
                                    data-kt-swapper="true"
                                    data-kt-swapper-mode="prepend"
                                    data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
                                    className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
                                >
                                    <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
                                        Settings Email
                                        <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                                    </h1>

                                    <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                                        <li className="breadcrumb-item text-muted">
                                            <Link to="/dashboard" className="text-muted text-hover-primary">Home</Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <span className="bullet bg-gray-200 w-5px h-2px"></span>
                                        </li>


                                        <li className="breadcrumb-item text-dark">Email-Setting</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="post d-flex flex-column-fluid" id="kt_post">
                            <div id="kt_content_container" className="container-xxl">
                                <EmailItem
                                    membercount={membercount}
                                    filterlimit={filterlimit}
                                    filterpage={filterpage}
                                    data={array}
                                    Tags={Tags}
                                    Levels={Levels}
                                    loading={loading}
                                    filtersort={filtersort}
                                    filterorder={filterorder}
                                    propsfiltersort={(field) => setfiltersort(field)}
                                    propsfilterorder={(order) => setfilterorder(order)}

                                    propsfilterpage={(event) => setfilterpage(event)}
                                    propsfiltersearch={(event) => setfiltersearch(event)}
                                    removeChapterHandle={(event) => removeChapterHandle(event)}
                                    handleChange={(value, event) => handleChange(value, event)}



                                    propsfilterlevel={(level) => {
                                        setfilterlevel(level);
                                    }}
                                    propsfiltertag={(tags) => {
                                        setfiltertag(tags);
                                    }}
                                    propsfilterlimit={(filterlimit) => {
                                        setfilterlimit(filterlimit);
                                    }}
                                />
                                
                            </div>
                        </div>
                    </div>
					<Footer/>
                </div>
            </div>
            <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
                <span className="svg-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="black" />
                        <path
                            d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                            fill="black"
                        />
                    </svg>
                </span>
            </div>
            
        </div>
    );
};

export default SettingEmail;
