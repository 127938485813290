import React, { useState } from "react";

import Moment from 'moment';
import { Link } from "react-router-dom";
import Select from "react-select";

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";
import { notification, Popconfirm, Tooltip, Switch, Spin } from "antd";

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#ffdbdb" : ""
        },
        backgroundColor: state.isSelected ? "#cc0c0c" : "",
    }),
};

const MemberItem = (props) => {
    const user_role = JSON.parse(localStorage.getItem("gpt_user_role"));
    const [tagvalue, settagvalue] = useState("");
    const [levelvalue, setlevelvalue] = useState("");
    const [perpagedata, setperpagedata] = useState(10);
    const [gendervalue, setgendervalue] = useState("");

    const memberstatus = [{ label: "Filter By Status", value: "" }, { label: "Active", value: "active" }, { label: "Inactive", value: "inactive" }]; 

    function cancel(e) {
        setTimeout(() => {
            notification.open({
                message: "Member",
                description: "Data is not deleted..",
            });
        }, 1000);
        // message.error('Data is not deleted');
    }

  
    const [columns, setcolumns] = useState([
        {
            sort: true,
            onSort: (field, order) => {
                props.propsfiltersort(field);
                props.propsfilterorder(order);
            },
            text: "Name",
            dataField: "userName",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <div key={"member_name_out_" + row._id}>
                        {row && row.email ? (
                            <span  className="text member_list_name">
                                {row.userName}
                            </span>

                        ) : (
                            "--"
                        )}
                        {row && row.email ? (
                            <span className="fw-bold text-gray-400"> {row.email} </span>
                        ) : (
                            "--"
                        )}
                    </div>
                );
            },
        },

        {
            // sort: true,
            // onSort: (field, order) => {
            //     props.propsfiltersort(field);
            //     props.propsfilterorder(order);
            // },
            text: "Level",
            dataField: "levelid",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <div key={"LevelName_" + row._id}>
                        {row && row.LevelName ? (
                            <span className="text member_list_name">
                                {row.LevelName}
                            </span>

                        ) : (
                            "--"
                        )}
                        
                    </div>
                );
            },
        },
        {
            // sort: true,
            // onSort: (field, order) => {
            //     props.propsfiltersort(field);
            //     props.propsfilterorder(order);
            // },
            text: "Tags",
            dataField: "tagid",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <div key={"TagName_" + row._id}>
                        {row && row.TagName ? (
                            <span className="text member_list_name">
                                {row.TagName}
                            </span>

                        ) : (
                            "--"
                        )}

                    </div>
                );
            },
        },
        {
            // sort: true,
            // onSort: (field, order) => {
            //     props.propsfiltersort(field);
            //     props.propsfilterorder(order);
            // },
            text: "Upcoming Interviews",
            dataField: "upcomingInterviews",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <div key={"upcomingInterviews_" + row._id}>
                        {/* {(user_role && user_role && user_role.memberInterview && user_role.memberInterview === "yes") &&
                        ( */}
                        {row && row.upcomingInterviews ? (
                            row.upcomingInterviews

                        ) : (
                            "--"
                        )}

                        {/* )} */}
                    </div>
                );
            },

        },
        {
            // sort: true,
            // onSort: (field, order) => {
            //     props.propsfiltersort(field);
            //     props.propsfilterorder(order);
            // },
            text: "Last Logged in",
            dataField: "login",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <div key={"datwe_" + row._id}>
                        {row && row.login ? (
                            <>
                                <span key={"member_date_" + row._id} className="text-gray-800"> {Moment(row.login).format('DD-MM-YYYY')}</span>
                                <div key={"member_time_" + row._id} className="fw-bold text-gray-400">{Moment(row.login).format('h:mm a')}</div>
                            </>


                        ) : (
                            "--"
                        )}
                    </div>
                );
            },

        },

        {
            text: "Actions",
            dataField: "actions",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <div key={"actions_" + row._id}
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            
                        }}
                    >
                        {(user_role && user_role && user_role.memberView && user_role.memberView === "yes") &&
                            (
                                <span 
                                    className="edit_icon me-2"
                                    style={{
                                        verticalAlign: "text-bottom",
                                    }}
                                >
                                    <Tooltip title="View">
                                        <Link to={`/view-member/${row._id}`} >
                                            <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"><i className="bi bi-eye fs-6"></i></span>
                                        </Link>
                                    </Tooltip>
                                </span>
                            )}
                        {(user_role && user_role && user_role.memberEdit && user_role.memberEdit === "yes") &&
                            (
                                <span 
                                    className="edit_icon me-2"
                                    style={{
                                        verticalAlign: "text-bottom",
                                    }}
                                >
                                    <Tooltip title="Edit">
                                        <Link to={`/edit-member/${row._id}`} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm" data-bs-placement="bottom" data-bs-original-title="Edit">
                                            <i className="bi bi-pencil-fill fs-7"></i>
                                        </Link>
                                    </Tooltip>
                                </span>
                            )}
                        {/* {(user_role && user_role && user_role.memberDelete && user_role.memberDelete === "yes") &&
                            (
                                <span className="me-2">
                                    <Tooltip title="Delete">
                                        <Popconfirm title="Are you sure you want to delete?" onConfirm={() => props.removeChapterHandle(row._id)} onCancel={cancel} okText="Yes" cancelText="No">
                                            <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                                                <i className="bi bi-trash fs-7"></i>
                                            </span>
                                        </Popconfirm>
                                    </Tooltip>
                                </span>
                            )} */}
                            {(user_role && user_role && user_role.memberDelete && user_role.memberDelete === "yes") &&
                            (
                                <span className="switch-btn">
                                <Tooltip title="Active & Inactive">
                                {row.status === "active" ? <Switch 
                                defaultChecked 
                                onChange={(event) =>{ 
                                // setgendervalue('active');
                                props.handleChange(row._id, event);                                
                                }} /> : <Switch 
                                onChange={(event) =>{ 
                                // setgendervalue('active');
                                props.handleChange(row._id, event)}}
                                 />}
                                </Tooltip>
                                </span>
                            )}
                        {row && row.status && row.status !== 'active' ? (

                            <span className="me-2">
                                <Tooltip title="Resend Email">
                                    <span onClick={(event) => props.ResendEmail(row._id, event)} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                                        <i className="bi bi-arrow-clockwise fs-7"></i>
                                    </span>
                                </Tooltip>
                            </span>

                        ) : (
                            ""
                        )}


                    </div>
                );
            },

        },
    ])

    // const handleTableChange = (type, { page, sizePerPage }) => {        
    //     props.propsfilterpage(page);
    // };
    const handleTableChange = (type, { sortField, sortOrder, page }) => {
        if (type === "sort") {
            props.propsfiltersort(sortField);
            props.propsfilterorder(sortOrder);
        }

        if (type === "pagination") {
            props.propsfilterpage(page);
        }
    };
    const RemotePagination = ({ columns, dataField, order, data, page, sizePerPage, onTableChange, totalSize }) => (
        <PaginationProvider
            pagination={paginationFactory({
                custom: true,
                page,
                sizePerPage,
                totalSize,
            })}
        >
            {({ paginationProps, paginationTableProps }) => (
                <>
                    <div className="table-responsive">
                        <BootstrapTable
                            remote
                            bootstrap4
                            data={data}
                            keyField="_id"
                            sort={{ dataField: dataField, order: order }}
                            columns={columns}
                            {...paginationTableProps}
                            onTableChange={onTableChange}
                            headerClasses="fw-bolder text-white bg-dark-row"
                            classes="table-bordered table-row-bordered table-row-dashed align-middle custom-table-style table-fields"
                            noDataIndication={() => <span className="text-center text-gray-400">No records found</span>}
                        />
                    </div>
                    <div className="d-flex flex-stack justify-content-between flex-wrap pt-3 pagination-main">                       
                        {totalSize > 0 && ( 
                        <div className="pagination_limt">
                            <select id='select' name='group' className='form-control input-sm form-select form-select-sm form-select-solid' size='1' value={perpagedata}
                                onChange={(event) => {
                                   
                                    props.propsfilterpage(1);
                                    setperpagedata(event.target.value);
                                    props.propsfilterlimit(parseInt(event.target.value));
                                }}
                            >
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={250}>250</option>
                                <option value={500}>500</option>
                            </select>
                                {(parseInt(sizePerPage) * parseInt(page)) > totalSize ? (
                                    <div className="count_number">Displaying {(parseInt(sizePerPage) * parseInt(page) + parseInt(1)) - (parseInt(perpagedata))}-{totalSize} of {totalSize} records</div>
                                ):(
                                        <div className = "count_number">Displaying { (parseInt(sizePerPage) * parseInt(page) + parseInt(1)) - (parseInt(perpagedata)) }-{parseInt(sizePerPage) * parseInt(page)} of {totalSize} records</div>
                                )}
                                
                        </div>
                        )}
                        <div className="pagination_page">
                            <PaginationListStandalone {...paginationProps} />
                        </div>
                    </div>
                </>
            )}
        </PaginationProvider>
    );

    return (
        <React.Fragment>
            <div className="card card-flush mb-xl-8" data-select2-id="select2-data-160-5h6n ">
                <div className="card-header mt-5">
                   <div className="card-title flex-column">
                        
                 </div> 
                    <div className="card-toolbar my-1 team-member-box" data-select2-id="select2-data-159-5lwa">
                        <div className="me-4 my-1 app-one">
                            <Select
                                styles={customStyles}
                                className="react-bootstrap-typeahead tag-outer"
                                placeholder="Filter By Status"
                                options={memberstatus}
                                value={memberstatus.filter(function (option) {
                                   // console.log('option.value===', option.value)
                                    // console.log('gendervalue===', gendervalue)
                                    // return option.value === props.filterstatus;
                                    return option.value === gendervalue;
                                })}
                                onChange={(event) => {
                                    //props.propsfilterstatus()
                                    setgendervalue(event.value);
                                    props.propsfilterpage(1);
                                    props.propsfilterstatus(event.value);
                                }}
                            ></Select>

                        </div>
                        <div className="me-4 my-1 app-one">
                            <Select
                                styles={customStyles}
                                className="react-bootstrap-typeahead tag-outer"
                                // defaultValue="All"
                                placeholder="Filter By Levels"
                                options={props.Levels}
                                value={props.Levels.filter(function (option) {
                                    return option.value === levelvalue;
                                })}
                                onChange={(event) => {
                                    setlevelvalue(event.value);
                                    props.propsfilterpage(1);
                                    props.propsfilterlevel(event.value);
                                }}
                            >
                            </Select>
                        </div>
                        <div className="me-4 my-1 app-one">
                            <Select
                                styles={customStyles}
                                className="react-bootstrap-typeahead tag-outer"
                                placeholder="Filter By Tags"
                                options={props.Tags}
                                value={props.Tags.filter(obj => props.selectedValue.includes(obj.value))} // set selected values
                                isMulti
                                isClearable

                                onChange={(event) => {
                                    settagvalue(event.value);
                                    props.propsfilterpage(1);
                                    props.propsfiltertag(event);
                                }}
                            >
                                
                            </Select>
                        </div>
                        <div className="d-flex align-items-center position-relative my-1">
                            <span className="svg-icon svg-icon-3 position-absolute ms-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
                                    <path
                                        d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                        fill="black"
                                    ></path>
                                </svg>
                            </span>
                            <input type="text" className="form-control form-control-solid form-select-sm ps-9" placeholder="Search by Name / Email" onChange={(event) => {
                                props.propsfiltersearch(event.target.value); props.propsfilterpage(1);
                            }} />
                        </div>
                    </div>
                </div>
                <div className="card-body pt-0">
                    <div className="table-responsive">
                        <div id="kt_profile_overview_table" className="dataTables_wrapper dt-bootstrap4 no-footer team-list-table">
                            <div className="table-responsive">
                                <div className="card-body p-0 bg-white radious-style radious-users">
                                    <Spin spinning={props.loading}>
                                    <RemotePagination 
                                    data={props.data} 
                                    columns={columns} 
                                    page={props.filterpage} 
                                    totalSize={props.membercount} 
                                    sizePerPage={props.filterlimit} 
                                    order={props.filterorder}
                                     dataField={props.filtersort}
                                    onTableChange={handleTableChange} 
                                    />
                                    </Spin>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </React.Fragment>

    );
};

export default MemberItem;